export const MEETING_ROUTE = "/meeting/:meetingId";
export const createMeetingLink = (meetingId) =>
  process.env.REACT_APP_MEETING_URL +
  (process.env.NODE_ENV === "development" ? ":3002" : "") +
  "/" +
  meetingId;
export const createMeetingLinkWithToken = (meetingId, token) =>
  process.env.REACT_APP_MEETING_URL +
  (process.env.NODE_ENV === "development" ? ":3002" : "") +
  "/join/" +
  meetingId +
  "?token=" +
  token;
