import cx from "classnames";
import React from "react";

interface Prop {
  child1: React.ReactElement;
  child2: React.ReactElement;
  isActiveFirst: boolean;
  duration: 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000;
  transition:
    | "fade"
    | "slide"
    | "slideFromRight"
    | "slideFromLeft"
    | "LeftInRightOut"
    | "RightInLeftOut";
  className?: string;
}

/**
 * @description Component to switch between two component using tailwind animation
 * @param {Element} child1 - First component to switch
 * @param {Element} child2 - Second component to switch
 * @param {boolean} isActiveFirst - If true, first component is active
 * @param {number} duration - Duration eof the animation in ms
 * @param {string} transition - Type of transition to use
 * @param {string} className - Class name to add to the component
 */
export default function ComponentSwitcher({
  child1,
  child2,
  isActiveFirst = true,
  duration = 300,
  transition = "fade",
  className = "",
}: Prop) {
  return (
    <div className={`relative ${className}`}>
      <div
        className={cx(
          `Child-1 absolute inset-0 transform transition duration-${duration} ease-linear`,
          {
            "opacity-100 z-10": isActiveFirst && transition === "fade",
            "opacity-0 z-0 pointer-events-none":
              !isActiveFirst && transition === "fade",
          },
          {
            "translate-x-0 opacity-100 z-10":
              isActiveFirst && transition === "slide",
            "translate-x-full opacity-0 z-0 pointer-events-none":
              !isActiveFirst && transition === "slide",
          },
          {
            "translate-x-0 opacity-100 z-10":
              isActiveFirst && transition === "slideFromRight",
            "translate-x-full opacity-0 z-0 pointer-events-none":
              !isActiveFirst && transition === "slideFromRight",
          },
          {
            "translate-x-0 opacity-100 z-10":
              isActiveFirst && transition === "slideFromLeft",
            "-translate-x-full opacity-0 z-0 pointer-events-none":
              !isActiveFirst && transition === "slideFromLeft",
          },
          {
            "translate-x-0 opacity-100 z-10":
              isActiveFirst && transition === "LeftInRightOut",
            "-translate-x-full opacity-0 z-0 pointer-events-none":
              !isActiveFirst && transition === "LeftInRightOut",
          },
          {
            "-translate-x-0 opacity-100 z-10":
              isActiveFirst && transition === "RightInLeftOut",
            "translate-x-full opacity-0 z-0 pointer-events-none":
              !isActiveFirst && transition === "RightInLeftOut",
          }
        )}>
        {child1}
      </div>
      <div
        className={cx(
          `Child-2 absolute inset-0 transform transition duration-${duration} ease-linear`,
          {
            "opacity-100 z-10": !isActiveFirst && transition === "fade",
            "opacity-0 z-0 pointer-events-none":
              isActiveFirst && transition === "fade",
          },
          {
            "translate-x-0 opacity-100 z-10":
              !isActiveFirst && transition === "slide",
            "translate-x-full opacity-0 z-0 pointer-events-none":
              isActiveFirst && transition === "slide",
          },
          {
            "opacity-100 z-10":
              !isActiveFirst && transition === "slideFromRight",
            "opacity-0 z-0 pointer-events-none":
              isActiveFirst && transition === "slideFromRight",
          },
          {
            " opacity-100 z-10":
              !isActiveFirst && transition === "slideFromLeft",
            " opacity-0 z-0 pointer-events-none":
              isActiveFirst && transition === "slideFromLeft",
          },
          {
            "translate-x-0 opacity-100 z-10":
              !isActiveFirst && transition === "LeftInRightOut",
            "translate-x-full opacity-0 z-0 pointer-events-none":
              isActiveFirst && transition === "LeftInRightOut",
          },
          {
            "translate-x-0 opacity-100 z-10":
              !isActiveFirst && transition === "RightInLeftOut",
            "-translate-x-full opacity-0 z-0 pointer-events-none":
              isActiveFirst && transition === "RightInLeftOut",
          }
        )}>
        {child2}
      </div>
    </div>
  );
}

interface ComponentDisplayProp {
  children: React.ReactElement;
  IsDisplay: boolean;
  duration?: 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000;
  transition: "fade" | "slide" | "slideFromRight" | "slideFromLeft" | "scale";
  className?: string;
}

/**
 * @description Component to display/hide using tailwind animation
 * @param {Element} children - component to display
 * @param {boolean} IsDisplay - If true, first component is active
 * @param {number} duration - Duration eof the animation in ms
 * @param {string} transition - Type of transition to use
 * @param {string} className - Class name to add to the component
 */
export function ComponentDisplay({
  children,
  IsDisplay = true,
  duration = 300,
  transition = "fade",
  className = "",
}: ComponentDisplayProp) {
  return (
    <div className={cx(className)}>
      <div
        className={cx(
          `inset-0 transform transition duration-${duration} ease-linear`,
          {
            "opacity-100 z-10": IsDisplay && transition === "fade",
            "opacity-0 z-0 pointer-events-none h-0 w-0":
              !IsDisplay && transition === "fade",
          },
          {
            "translate-x-0 opacity-100 z-10":
              IsDisplay && transition === "slide",
            "translate-x-full opacity-0 z-0 pointer-events-none h-0 w-0":
              !IsDisplay && transition === "slide",
          },
          {
            "translate-x-0 opacity-100 z-10":
              IsDisplay && transition === "slideFromRight",
            "translate-x-full opacity-0 z-0 pointer-events-none h-0 w-0":
              !IsDisplay && transition === "slideFromRight",
          },
          {
            "translate-x-0 opacity-100 z-10":
              IsDisplay && transition === "slideFromLeft",
            "-translate-x-full opacity-0 z-0 pointer-events-none h-0 w-0":
              !IsDisplay && transition === "slideFromLeft",
          },
          {
            "opacity-100 z-10 scale-100": IsDisplay && transition === "scale",
            "opacity-0 z-0 pointer-events-none scale-0":
              !IsDisplay && transition === "scale",
          }
        )}>
        {IsDisplay && children}
      </div>
    </div>
  );
}
