export const v1Pensil = {
  "Pensil Keyword": "Super Retaillians",
  "Home Page Left column": "Home Page Left column",
  Home: "Home",
  "Explore Groups": "Explore Hubs",
  Events: "Super Events",
  Groups: "Hubs",
  Links: "Links",
  "Direct Message": "Direct Message",
  "Powered by": "Powered by",
  "No groups here, please use + to create a new one, or join one see them here.":
    "No hubs here, please use + to create a new one, or join one see them here.",
  "Please join a group to see them here": "Please join a hub to see them here",
  "Section can only be reordered within the group!":
    "Space can only be reordered within the hub!",
  "Group Page": "Hub Page",
  "Invite Members": "Invite Members",
  Join: "Join",
  Joined: "Joined",
  "Create new section": "Create new Space",
  "Group Setting drop down": "Hub Setting drop down",
  Share: "Share",
  "Edit group": "Edit hub",
  "Delete group": "Delete hub",
  Leave: "Leave",
  "Are you sure you want to delete the group?":
    "Are you sure you want to delete the hub?",
  Cancel: "Cancel",
  "Yes, Go Ahead": "Yes, Go Ahead",
  "Share via link": "Share via link",
  Copy: "Copy",
  Copied: "Copied",
  "Share on social media": "Share on social media",
  "Add Section": "Add Space",
  "Section Header": "Space Header",
  Live: "Live",
  "Create Post": "Create Post",
  "Notificaiton Bell": "Notificaiton Bell",
  "All posts": "All posts",
  "Admin posts only": "Admin posts only",
  None: "None",
  "Section Setting drop down": "Space Setting drop down",
  "Edit Section": "Edit Space",
  "Embed Section": "Embed Space",
  "Delete Section": "Delete Space",
  "Are you sure you want to delete the section?":
    "Are you sure you want to delete the Space?",
  "Section deleted!": "Space deleted!",
  "Create Group Pop up / Update": "Create hub Pop up / Update",
  "Create group": "Create hub",
  "Basic Detail": "Basic Detail",
  Settings: "Settings",
  "Size 16:9 or 1600 by 900": "Size 16:9 or 1600 by 900",
  "Group name": "Hub name",
  "Enter group name": "Enter hub name",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Suggestions: Spaces, Channels, Hubs, Blog, Knowledgebase, etc",
  "Group Description": "Hub Description",
  "Enter description here": "Enter description here",
  "Group Type": "Hub Type",
  Open: "Open",
  "Visible to all & anyone can join": "Visible to all & anyone can join",
  Closed: "Closed",
  "User will have to request to join the group":
    "User will have to request to join the hub",
  Paid: "Paid",
  "Group Price": "Hub Price",
  "Currency (Default: INR)": "Currency (Default: INR)",
  "User will have to pay to join the group":
    "User will have to pay to join the hub",
  "Make this a private group": "Make this a private hub",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Private Hub will only be visible to members who are part of it. Admin can add members manually from member list",
  "Upload from device": "Upload from device",
  "Search image": "Search image",
  "Valid group name required!": "Valid Hub name required!",
  "Update Group": "Update Hub",
  "SEO Settings": "SEO Settings",
  "Custom URL": "Custom URL",
  "Meta Title": "Meta Title",
  "Maximum length of 255 characters allowed":
    "Maximum length of 255 characters allowed",
  "Meta Description": "Meta Description",
  "Open Graph Detail": "Open Graph Detail",
  "Open Graph Title": "Open Graph Title",
  "Open Graph Description": "Open Graph Description",
  "Open Graph Image": "Open Graph Image",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "If no URL is used, it will automatically picked your Hub thumbnail.",
  "Create Section Pop up / Update": "Create Space Pop up / Update",
  "Create Section": "Create Space",
  "Section Name": "Space Name",
  "Suggestions: General, Introductions, Community Guidelines":
    "Suggestions: General, Introductions, Community Guidelines",
  "Section Type": "Space Type",
  "Post Type": "Post Type",
  "Group Chat": "Hub Chat",
  "Who can post in this section?": "Who can post in this Space?",
  "Only Admin": "Only Admin",
  "Any user": "Any user",
  "Sorting order for section": "Sorting order for Space",
  "Latest first": "Latest first",
  "Oldest post first": "Oldest post first",
  "Section Appearance": "Space Appearance",
  "List View": "List View",
  "Grid View": "Grid View",
  "Make this closed section": "Make this closed Space",
  "Members will have to request access to see section content":
    "Members will have to request access to see Space content",
  "Make this a private section": "Make this a private Space",
  "Disables requests (Make sure closed section is selected as well).":
    "Disables requests (Make sure closed Space is selected as well).",
  "Post Editor": "Post Editor",
  "Create new post in": "Create new post in",
  "Add Cover Picture": "Add Cover Picture",
  "Heading (Optional)": "Heading (Optional)",
  "Write here..": "Write here..",
  Picture: "Picture",
  Video: "Video",
  Attachment: "Attachment",
  Poll: "Poll",
  "Embed Code": "Embed Code",
  Tags: "Tags",
  "Toggle to question": "Run a Quiz",
  "Poll expire time": "Poll expire time",
  "Poll question": "Poll question",
  "Enter Question": "Type your question",
  Question: "Question",
  Option: "Option",
  Options: "Options",
  "Add Option": "Add Option",
  "Poll Expiry time": "Poll Expiry time",
  "12 Hours": "12 Hours",
  "1 Day": "1 Day",
  "2 Days": "2 Days",
  "1 Week": "1 Week",
  "2 Weeks": "2 Weeks",
  "Always Open": "Always Open",
  "Write embeddable code": "Write embeddable code",
  "Click here to add embed code.": "Click here to add embed code.",
  "Paste your embed code here!": "Paste your embed code here!",
  "Post Settings": "Post Settings",
  "Update Post": "Update Post",
  Update: "Update",
  "Start meeting / Video call / Live": "Start meeting / Video call / Live",
  "Meeting Name": "Meeting Name",
  "Suggestion: Group discussion etc": "Suggestion: Hub discussion etc",
  Description: "Description",
  "Enter description here (optional)": "Enter description here (optional)",
  "When do you want to start the session":
    "When do you want to start the session",
  "Right now": "Right now",
  "Schedule for later": "Schedule for later",
  "Group video call": "Hub video call",
  Webinar: "Webinar",
  "Go Live": "Go Live",
  "Info on the Post": "Info on the Post",
  "Post comment": "Post comment",
  Comment: "Comment",
  Comments: "Comments",
  Reply: "Reply",
  Replies: "Replies",
  "Share via URL": "Share via URL",
  "Reshare in other sections": "Reshare in other Spaces",
  "Post Card Drop Down": "Post Card Drop Down",
  Bookmark: "Bookmark",
  "Pin post": "Pin post",
  Edit: "Edit",
  "Embed Post": "Embed Post",
  Delete: "Delete",
  "Remove Pin": "Remove Pin",
  "Remove Bookmark": "Remove Bookmark",
  "Just copy paste this code wherever you want it to be embedded":
    "Just copy paste this code wherever you want it to be embedded",
  Height: "Height",
  Preview: "Preview",
  "Copied to clipboard": "Copied to clipboard",
  "Select Widgets": "Select Widgets",
  "Add widgets to": "Add widgets to",
  "You can change their order by dragging & dropping the widgets over each other":
    "You can change their order by dragging & dropping the widgets over each other",
  "Please note: Widget wont be displayed if there is no data available,":
    "Please note: Widget wont be displayed if there is no data available,",
  "Group Members": "Hub Members",
  "Group Leaderboard": "Hub Leaderboard",
  "Community Leaderboard": "Community Leaderboard",
  "Trending Posts": "Trending Posts",
  "Group Events": "Hub Events",
  "Add Custom": "Add Custom",
  "Save Widgets": "Save Widgets",
  "Visible to user": "Visible to user",
  "Visible to admin": "Visible to admin",
  "Add widget to": "Add widget to",
  "Upload cover picture": "Upload cover picture",
  "1:1 aspect ration (recommended)": "1:1 aspect ration (recommended)",
  "Card Title": "Card Title",
  "Enter widget name": "Enter widget name",
  "Small Description (try to keep it under 50 words)":
    "Small Description (try to keep it under 50 words)",
  "Enter description": "Enter description",
  "Button Title": "Button Title",
  "Enter button name": "Enter button name",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestion: Know more, Join, Apply, etc",
  "URL link": "URL link",
  "Create custom widget": "Create custom widget",
  "Custom widget created successfully": "Custom widget created successfully",
  Widgets: "Widgets",
  Members: "Members",
  "See All": "See All",
  "Communtiy Top Contributors": "Communtiy Top Contributors",
  "Community Members": "Community Members",
  "Group Top Contributors": "Hub Top Contributors",
  "Upcoming Events": "Upcoming Events",
  "Home Page Banner": "Home Page Banner",
  "Edit Banner": "Edit Banner",
  "Home page Edit Banner Pop up": "Home page Edit Banner Pop up",
  "Update Banner": "Update Banner",
  Search: "Search",
  "Upload Image": "Upload Image",
  Remove: "Remove",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)",
  "Banner Title": "Banner Title",
  "Enter here": "Enter here",
  "Suggestion: Welcome to my community etc":
    "Suggestion: Welcome to my community etc",
  "Banner Description": "Banner Description",
  "Enter banner description": "Enter banner description",
  "Save Banner Info": "Save Banner Info",
  "Home Page Feed": "Home Page Feed",
  Recent: "Recent",
  "Top Posts": "Top Posts",
  Trending: "Trending",
  "Home Feed Groups": "Home Feed Hubs",
  "Header BarProfile Drop down": "Header BarProfile Drop down",
  "My Profile": "My Profile",
  Logout: "Logout",
  Notification: "Notification",
  Notifications: "Notifications",
  "Mark all viewed": "Mark all viewed",
  "has created a post": "has created a post",
  "wants to join": "wants to join",
  "has commented on your post": "has commented on your post",
  "has replied to your comment": "has replied to your comment",
  "Search for tags": "Search for tags",
  "All groups": "All Hubs",
  "Search result": "Search result",
  "No post found for selected hashtag": "No post found for selected hashtag",
  "Start a conversation": "Start a conversation",
  "Search by name": "Search by name",
  Upcoming: "Upcoming",
  UPCOMING: "UPCOMING",
  Past: "Past",
  PAST: "PAST",
  "No upcoming events": "No upcoming events",
  "Create event popup": "Create event popup",
  "Event details": "Event details",
  "16:9 aspect ratio": "16:9 aspect ratio",
  "Event name": "Event name",
  "Enter event name": "Enter event name",
  "Host Name": "Host Name",
  "You will be made host if none selected":
    "You will be made host if none selected",
  "Write here...": "Write here...",
  "No. of registration for the event": "No. of registration for the event",
  Unlimited: "Unlimited",
  "When & where": "When & where",
  From: "From",
  "Start date": "Start date",
  time: "time",
  To: "To",
  "End date": "End date",
  "Current time zone:": "Current time zone:",
  "Can be updated from the communtiy setting":
    "Can be updated from the communtiy setting",
  "Online event": "Online event",
  "Add meeting link": "Add meeting link",
  "Meeting link will be generated once event is created":
    "Meeting link will be generated once event is created",
  Venue: "Venue",
  "Enter address": "Enter address",
  Create: "Create",
  Invite: "Invite",
  "Share event via link": "Share event via link",
  Copy__1: "Copy",
  "Select section to post this event": "Select Space to post this event",
  "Once posted, members of that group or section will recieve an notification":
    "Once posted, members of that Hub or Space will recieve an notification",
  Post: "Post",
  RSVP: "RSVP",
  "Book Event": "Book Event",
  "Yes, I'll attend": "Yes, I'll attend",
  "May be": "May be",
  "No, I won't attend": "No, I won't attend",
  "Date & Time": "Date & Time",
  Host: "Host",
  "Online meeting": "Online meeting",
  "Meeting link will be visible once registered for the event":
    "Meeting link will be visible once registered for the event",
  "User Management": "User Management",
  "Go bac to community": "Go bac to community",
  "Manage Members": "Manage Members",
  Member: "Member",
  Invited: "Invited",
  "Search for member": "Search for member",
  "Add to group": "Add to Hub",
  "Send Direct Message": "Send Direct Message",
  "Change role": "Change role",
  "Kick from community": "Kick from community",
  "Super Admin": "Super Admin",
  "Super admin will have full access to the communtiy settings":
    "Super admin will have full access to the communtiy settings",
  "Group Admin": "Hub Admin",
  "Admin of the selected group can edit the group information and create, edt or delete section under selected group":
    "Admin of the selected Hub can edit the Hub information and create, edt or delete Space under selected Hub",
  "Group Moderator": "Hub Moderator",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Hub moderator can create or delete post, comment, reply & messages in the selected Hub.",
  User: "User",
  Save: "Save",
  "User role updated successfully": "User role updated successfully",
  Leaderboard: "Super Leaderboard",
  "Click on the column header for sorting":
    "Click on the column header for sorting",
  Rank: "Rank",
  "Karma Points": "Super Coins",
  "Custom Points": "Custom Points",
  Follow: "Follow",
  Upvotes: "Upvotes",
  Posts: "Posts",
  "Add custom points": "Add custom points",
  "Subtract custom points": "Subtract custom points",
  "Enter no. of points": "Enter no. of points",
  "Add points": "Add points",
  "Invite members to": "Invite members to",
  "Invite via link": "Invite via link",
  "Invite via email": "Invite via email",
  "Add more": "Add more",
  "Upload a CSV": "Upload a CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Please upload a comma-seprated CSV file, with header as name, email.",
  "Here is a template CSV file": "Here is a template CSV file",
  "Upload File": "Upload File",
  "Send Invite": "Send Invite",
  "Mail Template": "Mail Template",
  "Send Test Mail": "Send Test Mail",
  Hey: "Hey",
  "A has invite you to join the B": "A has invite you to join the B",
  "Switch to rich text editor": "Switch to rich text editor",
  "Add Custom Message": "Add Custom Message",
  "Enter message here": "Enter message here",
  "Send Bulk Mail": "Send Bulk Mail",
  "Send Mail": "Send Mail",
  "Send bulk mail to all community members or select group members":
    "Send bulk mail to all community members or select Hub members",
  Date: "Date",
  Subject: "Subject",
  Recipients: "Recipients",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 bulk emails remaining for this month. Resets on <date>",
  Everyone: "Everyone",
  "Enter mail subject": "Enter mail subject",
  "Write message": "Write message",
  Cancel__1: "Cancel",
  Send: "Send",
  "Profile Page": "Profile Page",
  Activity: "Activity",
  Bookmarks: "Bookmarks",
  "Edit Profile": "Edit Profile",
  Unsubscribe: "Unsubscribe",
  "Settings (Account)": "Settings (Account)",
  Profile: "Profile",
  "Set profile Picture": "Set profile Picture",
  "Your name": "Your name",
  "User ID": "User ID",
  "Short bio": "Short bio",
  "Link your social account, this will be displayed on your profile":
    "Link your social account, this will be displayed on your profile",
  "Add social handle": "Add social handle",
  Verification: "Verification",
  "Account Verification": "Account Verification",
  Email: "Email",
  "Enter email": "Enter email",
  Verified: "Verified",
  Unverified: "Unverified",
  Change: "Change",
  Unlink: "Unlink",
  Mobile: "Mobile",
  "enter mobile no.": "enter mobile no.",
  "Request OTP": "Request OTP",
  "Notification centre": "Notification centre",
  "Manage your personal account notification preferences":
    "Manage your personal account notification preferences",
  Prefrences: "Prefrences",
  "Email notification": "Email notification",
  "Notify me about comments on my posts":
    "Notify me about comments on my posts",
  "Notify me about replies on my comments":
    "Notify me about replies on my comments",
  "Notify me about upvotes on my posts": "Notify me about upvotes on my posts",
  "Notify me about reaction on my comments":
    "Notify me about reaction on my comments",
  "Notify me about reaction on my reply":
    "Notify me about reaction on my reply",
  "Notify me about direct message": "Notify me about direct message",
  "Notify me about my mentions": "Notify me about my mentions",
  "Notification for new posts": "Notification for new posts",
  "Only group & section available which you are part of":
    "Only Hub & Space available which you are part of",
  "Group & Section": "Hub & Space",
  "All activity": "All activity",
  "Post by admin": "Post by admin",
  "No Notification": "No Notification",
  "Setting (Community)": "Setting (Community)",
  "General Settings": "General Settings",
  "Upload community logo": "Upload community logo",
  "Community name": "Community name",
  "Invalid field": "Invalid field",
  "Your community address": "Your community address",
  "Fav icon": "Fav icon",
  "This will be visible on the browser tab (32px by 32px)":
    "This will be visible on the browser tab (32px by 32px)",
  TimeZone: "TimeZone",
  "Privacy Policy on login page": "Privacy Policy on login page",
  "Terms & Condition on login page": "Terms & Condition on login page",
  "Enter link here": "Enter link here",
  "Open Group Image": "Open Hub Image",
  "By default ir takes up the community banner uploaded with the communtiy":
    "By default ir takes up the community banner uploaded with the communtiy",
  "Community Experience": "Community Experience",
  Onboarding: "Onboarding",
  "Make your community private": "Make your community private",
  "Users will have to login or sign up to access the community":
    "Users will have to login or sign up to access the community",
  "Make account verification mandatory for both email & mobile":
    "Make account verification mandatory for both email & mobile",
  "New user will have to verify both email & mobile no. to access the community":
    "New user will have to verify both email & mobile no. to access the community",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Admin will also have to verify their mobile no. & email or else they won't be able to access the community",
  "Gated community access": "Gated community access",
  "Admin will have to approve or reject user's requests to join the community":
    "Admin will have to approve or reject user's requests to join the community",
  "Disable mobile login/register": "Disable mobile login/register",
  "Disable email login/register": "Disable email login/register",
  "Disable google login/register": "Disable google login/register",
  "Disable facebook login/register": "Disable facebook login/register",
  "Show & Hide features": "Show & Hide features",
  "Hide direct chat": "Hide direct chat",
  "User will not be able to send direct messages to each other":
    "User will not be able to send direct messages to each other",
  "Hide profile picture from top right corner for mobile responsive view":
    "Hide profile picture from top right corner for mobile responsive view",
  "Useful when using community in an in app browser":
    "Useful when using community in an in app browser",
  "Hide profile picture from top right corner in every view":
    "Hide profile picture from top right corner in every view",
  "Disable header dropdown on profile picture":
    "Disable header dropdown on profile picture",
  "Useful for integrated community, especially using SSO":
    "Useful for integrated community, especially using SSO",
  "Hide the communtiy logo from the header":
    "Hide the communtiy logo from the header",
  "Hide the communtiy title from the header":
    "Hide the communtiy title from the header",
  "Show collpased group in the side nav bar":
    "Show collpased Hub in the side nav bar",
  "Show full expanded post in the home feed":
    "Show full expanded post in the home feed",
  "Hide the leaderboard score from the header":
    "Hide the leaderboard score from the header",
  "Show powered by Pensil": "Show powered by Pensil",
  "Hide closed groups if not joined": "Hide closed Hubs if not joined",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Closed Hubs would not be shown to the user if they are not a member of the Hub",
  "Hide Community subscriber count from the users":
    "Hide Community subscriber count from the users",
  "This will hide the community subscriber list in the community header":
    "This will hide the community subscriber list in the community header",
  "Disable Communtiy Switcher": "Disable Communtiy Switcher",
  "Use MD editor for create post description":
    "Use MD editor for create post description",
  "Non logged in user will land on explore group page instead of post directory":
    "Non logged in user will land on explore Hub page instead of post directory",
  "Advanced configurable": "Advanced configurable",
  "Communtiy default country code": "Communtiy default country code",
  "This will show up by default on login/signup":
    "This will show up by default on login/signup",
  "Onboarding/Welcome Message": "Onboarding/Welcome Message",
  "Welcome Message": "Welcome Message",
  "Greet your members with personalized message":
    "Greet your members with personalized message",
  "Welcome to": "Welcome to",
  Message: "Message",
  "Add message": "Add message",
  "Custom domain": "Custom domain",
  "Add custom domain": "Add custom domain",
  "Create new request": "Create new request",
  "Enter your custom domain": "Enter your custom domain",
  "Setup details": "Setup details",
  "Add a CNAME record in your domain pointing to this community's address":
    "Add a CNAME record in your domain pointing to this community's address",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL",
  "Step by Step help apge": "Step by Step help apge",
  "All request list": "All request list",
  "Allow admin to assign/un-assign custom points to users":
    "Allow admin to assign/un-assign custom points to users",
  "Base points for user to begin with": "Base points for user to begin with",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Base points will be updated for all the users, any changes will reflect for all the users.",
  "Allocate points for interaction": "Allocate points for interaction",
  "Upvoting post": "Upvoting post",
  "creating a comment": "creating a comment",
  "creating a reply": "creating a reply",
  "Getting upvote to their post": "Getting upvote to their post",
  "Getting comment to their post": "Getting comment to their post",
  "Getting reply to their comment": "Getting reply to their comment",
  "Creating a post": "Creating a post",
  Visiblity: "Visiblity",
  "Move Up": "Move Up",
  "Move Down": "Move Down",
  "Create embedded section": "Create embedded Space",
  "Preview area": "Preview area",
  "Theme selector": "Theme selector",
  "Community Theme": "Community Theme",
  "Select theme": "Select theme",
  "Select brand color": "Select brand color",
  "Customize your button color to match your brand":
    "Customize your button color to match your brand",
  "Select on button text color": "Select on button text color",
  "Suggestion: Keep inverted contrast against brand color":
    "Suggestion: Keep inverted contrast against brand color",
  Billing: "Billing",
  "Current plan": "Current plan",
  "You are current on Free PLan": "You are current on Free PLan",
  "Renew on: NA": "Renew on: NA",
  Select: "Select",
  "Email us at": "Email us at",
  "Compare plan": "Compare plan",
  "API Keys": "API Keys",
  "Create new key": "Create new key",
  "Total keys": "Total keys",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.",
  "Key name": "Key name",
  "Key value": "Key value",
  Action: "Action",
  "Group updated!": "Hub updated!",
  "Are you sure you want to": "Are you sure you want to",
  "Make Moderator": "Make Moderator",
  "Remove from group": "Remove from Hub",
  "Show All": "Show All",
  "Change Role": "Change Role",
  Close: "Close",
  copy: "copy",
  LINKS: "LINKS",
  "Email Id": "Email Id",
  Name: "Name",
  "Search for an image": "Search for an image",
  "Crop Image": "Crop Image",
  "No photos found": "No photos found",
  Community: "Community",
  "Banner ratio - 16:9": "Banner ratio - 16:9",
  "Banner Ratio - 16:6": "Banner Ratio - 16:6",
  "Share on mail": "Share on mail",
  "Share on Telegram": "Share on Telegram",
  "Share on Whatsapp": "Share on Whatsapp",
  "Share on Linkedin": "Share on Linkedin",
  "Share on Facebook": "Share on Facebook",
  "Share on Twitter": "Share on Twitter",
  "Pin Post": "Pin Post",
  "Heading (optional)": "Heading (optional)",
  "Select section": "Select Space",
  Gif: "Gif",
  "Create Custom Widget": "Create Custom Widget",
  "Are you sure you want to delete this widget?":
    "Are you sure you want to delete this widget?",
  "Go to event": "Go to event",
  "Add Tags": "Add Tags",
  "Create Tag": "Create Tag",
  "Title (Optional)": "Title (Optional)",
  "Search Gif": "Search Gif",
  "What's on your mind": "What's on your mind",
  "Looks like nothing available here right now.":
    "Looks like nothing available here right now.",
  "Try exploring other tabs": "Try exploring other tabs",
  "Delete Widget": "Delete Widget",
  "Please select a section to post": "Please select a Space to post",
  "Post added successfully!": "Superrrb! Your post is now live!",
  "Failed to create post. Please check post content and retry again.":
    "Failed to create post. Please check post content and retry again.",
  "Are you sure you want to discard current post?":
    "Are you sure you want to discard current post?",
  "By default it will take up the image uploaded with the post":
    "By default it will take up the image uploaded with the post",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Quiz can be only viewed and attempted from mobile device right now!",
  "Community Banner": "Community Banner",
  "Change Cover": "Change Cover",
  "Suggestions: Welcome to my community etc":
    "Suggestions: Welcome to my community etc",
  "(edited)": "(edited)",
  "Aspect ratio (recommended)": "Aspect ratio (recommended)",
  "Banner details updated!": "Banner details updated!",
  "Refresh if changes are not visible!": "Refresh if changes are not visible!",
  "Banner details could not be updated!":
    "Banner details could not be updated!",
  "Post deleted": "Post deleted",
  "Post not available. It may have been deleted.":
    "Post not available. It may have been deleted.",
  Admin: "Admin",
  Moderator: "Moderator",
  "Bookmark added!": "Bookmark added!",
  "Bookmark removed": "Bookmark removed",
  "Failed performing action!": "Failed performing action!",
  "Copy this link to share on other platforms":
    "Copy this link to share on other platforms",
  "View this event": "View this event",
  Event: "Event",
  "Join Meeting": "Join Meeting",
  "The live meeting has ended": "The live meeting has ended",
  "read more...": "read more...",
  "Load more comments": "Load more comments",
  "Post Reply": "Post Reply",
  "Post Comment": "Post Comment",
  more: "more",
  upvoted: "upvoted",
  "1 Upvote": "1 Upvote",
  "1 Comment": "1 Comment",
  "Please enter a title": "Please enter a title",
  "Please enter a url link": "Please enter a url link",
  "Please enter button title": "Please enter button title",
  "Button label must be at least 20 characters":
    "Button label must be at least 20 characters",
  "Please enter valid url": "Please enter valid url",
  "Suggestions: Know more, Join, Apply...":
    "Suggestions: Know more, Join, Apply...",
  URL: "URL",
  "Community Events": "Community Events",
  "Widget deleted successfully": "Widget deleted successfully",
  "Community Top Contributors": "Community Top Contributors",
  points: "points",
  "Points cannot be greater than current points":
    "Points cannot be greater than current points",
  "Point cannot be a negative value": "Point cannot be a negative value",
  "Point cannot be a decimal point": "Point cannot be a decimal point",
  "Please enter a valid number": "Please enter a valid number",
  "Karma points updated successfully": "Super Coins updated successfully",
  "Error in updating karma points": "Error in updating Super Coins",
  "Add karma points": "Add Super Coins",
  "Minus karma points": "Minus Super Coins",
  "Total Karma points:": "Total Super Coins:",
  "Enter no. of karma points": "Enter no. of Super Coins",
  "Minus Points": "Minus Points",
  "Trending Post": "Trending Post",
  "No visible description": "No visible description",
  "Picture ratio: 1:1": "Picture ratio: 1:1",
  "home feed": "home feed",
  "Event name is invalid!": "Event name is invalid!",
  custom: "custom",
  "Custom widget updated successfully": "Custom widget updated successfully",
  "Name invalid!": "Name invalid!",
  "Current timezone:": "Current timezone:",
  "can be updated from community settings":
    "can be updated from community settings",
  "Event created successfully": "Event created successfully",
  "Error in creating event": "Error in creating event",
  Attendees: "Attendees",
  "Do you wish to notify these changes to attendees?":
    "Do you wish to notify these changes to attendees?",
  "users wants to attend this event": "users wants to attend this event",
  "Yes, Notify them": "Yes, Notify them",
  "No, its okay": "No, its okay",
  "Do you wish to notify that event is cancelled?":
    "Do you wish to notify that event is cancelled?",
  "Are you sure you want to delete the event?":
    "Are you sure you want to delete the event?",
  "Delete Event": "Delete Event",
  "New Event": "New Event",
  "Accept Invitation": "Accept Invitation",
  Requested: "Requested",
  Declined: "Declined",
  "Request Access": "Request Access",
  "Enroll for": "Enroll for",
  Housefull: "Housefull",
  "Just copy paste this code wherever you want it to be embedded:":
    "Just copy paste this code wherever you want it to be embedded:",
  Ended: "Ended",
  "Error getting events": "Error getting events",
  "Events could not be fetched!": "Events could not be fetched!",
  "Show Attendees": "Show Attendees",
  Subscribers: "Subscribers",
  "Make this a closed section": "Make this a closed Space",
  "Update Section": "Update Space",
  "Valid section name required!": "Valid Space name required!",
  "Enter section name": "Enter Space name",
  "Community Admin": "Community Admin",
  Embed: "Embed",
  "Invite to": "Invite to",
  "Please enter a valid email address": "Please enter a valid email address",
  "Invitation sent successfully": "Invitation sent successfully",
  "Error sending invitation": "Error sending invitation",
  "Email is not valid!": "Email is not valid!",
  "Data could not be loaded!": "Data could not be loaded!",
  "Posted in": "Posted in",
  "Invite Members Individually": "Invite Members Individually",
  "Start a discussion": "Start a discussion",
  "Post something": "Post something",
  "Invite your audience": "Invite your audience",
  "Choose Plan": "Choose Plan",
  or: "or",
  "Create a link": "Create a link",
  "Add link": "Add link",
  "Update link": "Update link",
  "Add shortcut to your most used tools":
    "Add shortcut to your most used tools",
  "Your website, Instagram Channel, Facebook profile etc":
    "Your website, Instagram Channel, Facebook profile etc",
  "Valid link name required!": "Valid link name required!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Valid url required, and make sure you are not missing http:// or https://",
  "Invite to__1": "Invite to",
  "Link Name": "Link Name",
  "Link URL": "Link URL",
  "Could not subscribe to": "Could not subscribe to",
  "Subscribed to": "Subscribed to",
  "By default it will take up the community banner uploaded with the community":
    "By default it will take up the community banner uploaded with the community",
  "Created by": "Created by",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Online Event (Meeting link will be visible once registered for the event)",
  "Join Call": "Join Call",
  "Please login to book this event": "Please login to book this event",
  "Commented on": "Commented on",
  "Replied to": "Replied to",
  "Looks like it's lonely here.": "Looks like it's lonely here.",
  "Try exploring other sections and groups.":
    "Try exploring other Spaces and Hubs.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Please proceed with the payment or contact admin to gain access to this Hub.",
  "This is a closed group You'll need access to this group.":
    "This is a closed Hub You'll need access to this Hub.",
  "You have no bookmarks right now.": "You have no bookmarks right now.",
  "The user hasn't posted anything yet publicly.":
    "The user hasn't posted anything yet publicly.",
  "No activity to show.": "No activity to show.",
  "Are you sure you want to unsubscribe the community?":
    "Are you sure you want to unsubscribe the community?",
  "deals left": "deals left",
  "Something went wrong while loading posts":
    "Something went wrong while loading posts",
  "Could not unsubscribe from": "Could not unsubscribe from",
  "Unsubscribed from": "Unsubscribed from",
  "Login to view this page": "Login to view this page",
  Login: "Login",
  "There was some error while doing the action!":
    "There was some error while doing the action!",
  "Unable to delete section!": "Unable to delete Space!",
};
