import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { PrettiFy } from "../types/course/course.type";
import I18 from "./atoms/i18";
import UIcon, { UIcons } from "./uicon-component";

export const ActionType = Object.freeze({
  default: "default",
  primary: "primary",
  alert: "alert",
});

type Action = {
  label: string | null;
  onClick?: (e?: any) => void;
  actionType?: keyof typeof ActionType;
  icon?: UIcons;
  solidIcon?: boolean;
};

interface Props {
  icon?: string;
  solid?: boolean;
  onClick?: (e: any) => {};
  className?: string;
  dropdownClassName?: string;
  actions: Array<PrettiFy<Action> | null>;
  hideOnEmpty?: boolean;
}

function IconMenu2({
  icon,
  onClick,
  solid = false,
  className,
  hideOnEmpty,
  actions,
  dropdownClassName,
}: Props) {
  const [active, setActive] = useState<Boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setActive(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const itemList = actions.filter((i) => i?.label);

  useEffect(() => {
    if ((itemList.length === 0 && hideOnEmpty) || !active) {
      return;
    }

    const closeMenuListener = async (e: any) => {
      setActive(false);
    };

    window.document.body.addEventListener("click", closeMenuListener);

    return () => {
      window.document.body.removeEventListener("click", closeMenuListener);
    };
  }, [itemList.length, hideOnEmpty, active]);

  // Hide if there is not item in list
  if (itemList.length === 0 && hideOnEmpty) {
    return <></>;
  }

  return (
    <div
      ref={ref}
      className={cx("IconMenu", {
        active,
      })}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick != null) {
          onClick(e);
        }
      }}>
      <div className={"flex items-center place-content-center"}>
        <span
          className={cx({
            "cursor-not-allowed": itemList.length === 0,
          })}
          onClick={(e) => {
            e.preventDefault();
            if (itemList.length !== 0) {
              setActive(!active);
            }
          }}>
          <UIcon
            icon={icon ?? ("menu-dots-vertical" as any)}
            solid={solid}
            className={cx(
              "h-10 w-10 hover:shadow flex items-center place-content-center rounded-full cursor-pointer",
              className,
              {
                "cursor-not-allowed": itemList.length === 0,
              }
            )}
          />
          {itemList.length === 0 ? (
            <div className="dropdown border theme-bg-surface theme-border-default shadow rounded py-2">
              <div className="theme-text-subtitle-2 cursor-not-allowed">
                No actions available
              </div>
            </div>
          ) : (
            <div
              className={cx(
                "dropdown border theme-bg-surface theme-border-default shadow rounded py-2",
                dropdownClassName
              )}>
              <div className="flex flex-col">
                {actions.map((action, index) => (
                  <ActionButton
                    key={index}
                    action={action}
                    setActive={setActive}
                  />
                ))}
              </div>
            </div>
          )}
        </span>
      </div>
    </div>
  );
}
function ActionButton({ action, setActive }: { action: any; setActive: any }) {
  if (action == null || action === undefined) {
    return null;
  }
  if (
    action.label === undefined ||
    action.label === null ||
    action.label === ""
  ) {
    return null;
  }
  return (
    <button
      onClick={(e) => {
        if (action.onClick) {
          action.onClick(e);
        }
        setActive(false);
      }}
      className={cx(
        "ActionButton mx-2 p-2 px-4 theme-text-default rounded hover:theme-bg-default flex items-center space-x-4 transition-all duration-200 ease-linear",
        {
          "theme-text-default": action.actionType === ActionType.default,
          "theme-text-primary": action.actionType === ActionType.primary,
          "theme-text-danger": action.actionType === ActionType.alert,
        }
      )}>
      <UIcon icon={action.icon} solid={action.solidIcon} />
      <span className="word-breaker">{<I18>{action.label}</I18>}</span>
    </button>
  );
}

export default IconMenu2;
