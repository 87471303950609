import { useAppService } from "../../hooks/use-app-service";

function CustomWidget(widget: any) {
  return (
    <div className="theme-bg-surface rounded mt-4">
      <CustomWidgetComponent prop={widget} />
    </div>
  );
}

function CustomWidgetComponent({ prop }: any) {
  const { widget } = prop;

  const { analyticsService } = useAppService();

  return (
    <div className="border-b theme-border-default">
      <div className="">
        {widget.details.picture && (
          <img
            className=""
            src={widget.details.picture}
            alt="event"
            loading="lazy"
          />
        )}

        <div className="p-4">
          <h2 className="font-bold">{widget.details.title}</h2>
          <p className="text-sm">{widget.details.description}</p>
        </div>
      </div>

      <div className="pb-2  pl-2">
        <button
          className="px-3 py-0.5 rounded theme-bg-primary theme-border-primary theme-text-on-primary focus:outline-none border-2 font-semibold"
          onClick={(e) => {
            e.preventDefault();
            analyticsService.track("custom-widget-button-clicked")
            window.open(widget.details.buttonURL);
          }}>
          <span>{`${
            widget.details.buttonLabel && widget.details.buttonLabel !== null
              ? widget.details.buttonLabel
              : "Click Here"
          }`}</span>
        </button>
      </div>
    </div>
  );
}
export default CustomWidget;
