import cx from "classnames";
import { useEffect, useState } from "react";
import I18 from "./atoms/i18";
import IconButton from "./icon-button.component";
import { IconButtonSmall } from "./realtime/message-card.component";

export default function IconMenu({
  icon = "ellipsis",
  img = "",
  alignToRight,
  onClick = (e) => {},
  iconSmall = false,
  iconExtraSmall = false,
  iconButtonSmall = false,
  className = "",
  items = [],
  hideOnEmpty = false,
  rotate = 0,
  rotateMenu = 0,
}) {
  const [active, setActive] = useState(false);

  const closeMenu = () => {
    setActive(false);
  };

  const itemList = items.filter((i) => i.label);

  useEffect(() => {
    if ((itemList.length === 0 && hideOnEmpty) || !active) {
      return;
    }

    const closeMenuListener = async (e) => {
      await setActive(false);
    };

    window.document.body.addEventListener("click", closeMenuListener);

    return () => {
      window.document.body.removeEventListener("click", closeMenuListener);
    };
  }, [itemList.length, hideOnEmpty, active]);

  // Hide if there is not item in list
  if (itemList.length === 0 && hideOnEmpty) {
    return <></>;
  }

  return (
    <div
      className={cx("IconMenu", className, {
        active,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}>
      {iconButtonSmall ? (
        <IconButtonSmall
          rotate={rotate}
          icon={icon}
          img={img}
          onClick={(e) => {
            e.preventDefault();
            if (itemList.length !== 0) {
              setActive(!active);
            }
          }}
          className={cx({
            "cursor-not-allowed": itemList.length === 0,
          })}
        />
      ) : iconExtraSmall ? (
        <div
          className={cx("realtimepost-option p-1 rounded")}
          onClick={(e) => {
            e.preventDefault();
            if (itemList.length !== 0) {
              setActive(!active);
            }
          }}>
          <img
            src={icon === "img" ? img : icon}
            alt={""}
            style={{
              transform: "rotate(" + rotate + "deg)",
            }}
            className="default-icon w-2.5 h-2.5"
          />
        </div>
      ) : (
        <IconButton
          rotate={rotate}
          icon={icon}
          iconSmall={iconSmall}
          onClick={(e) => {
            e.preventDefault();
            if (itemList.length !== 0) {
              setActive(!active);
            }
          }}
          className={cx({
            "cursor-not-allowed": itemList.length === 0,
          })}
        />
      )}
      {itemList.length === 0 ? (
        <div className="dropdown w-46 border theme-bg-surface theme-border-default shadow rounded py-2">
          <div className="theme-text-subtitle-2 cursor-not-allowed">
            <I18>No actions available</I18>
          </div>
        </div>
      ) : (
        <div
          style={{ right: alignToRight ? "unset" : 0 }}
          className="dropdown w-40 theme-bg-surface border theme-border-default shadow rounded py-2">
          {itemList.map((item, index) =>
            item.labelIcon ? (
              <div
                key={index}
                className="dropdown-item flex items-center cursor-pointer"
                onClick={(e) => {
                  item.onClick(e, closeMenu());
                }}>
                <div
                  className={cx("realtimepost-option p-2 rounded")}
                  onClick={(e) => {
                    e.preventDefault();
                    if (itemList.length !== 0) {
                      setActive(!active);
                    }
                  }}>
                  <img
                    src={item.labelIcon === "img" ? img : item.labelIcon}
                    alt={""}
                    style={{
                      transform: item.rotateMenu
                        ? "rotate(" + item.rotateMenu + "deg)"
                        : "",
                    }}
                    className="default-icon w-4 h-4"
                  />
                </div>
                <div
                  className={
                    typeof item.labelClass === "string" ? item.labelClass : ""
                  }
                  key={index}>
                  <I18>{item.label}</I18>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className={
                  "dropdown-label " +
                  (typeof item.labelClass === "string" ? item.labelClass : "")
                }
                onClick={(e) => {
                  item.onClick(e, closeMenu());
                }}>
                <I18>{item.label}</I18>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}
