import styled from "styled-components";

export default function SectionNewPostMarker({ onClick, newPostCount }) {
  if (!newPostCount) {
    return <></>;
  }

  return (
    <SectionNewPostMarkerWrapper onClick={onClick}>
      <div className="theme-bg-primary text-white -mt-5 px-10 py-2 rounded-full">
        {newPostCount} new posts
      </div>
    </SectionNewPostMarkerWrapper>
  );
}

const SectionNewPostMarkerWrapper = styled.div`
  cursor: pointer;
  position: fixed;
  left: calc(50% - 100px);
  top: 8rem;
  width: 200px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
