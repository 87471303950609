import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class PaymentPreferenceService {
  /**
   * Update the community payment preference
   * @param {string} communityId
   * @param {any} payload {name, accessibleGroups, accessibleCourses}
   */
  static async updatePreference(communityId: string, payload: any) {
    return axios
      .post(
        endpoints.paymentPreference.updatePaymentPreference(communityId),
        payload,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get community payment gateway preference
   * @param communityId
   */
  static async getPreference(communityId: string) {
    return axios
      .get(endpoints.paymentPreference.getPaymentPreference(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update the community invoice preference
   * @param {string} communityId
   * @param {any} payload
   */
  static async updateInvoicePreference(communityId: string, payload: any) {
    return axios
      .post(
        endpoints.paymentPreference.updateInvoicePreference(communityId),
        payload,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get community invoice preference
   * @param communityId
   */
  static async getInvoicePreference(communityId: string) {
    return axios
      .get(endpoints.paymentPreference.getInvoicePreference(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
}

export { PaymentPreferenceService };
