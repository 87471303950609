import { createContext } from "react";
import { useAppSelector } from "../hooks/redux.hook";
import { getCommunityAddress } from "../_config/helper.config";
import { AnalyticsService } from "../_service";

export type AppService = {
  analyticsService: AnalyticsService;
};

const AppServiceContext = createContext<AppService>(null as any);
AppServiceContext.displayName = "AppServiceContext";

export { AppServiceContext };

const AppServiceProvider = ({ children }: any) => {
  const { user, community } = useAppSelector((state) => {
    return {
      user: state.auth,
      community: state.community,
    };
  });
  const analyticsService = AnalyticsService.Instance({
    communityId: community?.id,
    communityName: community?.name,
    user: {
      name: user?.name,
      email: user?.email,
      mobile: user?.mobile,
    },
    communityUrl: community && getCommunityAddress(community),
  });

  return (
    <AppServiceContext.Provider value={{ analyticsService }}>
      {children}
    </AppServiceContext.Provider>
  );
};

export default AppServiceProvider;
