import {
  ADD_CHAT_IF_NEEDED,
  GET_ALL_DIRECT_CHATS,
  GET_ALL_DIRECT_CHATS_FAILURE,
  GET_ALL_DIRECT_CHATS_SUCCESS,
  MARK_CHAT_AS_READ,
} from "../_actions/direct-message.actions";

const defaultState = {
  isLoading: false,
  isInitialLoadComplete: false,
  error: null,
  users: [],
};

export function directMessageReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_DIRECT_CHATS:
      return {
        ...state,
        isLoading: true,
        isInitialLoadComplete: true,
        error: null,
        users: [],
      };
    case GET_ALL_DIRECT_CHATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        users: action.users,
      };
    case GET_ALL_DIRECT_CHATS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        users: [],
      };
    case ADD_CHAT_IF_NEEDED: {
      let user = state.users.find(
        (u) => u !== undefined && u.id === action.user.id
      );
      return {
        ...state,
        users: user !== undefined ? state.users : [...state.users, action.user],
      };
    }
    case MARK_CHAT_AS_READ: {
      return {
        ...state,
        users: state.users.map((currentChat) => {
          if (currentChat.id === action.chat.id) {
            return {
              ...currentChat,
              unreadMessageCount: 0,
            };
          } else {
            return currentChat;
          }
        }),
      };
    }
    default:
      return state;
  }
}
