import axios from "axios";
import { endpoints } from "../_config";

class MentionService {
  /**
   * search username in community
   * @param {*} postId
   * @returns
   */
  static async searchInCommunity(user, communityId, query) {
    return axios
      .get(endpoints.mentions.searchInCommunity(communityId, query), {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => response.data);
  }
}

export { MentionService };
