import cx from "classnames";

export function Radio({
  children,
  className = "",
  selected = false,
  disabled = false,
  onClick = (e) => {},
}) {
  return (
    <div>
      <div
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        className={cx("Radio  " + className, {
          selected: selected,
          "cursor-pointer": !disabled,
          "cursor-not-allowed": disabled,
        })}>
        <span
          className={cx(
            "radio-comp-1 relative transition-all duration-300 ease-linear",
            {
              "theme-border-primary": selected,
              "theme-border-subtitle-1 ": !selected,
              "theme-border-subtitle-2": disabled,
              "hover:theme-border-primary": !disabled,
            }
          )}>
          <span
            className={cx(
              "radio-comp-2 transition-all duration-300 ease-linear left-auto right-auto top-auto bottom-auto absolute",
              {
                "theme-bg-primary": selected,
                "theme-border-subtitle-2": disabled,
              }
            )}></span>
        </span>
        {children}
      </div>
    </div>
  );
}
