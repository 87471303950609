import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class GroupService {
  /**
   * Create a new group
   * @param {*} user
   * @param {*} group
   * @returns
   */
  static async createGroup(user, group) {
    return axios
      .post(endpoints.group.all, group, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Create a new group
   * @param {*} user
   * @param {*} group
   * @returns
   */
  static async updateGroup(user, groupId, group) {
    return axios
      .post(endpoints.group.updateGroup(groupId), group, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete a group
   * @param {*} user
   * @param {*} group
   * @returns
   */
  static async deleteGroup(user, groupId) {
    return axios
      .delete(endpoints.group.deleteGroup(groupId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async uploadBanner(user, groupId = "", banner) {
    const formData = new FormData();
    formData.append("banner", banner);

    return axios
      .post(endpoints.group.uploadBanner(groupId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Add a new group tab
   * @param {*} user
   * @param {*} groupId
   * @param {*} tab
   * @returns
   */
  static async createGroupTab(user, groupId, tab) {
    return axios
      .post(endpoints.group.addTab(groupId), tab, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Edit group tab
   * @param {*} user
   * @param {*} groupId
   * @param {*} tab
   * @returns
   */
  static async editGroupTab(user, groupId, tabId, tab) {
    return axios
      .post(endpoints.group.editTab(groupId, tabId), tab, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete group tab
   * @param {*} user
   * @param {*} groupId
   * @param {*} tab
   * @returns
   */
  static async deleteGroupTab(user, groupId, tabId) {
    return axios
      .delete(endpoints.group.deleteTab(groupId, tabId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get all groups
   * @param {*} user
   * @returns
   */
  static async getAllGroups(user) {
    return axios
      .get(endpoints.group.all, {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get the groups by user
   * @param {User} user
   */
  static async groupsByMe(user) {
    return axios
      .get(endpoints.group.byMe, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get the personal group by user
   * @param {User} user
   */
  static async getPersonalGroup(user) {
    return axios
      .get(endpoints.group.personalGroup, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Return group details
   * @param {*} groupId
   * @returns
   */
  static async groupDetail(groupId) {
    return axios
      .get(endpoints.group.all + "/" + groupId, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Return group details
   * @param {*} user
   * @param {*} communityId
   * @param {*} slug
   * @returns
   */
  static async groupDetailFromSlug(communityId, slug) {
    return axios
      .get(endpoints.group.getGroupFromSlug(communityId, slug), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get list of all group members
   * @param {AbortController} abortController
   * @param {*} groupId
   * @returns
   */
  static async groupMembers(abortController, groupId, selectedTabId) {
    return axios
      .get(endpoints.group.members(groupId, selectedTabId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
        signal: abortController.signal,
      })
      .then((response) => response.data);
  }
  static async groupMembersPaginated(
    abortController,
    groupId,
    selectedTabId,
    page = 1,
    limit = 20,
    search = ""
  ) {
    return axios
      .get(
        endpoints.group.membersPaginated(
          groupId,
          selectedTabId,
          page,
          limit,
          search
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
          signal: abortController.signal,
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get list of all closed section members
   * @param {*} groupId
   * @param {*} selectedTabId
   * @param {AbortController} abortController
   * @returns
   */
  static async groupClosedSectionMembers(
    abortController,
    groupId,
    selectedTabId
  ) {
    return axios
      .get(endpoints.group.closedSectionMembers(groupId, selectedTabId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
        signal: abortController.signal,
      })
      .then((response) => response.data);
  }
  static async groupClosedSectionMembersPaginated(
    abortController,
    groupId,
    selectedTabId,
    page = 1,
    limit = 20,
    search = ""
  ) {
    return axios
      .get(
        endpoints.group.closedSectionMembersPaginated(
          groupId,
          selectedTabId,
          page,
          limit,
          search
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
          signal: abortController.signal,
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get group leaderboard score
   * @param {string} groupId - group id
   * @param {number} page - page
   * @param {limit} limit - limit
   */
  static async getLeaderboardV2Paginated(groupId, page, limit) {
    return axios.get(endpoints.group.getLeaderboardV2Paginated(groupId, page, limit),  {
      headers: { Authorization: "Bearer " + getUserTokenFromStorage() },
    }).then((response) => response.data);
  }

  /**
   * Update the role of a user in group
   * @param {*} user
   * @param {*} groupId
   * @param {*} userId
   * @param {*} role
   * @returns
   */
  static async updateUserRole(user, groupId, userId, role = "user") {
    // userId is the member id
    return axios
      .post(
        endpoints.group.updateRole(groupId, userId),
        { role },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * remove user from a  group
   * @param {*} user
   * @param {*} groupId
   * @param {*} userId
   * @param {*} role
   * @returns
   */
  static async removeUser(user, groupId, userId) {
    // userId is the member id
    return axios
      .post(
        endpoints.group.removeUser(groupId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get list of all group posts
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async groupPosts(user, groupId) {
    return axios
      .get(endpoints.group.posts(groupId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get list of all group posts
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async sectionPostsPaginated(groupId, sectionId, page = 1) {
    return axios
      .get(endpoints.group.sectionPostsPaginated(groupId, sectionId, page), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get list of all pinned posts
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async sectionPostsPinned(groupId, sectionId) {
    return axios
      .get(endpoints.group.sectionPostsPinned(groupId, sectionId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get list of all group posts
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async sectionMessagesPaginated(user, groupId, sectionId, page = 1) {
    return axios
      .get(endpoints.group.sectionMessagesPaginated(groupId, sectionId, page), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get list of all relatime message
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async sectionMessagesTimestampPaginated(
    user,
    groupId,
    sectionId,
    lastId = null
  ) {
    return axios
      .get(
        endpoints.group.sectionMessagesTimestampPaginated(
          groupId,
          sectionId,
          lastId
        ),
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  // requests

  /**
   * Accept Invitation
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async acceptInvitation(user, groupId) {
    return axios
      .post(
        endpoints.group.acceptInvitation(groupId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Accept User Request
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async acceptUserRequest(user, groupId, userId) {
    return axios
      .post(
        endpoints.group.acceptJoinRequest(groupId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Reject User Request
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async rejectJoinRequest(user, groupId, userId) {
    return axios
      .post(
        endpoints.group.rejectJoinRequest(groupId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Join group
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async joinGroup(user, groupId) {
    return axios
      .post(
        endpoints.group.join(groupId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Join group
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async leaveGroup(user, groupId) {
    return axios
      .post(
        endpoints.group.leave(groupId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async verifyToken(user, groupId, code) {
    return axios
      .get(endpoints.group.verifyCouponCode(groupId, code), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Create order for closed paid group
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async createOrder(user, groupId, code) {
    return axios
      .post(
        endpoints.group.createOrder(groupId),
        {
          code,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Verify order for closed paid group
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async verifyPayment(user, groupId, paymentId) {
    return axios
      .post(
        endpoints.group.verifyPayment(groupId, paymentId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  // Closed Section Related
  static async joinClosedSection(user, groupId, sectionId) {
    return axios
      .post(
        endpoints.group.joinClosedSection(groupId, sectionId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Accept user in closed section
   * @param {*} user
   * @param {*} groupId
   * @param {*} sectionId
   * @param {*} userId
   * @returns
   */
  static async acceptJoinClosedSectionRequest(
    user,
    groupId,
    sectionId,
    userId
  ) {
    return axios
      .post(
        endpoints.group.acceptJoinClosedSectionRequest(
          groupId,
          sectionId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Reject user in closed section
   * @param {*} user
   * @param {*} groupId
   * @param {*} sectionId
   * @param {*} userId
   * @returns
   */
  static async rejectJoinClosedSectionRequest(
    user,
    groupId,
    sectionId,
    userId
  ) {
    return axios
      .post(
        endpoints.group.rejectJoinClosedSectionRequest(
          groupId,
          sectionId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get group trending posts
   * @param {*} groupId
   * @param {*} cancelToken
   * @returns {Promise<any>}}
   */
  static getTrendingPosts(groupId) {
    return axios
      .get(endpoints.group.trendingPosts(groupId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
}

export { GroupService };
