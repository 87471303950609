import { Link } from "react-router-dom";
import { Button } from "../../../../_components";
import Label from "../../../../_components/typography/label.typography";
import PrefixedLink from "../../../../_components/prefixed-link";

/**
 * Displays message to link paywall if no Paywall is linked to community
 */
export default function LinkPaywallView() {
  return (
    <div className="theme-bg-surface p-2">
      <div className="flex flex-col items-center justify-center">
        {/* Header */}
        <div className="flex items-center gap-2 place-content-between theme-bg-default p-4 w-full">
          <div className="flex flex-col">
            <Label>
              Please link your bank account or razorpay account to receive the
              payment in your account.
            </Label>
            <Label variant="t1">
              Contact <a href="mailto:anil@pensil.in">anil@pensil.in</a> to know
              more
            </Label>
          </div>
          <PrefixedLink to="/settings/paywall/payment">
            <Button
              outlined
              label="Paywall Settings"
              className="word-breaker"
            />
          </PrefixedLink>
        </div>
      </div>
    </div>
  );
}
