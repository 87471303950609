import { SET_COMMUNITY_PAC } from "../_actions/community_pac.actions";

const defaultState = {
  liveSession: true,
  paidGroup: true,
  sso: true,
  analytics: true,
  customDomain: true,
  embedSection: true,
  welcomeMessage: true,
  leaderboard: true,
  communityCustomization: true,
  zapierIntegration: true,
  hidePoweredByPensil: true,
  favicon: true,
  whiteLabelMobileApp: true,
  linkPaymentGateway: true,
  moderation: true,
  bulkEmailing: true,
  bulkImport: true,
  bulkExport: true,
  apiKeysAccess: true,
  customCodeSnippets: true,
};

/**
 * Community Pac Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function communityPacReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_COMMUNITY_PAC:
      return action.pac;
    default:
      return state;
  }
}
