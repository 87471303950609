import { CREATE_SESSION, DESTROY_SESSION } from "../_actions/auth.actions";

/**
 * Main Auth Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function authReducer(state = null, action) {
  switch (action.type) {
    case CREATE_SESSION:
      return action.user;
    case DESTROY_SESSION:
      return null;
    default:
      return state;
  }
}
