import cx from "classnames";

function Card({
  children,
  padding = true,
  className = "",
  style = {},
  onClick = (e) => {},
}) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={cx("Card theme-bg-default rounded shadow " + className, {
        "p-4": padding,
      })}>
      {children}
    </div>
  );
}

export { Card };
