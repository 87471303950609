import { useAppDispatch } from "../../hooks/redux.hook";
import { User } from "../../types/user/minimal-user.type";
import { setCurrentSectionPosts } from "../../_store/sections.store";
import I18 from "../atoms/i18";
import { ListView } from "../molecule/listview.component";
import PostListShimmer, {
  PostTileType,
} from "../shimmers/post/post-list-shimmer.component";
import SquarePostCard from "./square-post-card-component";

interface Props {
  posts: any[];
  user: User;
  isLoading: boolean;
  footerElement?: any;
}
/**
 * @description Display minimal post with cover photo in a grid
 * @param {Array<Object>} posts posts list
 * @param {Object} user user object
 * @param {Boolean} isLoading flag to check if posts are loading
 * @param {ReactElement} footerElement footer element
 */
export default function GridPostsList({
  posts,
  user,
  isLoading,
  footerElement,
}: Props) {
  const dispatch = useAppDispatch();
  return (
    <ListView
      items={posts}
      className="grid grid-cols-1 sm:grid-cols-2 gap-3 px-2 sm:px-0 mt-2"
      renderItem={(post, index) => (
        <SquarePostCard
          key={post.id}
          post={post}
          user={user}
          updatePost={(post: any) => {
            // get post index
            const postIndex = posts.findIndex((p: any) => p.id === post.id);
            const oldPosts = [...posts];
            oldPosts.splice(postIndex, 1, post);
            dispatch(setCurrentSectionPosts(oldPosts));
          }}
          deletePost={(post: any) => {
            // get post index
            const postIndex = posts.findIndex((p: any) => p.id === post.id);
            const oldPosts = [...posts];
            oldPosts.splice(postIndex, 1);
            dispatch(setCurrentSectionPosts(oldPosts));
          }}
        />
      )}
      loading={isLoading}
      footerElement={footerElement}
      noItemsElement={
        <div className="text-center mt-20 font-black">
          <I18>Start a discussion</I18>
        </div>
      }
      placeholder={
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-2 sm:px-0 PostList  p-2 rounded my-2 ">
          {Array.from({ length: 6 }).map((_, i) => (
            <PostListShimmer key={i} length={1} type={PostTileType.compact} />
          ))}
        </div>
      }
    />
  );
}
