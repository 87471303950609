import { GroupService } from "../../../_service";

/**
 * @description - Display Accept/Reject/Remove button for closed section
 * @param {Object} member - The member object
 * @param {string} groupId - GroupId
 * @param {string} activeTabId - Active section id.
 * @param {Object} user - The auth user object.
 * @param {function} onAccept - Callback to accept member
 * @param {function} updateGroupMember - Callback to update member
 */
export default function AcceptRejectSectionMember({
  member,
  groupId,
  activeTabId,
  user,
  updateGroupMember = (e) => {},
}) {
  if (member.tabStatus === "requested") {
    return (
      <div className="AcceptRejectSectionMember flex items-center">
        {/* show accept or reject */}
        <span
          onClick={(e) => {
            // call api to accept user in group
            GroupService.acceptJoinClosedSectionRequest(
              user,
              groupId,
              activeTabId,
              member.id
            ).then(({ group }) => {
              // update the current memeber
              updateGroupMember({
                ...member,
                tabStatus: "joined",
              });
            });
          }}
          className="theme-text-primary px-1 text-xs">
          Accept
        </span>
        <span
          onClick={(e) => {
            // call api to accept user in group
            GroupService.rejectJoinClosedSectionRequest(
              user,
              groupId,
              activeTabId,
              member.id
            ).then(({ group }) => {
              // update the current memeber
              updateGroupMember({
                ...member,
                tabStatus: null,
              });
            });
          }}
          className="theme-text-danger px-1 text-xs">
          Reject
        </span>
      </div>
    );
  }

  // // show remove member button
  // if (member.id !== user.id && member.tabStatus === "joined") {
  //   return (
  //     <div className="AcceptRejectSectionMember flex items-center">
  //       <span
  //         onClick={(e) => {
  //           // call api to accept user in group
  //           GroupService.rejectJoinClosedSectionRequest(
  //             user,
  //             groupId,
  //             activeTabId,
  //             member.id
  //           ).then(({ group }) => {
  //             // update the current memeber
  //             updateGroupMember({
  //               ...member,
  //               tabStatus: null,
  //             });
  //           });
  //         }}
  //         className="theme-text-danger px-1 text-xs">
  //         Remove
  //       </span>
  //     </div>
  //   );
  // }

  // show nothing else
  return <></>;
}
