import { useContext } from "react";
import { AppService, AppServiceContext } from "../contexts/app-service-context";
import { AnalyticsService } from "../_service";

/**
 * Hook to get the app service
 * @returns {AppService} AppService
 * @example
 * const { analyticsService } = useAppService();
 */
export function useAppService(): {
  analyticsService: AnalyticsService;
} {
  const appService = useContext<AppService>(AppServiceContext as any);
  const analyticsService = appService.analyticsService;

  return {
    analyticsService,
  };
}
