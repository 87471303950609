import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class CourseService {
  /**
      ▄████████  ▄██████▄  ███    █▄     ▄████████    ▄████████    ▄████████ 
     ███    ███ ███    ███ ███    ███   ███    ███   ███    ███   ███    ███ 
     ███    █▀  ███    ███ ███    ███   ███    ███   ███    █▀    ███    █▀  
     ███        ███    ███ ███    ███  ▄███▄▄▄▄██▀   ███         ▄███▄▄▄     
     ███        ███    ███ ███    ███ ▀▀███▀▀▀▀▀   ▀███████████ ▀▀███▀▀▀     
     ███    █▄  ███    ███ ███    ███ ▀███████████          ███   ███    █▄  
     ███    ███ ███    ███ ███    ███   ███    ███    ▄█    ███   ███    ███ 
     ████████▀   ▀██████▀  ████████▀    ███    ███  ▄████████▀    ██████████ 
                                        ███    ███                           
  */
  /**
   * Get all courses
   * @param {string} communityId the community id
   * @returns {Promise<any>} the promise
   */
  static async getAllCourses(communityId) {
    return axios
      .get(endpoints.course.getAll(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Create/update course
   * @param {string} communityId the community id
   * @param {string} courseId course id is optional. If courseId is provided, the course will be updated instead of created
   * @param {Object} payload the payload to create a course. Payload should be in the following format: { title: "Course title" }
   * @returns {Promise<any>} the promise
   */
  static async createCourse(communityId, payload, courseId) {
    return axios
      .post(endpoints.course.createCourse(communityId, courseId), payload, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Upload course banner image
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {Blob} file the file to be uploaded
   * @param {Function} onUploadProgress the progress callback
   * @returns {Promise<any>} the promise
   */
  static async uploadCourseBanner(
    communityId,
    courseId,
    file,
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    formData.append("banner", file);
    return axios
      .post(
        endpoints.course.uploadCourseBanner(communityId, courseId),
        formData,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercentComplete(percentCompleted);
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get a course details
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @returns {Promise<any>} the promise
   */
  static async getCourseDetails(communityId, courseId) {
    return axios
      .get(endpoints.course.getCourseDetails(communityId, courseId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete course
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @returns {Promise<any>} the promise
   */
  static async deleteCourse(communityId, courseId) {
    return axios
      .delete(
        endpoints.course.deleteCourse(communityId, courseId),

        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get paginated course members list
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {number} page the page number to fetch. Default is 1
   * @param {number} limit the limit to fetch. Default is 10
   * @returns {Promise<any>} the promise
   */
  static async getCourseMembers(communityId, courseId, page = 1, limit = 10) {
    return axios
      .get(
        endpoints.course.paginatedMembers(communityId, courseId, page, limit),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Accept/Add user's course join request
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} userId the user id
   * @returns {Promise<any>} the promise
   */
  static async addMember(communityId, courseId, userId) {
    return axios
      .post(
        endpoints.course.addMember(communityId, courseId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Join Course
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @returns {Promise<any>} the promise
   */
  static async joinCourse(communityId, courseId) {
    return axios
      .post(
        endpoints.course.joinCourse(communityId, courseId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Reject/Remove user's course join request
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   *  @param {string} userId the user id
   * @returns {Promise<any>} the promise
   */
  static async removeMember(communityId, courseId, userId) {
    return axios
      .post(
        endpoints.course.removeMember(communityId, courseId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
       ▄████████    ▄████████  ▄████████     ███      ▄█   ▄██████▄  ███▄▄▄▄      ▄████████ 
      ███    ███   ███    ███ ███    ███ ▀█████████▄ ███  ███    ███ ███▀▀▀██▄   ███    ███ 
      ███    █▀    ███    █▀  ███    █▀     ▀███▀▀██ ███▌ ███    ███ ███   ███   ███    █▀  
      ███         ▄███▄▄▄     ███            ███   ▀ ███▌ ███    ███ ███   ███   ███        
    ▀███████████ ▀▀███▀▀▀     ███            ███     ███▌ ███    ███ ███   ███ ▀███████████ 
             ███   ███    █▄  ███    █▄      ███     ███  ███    ███ ███   ███          ███ 
       ▄█    ███   ███    ███ ███    ███     ███     ███  ███    ███ ███   ███    ▄█    ███ 
     ▄████████▀    ██████████ ████████▀     ▄████▀   █▀    ▀██████▀   ▀█   █▀   ▄████████▀  
                                                                                        
  */

  /**
   * Create section in course
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {Object} payload the payload to create a section. Payload should be in the following format: { name: "Section name", emoji: "👍" }
   * @param {string} sectionId the section id. If sectionId is provided, the section will be updated instead of created.
   * @returns {Promise<any>} the promise
   */
  static async createSection(communityId, courseId, payload, sectionId) {
    return axios
      .post(
        endpoints.course.sections.createSection(
          communityId,
          courseId,
          sectionId
        ),
        payload,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Delete course section
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @returns {Promise<any>} the promise
   */
  static async deleteSection(communityId, courseId, sectionId) {
    return axios
      .delete(
        endpoints.course.sections.deleteSection(
          communityId,
          courseId,
          sectionId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
    ▄█          ▄████████    ▄████████    ▄████████  ▄██████▄  ███▄▄▄▄   
   ███         ███    ███   ███    ███   ███    ███ ███    ███ ███▀▀▀██▄ 
   ███         ███    █▀    ███    █▀    ███    █▀  ███    ███ ███   ███ 
   ███        ▄███▄▄▄       ███          ███        ███    ███ ███   ███ 
   ███       ▀▀███▀▀▀     ▀███████████ ▀███████████ ███    ███ ███   ███ 
   ███         ███    █▄           ███          ███ ███    ███ ███   ███ 
   ███▌    ▄   ███    ███    ▄█    ███    ▄█    ███ ███    ███ ███   ███ 
   █████▄▄██   ██████████  ▄████████▀   ▄████████▀   ▀██████▀   ▀█   █▀  
   ▀                                                                     
 */

  /**
   * Create/update section in course
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {Object} payload the payload to create a section. Payload should be in the following format: { name: "Section name", }
   * @param {string} sectionId the section id.
   * @param {string} lessonId the lesson id. If lessonId is provided, the lesson will be updated instead of created.
   * @returns {Promise<any>} the promise
   */
  static async createLesson(
    communityId,
    courseId,
    sectionId,
    payload,
    lessonId
  ) {
    return axios
      .post(
        endpoints.course.sections.lesson.createLesson(
          communityId,
          courseId,
          sectionId,
          lessonId
        ),
        payload,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get lesson details
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   * @returns {Promise<any>} the promise
   */
  static async getLesson(communityId, courseId, sectionId, lessonId) {
    return axios
      .get(
        endpoints.course.sections.lesson.getLessonDetails(
          communityId,
          courseId,
          sectionId,
          lessonId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Delete section's lesson
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   * @returns {Promise<any>} the promise
   */
  static async deleteLesson(communityId, courseId, sectionId, lessonId) {
    return axios
      .delete(
        endpoints.course.sections.lesson.deleteLesson(
          communityId,
          courseId,
          sectionId,
          lessonId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Upload lesson's video
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   * @param {Object} media the media object
   * @param {Function} setPercentComplete the progress function
   * @returns {Promise<any>} the promise
   */
  static async uploadLessonCoverMedia(
    communityId,
    courseId,
    sectionId,
    lessonId,
    media,
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    formData.append("coverMedia", media);
    return axios
      .post(
        endpoints.course.sections.lesson.updateLessonCover(
          communityId,
          courseId,
          sectionId,
          lessonId
        ),
        formData,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercentComplete(percentCompleted);
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Mark lesson as complete
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   * @param {string} userId the user id
   */
  static async markLessonComplete(
    communityId,
    courseId,
    sectionId,
    lessonId,
    userId
  ) {
    return axios
      .post(
        endpoints.course.sections.lesson.markLessonComplete(
          communityId,
          courseId,
          sectionId,
          lessonId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * Mark lesson as in-complete
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   * @param {string} userId the user id
   */
  static async markLessonInComplete(
    communityId,
    courseId,
    sectionId,
    lessonId,
    userId
  ) {
    return axios
      .post(
        endpoints.course.sections.lesson.markIncomplete(
          communityId,
          courseId,
          sectionId,
          lessonId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}
export { CourseService };
