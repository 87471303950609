import { useEffect, useState } from "react";
import { loadScript, MAIN_DOMAIN_SUFFIX } from "../_config/helper.config";
import { GroupService } from "../_service";

export default function RazorPay({
  group,
  coupon,
  onError = (e) => {},
  onSuccess = (e) => {},
  onCancel = (e) => {},
  user,
}) {
  const [script, setScript] = useState(null);

  // load script
  useEffect(() => {
    // if razorpay is not already initialized
    if (!window.Razorpay) {
      // load razorpay
      loadScript("https://checkout.razorpay.com/v1/checkout.js").then(
        async (result) => {
          if (!result) {
            return alert("Could not load razorpay!");
          }

          // add script
          setScript(result);
        }
      );
    }
  }, [user]);

  useEffect(() => {
    if (script || window.Razorpay) {
      initiatePayment(user, group, coupon, onSuccess, onError, onCancel);
    }
  }, [script, group, user, coupon, onSuccess, onError, onCancel]);

  return <></>;
}

async function initiatePayment(
  user,
  group,
  coupon,
  onSuccess,
  onError,
  onCancel
) {
  // call the order api
  const { order: createdOrder } = await GroupService.createOrder(
    user,
    group.id,
    coupon ? coupon.code : undefined
  );

  // create configuration
  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    amount: createdOrder.amount.toString(),
    currency: createdOrder.currency,
    name: "Pensil Social",
    description: createdOrder.receipt,
    image: `https://app.${MAIN_DOMAIN_SUFFIX}/assets/main-logo.png`,
    order_id: createdOrder.id,
    modal: {
      ondismiss: onCancel,
    },
    handler: async function (response) {
      try {
        const data = {
          orderCreationId: createdOrder.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        // call verify api here
        const { group: newGroup } = await GroupService.verifyPayment(
          user,
          group.id,
          response.razorpay_payment_id
        );

        // return group on success
        onSuccess(newGroup);
      } catch (error) {
        onError(error);
      }
    },
    prefill: {
      name: user.name,
      email: user.email,
      contact: user.mobile,
    },
    notes: createdOrder.notes,
    theme: {
      color: "#0445FE",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
  paymentObject.on("payment.failed", onError);
}
