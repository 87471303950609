import cx from "classnames";
import I18 from "./atoms/i18";

export function FormError({ error, padding0 = false }) {
  if (!error) return null;
  return (
    <span
      className={cx("FormError text-xs theme-text-danger", {
        "px-2": !padding0 && error,
      })}>
      <I18>{error}</I18>
    </span>
  );
}
