import { useState } from "react";
import styled from "styled-components";
import { MessageService } from "../../_service/message.service";
import RealtimeSectionMessageEditor from "../group/realtime-section-detail/realtime-section-message-editor";
import { MessageListList } from "./message-list.component";

export function ThreadBox({
  user,
  replies,
  isUserAllowedToPost,
  group,
  activeTabModel,
  showReplies,
  message,
}) {
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  return (
    <ThreadBoxWrapper>
      {/* replies */}
      {showReplies ? (
        <>
          {replies ? (
            <MessageListList
              messages={replies}
              deleteMessage={(message) => {
                return MessageService.deleteMessage(user, message);
              }}
              hideUserDetails
            />
          ) : (
            <></>
          )}
          {isUserAllowedToPost ? (
            // <MessageBox
            // groupId={group.id}
            // group={group}
            // tabId={activeTabModel.id}
            // parentMessage={message}
            // attachments={selectedAttachments}
            // setAttachments={setSelectedAttachments}
            // updateMessage={(message) => {
            //   // get post index
            //   // const messageIndex = replies.findIndex((p) => p.id === message.id);
            //   // const oldMessages = [...replies];
            //   // oldMessages.splice(messageIndex, 1, message);
            //   // setReplies(oldMessages);
            // }}
            // addPost={(post) => {
            //   if (post) {
            //     // no need to add post as it will be added using event
            //     // update posts list with new post
            //     // setPosts([...posts, post]);
            //   }
            // }}
            // />
            <div className="p-3">
              <RealtimeSectionMessageEditor
                community={group.community}
                user={user}
                groupId={group.id}
                group={group}
                tabId={activeTabModel.id}
                parentMessage={message}
                attachments={selectedAttachments}
                setAttachments={setSelectedAttachments}
                updateMessage={(message) => {
                  // get post index
                  // const messageIndex = replies.findIndex((p) => p.id === message.id);
                  // const oldMessages = [...replies];
                  // oldMessages.splice(messageIndex, 1, message);
                  // setReplies(oldMessages);
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </ThreadBoxWrapper>
  );
}

const ThreadBoxWrapper = styled.div`
  padding-left: 56px;
`;
