import React, { useEffect } from "react";
import { Button, TextInput } from "../../../../_components";
import TextButton from "../../../../_components/atoms/text-button";
import DropDownSelector from "../../../../_components/compound/dopdown-selector";
import ModalForm from "../../../../_components/model2";
import Label from "../../../../_components/typography/label.typography";
import UIcon from "../../../../_components/uicon-component";
import { Countries } from "../../../../_config/states/countries";
import { IndianStates } from "../../../../_config/states/indian-states";
import { UsStates } from "../../../../_config/states/us-states";
import { BillingDetails } from "../../../../_store/subscription/my-subscription/my-subscription.reducer";
import { Fold } from "../../../../_utils/extensions/typescript-utils";
import { Plan } from "../../../group/group-subscription-checkout-modal";

interface BillingAddressProps {
  plan: any;
  user: any;
  community: any;
  existingBillingAddress?: BillingDetails;
  onProceed: (billingInfo: BillingDetails) => void;
}
/**
 * Get user's billing address before checkout
 * @param {any} plan - subscription plan
 * @param {any} user - user object
 * @param {any} community - community object
 * @param {Function} onProceed - callback function to proceed to checkout
 */
export function BillingAddress({
  plan,
  user,
  community,
  existingBillingAddress,
  onProceed,
}: BillingAddressProps) {
  const [email, setFullNameEmail] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  useEffect(() => {
    if (existingBillingAddress && Object.keys(existingBillingAddress).length) {
      setFullNameEmail(existingBillingAddress.email ?? "");
      setFullName(existingBillingAddress.fullName ?? "");
      setMobile(existingBillingAddress.mobile ?? "");
      setAddressLine1(existingBillingAddress.addressLine1 ?? "");
      setAddressLine2(existingBillingAddress.addressLine2 ?? "");
      setState(existingBillingAddress.state ?? "");
      setCountry(existingBillingAddress.country ?? "");
    } else if (user) {
      setFullNameEmail(user.email ?? "");
      setFullName(user.name ?? "");
      setMobile(user.mobile ?? "");
    }
  }, [existingBillingAddress, user]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const billingInfo = {
          email,
          fullName,
          mobile,
          addressLine1,
          addressLine2,
          state,
          country,
        };
        onProceed(billingInfo);
      }}
      className="flex flex-col gap-4 theme-bg-surface p-4 w-full">
      <Label variant="t1" size="body1">
        Billing Details
      </Label>
      <div className="flex flex-col gap-1">
        <Label variant="t1">Email Id</Label>
        <TextInput
          type="email"
          required
          defaultValue={email}
          noMargin
          placeholder="Enter email address"
          onChange={(e) => setFullNameEmail(e)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Label variant="t1">Full Name</Label>
        <TextInput
          type="text"
          noMargin
          required
          defaultValue={fullName}
          placeholder="Enter full name"
          onChange={(e) => setFullName(e)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Label variant="t1">Mobile no.</Label>
        <TextInput
          type="text"
          noMargin
          required
          defaultValue={mobile}
          placeholder="Enter mobile number"
          onChange={(e) => setMobile(e)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Label variant="t1">Country</Label>
        <DropDownSelector
          list={Countries}
          value={country}
          required
          placeholder="Select country"
          selectClassName="pl-0 pr-2"
          onStateChange={(e) => {
            setCountry(e);
            setState("");
          }}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Label variant="t1">Address Line 1</Label>
        <TextInput
          type="text"
          noMargin
          required
          defaultValue={addressLine1}
          placeholder="Enter address line 1"
          onChange={(e) => setAddressLine1(e)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Label variant="t1">Address Line 2</Label>
        <TextInput
          type="text"
          noMargin
          defaultValue={addressLine2}
          placeholder="Enter address line 2"
          onChange={(e) => setAddressLine2(e)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <Label variant="t1">State</Label>

        <Fold
          value={["India", "United States"].includes(country)}
          ifPresent={() => (
            <DropDownSelector
              list={country === "India" ? IndianStates : UsStates}
              value={state}
              required
              selectClassName="pl-0 pr-2"
              placeholder="Select state"
              onStateChange={(e) => {
                setState(e);
              }}
            />
          )}
          ifAbsent={() => (
            <TextInput
              type="text"
              noMargin
              defaultValue={state}
              required
              placeholder="Enter state"
              onChange={(e) => setState(e)}
            />
          )}
        />
      </div>
      <Button label="Proceed" onClick={() => {}} />
    </form>
  );
}

interface AddressCheckoutProps {
  plan: any;
  user: any;
  community: any;
  isVisible: boolean;
  existingBillingAddress?: BillingDetails;
  setIsVisible: (isVisible: boolean) => void;
  onProceed: (billingInfo: BillingDetails) => void;
}

/**
 * Display subscription details model
 * @param {any} plan - subscription plan
 * @param {any} user - user object
 * @param {any} community - community object
 * @param {Boolean} isVisible - is model visible
 * @param {Function} setIsVisible - set model visibility
 */
export function AddressCheckout({
  community,
  user,
  plan,
  isVisible,
  existingBillingAddress,
  setIsVisible,
  onProceed = (_) => {},
}: AddressCheckoutProps) {
  if (!community || !isVisible) {
    return null;
  }
  return (
    <ModalForm
      hideHeader
      visible={isVisible}
      setVisible={setIsVisible}
      childrenClassName="AddressCheckout p-0"
      className="w-full theme-bg-default m-4 max-w-xl md:max-w-6xl overflow-y-auto max-h-[80vh]">
      <div className="flex flex-col place-content-center items-center md:items-start md:flex-row gap-2 min-w-full  theme-bg-default p-4 py-10 rounded">
        <section className="w-full md:w-1/2 flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Fold
              value={community.logo}
              ifPresent={() => (
                <img src={community.logo} alt="Logo" className="h-14" />
              )}
              ifAbsent={() => (
                <div className=" p-2 theme-bg-primary rounded theme-text-on-primary flex items-center place-content-center text-sm">
                  {community.name
                    .split(" ")
                    .map((word: string) => word[0])
                    .join("")}
                </div>
              )}
            />
            <Label variant="t1">{community.name}</Label>
          </div>
          <div className="mt-10">
            <TextButton
              label="Go Back"
              className="flex items-center gap-2"
              prefix={<UIcon icon="arrow-left" />}
              onClick={() => {
                setIsVisible(false);
              }}
            />
          </div>
          <Plan plan={plan} selected={true} />
        </section>
        <section className="w-full max-w-md">
          <BillingAddress
            plan={plan}
            user={user}
            community={community}
            onProceed={onProceed}
            existingBillingAddress={existingBillingAddress}
          />
        </section>
      </div>
    </ModalForm>
  );
}
