import { MAIN_DOMAIN_SUFFIX } from "../../../_config/helper.config";
import {
  OrderDetails,
  RazorpaySuccessResponse,
  SubscriptionDetails,
} from ".././types/razor-pay-type";

/**
 * @name PaymentService
 * @description This service is used to handle payment related operations
 */
export class RazorPayCheckoutService {
  /**
   * @name initiateOneTimePayment
   * @description Generate the payment link for one time payment
   * @param {OrderDetails} orderDetails
   * @param {any} community
   * @param {any} user
   * @returns {Promise<any>}
   */
  static initiateOneTimePayment(
    community: any,
    orderDetails: OrderDetails,
    user: { name: string; email: string; mobile: string },
    onSuccess: (data: RazorpaySuccessResponse) => void
  ): string {
    // create configuration
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: orderDetails.amount.toString(),
      currency: orderDetails.currency,
      name: community.name,
      image:
        community.logo ??
        `https://app.${MAIN_DOMAIN_SUFFIX}/assets/main-logo.png`,
      description: orderDetails.receipt,
      order_id: orderDetails.id,
      // modal: {
      //     ondismiss: onCancel,
      // },
      handler: function (response: any) {
        console.log("[Razor Pay]", "Handler success", response);
        onSuccess(response);
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      notes: orderDetails.notes,
      theme: {
        color: "#0445FE",
      },
    };
    // convert the object into JSON string
    const json = JSON.stringify(options);
    // convert JSON string into base64
    const base64 = window.btoa(json);
    // create configuration
    const url = `${process.env.REACT_APP_PAYMENT_INTERFACE_URL}/razorpay/${base64}`;
    // open window
    // window.open(url, '_blank');
    return url;
    // console.log('[Razor Pay]', "Initiate one time payment", options);
    // @ts-ignore
    // const paymentObject = new window.Razorpay(options);
    // paymentObject.open();
    // paymentObject.on("payment.failed", (error: any) => {
    //     console.log('on Error', error);
    // });
  }

  /**
   * @name initiateRecurringPayment
   * @description Generate the payment link for recurring payment
   * @param {any} community
   * @param {SubscriptionDetails} subscriptionDetails
   * @param {any} user
   * @returns {Promise<any>}
   */
  static initiateRecurringPayment(
    community: any,
    subscriptionDetails: SubscriptionDetails,
    user: {
      name: string;
      email: string;
      mobile: string;
    },
    onSuccess: (data: RazorpaySuccessResponse) => void
  ): string {
    console.log(
      "[Razor Pay]",
      "Initiate recurring payment",
      subscriptionDetails
    );
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      subscription_id: subscriptionDetails.id,
      name: community.name,
      // description: subscriptionDetails.receipt,
      image:
        community.logo ??
        `https://app.${MAIN_DOMAIN_SUFFIX}/assets/main-logo.png`,
      handler: function (response: any) {
        console.log("[Razor Pay]", "Handler success", response);
        onSuccess(response);
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      notes: subscriptionDetails.notes,
      theme: {
        color: "#0445FE",
      },
    };
    // convert the object into JSON string
    const json = JSON.stringify(options);
    // convert JSON string into base64
    const base64 = window.btoa(json);
    const url = `${process.env.REACT_APP_PAYMENT_INTERFACE_URL}/razorpay/${base64}`;
    // open window
    // window.open(url, '_blank');
    return url;
    // console.log('[Razor Pay]', "Initiate one time payment", options);
    // @ts-ignore
    // var paymentObject = new window.Razorpay(options);
    // paymentObject.open()
    // paymentObject.on("payment.failed", (error: any) => {
    //     console.log('on Error', error);
    // });
  }
}
