import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  create: base + "message",
  delete: (messageId) => base + "message/" + messageId,
  getPinnedMessage: (groupId, sectionId) =>
    base +
    "group/" +
    groupId +
    "/section/" +
    sectionId +
    "/messages/" +
    "pinned",
  pinned: (messageId) => base + "message/" + messageId + "/pin",
  unpinned: (messageId) => base + "message/" + messageId + "/pin",
  edit: (messageId) => base + "message/" + messageId,
  react: (messageId) => base + "message/" + messageId + "/react",
  unreact: (messageId) => base + "message/" + messageId + "/unreact",
  uploadAttachment: base + "message/upload-attachment",
};
