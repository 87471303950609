import { SubscriptionPlan } from "../../types/subscription/subscription-plan";
import { SET_SUBSCRIPTION_PLAN } from "./subscription-plan.action";

const defaultState = {
  subscriptions: [] as Array<SubscriptionPlan>,
};

/**
 * Subscription plan reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function subscriptions(state = defaultState, action: any) {
  switch (action.type) {
    case SET_SUBSCRIPTION_PLAN:
      return action.subscriptions;
    default:
      return state;
  }
}
