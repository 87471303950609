import { withAppContext } from "../../contexts/app.context";
function I18Component({ lang, children }) {
  // return <span>{children}</span>;
  if (!children || children === null) {
    return children ?? <></>;
  }
  if (typeof children !== "string") {
    console.warn("I18 component only accepts string as children", children);
    return children;
  }
  return lang.trans(children) ?? children ?? <></>;
}

const I18 = withAppContext(I18Component);

export default I18;
