import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastTypes, withToast } from "../../../contexts/toastr.context";
import { Community } from "../../../types/community/community.type";
import { User } from "../../../types/user/minimal-user.type";
import { Button, Loader } from "../../../_components";
import { ActionModal } from "../../../_components/action-modal.component";
import { CommunityService } from "../../../_service";
import { setCommunity } from "../../../_store/_actions/community.actions";
import { PlansList } from "./plans-list";
import { RedeemButton } from "./redeem-button";
import { useAppService } from "../../../hooks/use-app-service";

export const COMMUNITY_SUBSCRIPTION_PAGE_ROUTE = "/settings/subscription";

interface Props {
  community: Community;
  setCommunity: React.Dispatch<React.SetStateAction<Community>>;
  user: User;
  addToast: any;
}
function CommunitySubscriptionPageComponent({
  community,
  setCommunity,
  user,
  addToast,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [isCancelPlanModalVisible, setIsCancelPlanModalVisible] =
    useState(false);
  const [deals, setDeals] = useState(false);
  const [isDealUser, setIsDealUser] = useState(false);

  // get action and session_id from url params
  const urlParams = new URLSearchParams(window.location.search);
  const action = urlParams.get("success");
  const sessionId = urlParams.get("session_id");

  const { analyticsService } = useAppService();

  // show toast according to action
  useEffect(() => {
    if (action === "true" && community.planType) {
      addToast(
        `You have successfully subscribed to ${community.planType} plan for this community.`,
        "",
        ToastTypes.success
      );
    }

    if (action === "false") {
      addToast(
        "The subscription failed for some reason.",
        "Please contact pensil support to resolve any issue",
        ToastTypes.danger
      );
    }
  }, [action, community.planType]);

  // get the list of deals available for the user
  useEffect(() => {
    setIsLoadingDeals(true);
    CommunityService.getDeals()
      .then(({ deal, isDealUser }) => {
        setDeals(deal);
        setIsDealUser(isDealUser);
        setIsLoadingDeals(false);
      })
      .catch((error) => {
        setIsLoadingDeals(false);
        addToast(
          "The subscription failed for some reason.",
          "Please contact pensil support to resolve any issue",
          ToastTypes.danger
        );
      });
  }, []);

  if (isLoadingDeals) {
    return <Loader />;
  }

  return (
    <div className="CommunitySubscriptionPage theme-bg-surface rounded">
      <div className="main-content">
        <div className="p-3">
          {/* community plan */}
          <div className="flex p-2 rounded theme-bg-default">
            <div className="flex-grow">
              <h3 className="mb-1 font-semibold">
                Current Plan
                <span className="text-xxs theme-text-subtitle-2 pl-3 font-light">
                  Please refresh the page if needed.
                </span>
              </h3>
              <p>
                {community.isDealRedeemed
                  ? "You have redeemed a deal for this community."
                  : `You are currently on ${
                      community.planType ? community.planType : "Free"
                    } Plan`}
              </p>
              {community.isDealRedeemed ? (
                <></>
              ) : community.isPlanExpired ? (
                <p className="text-red-500">
                  Plan expired on{" "}
                  {moment(community.planExpiryDate).format("MMM Do YYYY")}
                </p>
              ) : (
                <p>
                  Renew on:{" "}
                  {community.planExpiryDate ? community.planExpiryDate : "NA"}
                </p>
              )}
            </div>
            <div className="">
              {community.planType && !community.isPlanExpired ? (
                isLoading ? (
                  <div className="flex justify-start items-center mt-3">
                    <Loader />
                  </div>
                ) : community.isDealRedeemed ? (
                  <Button label="Redeemed" />
                ) : deals ? (
                  <RedeemButton
                    user={user}
                    code="scale"
                    community={community}
                    setIsLoading={setIsLoading}
                    setCommunity={setCommunity}
                    addToast={addToast}
                  />
                ) : (
                  <Button
                    label="Cancel"
                    outlined
                    onClick={(e) => {
                      setIsCancelPlanModalVisible(true);
                    }}
                  />
                )
              ) : deals ? (
                <RedeemButton
                  user={user}
                  code="scale"
                  community={community}
                  setIsLoading={setIsLoading}
                  setCommunity={setCommunity}
                  addToast={addToast}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* plan list */}
          {!isDealUser && !isLoadingDeals ? (
            <PlansList
              deal={deals}
              addToast={addToast}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsCancelPlanModalVisible={setIsCancelPlanModalVisible}
            />
          ) : (
            <></>
          )}
          <div className="flex w-full items-center justify-center mt-6 mb-6">
            <a href={"https://www.pensil.in/pricing-for-webapp"} onClick={() => {
              analyticsService.track("compare-plans-clicked")
            }}>
              <Button label="Compare Plans" />
            </a>
            {/* <iframe
              title="Embed"
              src="https://www.pensil.in/pricing-for-webapp"
              width={1000}
              height={500}
              allow="full"></iframe> */}
          </div>
        </div>
      </div>
      <ActionModal
        active={isCancelPlanModalVisible}
        setActive={setIsCancelPlanModalVisible}
        onSubmit={(e: any) => {
          setIsLoading(true);
          setIsCancelPlanModalVisible(false);
          CommunityService.cancelCommunitySubscription(user, community.id)
            .then(
              ({
                selectedType,
                planType,
                planExpiryDate,
                isPlanExpired,
                isPaidFor,
              }) => {
                addToast(
                  "Subscription cancelled successfully!",
                  "",
                  ToastTypes.success
                );
                // update the community
                setCommunity({
                  ...community,
                  selectedType,
                  planType,
                  planExpiryDate,
                  isPlanExpired,
                  isPaidFor,
                });
              }
            )
            .catch((err) => {
              console.log({ err });
              addToast(
                "Could not cancel subscription!",
                "Please try again after refreshing or contact pensil support!",
                ToastTypes.danger
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        header="Cancel Subscription"
        btnColor="red"
        title="Are you sure you want to cancel the subscription?"
        isLoading={undefined}
        children={undefined} // isLoading={isLoading}
      />
    </div>
  );
}

const CommunitySubscriptionPage = withToast(
  connect(
    (s: any) => ({
      community: s.community,
      user: s.auth,
    }),
    (dispatch: any) =>
      bindActionCreators(
        {
          setCommunity: setCommunity,
        },
        dispatch
      )
  )(CommunitySubscriptionPageComponent)
);

export default CommunitySubscriptionPage;
