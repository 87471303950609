import axios from "axios";
import { endpoints } from "../_config";

class OpenService {
  static async getFontData() {
    return axios
      .get(endpoints.open.getFontData, {})
      .then((response) => response.data);
  }

  static async getLinkMetaData(url) {
    return axios
      .get(`https://web-scrapper-coral.vercel.app/api/meta/pensil?url=${url}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => response.data);
  }
}

export { OpenService };
