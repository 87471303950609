import {
  ADD_NOTIFICATION,
  SET_NOTIFICATIONS,
} from "../_actions/notification.actions";

/**
 * Notification Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function notificationReducer(state = [], action) {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.notifications;
    case ADD_NOTIFICATION:
      return [action.notification, ...state];
    default:
      return state;
  }
}
