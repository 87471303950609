import cx from "classnames";
import { useState } from "react";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Course } from "../../../../types/course/course.type";
import { Button } from "../../../../_components";
import { Radio } from "../../../../_components/form-controls/radio.component";
import AlertStrip from "../../../../_components/molecule/alert-strips";
import { CourseService } from "../../../../_service";
import { Fold } from "../../../../_utils/extensions/typescript-utils";
import { CourseStateType } from "../course-detail.page";
import { useAppService } from "../../../../hooks/use-app-service";

interface SettingsBodyProps {
  community: any;
  course: Course;
  setCourse: (course: any) => void;
}
/**
 * Course settings body
 * @param {Object} course - course object
 * @param {Object} community - community object
 * @param {Function} setCourse - set course state
 * @returns {JSX.Element}
 */
export function CourseSettingsView({
  community,
  course,
  setCourse,
}: SettingsBodyProps) {
  const CourseState = {
    [CourseStateType.draft]: "Draft",
    [CourseStateType.published]: "Open",
    [CourseStateType["closed-free"]]: "Closed",
  };
  const [accessType, setAccessType] = useState<keyof typeof CourseStateType>(
    course.state ? CourseStateType[course.state] : CourseStateType.draft
  );

  const [isSaving, setIsSaving] = useState(false);
  const [visibleToNonMembers, setVisibleToNonMembers] = useState(
    course.visibleToNonMembers
  );
  const { addToast, ToastTypes } = useToast();
  const isInDraft = course.state === CourseStateType.draft;

  const { analyticsService } = useAppService();

  function updateCourseSettings() {
    const payload = {
      ...course,
      visibleToNonMembers: visibleToNonMembers,
      state: accessType,
    };
    setIsSaving(true);
    CourseService.createCourse(community.id, payload, course.id)
      .then(({ course }) => {
        setCourse(course);
        addToast("Success", "Course settings updated", ToastTypes.success);
      })
      .catch((e) => {
        console.error(e);
        addToast(
          "Error",
          "Unable to update course settings",
          ToastTypes.danger
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <div className="flex flex-col space-y-4 mt-4">
      <Fold
        value={isInDraft}
        ifPresent={() => (
          <AlertStrip
            className="theme-bg-surface mt-4"
            warning
            title="Course is in draft. Only you can see it"
            message="Select access level setting and publish the course to make it live"
          />
        )}
      />

      <div className="flex flex-col space-y-4 theme-bg-surface rounded p-4 mt-2">
        <div className="Access flex flex-col space-y-2">
          <div className="flex place-content-between">
            <h2 className="font-semibold theme-text-heading-1">Access</h2>
            <Button
              label={isInDraft ? "Publish" : "Save"}
              disabled={
                ![
                  CourseStateType["closed-free"],
                  CourseStateType.published,
                  // @ts-ignore
                ].includes(accessType)
              }
              onClick={() => {
                analyticsService.track("course-published", {
                  isInDraft,
                  courseState: CourseState[accessType]
                })
                updateCourseSettings();
              }}
              isLoading={isSaving}
            />
          </div>

          {[CourseStateType.published, CourseStateType["closed-free"]].map(
            (type, index) => (
              <div
                key={index}
                onClick={() => setAccessType(type)}
                className={cx(
                  "flex flex-col space-y-2 mb-2 border p-2 rounded",
                  "hover:theme-bg-primary-light hover:theme-border-primary-light",
                  {
                    "theme-border-primary": CourseState[accessType] === type,
                    "theme-border-default": CourseState[accessType] !== type,
                  }
                )}>
                <Radio
                  selected={CourseStateType[accessType] === type}
                  onClick={() => {
                    setAccessType(type);
                  }}>
                  <p className="font-bold">{CourseState[type]}</p>
                </Radio>
                <p className="ml-6">
                  {/* {type === CourseStateType.draft &&
                    "No one can see this course except you"} */}
                  {type === CourseStateType.published &&
                    "Anyone can see and enroll into this course"}
                  {type === CourseStateType["closed-free"] &&
                    "Either the user can request to enroll in this course OR the user will have to make a payment to subscribe to this course via membership"}
                </p>
              </div>
            )
          )}
        </div>
        {/* <div className="flex flex-col">
          <Checkbox
            selected={visibleToNonMembers}
            alt
            onClick={() => {
              setVisibleToNonMembers(!visibleToNonMembers);
            }}>
            <p className="pl-">Visible to non members</p>
          </Checkbox>
        </div> */}
      </div>
    </div>
  );
}
