import plusIcon from "../../../_assets/vectors/plus-icon.svg";
import quizOptionCorrect from "../../../_assets/vectors/quiz-option-correct-icon.svg";
import quizOption from "../../../_assets/vectors/quiz-option-icon.svg";
import quizQuestionRemoveIcon from "../../../_assets/vectors/quiz-question-remove-icon.svg";
import { InputSwitch } from "../../form-controls/input-switch.component";
import { FormError } from "../../form-error.component";

export const QUIZ_QUESTION_SKELETON = {
  statement: "",
  options: ["", "", "", ""],
  answer: "",
};

export function CreatePostQuiz({
  errors,
  isPoll = false,
  setIsPoll = (e) => {},
  quizTitle = "",
  setQuizTitle = () => {},
  quizDuration = 10,
  setQuizDuration = () => {},
  quizInstruction = "",
  setQuizInstruction = () => {},
  quizQuestions = [],
  setQuizQuestions = () => {},
}) {
  const updateQuestion = (index, question) => {
    const newQuizQuestions = [...quizQuestions];
    newQuizQuestions.splice(index, 1, question);
    setQuizQuestions(newQuizQuestions);
  };

  const deleteQuestion = (index) => {
    const newQuizQuestions = [...quizQuestions];
    newQuizQuestions.splice(index, 1);
    setQuizQuestions(newQuizQuestions);
  };

  const addNewQuestion = () => {
    const newQuizQuestions = [...quizQuestions];
    newQuizQuestions.push(QUIZ_QUESTION_SKELETON);
    setQuizQuestions(newQuizQuestions);
  };

  return (
    <div className="CreatePostQuiz mt-5">
      {/* question switcher */}
      <div
        className="theme-bg-surface p-4 flex justify-between items-center"
        style={{ margin: "1rem -1rem" }}>
        <span>Toggle to Question</span>
        <InputSwitch selected={isPoll} onClick={setIsPoll} />
      </div>
      {/* title */}
      <div
        style={{
          backgroundColor: "#F5F6F8",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
        className="px-4 pt-6 pb-4">
        <div className="theme-text-heading-2">Test Series</div>
        <div className="mt-2">
          <input
            type="text"
            placeholder="Enter Test Title here"
            className="block px-4 py-3 w-full rounded-lg focus:outline-none bg-transparent"
            value={quizTitle}
            onChange={(e) => {
              setQuizTitle(e.target.value);
            }}
          />
          <FormError error={errors && errors.title} />
        </div>
      </div>
      {/* questions list */}
      {quizQuestions.map((question, index) => (
        <div key={index} className="my-5">
          <QuizQuestion
            index={index}
            question={question}
            updateQuestion={updateQuestion}
            deleteQuestion={deleteQuestion}
          />
          <FormError
            error={
              errors &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].statement
            }
          />
          <QuizOptions
            index={index}
            question={question}
            errors={
              errors &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].options
            }
            updateQuestion={updateQuestion}
          />
        </div>
      ))}
      {/* Add new question button */}
      <div className="my-5">
        <button
          className="theme-text-primary font-semibold text-xs flex justify-center items-center focus:outline-none"
          onClick={(e) => {
            addNewQuestion();
          }}>
          <span className="rounded-full border theme-border-primary w-5 h-5 flex justify-center items-center">
            <img src={plusIcon} alt="Add question" width="12px" />
          </span>
          <span className="px-2">Add Question</span>
        </button>
      </div>
      {/* Instructions and duration */}
      <div
        style={{
          backgroundColor: "#F5F6F8",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
        className="px-4 pt-6 pb-4 flex">
        <div className="w-2/3">
          <div className="theme-text-heading-2 font-semibold">
            Instruction (Optional)
          </div>
          <div className="mt-2 mr-4">
            <input
              type="text"
              placeholder="Enter Instruction here"
              className="block px-4 py-3 w-full rounded-lg focus:outline-none bg-transparent"
              value={quizInstruction}
              onChange={(e) => {
                setQuizInstruction(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-1/3">
          <div className="theme-text-heading-2 font-semibold">Duration</div>
          <div className="mt-2">
            <select
              type="text"
              placeholder="Enter Test Title here"
              className="block px-4 py-3 w-full rounded-lg focus:outline-none bg-transparent"
              value={quizDuration}
              onChange={(e) => {
                setQuizDuration(e.target.value);
              }}>
              <option value={5}>5 Mins</option>
              <option value={10}>10 Mins</option>
              <option value={20}>20 Mins</option>
              <option value={30}>30 Mins</option>
              <option value={40}>40 Mins</option>
              <option value={60}>60 Mins</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuizQuestion({ index, question, updateQuestion, deleteQuestion }) {
  return (
    <div className="QuizQuestion">
      <div className="mb-4 flex">
        <span className="theme-text-heading-2">Question {index + 1}</span>
        {index === 0 ? (
          <></>
        ) : (
          <img
            src={quizQuestionRemoveIcon}
            width="20px"
            alt="Quiz Option"
            className="cursor-pointer mx-2"
            onClick={(e) => {
              // set this option as answer
              deleteQuestion(index);
            }}
          />
        )}
      </div>
      <input
        type="text"
        placeholder="Enter Question"
        className="block w-full px-4 py-3 focus:outline-none border theme-border-default rounded-lg hover:shadow"
        value={question.statement}
        onChange={(e) => {
          updateQuestion(index, {
            ...question,
            statement: e.target.value,
          });
        }}
      />
    </div>
  );
}

function QuizOptions({ index, question, errors, updateQuestion }) {
  return (
    <div className="QuizOptions my-2">
      {question.options.map((option, optionIndex) => (
        <div key={optionIndex} className="QuizOption my-2">
          <div className="flex">
            <img
              src={
                option && question.answer === option
                  ? quizOptionCorrect
                  : quizOption
              }
              width="20px"
              alt="Quiz Option"
              className="cursor-pointer"
              onClick={(e) => {
                // set this option as answer
                updateQuestion(index, {
                  ...question,
                  answer: option,
                });
              }}
            />
            <input
              type="text"
              placeholder={"Option " + (optionIndex + 1)}
              className="block w-full ml-3 px-4 py-3 focus:outline-none border theme-border-default rounded-lg hover:shadow"
              value={option}
              onChange={(e) => {
                const newOptions = [...question.options];
                newOptions.splice(optionIndex, 1, e.target.value);
                updateQuestion(index, {
                  ...question,
                  options: newOptions,
                });
              }}
            />
          </div>
          <FormError
            error={errors && errors[optionIndex] && errors[optionIndex]}
          />
        </div>
      ))}
    </div>
  );
}
