import cx from "classnames";
import UIcon from "../uicon-component";
import I18 from "./i18";

interface TooltipProps {
  children: any;
  label: string;
  align?: "left" | "right" | "top" | "bottom";
  className?: string;
}
function Tooltip({
  children,
  label,
  align = "top",
  className = "",
}: TooltipProps) {
  const rotate = {
    left: "rotate(360deg)",
    right: "rotate(180deg)",
    top: "rotate(90deg)",
    bottom: "rotate(272deg)",
  };

  return (
    <div
      className={`tooltip relative transition duration-150 ease-in-out ${className}`}>
      <div
        className="transition duration-150 ease-in-out "
        aria-haspopup="true"
        aria-expanded="true">
        {children}
      </div>

      <div
        className={cx(
          "invisible opacity-0 label absolute flex flex-col items-center  transition-all duration-700 transform  ",
          {
            "-translate-y-2 mb-10 bottom-0 left-0 right-0": align === "top",
            "-translate-y-0 mt-10 top-0 left-0 right-0": align === "bottom",
            "-translate-x-24 -translate-y-9": align === "left",
            "-translate-x-0": align === "right",
          }
        )}>
        <span className="m-2 relative z-10 p-2 text-xs leading-none theme-bg-heading-1 theme-text-background whitespace-no-wrap  shadow-md rounded min-w-max delay-1000 ease-linear">
          <I18>{label}</I18>
          <div
            className={cx("absolute flex items-center w-full", {
              "left-0 -top-2 place-content-center ": align === "bottom",
              "left-0 -bottom-2 place-content-center ": align === "top",
              "h-full -left-1.5 top-0 place-content-start": align === "right",
              "h-full top-0 place-content-end": align === "left",
            })}>
            <div style={{ transform: `${rotate[align]}` }}>
              <UIcon icon="play" solid={true} className="theme-text-default" />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}
export default Tooltip;
