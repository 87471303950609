import ReactLinkify from "react-linkify";

export default function BlankLinkify({ children }) {
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}>
          {decoratedText}
        </a>
      )}>
      {children}
    </ReactLinkify>
  );
}
