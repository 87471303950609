import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./root-reducer";

// prepare the default state
const state = {
  // @ts-ignore
  auth: JSON.parse(localStorage.getItem("pensil.user")),
};

// prepare the store
let composeEnhancers;
const middleware = [thunk];
if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // @ts-ignore
  // middleware.push(logger);
} else {
  composeEnhancers = compose;
}

export const store = createStore(
  rootReducer,
  state,
  composeEnhancers(applyMiddleware(...middleware))
);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
