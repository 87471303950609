import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { withToast } from "../contexts/toastr.context";
import { PostCard } from "../_components";
import PostTileShimmer, {
  PostTileType,
} from "../_components/shimmers/post/post-tile-shimmer.component";
import Label from "../_components/typography/label.typography";
import { history } from "../_config";
import {
  getPostDetailById,
  getPostDetailBySlug,
  updatePostDetail,
} from "../_store/post-detail.store";
import { getSectionPageRoute } from "./group";
import { getSegmentedPath } from "../_utils/segmented-community-util";

export const POST_DETAIL_PAGE_ROUTE = "/post/:id";
export const POST_DETAIL_SLUG_PAGE_ROUTE = "/p/:slug";
export const createPostDetailPageRoute = (postId) => "/post/" + postId;
export const createPostDetailPageRouteFromSlug = (post) => {
  const isSharedPost = post.shared !== null && post.shared !== undefined;
  const postId = isSharedPost ? post.sharedId : post.id;
  const meta = !isSharedPost ? post.meta : post.shared.meta;
  if (meta !== undefined && meta.slug !== undefined) {
    return "/p/" + post.meta.slug;
  } else {
    return "/post/" + postId;
  }
};

export function createPostDetailPageUrl(postId) {
  return window.location.origin + createPostDetailPageRoute(postId);
}

function PostDetailPageComponent({
  // state
  user,
  community,
  postDetail,
  // dispatch
  getPostDetailBySlug,
  getPostDetailById,
  setPost,
  // context
  addToast,
}) {
  const { post, isLoading, statusCode } = postDetail;
  const { id, slug } = useParams();

  // load post detail from Id
  useEffect(() => {
    if (id === undefined) {
      return;
    }
    getPostDetailById(id);
  }, [id]);

  // load post detail from slug
  useEffect(() => {
    if (slug === undefined) {
      return;
    }
    getPostDetailBySlug(slug);
  }, [user, slug]);

  if (!user && !isLoading && statusCode === 403) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Label className="text-center">
          You need to be logged in to view this post.
        </Label>
      </div>
    );
  }

  return (
    <>
      <div className="PostPage">
        {/* post section link */}
        {post ? (
          <div className="w-fit mb-2 px-2 sm:px-0">
            <div
              className="cursor-pointer font-semibold"
              onClick={() =>
                history.push(getSegmentedPath(getSectionPageRoute(post.group.id, post.tabId)))
              }>
              ᐸ &nbsp;{post.tab.name ? post.tab.name : ""}
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* post detail */}
        {isLoading ? (
          <div className="flex m-6 animate-pulse">
            {/* <Loader /> */}
            <div className="PostCard theme-bg-surface rounded border theme-border-default w-full ">
              <PostTileShimmer type={PostTileType.expanded} />
            </div>
          </div>
        ) : !post ? (
          <div className="flex flex-col  w-full items-center my-10">
            {statusCode === 404 && (
              <>
                <Label variant="t2" size="h2">
                  This post was not found
                </Label>
                <Label variant="s1">
                  The post may have been removed or does not exist
                </Label>
              </>
            )}
            {statusCode === 403 && (
              <>
                <Label variant="t2" size="h2">
                  This is a private post
                </Label>
                <Label variant="s1">
                  You don't have the permission to view this post
                </Label>
              </>
            )}
          </div>
        ) : (
          <div className="flex justify-center flex-col p-3">
            <PostCard
              noMargin
              showFullPost
              post={post}
              updatePost={(post) => {
                setPost(post);
                history.push(getSegmentedPath(createPostDetailPageRouteFromSlug(post)));
              }}
              deletePost={() => {
                setPost(null);
              }}
              displayPollResult={true}
            />
          </div>
        )}
      </div>
      <div className="secondary-sidebar pr-5"></div>
    </>
  );
}

const dtp = {
  getPostDetailBySlug,
  getPostDetailById,
  setPost: updatePostDetail,
};

const PostDetailPage = withToast(
  connect(
    (s) => ({
      user: s.auth,
      community: s.community,
      postDetail: s.postDetail,
    }),
    dtp
  )(PostDetailPageComponent)
);

export default PostDetailPage;
