import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  create: base + "community",
  getSubscribedCommunities: base + "community/joined",
  updateCommunity: (id) => base + "community/" + id,
  updateCommunityWelcomeMessages: (id) =>
    base + "community/" + id + "/welcome-messages",
  updateCommunityBannerDetails: (id) =>
    base + "community/" + id + "/banner-details",
  updateCommunityBanner: (id) => base + "community/" + id + "/update-banner",
  removeCommunityBanner: (id) => base + "community/" + id + "/remove-banner",
  addressAvailability: (address) =>
    base + "community/address-availability/" + address,
  updateLogo: (id) => base + "community/" + id + "/update-logo",
  updateFavicon: (id) => base + "community/" + id + "/update-favicon",
  getCommunity: (address) => base + "community/" + address,
  getCommunityById: (id) => base + "community-by-id/" + id,
  verifyThirdPartyToken: (id) =>
    base + "community/" + id + "/verify-user-token",
  verifyUserByAdmin: (id, userId) =>
    base + "community/" + id + "/user/" + userId + "/verify",
  joinCommunity: (id) => base + "community/join/" + id,
  leaveCommunity: (id) => base + "community/leave/" + id,
  getUserPosts: (communityId, userId, page) =>
    base + `community/${communityId}/user/${userId}/posts?page=${page}`,
  getCommunityPostDirectory: (communityId, sort, page) =>
    base + `post/directory/${communityId}?sort=${sort}&page=${page}`,
  getUserActivities: (communityId, userId, page) =>
    base + `community/${communityId}/user/${userId}/activities?page=${page}`,
  groups: (id) => base + "community/" + id + "/groups",
  sortGroups: (id) => base + "community/" + id + "/groups/sort",
  sortGroupTabs: (id, groupId) =>
    base + "community/" + id + "/group/" + groupId + "/sort",
  subscribers: (communityId, groupId) =>
    base + "community/" + communityId + "/subscribers?group=" + groupId,
  subscribersPaginated: (communityId, limit, page, search, groupId) =>
    base +
    "community/" +
    communityId +
    "/subscribers/paginated?limit=" +
    limit +
    "&page=" +
    page +
    "&search=" +
    search +
    "&group=" +
    groupId,
  markSubscribersAsViewed: (communityId) =>
    base + `community/${communityId}/subscribers/mark-viewed`,
  makeUserModeratorInAllGroup: (communityId, groupId, userId) =>
    base +
    "community/" +
    communityId +
    "/group/" +
    groupId +
    "/user/" +
    userId +
    "/make-moderator",
  links: (id) => base + "community/" + id + "/links",
  addNewLink: (id) => base + "community/" + id + "/links",
  editLink: (id, linkId) => base + "community/" + id + "/links/" + linkId,
  deleteLink: (id, linkId) => base + "community/" + id + "/links/" + linkId,
  // leaderboard
  getMyLeaderboardScore: (communityId) =>
    base + "/leaderboard/community/" + communityId + "/my",
  // groupId is optional
  getLeaderboard: (communityId, groupId, page, limit) =>
    base +
    "/leaderboard/community/" +
    communityId +
    "/paginated?groupId=" +
    groupId +
    `&page=${page}&limit=${limit}`,

  getLeaderboardV2Paginated: (communityId, page, limit) => base + "leaderboard/community/" + communityId + `/paginated/v2?page=${page}` + `&limit=${limit}` ,
  getMyKarmaPoints: (communityId) => base + "leaderboard/community/" + communityId + "/my/v2",

  getCommunityKarmaPoints: (communityId) =>
    base + "/community/" + communityId + "/karma-points",
  sectionMessageNotificationBadges: (communityId) =>
    base + "/community/" + communityId + "/section-message-badges",
  sectionMessageNotificationMarkAsRead: (communityId, tabId) =>
    base + "community/" + communityId + "/section-message-mark-read/" + tabId,
  notificationPreference: (communityId) =>
    base + "community/" + communityId + "/notification-preferences",
  updateTabNotificationPreference: (communityId) =>
    base + "community/" + communityId + "/notification-preferences/tab",
  updateNotificationPreference: (communityId) =>
    base + "community/" + communityId + "/notification-preferences",
  roles: {
    makeAdminOfWholeCommunity: (communityId, userId) =>
      base + "community/" + communityId + "/user/" + userId + "/make-admin",
    removeUserAsAdminOfWholeCommunity: (communityId, userId) =>
      base +
      "community/" +
      communityId +
      "/user/" +
      userId +
      "/remove-as-admin",
    removeUserFromCommunity: (communityId, userId) =>
      base +
      "community/" +
      communityId +
      "/user/" +
      userId +
      "/remove-from-community",
    makeAdminOfSelectedGroups: (communityId, userId, groupsIds = []) =>
      base +
      "community/" +
      communityId +
      "/user/" +
      userId +
      "/update-role/groups",
    updateRole: (communityId) =>
      base + "community/" + communityId + "/update-role",
    getRole: (communityId, userId) =>
      base + "community/" + communityId + "/get-role/" + userId,
  },
  subscription: {
    // for community plan
    getPlansList: (communityId) =>
      base + "checkout/community/" + communityId + "/plans",
    getCheckoutURLForCommunityPlan: (
      communityId,
      planType,
      communityURL = window.location.origin
    ) =>
      base +
      "checkout/community/" +
      communityId +
      "/" +
      planType +
      "?communityURL=" +
      communityURL,
    cancelCommunitySubscription: (communityId) =>
      base + "cancel-plan/community/" + communityId,
  },
  theme: {
    communityThemeData: (communityId) =>
      base + "community-theme-data/" + communityId,
    communityThemeDataToggle: (communityId) =>
      base + "community-theme-data/" + communityId + "/toggle",
  },
  deals: {
    getDeals: base + "community-deals",
    redeemDeal: (communityId) =>
      base + "community-deals/" + communityId + "/redeem",
  },
  settings: {
    toggleCommunityLoginRequirement: (id) =>
      base + "community/" + id + "/toggle-login-requirement",
    updateConfig: (id) => base + "community/" + id + "/update-config",
    updateConfigV2: (id) => base + "community/" + id + "/update-config/v2",
  },
  configurable: (communityId, configurableKey) =>
    base + `community/${communityId}/configurable/${configurableKey}`,
  analytics: {
    getAnalyticsLink: (communityId) =>
      base + "community/" + communityId + "/analytics",
  },
  invite: {
    inviteViaEmail: (communityId) =>
      base + "community/" + communityId + "/invite",
    inviteViaCsv: (communityId) =>
      base + "community/" + communityId + "/invite/csv",
    getInvitesList: (communityId, query, pageNo) =>
      base +
      "community/" +
      communityId +
      "/invites/paginated?search=" +
      query +
      "&page=" +
      pageNo,
    configureMailTemplates: (communityId) =>
      base +
      "community/" +
      communityId +
      "/configurable/COMMUNITY_INVITE_MAIL_BODY_KEY",
    batchReInvite: (communityId) =>
      base + "community/" + communityId + "/invite/batch-reinvite",
    cancelBatchInvite: (communityId) =>
      base + "community/" + communityId + "/invite/batch-cancel",
  },
  bulkEmail: {
    send: (communityId) => base + "community/" + communityId + "/bulk-email",
    getBulkEmailLogList: (communityId, query, pageNo) =>
      base +
      `community/${communityId}/bulk-email/log?search=${query}&page=${pageNo}`,
  },
  additionalConfiguration: {
    getConfiguration: (communityId) =>
      base + "community/" + communityId + "/additional-configuration",
    saveDefaultJoinGroups: (communityId) =>
      base +
      "community/" +
      communityId +
      "/additional-configuration/default-join-groups",
  },
};
