import cx from "classnames";

export default function ReactionPills({
  reactions,
  unreact = (e) => {},
  react = (emoji) => {},
}) {
  return (
    <div className="flex">
      {reactions.details.map((reaction, index) => (
        <div
          key={reaction.emoji}
          className={cx(
            "flex items-center px-1 text-xs font-light rounded cursor-pointer mr-2",
            {
              "theme-bg-primary-light theme-text-primary": reaction.isByMe,
              "theme-bg-disable": !reaction.isByMe,
            }
          )}
          style={{
            height: "21px",
          }}
          onClick={(e) => {
            // set reaction as this emoji
            if (reaction.isByMe) {
              unreact();
            } else {
              react(reaction.emoji);
            }
            // hit like api
          }}
          title={
            "Reacted by " + reaction.users?.map((user) => user.name).join(", ")
          }>
          <span className="pr-1">{reaction.emoji}</span>
          <span className="pl-1">{reaction.count}</span>
        </div>
      ))}
    </div>
  );
}
