import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withToast } from "../contexts/toastr.context";
import useLang from "../hooks/use-lang.hook";
import questionIcon from "../_assets/vectors/question-icon.svg";
import I18 from "../_components/atoms/i18";
import { Loader } from "../_components/loader.component";
// import { TagCardList} from '../_components/tag-card-list.component';
import { PostCardList } from "../_components/post/post-card-list.component";
import { PostService } from "../_service/post.service";
// import { TagCardList } from '../_components';

export const TAG_PAGE_ROUTE = "/tagged";
export const showTagPageRoute = (tagName) => "/tagged?tag=" + tagName;

function TagPageDetailsComponent({ user, community, groups, updateGroups }) {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [isGroupDropdownVisible, setIsGroupDropdownVisible] = useState(false);
  const [isSectionDropdownVisible, setIsSectionDropdownVisible] =
    useState(false);
  const [isGroupSelected, setIsGroupSelected] = useState("All Groups");
  const [isSectionSelected, setIsSectionSelected] = useState("All Channels");
  const [isActiveSectionId, setIsActiveSectionId] = useState("");
  const [activeGroupId, setActiveGroupId] = useState("");
  const [sectionList, setSectionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const lang = useLang();

  //
  const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  // Searched Tag
  const [posts, setPosts] = useState([]);

  const inputRef = useRef();

  const location = useLocation();

  // hide on clicking outside this
  useEffect(() => {
    const closeMenuListener = (e) => {
      if (isGroupDropdownVisible) {
        setIsGroupDropdownVisible(false);
      } else if (isSectionDropdownVisible) {
        setIsSectionDropdownVisible(false);
      }
    };
    window.document.body.addEventListener("click", closeMenuListener);
    return () => {
      window.document.body.removeEventListener("click", closeMenuListener);
    };
  }, [isGroupDropdownVisible, isSectionDropdownVisible]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tag = urlParams.get("tag");
    if (tag !== undefined && tag !== "") {
      setQuery(`#${tag}`);
      getPosts(user, community.id, 1, tag, "", "");
    } else if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [user, community, location]);

  const getPosts = async (user, communityId, page, tags, tabId, groupId) => {
    setIsLoadingMorePosts(true);
    let searchTagResponse = await PostService.getPostByTagSearch(
      user,
      communityId,
      page,
      tags,
      tabId,
      groupId
    );
    await setPosts(searchTagResponse.posts);
    setIsLoadingMorePosts(false);
  };

  const selectGroup = async (name, id) => {
    let sectionListArr = [];
    setIsGroupSelected(name);
    setCurrentPage(1);
    sectionListArr = groups.filter((item) => item.name === name);
    sectionListArr.length > 0
      ? setSectionList(sectionListArr[0].tabs)
      : setSectionList([]);
    setIsSectionSelected("All Channel");
    getPosts(user, community.id, 1, query.slice(1), "", id);
    setActiveGroupId(id);
    setNoMorePosts(false);
  };

  const selectSection = async (name, id) => {
    await setIsSectionSelected(name);
    setCurrentPage(1);
    await getPosts(user, community.id, 1, query.slice(1), id, activeGroupId);
    await setIsActiveSectionId(id);
    setNoMorePosts(false);
  };
  // post lazy loading
  useEffect(() => {
    // create callback
    const callBack = () => {
      if (
        window.innerHeight + window.scrollY + 100 >=
        document.body.offsetHeight
      ) {
        if (!isLoadingMorePosts && !noMorePosts) {
          // if we are not already loading more posts, load more posts
          setIsLoadingMorePosts(true);
          PostService.getPostByTagSearch(
            user,
            community.id,
            currentPage + 1,
            query.slice(1),
            isActiveSectionId,
            activeGroupId
          ).then((response) => {
            // if there are no more posts, set so
            if (response.posts.length === 0) {
              setNoMorePosts(true);
            } else {
              setPosts([...posts, ...response.posts]);
              setCurrentPage(response.page);
            }
            setIsLoadingMorePosts(false);
          });
        }
      }
    };

    window.addEventListener("scroll", callBack);

    return () => {
      window.removeEventListener("scroll", callBack);
    };
  }, [isLoadingMorePosts, user, currentPage, posts, noMorePosts, community.id]);

  return (
    <>
      <div className="TagPage">
        {/* Header of tag search page */}
        <div className="GroupInfo rounded theme-bg-surface mb-4 px-3 py-2">
          <div className="flex justify-between items-center">
            <div
              className={cx(
                "font-bold theme-text-heading-2 p-4 pb-3 overflow-ellipsis w-full"
              )}>
              <div className="flex items-center px-4 py-1 rounded mb-4 theme-bg-default border theme-border-default">
                <input
                  type="text"
                  ref={inputRef}
                  placeholder={lang.trans("Search for tags")}
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  className="flex-grow bg-transparent py-1 px-4 focus:outline-none theme-text-subtitle-1 font-bold"
                />
                <img
                  onClick={() =>
                    getPosts(
                      user,
                      community.id,
                      currentPage,
                      query.slice(1),
                      ""
                    )
                  }
                  src={questionIcon}
                  alt=""
                  className="search-tag-icon cursor-pointer"
                  style={{ filter: "opacity(0.5)", width: "18px" }}
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="pl-4">
              <div className="dropdown inline-block relative">
                <button
                  onClick={(e) =>
                    setIsGroupDropdownVisible(!isGroupDropdownVisible)
                  }
                  className="theme-bg-primary theme-text-on-primary font-semibold py-2 px-4 rounded inline-flex items-center">
                  <span className="mr-1">{isGroupSelected}</span>
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                  </svg>
                </button>
                {isGroupDropdownVisible ? (
                  <ul className="block absolute theme-text-heading-2 pt-1 p-0 z-10">
                    <li
                      onClick={() => selectGroup("All Groups", "")}
                      className="list-none cursor-pointer">
                      <span className="rounded-t theme-bg-disable hover:theme-bg-default py-2 px-4 block whitespace-no-wrap">
                        <I18>All Groups</I18>
                      </span>
                    </li>
                    {groups
                      ? groups.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => selectGroup(item.name, item.id)}
                              className="list-none cursor-pointer">
                              <span className="rounded-t theme-bg-disable hover:theme-bg-default py-2 px-4 block whitespace-no-wrap">
                                {item.name}
                              </span>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className="pl-4">
              {isGroupSelected !== "All Groups" ? (
                <div className="dropdown inline-block relative">
                  <button
                    onClick={(e) =>
                      setIsSectionDropdownVisible(!isSectionDropdownVisible)
                    }
                    className="theme-bg-primary theme-text-on-primary font-semibold py-2 px-4 rounded inline-flex items-center">
                    <span className="mr-1">{isSectionSelected}</span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </button>
                  {isSectionDropdownVisible ? (
                    <ul className="block absolute theme-text-heading-2 pt-1 p-0 z-10">
                      <li
                        onClick={() => selectSection("All Channel", "")}
                        className="list-none cursor-pointer">
                        <span className="rounded-t theme-bg-disable hover:theme-bg-default py-2 px-4 block whitespace-no-wrap">
                          <I18>All Channel</I18>
                        </span>
                      </li>
                      {sectionList
                        ? sectionList.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() =>
                                  selectSection(item.name, item.id)
                                }
                                className="list-none cursor-pointer">
                                <span className="rounded-t theme-bg-disable hover:theme-bg-default py-2 px-4 block whitespace-no-wrap">
                                  {item.name}
                                </span>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Tag card */}
        <div>
          <div className="pl-2">
            <span className="theme-text-subtitle-2 font-bold">
              <I18>SEARCH RESULT</I18>
            </span>
          </div>
          {!isLoadingMorePosts && posts.length > 0 ? (
            <PostCardList posts={posts} allPosts={posts} setPosts={setPosts} />
          ) : (
            <></>
          )}
          {isLoadingMorePosts ? (
            <div className="flex justify-center align-center py-5">
              <Loader />
            </div>
          ) : posts.length === 0 ? (
            <div className="theme-text-subtitle-2 font-semibold text-center">
              <I18>No posts found for selected hashtag</I18>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="secondary-sidebar pr-5"></div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth,
  community: state.community,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const TagPageDetails = withToast(
  connect(mapStateToProps, mapDispatchToProps)(TagPageDetailsComponent)
);

export default TagPageDetails;
