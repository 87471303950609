import { useState } from "react";
import I18 from "./atoms/i18";
import TextButton from "./atoms/text-button";
import { Button } from "./button.component";
import IconButton2 from "./icon-button-2-component";
import { Modal } from "./modal.component";

const sampleButton = {
  label: "Sample button",
  isFlat: false, // true or false
  onClick: () => {},
};

function ActionModalComponent({
  onSubmit,
  title,
  header,
  isLoading,
  btnColor,
  children,
  active = false,
  setActive = (e) => {},
  buttons = [],
}) {
  const resetFormAndClose = () => {
    setActive(false);
  };

  if (!active) {
    return <></>;
  }

  return (
    <Modal
      className=" ActionModalComponent "
      active={active}
      width="390px"
      padding={false}
      setActive={setActive}>
      {/* headers */}
      <div className="pl-4 pr-2 py-2 flex items-center justify-between theme-bg-surface">
        <span className="font-bold theme-text-heading-2">
          <I18>{header}</I18>
        </span>
        <IconButton2
          icon="cross"
          size="md"
          onClick={() => {
            resetFormAndClose();
          }}
        />
      </div>
      {/* body */}
      <div className="px-5 py-8 theme-text-heading-2">
        {children ? (
          children
        ) : (
          <div className="text-center">
            <I18>{title}</I18>
          </div>
        )}
        <div className="mt-5">
          <div className="mx-auto">
            <div className="flex space-x-4">
              {buttons?.length ? (
                buttons.map((button, index) =>
                  button.isFlat ? (
                    <TextButton
                      key={index}
                      disabled={isLoading}
                      onClick={button.onClick}
                      className="mr-2 p-2 w-full border theme-border-primary"
                      label={button.label}
                    />
                  ) : (
                    <Button
                      key={index}
                      onClick={button.onClick}
                      isLoading={isLoading}
                      btnColor={btnColor}
                      className="p-2 w-full"
                      label={button.label}
                      large
                    />
                  )
                )
              ) : (
                <>
                  <TextButton
                    disabled={isLoading}
                    onClick={resetFormAndClose}
                    className="mr-2 p-2 w-full border theme-border-primary"
                    label="Cancel"
                  />
                  <Button
                    onClick={onSubmit}
                    isLoading={isLoading}
                    btnColor={btnColor}
                    className="p-2 w-full"
                    label="Yes, Go Ahead"
                    large
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function ActionModelTrigger({
  component,
  onSubmit = (e) => {},
  header = "",
  isLoading = false,
  btnColor = "red",
  children,
}) {
  const [active, setActive] = useState(false);

  return (
    <>
      {/* trigger component */}
      <span
        onClick={(e) => {
          setActive(true);
        }}>
        {component ? component : children ? children : <span>Click me!</span>}
      </span>
      {/* modal */}
      <ActionModalComponent
        active={active}
        setActive={setActive}
        onSubmit={() => {
          onSubmit(() => {
            setActive(false);
          });
        }}
        header={header}
        isLoading={isLoading}
        btnColor={btnColor}
      />
    </>
  );
}

const ActionModal = ActionModalComponent;

export { ActionModal, ActionModelTrigger };
