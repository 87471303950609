import HeaderShimmer from "./header/header-shimmer.component";
import SidebarShimmer from "./sidebar/sidebar-empty-shimmer.component";

export default function AuthAppShimmer() {
  return (
    <div className="animate-pulse  h-screen  w-full">
      <div>
        <HeaderShimmer />
      </div>
      <div className="AppContainer h-screen two-column">
        <div className="Sidebar  ">
          <SidebarShimmer />
        </div>
        <div className="flex flex-col space-y-2 px-4 sm:p-0">
          <div className="h-96 w-full theme-bg-surface" />
          <div className="flex h-full space-x-2">
            <div className="CommunityPostTab h-full w-full theme-bg-surface" />
            <div className="hidden xl:flex h-full w-1/3 theme-bg-surface" />
          </div>
        </div>
      </div>
    </div>
  );
}
