// import { DyteMeeting, Meeting } from "dyte-client";
import { useState } from "react";
import { WidgetType } from ".";
import { useAppSelector } from "../../hooks/redux.hook";
import { useToast } from "../../hooks/use-toast.hook";
import { User } from "../../types/user/minimal-user.type";
import { Widget } from "../../types/widget/widget.type";
import I18 from "../../_components/atoms/i18";
import SidebarCommunityCard from "../../_components/community/sidebar-community-card.component";
import { SelectWidgetModal } from "../../_components/group/select-group-widget-modal.component";
import ModalPortal from "../../_components/Portal";
import CustomWidget from "../../_components/widgets/custom-widget.components";
import LeaderBoardWidget from "../../_components/widgets/leaderboard-widget-component";
import TrendingPostWidget from "../../_components/widgets/trending-post-widget.component";
import UpcomingEventWidget from "../../_components/widgets/upcoming-events.widget.component";
import Validator from "../../_utils/validator";
import SidebarGroupMembersCard from "./group-members-card.component";
import { useAppService } from "../../hooks/use-app-service";
export const GROUPPAGE_ROUTE = "/group/:id";
export const GROUPPAGE_SLUG_ROUTE = "/g/:slug";
export const SECTIONPAGE_SLUG_ROUTE = "/g/:slug/s/:activeTabSlug";

/**
 * Generate Secondary Sidebar
 * @param {Object} user - The user object
 * @param {Object} group - The group object
 * @param {Object} community - The current community object
 * @param {Object} section - The current section object
 * @param {Array} members - List of group members
 * @param {Array} widgets - List of group widgets
 * @param {Array} closedSectionMembers - List of closed section members
 * @param {Number} closedSectionMembersCount - Count of closed section members
 * @param {Object} activeTabModel - The active section object
 * @param {function} addGroupMember - Callback to add a group member
 * @param {function} removeGroupMember - Callback to remove a group member
 * @param {function} removeClosedSectionMember - Callback to remove a closed section member
 * @param {function} updateGroupMember - Callback to update a group member
 * @param {function} updateClosedSectionMember - Callback to update a closed section member
 * @param {function} onWidgetListUpdate - Callback to update a group widget
 * @param {Function} addToast - Callback to add a toast
 * @returns
 */

interface Props {
  // group?: Group & { myRole: string };
  members?: Array<User & { groupStatus: string }>;
  widgets?: Array<Widget>;
  closedSectionMembers?: Array<User & { tabStatus?: string }>;
  closedSectionMembersCount?: Number | undefined;
  addGroupMember: (e: any) => void;
  updateGroupMember: (e: any) => void;
  updateClosedSectionMember: (e: any) => void;
  removeGroupMember: (e: any) => void;
  removeClosedSectionMember: (e: any) => void;
  onWidgetListUpdate: (e: any) => void;
}

export default function GroupSecondarySidebar({
  widgets,
  members = [],
  closedSectionMembers = [],
  closedSectionMembersCount,
  addGroupMember = (e: any) => {},
  updateGroupMember = (e: any) => {},
  updateClosedSectionMember = (e: any) => {},
  removeGroupMember = (e: any) => {},
  removeClosedSectionMember = (e: any) => {},
  onWidgetListUpdate = (e: any) => {},
}: Props) {
  const { user, group, community, activeTabModel } = useAppSelector((state) => {
    return {
      user: state.auth,
      group: state.activeGroup,
      community: state.community,
      activeTabModel: state.sections.activeSectionId
        ? state.sections.sections[state.sections.activeSectionId]
        : null,
    };
  });
  // flag to control visibility of add members modal
  const [addMembersVisible, setAddMembersVisible] = useState(false);
  const [isSelectWidgetVisible, setIsSelectWidgetVisible] = useState(false);

  const { addToast } = useToast();

  const { analyticsService } = useAppService();

  // generate joined members list
  const joinedMembers =
    activeTabModel && activeTabModel.isClosed
      ? closedSectionMembers.filter(
          (m) => m.tabStatus === "joined" || m.tabStatus === "requested"
        ) // show only joined members to closed group
      : members.filter(
          (m) => m.groupStatus === "joined" || m.groupStatus === "requested"
        );

  return (
    <div className="secondary-sidebar  pr-5">
      <div className="secondary-sidebar-content pb-8">
        {["admin", "moderator"].includes(group?.myRole ?? "") && (
          <>
            <div
              onClick={() => {
                analyticsService.track("click-select-widget", {
                  location: "group"
                });
                setIsSelectWidgetVisible(true);
              }}
              className="w-full flex place-content-center p-3 border border-dashed rounded theme-border-subtitle-2 mb-2 theme-text-subtitle-1 cursor-pointer">
              <I18>Select Widgets</I18>
            </div>
            {/* Add/Update customs group widgets pop-up */}

            <ModalPortal openPortal={isSelectWidgetVisible}>
              <SelectWidgetModal
                // @ts-ignore
                community={community}
                user={user}
                widgets={widgets}
                group={group}
                forCommunity={false}
                active={isSelectWidgetVisible}
                setActive={setIsSelectWidgetVisible}
                addToast={addToast}
                onWidgetListUpdate={onWidgetListUpdate}
              />
            </ModalPortal>
          </>
        )}

        {/* subscribers card */}
        {!community?.configuration?.hideCommunitySubscribersList ? (
          <SidebarCommunityCard
            addGroupMember={addGroupMember}
            updateGroupMember={updateGroupMember}
            removeGroupMember={removeGroupMember}
          />
        ) : (
          <></>
        )}

        {Validator.hasValue(widgets) && (
          <>
            {widgets!.map(
              (
                w: { isVisible: any; isVisibleToAdmin: any; type: any },
                index: number
              ) => {
                if (!group) {
                  return null;
                }
                if (!user && !w.isVisible) {
                  return null;
                } else if (["user"].includes(group.myRole) && !w.isVisible) {
                  return null;
                } else if (
                  ["admin"].includes(group.myRole) &&
                  !w.isVisibleToAdmin
                ) {
                  return null;
                }
                switch (w.type) {
                  case WidgetType.custom:
                    return <CustomWidget key={index} widget={w} />;
                  case WidgetType.members:
                    return (
                      <SidebarGroupMembersCard
                        key={index}
                        // @ts-ignore
                        members={members}
                        addMembersVisible={addMembersVisible}
                        setAddMembersVisible={setAddMembersVisible}
                        joinedMembers={joinedMembers}
                        closedSectionMembersCount={closedSectionMembersCount}
                        user={user}
                        group={group}
                        updateGroupMember={updateGroupMember}
                        updateClosedSectionMember={updateClosedSectionMember}
                        removeGroupMember={removeGroupMember}
                        removeClosedSectionMember={removeClosedSectionMember}
                        activeTabModel={activeTabModel}
                      />
                    );
                  // case WidgetType.topPosts:
                  //   return activeTabModel?.sectionType === "realtime" ? (
                  //     <></>
                  //   ) : (
                  //     <TrendingPostWidget
                  //       key={index}
                  //       group={group}
                  //       isCommunityPosts={undefined}
                  //     />
                  //   );
                  case WidgetType.upcomingEvents:
                    return (
                      <UpcomingEventWidget
                        key={index}
                        user={user}
                        community={community}
                        group={group}
                        isCommunityEvent={false}
                      />
                    );
                  // case WidgetType.communityMembers:
                  //   return (
                  //     <MemberWidget
                  //       community={community}
                  //       user={user}
                  //       memberPreviewLimit={4}
                  //     />
                  //   );
                  case WidgetType.leaderboard:
                    return (
                      <LeaderBoardWidget
                        key={index}
                        memberPreviewLimit={4}
                        group={group}
                        isCommunityLeaderBoard={false}
                      />
                    );

                  case WidgetType.communityLeaderboard:
                    return (
                      <LeaderBoardWidget
                        key={index}
                        memberPreviewLimit={4}
                        isCommunityLeaderBoard={true}
                        group={undefined}
                      />
                    );
                  default:
                    return null;
                }
              }
            )}
          </>
        )}
      </div>
    </div>
  );
}
