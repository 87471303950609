import React, { Suspense, useState } from "react";
import { Remarkable } from "remarkable";
import TurndownService from "turndown";
import { useAppService } from "../../../../hooks/use-app-service";
import { createPostDetailPageRouteFromSlug } from "../../../../_pages/post-detail.page";
import { MediaService } from "../../../../_service/media.service";
import { PostService } from "../../../../_service/post.service";
import { validatePost } from "../../../create-post-card.component";
import { ConvertToSlugString } from "../../../create-slug-modal";
import CreatePostToolbar from "../../create/component/create-post-toolbar";
import { ImagesPreview } from "../../create/component/image-preview";
import SelectedDocumentPreview from "../../create/component/post-document-preview";
import { PostEditor } from "../../create/component/post-editor.component";
import PostEmbedCode from "../../create/component/post-embed-code";
import { PostTitle } from "../../create/component/post-title-input";
import { CreatePostCustomButton } from "../../create/component/create-post-custom-buttons";
import { VideoPreviewWithImage } from "../../create/component/video-preview";
import { CoverPicture } from "../../create/component/cover-picture";
import PostAudioRecorder from "../../create/component/post-audio-recorder.component";
import MEDIA_TYPES from "../../../../_constants/mediaType";
const PostCreateHeader = React.lazy(() => import("../../create/component/post-create-header.component"));

/**
 * Edit post component
 * @param {Object} community - community object
 * @param {Object} user - Auth user object
 * @param {Object} postToUpdate - post to update
 * @param {Function} updatePost - update post callback
 * @param {Function} addToast - Display toast callback
 * @param {Function} setActive - Toggle modal active/inactive state
 * @param {Object} activeGroup - Group in which post is being created. If post is being created from community home page, group is undefined.
 */
export default function EditPostComponent({
  community,
  user,
  postToUpdate,
  updatePost = (post) => {},
  addToast,
  setActive,
  activeGroup,
}) {
  const { analyticsService } = useAppService();

  const md = new Remarkable();
  const tds = new TurndownService();
  const groupId =
    postToUpdate && postToUpdate.group ? postToUpdate.group.id : null;
  const tabId = postToUpdate ? postToUpdate.tabId : null;

  // title and description related
  const [title, setTitle] = useState(
    postToUpdate.title ? postToUpdate.title : ""
  );
  const [description, setDescription] = useState(
    postToUpdate.description
      ? community?.configuration?.useMdEditorForCreatePostDescription
        ? postToUpdate.description
        : md.render(postToUpdate.description)
      : ""
  );

  const [embedCode, setEmbedCode] = useState(
    postToUpdate.embedCode ? postToUpdate.embedCode : ""
  );
  const [embedCodeHeight, setEmbedCodeHeight] = useState(
    postToUpdate.embedCodeHeight ? postToUpdate.embedCodeHeight : ""
  );

  // file related
  const [images, setImages] = useState(postToUpdate.images ?? []);
  const [videos, setVideos] = useState(postToUpdate.videos ?? []);
  const [documents, setDocuments] = useState(postToUpdate.document ?? []);
  const [coverPicture, setCoverPicture] = useState(postToUpdate.banner ?? null);
  const [uploadedMedia, setUploadedMedia] = useState({
    images: postToUpdate.images ?? [],
    videos: postToUpdate.videos ?? [],
    document: postToUpdate.document ? [postToUpdate.document] : [],
    banner: postToUpdate.banner ? [postToUpdate.banner] : []
  });
  const [areFilesUploading, setAreFilesUploading] = useState(null);

  const [isRecorderVisible, setIsRecorderVisible] = useState(false);

  // Post Customization
  const Preferences = postToUpdate.preferences || {};
  const [hideComment, setHideComment] = useState(
    (Preferences && Preferences.hideComment) ?? false
  ); // hide comment
  const [hideLike, setHideLike] = useState(
    (Preferences && Preferences?.hideLikes) ?? false
  ); // hide like
  const [hideCreator, setHideCreator] = useState(
    (Preferences && Preferences?.hideCreator) ?? false
  ); // hide profile
  const [customDate, setCustomDate] = useState(
    postToUpdate.createdAt &&
      postToUpdate.createdAt &&
      new Date(postToUpdate.createdAt)
  ); // custom date
  const [hideDate, setHideDate] = useState(
    (Preferences && Preferences?.hideDate) ?? false
  ); // hide share

  // custom buttons related
  const [isButtonsSectionVisible, setIsButtonsSectionVisible] = useState(
    postToUpdate?.buttons?.length
  );
  const [buttons, setButtons] = useState(
    postToUpdate?.buttons?.length
      ? postToUpdate?.buttons
      : [
          {
            label: "",
            url: "",
            position: "top",
          },
        ]
  );

  // Slugs related
  const [slugModalActive, setSlugModalActive] = useState(false);
  const [slug, setSlug] = useState(
    postToUpdate.meta === undefined ? null : postToUpdate.meta.slug
  );
  const [slugTitle, setSlugTitle] = useState(
    postToUpdate.meta === undefined ? null : postToUpdate.meta.title
  );
  const [slugDescription, setSlugDescription] = useState(
    postToUpdate.meta === undefined ? null : postToUpdate.meta.description
  );
  const [OGTitle, setOGTitle] = useState(
    postToUpdate.meta === undefined ? null : postToUpdate.meta.openGraphTitle
  );
  const [OGDescription, setOGDescription] = useState(
    postToUpdate.meta === undefined
      ? null
      : postToUpdate.meta.openGraphDescription
  );
  const [OGImage, setOGImage] = useState(
    postToUpdate.meta === undefined ? null : postToUpdate.meta.openGraphImage
  );

  const [isEmbedEditorVisible, setIsEmbedEditorVisible] = useState(
    postToUpdate.embedCode ? true : false
  );
  const [isSavingPost, setIsSavingPost] = useState(false);
  const [errors, setErrors] = useState(null);

  const [removedMediaUploadsOnEdit, setRemovedMediaUploadsOnEdit] = useState([]);

  const canPost = (() => {
    if (areFilesUploading) return false;
    return [title, embedCode, description, images, videos, documents].some(arr => arr.length > 0)
  })();

  const submitToUpdatePost = async (event) => {
    try {
      // create post
      const embedlessDescription = description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );

      const postDescription = community?.configuration
        ?.useMdEditorForCreatePostDescription
        ? description
        : tds.turndown(embedlessDescription);

      const post = {
        title,
        description: postDescription,
        groupId,
        embedCode,
        embedCodeHeight,
        tabId,
        images,
        videos,
        document,
        meta: {
          slug: ConvertToSlugString(slug),
          title: slugTitle,
          description: slugDescription,
          openGraphTitle: OGTitle,
          openGraphDescription: OGDescription,
          openGraphImage: OGImage,
        },
        preferences: {
          hideComment: hideComment,
          hideLikes: hideLike,
          hideCreator: hideCreator,
          hideDate: hideDate,
        },
        createdAt: !customDate ? null : customDate,
      };

      const mediaTypes = ['images', 'videos', 'banner', 'document'];

      mediaTypes.forEach(type => {
          post[type] = uploadedMedia[type];
      });
      
      // add buttons to post
      if (isButtonsSectionVisible) {
        post.buttons = buttons;
      }

      // check for validation on post
      const e = validatePost(post, images, videos, documents);

      setErrors(e);
      if (e) {
        return;
      }

      // start loading
      setIsSavingPost(true);
      // create post
      let createdPostResponse = await PostService.updatePost(
        user,
        post,
        removedMediaUploadsOnEdit,
        postToUpdate.id
      );
      updatePost(createdPostResponse.post);
      analyticsService.track("edit-post", {
        groupName: postToUpdate.group.name,
        sectionName: postToUpdate.tab.name,
        postUrl:
          window.location.origin + createPostDetailPageRouteFromSlug(post),
      });
      // stop loading
      setIsSavingPost(false);
      addToast("Post updated successfully!");

      // close the modal
      setErrors({});
      setActive(false);
    } catch (error) {
      //
      setIsSavingPost(false);

      if (error && error.response && error.response.data.errors) {
        if (error.response.data.errors.title) {
          // setErrorMessage(error.response.data.errors.title[0])
          setErrors({ title: error.response.data.errors.title[0] });
        }
      } else {
        setErrors("Something went wrong!");
      }
    }
  };

  // used for uploading audio document
  const uploadDocument = async (documentToUpload) =>  {
    setAreFilesUploading(true);

    const res = await MediaService.uploadMedia(
      user,
      community.id,
      [documentToUpload],
      MEDIA_TYPES.DOCUMENT,
    );

    // directly spreading public urls since we have to clear old document if present while uploading
    setUploadedMedia(prevUploadedMedia => ({
      ...prevUploadedMedia,
      document: [...res.publicUrls]
    }));

    setAreFilesUploading(false);
  }

  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }

  return (
    <div className="CreatePostCard flex flex-col theme-bg-surface ">
      <Suspense fallback={<></>}>
        <PostCreateHeader
          isEditEnabled={true}
          setActive={setActive}
          community={community}
          slugModalActive={slugModalActive}
          setSlugModalActive={setSlugModalActive}
          slug={slug}
          setSlug={setSlug}
          title={title}
          slugTitle={slugTitle}
          setSlugTitle={setSlugTitle}
          description={description}
          slugDescription={slugDescription}
          setSlugDescription={setSlugDescription}
          OGTitle={OGTitle}
          setOGTitle={setOGTitle}
          OGDescription={OGDescription}
          setOGDescription={setOGDescription}
          OGImage={OGImage}
          setOGImage={setOGImage}
          clearSlugMeta={clearSlugMeta}
          group={activeGroup}
          // groups={groups}
          // selectedTab={selectedTab}
          // setSelectedTab={setSelectedTab}
          selectedGroup={activeGroup}
          disablePostInAnotherGroup={true} // disable post in another group for edit post
          hideLike={hideLike}
          setHideLike={setHideLike}
          hideComment={hideComment}
          setHideComment={setHideComment}
          hideCreator={hideCreator}
          setHideCreator={setHideCreator}
          hideDate={hideDate}
          setHideDate={setHideDate}
          customDate={customDate}
          setCustomDate={setCustomDate}
          areFilesUploading={areFilesUploading}
        />
      </Suspense>
      <div className="flex flex-col px-4">
      <CoverPicture
        user={user}
        community={community}
        coverPicture={coverPicture}
        setCoverPicture={setCoverPicture}
        setAreFilesUploading={setAreFilesUploading}
        setUploadedMedia={setUploadedMedia}
        uploadedMedia={uploadedMedia}
        setRemovedMediaUploadsOnEdit={setRemovedMediaUploadsOnEdit}
        isCoverPictureEdit={true}
        />
        <PostTitle
          title={title}
          setTitle={setTitle}
          description={description}
          errors={errors}
          setErrors={setErrors}
        />
        <ImagesPreview
          images={images}
          setImages={setImages}
          uploadedMedia={uploadedMedia}
          setUploadedMedia={setUploadedMedia}
          setRemovedMediaUploadsOnEdit={setRemovedMediaUploadsOnEdit}
        />
        <VideoPreviewWithImage
          videos={videos}
          setVideos={setVideos}
          uploadedMedia={uploadedMedia}
          setUploadedMedia={setUploadedMedia}
          areFilesUploading={areFilesUploading}
          setRemovedMediaUploadsOnEdit={setRemovedMediaUploadsOnEdit}
        />
        <div className="border-b theme-border-default mb-3" />
        <PostEditor
          description={description}
          setDescription={setDescription}
          user={user}
          community={community}
        />
        <SelectedDocumentPreview
          documents={documents}
          setDocument={setDocuments}
          setUploadedMedia={setUploadedMedia}
          areFilesUploading={areFilesUploading}
          setRemovedMediaUploadsOnEdit={setRemovedMediaUploadsOnEdit}
        />

        <PostEmbedCode
          isEmbedEditorVisible={isEmbedEditorVisible}
          embedCode={embedCode}
          setEmbedCode={setEmbedCode}
          embedCodeHeight={embedCodeHeight}
          setEmbedCodeHeight={setEmbedCodeHeight}
          isVisible={true}
        />

        {/* custom buttons */}
        {isButtonsSectionVisible ? (
          <CreatePostCustomButton buttons={buttons} setButtons={setButtons} />
        ) : (
          <></>
        )}
        <PostAudioRecorder
          isRecorderVisible={isRecorderVisible}
          setIsRecorderVisible={setIsRecorderVisible}
          setRecording={(recording) => {
            uploadDocument(recording);
            setDocuments([recording]);
          }}
        />
      </div>
      <CreatePostToolbar
        user={user}
        community={community}
        images={images}
        setImages={setImages}
        videos={videos}
        setVideos={setVideos}
        documents={documents}
        setDocument={setDocuments}
        isEmbedEditorVisible={isEmbedEditorVisible}
        areFilesUploading={areFilesUploading}
        setAreFilesUploading={setAreFilesUploading}
        setUploadedMedia={setUploadedMedia}
        removedMediaUploadsOnEdit={removedMediaUploadsOnEdit}
        setRemovedMediaUploadsOnEdit={setRemovedMediaUploadsOnEdit}
        uploadedMedia={uploadedMedia}
        setIsEmbedEditorVisible={
          postToUpdate.embedCode ? setIsEmbedEditorVisible : null
        }
        isRecorderVisible={isRecorderVisible}
        setIsRecorderVisible={setIsRecorderVisible}
        isPollVisible={false}
        setIsPollVisibleVisible={null}
        addToast={addToast}
        disableSubmit={!canPost}
        isSavingPost={isSavingPost}
        onSubmitPost={submitToUpdatePost}
        isButtonsSectionVisible={isButtonsSectionVisible}
        setIsButtonsSectionVisible={setIsButtonsSectionVisible}
        submitButtonLabel="Update"
      />
    </div>
  );
}
