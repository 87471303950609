import { useAppDispatch } from "../../../../hooks/redux.hook";
import {
  setCurrentSectionPosts,
  updateSectionPinnedPost,
} from "../../../../_store/sections.store";
import I18 from "../../../atoms/i18";
import GridPostsList from "../../../post/grid-post-list-component";
import { PostCardList } from "../../../post/post-card-list.component";
import PostListShimmer, {
  PostTileType,
} from "../../../shimmers/post/post-list-shimmer.component";

/**
 * @description - Display list of posts in section detail page
 * @param {any} user - user object
 * @param {any} activeTabModel - active section object
 * @param {Array<any>} posts - list of posts in section
 * @param {Array<any>} pinnedPosts - list of pinned posts in section
 * @param {boolean} isLoadingPosts - Flag to check if posts are loading
 * @param {boolean} noMorePosts - Flag to check if there are more posts left to load
 * @param {boolean} isLoadingMorePosts - Flag to check if more posts are loading
 */
export default function SectionPostList({
  user,
  activeTabModel,
  posts,
  pinnedPosts,
  noMorePosts = false,
  isLoadingPosts = false,
  isLoadingMorePosts = false,
}) {
  const dispatch = useAppDispatch();
  if (activeTabModel.sectionView === "grid") {
    return (
      <>
        <GridPostsList
          user={user}
          isLoading={isLoadingPosts}
          allPosts={posts}
          posts={posts}
        />
        <>
          {isLoadingMorePosts ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-2 sm:px-0 PostList  p-2 rounded my-2 ">
              {Array.from({ length: 6 }).map((_, i) => (
                <PostListShimmer
                  key={i}
                  length={1}
                  type={PostTileType.compact}
                />
              ))}
            </div>
          ) : (
            <div className="h-96"></div>
          )}
        </>
      </>
    );
  }
  return (
    <>
      <div>
        {/* pinned posts */}
        <PostCardList
          posts={pinnedPosts}
          allPosts={posts}
          onPostUpdate={(post, list) => {
            if (post.isPinned) {
              dispatch(updateSectionPinnedPost(post));
            }
            if (list) {
              dispatch(setCurrentSectionPosts(list));
            }
          }}
        />
        {/* normal posts */}
        <PostCardList
          posts={posts.filter((p) => !p.isPinned)}
          allPosts={posts}
          isLoading={isLoadingPosts}
          placeholder={
            <PostListShimmer length={6} type={PostTileType.comfortable} />
          }
          footerElement={
            <>
              {isLoadingMorePosts && (
                <div className="PostList theme-bg-surface p-2 rounded my-2">
                  <PostListShimmer length={3} type={PostTileType.compact} />
                </div>
              )}
              {noMorePosts && (
                <div className="h-32 flex items-center place-content-center theme-text-subtitle-1 text-xs">
                  <I18>
                    Looks like you have reached the end of the list, No more
                    post to display
                  </I18>
                </div>
              )}
            </>
          }
          onPostUpdate={(post, list) => {
            if (post.isPinned) {
              dispatch(updateSectionPinnedPost(post));
            }
            if (list) {
              dispatch(setCurrentSectionPosts(list));
            }
          }}
        />
      </div>
    </>
  );
}
