import cx from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastTypes, withToast } from "../contexts/toastr.context";
import plusGrayIcon from "../_assets/vectors/plus-gray-icon.svg";
import { PostService } from "../_service";
import I18 from "./atoms/i18";
import { Button } from "./button.component";
import { Radio } from "./form-controls/radio.component";
import IconButton from "./icon-button.component";
import { Modal } from "./modal.component";
import CopyTextField from "./molecule/copy-text-field.component";
import ShareOnSocialMedia from "./molecule/share-on-social-media.component";

function SharePostModalComponent({
  heading = "",
  text = "",
  active = false,
  setActive = (e) => {},
  onClose = (e) => {},
  groups,
  user,
  addToast,
  post,
}) {
  const [showSectionList, setShowSectionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  // const availableGroups = groups.filter((group) => {
  //   if(group)
  // });

  if (!active) {
    return null;
  }

  const availableGroups =
    groups !== undefined
      ? groups.filter(
          (group) =>
            ["admin", "moderator", "user"].includes(group.myRole) &&
            group.joinStatus === "joined"
        )
      : undefined;
  function reSharePost() {
    setIsLoading(true);
    const rePost = {
      sharedId: post.id,
      groupId: selectedGroup,
      tabId: showSectionList.length > 0 ? showSectionList[0] : "",
      title: post.title ?? "",
    };
    PostService.createPost(user, rePost)
      .then((res) => {
        addToast("Post shared successfully", "", ToastTypes.success);
        setActive(false);
      })
      .catch((err) => {
        console.log("Error creating post: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      className="SharePostModal"
      active={active}
      setActive={setActive}
      padding={false}>
      <div className="p-1 pl-4 theme-bg-surface flex justify-between items-center space-x-2">
        <span className=" font-semibold">{heading}</span>
        <IconButton icon={plusGrayIcon} rotate={45} onClick={onClose} />
      </div>
      <div className="flex flex-col space-y-2 px-4 py-2">
        <div className="font-semibold">
          <I18>Share via URL</I18>
        </div>

        <CopyTextField text={text} className="theme-bg-surface" />
      </div>
      <ShareOnSocialMedia text={text} />

      {/* Group and section list */}
      {/* Hide share post for temp fix */}
      <div className="flex flex-col space-y-2 px-4 py-2">
        <div className="font-semibold">
          <I18>Reshare in other channels</I18>
        </div>
        <div className="">
          <div className="overflow-scroll h-80 theme-bg-surface">
            {availableGroups && availableGroups.length > 0
              ? groups.map((group, index) => {
                  return (
                    <div
                      key={group?.id}
                      className="py-1 px-4 theme-bg-surface rounded">
                      <div className="theme-text-heading-1 text-sm font-semibold">
                        {group.name}
                      </div>
                      {group.tabs.map((tab, index) => {
                        // Restrict user to reShare post in realtime section or closed group
                        const disable =
                          (group.groupType === "closed" &&
                            group.joinStatus !== "joined") ||
                          tab.sectionType === "realtime";

                        return (
                          <React.Fragment key={tab?.id}>
                            {!["admin", "moderator"].includes(group.myRole) &&
                            !tab?.isJoined &&
                            tab?.isPrivate ? null : (
                              <div key={index} className=" mt-1">
                                <Radio
                                  className={`my-2 mr-5 ${
                                    disable ? "disabled cursor-not-allowed" : ""
                                  }`}
                                  selected={showSectionList.includes(tab.id)}
                                  onClick={() => {
                                    if (disable) {
                                      return;
                                    }
                                    if (showSectionList.includes(tab.id)) {
                                      setShowSectionList([]);
                                      setSelectedGroup("");
                                    } else {
                                      setShowSectionList([tab.id]);
                                      setSelectedGroup(group.id);
                                    }
                                  }}>
                                  <div
                                    className={`flex items-center ${
                                      disable ? "theme-text-subtitle-2" : ""
                                    }`}>
                                    <div
                                      className={cx("emoji", {
                                        hash: !tab.emoji || tab.emoji === "#",
                                      })}>
                                      {tab.emoji ? tab.emoji : "#"}
                                    </div>
                                    <span
                                      className={`${
                                        // disableState ? "theme-text-subtitle-2" : ""
                                        ""
                                      } pl-3`}>
                                      {tab.name}
                                    </span>
                                  </div>
                                </Radio>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
      {/* Hide share post for temp fix */}
      <div className="flex justify-between p-4">
        <button
          label="Cancel"
          onClick={() => {
            setActive(false);
          }}>
          <I18>Cancel</I18>
        </button>
        <Button
          label="Save"
          disabled={showSectionList.length === 0}
          onClick={() => {
            reSharePost();
          }}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth,
  community: state.community,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const SharePostModal = withToast(
  connect(mapStateToProps, mapDispatchToProps)(SharePostModalComponent)
);

export { SharePostModal };
