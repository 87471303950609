export const PostTileType = {
  default: 0,
  comfortable: 1,
  compact: 2,
  expanded: 3,
};

/**
 * PostTileShimmer
 * @param {PostTileType} type
 */
function PostTileShimmer({ type = PostTileType.default }) {
  let margin, padding;
  let displayThirdLine = true;

  switch (type) {
    case PostTileType.default:
      margin = "my-3";
      padding = "p-4";
      break;
    case PostTileType.comfortable:
      margin = "my-2";
      padding = "p-3";
      break;
    case PostTileType.compact:
      margin = "my-1";
      padding = "p-2";
      displayThirdLine = false;
      break;

    default:
      margin = "my-3";
      padding = "p-4";
      break;
  }

  if (type === PostTileType.expanded) {
    return <ExpandPost />;
  }

  return (
    <div className={margin + "rounded " + padding}>
      {/* User Tile */}
      <div className="flex place-items-center">
        <p className="w-8 h-8 theme-bg-disable rounded-full "></p>
        <div className="w-max ml-2 space-y-1 ">
          <p className="w-16 h-2 theme-bg-disable rounded"></p>
          <div className="w-8 h-2 mt-2  theme-bg-disable rounded" />
        </div>
      </div>

      {/* Description */}
      <div className="w-64 h-2 theme-bg-disable rounded mt-2" />
      <div className="w-32 h-2 theme-bg-disable rounded mt-2" />
      {displayThirdLine ? (
        <div className="w-48 h-2 theme-bg-disable rounded mt-4" />
      ) : null}

      {/* Like comment shimmer */}

      <div>
        <div className="w-full h-0.5 bg-gray-100 rounded mt-6" />
        <div className="flex place-items-center ">
          <div className="w-4 h-4 bg-gray-200 theme-border-default border-2 rounded mt-2 " />
          <div className="w-12 h-3 bg-gray-200 rounded mt-2 m-l-2 ml-2" />

          <div className="w-4 h-4 bg-gray-200 rounded mt-2 ml-6" />
          <div className="w-12 h-3 bg-gray-200 rounded mt-2 m-l-2 ml-2" />
        </div>
      </div>
    </div>
  );

  function ExpandPost() {
    return (
      <div className={"my-3 rounded px-6 pt-4"}>
        {/* User Tile */}
        <div className="flex place-items-center">
          <p className="w-8 h-8 theme-bg-disable rounded-full "></p>
          <div className="w-max ml-2 space-y-1 ">
            <p className="w-16 h-2 theme-bg-disable rounded"></p>
            <div className="w-8 h-2 mt-2  theme-bg-disable rounded" />
          </div>
        </div>

        {/* Space */}
        <div className="my-6" />

        {/* Description */}
        <div className="w-1/3 h-2 theme-bg-disable rounded mt-2" />
        <div className="w-1/2 h-2 theme-bg-disable rounded mt-2" />
        <div className=" h-2 theme-bg-disable rounded mt-4" />

        {/* Space */}
        <div className="h-5" />

        {/* Like comment shimmer */}
        <div className="flex space-x-4">
          <div className="w-16 h-2 theme-bg-disable rounded mt-2" />
          <div className="w-16 h-2 theme-bg-disable rounded mt-2" />
        </div>
        <div>
          {/* Divider */}
          {/* <div className="w-full h-0.5 bg-gray-100 rounded mt-6" /> */}

          {/* Action Button */}
          <div className="flex place-items-center border-t theme-border-default my-4 py-2">
            <div className="w-4 h-4 theme-bg-disable  rounded mt-2 " />
            <div className="w-12 h-3 theme-bg-disable rounded mt-2 m-l-2 ml-2" />

            <div className="w-4 h-4 theme-bg-disable rounded mt-2 ml-6" />
            <div className="w-12 h-3 theme-bg-disable rounded mt-2 m-l-2 ml-2" />
          </div>
        </div>
      </div>
    );
  }
}

export default PostTileShimmer;
