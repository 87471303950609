import axios from "axios";
import { endpoints } from "../_config";

class AuthService {
  static async requestOTP(
    countryCode,
    mobile,
    communityId = null,
    captchaToken
  ) {
    return axios
      .post(endpoints.auth.loginWithOTP, {
        countryCode,
        mobile,
        communityId,
        captchaToken,
      })
      .then((response) => response.data);
  }

  static async requestEmailOTP(email, communityId = null) {
    return axios
      .post(endpoints.auth.loginWithEmailOTP, {
        email,
        communityId,
      })
      .then((response) => response.data);
  }

  static async verifyOTP(countryCode, mobile, otp) {
    return axios
      .post(endpoints.auth.verifyOTP, {
        countryCode,
        mobile,
        otp,
      })
      .then((response) => response.data);
  }

  static async verifyEmailOTP(countryCode, email, otp) {
    return axios
      .post(endpoints.auth.verifyOTP, {
        countryCode,
        email,
        otp,
      })
      .then((response) => response.data);
  }

  /**
   * Google login v2
   * @param {*} accessToken
   * @returns
   */
  static async googleLoginV2(accessToken) {
    return axios
      .post(
        endpoints.auth.googleLoginV2,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Facebook login
   * @param {*} accessToken
   * @returns
   */
  static async facebookLogin(accessToken) {
    return axios
      .post(
        endpoints.auth.facebookLogin,
        {},
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * web3Login login
   * @param {*} idToken
   * @returns
   */
  static async web3Login(idToken) {
    return axios
      .post(
        endpoints.auth.web3Login,
        {},
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      )
      .then((response) => response.data);
  }
}

export { AuthService };
