import React, { Suspense } from "react";
import { MentionService } from "../../../../_service/mention.service";
import { Loader } from "../../../loader.component";
const RichTextEditor = React.lazy(() => import("../../../form-controls/rich-text-editor.component"));
const MDEditor = React.lazy(() => import("../../../form-controls/md-editor.component"));

/**
 *  Post description component
 * @param {string} description - post description string
 * @param {Function} setDescription - function to set description
 * @param {object} community - community object
 * @param {object} user - user object
 */
function PostEditor({ user, community, description, setDescription }) {
  return community?.configuration?.useMdEditorForCreatePostDescription ? (
    <Suspense fallback={<></>}>
        <MDEditor
        text={description}
        setText={setDescription}
        getMentionsFeed={async (query) => {
          const users = (
            await MentionService.searchInCommunity(user, community.id, query)
          ).users.map((user, index) => {
            const useruid = user.id;
            user.id = "@" + user.userId;
            user.userId = useruid;
            return user;
          });
          return users;
        }}
      />
    </Suspense>
  ) : (
    <div className="CreatePostEditor">
     <Suspense fallback={<></>}>
      <RichTextEditor
          className=" noBorder placeholder:font-md"
          text={description}
          setText={setDescription}
          getMentionsFeed={getMentionsFeed(user, community)}
          placeholder={`What's on your mind, ${user.name}?`}
        />
     </Suspense>
    </div>
  );
}

function getMentionsFeed(user, community) {
  return async (query) => {
    const users = (
      await MentionService.searchInCommunity(user, community.id, query)
    ).users.map((user, index) => {
      const useruid = user.id;
      user.id = "@" + user.userId;
      user.userId = useruid;
      return user;
    });
    return users;
  };
}

export { PostEditor, getMentionsFeed };
