import cx from "classnames";
import { useState } from "react";
import { Modal } from "../modal.component";

import { connect } from "react-redux";
import { Button } from "..";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { CommunityService } from "../../_service";
import { DetailedAvatar } from "../avatar.component";
import { Checkbox } from "../form-controls/checkbox.component";
import IconButton2 from "../icon-button-2-component";

function MakeAdminModalComponent({
  activeGroup = null,
  addToast = (e) => {},
  user = null,
  groups = [],
  active = false,
  community = null,
  setActive = (e) => {},
  selectedUser = null,
  updateGroupMember = (e) => {},
  updateSubscriber = null,
}) {
  const [selectedChoice, setSelectedChoice] = useState(null); // 1 for whole community, 2 for further choices

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [error, setError] = useState("");

  const resetFormAndClose = () => {
    setActive(false);
  };

  if (!selectedUser) return null;

  return (
    <Modal
      className="MakeAdminModal"
      active={active}
      setActive={setActive}
      padding={false}>
      {/* headers */}
      <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
        <span className="font-bold theme-text-heading-1">
          Make {selectedUser.name} admin
        </span>
        <IconButton2
          hoverable={true}
          icon="cross"
          size="sm"
          onClick={() => {
            resetFormAndClose();
          }}
        />
      </div>
      {/* body */}
      <div className="p-4">
        {/* user details */}
        <div className="theme-bg-default">
          <DetailedAvatar
            noBG
            loggedInUser={user}
            user={selectedUser}
            size={50}
            showDetailsOnHover
          />
        </div>
        {/* options */}
        <div className="my-5">
          {/* for whole community */}
          <div
            className={cx(
              "flex p-2 my-2 border theme-border-default rounded cursor-pointer",
              {
                "theme-border-primary": selectedChoice === 1,
                "theme-border-default": selectedChoice !== 1,
                "hover:theme-border-default": selectedChoice !== 1,
              }
            )}
            onClick={(e) => {
              setSelectedChoice(1); // 1 is for all
            }}>
            <Checkbox selected={selectedChoice === 1} />
            <div className="px-1">
              <div className="theme-text-heading-1 font-semibold">
                For whole community
              </div>
              <div className="text-xs theme-text-subtitle-2">
                Admin of community can create, edit or delete groups and
                channel. Create post in any group or channel.
              </div>
            </div>
          </div>
          {/* for specific groups */}
          <div
            className={cx("p-2 my-2 border rounded cursor-pointer", {
              "theme-border-primary": selectedChoice === 2,
              "theme-border-default": selectedChoice !== 2,
              "hover:theme-border-default": selectedChoice !== 2,
            })}
            onClick={(e) => {
              setSelectedChoice(2); // 2 is for all
            }}>
            <div className="flex">
              <Checkbox selected={selectedChoice === 2} />
              <div className="px-1">
                <div className="theme-text-heading-1 font-semibold">
                  For selected groups
                </div>
                <div className="text-xs theme-text-subtitle-2">
                  Admin of selected group can edit the group information and
                  create, edit or delete channel under selected groups.
                </div>
              </div>
            </div>
            {/* show group list to the user */}
            {selectedChoice === 2 ? (
              <div className="border-t theme-border-default  px-4 mt-3">
                {groups.map((group) => (
                  <div
                    className="flex my-3 text-xs"
                    key={group.id}
                    onClick={(e) => {
                      // toggle the group id in selectedGroups array
                      const index = selectedGroups.indexOf(group.id);
                      if (index === -1) {
                        setSelectedGroups([...selectedGroups, group.id]);
                      } else {
                        setSelectedGroups(
                          selectedGroups.filter((id) => id !== group.id)
                        );
                      }
                    }}>
                    <Checkbox selected={selectedGroups.includes(group.id)} />
                    <div className="mt-1 theme-text-subtitle-1 font-semibold">
                      {group.name}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {error ? <div className="theme-text-danger">{error}</div> : <></>}
        {/* action buttons */}
        <div className="flex items-center justify-between">
          <div
            className="p-2 cursor-pointer theme-text-subtitle-1 hover:border hover:theme-border-primary"
            onClick={resetFormAndClose}>
            Cancel
          </div>
          <Button
            label="Update"
            onClick={(e) => {
              // do the magic
              if (selectedChoice === 1) {
                // call the make community admin api
                CommunityService.makeAdminOfWholeCommunity(
                  user,
                  community.id,
                  selectedUser.id
                )
                  .then((res) => {
                    addToast(
                      "Role successfully updated",
                      "",
                      ToastTypes.success
                    );
                    updateGroupMember({ ...selectedUser, groupRole: "admin" });
                    if (updateSubscriber) {
                      updateSubscriber({
                        ...selectedUser,
                        groupRole: "admin",
                        communityRole: "admin",
                      });
                    }
                    resetFormAndClose();
                  })
                  .catch((err) => {
                    addToast(err.message, "", ToastTypes.danger);
                  });
              } else if (selectedChoice === 2) {
                // call the make specific group admin api
                CommunityService.makeAdminOfWholeCommunity(
                  user,
                  community.id,
                  selectedUser.id,
                  selectedGroups
                )
                  .then((res) => {
                    addToast(
                      "Role successfully updated",
                      "",
                      ToastTypes.success
                    );
                    // update user if he is in any of the selected groups
                    if (selectedGroups.includes(activeGroup.id)) {
                      updateGroupMember({
                        ...selectedUser,
                        groupRole: "admin",
                      });
                    }
                    resetFormAndClose();
                  })
                  .catch((err) => {
                    addToast(err.message, "", ToastTypes.danger);
                  });
              }
            }}
            disabled={
              !selectedChoice ||
              (selectedChoice === 2 && selectedGroups.length === 0)
            }
          />
        </div>
      </div>
    </Modal>
  );
}

const MakeAdminModal = withToast(
  connect((s) => ({ user: s.auth, groups: s.groups, community: s.community }))(
    MakeAdminModalComponent
  )
);

export default MakeAdminModal;
