export const nlDutch = {
  "Pensil Keyword": "Pensil -Schlüsselwort",
  "Home Page Left column": "Startpagina linkerkolom",
  Home: "Thuis",
  "Explore Groups": "Verken groepen",
  Events: "Evenementen",
  Groups: "Groepen",
  Links: "Links",
  "Direct Message": "Direct bericht",
  "Powered by": "Aangedreven door",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Geen groepen hier, gebruik + om een ​​nieuwe te maken, of word lid van een, zie ze hier.",
  "Please join a group to see them here":
    "Word lid van een groep om ze hier te zien",
  "Channel can only be reordered within the group!":
    "Sectie kan alleen worden opnieuw geordend binnen de groep!",
  "Group Page": "Groepspagina",
  "Invite Members": "Nodig leden uit",
  Join: "Meedoen",
  Joined: "Samengevoegd",
  "Create new Channel": "Maak een nieuw gedeelte",
  "Group Setting drop down": "Groepsinstelling vervolgdirect",
  Share: "Deel",
  "Edit group": "Bewerkingsgroep bewerken",
  "Delete group": "Verwijder groep",
  Leave: "Vertrekken",
  "Are you sure you want to delete the group?":
    "Weet u zeker dat u de groep wilt verwijderen?",
  Cancel: "Annuleren",
  "Yes, Go Ahead": "Ja toe maar",
  "Share via link": "Deel via link",
  Copy: "Kopiëren",
  Copied: "Gekopieerd",
  "Share on social media": "Deel op sociale media",
  "Add Channel": "Sectie toevoegen",
  "Channel Header": "Sectie header",
  Live: "Live",
  "Create Post": "Maak een bericht",
  "Notificaiton Bell": "Notificaiton Bell",
  "All posts": "Alle berichten",
  "Admin posts only": "Admin -berichten alleen",
  None: "Geen",
  "Channel Setting drop down": "Sectie -instelling vervolgkeuzemen",
  "Edit Channel": "Bewerk sectie",
  "Embed Channel": "Insluiten sectie",
  "Delete Channel": "Sectie verwijderen",
  "Are you sure you want to delete the Channel?":
    "Weet u zeker dat u de sectie wilt verwijderen?",
  "Channel deleted!": "Sectie verwijderd!",
  "Create Group Pop up / Update": "Maak een groep pop -up / update",
  "Create group": "Maak een groep",
  "Basic Detail": "Basisdetail",
  Settings: "Instellingen",
  "Size 16:9 or 1600 by 900": "Maat 16: 9 of 1600 bij 900",
  "Group name": "Groepsnaam",
  "Enter group name": "Voer de groepsnaam in",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Suggesties: spaties, kanalen, groepen, blog, kennisbasis, enz",
  "Group Description": "groepsomschrijving",
  "Enter description here": "Voer hier de beschrijving in",
  "Group Type": "Groepstype",
  Open: "Open",
  "Visible to all & anyone can join":
    "Zichtbaar voor iedereen en iedereen kan meedoen",
  Closed: "Gesloten",
  "User will have to request to join the group":
    "Gebruiker zal moeten vragen om lid te worden van de groep",
  Paid: "Betaald",
  "Group Price": "Groepsprijs",
  "Currency (Default: INR)": "Valuta (standaard: INR)",
  "User will have to pay to join the group":
    "Gebruiker zal moeten betalen om lid te worden van de groep",
  "Make this a private group": "Maak dit een privégroep",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Particuliere groep is alleen zichtbaar voor leden die er deel van uitmaken. Admin kan leden handmatig toevoegen van de ledenlijst",
  "Upload from device": "Upload van apparaat",
  "Search image": "Zoekafbeelding",
  "Valid group name required!": "Geldige groepsnaam vereist!",
  "Update Group": "Update -groep",
  "SEO Settings": "SEO -instellingen",
  "Custom URL": "Eigen URL",
  "Meta Title": "Meta -titel",
  "Maximum length of 255 characters allowed":
    "Maximale lengte van 255 tekens toegestaan",
  "Meta Description": "Meta omschrijving",
  "Open Graph Detail": "Open grafische details",
  "Open Graph Title": "Open grafiektitel",
  "Open Graph Description": "Open grafiekbeschrijving",
  "Open Graph Image": "Open grafiekafbeelding",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Aanbevolen afmetingen: 1200 px bij 630 px met een beeldverhouding van 1,91: 1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Als er geen URL wordt gebruikt, wordt uw groepsminiatuur automatisch geplukt.",
  "Create Channel Pop up / Update": "Sectie maken Pop -up / update",
  "Create Channel": "Sectie maken",
  "Channel Name": "sectie naam",
  "Suggestions: General, Introductions, Community Guidelines":
    "Suggesties: Algemene, inleidingen, Gemeenschapsrichtlijnen",
  "Channel Type": "Sectietype",
  "Post Type": "Posttype",
  "Group Chat": "Groeps chat",
  "Who can post in this Channel?": "Wie kan in dit gedeelte posten?",
  "Only Admin": "Alleen admin",
  "Any user": "Elke gebruiker",
  "Sorting order for Channel": "Sorteervolgorde voor sectie",
  "Latest first": "Laatste eerste",
  "Oldest post first": "Oudste post eerst",
  "Channel Appearance": "Channel -verschijning",
  "List View": "Lijstweergave",
  "Grid View": "Rasterweergave",
  "Make this closed Channel": "Maak dit gesloten gedeelte",
  "Members will have to request access to see Channel content":
    "Leden zullen toegang moeten vragen om de inhoud van de sectie te zien",
  "Make this a private Channel": "Maak dit een privésectie",
  "Disables requests (Make sure closed Channel is selected as well).":
    "Schakelt verzoeken uit (zorg ervoor dat ook gesloten sectie is geselecteerd).",
  "Post Editor": "Posteditor",
  "Create new post in": "Maak een nieuwe post in",
  "Add Cover Picture": "Voeg coverfoto toe",
  "Heading (Optional)": "Regel (optioneel)",
  "Write here..": "Schrijf hier..",
  Picture: "Afbeelding",
  Video: "Video",
  Attachment: "Bijlage",
  Poll: "Peiling",
  "Embed Code": "Embed code",
  Tags: "Tags",
  "Toggle to question": "Schakel om te vragen",
  "Poll expire time": "Peiling vervalt de tijd",
  "Poll question": "Peilvraag",
  "Enter Question": "Voer vraag in",
  Question: "Vraag",
  Option: "Keuze",
  Options: "Opties",
  "Add Option": "Optie toevoegen",
  "Poll Expiry time": "Poll vervaltijd",
  "12 Hours": "12 uren",
  "1 Day": "1 dag",
  "2 Days": "2 dagen",
  "1 Week": "1 week",
  "2 Weeks": "2 weken",
  "Always Open": "Altijd open",
  "Write embeddable code": "Schrijf insluitbare code",
  "Click here to add embed code.": "Klik hier om insluitcode toe te voegen.",
  "Paste your embed code here!": "Plak hier uw inbeddingscode!",
  "Post Settings": "Post -instellingen",
  "Update Post": "Update post",
  Update: "Update",
  "Start meeting / Video call / Live":
    "Begin met vergadering / video -oproep / live",
  "Meeting Name": "Ontmoeting naam",
  "Suggestion: Group discussion etc": "Suggestie: groepsdiscussie enz",
  Description: "Beschrijving",
  "Enter description here (optional)":
    "Voer hier de beschrijving in (optioneel)",
  "When do you want to start the session": "Wanneer wil je de sessie starten?",
  "Right now": "Direct",
  "Schedule for later": "Schema voor later",
  "Group video call": "Groepvideo -oproep",
  Webinar: "Webinar",
  "Go Live": "Ga leven",
  "Info on the Post": "Info over het bericht",
  "Post comment": "Plaats een reactie",
  Comment: "Opmerking",
  Comments: "Opmerkingen",
  Reply: "Antwoord",
  Replies: "Antwoorden",
  "Share via URL": "Deel via URL",
  "Reshare in other Channels": "Reshare in andere secties",
  "Post Card Drop Down": "Postkaart vervolgdirect",
  Bookmark: "Bladwijzer",
  "Pin post": "Pin bericht",
  Edit: "Bewerking",
  "Embed Post": "Insluiten",
  Delete: "Verwijderen",
  "Remove Pin": "Verwijder de pin",
  "Remove Bookmark": "Verwijder een bladwijzer",
  "Just copy paste this code wherever you want it to be embedded":
    "Kopieer gewoon deze code plakken waar u maar wilt worden ingesloten",
  Height: "Hoogte",
  Preview: "Voorbeeld",
  "Copied to clipboard": "Gekopieerd naar het klembord",
  "Select Widgets": "Selecteer widgets",
  "Add widgets to": "Voeg widgets toe aan",
  "You can change their order by dragging & dropping the widgets over each other":
    "U kunt hun bestelling wijzigen door de widgets over elkaar te slepen en te laten vallen",
  "Please note: Widget wont be displayed if there is no data available,":
    "Let op: Widget zal niet worden weergegeven als er geen gegevens beschikbaar zijn,",
  "Group Members": "Groepsleden",
  "Group Leaderboard": "Groepsleaderbord",
  "Community Leaderboard": "Community Leaderboard",
  "Trending Posts": "Trending -berichten",
  "Group Events": "Groepsevenementen",
  "Add Custom": "Voeg gewoonte toe",
  "Save Widgets": "Bewaar widgets",
  "Visible to user": "Zichtbaar voor gebruiker",
  "Visible to admin": "Zichtbaar voor admin",
  "Add widget to": "Voeg widget toe aan",
  "Upload cover picture": "Upload omslagfoto",
  "1:1 aspect ration (recommended)": "1: 1 aspect rantsoen (aanbevolen)",
  "Card Title": "Kaarttitel",
  "Enter widget name": "Voer widgetnaam in",
  "Small Description (try to keep it under 50 words)":
    "Kleine beschrijving (probeer het onder de 50 woorden te houden)",
  "Enter description": "Voer de beschrijving in",
  "Button Title": "Knooptitel",
  "Enter button name": "Voer de knopnaam in",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestie: meer weten, meedoen, solliciteren, enz",
  "URL link": "URL -link",
  "Create custom widget": "Maak een aangepaste widget",
  "Custom widget created successfully": "Aangepaste widget met succes gemaakt",
  Widgets: "Widgets",
  Members: "Leden",
  "See All": "Alles zien",
  "Communtiy Top Contributors": "CommunTiy topbijdragers",
  "Community Members": "Leden van de gemeenschap",
  "Group Top Contributors": "Groep topbijdragers",
  "Upcoming Events": "aankomende evenementen",
  "Home Page Banner": "Startpagina banner",
  "Edit Banner": "Banner bewerken",
  "Home page Edit Banner Pop up": "Startpagina bewerken banner verschijnt",
  "Update Banner": "Update banner",
  Search: "Zoekopdracht",
  "Upload Image": "Afbeelding uploaden",
  Remove: "Verwijderen",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Bannerafbeelding (gebruik 16: 6 ratio of 1600px bij 600px afbeelding voor de beste weergave)",
  "Banner Title": "Banner -titel",
  "Enter here": "Kom hier binnen",
  "Suggestion: Welcome to my community etc":
    "Suggestie: welkom bij mijn gemeenschap enz",
  "Banner Description": "Banner Beschrijving",
  "Enter banner description": "Voer de bannerbeschrijving in",
  "Save Banner Info": "Bannerinfo opslaan",
  "Home Page Feed": "Startpagina feed",
  Recent: "Recentelijk",
  "Top Posts": "Topberichten",
  Trending: "Trending",
  "Home Feed Groups": "Home Feed Groups",
  "Header BarProfile Drop down": "Header barprofile druppel naar beneden",
  "My Profile": "Mijn profiel",
  Logout: "Uitloggen",
  Notification: "Kennisgeving",
  Notifications: "Meldingen",
  "Mark all viewed": "Markeer alle bekeken",
  "has created a post": "heeft een bericht gemaakt",
  "wants to join": "wil meedoen",
  "has commented on your post": "heeft commentaar gegeven op je bericht",
  "has replied to your comment": "heeft op uw opmerking gereageerd",
  "Search for tags": "Zoek naar tags",
  "All groups": "Alle groepen",
  "Search result": "Zoekresultaat",
  "No post found for selected hashtag":
    "Geen bericht gevonden voor geselecteerde hashtag",
  "Start a conversation": "Een gesprek beginnen",
  "Search by name": "Zoek op naam",
  Upcoming: "Aankomend",
  UPCOMING: "Aankomend",
  Past: "Verleden",
  PAST: "VERLEDEN",
  "No upcoming events": "Geen aankomende evenementen",
  "Create event popup": "Creëer evenement pop -up",
  "Event details": "Evenementgegevens",
  "16:9 aspect ratio": "16: 9 beeldverhouding",
  "Event name": "Evenement naam",
  "Enter event name": "Voer evenementnaam in",
  "Host Name": "Hostnaam",
  "You will be made host if none selected":
    "U wordt gehost als er geen is geselecteerd",
  "Write here...": "Schrijf hier...",
  "No. of registration for the event": "Aantal registratie voor het evenement",
  Unlimited: "Onbeperkt",
  "When & where": "Wanneer, waar",
  From: "Van",
  "Start date": "Begin datum",
  time: "tijd",
  To: "Naar",
  "End date": "Einddatum",
  "Current time zone:": "Huidige tijdzone:",
  "Can be updated from the communtiy setting":
    "Kan worden bijgewerkt vanuit de CommunTiy -instelling",
  "Online event": "Online evenement",
  "Add meeting link": "Voeg vergaderlink toe",
  "Meeting link will be generated once event is created":
    "Meeting Link wordt gegenereerd zodra het evenement is gemaakt",
  Venue: "Locatie",
  "Enter address": "Adres invoeren",
  Create: "Creëren",
  Invite: "Uitnodiging",
  "Share event via link": "Deel evenement via link",
  Copy__1: "Kopiëren",
  "Select Channel to post this event":
    "Selecteer het gedeelte om dit evenement te plaatsen",
  "Once posted, members of that group or Channel will recieve an notification":
    "Eenmaal gepost, krijgen leden van die groep of sectie een melding",
  Post: "Na",
  RSVP: "RSVP",
  "Book Event": "Boekevenement",
  "Yes, I'll attend": "Ja, ik zal aanwezig zijn",
  "May be": "Misschien",
  "No, I won't attend": "Nee, ik zal niet aanwezig zijn",
  "Date & Time": "Datum Tijd",
  Host: "Gastheer",
  "Online meeting": "Online vergadering",
  "Meeting link will be visible once registered for the event":
    "Vergaderlink is zichtbaar eenmaal geregistreerd voor het evenement",
  "User Management": "Gebruikersbeheer",
  "Go bac to community": "Ga BAC naar de gemeenschap",
  "Manage Members": "Beheer leden",
  Member: "Lid",
  Invited: "Uitgenodigd",
  "Search for member": "Zoek naar lid",
  "Add to group": "Aan groep toevoegen",
  "Send Direct Message": "Stuur het directe bericht",
  "Change role": "Rolheid veranderen",
  "Kick from community": "Schop van de gemeenschap",
  "Super Admin": "Superbeheerder",
  "Super admin will have full access to the communtiy settings":
    "Super admin heeft volledige toegang tot de gemeenschappelijke instellingen",
  "Group Admin": "Groepsadmin",
  "Admin of the selected group can edit the group information and create, edt or delete Channel under selected group":
    "Admin van de geselecteerde groep kan de groepsinformatie bewerken en sectie maken, EDT of verwijderen onder geselecteerde groep",
  "Group Moderator": "Groepsmoderator",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Group Moderator kan post, commentaar, antwoord en berichten in de geselecteerde groep maken of verwijderen.",
  User: "Gebruiker",
  Save: "Redden",
  "User role updated successfully": "Gebruikersrol met succes bijgewerkt",
  Leaderboard: "Scorebord",
  "Click on the column header for sorting":
    "Klik op de kolomkop om te sorteren",
  Rank: "Rang",
  "Karma Points": "Karma -punten",
  "Custom Points": "Aangepaste punten",
  Follow: "Volgen",
  Upvotes: "Upvotes",
  Posts: "Palen",
  "Add custom points": "Voeg aangepaste punten toe",
  "Subtract custom points": "Trek aangepaste punten af",
  "Enter no. of points": "Voer nee in. van punten",
  "Add points": "Voeg punten toe",
  "Invite members to": "Nodig leden uit om",
  "Invite via link": "Nodig uit via link",
  "Invite via email": "Nodig uit via e -mail",
  "Add more": "Voeg meer toe",
  "Upload a CSV": "Upload een CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Upload een door komma's gepresenteerd CSV-bestand, met header als naam, e-mail.",
  "Here is a template CSV file": "Hier is een sjabloon CSV -bestand",
  "Upload File": "Upload bestand",
  "Send Invite": "Uitnodiging sturen",
  "Mail Template": "Postsjabloon",
  "Send Test Mail": "Stuur testmail",
  Hey: "Hoi",
  "A has invite you to join the B":
    "A heeft je uitgenodigd om lid te worden van de B",
  "Switch to rich text editor": "Schakel over naar Rich Text Editor",
  "Add Custom Message": "Voeg een aangepast bericht toe",
  "Enter message here": "Voer hier een bericht in",
  "Send Bulk Mail": "Stuur bulkpost",
  "Send Mail": "Verzend mail",
  "Send bulk mail to all community members or select group members":
    "Stuur bulkmail naar alle leden van de gemeenschap of selecteer groepsleden",
  Date: "Datum",
  Subject: "Onderwerp",
  Recipients: "Ontvangers",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 bulk -e -mails over voor deze maand. Resets op <date>",
  Everyone: "Iedereen",
  "Enter mail subject": "Voer het postonderwerp in",
  "Write message": "Schrijf een bericht",
  Cancel__1: "Annuleren",
  Send: "Versturen",
  "Profile Page": "Profiel pagina",
  Activity: "Activiteit",
  Bookmarks: "Bladwijzers",
  "Edit Profile": "Bewerk profiel",
  Unsubscribe: "Uitschrijven",
  "Settings (Account)": "Instellingen (account)",
  Profile: "Profiel",
  "Set profile Picture": "Stel profielfoto in",
  "Your name": "Uw naam",
  "User ID": "gebruikersnaam",
  "Short bio": "Korte biografie",
  "Link your social account, this will be displayed on your profile":
    "Koppel uw sociale account, dit wordt op uw profiel weergegeven",
  "Add social handle": "Sociaal handvat toevoegen",
  Verification: "Verificatie",
  "Account Verification": "Account verificatie",
  Email: "E -mail",
  "Enter email": "Voer email in",
  Verified: "Geverifieerd",
  Unverified: "Niet geverifieerd",
  Change: "Wijziging",
  Unlink: "Ontwerpen",
  Mobile: "Mobiel",
  "enter mobile no.": "Voer mobiel nr.",
  "Request OTP": "Vraag OTP aan",
  "Notification centre": "Meldingscentrum",
  "Manage your personal account notification preferences":
    "Beheer uw voorkeuren voor persoonlijke accountmeldingen",
  Prefrences: "Vooraf",
  "Email notification": "E-mail notificatie",
  "Notify me about comments on my posts":
    "Stel me op de hoogte van opmerkingen over mijn berichten",
  "Notify me about replies on my comments":
    "Stel me op de hoogte van antwoorden op mijn opmerkingen",
  "Notify me about upvotes on my posts":
    "Stel me op de hoogte van upvotes op mijn berichten",
  "Notify me about reaction on my comments":
    "Stel me op de hoogte van de reactie op mijn opmerkingen",
  "Notify me about reaction on my reply":
    "Stel me op de hoogte van de reactie op mijn antwoord",
  "Notify me about direct message":
    "Stel me op de hoogte van het directe bericht",
  "Notify me about my mentions": "Stel me op de hoogte van mijn vermeldingen",
  "Notification for new posts": "Kennisgeving voor nieuwe berichten",
  "Only group & Channel available which you are part of":
    "Alleen de beschikbare groep en sectie waar u deel van uitmaakt",
  "Group & Channel": "Groep en sectie",
  "All activity": "Alle activiteiten",
  "Post by admin": "Geplaatst door admin",
  "No Notification": "Geen melding",
  "Setting (Community)": "Setting (gemeenschap)",
  "General Settings": "Algemene instellingen",
  "Upload community logo": "Upload communitylogo",
  "Community name": "Gemeenschaps naam",
  "Invalid field": "Ongeldig veld",
  "Your community address": "Uw gemeenschapsadres",
  "Fav icon": "Fav -pictogram",
  "This will be visible on the browser tab (32px by 32px)":
    "Dit zal zichtbaar zijn op het tabblad Browser (32px bij 32 px)",
  TimeZone: "Tijdzone",
  "Privacy Policy on login page": "Privacybeleid op inlogpagina",
  "Terms & Condition on login page":
    "Voorwaarden en conditie op de inlogpagina",
  "Enter link here": "Voer hier de link in",
  "Open Group Image": "Open groepsafbeelding",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Standaard neemt IR de communitybanner op geüpload met de CommunTiy",
  "Community Experience": "Gemeenschapservaring",
  Onboarding: "Aan boord gaan",
  "Make your community private": "Maak uw gemeenschap privé",
  "Users will have to login or sign up to access the community":
    "Gebruikers moeten inloggen of zich aanmelden om toegang te krijgen tot de community",
  "Make account verification mandatory for both email & mobile":
    "Verificatie van accountverificatie verplicht maken voor zowel e -mail als mobiel",
  "New user will have to verify both email & mobile no. to access the community":
    "Nieuwe gebruiker zal zowel e -mail & mobiel nr. Om toegang te krijgen tot de gemeenschap",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Admin zal ook hun mobiele NR moeten verifiëren. & e -mail of anders hebben ze geen toegang tot de community",
  "Gated community access": "Gated Community Access",
  "Admin will have to approve or reject user's requests to join the community":
    "Admin zal de verzoeken van de gebruiker moeten goedkeuren of afwijzen om lid te worden van de community",
  "Disable mobile login/register": "Schakel mobiele inlog/register uit",
  "Disable email login/register": "Schakel e -mail inloggen/register uit",
  "Disable google login/register": "Schakel Google inloggen/register uit",
  "Disable facebook login/register": "Schakel Facebook -inlog/registratie uit",
  "Show & Hide features": "Toon en verbergen functies",
  "Hide direct chat": "Verberg directe chat",
  "User will not be able to send direct messages to each other":
    "Gebruiker kan geen directe berichten naar elkaar verzenden",
  "Hide profile picture from top right corner for mobile responsive view":
    "Verberg de profielfoto vanuit de rechterbovenhoek voor mobiele responsieve weergave",
  "Useful when using community in an in app browser":
    "Handig bij het gebruik van community in een in app -browser",
  "Hide profile picture from top right corner in every view":
    "Verberg de profielfoto in elke weergave van de rechterbovenhoek in elke weergave",
  "Disable header dropdown on profile picture":
    "Schakel kopstudie uit op profielfoto",
  "Useful for integrated community, especially using SSO":
    "Handig voor geïntegreerde gemeenschap, vooral met SSO",
  "Hide the communtiy logo from the header":
    "Verberg het CommunTiy -logo voor de header",
  "Hide the communtiy title from the header":
    "Verberg de gemeenschappelijke titel voor de header",
  "Show collpased group in the side nav bar":
    "Toon een collpased groep in de navigatie van de zijkant",
  "Show full expanded post in the home feed":
    "Toon volledige uitgebreide post in de thuisfeed",
  "Hide the leaderboard score from the header":
    "Verberg de leaderboard -score voor de header",
  "Show powered by Pensil": "Show aangedreven door Pensil",
  "Hide closed groups if not joined":
    "Verberg gesloten groepen als het niet is gevoegd",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Gesloten groepen zouden niet aan de gebruiker worden getoond als ze geen lid van de groep zijn",
  "Hide Community subscriber count from the users":
    "Verberg community -abonnees van de gebruikers",
  "This will hide the community subscriber list in the community header":
    "Dit verbergt de lijst met community -abonnees in de Community Header",
  "Disable Communtiy Switcher": "Schakel CommunTiy Switcher uit",
  "Use MD editor for create post description":
    "Gebruik MD -editor voor het maken van berichtbeschrijving",
  "Non logged in user will land on explore group page instead of post directory":
    "Niet -ingelogde gebruiker belandt op verkenningspagina in plaats van de post -directory",
  "Advanced configurable": "Geavanceerd configureerbaar",
  "Communtiy default country code": "CommunTiy standaard landcode",
  "This will show up by default on login/signup":
    "Dit verschijnt standaard bij inloggen/aanmelden",
  "Onboarding/Welcome Message": "Onboarding/welkomstbericht",
  "Welcome Message": "Welkoms bericht",
  "Greet your members with personalized message":
    "Begroet uw leden met gepersonaliseerde boodschap",
  "Welcome to": "Welkom bij",
  Message: "Bericht",
  "Add message": "Bericht toevoegen",
  "Custom domain": "Aangepast domein",
  "Add custom domain": "Voeg een aangepast domein toe",
  "Create new request": "Maak een nieuw verzoek aan",
  "Enter your custom domain": "Voer uw aangepaste domein in",
  "Setup details": "Stelgegevens instellen",
  "Add a CNAME record in your domain pointing to this community's address":
    "Voeg een CNAME -record toe in uw domein dat wijst op het adres van deze community",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "Om uw domein te configureren, moet u een CNAME -record toevoegen waarin uw domein wordt gericht aan uw Pensil -community -URL",
  "Step by Step help apge": "Stap voor stap help apge",
  "All request list": "Alle aanvragenlijst",
  "Allow admin to assign/un-assign custom points to users":
    "Sta admin toe om aangepaste punten toe te wijzen/niet-toewijzen aan gebruikers",
  "Base points for user to begin with":
    "Basepunten voor de gebruiker om mee te beginnen",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Basispunten worden bijgewerkt voor alle gebruikers, eventuele wijzigingen weerspiegelen voor alle gebruikers.",
  "Allocate points for interaction": "Wijs punten toe voor interactie",
  "Upvoting post": "Upvoting Post",
  "creating a comment": "Een opmerking maken",
  "creating a reply": "Een antwoord maken",
  "Getting upvote to their post": "Upot naar hun post krijgen",
  "Getting comment to their post": "Commentaar krijgen op hun bericht",
  "Getting reply to their comment": "Antwoord krijgen op hun opmerking",
  "Creating a post": "Een bericht maken",
  Visiblity: "Zichtbaarheid",
  "Move Up": "Omhoog gaan",
  "Move Down": "Naar beneden verplaatsen",
  "Create embedded Channel": "Maak een ingesloten sectie",
  "Preview area": "Voorbeeldgebied",
  "Theme selector": "Thema selector",
  "Community Theme": "Communitythema",
  "Select theme": "Selecteer thema",
  "Select brand color": "Selecteer merkkleur",
  "Customize your button color to match your brand":
    "Pas uw knopkleur aan om bij uw merk te passen",
  "Select on button text color": "Selecteer op de knop Tekstkleur",
  "Suggestion: Keep inverted contrast against brand color":
    "Suggestie: blijf omgekeerd contrast tegen merkkleur",
  Billing: "Het factureren",
  "Current plan": "Huidige plan",
  "You are current on Free PLan": "U bent actueel op gratis plan",
  "Renew on: NA": "Vernieuwen: NA",
  Select: "Uitkiezen",
  "Email us at": "E -mail ons op",
  "Compare plan": "Vergelijk plan",
  "API Keys": "API -toetsen",
  "Create new key": "Maak een nieuwe sleutel",
  "Total keys": "Totale sleutels",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Houd uw API -toetsen veilig! U kunt ze gebruiken om toegang te krijgen tot een specifieke functie en Cetain -apps te laten perfrom bepaalde acties namens u op het platform.",
  "Key name": "Sleutelnaam",
  "Key value": "Sleutel waarde",
  Action: "Actie",
  "Group updated!": "Groep bijgewerkt!",
  "Are you sure you want to": "Weet je zeker dat je wil",
  "Make Moderator": "Moderator maken",
  "Remove from group": "Verwijder uit de groep",
  "Show All": "Toon alles",
  "Change Role": "Rolheid veranderen",
  Close: "Dichtbij",
  copy: "kopiëren",
  LINKS: "Links",
  "Email Id": "E-mail identiteit",
  Name: "Naam",
  "Search for an image": "Zoek naar een afbeelding",
  "Crop Image": "Afbeelding bijsnijden",
  "No photos found": "Geen foto's gevonden",
  Community: "Gemeenschap",
  "Banner ratio - 16:9": "Banner Ratio - 16: 9",
  "Banner Ratio - 16:6": "Banner Ratio - 16: 6",
  "Share on mail": "Deel op post",
  "Share on Telegram": "Deel op Telegram",
  "Share on Whatsapp": "Deel op WhatsApp",
  "Share on Linkedin": "Deel op LinkedIn",
  "Share on Facebook": "Delen op Facebook",
  "Share on Twitter": "Delen op Twitter",
  "Pin Post": "Pin bericht",
  "Heading (optional)": "Regel (optioneel)",
  "Select Channel": "Selecteer het gedeelte",
  Gif: "Gif",
  "Create Custom Widget": "Maak een aangepaste widget",
  "Are you sure you want to delete this widget?":
    "Weet u zeker dat u deze widget wilt verwijderen?",
  "Go to event": "Ga naar het evenement",
  "Add Tags": "Tags toevoegen",
  "Create Tag": "Maak tag",
  "Title (Optional)": "Titel (optioneel)",
  "Search Gif": "Zoek GIF",
  "What's on your mind": "Waar denk je aan",
  "Looks like nothing available here right now.":
    "Het lijkt erop dat hier nu niets beschikbaar is.",
  "Try exploring other tabs": "Probeer andere tabbladen te verkennen",
  "Delete Widget": "Widget verwijderen",
  "Please select a Channel to post": "Selecteer een sectie om te posten",
  "Post added successfully!": "Post met succes toegevoegd!",
  "Failed to create post. Please check post content and retry again.":
    "Kan post niet maken. Controleer inhoud en probeer opnieuw.",
  "Are you sure you want to discard current post?":
    "Weet u zeker dat u het huidige bericht wilt weggooien?",
  "By default it will take up the image uploaded with the post":
    "Standaard wordt de afbeelding geüpload met het bericht",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Quiz kan nu alleen worden bekeken en geprobeerd vanaf mobiel apparaat!",
  "Community Banner": "Community Banner",
  "Change Cover": "Verander deksel",
  "Suggestions: Welcome to my community etc":
    "Suggesties: welkom bij mijn gemeenschap enz",
  "(edited)": "(bewerkt)",
  "Aspect ratio (recommended)": "Beeldverhouding (aanbevolen)",
  "Banner details updated!": "Banner Details bijgewerkt!",
  "Refresh if changes are not visible!":
    "Vernieuw als wijzigingen niet zichtbaar zijn!",
  "Banner details could not be updated!":
    "Bannersetails konden niet worden bijgewerkt!",
  "Post deleted": "Post verwijderd",
  "Post not available. It may have been deleted.":
    "Post niet beschikbaar. Het kan zijn verwijderd.",
  Admin: "beheerder",
  Moderator: "Moderator",
  "Bookmark added!": "Bladwijzer toegevoegd!",
  "Bookmark removed": "Bookmark verwijderd",
  "Failed performing action!": "Mislukt uitvoerende actie!",
  "Copy this link to share on other platforms":
    "Kopieer deze link om te delen op andere platforms",
  "View this event": "Bekijk dit evenement",
  Event: "Evenement",
  "Join Meeting": "Deelnemen aan de vergadering",
  "The live meeting has ended": "De live -bijeenkomst is afgelopen",
  "read more...": "Lees verder...",
  "Load more comments": "Laad meer opmerkingen",
  "Post Reply": "Reageer",
  "Post Comment": "Plaats een reactie",
  more: "meer",
  upvoted: "gestimuleerd",
  "1 Upvote": "1 UPVOTE",
  "1 Comment": "1 reactie",
  "Please enter a title": "Voer een titel in",
  "Please enter a url link": "Voer een URL -link in",
  "Please enter button title": "Voer de titel van de knop in",
  "Button label must be at least 20 characters":
    "Knoplabel moet minimaal 20 tekens zijn",
  "Please enter valid url": "Voer de geldige URL in",
  "Suggestions: Know more, Join, Apply...":
    "Suggesties: meer weten, meedoen, solliciteren ...",
  URL: "Url",
  "Community Events": "Community -evenementen",
  "Widget deleted successfully": "Widget met succes verwijderd",
  "Community Top Contributors": "Community topbijdragers",
  points: "punt",
  "Points cannot be greater than current points":
    "Punten kunnen niet groter zijn dan de huidige punten",
  "Point cannot be a negative value": "Punt kan geen negatieve waarde zijn",
  "Point cannot be a decimal point": "Punt kan geen decimaal punt zijn",
  "Please enter a valid number": "Gelieve een geldig nummer invoeren",
  "Karma points updated successfully":
    "Karma -punten zijn succesvol bijgewerkt",
  "Error in updating karma points": "Fout bij het bijwerken van karmapunten",
  "Add karma points": "Voeg karmapunten toe",
  "Minus karma points": "Minus Karma -punten",
  "Total Karma points:": "Totaal Karma -punten:",
  "Enter no. of karma points": "Voer nee in. van karma -punten",
  "Minus Points": "Min punten",
  "Trending Post": "Trending post",
  "No visible description": "Geen zichtbare beschrijving",
  "Picture ratio: 1:1": "Afbeeldingsverhouding: 1: 1",
  "home feed": "Home Feed",
  "Event name is invalid!": "Evenementnaam is ongeldig!",
  custom: "aangepast",
  "Custom widget updated successfully":
    "Aangepaste widget met succes bijgewerkt",
  "Name invalid!": "Naam ongeldig!",
  "Current timezone:": "Huidige tijdzone:",
  "can be updated from community settings":
    "kan worden bijgewerkt vanuit gemeenschapsinstellingen",
  "Event created successfully": "Evenement gemaakt met succes",
  "Error in creating event": "Fout bij het maken van gebeurtenis",
  Attendees: "Deelnemers",
  "Do you wish to notify these changes to attendees?":
    "Wilt u deze wijzigingen aan de aanwezigen op de hoogte stellen?",
  "users wants to attend this event":
    "Gebruikers willen dit evenement bijwonen",
  "Yes, Notify them": "Ja, laat ze op de hoogte stellen",
  "No, its okay": "Nee het is goed",
  "Do you wish to notify that event is cancelled?":
    "Wilt u op de hoogte stellen dat het evenement is geannuleerd?",
  "Are you sure you want to delete the event?":
    "Weet u zeker dat u het evenement wilt verwijderen?",
  "Delete Event": "Verwijder evenement",
  "New Event": "Nieuw evenement",
  "Accept Invitation": "Uitnodiging accepteren",
  Requested: "Aangevraagd",
  Declined: "Afgewezen",
  "Request Access": "Toegang vragen",
  "Enroll for": "Zich inschrijven voor",
  Housefull: "huis vol",
  "Just copy paste this code wherever you want it to be embedded:":
    "Kopieer gewoon deze code plakken waar u maar wilt, is ingesloten:",
  Ended: "Beëindigd",
  "Error getting events": "Fout bij het krijgen van gebeurtenissen",
  "Events could not be fetched!": "Evenementen konden niet worden opgehaald!",
  "Show Attendees": "Toon aanwezigen",
  Subscribers: "Abonnees",
  "Make this a closed Channel": "Maak dit een gesloten gedeelte",
  "Update Channel": "Update -sectie",
  "Valid Channel name required!": "Geldige sectienaam vereist!",
  "Enter Channel name": "Voer de sectienaam in",
  "Community Admin": "Community Admin",
  Embed: "Integreren",
  "Invite to": "Uitnodigen",
  "Please enter a valid email address":
    "Gelieve een geldig e-mailadres in te geven",
  "Invitation sent successfully": "Uitnodiging met succes verzonden",
  "Error sending invitation": "Fout het verzenden van uitnodiging",
  "Email is not valid!": "E-mail is niet geldig!",
  "Data could not be loaded!": "Gegevens konden niet worden geladen!",
  "Posted in": "Geplaatst in",
  "Invite Members Individually": "Nodig leden individueel uit",
  "Start a discussion": "Begin een discussie",
  "Post something": "Plaats iets",
  "Invite your audience": "Nodig uw publiek uit",
  "Choose Plan": "Kies plan",
  or: "of",
  "Create a link": "Maak een link",
  "Add link": "Voeg een link toe",
  "Update link": "Update link",
  "Add shortcut to your most used tools":
    "Voeg snelkoppeling toe aan uw meest gebruikte tools",
  "Your website, Instagram Channel, Facebook profile etc":
    "Uw website, Instagram -kanaal, Facebook -profiel enz",
  "Valid link name required!": "Geldige linknaam vereist!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Geldige URL vereist en zorg ervoor dat u http: // of https: // niet mist",
  "Invite to__1": "Uitnodigen",
  "Link Name": "Naam link",
  "Link URL": "Link URL",
  "Could not subscribe to": "Kon zich niet abonneren op",
  "Subscribed to": "Geabonneerd op",
  "By default it will take up the community banner uploaded with the community":
    "Standaard wordt de communitybanner geüpload met de community opgenomen",
  "Created by": "Gemaakt door",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Online evenement (vergaderlink is zichtbaar zodra ze zijn geregistreerd voor het evenement)",
  "Join Call": "Word lid van de oproep",
  "Please login to book this event": "Log in om dit evenement te boeken",
  "Commented on": "Gereageerd op",
  "Replied to": "Geantwoord op",
  "Looks like it's lonely here.": "Het lijkt erop dat het hier eenzaam is.",
  "Try exploring other Channels and groups.":
    "Probeer andere secties en groepen te verkennen.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Ga verder met de betaling of neem contact op met admin om toegang te krijgen tot deze groep.",
  "This is a closed group You'll need access to this group.":
    "Dit is een gesloten groep die u toegang hebt tot deze groep.",
  "You have no bookmarks right now.": "Je hebt momenteel geen bladwijzers.",
  "The user hasn't posted anything yet publicly.":
    "De gebruiker heeft nog niets publiekelijk gepost.",
  "No activity to show.": "Geen activiteit om te laten zien.",
  "Are you sure you want to unsubscribe the community?":
    "Weet je zeker dat je de gemeenschap wilt afmelden?",
  "deals left": "Deals links",
  "Something went wrong while loading posts":
    "Er ging iets mis tijdens het laden van berichten",
  "Could not unsubscribe from": "Kon zich niet afmelden voor",
  "Unsubscribed from": "Afmelden van",
  "Login to view this page": "Log in om deze pagina te bekijken",
  Login: "Log in",
  "There was some error while doing the action!":
    "Er was een fout tijdens het uitvoeren van de actie!",
  "Unable to delete Channel!": "Kan sectie niet verwijderen!",
};
