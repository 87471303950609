import PostListShimmer, {
  PostTileType,
} from "./post/post-list-shimmer.component";
export { PostTileType } from "./post/post-list-shimmer.component";

/**
 * SectionPostListShimmer
 * @param {*} length
 */
function SectionPostListShimmer({ length }) {
  const list = [];

  return (
    //   Header
    <div className="pt-0 mx-auto animate-pulse w-full ">
      <div className="flex justify-between p-6  bg-white border-b rounded-md">
        <div className=" w-12 h-6 bg-gray-300 rounded" />
        <div className=" w-12 h-6 bg-gray-300 rounded" />
      </div>

      {/* Post Shimmer list */}
      <PostListShimmer length={length} type={PostTileType.default} />
    </div>
  );
}

export default SectionPostListShimmer;
