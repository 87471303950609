import cx from "classnames";

export default function UploadProgressBar({
  percentComplete = 0,
  className = "",
}) {
  // percentage complete can go 0 to 100
  // show nothing is current progress is zero
  if (percentComplete === 0) {
    return <></>;
  }

  return (
    <div className={cx("UploadProgressBar", className)}>
      <div
        className="upload-progress"
        style={{ width: percentComplete + "%" }}></div>
    </div>
  );
}
