import {
  REMOVE_ACTIVE_GROUP,
  SET_ACTIVE_GROUP,
  SET_GROUPS,
  SET_IS_LOADING_GROUPS,
} from "../_actions/group.actions";

/**
 * Main Auth Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function groupReducer(state = [], action) {
  switch (action.type) {
    case SET_GROUPS:
      return action.groups;
    default:
      return state;
  }
}

/**
 * Active group reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function activeGroupReducer(state = null, action) {
  switch (action.type) {
    case SET_ACTIVE_GROUP:
      return action.group;
    default:
      return state;
  }
}

export function removeGroupReducer(state = null, action) {
  switch (action.type) {
    case REMOVE_ACTIVE_GROUP:
      return action.group;
    default:
      return state;
  }
}

/**
 * Main Auth Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function isLoadingGroupReducer(state = true, action) {
  switch (action.type) {
    case SET_IS_LOADING_GROUPS:
      return action.loading;
    default:
      return state;
  }
}
