import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class SearchService {
  static async search(communityId, search = "") {
    return axios
      .get(endpoints.misc.search(communityId) + "?search=" + search, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get meeting data for meetingId
   * @param {*} user
   * @param {*} meetingId
   * @returns
   */
  static async getMeetingData(user, meetingId) {
    return axios
      .get(endpoints.post.getMeetingData(meetingId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async toggleMeetingRecording(roomName, endingKey) {
    return axios
      .post(endpoints.post.toggleMeetingRecording(roomName, endingKey), {}, {})
      .then((response) => response.data);
  }

  static async endTheMeeting(roomName, endingKey) {
    return axios
      .get(endpoints.post.endMeeting(roomName, endingKey), {})
      .then((response) => response.data);
  }
}

export { SearchService };
