import { ReactNode, useEffect, useState } from "react";
import removeMd from "remove-markdown";
import { history } from "../../_config";
import { createPostDetailPageRouteFromSlug } from "../../_pages/post-detail.page";
import { CommunityService, GroupService } from "../../_service";
import { Avatar } from "../avatar.component";

import { useAppSelector } from "../../hooks/redux.hook";
import { Group } from "../../types/group/group.type";
import Validator from "../../_utils/validator";
import I18 from "../atoms/i18";
import LazyImage from "../atoms/lazy-image";
import { ListView } from "../molecule/listview.component";
import PostListShimmer, {
  PostTileType,
} from "../shimmers/post/post-list-shimmer.component";
import { useAppService } from "../../hooks/use-app-service";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

interface TrendingPostWidgetProps {
  group?: Group;
  isCommunityPosts?: Boolean;
}

/**
 * Widget to display trending posts of community and group
 * @param {Group} group current active user profile data.
 * @param {boolean} isCommunityPosts Determine whether to widget is to display on home page or in group
 */
function TrendingPostWidget({
  group,
  isCommunityPosts = false,
}: TrendingPostWidgetProps) {
  const sortOrder: any = "trending";
  const pageIndex = 1;
  const [posts, setPosts] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    community: { id: communityId },
    user,
  } = useAppSelector((state) => {
    return {
      community: state.community,
      user: state.auth,
    };
  });

  // post lazy loading
  useEffect(() => {
    if (isCommunityPosts && !Validator.hasValue(posts)) {
      getCommunityTrendingPosts();
    } else if (
      !isCommunityPosts &&
      (posts === undefined || posts.length === 0)
    ) {
      getGroupTrendingPosts();
    }
  }, [group?.id, isCommunityPosts]);

  // TODO: Call if user has access to group
  const getCommunityTrendingPosts = () => {
    // Restrict api to fetch posts while already in same process
    if (!isLoading) {
      // if we are not already loading more posts, load more posts
      setIsLoading(true);

      CommunityService.getCommunityPostDirectoryById(
        user,
        communityId,
        sortOrder,
        pageIndex
      )
        .then((response) => {
          if (response.posts.length !== 0) {
            setPosts(response.posts);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const getGroupTrendingPosts = () => {
    // Restrict api to fetch posts while already in same process
    if (!isLoading) {
      // if we are not already loading more posts, load more posts
      setIsLoading(true);

      GroupService.getTrendingPosts(group?.id)
        .then((response) => {
          // if there are no more posts, set so
          if (response.posts.length === 0) {
            console.log("No posts");
          } else {
            setPosts(response.posts);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  // Hide posts widget if there is no upcoming posts available
  if (!isLoading && (posts === undefined || posts.length === 0)) {
    return <></>;
  }
  return (
    <div className="theme-bg-surface rounded mt-4">
      <h4 className="font-bold px-4 py-2 border-b theme-border-default">
        <I18>Trending Post</I18>
      </h4>
      <ListView
        items={posts?.slice(0, 5)}
        loading={isLoading}
        className="mx-4 divide-y divide-[var(--theme-border-color)] theme-border-default"
        renderItem={function (post: any, i: number): ReactNode {
          return (
            <MinimizedPostCard
              key={i}
              post={post}
              displayLikeCommentIcons={false}
            />
          );
        }}
        placeholder={<PostListShimmer length={4} type={PostTileType.compact} />}
      />
    </div>
  );
}

/**
 * Component to display post in minimized format
 */
export function MinimizedPostCard({ post }: any) {
  const { title, description } = post;

  const { analyticsService } = useAppService();

  function getHeadline() {
    if (title && title.trim().length > 0) {
      return title;
    }
    if (description) {
      let text = removeMd(description);
      if (text.length > 60) {
        text = text.substring(0, 60) + " ...";
      }
      return removeMd(text);
    }
    return undefined;
  }
  return (
    <div
      className="theme-bg-surface py-3 cursor-pointer  theme-border-default items-start"
      onClick={(e) => {
      analyticsService.track("post-selected-from-widgets")
        // open post
        history.push(getSegmentedPath(createPostDetailPageRouteFromSlug(post)));
      }}>
      <div className=" space-x-2 text-md font-semibold flex">
        {/* post type */}
        <PostType post={post} />
        <span className=" word-breaker ">
          {getHeadline() ? (
            getHeadline()
          ) : (
            <div className="theme-text-subtitle-2 ">
              <I18>No visible description</I18>
            </div>
          )}
        </span>
      </div>
      {/* post details */}
      <span className="flex  mt-1 w-full">
        {/* other post details */}
        <div className="flex-none">
          <Avatar
            user={{ ...post.createdBy, name: "" }}
            size={20}
            extraInfo={undefined}
          />
        </div>
        <span className="flex-grow overflow-hidden word-breaker">
          {post.createdBy.name} in
          <span className="font-semibold theme-text-primary">
            &nbsp;
            {post.tab ? " - " + post.tab.name : ""}{" "}
          </span>
        </span>
      </span>
    </div>
  );
}

/**
 * Display poll tag if post contains poll
 */
function PostType({ post }: any) {
  if (post.poll) {
    return (
      <div
        className="text-xs max-w-max rounded font-semibold mr-2 flex items-center flex-shrink-0 "
        style={{
          backgroundColor: "#FFE27B",
          padding: "3px 8px 3px 3px",
        }}>
        <LazyImage
          src="assets/vectors/poll-small-icon.svg"
          alt=""
          style={{ width: "16px" }}
          className="mr-1"
        />
        {post.poll.isQuiz ? "Quiz" : "Poll"}
      </div>
    );
  }

  // nothing
  return <></>;
}

export default TrendingPostWidget;
