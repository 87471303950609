import axios from "axios";
import { endpoints } from "../_config";
import {
  getCommunityAddress,
  getUserTokenFromStorage,
} from "../_config/helper.config";
import { mixPanel } from "../_config/mixpanel.config";

class CommunityService {
  /**
   * Get the groups by user
   * @param {User} user
   */
  static async checkForAddressAvailability(address, user) {
    return axios
      .get(endpoints.community.addressAvailability(address), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Create community
   *
   */
  static async createCommunity(
    name,
    address,
    selectedLayout,
    selectedType,
    user
  ) {
    return axios
      .post(
        endpoints.community.create,
        {
          name,
          address,
          selectedLayout,
          selectedType,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        mixPanel.track("create-community", {
          communityname: response.data.community.name,
          communityurl: getCommunityAddress(response.data.community),
          logo: response.data.community.logo ? true : false,
          selectedLayout: response.data.community.selectedLayout,
          typeSelection: response.data.community.typeSelection,
        });
        return response.data;
      });
  }

  /**
   * Update community
   *
   */
  // selectedLayout = ""
  static async updateCommunity(
    communityId,
    name,
    address,
    selectedLayout = "",
    selectedType = "",
    user,
    timezone = "Asia/Kolkata",
    privacyPolicyLink = "",
    termsAndConditionsLink = "",
    meta
  ) {
    return axios
      .post(
        endpoints.community.updateCommunity(communityId),
        {
          name,
          address,
          selectedLayout,
          timezone,
          privacyPolicyLink,
          termsAndConditionsLink,
          meta,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Update community welcome messages
   *
   */
  static async updateCommunityWelcomeMessages(
    communityId,
    welcomeMessages = [],
    user
  ) {
    return axios
      .put(
        endpoints.community.updateCommunityWelcomeMessages(communityId),
        {
          welcomeMessages,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * updateCommunityBannerDetails
   *
   */
  static async updateCommunityBannerDetails(
    communityId,
    bannerTitle,
    bannerDescription,
    user
  ) {
    return axios
      .post(
        endpoints.community.updateCommunityBannerDetails(communityId),
        {
          bannerTitle,
          bannerDescription,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Update logo of a community
   */
  static async updateLogo(id, user, logo) {
    const data = new FormData();
    // add logo
    data.append("logo", logo, logo.fileName);
    // call api
    return axios
      .post(endpoints.community.updateLogo(id), data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update logo of a community
   */
  static async updateCommunityBanner(id, user, banner) {
    const data = new FormData();
    // add logo
    data.append("banner", banner, banner.fileName);
    // call api
    return axios
      .post(endpoints.community.updateCommunityBanner(id), data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Remove community banner of a community
   * @param {string} communityId community id
   */
  static async removeCommunityBanner(user, communityId) {
    // call api
    return axios
      .delete(endpoints.community.removeCommunityBanner(communityId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update favicon of a community
   */
  static async updateFavicon(id, user, favicon) {
    const data = new FormData();
    // add logo
    data.append("favicon", favicon, favicon.fileName);
    // call api
    return axios
      .post(endpoints.community.updateFavicon(id), data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get groups of community
   * @param {*} communityId
   * @param {*} user
   * @returns
   */
  static async getCommunityGroups(communityId, user) {
    return axios
      .get(endpoints.community.groups(communityId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  //********* leaderboard Related ************//

  /**
   *   Get my leaderboard score by community
   * @param {User} user
   */
  static async getMyLeaderboardScore(communityId) {
    return axios
      .get(endpoints.community.getMyLeaderboardScore(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   *   Get my leader board score by community
   * @param {AbortController} abortController - abort controller
   * @param {string} communityId - community id
   * @param {string} groupId - groupId is optional
   * @param {Number} limit - limit of records to be returned
   */
  static async getLeaderboard(
    abortController,
    communityId,
    groupId = "",
    page = 1,
    limit = 10
  ) {
    return axios
      .get(
        endpoints.community.getLeaderboard(communityId, groupId, page, limit),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

   /**
   * Get leader board score by community
   * @param {string} communityId - community id
   * @param {number} page - page
   * @param {limit} limit - limit
   */
  static async getLeaderboardV2Paginated(communityId, page, limit) {
    return axios
      .get(endpoints.community.getLeaderboardV2Paginated(communityId, page, limit), {
        headers: { Authorization: "Bearer " + getUserTokenFromStorage() },
      })
      .then((response) => response.data);
  }

  /**
   * Get self score
   * @param {string} communityId - community id
   */
  static async getMyKarmaPoints(communityId) {
    return axios
      .get(endpoints.community.getMyKarmaPoints(communityId), {
        headers: { Authorization: "Bearer " + getUserTokenFromStorage() },
      })
      .then((response) => response.data)
      .catch((error) => error.response.data);
  }

  /**
   *   Get my leaderboard score by community
   * @param {User} user
   */
  static async getCommunityKarmaPoints(communityId) {
    return axios
      .get(endpoints.community.getCommunityKarmaPoints(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   *   Get my leaderboard score by community
   * @param {User} user
   */
  static async updateCommunityKarmaPoints(communityId, karmaPoints) {
    return axios
      .post(
        endpoints.community.getCommunityKarmaPoints(communityId),
        { karmaPoints },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get list of communties user has subscribed to
   */
  static async getSubscribedCommunity(user) {
    return axios
      .get(endpoints.community.getSubscribedCommunities, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Function to sort community groups
   *
   */
  static async sortCommunityGroups(user, communityId, sortOrder) {
    return axios
      .post(
        endpoints.community.sortGroups(communityId),
        {
          sortOrder,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Function to sort community group sections
   *
   */
  static async sortCommunityGroupTabs(user, communityId, groupId, sortOrder) {
    return axios
      .post(
        endpoints.community.sortGroupTabs(communityId, groupId),
        {
          sortOrder,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async getCommunitySubscribers(user, communityId, groupId = "") {
    return axios
      .get(endpoints.community.subscribers(communityId, groupId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async getCommunitySubscribersPaginated(
    user,
    communityId,
    limit,
    page,
    search = "",
    groupId = ""
  ) {
    return axios
      .get(
        endpoints.community.subscribersPaginated(
          communityId,
          limit,
          page,
          search,
          groupId
        ),
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async markSubscribersAsViewed(communityId, userIds = []) {
    return axios
      .post(
        endpoints.community.markSubscribersAsViewed(communityId),
        {
          userIds,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  static async makeUserModeratorInAllGroups(
    user,
    communityId,
    groupId,
    userId
  ) {
    return axios
      .post(
        endpoints.community.makeUserModeratorInAllGroup(
          communityId,
          groupId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * get community by address
   * @param {*} address
   * @returns
   */
  static async getCommunity(address = "") {
    return axios
      .get(endpoints.community.getCommunity(address), {})
      .then((response) => response.data);
  }

  /**
   * get community by id
   * @param {*} address
   * @returns
   */
  static async getCommunityById(user, communityId) {
    return axios
      .get(endpoints.community.getCommunityById(communityId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get posts of a user in community by userId
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async getUserPostsInCommunityById(communityId, userId, page = 1) {
    return axios
      .get(endpoints.community.getUserPosts(communityId, userId, page), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get posts of a user in community by userId
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async getCommunityPostDirectoryById(
    user,
    communityId,
    sort = null,
    page = 1
  ) {
    return axios
      .get(
        endpoints.community.getCommunityPostDirectory(communityId, sort, page),
        {
          headers: {
            Authorization: "Bearer " + (user ? user.token : null),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get activities of a user in community by userId
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async getUserActivitiesInCommunityById(communityId, userId, page = 1) {
    return axios
      .get(endpoints.community.getUserActivities(communityId, userId, page), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Subscribe to a community
   * @param {*} user
   * @param {*} communityId
   * @returns
   */
  static async joinCommunity(user, communityId) {
    return axios
      .post(
        endpoints.community.joinCommunity(communityId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        mixPanel.track("subscribe-community", {
          communityname: response.data.community.name,
          communityurl: getCommunityAddress(response.data.community),
          communityPlan: response.data.community.plan ? response.data.community.plan.planType : "free",
          user: user.name,
          mobile: user.mobile,
          email: user.email,
        });
        return response.data;
      });
  }

  /**
   * Verify user to a community
   * @param {*} user
   * @param {*} communityId
   * @returns
   */
  static async verifyUserByAdmin(user, communityId, userId) {
    return axios
      .post(
        endpoints.community.verifyUserByAdmin(communityId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Unsubscribe to a community
   * @param {*} user
   * @param {*} communityId
   * @returns
   */
  static async leaveCommunity(user, communityId) {
    return axios
      .post(
        endpoints.community.leaveCommunity(communityId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  // links

  /**
   * get links in community
   * @param {*} user
   * @param {*} communityId
   * @param {*} link
   * @returns
   */
  static async getCommunityLinks(communityId) {
    return axios
      .get(endpoints.community.addNewLink(communityId), {})
      .then((response) => response.data);
  }

  /**
   * Add new link to community
   * @param {*} user
   * @param {*} communityId
   * @param {*} link
   * @returns
   */
  static async addCommunityLink(user, communityId, link) {
    return axios
      .post(endpoints.community.addNewLink(communityId), link, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Edit link in community
   * @param {*} user
   * @param {*} communityId
   * @param {*} link
   * @returns
   */
  static async editCommunityLink(user, communityId, link) {
    return axios
      .post(endpoints.community.editLink(communityId, link.id), link, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete link in community
   * @param {*} user
   * @param {*} communityId
   * @param {*} link
   * @returns
   */
  static async deleteCommunityLink(user, communityId, link) {
    return axios
      .delete(endpoints.community.editLink(communityId, link.id), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async verifyThirdPartyToken(communityId, token) {
    return axios
      .post(endpoints.community.verifyThirdPartyToken(communityId), {
        token,
      })
      .then((response) => response.data);
  }

  // role related

  /**
   * Make user the admin of whole community and all its groups
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async makeAdminOfWholeCommunity(user, communityId, userId) {
    return axios
      .post(
        endpoints.community.roles.makeAdminOfWholeCommunity(
          communityId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Remove user as admin of whole community
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async removeUserAsAdminOfWholeCommunity(user, communityId, userId) {
    return axios
      .post(
        endpoints.community.roles.removeUserAsAdminOfWholeCommunity(
          communityId,
          userId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Remove user from community
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async removeUserFromCommunity(user, communityId, userId) {
    return axios
      .post(
        endpoints.community.roles.removeUserFromCommunity(communityId, userId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Make user admin of selected groups in a community
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async makeAdminOfSelectedGroups(
    user,
    communityId,
    userId,
    groupIds = [],
    role = "admin"
  ) {
    return axios
      .post(
        endpoints.community.roles.makeAdminOfSelectedGroups(
          communityId,
          userId
        ),
        {
          groupIds,
          role,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get all plans list
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async getPlansList(communityId) {
    return axios
      .get(endpoints.community.subscription.getPlansList(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Make user admin of selected groups in a community
   * @param {*} user
   * @param {*} communityId
   * @param {*} userId
   * @returns
   */
  static async getCheckoutURLForCommunityPlan(
    user,
    communityId,
    planType = "advance",
    communityURL = window.location.origin
  ) {
    return axios
      .get(
        endpoints.community.subscription.getCheckoutURLForCommunityPlan(
          communityId,
          planType,
          communityURL
        ),
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   *
   * @param {*} community
   */
  static checkIfCommunityActionShouldBeDisabled(
    community,
    checkForNonAdmin = false
  ) {
    if (checkForNonAdmin) {
      return community &&
        community.selectedType === "paid" &&
        community.isPlanExpired
        ? true
        : false;
    } else {
      return community &&
        community.myRole === "admin" &&
        community.selectedType === "paid" &&
        community.isPlanExpired
        ? true
        : false;
    }
  }

  /**
   * Cancel all subscriptions for a community
   * @param {*} user
   * @param {*} communityId
   * @returns
   */
  static async cancelCommunitySubscription(user, communityId) {
    return axios
      .post(
        endpoints.community.subscription.cancelCommunitySubscription(
          communityId
        ),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Make user admin of selected groups in a community
   * @param {*} user
   * @param {*} communityId
   * @returns
   */
  static async getCommunityThemeData(user, communityId) {
    return axios
      .get(endpoints.community.theme.communityThemeData(communityId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update community theme data
   * @param {*} user
   * @param {*} communityId
   * @param {*} themedata
   */
  static async updateCommunityThemeData(user, communityId, themedata) {
    return axios
      .post(
        endpoints.community.theme.communityThemeData(communityId),
        themedata,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
  static async toggleCommunityThemeData(user, communityId, themedata) {
    return axios
      .post(
        endpoints.community.theme.communityThemeDataToggle(communityId),
        themedata,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get deals for the community
   * @param {*} user
   * @param {*} communityId
   */
  static async getDeals() {
    return axios
      .get(endpoints.community.deals.getDeals, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Redeem Deal on community
   * @param {*} user
   * @param {*} community
   * @param {*} code
   * @returns
   */
  static async redeemDeal(user, community, code) {
    return axios
      .post(
        endpoints.community.deals.redeemDeal(community.id),
        {
          communityPlanType: code,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async toggleCommunityLoginRequirement(
    user,
    communityId,
    isLoginRequired
  ) {
    return axios
      .put(
        endpoints.community.settings.toggleCommunityLoginRequirement(
          communityId
        ),
        {
          loginRequired: isLoginRequired,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Update community config with a configKey and a configValue
   * @param {*} user
   * @param {*} communityId
   * @param {String} configKey
   * @param {*} configValue
   * @returns
   */
  static async updateConfig(user, communityId, configKey, configValue) {
    return axios
      .put(
        endpoints.community.settings.updateConfig(communityId),
        {
          configKey,
          configValue,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Update community config with a configKey and a configValue in community.configuration
   * @param {*} user
   * @param {*} communityId
   * @param {String} configKey
   * @param {*} configValue
   * @returns
   */
  static async updateConfigV2(user, communityId, configKey, configValue) {
    return axios
      .put(
        endpoints.community.settings.updateConfigV2(communityId),
        {
          configKey,
          configValue,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async getConfigurable(communityId, configurableKey) {
    return axios
      .get(endpoints.community.configurable(communityId, configurableKey), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  static async updateConfigurable(
    communityId,
    configurableKey,
    configurableValue
  ) {
    return axios
      .post(
        endpoints.community.configurable(communityId, configurableKey),
        {
          configurableValue,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get analytics link for admin user
   * @param {*} communityId
   * @returns
   */
  static async getAnalyticsLink(communityId) {
    return axios
      .get(endpoints.community.analytics.getAnalyticsLink(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  static async getAdditionalConfiguration(communityId) {
    return axios
      .get(
        endpoints.community.additionalConfiguration.getConfiguration(
          communityId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  static async saveAdditionalConfigurationDefaultJoinGroups(
    communityId,
    groupIds = []
  ) {
    return axios
      .post(
        endpoints.community.additionalConfiguration.saveDefaultJoinGroups(
          communityId
        ),
        {
          groupIds,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}

export { CommunityService };
