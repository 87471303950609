import { mixPanel } from "../../_config/mixpanel.config";
import { CommunityService } from "../../_service";
import { setGroups, setIsGroupsLoading } from "./group.actions";

export const SET_COMMUNITY = "SET_COMMUNITY";

/**
 * Set community
 * @param {*} community
 * @returns
 */
export function setCommunity(community = null) {
  return {
    type: SET_COMMUNITY,
    community,
  };
}

// thunk related

/**
 * Get community groups
 */
export function getCommunityGroups() {
  return (dispatch, getState) => {
    // get the user
    const { auth: user, community } = getState();
    // get all the groups
    CommunityService.getCommunityGroups(
      community.id,
      user ? user : { token: null }
    )
      .then(({ groups }) => {
        dispatch(setGroups(groups));
        dispatch(setIsGroupsLoading(false));
        if (user && groups && groups.length > 0) {
          const map = groups
            .map((group, index) => {
              return {
                [`GR - ${group.name}`]: group.myRole,
              };
            })
            .reduce((acc, group) => Object.assign(acc, group, {}));

          if (map && user) {
            mixPanel.people.set(map);
          }
        }
      })
      .catch((err) => {
        dispatch(setIsGroupsLoading(false));
        // TODO: show error
        console.log("getCommunityGroups Redux failed", { err });
      });
  };
}
