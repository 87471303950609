import { SectionMessageBadgeService } from "../../_service";

export const SET_REALTIME_SECTION_NOTIFICATION_COUNT =
  "SET_REALTIME_SECTION_NOTIFICATION_COUNT";

/**
 * @param count - An object containing the counts for different sections.
 * @returns An action object with type and payload.
 */
const setRealtimeSectionNotificationCount = (count: {
  [key: string]: number;
}) => ({
  type: SET_REALTIME_SECTION_NOTIFICATION_COUNT,
  payload: count,
});

/**
 * Gets the real-time section notification badge count.
 */
export function getRealtimeSectionNotificationCount() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      const data =
        await SectionMessageBadgeService.getSectionMessageNotificationBadge(
          community.id
        );
      dispatch(setRealtimeSectionNotificationCount(data));
    } catch (error) {
      console.error("ERROR", error);
    }
  };
}
/**
 * Marks the real-time section notification count as read.
 * @param activeTabId - The ID of the active tab.
 */
export function markAsReadRealtimeSectionCount(activeTabId: string) {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community, realTimeSectionCountReducer } = getState();
      if (!user || !community) {
        return;
      }
      const { realtimeSectionNotificationCount } = realTimeSectionCountReducer;

      // Check if the active tab is available in the real-time section notification count.
      const isAvailable = Object.keys(
        realtimeSectionNotificationCount.count
      ).includes(activeTabId);

      if (!isAvailable) return;

      // Mark the active tab as read.
      await SectionMessageBadgeService.getSectionMessageNotificationMarkAsRead(
        community.id,
        activeTabId
      ).then((response) => {
        const updatedNotificationBadges =
          realtimeSectionNotificationCount.count;
        // Remove the badge count key for the active tab.
        delete updatedNotificationBadges[activeTabId];
        dispatch(
          setRealtimeSectionNotificationCount({
            count: updatedNotificationBadges,
          })
        );
      });
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 * Increase the count by 1 of the real-time section notification badge.
 * If the count is 0 or undefined, it will add the count to the state.
 * @param sectionId - The ID of the section.
 */
export function IncreaseGroupChatBadgeCount(sectionId: string) {
  return (dispatch: any, getState: any) => {
    try {
      const { auth: user, community, realTimeSectionCountReducer } = getState();
      if (!user || !community) {
        return;
      }
      const { realtimeSectionNotificationCount } = realTimeSectionCountReducer;

      // Check if the section is available in the real-time section notification count.
      const isAvailable = Object.keys(
        realtimeSectionNotificationCount.count
      ).includes(sectionId);

      if (!isAvailable) {
        // Add the count to the state.
        dispatch(
          setRealtimeSectionNotificationCount({
            count: {
              ...realtimeSectionNotificationCount.count,
              [sectionId]: 1,
            },
          })
        );
        return;
      }

      // Update the count by 1.
      const updatedNotificationBadges = realtimeSectionNotificationCount.count;
      updatedNotificationBadges[sectionId] += 1;
      dispatch(
        setRealtimeSectionNotificationCount({
          count: updatedNotificationBadges,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}
