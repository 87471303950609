/* eslint-disable no-unused-expressions */
import { useEffect, useState } from "react";

export default function useHelpScout(community) {
  const [isHelpscoutEnabled, setIsHelpscoutEnabled] = useState(false);

  useEffect(() => {
    if (community && community.myRole === "admin" && !isHelpscoutEnabled) {
      // we can init helpscout
      initHelpscout();
      setIsHelpscoutEnabled(true);
    }
  }, [community, isHelpscoutEnabled]);
}

function initHelpscout() {
  !(function (e, t, n) {
    function a() {
      let e = t.getElementsByTagName("script")[0],
        n = t.createElement("script");
      (n.type = "text/javascript"),
        (n.async = !0),
        (n.src = "https://beacon-v2.helpscout.net"),
        e.parentNode.insertBefore(n, e);
    }
    if (
      ((e.Beacon = n =
        function (t, n, a) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a });
        }),
      (n.readyQueue = []),
      "complete" === t.readyState)
    )
      return a();
    e.attachEvent
      ? e.attachEvent("onload", a)
      : e.addEventListener("load", a, !1);
  })(window, document, window.Beacon || function () {});
  if (process.env.REACT_APP_ENV === "production" || true) {
    window.Beacon("init", "c8430dd6-2a64-4b61-962f-ed18a2da7ea4");
    // hide icon
    window.Beacon("config", { display: { style: "manual" } });
  }
}
