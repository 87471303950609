import cx from "classnames";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { cn } from "./button.component";

interface Props {
  children: React.ReactNode;
  footer?: React.ReactNode;
  button?: React.ReactNode;
  title?: string;
  className?: string;
  childrenClassName?: string;
  visible: boolean;
  hideHeader?: boolean;
  headerBgDefault?: boolean;
  setVisible: (visible: boolean) => void;
}

export default function ModalForm({
  children,
  childrenClassName,
  footer,
  button,
  title,
  className = "",
  visible,
  hideHeader = false,
  headerBgDefault,
  setVisible,
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        event.keyCode === 27
      ) {
        console.log("esc", event.keyCode);
        setVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      let html = document.querySelector("html");
      if (html) {
        html.style.overflowY = "auto";
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setVisible, wrapperRef]);

  useEffect(() => {
    let html = document.querySelector("html");

    if (html) {
      if (visible && html) {
        html.style.overflowY = "hidden";

        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

        const modal = document.querySelector("#modal"); // select the modal by it's id
        if (!modal) return;

        const firstFocusableElement =
          modal.querySelectorAll(focusableElements)[1]; // get first element to be focused inside modal

        const focusableContent = modal.querySelectorAll(focusableElements);

        const lastFocusableElement =
          focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

        document.addEventListener("keydown", function (e) {
          if (e.keyCode === 27) {
            setVisible(false);
          }

          let isTabPressed = e.key === "Tab" || e.keyCode === 9;

          if (!isTabPressed) {
            return;
          }

          if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              // @ts-ignore
              lastFocusableElement.focus(); // add focus for the last focusable element
              e.preventDefault();
            }
          } else {
            // if tab key is pressed
            if (document.activeElement === lastFocusableElement) {
              // if focused has reached to last focusable element then focus first focusable element after pressing tab
              // @ts-ignore
              firstFocusableElement.focus(); // add focus for the first focusable element
              e.preventDefault();
            }
          }
        });
        // @ts-ignore
        firstFocusableElement?.focus();
      } else {
        html.style.overflowY = "visible";
      }
    }
  }, [setVisible, visible]);

  return (
    <>
      {button ? <div onClick={() => setVisible(true)}>{button}</div> : <></>}

      {visible && typeof document !== "undefined"
        ? ReactDOM.createPortal(
            <div
              className="fixed top-0 left-0 flex h-screen w-screen items-center justify-center "
              aria-labelledby="header-4a content-4a footer-4a"
              aria-modal="true"
              tabIndex={-1}
              role="dialog"
              style={{ zIndex: "999" }}>
              <div
                className="absolute h-screen w-screen bg-slate-300/20 backdrop-blur-sm z-0 cursor-pointer"
                onClick={() => setVisible(false)}
              />
              {/*    <!-- Modal --> */}
              <div
                // ref={wrapperRef}
                className={cx(
                  "flex max-h-screen max-w-7xl flex-col rounded theme-bg-surface theme-text-heading-1 shadow-xl shadow-slate-700/10 z-10",
                  className
                )}
                id="modal"
                role="document">
                {/*        <!-- Modal header --> */}
                <header
                  id="header-4a"
                  className={cx("flex items-center pl-4 pr-2 pt-1", {
                    hidden: hideHeader,
                    "theme-bg-default": headerBgDefault,
                  })}>
                  <h3 className="flex-1 text-lg font-semibold theme-text-heading-1">
                    {title}
                  </h3>
                  <button
                    onClick={() => setVisible(false)}
                    className="inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide  theme-text-primary transition duration-300 hover:theme-bg-primary-light  focus-visible:outline-none "
                    aria-label="close dialog">
                    <span className="relative only:-mx-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        role="img"
                        aria-labelledby="title-79 desc-79">
                        <title id="title-79">Icon title</title>
                        <desc id="desc-79">
                          A more detailed description of the icon
                        </desc>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </button>
                </header>
                {/*        <!-- Modal content --> */}
                <div
                  id="content-4a"
                  className={cn(cx("p-6", childrenClassName))}>
                  {children}
                </div>
                {footer && (
                  <footer id="footer-4a" className="px-4 pb-4">
                    {footer}
                  </footer>
                )}
              </div>
            </div>,
            document.body
          )
        : null}
    </>
  );
}
