function UserProfileHeaderShimmer() {
  return (
    <div className="my-3 theme-bg-surface rounded  shadow animate-pulse">
      {/* User Tile */}
      <div className="flex place-items-center p-4">
        {/* User Avatar */}
        <p className="w-32 h-32 bg-gray-300 rounded-full "></p>
        <div className="w-max ml-2 space-y-3 ">
          <p className="w-28 h-2 bg-gray-300 rounded"></p>
          <p className="w-24 h-2 mt-2  bg-gray-300 rounded" />
        </div>
      </div>

      <div className="grid justify-items-end w-full">
        {/* Divider */}
        <div className="w-8/12 h-0.5  bg-gray-100 rounded mt-4 place-self-end" />
        {/* Social Icons */}
        <div className="w-full flex place-content-center mt-3 space-x-4">
          <p className="w-6 h-6 bg-gray-300 rounded-md "></p>
          <p className="w-6 h-6 bg-gray-300 rounded-md "></p>
          <p className="w-6 h-6 bg-gray-300 rounded-md "></p>
          <p className="w-6 h-6 bg-gray-300 rounded-md "></p>
          <p className="w-6 h-6 bg-gray-300 rounded-md "></p>
          <p className="w-6 h-6 bg-gray-300 rounded-md "></p>
        </div>
      </div>
      {/* Divider */}
      <div className="w-full h-0.5 bg-gray-100 rounded mt-3" />
      {/* Tabs */}
      <div className="flex place-items-center  p-4">
        <div className="w-12 h-3 bg-gray-200 rounded  m-l-2 ml-2" />
        <div className="w-12 h-3 bg-gray-200 rounded  m-l-2 ml-12" />
        <div className="w-12 h-3 bg-gray-200 rounded  m-l-2 ml-12" />
      </div>
    </div>
  );
}

export default UserProfileHeaderShimmer;
