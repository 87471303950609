export const etEstonian = {
  "Pensil Keyword": "Pensil -Schlüsselwort",
  "Home Page Left column": "Avalehe vasak veerg",
  Home: "Kodu",
  "Explore Groups": "Avastage rühmi",
  Events: "Sündmused",
  Groups: "Rühmad",
  Links: "Lingid",
  "Direct Message": "Otsene sõnum",
  "Powered by": "Toiteallikaks",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Siin pole ühtegi rühma, palun kasutage + uue loomiseks või liituge nendega siit.",
  "Please join a group to see them here":
    "Palun liituge rühmaga, et neid siin näha",
  "Channel can only be reordered within the group!":
    "Jagu saab ümber korraldada ainult rühmas!",
  "Group Page": "Rühmaleht",
  "Invite Members": "Kutsuge liikmeid",
  Join: "Ühinema",
  Joined: "Ühendatud",
  "Create new Channel": "Looge uus jaotis",
  "Group Setting drop down": "Grupi seadistus rippmenüü",
  Share: "Osa",
  "Edit group": "Redigeerimisrühm",
  "Delete group": "Kustutusrühm",
  Leave: "Lahkuma",
  "Are you sure you want to delete the group?":
    "Kas olete kindel, et soovite rühma kustutada?",
  Cancel: "Tühistama",
  "Yes, Go Ahead": "Jah, lase käia",
  "Share via link": "Jaga lingi kaudu",
  Copy: "Koopia",
  Copied: "Kopeeritud",
  "Share on social media": "Jagage sotsiaalmeedias",
  "Add Channel": "Lisada jaotis",
  "Channel Header": "Sektsiooni päis",
  Live: "Elama",
  "Create Post": "Loo postitus",
  "Notificaiton Bell": "Teavikaiton bell",
  "All posts": "Kõik postitused",
  "Admin posts only": "Ainult administraatori postitused",
  None: "Mitte ükski",
  "Channel Setting drop down": "Sektsiooni seadistamine rippmenüü",
  "Edit Channel": "Redigeerimise osa",
  "Embed Channel": "Manustatud osa",
  "Delete Channel": "Kustutamisosa",
  "Are you sure you want to delete the Channel?":
    "Kas olete kindel, et soovite jaotise kustutada?",
  "Channel deleted!": "Jaotis kustutatud!",
  "Create Group Pop up / Update": "Looge grupi hüpik / värskendus",
  "Create group": "Loo grupp",
  "Basic Detail": "Põhiosa",
  Settings: "Sätted",
  "Size 16:9 or 1600 by 900": "Suurus 16: 9 või 1600 kuni 900",
  "Group name": "Grupi nimi",
  "Enter group name": "Sisestage grupi nimi",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Soovitused: ruumid, kanalid, rühmad, ajaveeb, teadmistebaas jne",
  "Group Description": "Rühma kirjeldus",
  "Enter description here": "Sisestage kirjeldus siin",
  "Group Type": "Grupitüüp",
  Open: "Avatud",
  "Visible to all & anyone can join": "Nähtav kõigile ja igaühele saab liituda",
  Closed: "Suletud",
  "User will have to request to join the group":
    "Kasutaja peab taotlema grupiga liitumist",
  Paid: "Makstud",
  "Group Price": "Grupihind",
  "Currency (Default: INR)": "Valuuta (vaikimisi: INR)",
  "User will have to pay to join the group":
    "Kasutaja peab grupiga liitumiseks maksma",
  "Make this a private group": "Tehke sellest privaatgrupp",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Erarühm on nähtav ainult liikmetele, kes on sellest osa. Administraator saab liikmete loendist käsitsi lisada",
  "Upload from device": "Seadmest üleslaadimine",
  "Search image": "Otsingupilt",
  "Valid group name required!": "Vajalik kehtiv grupi nimi!",
  "Update Group": "Värskendusrühm",
  "SEO Settings": "SEO seaded",
  "Custom URL": "Kohandatud URL",
  "Meta Title": "Meta pealkiri",
  "Maximum length of 255 characters allowed":
    "Maksimaalne pikkus 255 tähemärki",
  "Meta Description": "Meta kirjeldus",
  "Open Graph Detail": "Avatud graafi detail",
  "Open Graph Title": "Avatud graafi pealkiri",
  "Open Graph Description": "Avatud graafik kirjeldus",
  "Open Graph Image": "Avatud graafik pilt",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Soovitatavad mõõtmed: 1200 pikslit 630 pikslit 1,91: 1 kuvasuhtega.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Kui URL -i ei kasutata, valis see teie grupi pisipilt automaatselt.",
  "Create Channel Pop up / Update": "Looge sektsioon hüpikaks / värskendage",
  "Create Channel": "Loo osa",
  "Channel Name": "Sektsiooni nimi",
  "Suggestions: General, Introductions, Community Guidelines":
    "Soovitused: üldine, sissejuhatused, kogukonna juhised",
  "Channel Type": "Sektsiooni tüüp",
  "Post Type": "Postitaime",
  "Group Chat": "Grupivestlus",
  "Who can post in this Channel?": "Kes saab sellesse jaotisse postitada?",
  "Only Admin": "Ainult administraator",
  "Any user": "Iga kasutaja",
  "Sorting order for Channel": "Jaotise sorteerimisjärjekord",
  "Latest first": "Viimane esimene",
  "Oldest post first": "Vanim postitus esimene",
  "Channel Appearance": "Sektsiooni välimus",
  "List View": "Loendivaade",
  "Grid View": "Ruudustiku vaade",
  "Make this closed Channel": "Tehke see suletud osa",
  "Members will have to request access to see Channel content":
    "Liikmed peavad jaotise sisu nägemiseks taotlema juurdepääsu",
  "Make this a private Channel": "Tehke sellest privaatne osa",
  "Disables requests (Make sure closed Channel is selected as well).":
    "Keelake taotlused (veenduge, et ka suletud jaotis on valitud).",
  "Post Editor": "Postitoimetaja",
  "Create new post in": "Looge uus postitus aastal",
  "Add Cover Picture": "Lisage kaanepilt",
  "Heading (Optional)": "Pealkiri (valikuline)",
  "Write here..": "Kirjuta siia..",
  Picture: "Pilt",
  Video: "Video",
  Attachment: "Kiindumus",
  Poll: "Küsitlus",
  "Embed Code": "Manustatud kood",
  Tags: "Sildid",
  "Toggle to question": "Küsimustesse lülituma",
  "Poll expire time": "Küsitluse aegumine aeg",
  "Poll question": "Küsitlusküsimus",
  "Enter Question": "Küsimust sisestama",
  Question: "Küsimus",
  Option: "Optsioon",
  Options: "Valikud",
  "Add Option": "Lisamisvõimalus",
  "Poll Expiry time": "Küsitluse kehtivusaeg",
  "12 Hours": "12 tundi",
  "1 Day": "1 päev",
  "2 Days": "2 päeva",
  "1 Week": "1 nädal",
  "2 Weeks": "2 nädalat",
  "Always Open": "Alati avatud",
  "Write embeddable code": "Kirjutage manustatav kood",
  "Click here to add embed code.": "Manustatud koodi lisamiseks klõpsake siin.",
  "Paste your embed code here!": "Kleepige oma manustatud kood siia!",
  "Post Settings": "Postitusseaded",
  "Update Post": "Värskenduspostitus",
  Update: "Värskendama",
  "Start meeting / Video call / Live":
    "Alustage kohtumist / videokõnet / otseülekannet",
  "Meeting Name": "Koosolekunimi",
  "Suggestion: Group discussion etc": "Soovitus: grupi arutelu jne",
  Description: "Kirjeldus",
  "Enter description here (optional)": "Sisestage kirjeldus siin (valikuline)",
  "When do you want to start the session": "Millal soovite seanssi alustada",
  "Right now": "Praegu",
  "Schedule for later": "Ajakava hilisemaks",
  "Group video call": "Rühma videokõne",
  Webinar: "Veebiseminar",
  "Go Live": "Elama",
  "Info on the Post": "Teave postituse kohta",
  "Post comment": "Postita kommentaar",
  Comment: "Kommentaar",
  Comments: "Kommentaarid",
  Reply: "Vastama",
  Replies: "Vastused",
  "Share via URL": "Jaga URL -i kaudu",
  "Reshare in other Channels": "Reshare teistes sektsioonides",
  "Post Card Drop Down": "Postikaardi rippmenüü",
  Bookmark: "Järjehoidja",
  "Pin post": "Tihvtipost",
  Edit: "Redigeerima",
  "Embed Post": "Manustatud postitus",
  Delete: "Kustutama",
  "Remove Pin": "Eemaldage tihvt",
  "Remove Bookmark": "Eemaldage järjehoidja",
  "Just copy paste this code wherever you want it to be embedded":
    "Kopeerige see kood lihtsalt kõikjal, kus soovite, et see manustataks",
  Height: "Kõrgus",
  Preview: "Eelvaade",
  "Copied to clipboard": "Kopeeritud lõikelauale",
  "Select Widgets": "Valige vidinad",
  "Add widgets to": "Lisage vidinad",
  "You can change their order by dragging & dropping the widgets over each other":
    "Nende tellimust saate muuta, lohistades ja vidinaid üksteise peale visates",
  "Please note: Widget wont be displayed if there is no data available,":
    "Pange tähele: vidina ei kuvata, kui andmeid pole saadaval,",
  "Group Members": "Grupi liikmed",
  "Group Leaderboard": "Grupi edetabel",
  "Community Leaderboard": "Kogukonna edetabel",
  "Trending Posts": "Trendikate postitused",
  "Group Events": "Grupiüritused",
  "Add Custom": "Lisage tava",
  "Save Widgets": "Salvesta vidinaid",
  "Visible to user": "Kasutajale nähtav",
  "Visible to admin": "Administraatori nähtav",
  "Add widget to": "Lisage vidin",
  "Upload cover picture": "Üleslaadige kaanepilt",
  "1:1 aspect ration (recommended)": "1: 1 aspekt (soovitatav)",
  "Card Title": "Kaardipealkiri",
  "Enter widget name": "Sisestage vidina nimi",
  "Small Description (try to keep it under 50 words)":
    "Väike kirjeldus (proovige seda hoida alla 50 sõna)",
  "Enter description": "Sisestage kirjeldus",
  "Button Title": "Nupppealkiri",
  "Enter button name": "Sisestage nupu nimi",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestion: tead rohkem, liitu, kandideerima jne",
  "URL link": "URL -link",
  "Create custom widget": "Looge kohandatud vidin",
  "Custom widget created successfully": "Kohandatud vidin loodud edukalt",
  Widgets: "Vidinad",
  Members: "Liikmed",
  "See All": "Vaata kõiki",
  "Communtiy Top Contributors": "Communtisy parimad kaastöötajad",
  "Community Members": "Kogukonna liikmed",
  "Group Top Contributors": "Grupi parimad kaastöötajad",
  "Upcoming Events": "Eesseisvad Sündmused",
  "Home Page Banner": "Avalehe riba",
  "Edit Banner": "Redigeeri ribareklaami",
  "Home page Edit Banner Pop up": "Avalehe redigeeri ribareklaami hüppama",
  "Update Banner": "Uuendage ribareklaami",
  Search: "Otsing",
  "Upload Image": "Lae pilt üles",
  Remove: "Eemaldama",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Bänneri pilt (parima vaate jaoks kasutage suhet 16: 6 või 1600 pikslit 600px)",
  "Banner Title": "Ribareklaami pealkiri",
  "Enter here": "Sisestage siia",
  "Suggestion: Welcome to my community etc":
    "Soovitus: tere tulemast minu kogukonda jne",
  "Banner Description": "Ribareklaami kirjeldus",
  "Enter banner description": "Sisestage ribareklaami kirjeldus",
  "Save Banner Info": "Salvestage ribareklaami teave",
  "Home Page Feed": "Avalehe sööt",
  Recent: "Hiljutine",
  "Top Posts": "Tipppostitused",
  Trending: "Trendikas",
  "Home Feed Groups": "Kodu söödagrupid",
  "Header BarProfile Drop down": "Päise barprofile rippmenüü",
  "My Profile": "Minu profiil",
  Logout: "Logi välja",
  Notification: "Teatamine",
  Notifications: "Teatised",
  "Mark all viewed": "Märkige kõik vaadatud",
  "has created a post": "on loonud postituse",
  "wants to join": "tahab liituda",
  "has commented on your post": "on teie postitust kommenteerinud",
  "has replied to your comment": "on teie kommentaarile vastanud",
  "Search for tags": "Otsige silte",
  "All groups": "Kõik rühmad",
  "Search result": "Otsingutulemus",
  "No post found for selected hashtag":
    "Valitud hashtagi kohta ei leitud postitust",
  "Start a conversation": "Alustama vestlust",
  "Search by name": "Otsi nime järgi",
  Upcoming: "Tulevane",
  UPCOMING: "Tulevane",
  Past: "Möödunud",
  PAST: "Möödunud",
  "No upcoming events": "Eelseisvaid sündmusi pole",
  "Create event popup": "Looge sündmuste hüpik",
  "Event details": "Sündmuse üksikasjad",
  "16:9 aspect ratio": "16: 9 kuvasuhe",
  "Event name": "Sündmuse nimi",
  "Enter event name": "Sisestage sündmuse nimi",
  "Host Name": "Vastuvõtmisnimi",
  "You will be made host if none selected":
    "Teist tehakse hostiks, kui ükski valitakse",
  "Write here...": "Kirjuta siia...",
  "No. of registration for the event": "Ürituse registreerimise arv",
  Unlimited: "Piiramatu",
  "When & where": "Millal ja kus",
  From: "-Lt",
  "Start date": "Algus kuupäev",
  time: "aeg",
  To: "Juurde",
  "End date": "Lõppkuupäev",
  "Current time zone:": "Praegune ajavöönd:",
  "Can be updated from the communtiy setting":
    "Saab värskendada Commutiy seadistusest",
  "Online event": "Veebisündmus",
  "Add meeting link": "Lisage koosoleku link",
  "Meeting link will be generated once event is created":
    "Koosoleku link genereeritakse pärast sündmuse loomist",
  Venue: "Koht",
  "Enter address": "Sisestage aadress",
  Create: "Looma",
  Invite: "Kutsuma",
  "Share event via link": "Jagage sündmus lingi kaudu",
  Copy__1: "Koopia",
  "Select Channel to post this event":
    "Selle sündmuse postitamiseks valige jaotis",
  "Once posted, members of that group or Channel will recieve an notification":
    "Pärast postitamist saavad selle grupi või jaotise liikmed teatise",
  Post: "Postitus",
  RSVP: "RSVP",
  "Book Event": "Raamatusündmus",
  "Yes, I'll attend": "Jah, ma osalen",
  "May be": "Võib olla",
  "No, I won't attend": "Ei, ma ei käi",
  "Date & Time": "Kuupäev Kellaaeg",
  Host: "Võõrustaja",
  "Online meeting": "Veebikoosolek",
  "Meeting link will be visible once registered for the event":
    "Koosoleku link on nähtav pärast ürituse jaoks registreerumist",
  "User Management": "Kasutajahaldus",
  "Go bac to community": "Minge Baci kogukonda",
  "Manage Members": "Liikmeid juhtima",
  Member: "Liige",
  Invited: "Kutsutud",
  "Search for member": "Otsige liiget",
  "Add to group": "Lisage rühma",
  "Send Direct Message": "Saada otsesõnum",
  "Change role": "Roll",
  "Kick from community": "Kogukonna löök",
  "Super Admin": "Super administraator",
  "Super admin will have full access to the communtiy settings":
    "Super Administraatoril on täielik juurdepääs Commutiy seadetele",
  "Group Admin": "Rühma administraator",
  "Admin of the selected group can edit the group information and create, edt or delete Channel under selected group":
    "Valitud rühma administraator saab redigeerida grupiteavet ja luua valitud grupi alusel jaotist EDT või kustutada",
  "Group Moderator": "Grupimoderaator",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Grupi moderaator saab valitud rühmas luua või kustutada postituse, kommenteerida, vastata ja sõnumid.",
  User: "Kasutaja",
  Save: "Kokkuhoid",
  "User role updated successfully": "Kasutaja roll värskendati edukalt",
  Leaderboard: "Edetabelilaud",
  "Click on the column header for sorting":
    "Sorteerimiseks klõpsake veeru päisel",
  Rank: "Koht",
  "Karma Points": "Karmapunktid",
  "Custom Points": "Kohandatud punktid",
  Follow: "Järgnema",
  Upvotes: "Hääled",
  Posts: "Postitused",
  "Add custom points": "Lisage kohandatud punkte",
  "Subtract custom points": "Lahutage kohandatud punktid",
  "Enter no. of points": "Sisestage nr. punktidest",
  "Add points": "Lisama punkte",
  "Invite members to": "Kutsuge liikmeid",
  "Invite via link": "Kutsu Link kaudu",
  "Invite via email": "Kutsu e -posti teel",
  "Add more": "Lisada veel",
  "Upload a CSV": "Laadige üles CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Laadige üles komaseesega CSV-fail, mille nimi on päis, e-post.",
  "Here is a template CSV file": "Siin on mall CSV -fail",
  "Upload File": "Faili üles laadima",
  "Send Invite": "Saada kutse",
  "Mail Template": "Postimall",
  "Send Test Mail": "Saada testpost",
  Hey: "Hei",
  "A has invite you to join the B": "A on kutsunud teid liituma B -ga",
  "Switch to rich text editor": "Lülitage rikka tekstiredaktorile",
  "Add Custom Message": "Lisage kohandatud teade",
  "Enter message here": "Sisestage teade siia",
  "Send Bulk Mail": "Saada hulgipost",
  "Send Mail": "Kirja saatma",
  "Send bulk mail to all community members or select group members":
    "Saada hulgipost kõigile kogukonna liikmetele või valitud rühma liikmetele",
  Date: "Kuupäev",
  Subject: "Teema",
  Recipients: "Saajad",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "Selleks kuuks on jäänud 1000 hulgimeili. Lähtestatakse <kuupäev>",
  Everyone: "Igaüks",
  "Enter mail subject": "Sisestage meilisõnum",
  "Write message": "Kirjutage teade",
  Cancel__1: "Tühistama",
  Send: "Saada",
  "Profile Page": "Profiilileht",
  Activity: "Aktiivsus",
  Bookmarks: "Järjehoidjad",
  "Edit Profile": "Muuda profiili",
  Unsubscribe: "Tellima",
  "Settings (Account)": "Seaded (konto)",
  Profile: "Profiil",
  "Set profile Picture": "Seadke profiilipilt",
  "Your name": "Sinu nimi",
  "User ID": "kasutaja ID",
  "Short bio": "Lühike biograafia",
  "Link your social account, this will be displayed on your profile":
    "Linkige oma sotsiaalne konto, see kuvatakse teie profiilil",
  "Add social handle": "Lisage sotsiaalne käepide",
  Verification: "Kontrollimine",
  "Account Verification": "Konto kinnitamine",
  Email: "E -kiri",
  "Enter email": "Sisestage e -post",
  Verified: "Kinnitatud",
  Unverified: "Kontrollimata",
  Change: "Vahetus",
  Unlink: "Lahti ühendama",
  Mobile: "Mobiilne",
  "enter mobile no.": "Sisestage mobiilne nr.",
  "Request OTP": "Taotle OTP",
  "Notification centre": "Teavitamiskeskus",
  "Manage your personal account notification preferences":
    "Hallake oma isiklike kontoteate eelistusi",
  Prefrences: "Eelte",
  "Email notification": "E -posti teatis",
  "Notify me about comments on my posts":
    "Teatage mulle kommentaaridest oma postituste kohta",
  "Notify me about replies on my comments":
    "Teatage mulle vastustest oma kommentaaridest",
  "Notify me about upvotes on my posts":
    "Teavitage mulle oma postituste ülesvõtteid",
  "Notify me about reaction on my comments":
    "Teatage mulle reaktsioonist oma kommentaarides",
  "Notify me about reaction on my reply":
    "Teatage mulle reaktsioonist minu vastusest",
  "Notify me about direct message": "Teatage mulle otsese sõnumi kohta",
  "Notify me about my mentions": "Teatage mulle oma mainimistest",
  "Notification for new posts": "Uute postituste teatamine",
  "Only group & Channel available which you are part of":
    "Saadaval on ainult rühm ja jaotis, millesse kuulute",
  "Group & Channel": "Rühm ja sektsioon",
  "All activity": "Kogu tegevus",
  "Post by admin": "Postitama administraator",
  "No Notification": "Ei teavitamist",
  "Setting (Community)": "Seadistamine (kogukond)",
  "General Settings": "üldised seaded",
  "Upload community logo": "Laadige üles kogukonna logo",
  "Community name": "Kogukonna nimi",
  "Invalid field": "Kehtetu väli",
  "Your community address": "Teie kogukonna aadress",
  "Fav icon": "Fav ikoon",
  "This will be visible on the browser tab (32px by 32px)":
    "See on nähtav vahekaardil Brauser (32 pikslit 32 pikslit)",
  TimeZone: "Ajavöönd",
  "Privacy Policy on login page": "Privaatsuspoliitika sisselogimislehel",
  "Terms & Condition on login page":
    "Tingimused ja tingimused sisselogimislehel",
  "Enter link here": "Sisestage link siia",
  "Open Group Image": "Avatud grupi pilt",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Vaikimisi võtab IR kokku kogukonna riba, mis on üles laaditud koos Communitiyga",
  "Community Experience": "Kogukonnakogemus",
  Onboarding: "Pardalevõtmine",
  "Make your community private": "Muutke oma kogukond privaatseks",
  "Users will have to login or sign up to access the community":
    "Kasutajad peavad kogukonnale juurdepääsu saamiseks sisse logima või registreeruma",
  "Make account verification mandatory for both email & mobile":
    "Muutke konto kinnitamine kohustuslikuks nii e -posti kui ka mobiili jaoks",
  "New user will have to verify both email & mobile no. to access the community":
    "Uus kasutaja peab kontrollima nii e -posti kui ka mobiili nr. Kogukonnale juurdepääsu saamiseks",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Administraator peab kontrollima ka nende mobiiltelefoni nr. & e -kiri, vastasel juhul ei pääse nad kogukonnale juurde",
  "Gated community access": "Värava kogukonna juurdepääs",
  "Admin will have to approve or reject user's requests to join the community":
    "Administraator peab heaks kiitma või tagasi lükkama kasutaja taotlused kogukonnaga liitumiseks",
  "Disable mobile login/register": "Keela mobiilse sisselogimise/registri",
  "Disable email login/register": "Keela e -posti sisselogimine/register",
  "Disable google login/register": "Keelake Google'i sisselogimine/register",
  "Disable facebook login/register": "Keela Facebooki sisselogimine/register",
  "Show & Hide features": "Näita ja peida funktsioonid",
  "Hide direct chat": "Peida otsene vestlus",
  "User will not be able to send direct messages to each other":
    "Kasutaja ei saa üksteisele otsesõnumeid saata",
  "Hide profile picture from top right corner for mobile responsive view":
    "Peida profiilipilt paremast ülanurgast, et mobiili reageerivaks vaatele",
  "Useful when using community in an in app browser":
    "Kasulik kasutades kogukonda rakenduses Brauseris",
  "Hide profile picture from top right corner in every view":
    "Peida profiilipilt paremas ülanurgast igas vaates",
  "Disable header dropdown on profile picture":
    "Keelake päise rippprofiilipildil",
  "Useful for integrated community, especially using SSO":
    "Kasulik integreeritud kogukonnale, eriti SSO kasutamine",
  "Hide the communtiy logo from the header": "Peida kogukonna logo päise eest",
  "Hide the communtiy title from the header":
    "Peida kogukonna pealkiri päise eest",
  "Show collpased group in the side nav bar":
    "Näidake kokkupõrke gruppi külje NAV baaris",
  "Show full expanded post in the home feed":
    "Näita täielikku laiendatud postitust kodus voos",
  "Hide the leaderboard score from the header":
    "Peida edetabeli tulemus päise eest",
  "Show powered by Pensil": "Näita Pensili poolt",
  "Hide closed groups if not joined":
    "Peida suletud rühmad, kui neid pole ühendatud",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Suletud rühmi ei näitaks kasutajale, kui nad pole rühma liige",
  "Hide Community subscriber count from the users":
    "Peida kogukonna abonentide arv kasutajate eest",
  "This will hide the community subscriber list in the community header":
    "See peidab kogukonna abonentide nimekirja kogukonna päises",
  "Disable Communtiy Switcher": "Keela Communittiy Switcher",
  "Use MD editor for create post description":
    "Postituse loomiseks kasutage MD redigeerijat",
  "Non logged in user will land on explore group page instead of post directory":
    "Kasutaja sisselogitud maandub postituse kataloogi asemel grupi lehel Explore Group",
  "Advanced configurable": "Täiustatud konfigureeritav",
  "Communtiy default country code": "CommuniTiy vaikimisi riigi kood",
  "This will show up by default on login/signup":
    "See kuvatakse vaikimisi sisselogimisel/registreerumisel",
  "Onboarding/Welcome Message": "Pardalevõtmine/tervitussõnum",
  "Welcome Message": "Tervitussõnum",
  "Greet your members with personalized message":
    "Tervitage oma liikmeid isikupärastatud sõnumiga",
  "Welcome to": "Tere tulemast",
  Message: "Teade",
  "Add message": "Lisateade lisage",
  "Custom domain": "Kohandatud domeen",
  "Add custom domain": "Lisage kohandatud domeen",
  "Create new request": "Looge uus taotlus",
  "Enter your custom domain": "Sisestage oma kohandatud domeen",
  "Setup details": "Seadistamise üksikasjad",
  "Add a CNAME record in your domain pointing to this community's address":
    "Lisage oma domeeni CNAME kirje, osutades selle kogukonna aadressile",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "Domeeni konfigureerimiseks peate lisama CNAME kirje, mis osutab oma domeenile Pensil Community URL -i",
  "Step by Step help apge": "Samm -sammult abi apge",
  "All request list": "Kõik taotluste nimekiri",
  "Allow admin to assign/un-assign custom points to users":
    "Luba administraatorile kasutajatele kohandatud punkte määrata/määrata kohandatud punkte",
  "Base points for user to begin with": "Baaspunktid kasutaja alustamiseks",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Baaspunkte värskendatakse kõigi kasutajate jaoks, kõik muudatused kajastuvad kõigi kasutajate jaoks.",
  "Allocate points for interaction": "Jaotada suhtlemiseks punkte",
  "Upvoting post": "Häälevõtupost",
  "creating a comment": "Kommentaari loomine",
  "creating a reply": "vastuse loomine",
  "Getting upvote to their post": "Nende postituse hääletamine",
  "Getting comment to their post": "Nende postitusele kommenteerimine",
  "Getting reply to their comment": "Nende kommentaarile vastuse saamine",
  "Creating a post": "Posti loomine",
  Visiblity: "Visilisus",
  "Move Up": "Üles liikuma",
  "Move Down": "Liigu alla",
  "Create embedded Channel": "Loo manustatud osa",
  "Preview area": "Eelvaateala",
  "Theme selector": "Teemavalija",
  "Community Theme": "Kogukonnateema",
  "Select theme": "Valige teema",
  "Select brand color": "Valige kaubamärgi värv",
  "Customize your button color to match your brand":
    "Kohandage oma nupuvärvi, et see vastaks oma brändile",
  "Select on button text color": "Valige nupu teksti värv",
  "Suggestion: Keep inverted contrast against brand color":
    "Soovitus: hoidke ümberpööratud kontrasti brändi värviga",
  Billing: "Arveldus",
  "Current plan": "Praegune plaan",
  "You are current on Free PLan": "Olete tasuta plaanil",
  "Renew on: NA": "Uuendada: na",
  Select: "Valima",
  "Email us at": "Saada meile aadressil",
  "Compare plan": "Plaani võrdlema",
  "API Keys": "API võtmed",
  "Create new key": "Looge uus võti",
  "Total keys": "Klahvid kokku",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Hoidke oma API võtmed turvaliselt! Saate neid kasutada konkreetsele funktsioonile ja lasta Cetaini rakendustel teie nimel platvormil teatud toiminguid teha.",
  "Key name": "Võtmenimi",
  "Key value": "Võtmeväärtus",
  Action: "Toiming",
  "Group updated!": "Rühma värskendatud!",
  "Are you sure you want to": "Kas olete kindel, et soovite",
  "Make Moderator": "Mooge moderaatorit",
  "Remove from group": "Eemaldage grupist",
  "Show All": "Näita kõike",
  "Change Role": "Roll",
  Close: "Sulge",
  copy: "koopia",
  LINKS: "Lingid",
  "Email Id": "E-kirja ID",
  Name: "Nimetus",
  "Search for an image": "Otsige pilti",
  "Crop Image": "Saagi pilt",
  "No photos found": "Fotosid pole leitud",
  Community: "Kogukond",
  "Banner ratio - 16:9": "Bänneri suhe - 16: 9",
  "Banner Ratio - 16:6": "Bänneri suhe - 16: 6",
  "Share on mail": "Jagage postiga",
  "Share on Telegram": "Jaga telegramis",
  "Share on Whatsapp": "Jagage WhatsApp",
  "Share on Linkedin": "Jaga LinkedInis",
  "Share on Facebook": "Jagage Facebookis",
  "Share on Twitter": "Jaga Twitteris",
  "Pin Post": "Tihvtipost",
  "Heading (optional)": "Pealkiri (valikuline)",
  "Select Channel": "Valige jaotis",
  Gif: "Gif",
  "Create Custom Widget": "Looge kohandatud vidin",
  "Are you sure you want to delete this widget?":
    "Kas olete kindel, et soovite selle vidina kustutada?",
  "Go to event": "Minge üritusele",
  "Add Tags": "Lisa märksõnu",
  "Create Tag": "Looge silt",
  "Title (Optional)": "Pealkiri (valikuline)",
  "Search Gif": "Otsige GIF",
  "What's on your mind": "Mis sul mõttes on",
  "Looks like nothing available here right now.":
    "Paistab, et praegu pole siin midagi saadaval.",
  "Try exploring other tabs": "Proovige uurida muid vahekaarte",
  "Delete Widget": "Kustuta vidin",
  "Please select a Channel to post": "Valige postitamiseks jaotis",
  "Post added successfully!": "Postitage edukalt!",
  "Failed to create post. Please check post content and retry again.":
    "Posti loomine ebaõnnestus. Kontrollige postituse sisu ja proovige uuesti.",
  "Are you sure you want to discard current post?":
    "Kas olete kindel, et soovite praeguse postituse ära visata?",
  "By default it will take up the image uploaded with the post":
    "Vaikimisi võtab see pildi üles laaditud pildiga",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Viktoriini saab vaadata ja proovida mobiilseadmest kohe!",
  "Community Banner": "Kogukonna ribareklaam",
  "Change Cover": "Katet vahetama",
  "Suggestions: Welcome to my community etc":
    "Soovitused: tere tulemast minu kogukonda jne",
  "(edited)": "(redigeeritud)",
  "Aspect ratio (recommended)": "Kuvasuhe (soovitatav)",
  "Banner details updated!": "Bänneri üksikasjad on värskendatud!",
  "Refresh if changes are not visible!":
    "Värskendage, kui muudatused pole nähtavad!",
  "Banner details could not be updated!":
    "Bänneri üksikasju ei saanud värskendada!",
  "Post deleted": "Postitus kustutatud",
  "Post not available. It may have been deleted.":
    "Postitus pole saadaval. See võib olla kustutatud.",
  Admin: "Administraator",
  Moderator: "Moderaator",
  "Bookmark added!": "Lisatud järjehoidja!",
  "Bookmark removed": "Järjehoidja eemaldati",
  "Failed performing action!": "Ebaõnnestunud esinemine!",
  "Copy this link to share on other platforms":
    "Kopeerige see link, et jagada teistel platvormidel",
  "View this event": "Vaadake seda sündmust",
  Event: "Sündmus",
  "Join Meeting": "Liituma koosolekuga",
  "The live meeting has ended": "Otseülekanne on lõppenud",
  "read more...": "Loe rohkem...",
  "Load more comments": "Laadige rohkem kommentaare",
  "Post Reply": "Saada vastus",
  "Post Comment": "Postita kommentaar",
  more: "rohkem",
  upvoted: "häälestatud",
  "1 Upvote": "1 UPVOTE",
  "1 Comment": "1 kommentaar",
  "Please enter a title": "Palun sisestage pealkiri",
  "Please enter a url link": "Palun sisestage URL -i link",
  "Please enter button title": "Palun sisestage nupu pealkiri",
  "Button label must be at least 20 characters":
    "Nupp -silt peab olema vähemalt 20 tähemärki",
  "Please enter valid url": "Sisestage kehtiv URL",
  "Suggestions: Know more, Join, Apply...":
    "Soovitused: tead rohkem, liitu, rakendage ...",
  URL: "Url",
  "Community Events": "Kogukonnaüritused",
  "Widget deleted successfully": "Vidin kustutati edukalt",
  "Community Top Contributors": "Kogukonna parimad panustajad",
  points: "punkt",
  "Points cannot be greater than current points":
    "Punktid ei saa olla suuremad kui praegused punktid",
  "Point cannot be a negative value": "Punkt ei saa olla negatiivne väärtus",
  "Point cannot be a decimal point": "Punkt ei saa olla kümnendpunkt",
  "Please enter a valid number": "Sisestage kehtiv number",
  "Karma points updated successfully": "Karmapunktid värskendati edukalt",
  "Error in updating karma points": "Viga karma punktide värskendamisel",
  "Add karma points": "Lisage karmapunktid",
  "Minus karma points": "Miinus karmapunktid",
  "Total Karma points:": "Karma kogupunktid:",
  "Enter no. of karma points": "Sisestage nr. Karma punktidest",
  "Minus Points": "Miinuspunktid",
  "Trending Post": "Trendikapost",
  "No visible description": "Nähtavat kirjeldust pole",
  "Picture ratio: 1:1": "Pildisuhe: 1: 1",
  "home feed": "koduse sööt",
  "Event name is invalid!": "Sündmuse nimi on kehtetu!",
  custom: "kohandatud",
  "Custom widget updated successfully": "Kohandatud vidin värskendatud edukalt",
  "Name invalid!": "Nimi kehtetu!",
  "Current timezone:": "Praegune ajavöönd:",
  "can be updated from community settings":
    "saab kogukonna seadetest värskendada",
  "Event created successfully": "Üritus loodi edukalt",
  "Error in creating event": "Viga sündmuse loomisel",
  Attendees: "Osavõtjad",
  "Do you wish to notify these changes to attendees?":
    "Kas soovite neid muudatusi osalejatele teatada?",
  "users wants to attend this event":
    "kasutajad soovivad sellel üritusel osaleda",
  "Yes, Notify them": "Jah, teavitage neid",
  "No, its okay": "Ei, see on okei",
  "Do you wish to notify that event is cancelled?":
    "Kas soovite teatada, et sündmus tühistatakse?",
  "Are you sure you want to delete the event?":
    "Kas olete kindel, et soovite sündmuse kustutada?",
  "Delete Event": "Kustutaüritus",
  "New Event": "Uus üritus",
  "Accept Invitation": "Kutset aktsepteerima",
  Requested: "Taotletud",
  Declined: "Keeldunud",
  "Request Access": "Taotlege juurdepääsu",
  "Enroll for": "Registreeruma",
  Housefull: "Majakas",
  "Just copy paste this code wherever you want it to be embedded:":
    "Kopeerige lihtsalt see kood, kus iganes soovite, et see manustataks:",
  Ended: "Lõppenud",
  "Error getting events": "Viga sündmuste saamine",
  "Events could not be fetched!": "Üritusi ei saanud tõmmata!",
  "Show Attendees": "Näita kohalolijaid",
  Subscribers: "Tellijad",
  "Make this a closed Channel": "Tehke sellest suletud osa",
  "Update Channel": "Värskendusosa",
  "Valid Channel name required!": "Vajalik kehtiv sektsiooni nimi!",
  "Enter Channel name": "Sisestage jaotise nimi",
  "Community Admin": "Kogukonna administraator",
  Embed: "Manustatud",
  "Invite to": "Kutsuma",
  "Please enter a valid email address": "Palun sisesta kehtiv e-posti aadress",
  "Invitation sent successfully": "Kutse saadeti edukalt",
  "Error sending invitation": "Viga kutse saatmine",
  "Email is not valid!": "E -post ei kehti!",
  "Data could not be loaded!": "Andmeid ei saanud laadida!",
  "Posted in": "Postitatud",
  "Invite Members Individually": "Kutsuge liikmeid individuaalselt",
  "Start a discussion": "Arutelu alustama",
  "Post something": "Midagi postitama",
  "Invite your audience": "Kutsuge oma publikut",
  "Choose Plan": "Valige plaan",
  or: "või",
  "Create a link": "Looge link",
  "Add link": "Lisa link",
  "Update link": "Värskendage linki",
  "Add shortcut to your most used tools":
    "Lisage otsetee oma enim kasutatud tööriistadele",
  "Your website, Instagram Channel, Facebook profile etc":
    "Teie veebisait, Instagrami kanal, Facebooki profiil jne",
  "Valid link name required!": "Vajalik kehtiv lingi nimi!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Vajalik kehtiv URL ja veenduge, et teil poleks http: // või https: //",
  "Invite to__1": "Kutsuma",
  "Link Name": "Lingi nimi",
  "Link URL": "Link URL",
  "Could not subscribe to": "Ei saanud tellida",
  "Subscribed to": "Tellitud",
  "By default it will take up the community banner uploaded with the community":
    "Vaikimisi võtab see kogukonnaga üles laaditud kogukonna riba",
  "Created by": "Loodud",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Veebiüritus (koosoleku link on nähtav, kui ürituse jaoks registreerub)",
  "Join Call": "Liituma",
  "Please login to book this event":
    "Palun logige selle sündmuse broneerimiseks sisse",
  "Commented on": "Kommenteeris",
  "Replied to": "Vastas",
  "Looks like it's lonely here.": "Paistab, et siin on üksildane.",
  "Try exploring other Channels and groups.":
    "Proovige uurida teisi jaotisi ja rühmi.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Sellele grupile juurdepääsu saamiseks pöörduge maksega või pöörduge administraatori poole.",
  "This is a closed group You'll need access to this group.":
    "See on suletud rühm, millele vajate juurdepääsu sellele rühmale.",
  "You have no bookmarks right now.": "Teil pole praegu järjehoidjaid.",
  "The user hasn't posted anything yet publicly.":
    "Kasutaja pole veel midagi avalikult postitanud.",
  "No activity to show.": "Pole ühtegi tegevust, mida näidata.",
  "Are you sure you want to unsubscribe the community?":
    "Kas olete kindel, et soovite kogukonna tellimust tellida?",
  "deals left": "pakkumised jäänud",
  "Something went wrong while loading posts":
    "Postituste laadimisel läks midagi valesti",
  "Could not unsubscribe from": "Ei saanud tellimust tühistada",
  "Unsubscribed from": "Tellimata",
  "Login to view this page": "Selle lehe vaatamiseks logige sisse",
  Login: "Logi sisse",
  "There was some error while doing the action!":
    "Toimingu tegemisel oli viga!",
  "Unable to delete Channel!": "Ei saa jaotist kustutada!",
};
