function ErrorDiv({ error = "", textCenter = false }) {
  return (
    <div
      className={
        "theme-text-danger text-sm " + (textCenter ? "text-center" : "")
      }>
      {error}
    </div>
  );
}

export { ErrorDiv };
