import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withToast } from "../../../contexts/toastr.context";
import { useAppDispatch } from "../../../hooks/redux.hook";
import { redirectToAuthUrl } from "../../../_config/helper.config";
import {
  getSectionPinnedPosts,
  getSectionPosts,
  resetNewPostCount,
  setCurrentSectionPosts,
} from "../../../_store/sections.store";
import { Fold } from "../../../_utils/extensions/typescript-utils";
import Validator from "../../../_utils/validator";
import I18 from "../../atoms/i18";
import TextButton from "../../atoms/text-button";
import JoinGroupView from "./component/join-group-view.component";
import JoinSectionView from "./component/join-section-view.component";
import { SectionInfo } from "./component/section-info.component";
import SectionNewPostMarker from "./component/section-new-post-marker.component";
import SectionPostList from "./component/section-post-list";
import StartDiscussionViewComponent from "./component/start-discussion-view.component";

function SectionDetailComponent({
  user = null,
  group = null,
  community,
  setGroup = (e) => {},
  activeTabModel = null,
  showShareModal = false,
  setShowShareModal = (e) => {},
  addToast = (e) => {},
  updateActiveGroup = (e) => {},
  // state
  section,
  // dispatch
  setPosts,
  getSectionPinnedPosts,
  resetNewPostCount,
}) {
  // pinned posts related
  const {
    pinnedPosts,
    posts,
    currentPage,
    isLoadingPosts,
    isLoadingMorePosts,
    noMorePosts,
    newPostCount,
  } = section;

  const callbackRef = useRef();
  const dispatch = useAppDispatch();

  // get all the section posts paginated
  useEffect(() => {
    if (activeTabModel && activeTabModel.id) {
      if (
        (group.groupType === "open" || group.joinStatus === "joined") &&
        (!activeTabModel.isClosed || activeTabModel.isJoined)
      ) {
        dispatch(getSectionPosts(group.id, activeTabModel.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, group?.id, activeTabModel?.id]);

  // get all the pinned posts
  const groupId = group?.id;
  const activeTabId = activeTabModel?.id;
  const hasSectionAccess =
    (group.groupType === "open" || group.joinStatus === "joined") &&
    (!activeTabModel.isClosed || activeTabModel.isJoined);
  useEffect(() => {
    if (activeTabId && hasSectionAccess) {
      getSectionPinnedPosts(groupId, activeTabId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, hasSectionAccess, activeTabId]);

  let isUserAllowedToPost =
    group.joinStatus === "joined" &&
    (activeTabModel
      ? activeTabModel.isClosed && !activeTabModel.isJoined
        ? false
        : activeTabModel.postLevel === "admin"
        ? group.myRole === "admin" || group.myRole === "moderator"
          ? true
          : false
        : true
      : true);

  // post lazy loading
  useEffect(() => {
    // create callback
    const callBack = () => {
      if (
        window.innerHeight + window.scrollY + 100 >=
        document.body.offsetHeight
      ) {
        // you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMorePosts && !noMorePosts) {
          // if we are not already loading more posts, load more posts
          if (activeTabModel && activeTabModel.id) {
            dispatch(
              getSectionPosts(group.id, activeTabModel.id, currentPage + 1)
            );
          }
        }
      }
    };

    callbackRef.current = callBack;

    // load posts only if group open or joined by user
    if (
      (group.groupType === "open" || group.joinStatus === "joined") &&
      (!activeTabModel.isClosed || activeTabModel.isJoined)
    ) {
      window.addEventListener("scroll", callBack);

      return () => {
        window.removeEventListener("scroll", callBack);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingMorePosts,
    user,
    currentPage,
    posts,
    group,
    noMorePosts,
    activeTabModel,
  ]);

  return (
    <main className="SectionDetail">
      <SectionInfo
        group={group}
        setGroup={setGroup}
        user={user}
        sectionId={activeTabModel.id}
        showShareModal={showShareModal}
        setShowShareModal={setShowShareModal}
        addPost={(post) => {
          if (post) {
            // update posts list with new post
            setPosts([post, ...posts]);
          }
        }}
        updateActiveGroup={updateActiveGroup}
      />

      <SectionDetailWrapper className="SectionDetail">
        {/* New Post available nudge */}
        <SectionNewPostMarker
          newPostCount={newPostCount}
          onClick={() => {
            window.removeEventListener("scroll", callbackRef.current);
            // timeout to scroll first
            setTimeout(() => {
              // scroll to top
              window.scrollTo({
                top: 0,
              });
              // when at 0, not call the refresh apis
              setTimeout(() => {
                // reload posts
                getSectionPinnedPosts(groupId, activeTabId);
                getSectionPosts(group.id, activeTabModel.id);
                // reset new posts
                resetNewPostCount(activeTabModel.id);
              }, [500]);
            }, [500]);
          }}
        />
        <Fold
          value={isLoadingPosts || Validator.hasValue(posts)}
          // When there are posts available
          ifPresent={() => (
            <SectionPostList
              user={user}
              activeTabModel={activeTabModel}
              pinnedPosts={pinnedPosts}
              posts={posts}
              isLoadingPosts={isLoadingPosts}
              isLoadingMorePosts={isLoadingMorePosts}
              noMorePosts={noMorePosts}
            />
          )}
          // When there are no posts in the section
          ifAbsent={() =>
            !user ? (
              // If user is not logged in
              // For closed groups, show login button
              ["closed-free", "closed-paid"].includes(group.groupType) ? (
                <>
                  <div className="flex flex-col items-center gap-6 mt-6">
                    <div className="font-bold">
                      <I18>Login to view this section</I18>
                    </div>

                    <TextButton
                      label="Login"
                      onClick={() => {
                        redirectToAuthUrl(community);
                      }}
                    />
                  </div>{" "}
                </>
              ) : (
                // For open groups, show join button
                <JoinGroupView user={user} group={group} setGroup={setGroup} />
              )
            ) : (
              // If user is logged in
              <>
                {/* Display message when no post available */}
                {activeTabModel.isClosed && !activeTabModel.isJoined ? (
                  //If section is closed && user is not joined
                  <JoinSectionView
                    user={user}
                    group={group}
                    setGroup={setGroup}
                    activeTabModel={activeTabModel}
                  />
                ) : isUserAllowedToPost ? (
                  <StartDiscussionViewComponent
                    isUserAllowedToPost={isUserAllowedToPost}
                    user={user}
                    group={group}
                    sectionId={activeTabModel.id}
                    posts={posts}
                    setShowShareModal={setShowShareModal}
                  />
                ) : (
                  <JoinGroupView
                    user={user}
                    group={group}
                    setGroup={setGroup}
                  />
                )}
              </>
            )
          }
        />
      </SectionDetailWrapper>
    </main>
  );
}

const SectionDetailWrapper = styled.section`
  position: relative;
`;

const stp = (s) => ({
  section: s.sections.activeSectionId
    ? s.sections.sections[s.sections.activeSectionId]
    : null,
  community: s.community,
});

const dtp = {
  getSectionPinnedPosts,
  setPosts: setCurrentSectionPosts,
  resetNewPostCount,
};

const SectionDetail = connect(stp, dtp)(withToast(SectionDetailComponent));

/**
 * @description - To display the section detail view
 */
export default SectionDetail;
