import React from "react";
import FileUtil from "../../_utils/file-util";
import Validator from "../../_utils/validator";
interface Props {
  accept?: "image/*" | "video/*" | "audio/*" | "application/*" | "*";
  onChange?: (_: FileList | File | null) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  maxFiles?: number;
  /**
   * Maximum file size in MB to select file
   */
  maxFileSize?: number;
}
/**
 * Component to display input to select files
 * @param {string} accept - type of file to select
 * @param {any} onChange - function to set file
 * @param {any} children - component to display to select file
 * @param {boolean} disabled - disable input
 * @param {boolean} multiple - allow multiple files
 * @param {number} maxFiles - maximum number of files to select
 * @param {number} maxFileSize - maximum file size to select
 * @param {string} className - class name to add to component
 */
export function FileInput({
  accept = "*",
  onChange = (_: FileList | File | null) => {},
  children,
  className = "",
  disabled = false,
  multiple = false,
  maxFiles,
  maxFileSize,
}: Props) {
  const pictureRef = React.useRef<HTMLInputElement>(null);
  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e || e === null || !e.target || !e.target.files) return;

    e.stopPropagation();
    e.preventDefault();

    if (e.target.files.length > 0) {
      const maxFileSelectionPassed = checkMaxFileSelection(); // Restrict number of files to select
      if (!maxFileSelectionPassed) return;

      const sizeValidationPassed = checkSizeValidation(); // Restrict file size
      if (!sizeValidationPassed) return;

      // Check if multiple files are allowed
      if (multiple) {
        onChange(e.target.files);
      } else {
        onChange(e.target.files[0]);
      }
    } else {
      onChange(null);
    }
  };

  /**
   * Check if file size is less than maxFileSize
   */
  function checkSizeValidation(): boolean {
    if (!maxFileSize) {
      return true;
    }
    const files = pictureRef.current?.files;
    if (Validator.hasValue(files)) {
      for (let i = 0; i < files!.length; i++) {
        if (FileUtil.sizeGreaterThan(files![i], maxFileSize)) {
          alert(`File size should be less than ${maxFileSize} Mb`);
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Check for max file selection
   */
  function checkMaxFileSelection(): boolean {
    if (!maxFiles) {
      return true;
    }
    const files = pictureRef.current?.files;
    if (Validator.hasValue(files)) {
      if (files!.length > maxFiles) {
        alert(`You can only select ${maxFiles} files`);
        return false;
      }
    }
    return true;
  }

  return (
    <div
      className={"FileSelect cursor-pointer" + className}
      onClick={() => {
        if (!disabled) {
          pictureRef.current?.click();
        }
      }}>
      <input
        className="hidden"
        accept={accept}
        disabled={disabled}
        type="file"
        ref={pictureRef}
        multiple={multiple}
        onChange={(e) => {
          onFileSelect(e);
        }}
      />
      {children}
    </div>
  );
}
