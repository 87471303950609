function SidebarGroupsListShimmerComponent() {
  return (
    <div className="pt-0  animate-pulse  ">
      <GroupTileShimmer />
      <GroupTileShimmer />
      <GroupTileShimmer />
    </div>
  );
}

function GroupTileShimmer() {
  const list = [];
  for (let i = 0; i < 3 ?? 1; i++) {
    list.push(
      <div key={i} className="w-5/6 h-4 bg-gray-300 rounded mt-2 ml-2" />
    );
  }
  return (
    <div className="container flex-grow flex flex-col pt-0  animate-pulse py-2 mb-2">
      <div className=" w-3/6 h-6 bg-gray-300 rounded" />
      {list}
    </div>
  );
}

export default SidebarGroupsListShimmerComponent;
