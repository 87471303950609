import cx from "classnames";
import { cn } from "../../button.component";
import UIcon from "../../uicon-component";

function UserTileShimmer({ className }: { className?: string }) {
  return (
    <div
      className={cn(cx("UserTileShimmer flex my-3 items-center", className))}>
      <p className="w-8 h-8 bg-gray-300 rounded-full " />
      <div className="w-max ml-2 space-y-1 flex-grow">
        <div className=" w-8/12 h-2 mt-2  bg-gray-300 rounded" />
        <p className="w-32 h-2 bg-gray-300 rounded"></p>
      </div>
      <UIcon icon="menu-dots-vertical" className="theme-text-subtitle-2" />
    </div>
  );
}

export default UserTileShimmer;
