import axios from "axios";
import { endpoints } from "../_config";

class WidgetService {
  /**
   * get group widgets
   * @param {String} communityId
   * @param {String} groupId
   * @returns
   */
  static async getWidgets(communityId, groupId) {
    return axios
      .get(endpoints.widget.getGroupWidget(communityId, groupId))
      .then((response) => response.data);
  }

  /**
   * create custom widget for a group
   * @param {*} user
   * @param {String} communityId
   * @param {Object} widget
   * @returns
   */
  static async createCustomWidget(user, communityId, widget) {
    return axios
      .post(endpoints.widget.createCustomWidget(communityId), widget, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
  /**
   * create custom widget for a group
   * @param {*} user
   * @param {String} communityId
   * @param {String} widgetId
   * @param {Blob} file
   * @returns
   */
  static async uploadCustomCustomWidgetPicture(
    user,
    communityId,
    widgetId,
    file
  ) {
    const formData = new FormData();
    formData.append("picture", file);
    return axios
      .post(
        endpoints.widget.uploadCustomCustomWidgetPicture(communityId, widgetId),
        formData,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * Update sort order of widgets for a group
   * @param {*} user
   * @param {String} communityId
   * @param {Object} sortOrder: update widgets visibility along with sort order
   * @returns
   */
  static async updateSortOrder(user, communityId, sortOrder) {
    return axios
      .post(endpoints.widget.updateSortOrder(communityId), sortOrder, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
  /**
   * Update sort order of widgets for a group
   * @param {*} user
   * @param {String} communityId
   * @param {String} widgetId
   * @param {Object} widget
   * @returns
   */
  static async updateWidget(user, communityId, widgetId, widget) {
    return axios
      .post(endpoints.widget.updateWidget(communityId, widgetId), widget, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update sort order of widgets for a group
   * @param {*} user
   * @param {String} communityId
   * @param {String} widgetId
   * @returns
   */
  static async deleteWidget(user, communityId, widgetId) {
    return axios
      .delete(endpoints.widget.updateWidget(communityId, widgetId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
}

export { WidgetService };
