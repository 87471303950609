import { GroupService } from "../../_service";

export const SET_PERSONAL_GROUP = "SET_PERSONAL_GROUP";
/**
 * Set the groups list
 * @param {*} user
 * @returns
 */
export function setPersonalGroup(group = {}) {
  return {
    type: SET_PERSONAL_GROUP,
    group,
  };
}

// thunk related

/**
 * Get personal group
 */
export function getPersonalGroup() {
  return (dispatch, getState) => {
    // get the user
    const { auth: user } = getState();
    // get all the groups
    GroupService.getPersonalGroup(user)
      .then(({ group }) => {
        dispatch(setPersonalGroup(group));
      })
      .catch((err) => {
        // TODO: show error
      });
  };
}
