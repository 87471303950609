import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class RealtimeSectionPinnedMessageService {
  /**
   * @params messageId - The ID of the message to be pinned.
   */
  static async pinRealtimeSectionMessage(messageId: string) {
    try {
      const response = await axios.post(
        endpoints.message.pinned(messageId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  }

  /**
   * @params messageId - The ID of the message to be unpinned.
   */
  static async unpinRealtimeSectionMessage(messageId: string) {
    try {
      const response = await axios.delete(endpoints.message.pinned(messageId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      });
      return response.data;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  }

  /**
   * @params groupId - The ID of the group.
   * @params sectionId - The ID of the section.
   */
  static async getPinnedSectionMessages(groupId: string, sectionId: string) {
    try {
      const response = await axios.get(
        endpoints.message.getPinnedMessage(groupId, sectionId),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  }
}

export { RealtimeSectionPinnedMessageService };
