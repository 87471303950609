import { ActivityCommentCard } from "./activity/activity-comment-card.component";
import I18 from "./atoms/i18";
import { ListView } from "./molecule/listview.component";
import { PostCard } from "./post/post-card.component";
import PostListShimmer from "./shimmers/post/post-list-shimmer.component";

export function ActivityCardList({
  isLoadingPosts,
  activities,
  updatePost,
  isLoadingMorePosts,
}) {
  return (
    <ListView
      items={activities}
      loading={isLoadingPosts}
      renderItem={(post) => {
        return post.activityType === "post-create" ? (
          post.post ? (
            <PostCard
              post={post.post}
              key={post.id}
              updatePost={(updatedPost) => {
                if (updatedPost) {
                  const newPost = {
                    ...post,
                    post: updatedPost,
                  };
                  updatePost(newPost);
                }
              }}
              showFullPost={activities.length === 1}
              deletePost={(deletedPost) => {
                if (deletedPost) {
                  const newPost = {
                    ...post,
                    post: undefined,
                  };
                  updatePost(newPost);
                }
              }}
            />
          ) : (
            <></>
          )
        ) : (
          <ActivityCommentCard
            activityType={post.activityType}
            post={post.post}
            comment={post.comment}
            reply={post.reply}
            key={post.id}
          />
        );
      }}
      noItemsElement={
        <div className="no-post p-2 my-10 text-center">
          <div className="font-bold my-3">
            <I18>Looks like it's lonely here.</I18>
          </div>
          <div>
            <I18>No activity to show.</I18>
          </div>
        </div>
      }
      placeholder={
        <div className="theme-bg-surface mt-2 rounded">
          <PostListShimmer length={7} />
        </div>
      }
      footerElement={
        <>
          {isLoadingMorePosts && (
            <div className="theme-bg-surface mt-2 rounded">
              <PostListShimmer length={2} />
            </div>
          )}
        </>
      }
    />
  );
}
