import { ToastTypes } from "../../../contexts/toastr.context";
import { Button } from "../../../_components";
import { CommunityService } from "../../../_service";

export function RedeemButton({
  user,
  code,
  community,
  setIsLoading = (e) => {},
  setCommunity = (e) => {},
  addToast = (e) => {},
}) {
  return (
    <Button
      className="mt-2"
      label="Redeem"
      outlined
      onClick={(e) => {
        // try redeeming this deal
        setIsLoading(true);
        CommunityService.redeemDeal(user, community, code)
          .then(({ community }) => {
            // TODO: enhance setCommunity()
            // reload for now
            window.location.reload();
          })
          .catch((err) => {
            addToast(
              "Could not redeem deal!",
              "Please try again after refreshing or contact pensil support!",
              ToastTypes.danger
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    />
  );
}
