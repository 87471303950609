import { useMemo } from "react";
import { MySubscription } from "../../types/subscription/my-subscription-plan";
import Label from "../../_components/typography/label.typography";
import { SubscriptionUtils } from "../../_utils/subscription/subscription-util";
import { CurrencySymbol } from "../user-management-settings/subscriptions/component/subscription_tile.component";

interface Props {
  subscription?: MySubscription;
}
/**
 * Displays the user's membership card on the user's profile if the user have any subscription
 */

export default function UserMembershipCard({ subscription }: Props) {
  if (!subscription || !subscription.subscriptionPlan) {
    return;
  }
  const totalAmount = SubscriptionUtils.calculateSubscriptionTotal(
    subscription.subscriptionPlan
  );

  const currency =
    CurrencySymbol[
      subscription.subscriptionPlan.currency as keyof typeof CurrencySymbol
    ] || subscription.subscriptionPlan.currency;

  const isRecurring = subscription.subscriptionPlan.isRecurring;
  const purchasedDate = subscription.subscriptionPlan.createdAt;
  const interval = subscription.subscriptionPlan.recurringInterval;
  // Find the subscription renewal date
  var renewalDate = new Date(purchasedDate);
  if (isRecurring && interval) {
    renewalDate.setMonth(renewalDate.getMonth() + interval);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const locale = useMemo(() => getLang(), []) ?? "en-US";

  function getLang() {
    if (navigator.languages !== undefined) return navigator.languages[0];
    return navigator.language;
  }

  return (
    <div className="relative">
      <div className="absolute top-0 border rounded theme-bg-surface w-full text-sm">
        <div className="border-b p-4 ">
          <Label variant="t1" translate>
            CURRENT MEMBERSHIP
          </Label>
        </div>
        <div className="flex flex-col p-4">
          <Label size="body1" variant="t1">
            {subscription.subscriptionPlan.name}
          </Label>

          <div
            className="NL2BR headings"
            dangerouslySetInnerHTML={{
              __html: subscription.subscriptionPlan.description,
            }}
          />
          <Label variant="t1" size="body1" className="mt-5 ">
            {totalAmount > 0 && `${currency} ${totalAmount} per month`}
            {subscription.subscriptionPlan.isRecurring &&
              ` ${currency} per ${subscription.subscriptionPlan.recurringInterval}` +
                " month"}
          </Label>
          {subscription.subscriptionPlan.isRecurring && (
            <>
              renew on{" "}
              {renewalDate.toLocaleDateString(locale, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
