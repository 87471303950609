import mixpanel from "mixpanel-browser";

const MixPanelCommunities = {
  "hopes.pensil.so": "1e931974dd683091fe7762831cbbf918",
  "webveda.pensil.in": "48a22588b57fa763c042e8c79f37c6dc",
  "epicrew.pensil.in": "6b7a489a26de8e5cf0fa41ff9e15e448",
  "community.webveda.com": "48a22588b57fa763c042e8c79f37c6dc",
  "theundivided.pensil.in": "7b3322b847fa7aed8f50d95bd82fca3d",
  "design.toprankers.com": "52c2a4036b0163d2e15df1be334eb65f",
  "management.toprankers.com": "52c2a4036b0163d2e15df1be334eb65f",
  "cuet.toprankers.com": "52c2a4036b0163d2e15df1be334eb65f",
  "udaan.toprankers.com": "52c2a4036b0163d2e15df1be334eb65f",
  "law.toprankers.com": "52c2a4036b0163d2e15df1be334eb65f",
  "www.cuet.com": "52c2a4036b0163d2e15df1be334eb65f",
  "fyndacademy.pensil.in": "d76a235fae6cbe13a8691df369ba67b7",
  "theforexfunda.pensil.in": "efb89ec238d18ec50bdfb9eae1c85646",
};
const communityAddress = window.location.hostname;
var id = MixPanelCommunities[communityAddress];

if (!id) {
  id = process.env.REACT_APP_MIXPANEL_TOKEN;
}

mixpanel.init(id);

let envCheck = process.env.NODE_ENV === "production";

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (eventName, props, options = {}, callback = (err) => {}) => {
    return mixpanel.track(
      (envCheck ? "WEBAPP" : "WEBAPP_DEV") + "." + eventName,
      props,
      options,
      callback
    );
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  reset: () => {
    mixpanel.reset();
  },
};

export let mixPanel = actions;
