import cx from "classnames";
import React from "react";
import { cn } from "../button.component";
interface Props {
  className?: string;
  dropdownClassName?: string;
  button: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  alignment?: "left-1" | "right-1";
}
/**
 * custom Drop down component
 * @param {*} button - button component
 * @param {any} children - children component to be displayed in drop down
 * @param {string} className - The class name of the drop down
 * @param {boolean} disabled - Boolean to determine if the drop down is disabled
 * @param {string} alignment - The alignment of the dropdown
 */
export default function CustomDropDown({
  button = <h3>Drop Down</h3>,
  className = "",
  dropdownClassName = "",
  children = <></>,
  disabled = false,
  alignment = "right-1",
}: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div
      ref={ref}
      tabIndex={0}
      className={cn(`relative text-left customDropdown z-10 ${className}`)}>
      <button
        className={cx(
          "inline-flex items-center space-x-1 justify-between w-full text-sm font-medium leading-5 transition duration-150 ease-in-out  rounded-md ",
          {
            "cursor-not-allowed theme-bg-disable theme-text-subtitle-1":
              disabled,
            "hover:theme-text-heading-2 focus:outline-none focus:theme-border-primary focus:shadow-outline-blue theme-text-heading-1":
              !disabled,
          }
        )}
        type="button"
        disabled={disabled}
        aria-haspopup="true"
        aria-expanded="true">
        {button}
      </button>

      <div
        className={cx(
          "opacity-0 invisible dropdown-menu transition-all duration-300 transform -translate-y-2 scale-95",
          {
            "origin-top-right top-0 right-0": alignment === "right-1",
            "origin-top-left top-0 left-0": alignment === "left-1",
          }
        )}>
        <div
          className={cn(
            cx(
              "absolute w-max mt-2  origin-top-right theme-bg-surface border theme-border-default divide-y  divide-[var(--theme-border-color)] theme-border-default rounded-md shadow-lg outline-none z-50",
              {
                "right-1": alignment === "right-1",
                "left-1": alignment === "left-1",
              }
            ),
            dropdownClassName
          )}
          onClick={() => {
            ref.current?.blur();
          }}
          role="menu">
          {children}
        </div>
      </div>
    </div>
  );
}
