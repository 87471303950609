import { InvoicePreference } from "../../types/invoice-preference/invoice-preference.type";
import { PaymentPreference } from "../../types/payment-preference/payment-preference.type";
import {
  SET_INVOICE_PREFERENCE,
  SET_INVOICE_PREFERENCE_LOADING_STATUS,
  SET_PAYMENT_PREFERENCE,
  SET_PAYMENT_PREFERENCE_LOADING_STATUS,
} from "./preference.action";

export type Status = "idle" | "loading" | "success" | "error";
export type PreferenceType = {
  paymentPreference: {
    preference: PaymentPreference;
    status: Status;
  };
  invoicePreference: {
    preference: InvoicePreference;
    status: Status;
  };
};
const defaultState = {
  paymentPreference: {
    preference: {
      integrationStatus: "not-set",
      bankAccount: {
        bankAccountNumber: undefined,
        beneficiaryName: undefined,
        ifscCode: undefined,
        businessName: undefined,
        businessType: undefined,
      },
      paymentMethod: "not-set",
      razorpay: {
        razorpayId: undefined,
      },
    } as PaymentPreference,
    status: "idle" as Status,
  },
  invoicePreference: {
    preference: {
      integrationStatus: "not-set",
    },
    status: "idle" as Status,
  },
} as PreferenceType;

/**
 * Payment Preference Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function preferenceReducer(
  state = defaultState,
  action: any
): PreferenceType {
  switch (action.type) {
    case SET_PAYMENT_PREFERENCE:
      return {
        ...state,
        paymentPreference: {
          preference: action.paymentPreference,
          status: action.status ?? state.paymentPreference.status,
        },
      };
    case SET_PAYMENT_PREFERENCE_LOADING_STATUS:
      return {
        ...state,
        paymentPreference: {
          preference: state.paymentPreference.preference,
          status: action.status,
        },
      };
    case SET_INVOICE_PREFERENCE:
      return {
        ...state,
        invoicePreference: {
          preference: action.invoicePreference,
          status: action.status ?? state.invoicePreference.status,
        },
      };
    case SET_INVOICE_PREFERENCE_LOADING_STATUS:
      return {
        ...state,
        invoicePreference: {
          preference: state.invoicePreference.preference,
          status: action.status,
        },
      };
    default:
      return state;
  }
}
