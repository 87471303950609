import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
import { CommunityService } from "../../_service";
import I18 from "../atoms/i18";
import { Avatar } from "../avatar.component";
import IconButton2 from "../icon-button-2-component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";
import UIcon from "../uicon-component";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

/**
 * Widget to display community subscribers list.
 * @param {Object} community community object.
 * @param {Object} user current active user profile data.
 * @param {int} memberPreviewLimit No. of members to be displayed.
 */
export default function MemberWidget({
  community,
  user,
  memberPreviewLimit = 4,
}) {
  // load the subscriber list once, or per community or group change

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [members, setMembers] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [subscribersCount, setSubscribersCount] = useState(0);
  const [isSubscriberListVisible, setIsSubscriberListVisible] = useState(false);
  const [isLoadingMoreSubscribers, setIsLoadingMoreSubscribers] =
    useState(false);

  const [noMoreSubscribers, setNoMoreSubscribers] = useState(false);
  const [page, setPage] = useState(1);

  const innerRef = useRef();
  useEffect(() => {
    setIsLoading(true);
    CommunityService.getCommunitySubscribersPaginated(
      user,
      community.id,
      10,
      1,
      query,
      ""
    )
      .then(({ users, total }) => {
        setSubscribers(users);
        setMembers(users);
        setSubscribersCount(total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error({ error });
        setIsLoading(false);
      });
  }, [user, community.id]);

  const handleSearchDebounced = React.useCallback(
    debounce((query) => {
      if (["admin"].includes(community.myRole)) {
        setIsLoading(true);
        CommunityService.getCommunitySubscribersPaginated(
          user,
          community.id,
          10,
          1,
          query,
          ""
        )
          .then(({ users, total }) => {
            setSubscribers(users);
            setSubscribersCount(total);
            setIsLoading(false);
            setNoMoreSubscribers(false);
          })
          .catch((error) => {
            console.error({ error });
            setIsLoading(false);
          });
      }
    }, 1000),
    [community.myRole]
  );

  const onScroll = React.useCallback(() => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // subscribers list lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreSubscribers && !noMoreSubscribers) {
          const pageCount = page + 1;
          // if we are not already loading more subscribers, load more subscribers
          setIsLoadingMoreSubscribers(true);
          CommunityService.getCommunitySubscribersPaginated(
            user,
            community.id,
            10,
            pageCount,
            query,
            ""
          )
            .then((response) => {
              if (response.users.length === 0) {
                setNoMoreSubscribers(true);
              } else {
                setSubscribers([...subscribers, ...response.users]);
                setPage(pageCount);
              }
              setIsLoadingMoreSubscribers(false);
            })
            .catch((error) => {
              console.error({ error });
              setIsLoading(false);
            });
        }
      }
    }
  }, [isLoadingMoreSubscribers, noMoreSubscribers, page, query, subscribers]);

  const subscribersList = subscribers;

  return (
    <>
      <div className="theme-bg-surface mt-3 rounded">
        <h4 className="font-bold px-4 py-2 border-b theme-border-default select-none cursor-pointer">
          <I18>Community Members</I18>
        </h4>
        {/* members section */}
        <div className="p-4 pb-4">
          {/* members list */}
          <div className="GroupMemberList">
            {members.slice(0, memberPreviewLimit).map((member) => (
              <div
                className="group-member-list-item my-3 flex justify-between items-center cursor-pointer"
                key={member.id}>
                <Avatar
                  user={member}
                  onClick={() => {
                    // open the user page
                    window.open(getSegmentedPath(createUserWallPageRoute(member.id, "activity")));
                  }}
                  tag={
                    member.groupRole === "admin" ? (
                      <div className="theme-theme-bg-primary-light theme-text-primary mx-2 font-semibold text-xxs px-1 rounded">
                        <I18>Admin</I18>
                      </div>
                    ) : member.groupRole === "moderator" ? (
                      <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                        <I18>Moderator</I18>
                      </div>
                    ) : member.groupRole === "user" ? (
                      <></>
                    ) : (
                      <></>
                    )
                  }
                  extraInfo={undefined}
                />
              </div>
            ))}
          </div>
          {/* show all link */}

          <div className="my-2">
            <span
              className="theme-text-primary text-sm cursor-pointer"
              onClick={(e) => {
                setIsSubscriberListVisible(true);
              }}>
              <I18>Show all</I18>
            </span>
          </div>

          {/* show more members model */}
          <Modal
            className="theme-bg-surface"
            setActive={setIsSubscriberListVisible}
            active={isSubscriberListVisible}
            padding={false}>
            {/* headers */}
            <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
              <span className="font-bold theme-text-heading-1">
                {community.name} - {subscribersCount} <I18>Subscribers</I18>
              </span>
              <IconButton2
                hoverable={true}
                icon="cross"
                size="xs"
                iconClass="theme-text-subtitle-2 text-xs"
                onClick={() => {
                  setIsSubscriberListVisible(false);
                }}
              />
            </div>
            {/* body */}
            <div className="p-5 theme-text-heading-1 pb-10 theme-bg-default">
              {/* search bar */}
              <div className="flex theme-bg-surface items-center border theme-border-default theme-bg-default px-4 py-1 rounded mb-4">
                <UIcon
                  icon="search"
                  className="theme-text-subtitle-2 text-xs"
                />
                <input
                  type="text"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => {
                    setIsLoading(true);
                    setQuery(e.target.value);
                    handleSearchDebounced(e.target.value);
                  }}
                  className="flex-grow  bg-transparent py-1 px-4 focus:outline-none"
                />
                {query.length > 0 ? (
                  <i
                    onClick={() => {
                      setIsLoading(true);
                      setQuery("");
                      handleSearchDebounced("");
                    }}
                    className="fi fi-rr-cross theme-text-heading-2 text-sm pr-1 cursor-pointer"
                  />
                ) : null}
              </div>
              {/* subscribers list */}
              {isLoading ? (
                <div className="p-1">
                  <Loader />
                </div>
              ) : (
                <></>
              )}
              <div
                onScroll={onScroll}
                ref={innerRef}
                className="overflow-y-scroll h-96">
                {subscribersList.map((member) => (
                  <div
                    className="m-3 flex justify-between items-center cursor-pointer"
                    key={member.id}>
                    <div className="flex items-center justify-center">
                      <Avatar
                        user={member}
                        onClick={() => {
                          // open the user page
                          window.open(
                            getSegmentedPath(createUserWallPageRoute(member.id, "activity"))
                          );
                        }}
                        tag={
                          <>
                            {member.communityRole === "admin" ? (
                              <div className="bg-blue-50 mx-2 font-semibold theme-text-primary text-xxs px-1 rounded">
                                <I18>Community Admin</I18>
                              </div>
                            ) : (
                              <></>
                            )}
                            {member.groupRole === "admin" ? (
                              <div className="bg-blue-50 mx-2 font-semibold theme-text-primary text-xxs px-1 rounded">
                                <I18>Admin</I18>
                              </div>
                            ) : member.groupRole === "moderator" ? (
                              <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                                <I18>Moderator</I18>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
              {isLoadingMoreSubscribers ? (
                <div className="p-1">
                  <Loader />
                </div>
              ) : null}
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
