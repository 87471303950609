import cx from "classnames";
import React, { Suspense, useEffect, useState } from "react";
import { Remarkable } from "remarkable";
import TurndownService from "turndown";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Button, Loader } from "../../../../_components";
import { CloseButton } from "../../../../_components/activity/close-button.component";
import I18 from "../../../../_components/atoms/i18";
import TextButton from "../../../../_components/atoms/text-button";
import { FileInput } from "../../../../_components/form-controls/file-input";
import ModalForm from "../../../../_components/model2";
import AlertStrip from "../../../../_components/molecule/alert-strips";
import Label from "../../../../_components/typography/label.typography";
import UIcon from "../../../../_components/uicon-component";
import UploadProgressBar from "../../../../_components/upload-progress-bar.component";
import { CourseService } from "../../../../_service";
import Validator from "../../../../_utils/validator";
import AddLessonContentComponent from "../../course-detail/course-add-section/add-Lesson-content.component";
import { LessonState } from "../course-view.page";
const RichTextEditor = React.lazy(() => import("../../../../_components/form-controls/rich-text-editor.component"));
const tds = new TurndownService();

/**
 * Render lesson content
 * @param {*} param0
 * @returns
 */

export function LessonSummaryEdit({
  community,
  course,
  section,
  lesson,
  updateSection,
  isLoading,
}) {
  const { addToast, ToastTypes } = useToast();
  const [IsSavingLesson, setIsSavingLesson] = useState(false);
  const [IsPublishing, setIsPublishing] = useState(false);
  const [coverMediaUploadPercent, setCoverMediaUpload] = useState(0);
  const [embedded, setEmbedded] = useState();
  const [embeddedUrl, setEmbeddedUrl] = useState();
  const [content, setContent] = useState();
  const [createContent, setCreateContent] = useState();
  const [contentUrl, setContentUrl] = useState();
  const [description, setDescription] = useState("");
  const isPublished = lesson?.state === LessonState.published;
  /** Initialize contentUrl and description */
  useEffect(() => {
    if (lesson.coverMedia) {
      setContentUrl(lesson.coverMedia);
      setEmbeddedUrl(lesson.coverMedia);
    } else {
      setContentUrl(null);
      setEmbeddedUrl(null);
    }

    const md = new Remarkable();
    setDescription(md.render(lesson.description));
    setContent(null);
    setEmbedded(null);
  }, [lesson]);

  function getPlainDescription(description) {
    if (!description) return "";
    const embeddedDescription = description.replace(
      /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
      " $1 "
    );

    return tds.turndown(embeddedDescription);
  }

  //  UPDATE LESSON DESCRIPTION
  const updateLessonDescription = (description) => {
    const plainDescription = getPlainDescription(description);
    setIsSavingLesson(true);
    const Payload = {
      ...lesson,
      description: plainDescription,
    };
    CourseService.createLesson(
      community.id,
      course.id,
      section.id,
      Payload,
      lesson.id
    )
      .then(async ({ lesson }) => {
        await uploadCoverMedia(lesson, (lesson) => {
          updateLesson(lesson);
          addToast(
            "Success",
            "Lesson updated successfully!",
            ToastTypes.success
          );
        });
      })
      .catch((error) => {
        console.error(error);
        addToast("Error", "Lesson could not be updated!", ToastTypes.danger);
      })
      .then(() => {
        setIsSavingLesson(false);
      });
  };

  // Upload cover media.
  async function uploadCoverMedia(lesson, updateLesson = (e) => {}) {
    // Check if file is video file
    if (content) {
      // Upload cover media
      CourseService.uploadLessonCoverMedia(
        community.id,
        course.id,
        section.id,
        lesson.id,
        content,
        (progress) => {
          setCoverMediaUpload(progress);
        }
      )
        .then(({ lesson }) => {
          updateLesson(lesson);
        })
        .catch((error) => {
          console.error(error);
          addToast(
            "Error",
            "Lesson cover media could not be uploaded!",
            ToastTypes.danger
          );
        })
        .then(() => {
          setCoverMediaUpload(0);
        });
    } else if (lesson.coverMedia && contentUrl == null) {
      // Delete cover media
      CourseService.uploadLessonCoverMedia(
        community.id,
        course.id,
        section.id,
        lesson.id,
        undefined,
        (progress) => {
          setCoverMediaUpload(progress);
        }
      )
        .then(({ lesson }) => {
          updateLesson(lesson);
        })
        .catch((error) => {
          console.error(error);
          addToast(
            "Error",
            "Lesson cover media could not be uploaded!",
            ToastTypes.danger
          );
        })
        .then(() => {
          setCoverMediaUpload(0);
        });
    } else {
      updateLesson(lesson);
    }
  }

  // Publish lesson
  const publishLesson = (e) => {
    setIsPublishing(true);
    const Payload = {
      ...lesson,
      state: isPublished ? LessonState.draft : LessonState.published,
    };
    CourseService.createLesson(
      community.id,
      course.id,
      section.id,
      Payload,
      lesson.id
    )
      .then(({ lesson }) => {
        updateLesson(lesson);
        addToast(
          "Success",
          "Lesson published successfully!",
          ToastTypes.success
        );
      })
      .catch((error) => {
        console.error(error);
        addToast("Error", "Lesson could not be published!", ToastTypes.danger);
      })
      .then(() => {
        setIsPublishing(false);
      });
  };

  //UPDATE EXISTING LESSON IN SECTION
  function updateLesson(lesson) {
    if (!lesson) {
      console.error("Lesson is not defined");
    } else if (!section) {
      console.error("Section is not defined");
    } else {
      const lessons = section.lessons.map((l) => {
        if (l.id === lesson.id) {
          return lesson;
        }
        return l;
      });
      updateSection({ ...section, lessons });
    }
  }
  if (isLoading) {
    return (
      <div className="flex-col space-y-2 h-full px-4 py-2 theme-bg-surface rounded">
        <Loader />
      </div>
    );
  } else if (!Validator.hasValue(lesson)) {
    return (
      <>
        <AlertStrip
          error
          title="No lesson found"
          message="Please select a lesson from the left panel."
          className="theme-bg-surface"
        />
      </>
    );
  }

  return (
    <div className="flex flex-col space-y-2 h-full px-4 py-2 theme-bg-surface rounded">
      <div className="flex items-center place-content-between">
        <Label size="h3" variant="t2">
          {lesson.title}
        </Label>

        <div className="flex items-center space-x-2">
          <TextButton
            label="Save"
            isLoading={IsSavingLesson}
            onClick={(e) => {
              updateLessonDescription(description);
            }}
          />
          {!isPublished && (
            <Button
              label="Publish"
              flat
              onClick={publishLesson}
              isLoading={IsPublishing}
            />
          )}
        </div>
      </div>
      {/* <ContentSection
        setContent={setContent}
        setContentUrl={setContentUrl}
        setCreateContent={setCreateContent}
        createContentUrl={createContent}
        setCre
        content={content}
        contentUrl={contentUrl}
        coverMediaUploadPercent={coverMediaUploadPercent}
      />
      {embedded && (
        <EmbeddedSection
          embedded={embedded}
          embeddedUrl={embeddedUrl}
          coverMediaUploadPercent={coverMediaUploadPercent}
        />
      )}

      {content ? (
        <></>
      ) : (
        <AddContentSection
          content={content}
          setContent={setContent}
          setCreateContent={setCreateContent}
        />
      )} */}
      <VideoSection />

      <Suspense fallback={<></>}>
        <RichTextEditor
          className="noBorder  w-full px-2 maxHeightInfinite"
          text={description}
          placeholder="Write lesson details here ..."
          setText={setDescription}
        />
      </Suspense>
    </div>
  );

  function AddContentSection({ setContent, setCreateContent }) {
    const [addContentVisible, setAddContentVisible] = useState(false);
    return (
      <div
        className=" flex-col gap-3 cursor-pointer flex justify-center border-dashed border h-[150px] items-center theme-border-subtitle-2
      theme-text-subtitle-2 rounded">
        <UIcon
          icon="plus"
          className=" theme-bg-primary theme-text-on-primary rounded-full text-xs w-5 h-5 flex items-center justify-center"
        />
        <ModalForm
          title={"Select Content"}
          visible={addContentVisible}
          setVisible={setAddContentVisible}
          className=" max-w-lg w-full"
          button={
            <TextButton
              label="Add content"
              className="outline-1 outline mx-2"
              onClick={() => setAddContentVisible(true)}
            />
          }>
          <AddLessonContentComponent
            setCreateContent={setCreateContent}
            setContent={setContent}
            setEmbedded={setEmbedded}
          />
        </ModalForm>
      </div>
    );
  }

  function VideoSection() {
    return (
      <div className="flex flex-col z-0">
        <div className="w-full">
          {content ? (
            <>
              <div className="flex flex-col items-center justify-center max-h-80 w-full  theme-bg-default rounded">
                {coverMediaUploadPercent && coverMediaUploadPercent > 0 ? (
                  <>
                    {/* Display upload video progress */}
                    <div className="h-64 flex flex-col place-content-center items-center">
                      <p>Uploading video {coverMediaUploadPercent}%</p>
                    </div>
                  </>
                ) : (
                  <>
                    <video
                      className="w-full max-h-80  cursor-pointer"
                      controls
                      src={URL.createObjectURL(content)}
                      controlsList="nodownload"
                    />
                  </>
                )}
              </div>
              <div className="flex items-center place-content-between space-x-1 w-full h-12 mt-2 border px-2 rounded theme-bg-default">
                <div className="flex items-center">
                  {/* Display video in MB up to 1 decimal point */}
                  <div className="flex items-center space-x-2 theme-text-subtitle-1">
                    <UIcon icon="play-alt" className="mt-1" />
                    <p className="word-breaker">{content.name}</p>
                    <p className="border px-1 rounded theme-bg-disable theme-text-subtitle-1 word-breaker">
                      {Math.round((content.size / 1024 / 1024) * 10) / 10} MB
                    </p>
                  </div>
                </div>

                <CloseButton
                  hoverable
                  className="theme-bg-primary-light"
                  type="primary"
                  onClick={(e) => {
                    setContent(null);
                  }}
                />
              </div>
            </>
          ) : contentUrl ? (
            <div className="relative w-full mt-4 group z-0">
              <video
                className="max-h-80 w-full theme-bg-default rounded cursor-pointer"
                controls
                controlsList="nodownload"
                src={contentUrl}
              />

              <div className="absolute -right-4 -top-4 opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-linear">
                <CloseButton
                  hoverable
                  type="primary"
                  className="theme-bg-primary-light"
                  onClick={(e) => {
                    setContentUrl(null);
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <FileInput
                accept="video/*"
                onChange={(video) => {
                  setContent(video);
                }}>
                <div
                  style={{ aspectRatio: 16 / 9 }}
                  className={cx(
                    "flex  items-center place-content-center rounded h-[204px] z-0",
                    "border border-dashed theme-border-subtitle-2",
                    "theme-text-subtitle-2 text-smg",
                    " lg:w-3/5 mx-auto"
                  )}>
                  <I18>Upload video lecture</I18>
                </div>
              </FileInput>
            </>
          )}
        </div>
        <UploadProgressBar percentComplete={coverMediaUploadPercent} />
      </div>
    );
  }
}
