import { SubscriptionPlan } from "../../types/subscription/subscription-plan";
import { SubscriptionService } from "../../_service";

export const SET_SUBSCRIPTION_PLAN = "SET_SUBSCRIPTION_PLAN";

/**
 * Set subscription plans for users
 * @param { Array<any>} subscriptions
 * @returns
 */
export function setSubscriptionPlan(subscriptions: Array<SubscriptionPlan>) {
  return {
    type: SET_SUBSCRIPTION_PLAN,
    subscriptions: subscriptions,
  };
}

/**
 * Get subscription plans for users
 */
export function getSubscriptions() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      SubscriptionService.getAllSubscriptions(community.id)
        .then(({ subscriptions }) => {
          dispatch(setSubscriptionPlan(subscriptions));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
}
