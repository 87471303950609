export const frFrench = {
  "Pensil Keyword": "French",
  "Home Page Left column": "Page d'accueil Colonne de gauche",
  Home: "Maison",
  "Explore Groups": "Explorer les groupes",
  Events: "Événements",
  Groups: "Groupes",
  Links: "Liens",
  "Direct Message": "Message direct",
  "Powered by": "Alimenté par",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Aucun groupe ici, veuillez utiliser + pour en créer un nouveau, ou rejoignez-les, voyez-les ici.",
  "Please join a group to see them here":
    "Veuillez rejoindre un groupe pour les voir ici",
  "Channel can only be reordered within the group!":
    "La Channel ne peut être réorganisée que dans le groupe!",
  "Group Page": "Page de groupe",
  "Invite Members": "Inviter des membres",
  Join: "Rejoindre",
  Joined: "Joint",
  "Create new Channel": "Créer une nouvelle Channel",
  "Group Setting drop down": "Définition du groupe déroulant",
  Share: "Partager",
  "Edit group": "Éditer du groupe",
  "Delete group": "Supprimer le groupe",
  Leave: "Partir",
  "Are you sure you want to delete the group?":
    "Êtes-vous sûr de vouloir supprimer le groupe?",
  Cancel: "Annuler",
  "Yes, Go Ahead": "Oui, vas y",
  "Share via link": "Partager via le lien",
  Copy: "Copie",
  Copied: "Copié",
  "Share on social media": "Partager sur les réseaux sociaux",
  "Add Channel": "Ajouter la Channel",
  "Channel Header": "En-tête de Channel",
  Live: "En direct",
  "Create Post": "Créer un message",
  "Notificaiton Bell": "Notificaiton cloche",
  "All posts": "Tous les messages",
  "Admin posts only": "Public d'administration uniquement",
  None: "Aucun",
  "Channel Setting drop down": "Détal du réglage de la Channel",
  "Edit Channel": "Channel de modification",
  "Embed Channel": "Channel intégrée",
  "Delete Channel": "Supprimer la Channel",
  "Are you sure you want to delete the Channel?":
    "Êtes-vous sûr de vouloir supprimer la Channel?",
  "Channel deleted!": "Channel supprimée!",
  "Create Group Pop up / Update": "Créer un pop-up / mise à jour de groupe",
  "Create group": "Créer un groupe",
  "Basic Detail": "Détail de base",
  Settings: "Paramètres",
  "Size 16:9 or 1600 by 900": "Taille 16: 9 ou 1600 par 900",
  "Group name": "Nom de groupe",
  "Enter group name": "Entrez le nom du groupe",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Suggestions: Espaces, canaux, groupes, blog, Knowledgebase, etc.",
  "Group Description": "Description du groupe",
  "Enter description here": "Entrez la description ici",
  "Group Type": "Type de groupe",
  Open: "Ouvrir",
  "Visible to all & anyone can join":
    "Visible pour tous et n'importe qui peut rejoindre",
  Closed: "Fermé",
  "User will have to request to join the group":
    "L'utilisateur devra demander à rejoindre le groupe",
  Paid: "Payé",
  "Group Price": "Prix ​​du groupe",
  "Currency (Default: INR)": "Devise (par défaut: INR)",
  "User will have to pay to join the group":
    "L'utilisateur devra payer pour rejoindre le groupe",
  "Make this a private group": "Faites-en un groupe privé",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Le groupe privé ne sera visible que pour les membres qui en font partie. L'administrateur peut ajouter des membres manuellement à partir de la liste des membres",
  "Upload from device": "Télécharger à partir de l'appareil",
  "Search image": "Image de recherche",
  "Valid group name required!": "Nom du groupe valide requis!",
  "Update Group": "Mettre à jour le groupe",
  "SEO Settings": "Paramètres SEO",
  "Custom URL": "URL personnalisée",
  "Meta Title": "Méta-titre",
  "Maximum length of 255 characters allowed":
    "Longueur maximale de 255 caractères autorisés",
  "Meta Description": "Meta Description",
  "Open Graph Detail": "Détail du graphique ouvert",
  "Open Graph Title": "Titre du graphique ouvert",
  "Open Graph Description": "Description du graphique ouvrir",
  "Open Graph Image": "Image du graphique ouvrir",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Dimensions recommandées: 1200px par 630px avec un rapport d'aspect de 1,91: 1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Si aucune URL n'est utilisée, elle a automatiquement choisi votre miniature de groupe.",
  "Create Channel Pop up / Update": "Créer la Channel pop up / mise à jour",
  "Create Channel": "Créer une Channel",
  "Channel Name": "Nom de la Channel",
  "Suggestions: General, Introductions, Community Guidelines":
    "Suggestions: Général, introductions, directives communautaires",
  "Channel Type": "Type de Channel",
  "Post Type": "Type de poste",
  "Group Chat": "Discussion de groupe",
  "Who can post in this Channel?": "Qui peut publier dans cette Channel?",
  "Only Admin": "Seulement administrateur",
  "Any user": "N'importe quel utilisateur",
  "Sorting order for Channel": "Ordre de tri pour la Channel",
  "Latest first": "Dernier premier",
  "Oldest post first": "Post le plus ancien en premier",
  "Channel Appearance": "Channel apparition",
  "List View": "Affichage de la liste",
  "Grid View": "Vue de la grille",
  "Make this closed Channel": "Faire cette Channel fermée",
  "Members will have to request access to see Channel content":
    "Les membres devront demander l'accès pour voir le contenu de la Channel",
  "Make this a private Channel": "Faites-en une Channel privée",
  "Disables requests (Make sure closed Channel is selected as well).":
    "Désactive les demandes (assurez-vous que la Channel fermée est également sélectionnée).",
  "Post Editor": "Éditeur de poste",
  "Create new post in": "Créer un nouveau message dans",
  "Add Cover Picture": "Ajouter une image de couverture",
  "Heading (Optional)": "En-tête (facultatif)",
  "Write here..": "Écrivez ici..",
  Picture: "Image",
  Video: "Vidéo",
  Attachment: "Pièce jointe",
  Poll: "Sondage",
  "Embed Code": "Code intégré",
  Tags: "Mots clés",
  "Toggle to question": "Basculer à la question",
  "Poll expire time": "Sondage expire l'heure",
  "Poll question": "Question de sondage",
  "Enter Question": "Entrer en question",
  Question: "Question",
  Option: "Option",
  Options: "Options",
  "Add Option": "Ajouter l'option",
  "Poll Expiry time": "Temps d'expiration du sondage",
  "12 Hours": "12 heures",
  "1 Day": "Un jour",
  "2 Days": "2 jours",
  "1 Week": "1 semaine",
  "2 Weeks": "2 semaines",
  "Always Open": "Toujours ouvert",
  "Write embeddable code": "Écrire du code intégable",
  "Click here to add embed code.": "Cliquez ici pour ajouter du code intégré.",
  "Paste your embed code here!": "Collez votre code intégré ici!",
  "Post Settings": "Paramètres de publication",
  "Update Post": "Mettre à jour la publication",
  Update: "Mise à jour",
  "Start meeting / Video call / Live":
    "Commencez à rencontrer / appel vidéo / en direct",
  "Meeting Name": "Nom de réunion",
  "Suggestion: Group discussion etc": "Suggestion: discussion de groupe, etc.",
  Description: "Description",
  "Enter description here (optional)":
    "Entrez la description ici (facultative)",
  "When do you want to start the session":
    "Quand voulez-vous commencer la session",
  "Right now": "Tout de suite",
  "Schedule for later": "Horaire plus tard",
  "Group video call": "Appel vidéo de groupe",
  Webinar: "Séminaire en ligne",
  "Go Live": "Se mettre en vie",
  "Info on the Post": "Informations sur le post",
  "Post comment": "Poster un commentaire",
  Comment: "Commentaire",
  Comments: "commentaires",
  Reply: "Répondre",
  Replies: "réponses",
  "Share via URL": "Partager via URL",
  "Reshare in other Channels": "Reshare dans d'autres Channels",
  "Post Card Drop Down": "Drop endetté de la carte postale",
  Bookmark: "Signet",
  "Pin post": "PIN",
  Edit: "Modifier",
  "Embed Post": "Poster",
  Delete: "Supprimer",
  "Remove Pin": "Retirer la broche",
  "Remove Bookmark": "Supprimer les signets",
  "Just copy paste this code wherever you want it to be embedded":
    "Copiez simplement Coller ce code partout où vous voulez qu'il soit intégré",
  Height: "Hauteur",
  Preview: "Aperçu",
  "Copied to clipboard": "Copié dans le presse-papier",
  "Select Widgets": "Sélectionner les widgets",
  "Add widgets to": "Ajouter des widgets à",
  "You can change their order by dragging & dropping the widgets over each other":
    "Vous pouvez changer leur commande en faisant glisser et en déposant les widgets les uns sur les autres",
  "Please note: Widget wont be displayed if there is no data available,":
    "Veuillez noter: le widget ne sera pas affiché s'il n'y a pas de données disponibles,",
  "Group Members": "Les membres du groupe",
  "Group Leaderboard": "Classement de groupe",
  "Community Leaderboard": "Classement communautaire",
  "Trending Posts": "Postes de tendance",
  "Group Events": "Événements de groupe",
  "Add Custom": "Ajouter la coutume",
  "Save Widgets": "Enregistrer les widgets",
  "Visible to user": "Visible pour l'utilisateur",
  "Visible to admin": "Visible pour administrer",
  "Add widget to": "Ajouter un widget à",
  "Upload cover picture": "Télécharger l'image de la couverture",
  "1:1 aspect ration (recommended)": "Ration d'aspect 1: 1 (recommandé)",
  "Card Title": "Titre de carte",
  "Enter widget name": "Entrez le nom du widget",
  "Small Description (try to keep it under 50 words)":
    "Petite description (essayez de le garder sous 50 mots)",
  "Enter description": "Entrez description",
  "Button Title": "Titre du bouton",
  "Enter button name": "Entrez le nom du bouton",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggentition: en savoir plus, rejoindre, postuler, etc.",
  "URL link": "Lien URL",
  "Create custom widget": "Créer un widget personnalisé",
  "Custom widget created successfully": "Widget personnalisé créé avec succès",
  Widgets: "Widgets",
  Members: "Membres",
  "See All": "Voir tout",
  "Communtiy Top Contributors": "Contributeurs de communauy",
  "Community Members": "Membres de la communauté",
  "Group Top Contributors": "Contributeurs de groupes de groupe",
  "Upcoming Events": "évènements à venir",
  "Home Page Banner": "Bannière de la page d'accueil",
  "Edit Banner": "Modifier la bannière",
  "Home page Edit Banner Pop up": "Page d'accueil Modifier la bannière pop-up",
  "Update Banner": "Mettre à jour la bannière",
  Search: "Recherche",
  "Upload Image": "Télécharger l'image",
  Remove: "Retirer",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Image de la bannière (veuillez utiliser un rapport 16: 6 ou 1600px par image 600px pour la meilleure vue)",
  "Banner Title": "Titre de la bannière",
  "Enter here": "Entrer ici",
  "Suggestion: Welcome to my community etc":
    "Suggestion: bienvenue dans ma communauté, etc.",
  "Banner Description": "Description de la bannière",
  "Enter banner description": "Entrez la description de la bannière",
  "Save Banner Info": "Enregistrer les informations de bannière",
  "Home Page Feed": "Page d'accueil Flux",
  Recent: "Récent",
  "Top Posts": "Posts supérieurs",
  Trending: "Tendance",
  "Home Feed Groups": "Groupes d'alimentation à domicile",
  "Header BarProfile Drop down": "En-tête Barprofile Drop Down",
  "My Profile": "Mon profil",
  Logout: "Se déconnecter",
  Notification: "Notification",
  Notifications: "Notifications",
  "Mark all viewed": "Marquez tous vu",
  "has created a post": "a créé un message",
  "wants to join": "veut rejoindre",
  "has commented on your post": "a commenté votre message",
  "has replied to your comment": "a répondu à votre commentaire",
  "Search for tags": "Rechercher des balises",
  "All groups": "Tous les groupes",
  "Search result": "Résultat de la recherche",
  "No post found for selected hashtag":
    "Aucun message trouvé pour le hashtag sélectionné",
  "Start a conversation": "Commencer une conversation",
  "Search by name": "Recherche par nom",
  Upcoming: "A venir",
  UPCOMING: "A VENIR",
  Past: "Passé",
  PAST: "PASSÉ",
  "No upcoming events": "Pas d'événements à venir",
  "Create event popup": "Créer une popup d'événement",
  "Event details": "Détails de l'évènement",
  "16:9 aspect ratio": "Ratio d'aspect 16: 9",
  "Event name": "Nom de l'événement",
  "Enter event name": "Entrez le nom de l'événement",
  "Host Name": "Nom d'hôte",
  "You will be made host if none selected":
    "Vous serez hôte si aucun n'est sélectionné",
  "Write here...": "Écrivez ici...",
  "No. of registration for the event": "Nombre d'inscription pour l'événement",
  Unlimited: "Illimité",
  "When & where": "Quand où",
  From: "Depuis",
  "Start date": "Date de début",
  time: "temps",
  To: "Pour",
  "End date": "Date de fin",
  "Current time zone:": "Fuseau horaire actuel:",
  "Can be updated from the communtiy setting":
    "Peut être mis à jour à partir du paramètre communitiy",
  "Online event": "Événement en ligne",
  "Add meeting link": "Ajouter le lien de la réunion",
  "Meeting link will be generated once event is created":
    "Le lien de réunion sera généré une fois l'événement créé",
  Venue: "Lieu",
  "Enter address": "Entrer l'adresse",
  Create: "Créer",
  Invite: "Inviter",
  "Share event via link": "Partager l'événement via le lien",
  Copy__1: "Copie",
  "Select Channel to post this event":
    "Sélectionnez la Channel pour publier cet événement",
  "Once posted, members of that group or Channel will recieve an notification":
    "Une fois publiés, les membres de ce groupe ou de ce Channel recevront une notification",
  Post: "Poste",
  RSVP: "RSVP",
  "Book Event": "Événement de livre",
  "Yes, I'll attend": "Oui, je vais assister",
  "May be": "Peut être",
  "No, I won't attend": "Non, je ne vais pas y assister",
  "Date & Time": "Date et heure",
  Host: "Héberger",
  "Online meeting": "Réunion en ligne",
  "Meeting link will be visible once registered for the event":
    "Le lien de réunion sera visible une fois inscrit à l'événement",
  "User Management": "Gestion des utilisateurs",
  "Go bac to community": "Aller au bac à la communauté",
  "Manage Members": "Gérer les membres",
  Member: "Membre",
  Invited: "Invité",
  "Search for member": "Rechercher un membre",
  "Add to group": "Ajouter au groupe",
  "Send Direct Message": "Envoyer un message direct",
  "Change role": "Rôle de changement",
  "Kick from community": "Donner un coup de pied à la communauté",
  "Super Admin": "Super administrateur",
  "Super admin will have full access to the communtiy settings":
    "Super Admin aura un accès complet aux paramètres de la communauté",
  "Group Admin": "Admin du groupe",
  "Admin of the selected group can edit the group information and create, edt or delete Channel under selected group":
    "L'administrateur du groupe sélectionné peut modifier les informations du groupe et créer, EDT ou Supprimer la Channel dans le groupe sélectionné",
  "Group Moderator": "Modérateur de groupe",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Le modérateur de groupe peut créer ou supprimer la publication, le commentaire, la réponse et les messages dans le groupe sélectionné.",
  User: "Utilisateur",
  Save: "Sauvegarder",
  "User role updated successfully":
    "Rôle de l'utilisateur mis à jour avec succès",
  Leaderboard: "Classement",
  "Click on the column header for sorting":
    "Cliquez sur l'en-tête de la colonne pour le tri",
  Rank: "Rang",
  "Karma Points": "Points karma",
  "Custom Points": "Points personnalisés",
  Follow: "Suivre",
  Upvotes: "Votes up",
  Posts: "Des postes",
  "Add custom points": "Ajouter des points personnalisés",
  "Subtract custom points": "Soustraire les points personnalisés",
  "Enter no. of points": "Entrez no. des points",
  "Add points": "Ajouter des points",
  "Invite members to": "Inviter les membres à",
  "Invite via link": "Inviter via le lien",
  "Invite via email": "Inviter par e-mail",
  "Add more": "Ajouter plus",
  "Upload a CSV": "Télécharger un CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Veuillez télécharger un fichier CSV sévéré par des virgules, avec en-tête comme nom, e-mail.",
  "Here is a template CSV file": "Voici un modèle de fichier CSV",
  "Upload File": "Téléverser un fichier",
  "Send Invite": "Envoyer l'invitation",
  "Mail Template": "Modèle de courrier",
  "Send Test Mail": "Envoyer un courrier de test",
  Hey: "Hé",
  "A has invite you to join the B": "A vous invite à rejoindre le B",
  "Switch to rich text editor": "Passez à un éditeur de texte riche",
  "Add Custom Message": "Ajouter un message personnalisé",
  "Enter message here": "Entrez le message ici",
  "Send Bulk Mail": "Envoyer un courrier en vrac",
  "Send Mail": "Envoyer un mail",
  "Send bulk mail to all community members or select group members":
    "Envoyez du courrier en vrac à tous les membres de la communauté ou sélectionnez les membres du groupe",
  Date: "Date",
  Subject: "Sujet",
  Recipients: "Destinataire",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 e-mails en vrac restant pour ce mois. Réinitialise <Date>",
  Everyone: "Tout le monde",
  "Enter mail subject": "Entrez le sujet du courrier",
  "Write message": "Écrire un message",
  Cancel__1: "Annuler",
  Send: "Envoyer",
  "Profile Page": "Page de profil",
  Activity: "Activité",
  Bookmarks: "Signets",
  "Edit Profile": "Editer le profil",
  Unsubscribe: "Se désabonner",
  "Settings (Account)": "Paramètres (compte)",
  Profile: "Profil",
  "Set profile Picture": "Définir la photo du profil",
  "Your name": "Votre nom",
  "User ID": "ID de l'utilisateur",
  "Short bio": "Courte biographie",
  "Link your social account, this will be displayed on your profile":
    "Liez votre compte social, cela sera affiché sur votre profil",
  "Add social handle": "Ajouter la poignée sociale",
  Verification: "Vérification",
  "Account Verification": "Vérification de compte",
  Email: "E-mail",
  "Enter email": "Entrez le courrier électronique",
  Verified: "Vérifié",
  Unverified: "Non vérifié",
  Change: "Changement",
  Unlink: "Déchaîner",
  Mobile: "Mobile",
  "enter mobile no.": "Entrez le mobile no.",
  "Request OTP": "Demander OTP",
  "Notification centre": "Centre de notification",
  "Manage your personal account notification preferences":
    "Gérez vos préférences de notification de compte personnel",
  Prefrences: "Préfusion",
  "Email notification": "Notification par e-mail",
  "Notify me about comments on my posts":
    "Me notifier des commentaires sur mes messages",
  "Notify me about replies on my comments":
    "Me notifier des réponses sur mes commentaires",
  "Notify me about upvotes on my posts":
    "Me notifier des votes up sur mes messages",
  "Notify me about reaction on my comments":
    "Me notifier de la réaction sur mes commentaires",
  "Notify me about reaction on my reply":
    "Me notifier de la réaction sur ma réponse",
  "Notify me about direct message": "Me notifier du message direct",
  "Notify me about my mentions": "Me notifier de mes mentions",
  "Notification for new posts": "Notification pour les nouveaux messages",
  "Only group & Channel available which you are part of":
    "Seule groupe et Channel disponibles dont vous faites partie",
  "Group & Channel": "Groupe et Channel",
  "All activity": "Toutes les activités",
  "Post by admin": "Post par admin",
  "No Notification": "Aucune notification",
  "Setting (Community)": "Cadre (communauté)",
  "General Settings": "réglages généraux",
  "Upload community logo": "Télécharger le logo de la communauté",
  "Community name": "Nom de la communauté",
  "Invalid field": "Champ non valide",
  "Your community address": "Votre adresse communautaire",
  "Fav icon": "Icône préférée",
  "This will be visible on the browser tab (32px by 32px)":
    "Ce sera visible sur l'onglet du navigateur (32px par 32px)",
  TimeZone: "Fuseau horaire",
  "Privacy Policy on login page":
    "Politique de confidentialité sur la page de connexion",
  "Terms & Condition on login page":
    "Conditions et condition sur la page de connexion",
  "Enter link here": "Entrez le lien ici",
  "Open Group Image": "Image de groupe ouvert",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Par défaut, IR prend la bannière communautaire téléchargée avec la communauté",
  "Community Experience": "Expérience communautaire",
  Onboarding: "Embarquement",
  "Make your community private": "Rendre votre communauté privée",
  "Users will have to login or sign up to access the community":
    "Les utilisateurs devront se connecter ou s'inscrire pour accéder à la communauté",
  "Make account verification mandatory for both email & mobile":
    "Rendre la vérification du compte obligatoire pour les e-mails et le mobile",
  "New user will have to verify both email & mobile no. to access the community":
    "Le nouvel utilisateur devra vérifier à la fois les e-mails et le mobile no. pour accéder à la communauté",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "L'administrateur devra également vérifier leur mobile non. & e-mail ou bien ils ne pourront pas accéder à la communauté",
  "Gated community access": "Accès communautaire fermé",
  "Admin will have to approve or reject user's requests to join the community":
    "L'administrateur devra approuver ou rejeter les demandes de l'utilisateur pour rejoindre la communauté",
  "Disable mobile login/register":
    "Désactiver la connexion / enregistrement mobile",
  "Disable email login/register":
    "Désactiver la connexion / enregistrement des e-mails",
  "Disable google login/register":
    "Désactiver Google Connexion / enregistrement",
  "Disable facebook login/register":
    "Désactiver la connexion / enregistrement Facebook",
  "Show & Hide features": "Afficher et masquer les fonctionnalités",
  "Hide direct chat": "Masquer le chat direct",
  "User will not be able to send direct messages to each other":
    "L'utilisateur ne pourra pas se envoyer des messages directs les uns aux autres",
  "Hide profile picture from top right corner for mobile responsive view":
    "Masquer la photo du profil du coin supérieur droit pour une vue réactive mobile",
  "Useful when using community in an in app browser":
    "Utile lors de l'utilisation de la communauté dans un navigateur d'applications",
  "Hide profile picture from top right corner in every view":
    "Masquer la photo du profil du coin supérieur droit dans chaque vue",
  "Disable header dropdown on profile picture":
    "Désactiver la liste déroulante de l'en-tête sur la photo du profil",
  "Useful for integrated community, especially using SSO":
    "Utile pour la communauté intégrée, en particulier en utilisant SSO",
  "Hide the communtiy logo from the header":
    "Cachez le logo communiy de l'en-tête",
  "Hide the communtiy title from the header":
    "Cachez le titre commun de la tête de l'en-tête",
  "Show collpased group in the side nav bar":
    "Afficher le groupe collé dans la barre de navigation latérale",
  "Show full expanded post in the home feed":
    "Afficher le poste élargi complet dans le flux de la maison",
  "Hide the leaderboard score from the header":
    "Cachez le score de classement de l'en-tête",
  "Show powered by Pensil": "Montrez Powered par Pensil",
  "Hide closed groups if not joined":
    "Cacher les groupes fermés s'ils ne sont pas joints",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Les groupes fermés ne seraient pas montrés à l'utilisateur s'ils ne sont pas membre du groupe",
  "Hide Community subscriber count from the users":
    "Masquer le nombre d'abonné communautaire des utilisateurs",
  "This will hide the community subscriber list in the community header":
    "Cela cachera la liste des abonnés communautaires dans l'en-tête communautaire",
  "Disable Communtiy Switcher": "Désactiver le commutateur commun",
  "Use MD editor for create post description":
    "Utilisez MD Editor pour créer une description du post",
  "Non logged in user will land on explore group page instead of post directory":
    "L'utilisateur non connecté atterrira sur la page du groupe Explore au lieu du répertoire postal",
  "Advanced configurable": "Configurable avancé",
  "Communtiy default country code": "Code de pays par défaut communaut",
  "This will show up by default on login/signup":
    "Cela apparaîtra par défaut sur la connexion / l'inscription",
  "Onboarding/Welcome Message": "Message d'intégration / de bienvenue",
  "Welcome Message": "Message de bienvenue",
  "Greet your members with personalized message":
    "Saluer vos membres avec un message personnalisé",
  "Welcome to": "Bienvenue à",
  Message: "Message",
  "Add message": "Ajouter un message",
  "Custom domain": "Domaine personnalisé",
  "Add custom domain": "Ajouter un domaine personnalisé",
  "Create new request": "Créer une nouvelle demande",
  "Enter your custom domain": "Entrez votre domaine personnalisé",
  "Setup details": "Détails de configuration",
  "Add a CNAME record in your domain pointing to this community's address":
    "Ajoutez un enregistrement CNAME dans votre domaine pointant vers l'adresse de cette communauté",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "Pour configurer votre domaine, vous devrez ajouter un enregistrement CNAME pointant votre domaine à votre URL de la communauté Pensil",
  "Step by Step help apge": "Aide étape par étape APGE",
  "All request list": "Toutes les demandes de demande",
  "Allow admin to assign/un-assign custom points to users":
    "Autoriser l'administrateur à affecter / non-offsigner des points personnalisés aux utilisateurs",
  "Base points for user to begin with":
    "Points de base pour que l'utilisateur commence par",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Les points de base seront mis à jour pour tous les utilisateurs, toutes les modifications refléteront pour tous les utilisateurs.",
  "Allocate points for interaction": "Allouer des points à l'interaction",
  "Upvoting post": "Post de vote up",
  "creating a comment": "Créer un commentaire",
  "creating a reply": "Créer une réponse",
  "Getting upvote to their post": "Se faire voter à leur poste",
  "Getting comment to their post": "Obtenir un commentaire à leur message",
  "Getting reply to their comment": "Obtenir une réponse à leur commentaire",
  "Creating a post": "Créer un post",
  Visiblity: "Visibilité",
  "Move Up": "Déplacer vers le haut",
  "Move Down": "Descendre",
  "Create embedded Channel": "Créer une Channel intégrée",
  "Preview area": "Zone d'aperçu",
  "Theme selector": "Sélecteur de thème",
  "Community Theme": "Thème de la communauté",
  "Select theme": "Sélectionne un thème",
  "Select brand color": "Sélectionner la couleur de la marque",
  "Customize your button color to match your brand":
    "Personnalisez la couleur de votre bouton pour correspondre à votre marque",
  "Select on button text color": "Sélectionnez la couleur du texte du bouton",
  "Suggestion: Keep inverted contrast against brand color":
    "Suggestion: garder le contraste inversé avec la couleur de la marque",
  Billing: "Facturation",
  "Current plan": "Plan actuel",
  "You are current on Free PLan": "Vous êtes à jour sur le plan gratuit",
  "Renew on: NA": "Renouveler sur: NA",
  Select: "Sélectionner",
  "Email us at": "Envoyez-nous un courriel à",
  "Compare plan": "Plan de comparaison",
  "API Keys": "Clés de l'API",
  "Create new key": "Créer une nouvelle clé",
  "Total keys": "Clés totales",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Gardez vos clés API en sécurité! Vous pouvez les utiliser pour accéder à une fonctionnalité spécifique et laisser les applications Cetain perfroom certaines actions en votre nom sur la plate-forme.",
  "Key name": "Nom de clé",
  "Key value": "Valeur clé",
  Action: "Action",
  "Group updated!": "Groupe mis à jour!",
  "Are you sure you want to": "Êtes-vous sûr de vouloir",
  "Make Moderator": "Faire du modérateur",
  "Remove from group": "Retirer du groupe",
  "Show All": "Afficher tout",
  "Change Role": "Rôle de changement",
  Close: "Fermer",
  copy: "copie",
  LINKS: "LIENS",
  "Email Id": "ID de messagerie",
  Name: "Nom",
  "Search for an image": "Rechercher une image",
  "Crop Image": "Image de recadrage",
  "No photos found": "Aucune photo trouvée",
  Community: "Communauté",
  "Banner ratio - 16:9": "Ratio de bannières - 16: 9",
  "Banner Ratio - 16:6": "Ratio de bannières - 16: 6",
  "Share on mail": "Partager par courrier",
  "Share on Telegram": "Partager sur Telegram",
  "Share on Whatsapp": "Partager sur WhatsApp",
  "Share on Linkedin": "Partager sur LinkedIn",
  "Share on Facebook": "Partager sur Facebook",
  "Share on Twitter": "Partager sur Twitter",
  "Pin Post": "PIN",
  "Heading (optional)": "En-tête (facultatif)",
  "Select Channel": "Sélection de la Channel",
  Gif: "Gif",
  "Create Custom Widget": "Créer un widget personnalisé",
  "Are you sure you want to delete this widget?":
    "Êtes-vous sûr de vouloir supprimer ce widget?",
  "Go to event": "Aller à l'événement",
  "Add Tags": "Ajouter des balises",
  "Create Tag": "Créer une balise",
  "Title (Optional)": "Titre (facultatif)",
  "Search Gif": "Rechercher le gif",
  "What's on your mind": "Qu'est-ce qui préoccupe votre esprit",
  "Looks like nothing available here right now.":
    "On dirait rien de disponible ici.",
  "Try exploring other tabs": "Essayez d'explorer d'autres onglets",
  "Delete Widget": "Supprimer le widget",
  "Please select a Channel to post":
    "Veuillez sélectionner une Channel à publier",
  "Post added successfully!": "Post ajouté avec succès!",
  "Failed to create post. Please check post content and retry again.":
    "Échec de la création du post. Veuillez vérifier le contenu de la publication et réessayer.",
  "Are you sure you want to discard current post?":
    "Êtes-vous sûr de vouloir éliminer le message actuel?",
  "By default it will take up the image uploaded with the post":
    "Par défaut, il prendra l'image téléchargée avec le message",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Le quiz ne peut être visualisé et tenté que de l'appareil mobile dès maintenant!",
  "Community Banner": "Bannière communautaire",
  "Change Cover": "Changer la couverture",
  "Suggestions: Welcome to my community etc":
    "Suggestions: Bienvenue dans ma communauté, etc.",
  "(edited)": "(édité)",
  "Aspect ratio (recommended)": "Ratio d'aspect (recommandé)",
  "Banner details updated!": "Détails de la bannière mis à jour!",
  "Refresh if changes are not visible!":
    "Actualiser si les modifications ne sont pas visibles!",
  "Banner details could not be updated!":
    "Les détails de la bannière n'ont pas pu être mis à jour!",
  "Post deleted": "Post supprimé",
  "Post not available. It may have been deleted.":
    "Post non disponible. Il a peut-être été supprimé.",
  Admin: "Administrer",
  Moderator: "Modérateur",
  "Bookmark added!": "Bookmark ajouté!",
  "Bookmark removed": "Bookmark supprimé",
  "Failed performing action!": "Échec de l'action de performance!",
  "Copy this link to share on other platforms":
    "Copiez ce lien à partager sur d'autres plateformes",
  "View this event": "Voir cet événement",
  Event: "Événement",
  "Join Meeting": "Rejoindre la réunion",
  "The live meeting has ended": "La réunion en direct a pris fin",
  "read more...": "En savoir plus...",
  "Load more comments": "Chargez plus de commentaires",
  "Post Reply": "Poster une réponse",
  "Post Comment": "Poster un commentaire",
  more: "plus",
  upvoted: "voté",
  "1 Upvote": "1 vote up",
  "1 Comment": "1 commentaire",
  "Please enter a title": "Veuillez saisir un titre",
  "Please enter a url link": "Veuillez saisir un lien URL",
  "Please enter button title": "Veuillez saisir le titre du bouton",
  "Button label must be at least 20 characters":
    "L'étiquette de bouton doit comporter au moins 20 caractères",
  "Please enter valid url": "Veuillez saisir URL valide",
  "Suggestions: Know more, Join, Apply...":
    "Suggestions: en savoir plus, rejoindre, postuler ...",
  URL: "URL",
  "Community Events": "Événements communautaires",
  "Widget deleted successfully": "Widget supprimé avec succès",
  "Community Top Contributors": "Top contributeurs de la communauté",
  points: "points",
  "Points cannot be greater than current points":
    "Les points ne peuvent pas être supérieurs aux points actuels",
  "Point cannot be a negative value":
    "Le point ne peut pas être une valeur négative",
  "Point cannot be a decimal point":
    "Le point ne peut pas être un point décimal",
  "Please enter a valid number": "S'il vous plait, entrez un nombre valide",
  "Karma points updated successfully": "Points de karma mis à jour avec succès",
  "Error in updating karma points": "Erreur de mise à jour des points karma",
  "Add karma points": "Ajouter des points de karma",
  "Minus karma points": "Minus Karma Points",
  "Total Karma points:": "Points karma totaux:",
  "Enter no. of karma points": "Entrez no. de points karma",
  "Minus Points": "MINUS POINTS",
  "Trending Post": "Poste de tendance",
  "No visible description": "Aucune description visible",
  "Picture ratio: 1:1": "Ratio d'image: 1: 1",
  "home feed": "flux de maison",
  "Event name is invalid!": "Le nom de l'événement n'est pas valide!",
  custom: "coutume",
  "Custom widget updated successfully":
    "Widget personnalisé mis à jour avec succès",
  "Name invalid!": "Nom invalide!",
  "Current timezone:": "Fuseau horaire actuel:",
  "can be updated from community settings":
    "peut être mis à jour à partir des paramètres communautaires",
  "Event created successfully": "Événement créé avec succès",
  "Error in creating event": "Erreur de création de l'événement",
  Attendees: "Participants",
  "Do you wish to notify these changes to attendees?":
    "Souhaitez-vous informer ces changements aux participants?",
  "users wants to attend this event":
    "Les utilisateurs veulent assister à cet événement",
  "Yes, Notify them": "Oui, informez-les",
  "No, its okay": "Non c'est bon",
  "Do you wish to notify that event is cancelled?":
    "Souhaitez-vous informer que l'événement est annulé?",
  "Are you sure you want to delete the event?":
    "Êtes-vous sûr de vouloir supprimer l'événement?",
  "Delete Event": "Supprimer l'événement",
  "New Event": "Nouvel évènement",
  "Accept Invitation": "Accepter l'invitation",
  Requested: "Demandé",
  Declined: "Diminué",
  "Request Access": "Demande d'accès",
  "Enroll for": "S'inscrire à",
  Housefull: "Maison de la maison",
  "Just copy paste this code wherever you want it to be embedded:":
    "Copiez simplement Coller ce code partout où vous voulez qu'il soit intégré:",
  Ended: "Terminé",
  "Error getting events": "Erreur d'erreur d'obtenir des événements",
  "Events could not be fetched!": "Les événements n'ont pas pu être récupérés!",
  "Show Attendees": "Montrer aux participants",
  Subscribers: "Les abonnés",
  "Make this a closed Channel": "En faire une Channel fermée",
  "Update Channel": "Channel de mise à jour",
  "Valid Channel name required!": "Nom de la Channel valide requis!",
  "Enter Channel name": "Entrez le nom de la Channel",
  "Community Admin": "Administrateur communautaire",
  Embed: "Encombrer",
  "Invite to": "Inviter à",
  "Please enter a valid email address":
    "S'il vous plaît, mettez une adresse email valide",
  "Invitation sent successfully": "Invitation envoyée avec succès",
  "Error sending invitation": "Erreur d'envoi d'invitation",
  "Email is not valid!": "L'email n'est pas valide!",
  "Data could not be loaded!": "Les données n'ont pas pu être chargées!",
  "Posted in": "Publié dans",
  "Invite Members Individually": "Inviter les membres individuellement",
  "Start a discussion": "Lancer une discussion",
  "Post something": "Publier quelque chose",
  "Invite your audience": "Invitez votre public",
  "Choose Plan": "Choisir le plan",
  or: "ou",
  "Create a link": "Créer un lien",
  "Add link": "Ajouter un lien",
  "Update link": "Mettre à jour le lien",
  "Add shortcut to your most used tools":
    "Ajouter le raccourci à vos outils les plus utilisés",
  "Your website, Instagram Channel, Facebook profile etc":
    "Votre site Web, chaîne Instagram, profil Facebook, etc.",
  "Valid link name required!": "Nom du lien valide requis!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "URL valide requise et assurez-vous que vous ne manquez pas http: // ou https: //",
  "Invite to__1": "Inviter à",
  "Link Name": "Nom de lien",
  "Link URL": "URL de liaison",
  "Could not subscribe to": "Je ne pouvais pas souscrire à",
  "Subscribed to": "Abonné à",
  "By default it will take up the community banner uploaded with the community":
    "Par défaut, il prendra la bannière communautaire téléchargée avec la communauté",
  "Created by": "Créé par",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Événement en ligne (le lien de réunion sera visible une fois enregistré pour l'événement)",
  "Join Call": "Rejoindre l'appel",
  "Please login to book this event":
    "Veuillez vous connecter pour réserver cet événement",
  "Commented on": "Commenté sur",
  "Replied to": "Répondu à",
  "Looks like it's lonely here.": "On dirait que c'est seul ici.",
  "Try exploring other Channels and groups.":
    "Essayez d'explorer d'autres Channels et groupes.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Veuillez procéder avec le paiement ou l'administrateur de contact pour accéder à ce groupe.",
  "This is a closed group You'll need access to this group.":
    "Il s'agit d'un groupe fermé, vous aurez besoin d'accéder à ce groupe.",
  "You have no bookmarks right now.":
    "Vous n'avez pas de signets en ce moment.",
  "The user hasn't posted anything yet publicly.":
    "L'utilisateur n'a encore rien publié publiquement.",
  "No activity to show.": "Aucune activité à présenter.",
  "Are you sure you want to unsubscribe the community?":
    "Êtes-vous sûr de vouloir désabonner la communauté?",
  "deals left": "Offres laissées",
  "Something went wrong while loading posts":
    "Quelque chose s'est mal passé en chargeant des messages",
  "Could not unsubscribe from": "Ne pouvait pas se désabonner de",
  "Unsubscribed from": "Non abusé de",
  "Login to view this page": "Connectez-vous pour afficher cette page",
  Login: "Connexion",
  "There was some error while doing the action!":
    "Il y a eu une erreur en faisant l'action!",
  "Unable to delete Channel!": "Impossible de supprimer la Channel!",
};
