function RectangleCardShimmer({ width = "100%", height = "300px" }) {
  return (
    <div
      className="RectangleCard animate-pulse py-1 bg-gray-300"
      style={{ height, width }}
    />
  );
}

export { RectangleCardShimmer };
