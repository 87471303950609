import cx from "classnames";
import { useEffect, useState } from "react";
import { ToastTypes } from "../../contexts/toastr.context";
import { WidgetService } from "../../_service";
import Validator from "../../_utils/validator";
import { ActionModal } from "../action-modal.component";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import { Checkbox } from "../form-controls/checkbox.component";
import IconButton2 from "../icon-button-2-component";
import IconMenu2, { ActionType } from "../icon-menu-2.component";
import { Modal } from "../modal.component";
import { CreateWidgetModal } from "./create-custom-widget-modal.component";
import { useAppService } from "../../hooks/use-app-service";

/**
 * @description This component is used to create/edit custom widget.
 * @param {Function} addToast Callback function to display message in toast
 * @param {any} community community object.
 * @param {any} user current active user profile data.
 * @param {boolean} forCommunity Whether pop-up to display on community or in group. Default value is true. Set false to use this pop-up on group.
 * @param {Object} Group active group object is required if forCommunity is set to false.
 * @param {boolean} active Determine pop-up visibility.
 * @param {Function} setActive Callback function to toggle pop-up visibility.
 * @param {Array[]} widgets List of widgets to be displayed in pop-up.
 * @param {Function} Callback function to update widget list.
 */
export function SelectWidgetModal({
  addToast,
  community,
  user,
  forCommunity = true,
  group,
  active,
  setActive = (e) => {},
  widgets = [],
  onWidgetListUpdate = (e) => {},
}) {
  const [widgetList, setWidgetList] = useState(widgets ?? []);
  const [isSelectWidgetVisible, setIsSelectWidgetVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [widgetToEdit, setWidgetToEdit] = useState();

  const [draggingTabIndex, setDraggingTabIndex] = useState(null);

  const { analyticsService } = useAppService();

  useEffect(() => {
    if (widgetToEdit) {
      setIsSelectWidgetVisible(true);
    } else {
      setIsSelectWidgetVisible(false);
    }
  }, [widgetToEdit]);

  useEffect(() => {
    if (Validator.hasValue(widgets)) {
      setWidgetList(widgets);
    }
  }, [widgets]);

  if (!active) {
    return null;
  }

  function saveChanges() {
    const sortWidgets = widgetList.map((w, index) => {
      return {
        widgetId: w.id,
        sortOrder: index,
        isVisible: w.isVisible,
        isVisibleToAdmin: w.isVisibleToAdmin,
      };
    });

    const noOfCustomWidgets = widgetList.filter(widget => widget.type === 'custom').length;
    const nonCustomVisibleWidgets = widgetList
    .filter(widget => widget.type !== 'custom')
    .reduce((acc, widget) => {
      acc[widget.type] = widget.isVisible;
      return acc;
    }, {});

    analyticsService.track("save-widget-clicked", {
      noOfCustomWidgets,
      ...nonCustomVisibleWidgets
    });

    setIsLoading(true);
    WidgetService.updateSortOrder(user, community.id, {
      sortOrder: sortWidgets,
      groupId: group ? group.id : "",
    })
      .then((res) => {
        setActive(false);
        addToast("Widgets saved successfully");
        onWidgetListUpdate(widgetList);
      })
      .catch((err) => {
        console.log(err);
        addToast("Error in saving widgets", "", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      className="SelectGroupWidgetModal"
      active={active}
      width="490px"
      padding={false}
      setActive={setActive}>
      {/* headers */}
      <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
        <span className="font-bold">
          <I18>Select Widgets</I18>
        </span>
        <IconButton2
          hoverable
          icon="cross"
          size="sm"
          rotate={45}
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      {/* body */}
      <div className="px-5 py-2 select-none">
        {/* info */}
        <div className="mb-5">
          <div className="font-semibold mt-1">
            <I18>Add widget to</I18> {group ? group.name : community.name}
          </div>
          <div className="text-xs theme-text-subtitle-1 mt-1">
            <I18>
              You can change their order by dragging & dropping the widgets over
              each other
            </I18>
          </div>
          <div className="text-xxs theme-text-subtitle-2 p-1  border-l-2 ">
            <I18>
              Please note: Widget wont be displayed if there is no data
              available
            </I18>
          </div>
        </div>
        {/* widgets */}
        {widgetList &&
          widgetList.map((widget, index) => {
            return (
              <div
                className="mb-2"
                key={index}
                onDrop={(e) => {
                  try {
                    // exchange the sortOrder of both of the indices
                    const sortOrder = widgetList.map((g, i) => {
                      return {
                        ...g,
                        sortOrder: i + 1,
                      };
                    });

                    const oldIndex = sortOrder[index].sortOrder;
                    // set new sort order
                    sortOrder[index].sortOrder =
                      sortOrder[draggingTabIndex].sortOrder;
                    // set old sort order
                    sortOrder[draggingTabIndex].sortOrder = oldIndex;

                    // sort the sortOrder list according to sortOrder
                    sortOrder.sort((a, b) => a.sortOrder - b.sortOrder);
                    setWidgetList(sortOrder);
                  } catch (e) {
                    console.error(e);
                  }
                }}>
                <WidgetRow
                  addToast={addToast}
                  widget={widget}
                  forCommunity={forCommunity}
                  setWidgetToEdit={setWidgetToEdit}
                  onDeleteWidget={(wid) => {
                    // get widget index
                    const newList = widgetList.filter((p) => p.id !== wid.id);
                    setWidgetList(newList);
                  }}
                  onUpdateWidget={(wid) => {
                    // get widget index
                    const widgetIndex = widgetList.findIndex(
                      (p) => p.id === wid.id
                    );
                    const oldWidget = [...widgetList];
                    oldWidget.splice(widgetIndex, 1, wid);
                    setWidgetList(oldWidget);
                  }}
                  onDragStart={(e) => {
                    setDraggingTabIndex(index);
                  }}
                />
              </div>
            );
          })}

        <div className="mt-8 ">
          <Button
            onClick={(e) => {
              analyticsService.track("add-custom-widget-clicked");
              setIsSelectWidgetVisible(true);
            }}
            outlined
            label="Add Custom"
          />
        </div>

        <div className="flex justify-between mt-14">
          <Button
            label="Cancel"
            onClick={(e) => {
              setActive(false);
            }}
            large
            flat></Button>

          <div className="justify-between">
            <Button
              isLoading={isLoading}
              onClick={(e) => {
                saveChanges();
              }}
              className="p-4 w-full"
              label="Save Widgets"
              large
            />
          </div>
        </div>
        <CreateWidgetModal
          widgetToEdit={widgetToEdit}
          community={community}
          user={user}
          group={group}
          active={isSelectWidgetVisible}
          setActive={(visible) => {
            setIsSelectWidgetVisible(visible);
            setWidgetToEdit(undefined);
          }}
          forCommunity={forCommunity}
          onWidgetCreate={(widget) => {
            addToast("Custom widget created successfully");
            const list = [...widgetList, widget];
            setWidgetList(list);
            setIsSelectWidgetVisible(false);
            // setActive(false);
            console.log("Widgets added to list successfully", widgetList);
          }}
          onWidgetUpdated={(widget) => {
            addToast("Custom widget updated successfully");
            const list = [...widgetList];
            const index = list.findIndex((p) => p.id === widget.id);
            list[index] = widget;
            setWidgetList(list);
            setIsSelectWidgetVisible(false);
            // setActive(false);
          }}
        />
      </div>
    </Modal>
  );

  function WidgetRow({
    addToast,
    widget,
    forCommunity,
    setWidgetToEdit = (e) => {},
    onUpdateWidget = (e) => {},
    onDeleteWidget = (e) => {},
    onDragStart = (e) => {},
    onDrop = (e) => {},
  }) {
    const isCustomWidget = widget.type === "custom";
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    function getMappedWidgetName() {
      switch (widget.type) {
        case "custom":
          return widget.details.title;
        case "upcoming-events":
          return forCommunity ? "Community Events" : "Group Events";
        case "community-upcoming-event":
          return "Community Event";
        case "top-posts":
          return "Trending Posts";
        case "members":
          return forCommunity ? "Community Members" : "Group Members";
        case "leaderboard":
          return "Group Leaderboard";
        case "community-leaderboard":
          return "Community Leaderboard";
        case "community-members":
          return "Community Members";
        default:
          return widget.type;
      }
    }

    function onSubmitDelete() {
      setIsLoading(true);
      WidgetService.deleteWidget(user, community.id, widget.id)
        .then(() => {
          addToast("Widget deleted successfully");
          onDeleteWidget(widget);
        })
        .catch((err) => {
          console.error(err);
          addToast("Error in deleting widget", "", ToastTypes.danger);
        })
        .finally(() => {
          setIsDeleteModalVisible(false);
          setIsLoading(false);
        });
    }
    return (
      <div
        draggable={true}
        onDragStart={onDragStart}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={onDrop}>
        <div
          className={cx(" border theme-border-default theme-bg-surface", {
            "pl-3": isCustomWidget,
            "p-3": !isCustomWidget,
          })}>
          <div className="flex items-center justify-between">
            <span className="theme-text-headline-1 font-semibold text-md">
              {widget.type === "custom" ? (
                <div>
                  <span>
                    <I18>{getMappedWidgetName()}</I18>
                    <span
                      className={
                        "mx-2 px-2 py-1 theme-bg-disable theme-text-primary text-xs rounded"
                      }>
                      <I18>custom</I18>
                    </span>
                  </span>
                </div>
              ) : (
                <span>
                  <I18>{getMappedWidgetName()}</I18>
                </span>
              )}
            </span>
            <div className="flex  items-center">
              {widget.type === "custom" ? (
                <IconMenu2
                  className=""
                  hoverable={false}
                  actions={[
                    {
                      // change user role
                      icon: "edit",
                      label: "Edit",

                      onClick: (e) => {
                        setWidgetToEdit(widget);
                      },
                    },
                    {
                      // change user role
                      icon: "trash",
                      label: "Delete",
                      actionType: ActionType.alert,
                      onClick: (e) => {
                        setIsDeleteModalVisible(true);
                      },
                    },
                  ]}
                />
              ) : null}
            </div>
          </div>

          <div className="flex justify-evenly mb-4 mt-2 border theme-border-default p-2 mr-3 theme-bg-default">
            <div className="flex space-x-2 text-xs theme-text-subtitle-2">
              <div>
                <I18>Visible to user</I18>
              </div>
              <Checkbox
                className="ml-2"
                onClick={(val) => {
                  const updateWidget = {
                    ...widget,
                    isVisible: !widget.isVisible,
                  };
                  onUpdateWidget(updateWidget);
                }}
                selected={widget.isVisible}
              />
            </div>
            <div className="flex space-x-2 text-xs theme-text-subtitle-2 ">
              <div>
                <I18>Visible to admin</I18>
              </div>
              <Checkbox
                className="ml-2"
                onClick={(val) => {
                  const updateWidget = {
                    ...widget,
                    isVisibleToAdmin: !widget.isVisibleToAdmin,
                  };
                  onUpdateWidget(updateWidget);
                }}
                selected={widget.isVisibleToAdmin}
              />
            </div>
          </div>
        </div>
        <ActionModal
          active={isDeleteModalVisible}
          setActive={setIsDeleteModalVisible}
          onSubmit={onSubmitDelete}
          header="Delete Widget"
          btnColor="red"
          title="Are you sure you want to delete this widget?"
          isLoading={isLoading}
        />
      </div>
    );
  }
}
