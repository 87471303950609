import { useEffect, useState } from "react";

const useRecorder = (): {
  audioURL: string;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  resetRecording: () => void;
} => {
  const [audioURL, setAudioURL] = useState("");
  const [audio, setAudio] = useState<any>({});
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else if (recorder.state === "recording") {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e: BlobEvent) => {
      setAudioURL(URL.createObjectURL(e.data));
      setAudio({
        blob: e.data,
        url: URL.createObjectURL(e.data),
        name: "audio",
        type: e.data.type,
        size: e.data.size,
      });
      // console.log("audio", e.data);
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
    // console.log("recording Started");
  };

  const stopRecording = () => {
    setIsRecording(false);
    // console.log("recording Stopped");
  };

  const resetRecording = () => {
    setAudioURL("");
    setIsRecording(false);
    // console.log("recording Reset");
  };

  return {
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    resetRecording,
  };
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;
