import MessageTileShimmer from "./message-tile-shimmer.component";

/**
 * MessageListShimmer
 * @param {*} length
 */
function MessageListShimmer({ length }) {
  const list = [];

  for (let i = 0; i < length ?? 1; i++) {
    list.push(<MessageTileShimmer key={i} />);
  }
  return (
    <div className="pt-0 mx-auto animate-pulse w-full ">
      <div className="flex p-6   border-b rounded">
        <div className=" w-12 h-2 bg-gray-300 rounded" />
      </div>
      <div className="RealtimeSectionDetail flex-grow flex flex-col justify-end px-8 py-2">
        {list}
      </div>
    </div>
  );
}

export default MessageListShimmer;
