import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import cx from "classnames";
import { ToastTypes } from "../../contexts/toastr.context";
import { useToast } from "../../hooks/use-toast.hook";
import { Community } from "../../types/community/community.type";
import { Group } from "../../types/group/group.type";
import { MySubscription } from "../../types/subscription/my-subscription-plan";
import { SubscriptionPlan } from "../../types/subscription/subscription-plan";
import { User } from "../../types/user/minimal-user.type";
import { history } from "../../_config";
import { createGroupPageUrlFromSlug, getGroupPageRoute } from "../../_pages";
import { GroupCheckoutComponents } from "../../_pages/group/group-checkout-components";
import JoinButton from "../../_pages/group/group-join-button.component";
import { GroupService } from "../../_service";
import AnalyticsService, { EventType } from "../../_service/analytics-service";
import { RootState } from "../../_store";
import { cancelMySubscription } from "../../_store/subscription/my-subscription/my-subscription.action";
import {
  removeActiveGroup,
  setGroups,
} from "../../_store/_actions/group.actions";
import { Fold } from "../../_utils/extensions/typescript-utils";
import { SubscriptionUtils } from "../../_utils/subscription/subscription-util";
import Validator from "../../_utils/validator";
import { ActionModal } from "../action-modal.component";
import { NewActionModal } from "../action2-modal.component";
import I18 from "../atoms/i18";
import LazyImage from "../atoms/lazy-image";
import EmbedLinkModal from "../embed-link-modal.component";
import IconMenu2 from "../icon-menu-2.component";
import { Loader } from "../loader.component";
import { NL2BR } from "../nl2br.component";
import { ShareTextModal } from "../share-text-modal.component";
import { CreateGroupModal } from "./create-group-modal.component";
import { CreateSectionModal } from "./create-section-modal.component";
import GroupInviteModal from "./group-invite-modal.component";
import { MembersListModal } from "./group-memberslist.modal";
import PrefixedLink from "../prefixed-link";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

interface GroupInfoComponentProps {
  group: Group;
  setGroup: (e: Group) => void;
  user: User;
  activeTab: String | null;
  socket: any;
  community: Community;
  showShareModal: boolean;
  subscriptionsPlan: Array<SubscriptionPlan>;
  setShowShareModal: (e: any) => void;
  mySubscription: MySubscription;
  cancelMySubscription?: (e: any) => void;
  updateActiveGroup?: (e: Group) => void;
}

function GroupInfoComponent({
  group,
  setGroup,
  user,
  activeTab,
  socket,
  community,
  showShareModal,
  subscriptionsPlan,
  setShowShareModal,
  mySubscription,
  cancelMySubscription = () => {},
  updateActiveGroup = (e) => {},
}: GroupInfoComponentProps) {
  //get total members counts
  const [members, setMembers] = useState({});

  const [isMobile, setIsMobile] = useState(false);
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);
  const [showMembersList, setShowMembersList] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);

  const [isEditingTab, setIsEditingTab] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [activeInvite, setActiveInvite] = useState(false);

  const [buyNowModal, setBuyNowModal] = useState(false);

  const [displayCancelSubscription, setIsDisplayCancelSubscription] =
    useState(false);

  const activeTabModel = group?.tabs?.find((t) => t.id === activeTab);

  const { addToast } = useToast();

  const plans = useMemo(
    () =>
      SubscriptionUtils.filterSubscriptionsForGroup(
        group.id!,
        subscriptionsPlan
      ),
    [subscriptionsPlan, group.id]
  );

  const isSubscriptionPurchased =
    SubscriptionUtils.isSubscriptionPurchasedByGroup(mySubscription, group.id!);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1201); // Updates the isMobile state based on screen width
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSubmitDelete = () => {
    setIsLoading(true);
    GroupService.deleteGroup(user, group.id)
      .then((response) => {
        // remove group
        setGroup(response.group);
        addToast("Group deleted!");
        updateActiveGroup(response.group);
        setIsDeleteModalVisible(false);
        history.push(getSegmentedPath("/"));
        // TODO: redirect to some other group or homepage
      })
      .catch((err) => {
        console.log(err);
        setIsDeleteModalVisible(false);
        addToast("Group could not be deleted!", "", "danger");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitLeave = () => {
    setIsLoading(true);
    GroupService.leaveGroup(user, group.id)
      .then((response) => {
        // update group
        setGroup(response.group);
        // set loading to false
        setIsLoading(false);
        setIsLeaveModalVisible(false);
        addToast("Group left!", "", "success");
        AnalyticsService.logEvent(EventType.Group.name, EventType.Group.leave);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsLeaveModalVisible(false);
        console.log(error);
        addToast("Unable to leave group.", "", "danger");
      });
  };

  const onCancelSubscription = () => {
    setIsLoading(true);
    cancelMySubscription({
      onSuccess: () => {
        setIsLoading(false);
        setIsDisplayCancelSubscription(false);
        addToast("Subscription cancelled successfully", "");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      onError: (err: any) => {
        console.log(err);
        setIsLoading(false);
        setIsDisplayCancelSubscription(false);
        addToast("Failed to cancel subscription", "", ToastTypes.danger);
      },
    });
  };
  return (
    <>
      {/* group name when tab is selected */}
      {activeTabModel ? (
        <PrefixedLink
          to={getGroupPageRoute(group.id)}
          className="my-2 block font-semibold">
          {group.name}
        </PrefixedLink>
      ) : (
        <></>
      )}
      {/* group info */}
      <div className="GroupInfo rounded theme-bg-surface mb-4">
        {group.banner && !group.color ? (
          <div className="GroupBanner overflow-hidden relative top-0 left-0 rounded-t">
            <LazyImage
              src={group.banner}
              alt="Group Banner"
              className="absolute top-0 left-0 object-cover rounded-t w-full"
            />
          </div>
        ) : (
          <></>
        )}
        {/* group title */}
        <div className="flex flex-col sm:flex-row items-stretch  justify-between">
          <div
            className={cx(
              "font-bold theme-text-heading-1 p-4 pb-3 overflow-ellipsis",
              {
                "text-xl": activeTabModel,
                "text-2xl": !activeTabModel,
              }
            )}>
            {activeTabModel
              ? activeTabModel.emoji + " " + activeTabModel.name
              : group.name}
          </div>
          <div className="flex items-center">
            {/* join / invite button, on group level only */}
            <div className="mx-2">
              {group.joinStatus === "joined" &&
              ["admin", "moderator"].includes(group.myRole ?? "") ? (
                activeTabModel ? (
                  <></>
                ) : (
                  <button
                    onClick={(e) => {
                      setActiveInvite(true);
                    }}
                    className="border theme-border-primary theme-text-primary font-semibold rounded px-5 py-2 focus:outline-none">
                    <I18>Invite Members</I18>
                  </button>
                )
              ) : group.joinStatus === "joined" ? (
                <></>
              ) : (
                <JoinButton group={group} setGroup={setGroup} user={user} />
              )}
            </div>
            {
              // show menu only if joined
              group.joinStatus === "joined" ? (
                <div className="flex-1 flex place-content-end">
                  <Fold
                    value={group.myRole === "admin"}
                    ifPresent={() => (
                      <IconMenu2
                        actions={[
                          // @ts-ignore
                          isMobile && {
                            // show share option
                            icon: "user",
                            label: "Group members",
                            onClick: () => {
                              setShowMembersList(true);
                            },
                          },

                          {
                            // show share option
                            icon: "share",
                            label: "Share",
                            onClick: () => {
                              setShowShareModal(true);
                            },
                          },
                          {
                            // show embed link if active modal
                            icon: "globe",
                            label: activeTabModel ? "Embed Channel" : null,
                            onClick: () => {
                              setEmbedModalVisible(true);
                            },
                          }, // now other options for moderator or admin
                          {
                            // for tab menu
                            icon: "edit",
                            label: activeTabModel ? "Edit Channel" : null,
                            onClick: () => {
                              setIsEditingTab(true);
                            },
                          },
                          {
                            // for section delete
                            icon: "trash",
                            label: activeTabModel ? "Delete Channel" : null,
                            onClick: () => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete the channel?"
                                )
                              ) {
                                setIsLoading(true);
                                GroupService.deleteGroupTab(
                                  user,
                                  group.id,
                                  activeTabModel?.id
                                )
                                  .then(({ group }) => {
                                    // update the group
                                    setIsLoading(false);
                                    setGroup(group);
                                  })
                                  .catch((error) => {
                                    console.log({ error });
                                    alert("Something went wrong!");
                                    setIsLoading(false);
                                  });
                              }
                            },
                            actionType: "alert",
                          }, // now show options that are only available to admin when no section is selected
                          {
                            // edit group
                            icon: "edit",
                            label: !activeTabModel ? "Edit group" : null,
                            onClick: () => {
                              setIsCreateGroupModalVisible(true);
                            },
                          },
                          {
                            // delete group
                            icon: "trash",
                            label: !activeTabModel ? "Delete group" : null,
                            onClick: () => {
                              // delete group
                              setIsDeleteModalVisible(true);
                            },
                            actionType: "alert",
                          },
                          {
                            // leave button for normal user
                            icon: "sign-out",
                            label: group.myRole !== "admin" ? "Leave" : null,

                            onClick: (e) => {
                              setIsLeaveModalVisible(true);
                            },
                            actionType: "alert",
                          },
                        ]}
                      />
                    )}
                    ifAbsent={() => (
                      <IconMenu2
                        actions={[
                          // @ts-ignore
                          isMobile && {
                            // show share option
                            icon: "user",
                            label: "Group members",
                            onClick: () => {
                              setShowMembersList(true);
                            },
                          },
                          {
                            // show share option
                            icon: "share",
                            label: "Share",
                            onClick: () => {
                              setShowShareModal(true);
                            },
                          },

                          {
                            // Change the current plan
                            // It will be visible only if user has subscription for current group.
                            // If user click on this button, user can proceed to buy a new plan.
                            // Current plan will be cancelled and user will be charged for the next billing cycle.
                            label: isSubscriptionPurchased
                              ? "Change Plan"
                              : null,

                            onClick: (e) => {
                              setBuyNowModal(true);
                            },
                          },
                          {
                            // Change the current plan.
                            // It will be visible only if user has subscription for current group.
                            // If user click on this button, it will cancel the subscription and user won't be charged for the next billing cycle.
                            label: isSubscriptionPurchased
                              ? "Cancel subscription plan"
                              : null,

                            onClick: (e) => {
                              setIsDisplayCancelSubscription(true);
                            },
                            actionType: "alert",
                          },

                          {
                            // Leave group button for user without subscription
                            // It will be visible only if group is not a part of subscription
                            icon: "sign-out",
                            label: isSubscriptionPurchased ? null : "Leave",

                            onClick: (e) => {
                              setIsLeaveModalVisible(true);
                            },
                            actionType: "alert",
                          },
                        ]}
                      />
                    )}
                  />
                </div>
              ) : (
                <></>
              )
            }
            {/* edit tab model */}
            <CreateSectionModal
              user={user}
              activeGroup={group}
              // @ts-ignore
              activeTab={activeTabModel}
              active={isEditingTab}
              setActive={setIsEditingTab}
              updateActiveGroup={setGroup}
            />
            {/* Delete tab model */}
            {/* @ts-ignore */}
            <ActionModal
              active={
                group.myRole !== "admin"
                  ? isLeaveModalVisible
                  : isDeleteModalVisible
              }
              setActive={
                group.myRole !== "admin"
                  ? setIsLeaveModalVisible
                  : setIsDeleteModalVisible
              }
              onSubmit={
                group.myRole !== "admin" ? onSubmitLeave : onSubmitDelete
              }
              header={`${group.myRole !== "admin" ? "Leave" : "Delete"} Group`}
              title={`Are you sure you want to ${
                group.myRole !== "admin" ? "leave" : "delete"
              } the group?`}
              isLoading={isLoading}
              btnColor="red"
            />
            <ShareTextModal
              heading="Share Group"
              // text={window.location.origin}
              text={createGroupPageUrlFromSlug(group)}
              active={showShareModal}
              setActive={setShowShareModal}
              onClose={(e: any) => {
                setShowShareModal(false);
              }}
            />
            <MembersListModal
              setActive={setShowMembersList}
              heading="Group members"
              active={showMembersList}
              groupId={group.id}
            />
            <GroupInviteModal
              group={group}
              active={activeInvite}
              setActive={setActiveInvite}
              community={community}
              user={user}
            />
            <GroupCheckoutComponents
              community={community}
              user={user}
              plans={plans}
              header={group?.name}
              setActive={setBuyNowModal}
              active={buyNowModal}
              mySubscription={mySubscription}
              socket={socket}
            />
            <NewActionModal
              title={"Are you sure you want to cancel your subscription?"}
              active={displayCancelSubscription}
              setActive={setIsDisplayCancelSubscription}
              btnColor="red"
              onCancelSubmit={() => {
                setIsDisplayCancelSubscription(false);
              }}
              onSubmit={() => {
                onCancelSubscription();
              }}
              header="Cancel Subscription"
              isLoading={isLoading}
              width={400}
              labelCancel="No, keep my subscription"
              labelSubmit="Cancel my subscription"
              subTitle="You will lose access to all the premium features of the subscription plan."
            />
            {isLoading ? <Loader /> : <></>}
          </div>
        </div>
        {/* group description */}
        {activeTabModel ? (
          <></>
        ) : (
          <div
            className={cx("p-4", {
              hidden: !Validator.hasValue(group.description),
            })}>
            <NL2BR text={group.description} />
            {/* <div
              className="NL2BR headings"
              dangerouslySetInnerHTML={{ __html: group.description }}></div> */}
          </div>
        )}
      </div>
      {/* group edit modal */}
      <CreateGroupModal
        // @ts-ignore
        group={group}
        updateGroup={(group) => {
          addToast("Group updated!", "", "success");
          setGroup(group);
        }}
        active={isCreateGroupModalVisible}
        setActive={setIsCreateGroupModalVisible}
      />
      {/* embed modal */}
      {activeTabModel ? (
        <EmbedLinkModal
          link={
            process.env.REACT_APP_EMBED_URL +
            (window.location.port ? ":" + window.location.port : "") +
            "/group/" +
            group.id +
            "/feed/" +
            activeTabModel.id
          }
          active={isEmbedModalVisible}
          setActive={setEmbedModalVisible}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export const GroupInfo = connect(
  (s: RootState) => ({
    user: s.auth,
    activeGroup: s.activeGroup,
    groups: s.groups,
    community: s.community,
    mySubscription: s.mySubscription,
    subscriptionsPlan: s.subscriptionsPlan,
    socket: s.socket,
  }),
  (d) =>
    bindActionCreators(
      {
        cancelMySubscription: cancelMySubscription,
        updateGroups: setGroups,
        updateActiveGroup: removeActiveGroup,
      },
      d
    )
)(GroupInfoComponent);
