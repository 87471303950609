import cx from "classnames";
import React from "react";
import I18 from "./atoms/i18";
interface Props {
  tab: string;
  selected: Boolean;
  disabled?: Boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

function Tab({ tab, selected, onClick, className, disabled = false }: Props) {
  return (
    <div className={cx({ "cursor-not-allowed": disabled })}>
      <div
        key={tab}
        className={cx(
          "tab hover:border-b-2 rounded-t px-4 py-1 font-semibold hover:theme-bg-primary-light cursor-pointer hover:theme-border-primary theme-text-primary select-none transition duration-500 ease-in-out" +
            className,
          {
            "border-b-2 theme-border-primary": selected,
          },
          {
            "pointer-events-none": disabled,
          }
        )}
        onClick={onClick}>
        <I18>{tab}</I18>
      </div>
    </div>
  );
}
export default Tab;
