export const SET_COMMUNITY_PAC = "SET_COMMUNITY_PAC";

/**
 * Set community pac
 * @param {*} pac
 * @returns
 */
export function setCommunityPac(pac = null) {
  return {
    type: SET_COMMUNITY_PAC,
    pac: pac,
  };
}
