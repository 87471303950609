import { useEffect } from "react";
import { connect } from "react-redux";

import { withToast } from "../../contexts/toastr.context";

import { useAppService } from "../../hooks/use-app-service";
import { Community } from "../../types/community/community.type";
import { ProfileUpdate } from "../../_components/profile/profile-update.component";
export const USER_PROFILE_UPDATE_PAGE_ROUTE = "/settings/profile";

interface IUser {
  bio?: string;
  picture?: string;
  [key: string]: any;
}
interface SocialHandles {
  [key: string]: string | undefined;
}

interface ComponentProps {
  addToast: any;
  community: Community;
  user: IUser;
}
function Component({ addToast, community, user }: ComponentProps) {
  const { analyticsService } = useAppService();

  useEffect(() => {
    // call edit profile event once while editing user profile
    const socialHandleProperties = [
      "instagramLink",
      "linkedinLink",
      "youtubeLink",
      "twitterLink",
      "facebookLink",
      "pintrestLink",
      "dribbbleLink",
      "behanceLink",
      "webLink",
    ];

    const socialHandles: SocialHandles = {};

    socialHandleProperties.forEach((property) => {
      if (user && user[property]) {
        const handleName = property.replace("Link", "");
        socialHandles[handleName] = user[property];
      }
    });
    console.log(socialHandles)
    const eventData = {
      bio: user?.bio,
      profilePic: user?.picture,
      name: user?.name,
      subTitle: user?.shortBio,
      socialHandles,
    };
    analyticsService.track("edit-profile", eventData);
  }, [analyticsService, user]);
  return (
    <div className="UserProfileUpdatePage rounded pensil-card theme-bg-surface shadow">
      <div className="main-content pt-4">
        <ProfileUpdate
          isFullScreen={false}
          community={community}
          addToast={addToast}
          visible={true}
          setVisible={(e: any) => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
}

const stp = (state: any) => ({
  user: state.auth,
  community: state.community,
});

const UserProfileUpdatePage = withToast(connect(stp)(Component));

export default UserProfileUpdatePage;
