import { isAfter, isBefore } from "date-fns";
import moment from "moment";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { history } from "../../_config";
import {
  createEventDetailSlugPageRoute,
  formatEventTime,
} from "../../_pages/event-detail.page";
import { EventService } from "../../_service/event.service";
import { ActionEventModal } from "../action-event-modal.component";
import { ActionModal } from "../action-modal.component";
import I18 from "../atoms/i18";
import LazyImage from "../atoms/lazy-image";
import { Avatar } from "../avatar.component";
import IconMenu2 from "../icon-menu-2.component";
import { Loader } from "../loader.component";
import Tab from "../tab.component";
import { useAppService } from "../../hooks/use-app-service";
import { EventCreateModel } from "../../_components/event/create-event-modal-component";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

/**
 * GET upcoming and past events
 * @param {*} param0
 * @returns
 */

const TabButton = Object.freeze({ upcoming: 1, past: 2 });

function EventCardListComponent({ user, addToast, community, isEventAdded }) {
  const [openTab, setOpenTab] = useState(TabButton.upcoming);
  const [upcomingEventsList, setUpcomingEventsList] = useState([]);
  const [previousEventsList, setPreviousEventsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // helps with pagination
  const [isLoadingMoreUpcomingEvents, setIsLoadingMoreUpcomingEvents] =
    useState(false);
  const [noMoreUpcomingEvents, setNoMoreUpcomingEvents] = useState(false);
  const [isLoadingMorePastEvents, setIsLoadingMorePastEvents] = useState(false);
  const [noMorePastEvents, setNoMorePastEvents] = useState(false);

  // edit,share,delete
  const [activeModel, setActiveModel] = useState(false);
  const [event, setEvent] = useState();
  const [isShowAttendee, setIsShowAttendee] = useState(false);
  const [isShowInvite, setIsShowInvite] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [notifyUser, setNotifyUser] = useState(false);
  const [isNotifyModalVisible, setIsNotifyModalVisible] = useState(false);

  const upInnerRef = useRef();
  const pastInnerRef = useRef();

  const { analyticsService } = useAppService();

  useEffect(() => {
    getUpcomingEvents("");
    getPreviousEvents("");
  }, [isEventAdded]);

  useEffect(() => {
    if (notifyUser) {
      onSubmitDelete(false);
    }
  }, [notifyUser]);

  const getUpcomingEvents = (timestamp = null) => {
    if (community.id) {
      setIsLoading(true);
      EventService.upcomingEvents(user, community.id, timestamp)
        .then((response) => {
          setUpcomingEventsList(response.events);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          addToast("Error getting upcoming events", error);
          addToast("Events could not be fetched!", "", ToastTypes.danger);
        });
    }
  };

  const getPreviousEvents = (timestamp = null) => {
    if (community.id) {
      setIsLoading(true);
      EventService.previousEvents(user, community.id, timestamp)
        .then((response) => {
          setPreviousEventsList(response.events);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          addToast("Error getting previous events", error);
          addToast("Events could not be fetched!", "", ToastTypes.danger);
        });
    }
  };

  const onScrollUpcoming = () => {
    if (upInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = upInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // Event lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreUpcomingEvents && !noMoreUpcomingEvents) {
          let lastElement = upcomingEventsList[upcomingEventsList.length - 1];
          let timestamp = lastElement.startTime;
          // if we are not already loading more Events, load more Events
          setIsLoadingMoreUpcomingEvents(true);
          EventService.upcomingEvents(user, community.id, timestamp)
            .then((response) => {
              if (response.events.length === 0) {
                setNoMoreUpcomingEvents(true);
              } else {
                setUpcomingEventsList([
                  ...upcomingEventsList,
                  ...response.events,
                ]);
              }
              setIsLoadingMoreUpcomingEvents(false);
            })
            .catch((error) => {
              addToast("Events could not be fetched!", "", ToastTypes.danger);
            });
        }
      }
    }
  };
  const onScrollPrevious = () => {
    if (pastInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = pastInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // Event lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMorePastEvents && !noMorePastEvents) {
          let lastElement = previousEventsList[previousEventsList.length - 1];
          let timestamp = lastElement.startTime;
          // if we are not already loading more Events, load more Events
          setIsLoadingMorePastEvents(true);
          EventService.previousEvents(user, community.id, timestamp)
            .then((response) => {
              if (response.events.length === 0) {
                setNoMorePastEvents(true);
              } else {
                setPreviousEventsList([
                  ...previousEventsList,
                  ...response.events,
                ]);
              }
              setIsLoadingMorePastEvents(false);
            })
            .catch((error) => {
              addToast("Events could not be fetched!", "", ToastTypes.danger);
            });
        }
      }
    }
  };

  const onSubmitDelete = (isPast) => {
    EventService.deleteEvent(user, community.id, event.id, notifyUser)
      .then(async (response) => {
        // delete event
        let upcomingEvents = upcomingEventsList;
        let pastEvents = previousEventsList;
        if (isPast === true) {
          let eventId = await pastEvents.findIndex((e) => e.id === event.id);
          pastEvents.splice(eventId, 1);
          await setPreviousEventsList(pastEvents);
        } else {
          let eventId = await upcomingEvents.findIndex(
            (e) => e.id === event.id
          );
          upcomingEvents.splice(eventId, 1);
          await setUpcomingEventsList(upcomingEvents);
        }
        analyticsService.track("event-delete")
        addToast("Event deleted!");
        setIsDeleteModalVisible(false);
        setIsNotifyModalVisible(false);
      })
      .catch((err) => {
        setIsDeleteModalVisible(false);
        setIsNotifyModalVisible(false);
        addToast("Event could not be deleted!", "", "danger");
      });
  };

  // should notifyUser
  const onSubmitNotify = () => {
    setNotifyUser(true);
  };

  // dont notifyUser
  const onCancelNotify = (isPast = false) => {
    onSubmitDelete(isPast);
  };

  return (
    <>
      {/* TAB View */}
      <div className="theme-bg-surface flex theme-border-default rounded-t items-center cursor-pointer">
        <div className="flex w-full items-center rounded-t theme-bg-surface tab-holder border-b theme-border-default shadow px-4">
          <div className="items-center">
            <Tab
              onClick={(e) => {
                if (openTab !== TabButton.upcoming) {
                  setIsLoadingMoreUpcomingEvents(false);
                  setNoMoreUpcomingEvents(false);
                  setOpenTab(TabButton.upcoming);
                }
              }}
              tab="UPCOMING"
              selected={openTab === TabButton.upcoming}
              className="text-center pb-3 pt-2"
            />
          </div>
          <div className="px-4">
            <Tab
              onClick={(e) => {
                if (openTab !== TabButton.past) {
                  setNoMorePastEvents(false);
                  setIsLoadingMorePastEvents(false);
                  setOpenTab(TabButton.past);
                }
              }}
              tab="PAST"
              selected={openTab === TabButton.past}
              className="text-center pb-3 pt-2"
              // disabled={true}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center my-10">
          <Loader />
        </div>
      ) : null}
      {!isLoading &&
      upcomingEventsList.length === 0 &&
      openTab === TabButton.upcoming ? (
        <div className="no-post p-2 my-10 text-center">
          <div className="font-bold my-3">No upcoming events</div>
        </div>
      ) : null}
      {openTab === TabButton.upcoming ? (
        <div
          onScroll={onScrollUpcoming}
          ref={upInnerRef}
          className="event-content-list mt-4">
          {upcomingEventsList.length > 0
            ? upcomingEventsList.map((event, index) => (
                <EventListItem
                  event={event}
                  setEvent={setEvent}
                  key={index}
                  user={user}
                  community={community}
                  setIsShowInvite={setIsShowInvite}
                  setActiveModel={setActiveModel}
                  setIsDeleteModalVisible={setIsDeleteModalVisible}
                  upcomingEventsList={upcomingEventsList}
                />
              ))
            : null}
          {isLoadingMoreUpcomingEvents ? (
            <div className="flex justify-center align-center py-5">
              <Loader />
            </div>
          ) : null}
        </div>
      ) : null}
      {!isLoading &&
      previousEventsList.length === 0 &&
      openTab === TabButton.past ? (
        <div className="no-post p-2 my-10 text-center">
          <div className="font-bold my-3">No past events</div>
        </div>
      ) : null}
      {openTab === TabButton.past ? (
        <div
          onScroll={onScrollPrevious}
          ref={pastInnerRef}
          className="event-content-list mt-4">
          {previousEventsList.length > 0
            ? previousEventsList.map((event, index) => {
                let previousDate = "";
                let currentDate = "";
                if (index > 0) {
                  let previousEle = previousEventsList[index - 1];
                  previousDate = moment(previousEle.startTime).format(
                    "MMMM-YYYY"
                  );
                }
                currentDate = moment(event.startTime).format("MMMM-YYYY");
                return (
                  <div key={index} className="mt-2">
                    {previousDate !== currentDate ? (
                      <div className="py-2 font-bold pl-1 mx-4">
                        {moment(event.startTime).format("MMMM YYYY")}
                      </div>
                    ) : null}
                    <div
                      className={`
					    ${
                previousDate === currentDate ? "mt-3" : ""
              } cursor-pointer border theme-border-default mx-4 rounded`}
                      onClick={() => {
                        analyticsService.track("event-selected", {
                          eventStatus: "past"
                        })
                        history.push(getSegmentedPath(createEventDetailSlugPageRoute(event)))
                      }}>
                      <div className="theme-bg-surface rounded flex justify-between">
                        <div className="flex items-center">
                          {event.banner ? (
                            <div className="flex theme-bg-disable w-32 h-full">
                              <LazyImage
                                src={event.banner}
                                alt=""
                                className="object-cover rounded-l h-full w-32"
                              />
                            </div>
                          ) : null}
                          <div className="self-center	py-1 font-semibold py-2 px-3 mt-1">
                            <div className="flex">
                              {formatEventTime(event.startTime, event.endTime)}
                            </div>
                            <div className="font-semibold self-center overflow-auto whitespace-nowrap	 py-1">
                              {event.title && event.title.length > 70
                                ? event.title.substring(0, 67) + "..."
                                : event.title}
                            </div>
                            <div className="pt-2">
                              <Avatar user={event.host} size="30" />
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="">
                            {user &&
                              event.myCommunityRole === "admin" &&
                              isBefore(new Date(event.endTime), Date.now()) && (
                                <>
                                  <IconMenu2
                                    dropdownClassName="w-56"
                                    icon="menu-dots"
                                    actions={[
                                      {
                                        icon: "trash",
                                        label: "Delete",
                                        labelClass: "theme-text-danger",
                                        onClick: () => {
                                          setEvent(event);
                                          setTimeout(() => {
                                            setIsDeleteModalVisible(true);
                                          }, 100);
                                        },
                                        actionType: "alert",
                                      },
                                      {
                                        icon: "eye",
                                        label: "Show Attendees",
                                        onClick: () => {
                                          setEvent(event);
                                          setTimeout(() => {
                                            setIsShowAttendee(true);
                                            setActiveModel(true);
                                          }, 100);
                                        },
                                      },
                                    ]}
                                  />
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          {isLoadingMorePastEvents ? (
            <div className="flex justify-center align-center py-5">
              <Loader />
            </div>
          ) : null}
        </div>
      ) : null}
        <EventCreateModel
          active={activeModel}
          user={user}
          community={community}
          eventToUpdate={event}
          onUpdateEvent={(event) => {
            let upcomingEvents = upcomingEventsList;
            let eventId = upcomingEvents.findIndex((e) => e.id === event.id);
            upcomingEvents[eventId] = event;
          }}
          // onNewEventAdded={(event) => console.log("finally")}
          isShowAttendee={isShowAttendee}
          setIsShowAttendee={setIsShowAttendee}
          isShowInvite={isShowInvite}
          setIsShowInvite={setIsShowInvite}
          openModel={(val) => {
            setActiveModel(val);
          }}
        />
      <ActionModal
        active={isDeleteModalVisible}
        setActive={setIsDeleteModalVisible}
        onSubmit={() => {
          if (isAfter(Date.now(), new Date(event.endTime))) {
            onCancelNotify(true);
          } else {
            setIsDeleteModalVisible(false);
            setIsNotifyModalVisible(true);
          }
        }}
        header={"Delete Event"}
        title={"Are you sure you want to delete the event?"}
        isLoading={isLoading}
        btnColor="red"
      />
      <ActionEventModal
        active={isNotifyModalVisible}
        setActive={setIsNotifyModalVisible}
        onSubmit={onSubmitNotify}
        onCancelSubmit={onCancelNotify}
        labelCancel="No, its okay"
        labelSubmit="Yes, Notify them"
        title={"Do you wish to notify that event is cancelled?"}
        // subTitle={`${
        //   event.participantCount > 0
        //     ? `${event.participantCount} users wants to attend this event`
        //     : ""
        // } `}
        isLoading={isLoading}
        large
        btnColor="theme-bg-disabled"
      />
    </>
  );
}

function EventListItem({
  index,
  event,
  community,
  setEvent = (e) => {},
  upcomingEventsList,
  user,
  setIsShowInvite = (e) => {},
  setActiveModel = (e) => {},
  setIsDeleteModalVisible = (e) => {},
}) {
  let previousDate = "";
  let currentDate = "";
  if (index > 0) {
    let previousEle = upcomingEventsList[index - 1];
    previousDate = moment(previousEle.startTime).format("MMMM-YYYY");
  }
  currentDate = moment(event.startTime).format("MMMM-YYYY");

  const isStartAndEndSameDay = moment(event.startTime).isSame(
    moment(event.endTime),
    "day"
  );

  const { analyticsService } = useAppService();

  return (
    <div key={index} className="mt-2 rounded">
      {previousDate !== currentDate ? (
        <div className="py-2 font-bold pl-1 mx-4 ">
          {moment(event.startTime).format("MMMM YYYY")}
        </div>
      ) : null}
      <div
        className={`
						${
              previousDate === currentDate ? "mt-3" : ""
            } cursor-pointer border theme-border-default mx-4 rounded`}
        onClick={() => {
          analyticsService.track("event-selected", {
            eventStatus: "upcoming"
          })
          history.push(getSegmentedPath(createEventDetailSlugPageRoute(event)))
          }}>
        <div className="flex theme-bg-surface rounded justify-between">
          <div className="flex items-center">
            {event.banner ? (
              <div className="flex theme-bg-disable w-32 h-full">
                {/* <div className="absolute top-0 left-0 w-full h-full"> */}
                <img
                  src={event.banner}
                  alt=""
                  className="object-cover rounded-l h-full w-32"
                />
                {/* </div> */}
              </div>
            ) : null}
            <div className="self-center	font-semibold py-2 px-3">
              {/* event title */}
              <div className="font-bold self-center whitespace-nowrap text-lg">
                {event.title && event.title.length > 70
                  ? event.title.substring(0, 67) + "..."
                  : event.title}
              </div>
              {/* event time */}
              <div className="flex">
                {formatEventTime(event.startTime, event.endTime)}
              </div>
              {/* event time zone */}
              <span className="font-semibold">
                <I18>Timezone :</I18>&nbsp;
              </span>
              <span className="text-xs">
                {community.timezone ? community.timezone : "Asia/Kolkata"}
              </span>
              {/* user details */}
              <div className="pt-2">
                <Avatar user={event.host} size="30" />
              </div>
            </div>
          </div>

          <div className="flex">
            <div>
              {user &&
                (event.myCommunityRole === "admin" ||
                  event.createdBy.id === user.id) &&
                isAfter(new Date(event.startTime), Date.now()) && (
                  <>
                    <IconMenu2
                      icon="menu-dots"
                      actions={[
                        {
                          icon: "share",
                          label: "Share",
                          onClick: (e) => {
                            setEvent(event);
                            setTimeout(() => {
                              setIsShowInvite(true);
                              setActiveModel(true);
                            }, 100);
                          },
                        },
                        {
                          icon: "edit",
                          label: "Edit",
                          onClick: (e) => {
                            setEvent(event);
                            setTimeout(() => {
                              setActiveModel(true);
                            }, 100);
                          },
                        },
                        {
                          icon: "trash",
                          label: "Delete",
                          labelClass: "theme-text-danger",
                          onClick: () => {
                            setEvent(event);
                            setTimeout(() => {
                              setIsDeleteModalVisible(true);
                            }, 100);
                          },
                          actionType: "alert",
                        },
                      ]}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const EventCardList = connect((s) => ({
  user: s.auth,
  group: s.activeGroup,
  community: s.community,
}))(withToast(EventCardListComponent));

export default EventCardList;
