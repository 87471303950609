import { SubscriptionPlan } from "../../../../../types/subscription/subscription-plan";
import { TableBody } from "../../../../../_components/molecule/listview.component";
import Label from "../../../../../_components/typography/label.typography";
import { MemberTableRowShimmer } from "../../../invited-users-list";
import { Status } from "../../subscriptions-list/subscriptions-page";
import { ActiveSubscriptionMember } from "../subscription-detail-component";
import {
  ActiveMembersListTile,
  LogsListTile,
} from "./active-subscription-member-tile";

function TableHeader({ headers }: { headers: string[] }) {
  return (
    <thead className="text-xs text-right theme-text-headline-1 border">
      <tr>
        {headers.map((header) => (
          <th key={header} className="py-3 p-1 md:px-4 text-left">
            <Label variant="t1">{header}</Label>
          </th>
        ))}
      </tr>
    </thead>
  );
}

interface LogsListTileProps {
  status: Status;
  communityId: string;
  subscription: SubscriptionPlan;
  logs: Array<ActiveSubscriptionMember> | undefined;
}

/**
 * Displays the subscription logs.
 */
export function LogsDetailList({
  subscription,
  logs,
  communityId,
}: LogsListTileProps) {
  const headers = ["Invoice", "Member", "State", "Country", "Payment on"];

  return (
    <div className="mx-4">
      <table className="table-auto border theme-bg-surface rounded text-sm theme-text-subtitle-1 w-full">
        <TableHeader headers={headers} />
        <tbody>
          <TableBody
            items={logs}
            renderItem={(sub: ActiveSubscriptionMember, index: number) => (
              <LogsListTile
                subscription={subscription}
                key={index}
                plan={sub}
                communityId={communityId}
                onPlanUpdate={() => {}}
              />
            )}
            noItemsElement={
              <tr>
                <td colSpan={6} className="py-3 px-4 text-center h-96">
                  <Label variant="s1">No Logs Available</Label>
                </td>
              </tr>
            }
            placeholder={[...Array(10)].map((_, index) => (
              <MemberTableRowShimmer key={index} />
            ))}
          />
        </tbody>
      </table>
    </div>
  );
}

interface IActiveMembersListTileProps {
  status: Status;
  subscription: SubscriptionPlan;
  communityId: string;
  activeMembers: Array<ActiveSubscriptionMember> | undefined;
  onPlanUpdate: (plan: ActiveSubscriptionMember) => void;
}
/**
 * Active Members in Subscription
 */
export function ActiveSubscriptionMembers({
  activeMembers,
  subscription,
  status,
  communityId,
  onPlanUpdate,
}: IActiveMembersListTileProps) {
  const headers = [
    "Invoice",
    "Member",
    "State",
    "Country",
    "Started on",
    "Next renewal on",
  ];

  return (
    <div className="ActiveSubscriptionMembers mx-4 rounded">
      <table className="table-auto border theme-default-border theme-bg-surface rounded text-sm theme-text-subtitle-1 w-full">
        <TableHeader headers={headers} />
        <tbody>
          <TableBody
            items={activeMembers}
            loading={status === "loading"}
            renderItem={(sub: any, index: number) => (
              <ActiveMembersListTile
                subscription={subscription}
                key={index}
                plan={sub}
                communityId={communityId}
                onPlanUpdate={onPlanUpdate}
              />
            )}
            noItemsElement={
              <tr>
                <td colSpan={6} className="text-center h-96">
                  <Label variant="s1">No Active members</Label>
                </td>
              </tr>
            }
            placeholder={[...Array(10)].map((_, index) => (
              <MemberTableRowShimmer key={index} />
            ))}
          />
        </tbody>
      </table>
    </div>
  );
}
