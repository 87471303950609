import { SET_COMMUNITY } from "../_actions/community.actions";

/**
 * Community Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function communityReducer(state = null, action) {
  switch (action.type) {
    case SET_COMMUNITY:
      return action.community;
    default:
      return state;
  }
}
