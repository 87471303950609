import cx from "classnames";
import moment from "moment";
import useLang from "../../../hooks/use-lang.hook";
import plusIcon from "../../../_assets/vectors/plus-gray-icon.svg";
import quizOptionCorrect from "../../../_assets/vectors/quiz-option-correct-icon.svg";
import quizOption from "../../../_assets/vectors/quiz-option-icon.svg";
import quizQuestionRemoveIcon from "../../../_assets/vectors/quiz-question-remove-icon.svg";
import I18 from "../../atoms/i18";
import { InputSwitch } from "../../form-controls/input-switch.component";
import { FormError } from "../../form-error.component";

export const QUIZ_QUESTION_SKELETON = {
  statement: "",
  options: ["", "", "", ""],
  answer: "",
};

export function CreatePostPoll({
  errors,
  isQuestion = false,
  setIsQuestion = () => {},
  isQuiz = false,
  setIsQuiz = (e) => {},
  quizTitle = "",
  setQuizTitle = () => {},
  quizDuration = 10,
  setQuizDuration = () => {},
  quizInstruction = "",
  setQuizInstruction = () => {},
  quizQuestions = [],
  setQuizQuestions = () => {},
  endTime = null,
  setEndTime = () => {},
}) {
  const updateQuestion = (index, question) => {
    const newQuizQuestions = [...quizQuestions];
    newQuizQuestions.splice(index, 1, question);
    setQuizQuestions(newQuizQuestions);
  };

  return (
    <div className="CreatePostQuiz mt-5">
      {/* quiz switcher */}
      {/* <div
                className="theme-bg-surface p-4 flex justify-between items-center"
                style={{ margin: "1rem -1rem" }}>
                <span>Toggle to Quiz</span>
                <InputSwitch selected={isQuiz} onClick={setIsQuiz} />
            </div> */}
      {/* question switcher */}
      <div
        className="theme-bg-surface p-4 flex justify-between items-center"
        style={{ margin: "1rem -1rem" }}>
        <span>
          <I18>Toggle to question</I18>
        </span>
        <InputSwitch
          selected={isQuestion}
          alt
          onClick={(e) => {
            // check if isQuestion
            if (isQuestion) {
              // turning to poll, make the mods
              const newQuestion = { ...quizQuestions[0] };
              setQuizQuestions([newQuestion]);
            } else {
              // turning to question make the mods
              const newQuestion = { ...quizQuestions[0] };
              if (newQuestion.options.length > 4) {
                newQuestion.options.length = 4;
              }
              while (newQuestion.options.length < 4) {
                newQuestion.options.push("");
              }
              setQuizQuestions([newQuestion]);
            }
            setIsQuestion(!isQuestion);
          }}
        />
      </div>
      {/* questions list */}
      {quizQuestions.map((question, index) => (
        <div key={index} className="my-5">
          <QuizQuestion
            isQuestion={isQuestion}
            index={index}
            question={question}
            updateQuestion={updateQuestion}
          />
          <FormError
            error={
              errors &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].statement
            }
          />
          <QuizOptions
            index={index}
            question={question}
            isQuestion={isQuestion}
            errors={
              errors &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].options
            }
            updateQuestion={updateQuestion}
          />
          <FormError
            error={
              errors &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].answer
            }
          />
          {/* Add new poll option */}
          {!isQuestion ? (
            <div className="my-5">
              <button
                className="font-semibold text-xs flex justify-center items-center focus:outline-none"
                onClick={(e) => {
                  const newQuestion = { ...question };
                  newQuestion.options.push("");
                  updateQuestion(0, newQuestion);
                }}>
                <span className="rounded-full border theme-border-default w-5 h-5 flex justify-center items-center">
                  <img src={plusIcon} alt="Add Option" width="12px" />
                </span>
                <span className="px-2">
                  <I18>Add Option</I18>
                </span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
      {/* poll expires */}
      {!isQuestion ? (
        <div>
          <div className="theme-text-heading-2 font-semibold">
            <I18>Poll expire time</I18>
          </div>
          <div className="mt-2">
            <select
              type="text"
              placeholder="Enter Test Title here"
              className="block px-4 py-3 w-full rounded-lg border theme-border-default focus:outline-none theme-bg-surface"
              onChange={(e) => {
                setEndTime(moment().add(e.target.value, "h"));
              }}>
              <option value={12}>12 Hours</option>
              <option value={24}>1 Day</option>
              <option value={48}>2 Days</option>
              <option value={168}>1 Week</option>
              <option value={336}>2 Weeks</option>
              {/* 87600 is 10 years */}
              <option value={87600}>Unlimited</option>
            </select>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function QuizQuestion({ index, question, isQuestion, updateQuestion }) {
  let rows = question.statement.split("\n").length;
  rows = rows > 0 ? rows : 1;
  const lang = useLang();

  return (
    <div className="QuizQuestion">
      <div className="mb-4 flex">
        <span className="theme-text-heading-2 font-semibold">
          {isQuestion ? <I18>Question</I18> : <I18>Poll question</I18>}
        </span>
      </div>
      <textarea
        type="text"
        value={question.statement}
        onChange={(e) => {
          updateQuestion(index, {
            ...question,
            statement: e.target.value,
          });
          window.setTimeout(() => {
            e.target.style.height = "auto";
            e.target.style.height = e.target.scrollHeight + "px";
          }, 0);
        }}
        className="block w-full px-4 py-3 focus:outline-none border theme-border-default theme-bg-surface rounded-lg hover:shadow"
        rows={rows}
        placeholder={lang.trans("Enter Question")}
      />
    </div>
  );
}

function QuizOptions({ index, question, isQuestion, errors, updateQuestion }) {
  const lang = useLang();
  return (
    <div className="QuizOptions my-2">
      <span className="theme-text-heading-2 font-semibold">
        <I18>Options</I18>
      </span>
      {question.options.map((option, optionIndex) => (
        <div key={optionIndex} className="QuizOption my-2">
          <div className="flex">
            {/* show option to select answer if question */}
            {isQuestion ? (
              <img
                src={
                  option && question.answer === option
                    ? quizOptionCorrect
                    : quizOption
                }
                width="20px"
                alt="Quiz Option"
                className="cursor-pointer"
                onClick={(e) => {
                  // set this option as answer
                  updateQuestion(index, {
                    ...question,
                    answer: option,
                  });
                }}
              />
            ) : (
              <></>
            )}
            <input
              type="text"
              placeholder={lang.trans("Option") + " " + (optionIndex + 1)}
              className={cx(
                "block w-full px-4 py-3 focus:outline-none border theme-border-default theme-bg-surface rounded-lg hover:shadow",
                {
                  "ml-3": isQuestion,
                  "mr-3": !isQuestion,
                }
              )}
              value={option}
              onChange={(e) => {
                const newOptions = [...question.options];
                newOptions.splice(optionIndex, 1, e.target.value);
                updateQuestion(index, {
                  ...question,
                  options: newOptions,
                });
              }}
            />
            {isQuestion || optionIndex < 2 ? (
              <></>
            ) : (
              <img
                src={quizQuestionRemoveIcon}
                width="20px"
                alt="Remove option"
                className="cursor-pointer mx-2"
                onClick={(e) => {
                  // remove this option
                  const newQuestion = { ...question };
                  const { options } = newQuestion;
                  options.splice(optionIndex, 1);
                  newQuestion.options = options;
                  updateQuestion(0, newQuestion);
                }}
              />
            )}
          </div>
          <FormError
            error={errors && errors[optionIndex] && errors[optionIndex]}
          />
        </div>
      ))}
    </div>
  );
}
