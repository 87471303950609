/**
 * A global umami analytics service that can be used to track events.
 * @module AnalyticsService
 */
const EventType = Object.freeze({
  Post: {
    name: "Post",
    create: "Create",
    update: "Update",
    delete: "Delete",
    like: "Like",
    unlike: "Unlike",
    share: "Share",
    comment: {
      name: "Comment",
      create: "Create",
      update: "Update",
      delete: "Delete",
      reply: {
        name: "Reply",
        create: "Create",
        update: "Update",
        delete: "Delete",
      },
    },
  },
  User: {
    name: "User",
    create: "Create",
    update: "Update",
    login: "Login",
    logout: "Logout",
  },
  Group: {
    name: "Group",
    join: "Join",
    leave: "Leave",
  },
});
/**
 * @deprecated Use {@link AnalyticsService} instead.
 */
export default class AnalyticsService {
  /**
   * Tracks a click event.
   * @param {string} eventName - The name of the event. It can be any javascript event.
   * @param {any} eventValue - The value of the event.
   * @returns {void}
   * @example
   * AnalyticsService.trackClick('Post Like', 'click');
   * */
  static logEvent(eventName, eventValue) {
    if (!window.umami || window.umami === null) {
      console.warn("AnalyticsService: Analytics is not initialized.");
      return;
    }
    try {
      window.umami.trackEvent(eventValue, eventName);
      console.log("track", eventName, eventValue);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Tracks a page view.
   * @param {string} pageName - The name of the page.
   * @returns {void}
   * @example
   * AnalyticsService.logPageView('/home');
   * */
  static logPageView(pageName) {
    if (!window.umami || window.umami === null) {
      console.warn("AnalyticsService: Analytics is not initialized.");
      return;
    }
    try {
      window.umami.trackView(pageName);
      console.log("trackView", pageName);
    } catch (error) {
      console.log(error);
    }
  }
}

export { EventType };
