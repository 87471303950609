import cx from "classnames";
interface AlertStripProps {
  title?: string;
  message?: string;
  className?: string;
  warning?: boolean;
  error?: boolean;
  success?: boolean;
  info?: boolean;
}
export default function AlertStrip({
  title,
  message,
  className,
  warning,
  error,
  success,
  info,
}: AlertStripProps) {
  return (
    <>
      {/*<!-- Component: Warning Alert With Icon - Title - Text --> */}
      <div
        className={cx(
          "flex w-full items-start gap-4 rounded border-l-4   px-4 py-3 text-sm ",
          { "theme-border-warning": warning },
          { "theme-border-danger": error },
          { "theme-border-success": success },
          { "theme-border-info": info },
          className
        )}
        role="alert">
        {/*  <!-- Icon --> */}
        <Icon error={error} warning={warning} success={success} info={info} />
        {/*  <!-- Text --> */}
        <div>
          {title && (
            <h3 className="mb-2 font-semibold font-md theme-text-heading-1">
              {title}
            </h3>
          )}
          {message && (
            <p className="text-sm theme-text-subtitle-1">{message}</p>
          )}
        </div>
      </div>
      {/*<!-- End Warning Alert With Icon - Title - Text --> */}
    </>
  );

  function Icon({ error, warning, success, info }: any) {
    const errorIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="1.5"
        aria-labelledby="title-09 desc-09">
        <title id="title-09">Icon title</title>
        <desc id="desc-09">A more detailed description of the icon</desc>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const successIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="1.5"
        aria-labelledby="title-06 desc-06">
        <title id="title-06">Icon title</title>
        <desc id="desc-06">A more detailed description of the icon</desc>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const warningIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="1.5"
        aria-labelledby="title-07 desc-07">
        <title id="title-07">Icon title</title>
        <desc id="desc-07">A more detailed description of the icon</desc>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
    );
    const infoIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 shrink-0"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="1.5"
        aria-labelledby="title-08 desc-08">
        <title id="title-08">Icon title</title>
        <desc id="desc-08">A more detailed description of the icon</desc>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
        />
      </svg>
    );

    return (
      <div
        className={cx("", {
          "theme-text-warning": warning,
          "theme-text-danger": error,
          "theme-text-success": success,
          "theme-text-info": info,
        })}>
        {success && successIcon}
        {error && errorIcon}
        {warning && warningIcon}
        {info && infoIcon}
      </div>
    );
  }
}
