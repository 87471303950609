import React, { useEffect, useMemo, useState } from "react";
import { ToastTypes } from "../contexts/toastr.context";
import useLang from "../hooks/use-lang.hook";
import { createUserWallPageRoute } from "../_pages/user-wall.page";
import { CommunityService, GroupService } from "../_service";
import LeaderboardService from "../_service/leaderboard.service";
import { Fold } from "../_utils/extensions/typescript-utils";
import I18 from "./atoms/i18";
import { Avatar } from "./avatar.component";
import { Button } from "./button.component";
import IconButton2 from "./icon-button-2-component";
import IconMenu2 from "./icon-menu-2.component";
import { Loader } from "./loader.component";
import { Modal } from "./modal.component";
import { TextInput } from "./text-input.component";
import { getSegmentedPath } from "../_utils/segmented-community-util";

interface Props {
  community: any;
  group: any;
  user: any;
  contributors: Array<any>;
  onUpdateContributors: (e: any) => void;
  addToast: any;
  isCommunityLeaderBoard: Boolean;
}
/**
 * @description - Leaderboard Component for the community or group
 * @param {Object} community - Active  community object
 * @param {Object} group - Active group object
 * @param {Object} user - Active user object
 * @param {Array} contributors - contributors array for the community or group
 * @param {Function} onUpdateContributors - function to update contributors
 * @param {Boolean} isCommunityLeaderBoard - boolean to check if community leaderboard. Default is true
 */
export default function LeaderBoardComponent({
  community,
  group,
  user,
  addToast,
  contributors,
  onUpdateContributors,
  isCommunityLeaderBoard = true,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<any>();

  const [isLoadingMoreContributors, setIsLoadingMoreContributors] =
    useState(false);
  const [noMoreContributors, setNoMoreContributors] = useState(false);
  const [page, setPage] = useState(1);

  const innerRef = React.createRef<HTMLDivElement>();
  // const abortController = useMemo(() => new AbortController(), []);

  const recordLimit = 25;
  const communityId = community?.id;
  const groupId = group?.id;

  useEffect(() => {
    if (isCommunityLeaderBoard && communityId) {
      setIsLoading(true);
      const getLeaderboard = async () => {
        try {
          // first set of contributors
          const leaderboardData =
            await CommunityService.getLeaderboardV2Paginated(
              communityId,
              page,
              recordLimit
            );
          if (leaderboardData) {
            const rankedLeaderboardData = leaderboardData.leaderboard.map((contributor: any, index: any) => {
              return {
                rank: index < 9 ? `0${index + 1}` : index + 1,
                id: contributor.user.id,
                user: contributor.user,
                karmaPoints: contributor.karmaPoints,
              };
            });
            setList(rankedLeaderboardData);
          }
          setIsLoading(false);
        } catch (err) {
          console.log({ err });
          setIsLoading(true);
        }
      };
      getLeaderboard();
    }

    if (!isCommunityLeaderBoard && groupId) {
      setIsLoading(true);
      const getGroupLeaderboard = async () => {
        try {
          // first set of contributors
          const leaderboardData =
            await GroupService.getLeaderboardV2Paginated(
              groupId,
              page,
              recordLimit
            );
          if (leaderboardData) {
            const rankedLeaderboardData = leaderboardData.leaderboard.map((contributor: any, index: any) => {
              return {
                rank: index < 9 ? `0${index + 1}` : index + 1,
                id: contributor.user.id,
                user: contributor.user,
                karmaPoints: contributor.karmaPoints
              };
            });
            setList(rankedLeaderboardData);
          }
          setIsLoading(false);
        } catch (err) {
          console.log({ err });
          setIsLoading(true);
        }
      };
      getGroupLeaderboard();
      setIsLoading(false);
    }
  }, [communityId, groupId, isCommunityLeaderBoard]);


  function assignRankToMemberAndConcatenate(list1: any, list2: any) {
    let list = [...list1, ...list2];
    const detailsRows = list.map((row, index) => {
      return {
        ...row,
        rank: index < 9 ? `0${index + 1}` : index + 1,
      };
    });

    return detailsRows;
  }

  function assignRankToMemberSingleList(list1: any) {
    let list = [...list1];
    const detailsRows = list.map((row, index) => {
      return {
        ...row,
        rank: index < 9 ? `0${index + 1}` : index + 1,
      };
    });

    return detailsRows;
  }

  const onScroll = async () => {
    // console.log("scrolled", innerRef.current !== null);
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;

      // console.log(
      //   "scrollTop + clientHeight === scrollHeight",
      //   scrollTop + clientHeight === scrollHeight
      // );
      if (scrollTop + clientHeight === scrollHeight) {
        // Contributors list lazy loading you're at the bottom of the page
        // do this when we reach end
        // console.log("isLoadingMoreContributors", isLoadingMoreContributors);
        // console.log("noMoreContributors", noMoreContributors);
        // console.log(
        //   "BOTH:- ",
        //   !isLoadingMoreContributors && !noMoreContributors
        // );
        if (!isLoadingMoreContributors && !noMoreContributors) {
          const pageCount = page + 1;
          // if we are not already loading more Contributors, load more Contributors
          setIsLoadingMoreContributors(true);
          try {
            let leaderboardData;
            if (isCommunityLeaderBoard) {
              leaderboardData =
                await CommunityService.getLeaderboardV2Paginated(
                  communityId,
                  pageCount,
                  recordLimit
                );
            } else {
              leaderboardData = await GroupService.getLeaderboardV2Paginated(
                groupId,
                page,
                recordLimit
              );
            }
            if (leaderboardData.leaderboard.length === 0) {
              setNoMoreContributors(true);
            } else {
              let rankedLeaderboardData = leaderboardData.leaderboard.map((contributor: any, index: any) => {
                return {
                  rank: index < 9 ? `0${index + 1}` : index + 1,
                  id: contributor.user.id,
                  user: contributor.user,
                  karmaPoints: contributor.karmaPoints
                };
              });
              rankedLeaderboardData = assignRankToMemberAndConcatenate(list, rankedLeaderboardData)
              setList(rankedLeaderboardData);
              setPage(pageCount);
              setIsLoadingMoreContributors(false);
            }
          } catch (error) {
            console.error({ error });
            console.log(error);
            setIsLoading(false);
          }
        }
      }
    }
  };

  return (
    <div
      className="LeaderBoardComponent flex flex-col theme-bg-default"
      style={{ height: "100%" }}>
      <div
        className="flex-none flex items-center px-4 theme-bg-surface font-bold"
        style={{ height: "53px" }}>
        {isCommunityLeaderBoard ? (
          <span>
            {`${community.name} `}
            <I18>Leaderboard</I18>
          </span>
        ) : (
          <span>
            {`${group.name} `} <I18>Leaderboard</I18>
          </span>
        )}
      </div>
      <Fold
        value={isLoading}
        ifPresent={() => (
          <div className="flex flex-col items-center place-content-center h-96">
            <Loader />
          </div>
        )}
        ifAbsent={() => (
          <>
            <div
              className="flex-grow overflow-y-auto"
              style={{ height: "calc(100%-53px)" }}
              ref={innerRef}
              onScroll={onScroll}>
              {list &&
                list.map((contributor: any, index: any) => {
                  return (
                    <Contributor
                      key={index}
                      community={community}
                      group={group}
                      user={user}
                      contributor={contributor}
                      index={index}
                      addToast={addToast}
                      onUpdateActivity={(activity: any) => {
                        // update contributors array
                        const tempContributors = [...contributors];
                        const index = tempContributors.findIndex(
                          (p) => p.user.id === activity.user.id
                        );
                        if (index >= 0) {
                          tempContributors[index] = activity;
                          tempContributors.sort(
                            (a, b) => b.grandTotal - a.grandTotal
                          );
                          onUpdateContributors(tempContributors);
                        }

                        // Update list array
                        const tempList = [...list];
                        const listIndex = tempList.findIndex(
                          (p) => p.user.id === activity.user.id
                        );
                        if (listIndex >= 0) {
                          tempList[listIndex] = activity;
                          let rankedLeaderboardData = list.map(
                            (contributor: any, index: any) => {
                              if (index === listIndex) {
                                return {
                                  ...contributor,
                                  rank: index < 9 ? `0${index + 1}` : index + 1,
                                  karmaPoints: {
                                    ...contributor.karmaPoints,
                                    grandTotal:
                                      tempList[listIndex].karmaPoints
                                        ?.grandTotal,
                                    ["admin-assigned"]:
                                      tempList[listIndex].karmaPoints[
                                        "admin-assigned"
                                      ],
                                  },
                                };
                              } else {
                                return {
                                  ...contributor,
                                  rank: index < 9 ? `0${index + 1}` : index + 1,
                                };
                              }
                            }
                          );
                          rankedLeaderboardData.sort((a: any, b: any) => b.karmaPoints.grandTotal - a.karmaPoints.grandTotal);
                          rankedLeaderboardData = assignRankToMemberSingleList(rankedLeaderboardData);
                          setList(rankedLeaderboardData);
                        }
                      }}
                    />
                  );
                })}
            </div>
            {isLoadingMoreContributors && <Loader />}
          </>
        )}
      />
    </div>
  );
}

function Contributor({
  community,
  group,
  user,
  contributor,
  index,
  addToast,
  onUpdateActivity,
}: any) {
  const [active, setActive] = useState(false);
  const [isAddPoints, setIsAddPoints] = useState(true);
  return (
    <div className="Contributor group flex justify-between items-center font-semibold my-3  px-6 cursor-pointer hover:theme-bg-primary-light">
       <div
        onClick={() => {
          // open the user page
          window.open(getSegmentedPath(createUserWallPageRoute(contributor.user.id, "activity")));
        }}>
        <div className="flex justify-between  ">
          <span className="pr-2 font-bold">
            #{contributor.rank}
          </span>
          <div className=" flex flex-row gap-8">
            <Avatar user={contributor.user} extraInfo={undefined} size={25} />
          </div>
        </div>
      </div>

      <span className="font-bold flex  items-center ">
        {((contributor.karmaPoints?.grandTotal || 0) + (community?.configurables?.COMMUNITY_BASE_POINT_KEY) || 0)} <I18>points</I18>
        {["admin"].includes(community.myRole) && (
          <IconMenu2
            dropdownClassName="w-72"
            actions={[
              {
                icon: "plus",
                label: "Add custom points",

                onClick: (e: any) => {
                  setIsAddPoints(true);
                  setActive(true);
                },
              },
              {
                // change user role
                icon: "minus",
                label: "Subtract custom points",

                onClick: (e: any) => {
                  setIsAddPoints(false);
                  setActive(true);
                },
              },
            ]}
            hideOnEmpty={false}
            onClick={undefined}
          />
        )}
      </span>
      <AlterCustomPointsModal
        addToast={addToast}
        active={active}
        setActive={setActive}
        contributor={contributor}
        isAddPoints={isAddPoints}
        community={community}
        group={group}
        onUpdateActivity={onUpdateActivity}
      />
    </div>
  );
}

/**
 * Update user karma points
 * @param {Object} group -  group object
 * @param {Object} community - community object
 * @param {boolean} active - modal active state
 * @param {function} setActive - modal active state setter
 * @param {Object} contributor - contributor object
 * @param {boolean} isAddPoints - true if adding points, false if subtracting
 * @param {function} onUpdateActivity - callback function to update activity
 * @param {function} addToast - callback function to add toast
 */

export function AlterCustomPointsModal({
  group,
  community,
  active,
  setActive,
  contributor,
  isAddPoints = true,
  addToast,
  onUpdateActivity = (e: any) => {},
}: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [point, setPoint] = useState<any>();
  const lang = useLang();

  function updateKarmaPoints() {
    // Check if point is numeric or not using regular expression
    if (point && isNaN(Number(point))) {
      alert(lang.trans("Please enter a valid number"));
      return;
    }
    //  check if point is a not decimal point
    if (point && point.indexOf(".") > -1) {
      alert(lang.trans("Point cannot be a decimal point"));
      return;
    }
    // check if point has negative value
    if (point && point < 0) {
      alert(lang.trans("Point cannot be a negative value"));
      return;
    } else if (!isAddPoints && point > contributor.karmaPoints?.["admin-assigned"]) {
      alert(lang.trans("Points cannot be greater than current custom points"));
      return;
    }
    const karmaPoint = {
      groupId: group ? group.id : null,
      points: point * (isAddPoints ? 1 : -1),
    };

    setIsLoading(true);
    LeaderboardService.addCustomPoints(
      community.id,
      karmaPoint,
      contributor.user.id
    )
      .then((response) => {
        onUpdateActivity({
          ...contributor,
          grandTotal: contributor.grandTotal + parseInt(response?.userPoint?.points),
          customPoints: contributor.customPoints + parseInt(response?.userPoint?.points),
          karmaPoints: {
            ...contributor.karmaPoints,
            grandTotal: (contributor.karmaPoints.grandTotal || 0) + parseInt(response?.userPoint?.points),
            ["admin-assigned"]: (contributor.karmaPoints?.["admin-assigned"] || 0) + parseInt(response?.userPoint?.points)
          },
        })
        addToast("Karma points updated successfully");
      })
      .catch((e: any) => {
        console.log(e);
        addToast("Error in updating karma points", "", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
        setActive(false);
        setPoint(undefined);
      });
  }

  if (!active) {
    return null;
  }
  return (
    <Modal
      className="AlterCustomPointsModal theme-bg-default"
      padding={false}
      active={active}
      width="450px"
      setActive={(e) => {
        setPoint(undefined);
        setActive(false);
      }}>
      <div className="">
        <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
          <span className="font-bold theme-text-heading-1">
            {isAddPoints ? (
              <I18>Add custom karma points</I18>
            ) : (
              <I18>Minus custom karma points</I18>
            )}
          </span>
          <IconButton2
            hoverable={true}
            icon="cross"
            size="xs"
            iconClass="theme-text-subtitle-2 text-xs"
            onClick={() => {
              setActive(false);
            }}
          />
        </div>
        <div className="p-4">
          {/* User Info */}
          <div className=" space-y-2 theme-bg-surface rounded p-6">
            <Avatar user={contributor.user} extraInfo={undefined} size={35} />
            <div className="font-bold text-sm">
              <I18>Total custom karma points:</I18> {contributor.karmaPoints?.["admin-assigned"] || 0}{" "}
              <I18>points</I18>
            </div>
          </div>

          <div className="my-3">
            <h3 className="font-bold float-none">
              <I18>Add custom karma points</I18>
            </h3>
            <div className="flex justify-between items-center space-x-3">
              <span className="font-bold">
                {contributor.karmaPoints?.["admin-assigned"] || 0} &nbsp; {isAddPoints ? "+" : "-"}{" "}
              </span>
              <TextInput
                containerClassName="flex-grow p-2"
                placeholder="Enter no. of karma points"
                noMargin
                type="number"
                inputHolderClassName="p-2"
                onChange={(e) => {
                  setPoint(e);
                }}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              label="Cancel"
              onClick={() => {
                setActive(false);
              }}
              flat
            />

            <Button
              label={isAddPoints ? "Add points" : "Minus Points"}
              onClick={updateKarmaPoints}
              isLoading={isLoading}
              disabled={!point}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
