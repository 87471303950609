import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  sendDirectMessage: (userId) =>
    base + "chats/direct/" + userId + "/send-message",
  editDirectMessage: (messageId) =>
    base + "chats/direct/edit-message/" + messageId,
  deleteDirectMessage: (messageId) =>
    base + "chats/direct/delete-message/" + messageId,
  uploadAttachments: (userId) =>
    base + "chats/direct/" + userId + "/upload-attachment",
  markAllMessageAsRead: (userId) =>
    base + "chats/direct/" + userId + "/mark-all-read",
  chats: base + "chats/direct",
  userChat: (userId, lastId = null) =>
    base + "chats/direct/" + userId + "?lastId=" + lastId,
};
