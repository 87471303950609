export const csCzech = {
  "Pensil Keyword": "Czech",
  "Home Page Left column": "Domovská stránka Levý sloupec",
  Home: "Domovská stránka",
  "Explore Groups": "Prozkoumat skupiny",
  Events: "Události",
  Groups: "Skupiny",
  Links: "Odkazy",
  "Direct Message": "Přímé zprávy",
  "Powered by": "Vyvtvořeno od",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Zatím tady nejsou žádné skupiny, použijte prosím + k vytvoření nové nebo se k nějaké připojte pro její zobrazení zde.",
  "Please join a group to see them here":
    "Prosím připojte se k nějké skupině pro její zobrazení zde",
  "Section can only be reordered within the group!":
    "Sekce lze přeskupit pouze ve skupině!",
  "Group Page": "Skupinová stránka",
  "Invite Members": "Pozvat členy",
  Join: "Připojit se",
  Joined: "Připojil se",
  "Create new section": "Vytvořit novou sekci",
  "Group Setting drop down": "Rozbalování skupinového nastavení",
  Share: "Sdílet",
  "Edit group": "Upravit skupinu",
  "Delete group": "Smazat skupinu",
  Leave: "Opustit",
  "Are you sure you want to delete the group?":
    "Jste si jistí, že chcete smazat tuto skupinu?",
  Cancel: "Zrušit",
  "Yes, Go Ahead": "Ano, pokračovat",
  "Share via link": "Sdílet pomocí odkazu",
  Copy: "Kopírovat",
  Copied: "Zkopírováno",
  "Share on social media": "Sdílet na sociálních sítích",
  "Add Section": "Přidat sekci",
  "Section Header": "Hlavička sekce",
  Live: "Živé vysílání",
  "Create Post": "Vytvořit příspěvek",
  "Notificaiton Bell": "Zvoneček pro oznámení",
  "All posts": "Všechny příspěvky",
  "Admin posts only": "Pouze příspěvky správců",
  None: "Žádné",
  "Section Setting drop down": "Rozbalování nastavení sekce",
  "Edit Section": "Upravit sekci",
  "Embed Section": "Vložit sekci",
  "Delete Section": "Smazat sekci",
  "Are you sure you want to delete the section?":
    "Jste si jistí, že chcete smazat tuto sekci?",
  "Section deleted!": "Sekce smazána!",
  "Create Group Pop up / Update":
    "Vytvořit vyskakovací okno skupiny / Aktualizovat",
  "Create group": "Vytvořit skupinu",
  "Basic Detail": "Základní detaily",
  Settings: "Nastavení",
  "Size 16:9 or 1600 by 900": "Velikost 16:9 nebo 1600 na 900",
  "Group name": "Název skupiny",
  "Enter group name": "Vložte název skupiny",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Návrhy: Prostory, Kanály, Skupiny, Blog, Znalostní databáze atd.",
  "Group Description": "Popisek skupiny",
  "Enter description here": "Vložte popisek skupiny",
  "Group Type": "Typ skupiny",
  Open: "Otevřít",
  "Visible to all & anyone can join":
    "Viditelné pro všechny a všichni se můžou připojit",
  Closed: "Uzavřeno",
  "User will have to request to join the group":
    "Uživatel bude muset zažádat o připojení do skupiny",
  Paid: "Placené",
  "Group Price": "Skupinová cena",
  "Currency (Default: INR)": "Měna (Výchozí: INR)",
  "User will have to pay to join the group":
    "Uživatel bude muset zaplatit pro připojení do skupiny",
  "Make this a private group": "Změnit tuto skupinu na soukromou",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Soukromá skupina bude viditelná pouze pro členy, kteří jsou její součástí. Správce může přidat členy ručně ze seznamu členů",
  "Upload from device": "Nahrát ze zařízení",
  "Search image": "Hledat obrázky",
  "Valid group name required!": "Je vyžadováno platné jméno skupiny!",
  "Update Group": "Aktualizovat skupinu",
  "SEO Settings": "Nastavení SEO",
  "Custom URL": "Vlastní URL",
  "Meta Title": "Meta titulek",
  "Maximum length of 255 characters allowed":
    "Je povolena maximální délka 255 znaků",
  "Meta Description": "Meta popisek",
  "Open Graph Detail": "Otevřít detail grafu",
  "Open Graph Title": "Otevřít titulek grafu",
  "Open Graph Description": "Otevřít popisek grafu",
  "Open Graph Image": "Otevřít obrázek grafu",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Doporučené rozměry: 1200px na 630px s poměrem stran 1,91:1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Pokud není použita žádná adresa URL, automaticky bude vybrána miniatura vaší skupiny.",
  "Create Section Pop up / Update":
    "Vytvořit vyskakovací okno sekce / Aktualizovat",
  "Create Section": "Vytvořit sekci",
  "Section Name": "Název sekce",
  "Suggestions: General, Introductions, Community Guidelines":
    "Návrhy: Všeobecné, Instrukce, Pravidla komunity",
  "Section Type": "Typ sekce",
  "Post Type": "Typ příspěvku",
  "Group Chat": "Skupinový chat",
  "Who can post in this section?": "Kdo může v této sekci zveřejňovat?",
  "Only Admin": "Pouze admin",
  "Any user": "Jakýkoli uživatel",
  "Sorting order for section": "Způsob řazení v této sekci",
  "Latest first": "Od nejnovějších příspěvků",
  "Oldest post first": "Od nejstarších příspěvků",
  "Section Appearance": "Vzhled sekce",
  "List View": "Zobrazení v seznamu",
  "Grid View": "Zobrazení na mřížku",
  "Make this closed section": "Změnit tuto sekci na uzavřenou",
  "Members will have to request access to see section content":
    "Členové budou muset požádat o přístup k zobrazení obsahu sekce",
  "Make this a private section": "Změnit tuto sekci na soukromou",
  "Disables requests (Make sure closed section is selected as well).":
    "Zakázat žádosti (Ujistěte se, že je také vybrána uzavřená část).",
  "Post Editor": "Editor příspěvku",
  "Create new post in": "Vytvořit nový příspěvek v",
  "Add Cover Picture": "Přidat náhledový obrázek",
  "Heading (Optional)": "Nadpis (volitelné)",
  "Write here..": "Začněte psát tady...",
  Picture: "Obrázek",
  Video: "Video",
  Attachment: "Příloha",
  Poll: "Hlasování",
  "Embed Code": "Kód pro vložení",
  Tags: "Tagy",
  "Toggle to question": "Změnit na otázky",
  "Poll expire time": "Čas vypršení hlasování",
  "Poll question": "Otázka pro hlasování",
  "Enter Question": "Vložte otázku",
  Question: "Otázka",
  Option: "Možnost",
  Options: "Možnosti",
  "Add Option": "Přidat možnost",
  "Poll Expiry time": "Čas vypršení hlasování",
  "12 Hours": "12 hodin",
  "1 Day": "1 den",
  "2 Days": "2 dny",
  "1 Week": "1 týden",
  "2 Weeks": "2 týdny",
  "Always Open": "Vždy otevřené",
  "Write embeddable code": "Napište vkládatelný kód",
  "Click here to add embed code.": "Klikněte zde pro zadání kódu pro vložení.",
  "Paste your embed code here!": "Vložte svůj kód pro vložení zde!",
  "Post Settings": "Nastavení příspěvku",
  "Update Post": "Aktualizovat příspěvek",
  Update: "Aktualizovat",
  "Start meeting / Video call / Live":
    "Zahájit schůzku / Video hovor / Živé vysílání",
  "Meeting Name": "Název schůzky",
  "Suggestion: Group discussion etc": "Návrh: Skupinová diskuse atd.",
  Description: "Popisek",
  "Enter description here (optional)": "Zde zadejte popisek (volitelné)",
  "When do you want to start the session": "Kdy chcete zahájit sezení",
  "Right now": "Okamžitě",
  "Schedule for later": "Naplánovat na později",
  "Group video call": "Skupinový video hovor",
  Webinar: "Webinář",
  "Go Live": "Zahájit živé vysílání",
  "Info on the Post": "Informace o příspěvku",
  "Post comment": "Přidat komentář",
  Comment: "Komentář",
  Comments: "Komentáře",
  Reply: "Odpovědět",
  Replies: "Odpovědi",
  "Share via URL": "Sdílet pomocí URL",
  "Reshare in other sections": "Zasdílet v dalších sekcích",
  "Post Card Drop Down": "Rozbalování karty příspěvku",
  Bookmark: "Záložka",
  "Pin post": "Připnout příspěvek",
  Edit: "Upravit",
  "Embed Post": "Vložit příspěvek",
  Delete: "Smazat",
  "Remove Pin": "Odstranit připnutí",
  "Remove Bookmark": "Odstranit záložku",
  "Just copy paste this code wherever you want it to be embedded":
    "Stačí zkopírovat a vložit tento kód kamkoli chcete, aby byl vložen",
  Height: "Výška",
  Preview: "Náhled",
  "Copied to clipboard": "Zkopírováno do schránky",
  "Select Widgets": "Vyberte widgety",
  "Add widgets to": "Přidat widgety do",
  "You can change their order by dragging & dropping the widgets over each other":
    "Můžete změnit jejich pořadí přetažením a upuštěním widgetů na sebe",
  "Please note: Widget wont be displayed if there is no data available,":
    "Poznámka: Widget nebude zobrazen, pokud nejsou k dispozici žádná data,",
  "Group Members": "Členové skupiny",
  "Group Leaderboard": "Žebříček skupiny",
  "Community Leaderboard": "Žebříček komunity",
  "Trending Posts": "Trendující příspěvky",
  "Group Events": "Události skupiny",
  "Add Custom": "Přidat vlastní",
  "Save Widgets": "Uložit widgety",
  "Visible to user": "Viditelné pro uživatele",
  "Visible to admin": "Viditelné pro adminy",
  "Add widget to": "Přidat widget do",
  "Upload cover picture": "Nahrát náhledový obrázek",
  "1:1 aspect ration (recommended)": "Poměr stran 1:1 (doporučeno)",
  "Card Title": "Nadpis karty",
  "Enter widget name": "Vložte jméno widgetu",
  "Small Description (try to keep it under 50 words)":
    "Malý popisek (snažte se dodržet délku pod 50 slov)",
  "Enter description": "Vložte popisek",
  "Button Title": "Nadpis tlačítka",
  "Enter button name": "Vložte jméno tlačítka",
  "Siggestion: Know more, Join, Apply, etc":
    "Návrhy: Zjistit více, Připojit, Použít atd.",
  "URL link": "Odkaz URL",
  "Create custom widget": "Vytvořit vlastní widget",
  "Custom widget created successfully": "Vlastní widget úspěšně vytvořen",
  Widgets: "Widgety",
  Members: "Členové",
  "See All": "Zobrazit vše",
  "Communtiy Top Contributors": "Nejlepší přispěvatelé komunity",
  "Community Members": "Členové komunity",
  "Group Top Contributors": "Nejlepší přispěvatelé skupiny",
  "Upcoming Events": "Nadcházející akce",
  "Home Page Banner": "Banner domovské stránky",
  "Edit Banner": "Upravit banner",
  "Home page Edit Banner Pop up":
    "Domovská stránka Upravit banner Vyskakovací okno",
  "Update Banner": "Aktualizovat banner",
  Search: "Hledat",
  "Upload Image": "Nahrát obrázek",
  Remove: "Odstranit",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Obrázek banneru (prosím použijte poměr stran obrázku 16:6 nebo 1600px na 600px pro nejlepší zobrazení)",
  "Banner Title": "Nadpis banneru",
  "Enter here": "Vložte zde",
  "Suggestion: Welcome to my community etc":
    "Návrh: Vítejte do mé komunity atd.",
  "Banner Description": "Popisek banneru",
  "Enter banner description": "Vložit popisek banneru",
  "Save Banner Info": "Uložit informace o banneru",
  "Home Page Feed": "Feed domovské stránky",
  Recent: "Nedávné",
  "Top Posts": "Top příspěvky",
  Trending: "Trendující",
  "Home Feed Groups": "Feed skupiny",
  "Header BarProfile Drop down": "ZáhlavíRozjížděcí výběr profilů",
  "My Profile": "Můj profil",
  Logout: "Odhlásit se",
  Notification: "Oznámení",
  Notifications: "Oznámení",
  "Mark all viewed": "Označit vše jako zobrazené",
  "has created a post": "vytvořil/a příspěvek",
  "wants to join": "se chce připojit",
  "has commented on your post": "okomentoval/a váš příspěvek",
  "has replied to your comment": "odpověděl/a na váš komentář",
  "Search for tags": "Vyhledávat tagy",
  "All groups": "Všechny skupiny",
  "Search result": "Výsledky vyhledávání",
  "No post found for selected hashtag":
    "Žádný příspěvek nenalezen pro vybraný hashtag",
  "Start a conversation": "Zahájit konverzaci",
  "Search by name": "Vyhledávat podle jména",
  Upcoming: "Nadcházející",
  UPCOMING: "NADCHÁZEJÍCÍ",
  Past: "Minulé",
  PAST: "MINULÉ",
  "No upcoming events": "Žádné nadcházející události",
  "Create event popup": "Vytvořte vyskakovací okno události",
  "Event details": "Detaily události",
  "16:9 aspect ratio": "Poměr stran 16:1",
  "Event name": "Název události",
  "Enter event name": "Vložte název události",
  "Host Name": "Název hostitele",
  "You will be made host if none selected":
    "Pokud nikdo nevyberete, budete hostitelem vy",
  "Write here...": "Začněte psát tady...",
  "No. of registration for the event": "Počet možných registrací na údálost",
  Unlimited: "Neomezené",
  "When & where": "Kdy a kde",
  From: "Od",
  "Start date": "Datum začátku",
  time: "Čas",
  To: "Do",
  "End date": "Datum konce",
  "Current time zone:": "Aktuální časová zóna:",
  "Can be updated from the communtiy setting":
    "Může být změněno z nastavení komunity",
  "Online event": "Online událost",
  "Add meeting link": "Přidat odkaz na setkání",
  "Meeting link will be generated once event is created":
    "Odkaz na setkání bude vygenerován po vytvoření události",
  Venue: "Místo konání",
  "Enter address": "Vložte adresu",
  Create: "Vytvořit",
  Invite: "Pozvat",
  "Share event via link": "Sdílet událost pomocí odkazu",
  Copy__1: "Zkopírovat",
  "Select section to post this event":
    "Vyberte sekci pro zveřejnění této události",
  "Once posted, members of that group or section will recieve an notification":
    "Po zveřejnění obdrží členové této skupiny nebo sekce oznámení",
  Post: "Zveřejnit",
  RSVP: "Prosím odpovězte",
  "Book Event": "Registrovat se na událost",
  "Yes, I'll attend": "Ano, zúčastním se",
  "May be": "Možná",
  "No, I won't attend": "Ne, nezúčastním se",
  "Date & Time": "Datum a čas",
  Host: "Hostitel",
  "Online meeting": "Online setkání",
  "Meeting link will be visible once registered for the event":
    "Odkaz na setkání bude viditelný po registraci na akci",
  "User Management": "Správa uživatelů",
  "Go bac to community": "Vrátit se do komunity",
  "Manage Members": "Správa členů",
  Member: "Čeln",
  Invited: "Pozvaný",
  "Search for member": "Hledat člena",
  "Add to group": "Přidat do skupiny",
  "Send Direct Message": "Odeslat přímou zprávu",
  "Change role": "Změnit roli",
  "Kick from community": "Vyhodit z komunity",
  "Super Admin": "Super admin",
  "Super admin will have full access to the communtiy settings":
    "Super admin bude mít plný přístup k nastavení komunity",
  "Group Admin": "Skupinový admin",
  "Admin of the selected group can edit the group information and create, edt or delete section under selected group":
    "Správce vybrané skupiny může upravit informace o skupině a vytvořit, upravit nebo odstranit sekci ve vybrané skupině",
  "Group Moderator": "Moderátor skupiny",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Moderátor skupiny může ve vybrané skupině vytvářet nebo smazat příspěvek, komentář, odpověď a zprávy.",
  User: "Uživatel",
  Save: "Uložit",
  "User role updated successfully": "Role uživatele úspěšně aktualizována",
  Leaderboard: "Žebříček",
  "Click on the column header for sorting":
    "Klikněte na záhlaví sloupce pro seřazení",
  Rank: "Úroveň",
  "Karma Points": "Karma body",
  "Custom Points": "Vlastní body",
  Follow: "Sledovat",
  Upvotes: "To se mi líbí",
  Posts: "Příspěvky",
  "Add custom points": "Přidat vlastní body",
  "Subtract custom points": "Odebrat vlastní body",
  "Enter no. of points": "Zadat počet bodů",
  "Add points": "Přidat body",
  "Invite members to": "Pozvat člen do",
  "Invite via link": "Pozvat pomocí odkazu",
  "Invite via email": "Pozvat pomocí emailu",
  "Add more": "Přidat více",
  "Upload a CSV": "Nahrát CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Nahrajte prosím soubor CSV oddělený čárkami, se záhlavím jako název, e-mail.",
  "Here is a template CSV file": "Zde je šablona CSV souboru",
  "Upload File": "Nahrát soubor",
  "Send Invite": "Odeslat pozvánky",
  "Mail Template": "Šablona emailu",
  "Send Test Mail": "Odeslat testovací email",
  Hey: "Ahoj",
  "A has invite you to join the B": "A vás pozval abyste se připojili k B",
  "Switch to rich text editor": "Přepnout na bohatý textový editor",
  "Add Custom Message": "Vložit vlastní zprávu",
  "Enter message here": "Vložte zprávu zde",
  "Send Bulk Mail": "Odeslat hromadný email",
  "Send Mail": "Odeslat email",
  "Send bulk mail to all community members or select group members":
    "Odeslat hromadný email všem členům komunity nebo členům vybrané skupiny",
  Date: "Datum",
  Subject: "Předmět",
  Recipients: "Příjemci",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "Tento měsíc zbývá 1000 hromadných email. Resetuje se <date>",
  Everyone: "Všichni",
  "Enter mail subject": "Vložit předmět emailu",
  "Write message": "Napsat zprávu",
  Cancel__1: "Zrušit",
  Send: "Odeslat",
  "Profile Page": "Profilové stránka",
  Activity: "Aktivita",
  Bookmarks: "Záložky",
  "Edit Profile": "Upravit profil",
  Unsubscribe: "Odhlásit odběr",
  "Settings (Account)": "Nastavení (Účet)",
  Profile: "Profil",
  "Set profile Picture": "Nastavit profilový obrázek",
  "Your name": "Vaše jméno",
  "User ID": "ID uživatele",
  "Short bio": "Krátké informace o sobě",
  "Link your social account, this will be displayed on your profile":
    "Propojte svůj sociální účet, toto se zobrazí na vašem profilu",
  "Add social handle": "Přidat odkaz na sociální síť",
  Verification: "Ověření",
  "Account Verification": "Ověření účtu",
  Email: "Email",
  "Enter email": "Zadejte email",
  Verified: "Ověřeno",
  Unverified: "Neověřeno",
  Change: "Změnit",
  Unlink: "Odebrat propojení",
  Mobile: "Mobil",
  "enter mobile no.": "Zadejte tel. číslo",
  "Request OTP": "Zažádat o jednorázové heslo",
  "Notification centre": "Centrum oznámení",
  "Manage your personal account notification preferences":
    "Spravujte předvolby oznámení vašeho osobním účtu",
  Prefrences: "Předvolby",
  "Email notification": "Emailová oznámení",
  "Notify me about comments on my posts":
    "Informujte mě o komentářích k mým příspěvkům",
  "Notify me about replies on my comments":
    "Informujte mě o odpovědích na mé komentáře",
  "Notify me about upvotes on my posts":
    "Informujte mě o to se mi líbí na mých příspěvcích",
  "Notify me about reaction on my comments":
    "Informujte mě o reakci na mé komentáře",
  "Notify me about reaction on my reply":
    "Informujte mě o reakci na mou odpověď",
  "Notify me about direct message": "Informujte mě o přímých zprávách",
  "Notify me about my mentions": "Informujte mě o mých zmínkách",
  "Notification for new posts": "Oznámení o nových příspěvcích",
  "Only group & section available which you are part of":
    "Pouze skupiny a sekce, kterých jste členy",
  "Group & Section": "Skupiny a sekce",
  "All activity": "Veškerá aktivita",
  "Post by admin": "Přidáno adminem",
  "No Notification": "Žádné oznámení",
  "Setting (Community)": "Nastavení (Komunity)",
  "General Settings": "Všeobecná nastavení",
  "Upload community logo": "Nahrát logo komunity",
  "Community name": "Název komunity",
  "Invalid field": "Neplatné pole",
  "Your community address": "Adresa vaší komunity",
  "Fav icon": "Fav ikona",
  "This will be visible on the browser tab (32px by 32px)":
    "Zobrazí se na záložce prohlížeče (32px na 32px)",
  TimeZone: "Časová zóna",
  "Privacy Policy on login page":
    "Zásady ochrany osobních údajů na přihlašovací stránce",
  "Terms & Condition on login page":
    "Podmínky používání na přihlašovací stránce",
  "Enter link here": "Zadejte odkaz zde",
  "Open Group Image": "Otevřete obrázek skupiny",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Ve výchozím nastavení zabírá komunitní banner nahraný v komunitě",
  "Community Experience": "Komunitní zkušenosti",
  Onboarding: "Onboarding",
  "Make your community private": "Změňte vaši skupinu na soukromou",
  "Users will have to login or sign up to access the community":
    "Uživatelé se budou muset přihlásit nebo se zaregistrovat pro přístup ke komunitě",
  "Make account verification mandatory for both email & mobile":
    "Nastavit ověření účtu jako povinné pro e-mail i mobil",
  "New user will have to verify both email & mobile no. to access the community":
    "Nový uživatel bude muset ověřit e -mail i mobilní č. pro přístup ke komunitě",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Admin bude také muset ověřit své mobilní č. a e-mail, jinak nebudou mít přístup ke komunitě",
  "Gated community access": "Uzavřený přístup ke komunitě",
  "Admin will have to approve or reject user's requests to join the community":
    "Admin bude muset schválit nebo odmítnout požadavek uživatele o připojení ke komunitě",
  "Disable mobile login/register": "Zakázat mobilní přihlášení/registraci",
  "Disable email login/register": "Zakázat emailové přihlášení/registraci",
  "Disable google login/register": "Zakázat google přihlášení/registraci",
  "Disable facebook login/register":
    "Zakázat facebookové přihlášení/registraci",
  "Show & Hide features": "Zobrazit a skrýt funkce",
  "Hide direct chat": "Skrýt přímý chat",
  "User will not be able to send direct messages to each other":
    "Uživatelé si nebude moci navzájem posílat přímé zprávy",
  "Hide profile picture from top right corner for mobile responsive view":
    "Skrýt profilový obrázek z pravého horního rohu pro mobilní responzivní zobrazení",
  "Useful when using community in an in app browser":
    "Užitečné při používání komunity v prohlížeči",
  "Hide profile picture from top right corner in every view":
    "Skryjte profilový obrázek z pravého horního rohu v každém zobrazení",
  "Disable header dropdown on profile picture":
    "Zakázat rozbalovací informace o hlavičce na profilovém obrázku",
  "Useful for integrated community, especially using SSO":
    "Užitečné pro integrovanou komunitu, zejména pomocí SSO",
  "Hide the communtiy logo from the header": "Skrýt logo komunity z hlavičky",
  "Hide the communtiy title from the header":
    "Skrýt titulek komunity z hlavičky",
  "Show collpased group in the side nav bar":
    "Zobrazit sbalenou skupinu v boční navigaci",
  "Show full expanded post in the home feed":
    "Zobrazit plně rozbalené příspěvky v domovském feedu",
  "Hide the leaderboard score from the header":
    "Skrýt skóre žebříčku z hlavičky",
  "Show powered by Pensil": "Zobrazit vytvořeno pomocí Pensil",
  "Hide closed groups if not joined":
    "Skrýt uzavřené skupiny, pokud není připojen",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Uzavřené skupiny nebudou uživateli zobrazeny, pokud nejsou členem skupiny",
  "Hide Community subscriber count from the users":
    "Skrýt počet odběratelů komunity před uživateli",
  "This will hide the community subscriber list in the community header":
    "Toto skryje seznam odběratelů komunity v záhlaví komunity",
  "Disable Communtiy Switcher": "Zakázat přepínač komunit",
  "Use MD editor for create post description":
    "Použijte MD editor pro vytvoření popisu příspěvku",
  "Non logged in user will land on explore group page instead of post directory":
    "Nepřihlášený uživatel přistane na prozkoumání stránek skupiny namísto adresáře příspěvků",
  "Advanced configurable": "Pokročilé konfigurace",
  "Communtiy default country code": "Výchozí kód země komunity",
  "This will show up by default on login/signup":
    "Toto se ve výchozím nastavení zobrazí při přihlášení/registraci",
  "Onboarding/Welcome Message": "Zpráva při onboardingu/Uvítací zpráva",
  "Welcome Message": "Uvítací zpráva",
  "Greet your members with personalized message":
    "Pozdravte své členy s personalizovanou zprávou",
  "Welcome to": "Vítejte v",
  Message: "Zpráva",
  "Add message": "Přidat zprávu",
  "Custom domain": "Vlastní doména",
  "Add custom domain": "Vložit vlastní doménu",
  "Create new request": "Zadat požadavek",
  "Enter your custom domain": "Vložte vlastní doménu",
  "Setup details": "Nastavení detailů",
  "Add a CNAME record in your domain pointing to this community's address":
    "Přidejte záznam CNAME ke své doméně, který ukazuje na adresu této komunity",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "Chcete -li nakonfigurovat vaši doménu, musíte přidat záznam CNAME, který nasměruje vaši doménu na URL komunity na Pensil",
  "Step by Step help apge": "Stránka nápovědy krok za krokem",
  "All request list": "Seznam všech požadavků",
  "Allow admin to assign/un-assign custom points to users":
    "Povolit administrátorům přidávat/ubírat vlastní body uživatelům",
  "Base points for user to begin with":
    "Základní počet bodů, s kterými budou uživatelé začínat",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Základní body budou aktualizovány pro všechny uživatele, jakékoli změny se budou odrážet pro všechny uživatele.",
  "Allocate points for interaction": "Přidělit body za interakci",
  "Upvoting post": "To se mi líbí příspěvku",
  "creating a comment": "vytváření komentáře",
  "creating a reply": "vytváření odpovědi",
  "Getting upvote to their post": "Získání to se mi líbí jejich příspěvku",
  "Getting comment to their post": "Získání komentáře k jejich příspěvku",
  "Getting reply to their comment": "Získání odpovědi na jejich komentář",
  "Creating a post": "Vytváření příspěvku",
  Visiblity: "Viditelnost",
  "Move Up": "Posunout nahoru",
  "Move Down": "Posunout dolů",
  "Create embedded section": "Vytvořit vloženou sekci",
  "Preview area": "Náhled oblasti",
  "Theme selector": "Výběr motivů",
  "Community Theme": "Motiv komunity",
  "Select theme": "Vybrat motiv",
  "Select brand color": "Vybrat barvu značky",
  "Customize your button color to match your brand":
    "Přizpůsobte barvu tlačítka tak, aby odpovídala vaší značce",
  "Select on button text color": "Vyberte barvu textu tlačítka",
  "Suggestion: Keep inverted contrast against brand color":
    "Návrh: Udržujte obrácený kontrast proti barvě značky",
  Billing: "Fakturace",
  "Current plan": "Aktuální plán",
  "You are current on Free PLan": "Aktuálně jste na bezplatném plánu",
  "Renew on: NA": "Obnoví se: Nedostupné",
  Select: "Vybrat",
  "Email us at": "Pošlete nám email na",
  "Compare plan": "Porovnat plány",
  "API Keys": "Klíče API",
  "Create new key": "Vytvořit nový klíč",
  "Total keys": "Všecny klíče",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "",
  "Key name": "Název klíče",
  "Key value": "Hodnota klíče",
  Action: "Akce",
  "Group updated!": "Skupina aktualizována!",
  "Are you sure you want to": "Jste si jistí, že chcete",
  "Make Moderator": "Udělat moderátorem",
  "Remove from group": "Odstranit ze skupiny",
  "Show All": "Zobrazit vše",
  "Change Role": "Změnit roli",
  Close: "Zavřít",
  copy: "zkopírovat",
  LINKS: "ODKAZY",
  "Email Id": "ID emailu",
  Name: "Název",
  "Search for an image": "Hledat obrázek",
  "Crop Image": "Oříznout obrázek",
  "No photos found": "Žádné obrázky nenalezeny",
  Community: "Komunita",
  "Banner ratio - 16:9": "Poměr banneru - 16:9",
  "Banner Ratio - 16:6": "Poměr banneru - 16:6",
  "Share on mail": "Sdílet emailem",
  "Share on Telegram": "Sdílet na Telegramu",
  "Share on Whatsapp": "Sdílet na Whatsappu",
  "Share on Linkedin": "Sdílet na Linkedinu",
  "Share on Facebook": "Sdílet na Facebooku",
  "Share on Twitter": "Sdílet na Twitteru",
  "Pin Post": "Připnout příspěvek",
  "Heading (optional)": "Nadpis (volitelné)",
  "Select section": "Vybrat sekci",
  Gif: "Gif",
  "Create Custom Widget": "Vytvořit vlastní widget",
  "Are you sure you want to delete this widget?":
    "Jste si jistí, že si přejete smazat tento widget?",
  "Go to event": "Přejít na událost",
  "Add Tags": "Přidat tagy",
  "Create Tag": "Vytvořit tagy",
  "Title (Optional)": "Titulek (volitelné)",
  "Search Gif": "Hledat Gif",
  "What's on your mind": "Co se vám honí hlavou?",
  "Looks like nothing available here right now.":
    "Vypadá to, že právě teď není nic dostupného.",
  "Try exploring other tabs": "Zkuste prozkoumat další karty",
  "Delete Widget": "Smazat widget",
  "Please select a section to post": "Prosím vyberte sekci pro příspěvek",
  "Post added successfully!": "Příspěvek úspěšně přidán!",
  "Failed to create post. Please check post content and retry again.":
    "Nepodařilo se vytvořit příspěvek. Zkontrolujte prosím obsah příspěvku a opakujte znovu.",
  "Are you sure you want to discard current post?":
    "Jste si jisti, že chcete zahodit aktuální příspěvek?",
  "By default it will take up the image uploaded with the post":
    "Ve výchozím nastavení bude použit obrázek nahraný s příspěvkem",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Kvíz si nyní můžete prohlédnout a vyplnit pouze z mobilního zařízení!",
  "Community Banner": "Banner komunity",
  "Change Cover": "Změnit náhledový obrázek",
  "Suggestions: Welcome to my community etc":
    "Nápovědy: Vítejte v mé komunitě atd.",
  "(edited)": "(upraveno)",
  "Aspect ratio (recommended)": "Poměr stran (doporučené)",
  "Banner details updated!": "Detaily banneru aktualizovány!",
  "Refresh if changes are not visible!":
    "Obnovte, pokud změny nejsou viditelné!",
  "Banner details could not be updated!":
    "Detaily banneru nebylo možné aktualizovat!",
  "Post deleted": "Příspěvek smazán",
  "Post not available. It may have been deleted.":
    "Příspěvek není dostupný. Je možné, že byl smazaný.",
  Admin: "Administrátor",
  Moderator: "Moderátor",
  "Bookmark added!": "Záložka přidána!",
  "Bookmark removed": "Záložka odstraněna",
  "Failed performing action!": "Nepodařilo se provést akci!",
  "Copy this link to share on other platforms":
    "Zkopírujte tento odkaz pro sdílení na jiných platformách",
  "View this event": "Zobrazit tuto událost",
  Event: "Událost",
  "Join Meeting": "Připojit se k setkání",
  "The live meeting has ended": "Živé setkání skončilo",
  "read more...": "číst více...",
  "Load more comments": "Načíst více komentářů",
  "Post Reply": "Přidat odpověď",
  "Post Comment": "Přidat komentář",
  more: "více",
  "přidáno to se mi líbí": "hlasováno",
  "1 Upvote": "1 líbí se",
  "1 Comment": "1 komentář",
  "Please enter a title": "Prosím zadejte titulek",
  "Please enter a url link": "Prosím zadejte url odkazu",
  "Please enter button title": "Prosím zadejte titulek tlačítka",
  "Button label must be at least 20 characters":
    "Štítek tlačítka musí mít nejméně 20 znaků",
  "Please enter valid url": "Prosím zadejte funkční url",
  "Suggestions: Know more, Join, Apply...":
    "Nápovědy: Zjistit více, Připojit se, Použít...",
  URL: "URL",
  "Community Events": "Události komunity",
  "Widget deleted successfully": "Widget smazán úspěšně",
  "Community Top Contributors": "Nejlepší přispěvatelé komunity",
  points: "body",
  "Points cannot be greater than current points":
    "Body nemohou být vyšší než aktuální počet bodů",
  "Point cannot be a negative value": "Body nemohou mít zápornou hodnotu",
  "Point cannot be a decimal point": "Body nemohou mít desetinnou čárku",
  "Please enter a valid number": "Prosím zadejte platné číslo",
  "Karma points updated successfully": "Karma body aktualizovány úspěšně",
  "Error in updating karma points": "Chyba při aktualizaci karma bodů",
  "Add karma points": "Přidat karma body",
  "Minus karma points": "Mínus karma body",
  "Total Karma points:": "Celkový počet karma bodů:",
  "Enter no. of karma points": "Zadejte počet karma bodů",
  "Minus Points": "Mínus body",
  "Trending Post": "Trendující příspěvky",
  "No visible description": "Žádný viditelný popisek",
  "Picture ratio: 1:1": "Poměr obrázku 1:1",
  "home feed": "domovský feed",
  "Event name is invalid!": "Název události není platný!",
  custom: "vlastní",
  "Custom widget updated successfully": "Vlastní widget úspěšně aktualizován",
  "Name invalid!": "Neplatný název!",
  "Current timezone:": "Aktuální časová zóna:",
  "can be updated from community settings":
    "může být aktualizováno v nastavení komunity",
  "Event created successfully": "Událost vytvořena úspěšně",
  "Error in creating event": "Chyba při vytváření události",
  Attendees: "Účastníci",
  "Do you wish to notify these changes to attendees?":
    "Přejete si o těchto změnách upozornit účastníky?",
  "users wants to attend this event": "uživatelů se chtějí zúčastnit této akce",
  "Yes, Notify them": "Ano, upozorněte je",
  "No, its okay": "Ne, to je v pořádku",
  "Do you wish to notify that event is cancelled?":
    "Chcete oznámit, že událost je zrušena?",
  "Are you sure you want to delete the event?":
    "Jste si jisti, že chcete událost odstranit?",
  "Delete Event": "Smazat událost",
  "New Event": "Nová událost",
  "Accept Invitation": "Přijmout pozvánku",
  Requested: "Zažádáno",
  Declined: "Odmítnuto",
  "Request Access": "Požádat o přístup",
  "Enroll for": "Zaregistrujte se na",
  Housefull: "Plno",
  "Just copy paste this code wherever you want it to be embedded:":
    "Stačí zkopírovat a vložit tento kód kamkoli chcete, aby byl vložen:",
  Ended: "Skončilo",
  "Error getting events": "Chyba při načítání událostí",
  "Events could not be fetched!": "Události nemohly být načteny!",
  "Show Attendees": "Zobrazit účastníky",
  Subscribers: "Odběratelé",
  "Make this a closed section": "Změnit tuto sekci na uzavřenou",
  "Update Section": "Aktualizovat sekci",
  "Valid section name required!": "Je vyžadovaný platný název sekce!",
  "Enter section name": "Zadejte název sekce",
  "Community Admin": "Komunitní admin",
  Embed: "Vložit",
  "Invite to": "Pozvat k",
  "Please enter a valid email address":
    "Prosím zadejte platnou emailovou adresu",
  "Invitation sent successfully": "Pozvánka úspěšně odeslána",
  "Error sending invitation": "Chyba při odesílání pozvánky",
  "Email is not valid!": "Email není platný!",
  "Data could not be loaded!": "Data nebylo možné načíst!",
  "Posted in": "Přidáno do",
  "Invite Members Individually": "Pozvat členy jednotlivě",
  "Start a discussion": "Zahájit diskusi",
  "Post something": "Přidejte něco",
  "Invite your audience": "Pozvěte své publikum",
  "Choose Plan": "Vybrat plán",
  or: "nebo",
  "Create a link": "Vytvořit odkaz",
  "Add link": "Přidat odkaz",
  "Update link": "Aktualizovat odkaz",
  "Add shortcut to your most used tools":
    "Přidat zkratku na vaše nejčastěji používané nástroje",
  "Your website, Instagram Channel, Facebook profile etc":
    "Vaše stránka, Instagramový kanál, Facebookový profil atd",
  "Valid link name required!": "Vyžadován platný název odkazu!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Vyžadováno platné URL. Ujistěte se, že v něm nechybí http:// nebo https://",
  "Invite to__1": "Pozvat k",
  "Link Name": "Název odkazu",
  "Link URL": "URL odkazu",
  "Could not subscribe to": "Nebylo možné odebírat",
  "Subscribed to": "Nyní odebíráte",
  "By default it will take up the community banner uploaded with the community":
    "Ve výchozím nastavení zabere banner komunity nahraný v komunitě",
  "Created by": "Vytvořil/a",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Online událost (odkaz na setkání bude viditelný po registraci na akci)",
  "Join Call": "Připojit se k hovoru",
  "Please login to book this event":
    "Prosím přihlaste se pro registraci na tuto událost",
  "Commented on": "Okomentoval/a",
  "Replied to": "Odpověděl/a na",
  "Looks like it's lonely here.": "Vypadá to, že je to tady osamělé.",
  "Try exploring other sections and groups.":
    "Zkuste prozkoumat další sekce a skupiny.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Pokračujte s platbou nebo kontaktujte administrátora k získání přístupu k této skupině.",
  "This is a closed group You'll need access to this group.":
    "Toto je uzavřená skupina, budete potřebovat přístup k této skupině.",
  "You have no bookmarks right now.": "Aktuálně nemáte žádné záložky.",
  "The user hasn't posted anything yet publicly.":
    "Uživatel ještě nic nepublikoval veřejně.",
  "No activity to show.": "Žádná aktivita k zobrazení.",
  "Are you sure you want to unsubscribe the community?":
    "Jste si jisti, že chcete zrušit odběr této komunity?",
  "deals left": "zbývá dnů",
  "Something went wrong while loading posts":
    "Došlo k nějaké chybě při načítání příspěvků",
  "Could not unsubscribe from": "Nebylo možné odhlásit odběr od",
  "Unsubscribed from": "Odhlášen odběr od",
  "Login to view this page": "Přihlaste se pro zobrazení této stránky",
  Login: "Přihlásit se",
  "There was some error while doing the action!":
    "Došlo k nějaké chybě při provádění akce!",
  "Unable to delete section!": "Nebylo možné smazat sekci!",
};
