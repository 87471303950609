import React, { Suspense, lazy, useEffect, useState } from "react";
import { ToastTypes } from "../../../../contexts/toastr.context";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux.hook";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Course } from "../../../../types/course/course.type";
import { Button, ErrorDiv, Loader, TextInput } from "../../../../_components";
import I18 from "../../../../_components/atoms/i18";
import TextButton from "../../../../_components/atoms/text-button";
import CurrencySelector from "../../../../_components/compound/currency-selector";
import { Checkbox } from "../../../../_components/form-controls/checkbox.component";
import IconButton2 from "../../../../_components/icon-button-2-component";
import { ListView } from "../../../../_components/molecule/listview.component";
import Label from "../../../../_components/typography/label.typography";
import { SubscriptionService } from "../../../../_service";
import { store } from "../../../../_store";
import { Status } from "../../../../_store/courses/courses-reducer";
import { getAllCourses } from "../../../../_store/courses/courses.action";
import { getInvoicePreference } from "../../../../_store/preference/preference.action";
import { Fold } from "../../../../_utils/extensions/typescript-utils";
import Validator from "../../../../_utils/validator";
const RichTextEditor = lazy(() => import("../../../../_components/form-controls/rich-text-editor.component"));

interface Props {
  groups: Array<any> | undefined;
  courses: {
    list: Array<Course> | undefined;
    status: Status;
  };
  community: {
    id: string;
    [key: string]: any;
  };
  isSubscriptionVisible: boolean;
  setIsSubscriptionVisible: (value: boolean) => void;
  onSubscriptionCreated: (sub: any) => void;
}

/**
 * Component to create a new subscription plan
 * @param {Array<any>} groups - list of groups
 * @param {Array<any>} courses - list of courses
 * @param {string} communityId - community id
 * @param {boolean} isSubscriptionVisible - boolean to set the visibility of the subscription modal
 * @param {boolean} setIsSubscriptionVisible - function to set the visibility of the subscription modal
 * @param {Function} onSubscriptionCreated - function to call when a subscription is created
 */

export default function CreateSubscriptions({
  groups,
  courses,
  community,
  isSubscriptionVisible,
  setIsSubscriptionVisible,
  onSubscriptionCreated,
}: Props) {
  const PAYMENT_FREQUENCY = [
    { value: 1, label: "Monthly" },
    { value: 3, label: "3 Months" },
    { value: 6, label: "6 Months" },
    { value: 12, label: "1 Year" },
    { value: 0, label: "One Time" },
  ];

  const [interval, setInterval] = useState<number>(1);
  const [paywallName, setPaywallName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("0");

  const [error, setError] = useState({
    amountError: "",
    paywallNameError: "",
    courseError: "",
    groupError: "",
    apiError: "",
  });
  const [selectedCourse, setSelectedCourse] = useState<Array<Course>>([]);
  const [selectedGroup, setSelectedGroup] = useState<Array<Course>>([]);

  type Tab = "paywall" | "access";
  type Status = "idle" | "loading" | "success" | "error";

  const [activeTab, setActiveTab] = useState<Tab>("paywall");
  const [status, setStatus] = useState<Status>("idle");
  const [currency, setCurrency] = useState("USD");

  const toast = useToast();
  const dispatch = useAppDispatch();
  const { preference, status: invoiceStatus } = useAppSelector(
    (state) => state.preferenceReducer.invoicePreference
  );
  const taxPercentage = preference.sellerInformation?.taxPercentage ?? 0;
  const amountInt = parseInt(Validator.hasValue(amount) ? amount : "0");
  const totalAmount = amountInt + amountInt * (taxPercentage / 100);
  const percentAmount = amountInt * (taxPercentage / 100);

  // INITIALIZE THE CURRENCY
  useEffect(() => {
    if (community && community.configurables) {
      setCurrency(
        community.configurables.COMMUNITY_DEFAULT_SUBSCRIPTION_CURRENCY ?? "USD"
      );
    }
  }, [community]);

  // GET COURSES IF NOT PRESENT
  useEffect(() => {
    if (courses.status === "idle" && isSubscriptionVisible) {
      store.dispatch(getAllCourses());
    }
  }, [courses, isSubscriptionVisible]);

  // RESET THE FORM ON MODEL CLOSE
  useEffect(() => {
    if (!isSubscriptionVisible) {
      setActiveTab("paywall");
      setInterval(1);
      setPaywallName("");
      setDescription("");
      setAmount("");
      setError({
        amountError: "",
        paywallNameError: "",
        courseError: "",
        groupError: "",
        apiError: "",
      });
      setSelectedCourse([]);
      setSelectedGroup([]);
    }
  }, [isSubscriptionVisible]);

  // GET INVOICE PREFERENCE IF NOT PRESENT
  useEffect(() => {
    if (invoiceStatus === "idle" && isSubscriptionVisible) {
      dispatch(getInvoicePreference());
    }
  }, [dispatch, invoiceStatus, isSubscriptionVisible]);

  /**
   * Function to change the active tab.
   * If the active tab is paywall, validate the paywall tab fields before switching to access tab
   * If the active tab is access, call the CreateSubscriptions function to create a new subscription
   * @param tabName - name of the tab
   */
  const handleTabClick = (tabName: Tab) => {
    if (tabName === "access") {
      // validate fields before switching to access tab
      const isValidPaywall = validatePayWall();
      if (!isValidPaywall) {
        return;
      }

      setActiveTab(tabName);
    } else {
      setActiveTab(tabName);
    }
  };

  /**
   * Function to validate the paywall tab fields and set the error if any
   */
  function validatePayWall(): boolean {
    if (!/^\d+$/.test(amount)) {
      setError((prev) => {
        return { ...prev, amountError: "Please enter a valid amount" };
      });
      return false;
    }

    if (paywallName === "") {
      setError((prev) => {
        return { ...prev, paywallNameError: "Please enter paywall name" };
      });
      return false;
    }

    if (paywallName.length > 50) {
      setError((prev) => {
        return {
          ...prev,
          paywallNameError: "Paywall name must be 50 characters or less.",
        };
      });

      return false;
    }
    return true;
  }

  /**
   * Function to validate the access tab fields and set the error if any
   */
  function validateAccess(): boolean {
    const hasAnyCourse = Validator.hasValue(courses?.list);
    const hasAnyGroup = Validator.hasValue(groups);
    const hasAnySelectedCourse = Validator.hasValue(selectedCourse);
    const hasAnySelectedGroup = Validator.hasValue(selectedGroup);

    // When both courses and groups are present
    if (hasAnyCourse && hasAnyGroup) {
      if (!(hasAnySelectedCourse || hasAnySelectedGroup)) {
        setError((prev) => {
          return {
            ...prev,
            courseError: "Please select at least one course or group",
            // groupError: "Please select at least one group",
          };
        });
        return false;
      }
    }
    // When only groups are present
    else if (hasAnyGroup && !hasAnySelectedGroup) {
      setError((prev) => {
        return {
          ...prev,
          groupError: "Please select at least one group",
        };
      });
      return false;
    }
    // When only courses are present
    else if (hasAnyCourse && !hasAnySelectedCourse) {
      setError((prev) => {
        return {
          ...prev,
          courseError: "Please select at least one course",
        };
      });
      return false;
    } else if (!hasAnyCourse && !hasAnyGroup) {
      setError((prev) => {
        return {
          ...prev,
          courseError: "No courses or groups available",
        };
      });
      return false;
    }
    return true;
  }

  /**
   * Function to create a new subscription plan if all the fields are valid
   */
  function CreateSubscriptions() {
    const isValidPaywall = validatePayWall();
    if (!isValidPaywall) {
      return;
    }

    const isValidAccess = validateAccess();
    if (!isValidAccess) return;

    const amt = amountInt;

    const payload = {
      name: paywallName,
      description: description,
      accessibleGroups: selectedGroup.map((group) => group.id),
      accessibleCourses: selectedCourse.map((course) => course.id),
      subscriptionAmount: amt * 100,
      recurringInterval: amt < 1 ? 0 : interval,
      isRecurring: amt > 1 && interval > 0,
      taxPercentage: taxPercentage,
      currency: currency,
    };
    setStatus("loading");
    setError((prev) => {
      return {
        apiError: "",
        amountError: "",
        courseError: "",
        groupError: "",
        paywallNameError: "",
      };
    });
    SubscriptionService.createSubscription(community.id!, payload)
      .then(({ subscription }) => {
        setStatus("success");
        toast.addToast("Subscription created", "", ToastTypes.success);
        setIsSubscriptionVisible(false);
        onSubscriptionCreated(subscription);
      })
      .catch((err) => {
        setStatus("error");
        console.error(err);
        toast.addToast(
          "Unable to create subscription",
          "Try refreshing page and try again ",
          ToastTypes.danger
        );
        setError((prev) => {
          return {
            ...prev,
            apiError: "Unable to create subscription",
          };
        });
      });
  }

  return (
    <div className="shadow-2xl">
      <Header />
      <div className="flex flex-col gap-4 p-4 pt-3 theme-bg-surface">
        {activeTab === "paywall" && (
          <>
            {/* Currency and Amount */}
            <div className="sm:flex gap-2">
              <CurrencySelector
                label="Currency"
                className="flex-1 "
                selectClassName="theme-bg-default"
                currency={currency}
                onCurrencyChange={setCurrency}
              />

              <div className="flex-1 flex flex-col gap-1">
                <Label variant="t1" className="requiredField">
                  Base Amount
                </Label>
                <TextInput
                  placeholder="Enter base amount"
                  type="number"
                  defaultValue={amount}
                  noMargin={true}
                  className="ml-2"
                  large
                  containerClassName="theme-bg-default"
                  onChange={(value) => {
                    setAmount(value.replace(/[.,]/g, ""));
                    setError((prev) => {
                      return { ...prev, amountError: "" };
                    });
                  }}
                />
                <ErrorDiv error={error.amountError} />
              </div>
            </div>
            {/* Total  Amount*/}
            <Fold
              value={invoiceStatus === "success" ? true : undefined}
              ifPresent={() => (
                <div className="flex gap-2 theme-bg-default items-center place-content-around rounded p-4 overflow-x-hidden border theme-border-default">
                  <div className="flex flex-col gap-2">
                    <Label variant="t1">Base Amount</Label>
                    <Label variant="t1">
                      {currency} {amountInt}
                    </Label>
                  </div>
                  <div>+</div>
                  <div className="flex flex-col gap-2">
                    <Label variant="t1">Tax ({taxPercentage}%)</Label>
                    <Label variant="t1">
                      {currency} {percentAmount.toFixed(2)}
                    </Label>
                  </div>
                  <div>=</div>
                  <div className="flex flex-col gap-2">
                    <Label variant="t1">Total Amount</Label>
                    <Label variant="t1">
                      {currency} {totalAmount.toFixed(2)}
                    </Label>
                  </div>
                </div>
              )}
              ifAbsent={() => (
                <div className="h-16 w-full animate-pulse flex gap-2 theme-bg-default items-center place-content-around rounded p-4 overflow-x-hidden border theme-border-default">
                  <div className="flex flex-col gap-2 theme-bg-surface w-full h-11" />
                  <div className="flex flex-col gap-2 theme-bg-surface w-full h-11" />
                  <div className="flex flex-col gap-2 theme-bg-surface w-full h-11" />
                </div>
              )}
            />
            {/* Frequency */}
            <div className="flex flex-col gap-1">
              <Label variant="t1">Payment Frequency</Label>
              <div className="w-full border theme-border-default theme-bg-default rounded  h-10 flex items-center">
                <select
                  value={interval}
                  onChange={(e) => {
                    setInterval(parseInt(e.target.value));
                  }}
                  className="w-full outline-none px-3 theme-bg-default">
                  {PAYMENT_FREQUENCY.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Paywall Name */}
            <div className="flex flex-col gap-1">
              <Label variant="t1">Paywall Name</Label>
              <TextInput
                defaultValue={paywallName}
                placeholder="Basic, Starter, Premium, Pro"
                className=" h-10 ml-2 "
                containerClassName="theme-bg-default"
                noMargin={true}
                onChange={(value) => {
                  setPaywallName(value);

                  setError((prev) => {
                    return {
                      ...prev,
                      paywallNameError:
                        value === ""
                          ? "Please enter paywall name"
                          : value.length > 50
                          ? "Paywall name must be 50 characters or less."
                          : "",
                    };
                  });
                }}
              />
              <ErrorDiv error={error.paywallNameError} />
            </div>
            {/* Description */}
            <div className="flex flex-col gap-1">
              <span>
                <Label variant="t1">Small Description</Label>
                <Label variant="s2" size="caption">
                  &nbsp;(try to keep it under 50 words)
                </Label>
              </span>

              <Suspense fallback={<></>}>
                <RichTextEditor
                  text={description}
                  setText={setDescription}
                  uploadImage={false}
                  className="resize-none theme-bg-default"
                  onSubmit={undefined}
                  placeholder="Enter description here (optional)"
                />
              </Suspense>
            </div>
            {/* cancel and next button */}
            <div className="flex flex-row justify-between  ">
              <TextButton
                label="Cancel"
                onClick={() => {
                  setIsSubscriptionVisible(false);
                }}
              />
              <Button
                label="Next"
                large={true}
                onClick={() => handleTabClick("access")}
              />
            </div>
          </>
        )}
        {activeTab === "access" && (
          <>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col space-y-2 mb-5  gap-2">
                {/* GROUPS */}
                <div className="flex-1 flex flex-col gap-1">
                  <div className="flex flex-col">
                    <Label variant="t1" translate>
                      Groups
                    </Label>
                    <Label variant="s2" size="caption">
                      Only closed groups can be added to subscription
                    </Label>
                  </div>
                  <ListView
                    items={groups}
                    // sortBy={(a, b) => (a.groupType === "open" ? 1 : -1)}
                    className="overflow-y-scroll max-h-52 border theme-border-default rounded  theme-bg-surface select-none px-4"
                    renderItem={(item: any, index: number) => (
                      <GroupTile
                        key={index}
                        group={item}
                        setSelectedGroup={(groups: any) => {
                          setSelectedGroup(groups);
                        }}
                        selectedGroup={selectedGroup}
                      />
                    )}
                    noItemsElement={
                      <div className="flex flex-col items-center justify-center h-full">
                        <Label variant="t1" size="caption">
                          No Group available
                        </Label>
                      </div>
                    }
                  />
                  <ErrorDiv error={error.groupError} />
                </div>
              </div>

              {/* COURSES */}
              <div className="flex-1 flex flex-col mb-5 gap-2">
                <div className="flex flex-col">
                  <Label variant="t1"> Courses</Label>
                  <Label variant="s2" size="caption">
                    Only closed and published course can be added to
                    subscription
                  </Label>
                </div>
                <ListView
                  className="select-none overflow-y-scroll max-h-52 border theme-border-default rounded px-4 py-2 theme-bg-surface"
                  items={courses?.list}
                  renderItem={(course: any, index: number) => (
                    <CourseTile
                      key={index}
                      course={course}
                      selectedCourse={selectedCourse}
                      setSelectCourse={(course: any) => {
                        setSelectedCourse(course);
                      }}
                    />
                  )}
                  noItemsElement={
                    <div className="flex flex-col items-center justify-center h-full">
                      <Label variant="t1" size="caption">
                        No courses available
                      </Label>
                    </div>
                  }
                />
                <ErrorDiv error={error.courseError} />
              </div>
            </div>

            {/* cancel and next button */}
            <div className="flex flex-row justify-between ">
              <TextButton
                label="Cancel"
                onClick={() => {
                  setIsSubscriptionVisible(false);
                }}
              />
              <Button
                label="Publish"
                large={true}
                isLoading={status === "loading"}
                onClick={() => CreateSubscriptions()}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );

  /**
   * Header component to show the header of the modal
   */
  function Header() {
    return (
      <div className="border-b theme-border-default">
        <div className="pl-4 pr-2 py-2  flex items-center justify-between">
          <div className="font-bold theme-text-heading-1">
            <I18>Create Subscription</I18>
          </div>
          <IconButton2
            hoverable
            icon="cross"
            size="md"
            iconClass="text-bold"
            onClick={() => {
              setIsSubscriptionVisible(false);
            }}
          />
        </div>
        <div className=" flex items-center gap-6 ml-4">
          <div
            onClick={() => {
              setActiveTab("paywall");
            }}
            className={
              activeTab === "paywall"
                ? "theme-text-primary cursor-pointer border-b  theme-border-primary w-1/4 justify-center flex whitespace-nowrap font-bold"
                : "ml-4 cursor-pointer"
            }>
            Subscription Details
          </div>
          <div
            onClick={() => {
              handleTabClick("access");
            }}
            className={
              activeTab === "access"
                ? "theme-text-primary cursor-pointer border-b theme-border-primary w-1/6 justify-center flex whitespace-nowrap font-bold"
                : " cursor-pointer"
            }>
            Access To
          </div>
        </div>
      </div>
    );
  }
}

/**
 * GroupTile component to show the group
 * @param {any} group
 * @param {Array<any>} selectedGroup
 * @param {Function} setSelectedGroup
 */
function GroupTile({
  group,
  selectedGroup,
  setSelectedGroup = () => {},
}: {
  group: { id: any; name: any; groupType?: string } & { [key: string]: any };
  selectedGroup: any;
  setSelectedGroup?: React.Dispatch<React.SetStateAction<any>>;
}) {
  const isSelected = selectedGroup.some((g: any) => g.id === group.id);
  return (
    <div className="py-1 px-2 rounded font-semibold ">
      <Checkbox
        className="my-2 mr-5"
        disabled={group.groupType === "open"}
        onClick={() => {
          const newSelected = isSelected
            ? selectedGroup.filter((g: any) => g.id !== group.id)
            : [...selectedGroup, group];
          setSelectedGroup(newSelected);
        }}
        selected={isSelected}>
        <div className="flex items-center">
          <Label variant={group.groupType === "open" ? "s1" : "t2"}>
            {group.name}
          </Label>
        </div>
      </Checkbox>
    </div>
  );
}

/**
 * CourseTile component to show the course
 * @param {Course} course
 * @param {Array<Course>} selectedCourse
 * @param {Function} setSelectCourse
 */
function CourseTile({
  course,
  selectedCourse,
  setSelectCourse = () => {},
}: {
  course: Course;
  selectedCourse: Array<Course>;
  setSelectCourse?: (arg0: Array<Course>) => void;
}) {
  const isSelected = selectedCourse.some((c: Course) => c.id === course.id);
  return (
    <div className="py-1 px-2 rounded font-semibold">
      <div className=" mt-1">
        <Checkbox
          className="my-2 mr-5"
          disabled={course.state !== "closed-free"}
          onClick={() => {
            const newSelected = isSelected
              ? selectedCourse.filter((c: any) => c.id !== course.id)
              : [...selectedCourse, course];
            setSelectCourse(newSelected);
          }}
          selected={isSelected}>
          <div className="flex items-center">
            <Label variant={course.state !== "closed-free" ? "s1" : "t2"}>
              {course.title}
            </Label>
          </div>
        </Checkbox>
      </div>
    </div>
  );
}
