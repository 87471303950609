import { MySubscription } from "../../types/subscription/my-subscription-plan";
import {
  GroupSubscriptionPlans,
  SubscriptionPlan,
} from "../../types/subscription/subscription-plan";
import Validator from "../validator";

export class SubscriptionUtils {
  /**
   * Check ig group is included in subscription
   */
  public static isSubscriptionPurchasedByGroup(
    subscription: MySubscription,
    groupId: string
  ): boolean {
    if (subscription && subscription.isActive) {
      return (
        subscription.subscriptionPlan?.accessibleGroups?.some(
          (id) => id === groupId
        ) ?? false
      );
    }
    return false;
  }

  /**
   * Check if course is is included in subscription
   */
  public static isSubscriptionPurchasedByCourse(
    subscription: MySubscription,
    courseId: string
  ): boolean {
    if (subscription && subscription.isActive) {
      return (
        subscription.subscriptionPlan?.accessibleCourses?.some(
          (id) => id === courseId
        ) ?? false
      );
    }
    return false;
  }

  /**
   * Check if plan has any paid subscription
   */
  public static hasPaidSubscription(
    plans: GroupSubscriptionPlans,
    groupId: string
  ): boolean {
    return (
      plans.oneMonthlySubscriptions.length > 0 ||
      plans.threeMonthlySubscriptions.length > 0 ||
      plans.sixMonthlySubscriptions.length > 0 ||
      plans.twelveMonthlySubscriptions.length > 0 ||
      plans.oneTimeSubscriptions.length > 0
    );
  }

  /**
   * Get all subscriptions for a group
   * @param {string}  groupId
   * @param {Array<SubscriptionPlan>} subscriptions
   */
  public static filterSubscriptionsForGroup(
    groupId: string,
    subscriptions: Array<SubscriptionPlan>
  ) {
    var results = {
      oneMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      threeMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      sixMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      twelveMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      oneTimeSubscriptions: [] as Array<SubscriptionPlan>,
    };

    function getFilter(subscription: SubscriptionPlan, interval: number) {
      const isRecurring =
        subscription.isRecurring &&
        subscription.recurringInterval === interval &&
        !subscription.isDeleted;
      return isRecurring;
    }

    const getGroupsFilter = (subscription: SubscriptionPlan) =>
      subscription?.accessibleGroups?.includes(groupId);

    if (!groupId) {
      return results;
    }
    if (!Validator.hasValue(subscriptions) || !Array.isArray(subscriptions)) {
      return results;
    }

    for (var i = 0; i < subscriptions.length; i++) {
      var subscription = subscriptions[i];
      if (getGroupsFilter(subscription)) {
        if (getFilter(subscription, 1)) {
          results.oneMonthlySubscriptions.push(subscription);
        }
        if (getFilter(subscription, 3)) {
          results.threeMonthlySubscriptions.push(subscription);
        }
        if (getFilter(subscription, 6)) {
          results.sixMonthlySubscriptions.push(subscription);
        }
        if (getFilter(subscription, 12)) {
          results.twelveMonthlySubscriptions.push(subscription);
        }
        if (!subscription.isRecurring && !subscription.isDeleted) {
          results.oneTimeSubscriptions.push(subscription);
        }
      }
    }

    return results;
  }

  /**
   * Get all subscriptions for a course
   * @param {string}  courseId
   * @param {Array<SubscriptionPlan>} subscriptions
   */
  public static filterSubscriptionsForCourse(
    courseId: string,
    subscriptions: Array<SubscriptionPlan>
  ) {
    var results = {
      oneMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      threeMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      sixMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      twelveMonthlySubscriptions: [] as Array<SubscriptionPlan>,
      oneTimeSubscriptions: [] as Array<SubscriptionPlan>,
    };

    function getFilter(subscription: SubscriptionPlan, interval: number) {
      const isRecurring =
        subscription.isRecurring &&
        subscription.recurringInterval === interval &&
        !subscription.isDeleted;
      return isRecurring;
    }

    const getGroupsFilter = (subscription: SubscriptionPlan) =>
      subscription?.accessibleCourses?.includes(courseId);

    if (!courseId) {
      return results;
    }
    if (!Validator.hasValue(subscriptions) || !Array.isArray(subscriptions)) {
      return results;
    }

    for (var i = 0; i < subscriptions.length; i++) {
      var subscription = subscriptions[i];
      if (getGroupsFilter(subscription)) {
        if (getFilter(subscription, 1)) {
          results.oneMonthlySubscriptions.push(subscription);
        }
        if (getFilter(subscription, 3)) {
          results.threeMonthlySubscriptions.push(subscription);
        }
        if (getFilter(subscription, 6)) {
          results.sixMonthlySubscriptions.push(subscription);
        }
        if (getFilter(subscription, 12)) {
          results.twelveMonthlySubscriptions.push(subscription);
        }
        if (!subscription.isRecurring && !subscription.isDeleted) {
          results.oneTimeSubscriptions.push(subscription);
        }
      }
    }

    return results;
  }

  /**
   *  Convert amount in the format 1000 to 100.00
   *
   */
  public static convertAmount(amount: number | string | undefined): number {
    if (amount) {
      return Number(amount) / 100;
    }
    return 0;
  }

  /**
   * Calculate Tax amount
   * @param {SubscriptionPlan} subscription
   */
  public static calculateTaxAmount(subscription: SubscriptionPlan): number {
    if (subscription) {
      const baseAmount = subscription?.subscriptionAmount ?? 0;
      const taxPercentage = subscription.taxPercentage ?? 0;
      const taxAmount = baseAmount * (taxPercentage / 100);
      // Return up to 2 decimal places if the amount is not an integer
      if (Number.isInteger(this.convertAmount(taxAmount))) {
        return this.convertAmount(taxAmount);
      }
      return Number(this.convertAmount(taxAmount).toFixed(3));
    }
    return 0;
  }

  /**
   * Calculate the total amount of a subscription.
   * @param {SubscriptionPlan} subscription
   */
  public static calculateSubscriptionTotal(
    subscription: SubscriptionPlan
  ): number {
    if (subscription) {
      // const baseAmount = subscription?.subscriptionAmount ?? 0;
      // const taxAmount = this.calculateTaxAmount(subscription);
      const baseAmount = subscription?.subscriptionAmount ?? 0;
      const taxPercentage = subscription.taxPercentage ?? 0;
      const taxAmount = baseAmount * (taxPercentage / 100);
      const totalAmount = baseAmount + taxAmount;
      return this.convertAmount(totalAmount);
    }
    return 0;
  }

  /**
   * Add 1 to the invoice series.
   * @example
   * // if the invoice series is 00000, the next invoice series will be 00001.
   * // if the invoice series is 00001, the next invoice series will be 00002.
   * // if the invoice series is 09999, the next invoice series will be 010000.
   * // if the invoice series is 99999, the next invoice series will be 100000.
   * @example getNextInvoiceSeries('00000') // returns '00001'
   * @param {string} invoiceSeries
   * @param {number} increment
   * @returns {string} next invoice series
   */
  public static getNextInvoiceSeries(
    invoiceSeries: string,
    increment: number
  ): string {
    const invoiceSeriesNumber = Number(invoiceSeries);
    const nextInvoiceSeriesNumber = invoiceSeriesNumber + increment ?? 1;
    return nextInvoiceSeriesNumber.toString().padStart(5, "0");
  }
}
