const loaderStyle = {
  // position: "fixed",
  // zIndex: 100,
  // left: 0,
  // right: 0,
  // bottom: 0,
  // backgroundColor: "white",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  // top: 0
  padding: "2px",
};

const spinnerStyle = (white, spinnerColor, size, stroke) => ({
  content: " ",
  display: "block",
  background: 0,
  borderRadius: "50%",
  width: `${size}px`,
  height: `${size}px`,
  margin: 0,
  boxSizing: "border-box",
  border: `${stroke}px solid #fff`,
  borderColor: !white
    ? `${spinnerColor} transparent ${spinnerColor} transparent`
    : "white transparent white transparent",
});

function Loader({
  white = false,
  spinnerColor = "var(--theme-primary-color)",
  size = 24,
  stroke = 2,
}) {
  return (
    <div className="flex Loader justify-center" style={loaderStyle}>
      <div
        className="spinner"
        style={spinnerStyle(white, spinnerColor, size, stroke)}></div>
    </div>
  );
}

export { Loader };
