import { useState } from "react";
import mainLogo from "../../_assets/vectors/main-logo.svg";

export default function CommunityLogo({
  community = null,
  height = 54,
  width = null,
  maxSize = false,
  className = "",
}) {
  // check if the image has error
  const [imageHasError, setImageHasError] = useState(false);

  return (community && community.logo && !imageHasError) || !community ? (
    <img
      src={community ? community.logo : mainLogo}
      className={"rounded flex-shrink-0 " + className}
      alt={community ? community.name + " Logo" : "Pensil Logo"}
      style={{
        width: "auto", //maxSize ? "auto" : width ? width + "px" : height + "px",
        height: maxSize ? "auto" : height + "px",
        maxWidth: "260px", //!maxSize ? "auto" : width ? width + "px" : height + "px",
        maxHeight: !maxSize ? "auto" : height + "px",
      }}
      onError={(e) => {
        setImageHasError(true);
      }}
    />
  ) : (
    <svg
      className={"rounded flex-shrink-0 " + className}
      style={{
        backgroundColor: "var(--theme-primary-color)",
        width: "42px", //maxSize ? "auto" : width ? width + "px" : height + "px",
        height: "43px",
      }}>
      <text
        x="50%"
        y="55%"
        fontSize="21"
        fontWeight="bold"
        textAnchor="middle"
        fill="var(--theme-on-primary-color)"
        dominantBaseline="middle">
        {getCommunityInitials(community.name)}
      </text>
    </svg>
  );
}

/**
 * get community initials from community name
 */
function getCommunityInitials(communityName) {
  return communityName
    .split(" ")
    .map((word) => word[0])
    .slice(0, 2)
    .join("")
    .toUpperCase();
}
