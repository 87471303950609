import moment from "moment";
import React, { Suspense, useEffect, useState } from "react";
import TurndownService from "turndown";
import { ToastTypes } from "../../../../contexts/toastr.context";
import { useAppService } from "../../../../hooks/use-app-service";
import useLang from "../../../../hooks/use-lang.hook";
import { createPostDetailPageRouteFromSlug } from "../../../../_pages/post-detail.page";
import { MediaService } from "../../../../_service/media.service";
import { PostService } from "../../../../_service/post.service";
import { validatePost } from "../../../create-post-card.component";
import {
  ConvertToSlugString,
  limitMaxCharacterTo255,
} from "../../../create-slug-modal";
import { CreatePoll } from "./create-poll";
import CreatePostToolbar from "./create-post-toolbar";
import { ImagesPreview } from "./image-preview";
import PostAudioRecorder from "./post-audio-recorder.component";
import SelectedDocumentPreview from "./post-document-preview";
import { PostEditor } from "./post-editor.component";
import PostEmbedCode from "./post-embed-code";
import { PostTitle } from "./post-title-input";
import { VideoPreviewWithImage } from "./video-preview";
import { CreatePostCustomButton } from "./create-post-custom-buttons";
import { CoverPicture } from "./cover-picture";
import MEDIA_TYPES from "../../../../_constants/mediaType";
const PostCreateHeader = React.lazy(() => import("./post-create-header.component"));
const tds = new TurndownService();

export const QUIZ_QUESTION_SKELETON = {
  statement: "",
  options: ["", "", "", ""],
  answer: "",
};

/**
 * Component to create post
 * @param {object} community - community object
 * @param {object} user - user object
 * @param {object} group - group object
 * @param {Array<Object>} groups - List of groups of community
 * @param {object} sectionId - sectionId object
 * @param {Function} addPost - function to add post to post list.
 * @param {Function} addToast - function to display toast.
 * @param  React.Dispatch<React.SetStateAction<boolean>>} setActive - function to toggle post create modal visibility.
 * @param {{boolean,function(boolean)}} closePrompt - Object contains getter and setter to show close prompt.
 */
export default function CreatePostComponent({
  community,
  user,
  group,
  groups,
  sectionId,
  setActive = (e) => {},
  addPost = (post) => {},
  addToast,
  closePrompt,
}) {
  const { analyticsService } = useAppService();

  // title and description related
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // file related
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [documents, setDocument] = useState([]);
  const [coverPicture, setCoverPicture] = useState(null);

  // file progress related

  // embed code related
  const [isEmbedEditorVisible, setIsEmbedEditorVisible] = useState(false);
  const [embedCode, setEmbedCode] = useState("");
  const [embedCodeHeight, setEmbedCodeHeight] = useState(""); // 100px or 200px

  // Recorder related
  const [isRecorderVisible, setIsRecorderVisible] = useState(false);

  // poll related
  const [isPollVisible, setIsPollVisibleVisible] = useState(false); // poll modal visibility
  const [isQuiz, setIsQuiz] = useState(false); // If post is quiz
  const [quizTitle, setQuizTitle] = useState(""); // quiz title
  const [quizDuration, setQuizDuration] = useState(10); // quiz duration
  const [quizQuestions, setQuizQuestions] = useState([QUIZ_QUESTION_SKELETON]); // quiz questions object
  const [quizInstruction, setQuizInstruction] = useState(""); // quiz instruction

  // question related
  const [isPoll, setIsPoll] = useState(true); // If post is poll
  const [isQuestion, setIsQuestion] = useState(false); // If post is question
  const [endTime, setEndTime] = useState(moment().add(12, "h")); // end time of poll

  // custom buttons related
  const [isButtonsSectionVisible, setIsButtonsSectionVisible] = useState(false);
  const [buttons, setButtons] = useState([
    {
      label: "",
      url: "",
      position: "top",
    },
  ]);

  // Slugs related
  const [slugModalActive, setSlugModalActive] = useState(false); // slug modal visibility
  const [slug, setSlug] = useState(null); // slug string
  const [slugTitle, setSlugTitle] = useState(null); // slug title string
  const [slugDescription, setSlugDescription] = useState(null); // slug description string
  const [OGTitle, setOGTitle] = useState(null); // OG title string
  const [OGDescription, setOGDescription] = useState(null); // OG description string
  const [OGImage, setOGImage] = useState(null); // OG image string url

  // Store post create error message
  const [errors, setErrors] = useState(null); // error messages object
  const [isSavingPost, setIsSavingPost] = useState(false); // if post is saving

  // Gif related
  const [isGifPickerVisible, setIsGifPickerVisible] = useState(false); // gif picker visibility

  // Post Customization
  const [hideComment, setHideComment] = useState(false); // hide comment
  const [hideLike, setHideLike] = useState(false); // hide like
  const [hideCreator, setHideCreator] = useState(false); // hide profile
  const [customDate, setCustomDate] = useState(); // hide share
  const [hideDate, setHideDate] = useState(false); // hide share

  const [uploadedMedia, setUploadedMedia] = useState({
    images: [],
    videos: [],
    document: [],
    banner: []
  });

  const [areFilesUploading, setAreFilesUploading] = useState(null);

  const [sendPushNotification, setSendPushNotification] = useState(true);
  const [sendEmailNotification, setSendEmailNotification] = useState(true);

  const lang = useLang();

  // Section Related
  const [selectedGroup, setSelectedGroup] = useState(group);
  const [selectedTab, setSelectedTab] = useState(
    selectedGroup && selectedGroup.tabs.find((tab) => tab.id == sectionId)
  );

  const canPost = (() => {
    const invalidPoll = !isPollVisible // Poll| Quiz should be check if poll is visible
      ? true
      : isPoll || isQuestion || isQuiz
      ? quizTitle.length === 0 &&
        quizQuestions[0]?.statement?.length === 0 &&
        quizQuestions[0]?.answer.length === 0
      : false;

    // don't let user post if media is being uploaded to GC
    if (areFilesUploading === true) {
      return false;
    }

    return !(
      title.length === 0 &&
      description.length === 0 &&
      invalidPoll &&
      images.length === 0 &&
      videos.length === 0 &&
      documents.length === 0
      // !selectedGroup && // If no group set or  selected
      // !selectedTab // If no tab is set or  selected
    );
  })();

  useEffect(() => {
    if (sectionId) {
      const tabModel = group.tabs.find((t) => t.id === sectionId);
      if (tabModel) {
        setSendEmailNotification(tabModel.emailNotificationOnNewPost);
        setSendPushNotification(tabModel.pushNotificationOnNewPost);
      }
    }
  }, [group, sectionId]);

  const createPost = async (event) => {
    // Check ig group is selected or nor if post is being created from community home page
    if (!group && !selectedGroup) {
      setActiveModal(true);
      return;
    }
    try {
      const embedlessDescription = description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );

      const postDescription = community?.configuration
        ?.useMdEditorForCreatePostDescription
        ? description
        : tds.turndown(embedlessDescription);

      // create post
      const post = {
        title,
        description: postDescription,
        groupId: selectedGroup.id,
        embedCode,
        embedCodeHeight,
        tabId: selectedTab?.id ?? sectionId,
        sendEmailNotification,
        sendPushNotification,
        preferences: {
          hideComment: hideComment,
          hideLikes: hideLike,
          hideCreator: hideCreator,
          hideDate: hideDate,
        },
        createdAt: !customDate ? null : customDate,
        meta: {
          slug: ConvertToSlugString(
            slug === null ? ConvertToSlugString(title) : slug
          ),
          title: limitMaxCharacterTo255(slugTitle === null ? title : slugTitle),
          description: limitMaxCharacterTo255(
            slugDescription === null ? postDescription : slugDescription
          ),
          openGraphTitle: limitMaxCharacterTo255(
            OGTitle === null ? title : OGTitle
          ),
          openGraphDescription: limitMaxCharacterTo255(
            OGDescription === null ? postDescription : OGDescription
          ),
          openGraphImage: OGImage,
        },
      };
      if (isPollVisible) {
        if (isQuiz) {
          // check if isQuiz
          post.poll = {
            isQuiz: true,
            isQuestion: false,
            title: quizTitle,
            questions: quizQuestions,
            duration: quizDuration,
            endTime: moment().add(1, "month"),
          };
        }

        if (isPoll) {
          post.poll = {
            isQuiz: false,
            isQuestion: isQuestion,
            title: quizTitle,
            questions: quizQuestions,
            duration: quizDuration,
            endTime: endTime,
          };
        }
      }

      // add buttons to post
      if (isButtonsSectionVisible) {
        post.buttons = buttons.filter(({ label, url }) => label && url);
      }

      const mediaTypes = ['images', 'videos', 'banner', 'document'];

      mediaTypes.forEach(type => {
        if (uploadedMedia[type] && uploadedMedia[type].length > 0)
          post[type] = uploadedMedia[type];
      });

      const e = validatePost(post, images, videos, documents);

      setErrors(e);
      if (e) {
        console.log("errors", e);
        return;
      }

      // start loading
      setIsSavingPost(true);
      let createdPostResponse = await PostService.createPost(user, post);   
      addPost(createdPostResponse.post);

      // mixpanel event
      analyticsService.track("create-post", {
        userType: community.myRole,
        groupName: selectedGroup.name,
        sectionName: selectedTab?.name,
        postType: isQuiz
          ? "quiz"
          : isPoll
          ? "poll"
          : documents
          ? "attachment"
          : "none",
        postUrl:
          window.location.origin + createPostDetailPageRouteFromSlug(post),
      });
      // stop loading
      setIsSavingPost(false);
      addToast("Post added successfully!");
      closeModal();
    } catch (error) {
      //
      setIsSavingPost(false);
      console.error({ error });
      if (error && error.response && error.response.data.errors) {
        if (error.response.data.errors.title) {
          setErrors({ title: error.response.data.errors.title[0] });
        }
      }
      addToast(
        "Failed to create post. Please check post content and retry again.",
        "",
        ToastTypes.danger
      );
    }
  };

  // used for uploading audio document
  const uploadDocument = async (documentToUpload) =>  {
    setAreFilesUploading(true);

    const res = await MediaService.uploadMedia(
      user,
      community.id,
      [documentToUpload],
      MEDIA_TYPES.DOCUMENT,
    );
    
    // directly spreading public urls since we have to clear old document if present while uploading
    setUploadedMedia(prevUploadedMedia => ({
      ...prevUploadedMedia,
      document: [...res.publicUrls]
    }));

    setAreFilesUploading(false);
  }

  // Display close prompt if user clicked outside the modal
  useEffect(() => {
    if (closePrompt.displayClosePrompt) {
      closeModal(true);
      closePrompt.setDisplayClosePrompt(false);
    }
  }, [closePrompt.displayClosePrompt]);

  // Reset create post component state before close
  const closeModal = (prompt = false) => {
    // if we are said to prompt and user can post, show prompt
    if (prompt && canPost) {
      if (
        !window.confirm(
          lang.trans("Are you sure you want to discard current post?")
        )
      ) {
        return;
      }
    }

    // reset the form
    setTitle("");
    setDescription("");
    setEmbedCode("");
    setEmbedCodeHeight("");
    // reset the rows
    // if (descriptionRef) descriptionRef.style.height = "auto";
    // remove quiz/poll/question
    setIsPoll(false);
    setIsQuestion(false);
    setIsQuiz(false);
    setQuizTitle("");
    setQuizQuestions([QUIZ_QUESTION_SKELETON]);
    // remove media
    setImages([]);
    setVideos([]);
    setDocument([]);
    setCoverPicture(null);
    // reset upload percentage
    setErrors(null);
    // set the form inactive
    setActive(false);
    clearSlugMeta();
  };

  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }
  const [activeModal, setActiveModal] = React.useState(false);

  return (
    <div className="CreatePostCard flex flex-col theme-bg-surface max-h-[85vh] sm:max-h-[95vh] overflow-auto">
      <Suspense fallback={<></>}>
        <PostCreateHeader
          setActive={setActive}
          setActiveModal={setActiveModal}
          activeModal={activeModal}
          community={community}
          slugModalActive={slugModalActive}
          setSlugModalActive={setSlugModalActive}
          slug={slug}
          setSlug={setSlug}
          title={title}
          slugTitle={slugTitle}
          setSlugTitle={setSlugTitle}
          description={description}
          slugDescription={slugDescription}
          setSlugDescription={setSlugDescription}
          OGTitle={OGTitle}
          setOGTitle={setOGTitle}
          OGDescription={OGDescription}
          setOGDescription={setOGDescription}
          OGImage={OGImage}
          setOGImage={setOGImage}
          clearSlugMeta={clearSlugMeta}
          groups={groups}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          disablePostInAnotherGroup={sectionId && group} // if sectionId and group is available, disable post in another group
          sendEmailNotification={sendEmailNotification}
          setSendEmailNotification={setSendEmailNotification}
          sendPushNotification={sendPushNotification}
          setSendPushNotification={setSendPushNotification}
          hideLike={hideLike}
          setHideLike={setHideLike}
          hideComment={hideComment}
          setHideComment={setHideComment}
          hideCreator={hideCreator}
          setHideCreator={setHideCreator}
          hideDate={hideDate}
          setHideDate={setHideDate}
          customDate={customDate}
          setCustomDate={setCustomDate}
          areFilesUploading={areFilesUploading}
        />
      </Suspense>
      {/* BODY */}
      <div className="flex flex-col px-4">
        <CoverPicture
          user={user}
          community={community}
          coverPicture={coverPicture}
          setCoverPicture={setCoverPicture}
          setAreFilesUploading={setAreFilesUploading}
          setUploadedMedia={setUploadedMedia}
          uploadedMedia={uploadedMedia}
        />
        <PostTitle
          title={title}
          setTitle={setTitle}
          description={description}
          errors={errors}
          setErrors={setErrors}
        />
        <ImagesPreview
          images={images}
          setImages={setImages}
          uploadedMedia={uploadedMedia}
          setUploadedMedia={setUploadedMedia}
        />
        <VideoPreviewWithImage
          videos={videos}
          setVideos={setVideos}
          setUploadedMedia={setUploadedMedia}
          areFilesUploading={areFilesUploading}
        />

        <div className="border-b theme-border-default mb-3" />

        <PostEditor
          description={description}
          setDescription={setDescription}
          user={user}
          community={community}
        />

        <SelectedDocumentPreview
          documents={documents}
          setDocument={setDocument}
          setUploadedMedia={setUploadedMedia}
          areFilesUploading={areFilesUploading}
        />

        <CreatePoll
          isPollVisible={isPollVisible}
          errors={errors}
          isQuestion={isQuestion}
          setIsQuestion={setIsQuestion}
          isQuiz={isQuiz}
          setIsQuiz={(e) => {
            setIsQuiz(true);
            setIsPoll(false);
          }}
          isPoll={isPoll}
          setIsPoll={setIsPoll}
          quizTitle={quizTitle}
          setQuizTitle={setQuizTitle}
          quizDuration={quizDuration}
          setQuizDuration={setQuizDuration}
          quizInstruction={quizInstruction}
          setQuizInstruction={setQuizInstruction}
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
          endTime={endTime}
          setEndTime={setEndTime}
        />
        <PostEmbedCode
          isEmbedEditorVisible={isEmbedEditorVisible}
          embedCode={embedCode}
          setEmbedCode={setEmbedCode}
          embedCodeHeight={embedCodeHeight}
          setEmbedCodeHeight={setEmbedCodeHeight}
          isVisible={
            !(
              !selectedGroup ||
              !["admin", "moderator"].includes(selectedGroup.myRole)
            )
          }
        />

        {/* custom buttons */}
        {isButtonsSectionVisible ? (
          <CreatePostCustomButton buttons={buttons} setButtons={setButtons} />
        ) : (
          <></>
        )}
        <PostAudioRecorder
          isRecorderVisible={isRecorderVisible}
          setIsRecorderVisible={setIsRecorderVisible}
          setRecording={(recording) => {
            uploadDocument(recording);
            setDocument([recording]);
          }}
        />
      </div>
      <CreatePostToolbar
        community={community}
        user={user}
        images={images}
        setImages={setImages}
        videos={videos}
        setVideos={setVideos}
        documents={documents}
        setDocument={setDocument}
        isEmbedEditorVisible={isEmbedEditorVisible}
        setIsEmbedEditorVisible={
          !(
            !selectedGroup ||
            !["admin", "moderator"].includes(selectedGroup.myRole)
          )
            ? setIsEmbedEditorVisible
            : null
        }
        setAreFilesUploading={setAreFilesUploading}
        isPollVisible={isPollVisible}
        setIsPollVisibleVisible={(val) => {
          // Reset poll data if poll is not visible
          if (!val) {
            setQuizTitle("");
            setQuizQuestions([QUIZ_QUESTION_SKELETON]);
          }
          setIsPollVisibleVisible(val);
        }}
        isGifPickerVisible={isGifPickerVisible}
        setIsGifPickerVisible={setIsGifPickerVisible}
        isButtonsSectionVisible={isButtonsSectionVisible}
        setIsButtonsSectionVisible={setIsButtonsSectionVisible}
        isRecorderVisible={isRecorderVisible}
        setIsRecorderVisible={setIsRecorderVisible}
        uploadedMedia={uploadedMedia}
        setUploadedMedia={setUploadedMedia}
        addToast={addToast}
        disableSubmit={!canPost}
        isSavingPost={isSavingPost}
        onSubmitPost={createPost}
      />
    </div>
  );
}
