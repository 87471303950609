import { SET_SOCKET } from "../_actions/socket.actions";

export function socketReducer(state = null, action) {
  switch (action.type) {
    case SET_SOCKET:
      return action.socket;
    default:
      return state;
  }
}
