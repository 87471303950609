import { useState } from "react";
import { useToast } from "../../../../../hooks/use-toast.hook";
import { Button, FormError, TextInput } from "../../../../../_components";
import Label from "../../../../../_components/typography/label.typography";
import { SubscriptionService } from "../../../../../_service";
import Validator from "../../../../../_utils/validator";
import { ActiveSubscriptionMember } from "../subscription-detail-component";

interface EditInvoiceProps {
  communityId: string;
  userSubscriptionId: string;
  invoiceNumber: string;
  invoicePrefix: string;
  setVisible: (visible: boolean) => void;
  onPlanUpdate: (plan: ActiveSubscriptionMember) => void;
}
/**
 * Edit Invoice
 * @param {string} communityId the community object
 * @param {string} userSubscriptionId the course object
 * @param {string} invoiceNumber the invoice number
 * @param {string} invoicePrefix the invoice prefix
 * @param {function} setVisible the function to set the modal visibility
 * @param {function} onPlanUpdate the function to update the plan
 */
export default function EditInvoice({
  communityId,
  userSubscriptionId,
  invoiceNumber,
  invoicePrefix,
  setVisible = () => {},
  onPlanUpdate = () => {},
}: EditInvoiceProps) {
  const [prefix, setPrefix] = useState(invoicePrefix);
  const [number, setNumber] = useState(invoiceNumber);

  const [error, setError] = useState({
    invoiceNumber: "",
    invoicePrefix: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const { addToast, ToastTypes } = useToast();

  /**
   * Handle update invoice
   **/
  const handleUpdateInvoice = (e: any) => {
    e.preventDefault();
    if (!userSubscriptionId) {
      console.error("userSubscriptionId is required");
      return;
    }

    const payload = {
      invoicePrefix: prefix,
      invoiceNumber: number,
    };
    setIsLoading(true);
    SubscriptionService.editInvoice(communityId, userSubscriptionId, payload)
      .then(({ subscription }) => {
        addToast("Invoice updated successfully");
        onPlanUpdate(subscription);
        setVisible(false);
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        if (
          errors &&
          errors?.invoicePrefix &&
          Array.isArray(errors.invoicePrefix)
        ) {
          setError((prev) => ({
            ...prev,
            invoicePrefix: errors.invoicePrefix[0],
          }));
        } else if (
          errors &&
          errors?.invoiceNumber &&
          Array.isArray(errors.invoiceNumber)
        ) {
          setError((prev) => ({
            ...prev,
            invoiceNumber: errors.invoiceNumber[0],
          }));
        } else {
          console.log(err);
        }
        addToast("Unable to update invoice.", "", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="max-w-lg min-w-full">
      <form
        id="content-4a"
        className="flex flex-col gap-4"
        onSubmit={handleUpdateInvoice}>
        <div className="flex flex-col">
          <h3 className="after:content-['*'] after:text-red-500 after:ml-0.5">
            <Label variant="t2">Update Invoice Prefix</Label>
          </h3>

          <TextInput
            placeholder="Enter invoice prefix"
            className="theme-bg-default"
            containerClassName="theme-bg-default"
            required
            defaultValue={invoicePrefix}
            onChange={(value) => {
              setPrefix(value);
              setError({ ...error, invoicePrefix: "" });
            }}
            large
            noMargin
          />

          <FormError error={error.invoicePrefix} />
        </div>
        <div className="flex flex-col">
          <h3 className="after:content-['*'] after:text-red-500 after:ml-0.5">
            <Label variant="t2">Update Invoice number</Label>
          </h3>

          <TextInput
            placeholder="Enter invoice number"
            className="theme-bg-default"
            containerClassName="theme-bg-default"
            required
            defaultValue={invoiceNumber}
            onChange={(value) => {
              setNumber(value);
              setError({ ...error, invoiceNumber: "" });
            }}
            large
            noMargin
          />

          <FormError error={error.invoiceNumber} />
        </div>

        <div className="flex justify-end mt-4">
          <Button
            label={"Update"}
            onClick={() => {}}
            disabled={
              Validator.hasValue(error.invoiceNumber) ||
              Validator.hasValue(error.invoicePrefix)
            }
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
}
