import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { Community } from "../../types/community/community.type";
import { Group } from "../../types/group/group.type";
import {
  NotificationCentre,
  NotificationPreferences,
} from "../../types/notification/notification-centre.type";
import { User } from "../../types/user/minimal-user.type";
import { Loader } from "../../_components";
import { Button } from "../../_components/button.component";
import { Checkbox } from "../../_components/form-controls/checkbox.component";
import { Radio } from "../../_components/form-controls/radio.component";
import UIcon from "../../_components/uicon-component";
import { NotificationCentreService } from "../../_service/notification-centre-service";
import { setCommunity } from "../../_store/_actions/community.actions";
import { setNotificationCentre } from "../../_store/_actions/notification-centre.actions";
import Validator from "../../_utils/validator";
import { useAppService } from "../../hooks/use-app-service";

export const NOTIFICATION_CENTRE_PAGE_ROUTE = "/settings/notification";

// Notifications type
export const NotificationLevel = Object.freeze({
  ALL: "all",
  ADMIN: "admin",
  NONE: "none",
});

interface NotificationCentreComponentProps {
  user: User;
  community: Community;
  groups: Group[];
  notificationCentre: NotificationCentre;
  addToast: any;
  onNotificationCentreUpdate: (e: any) => void;
}
/**
 * Notification Centre settings component
 * @param {Object} user user object
 * @param {Object} community community object
 * @param {Array<Object>} groups list of groups
 * @param {Object} notificationCentre notification preferences. @see rootReducer for details
 * @param {Function} addToast - callback function to display toast message
 * @param {Function} onNotificationCentreUpdate - callback function to set notification preferences in store. @see setNotificationCentre for details
 */
function NotificationCentreComponent({
  user,
  community,
  groups,
  notificationCentre,
  addToast,
  onNotificationCentreUpdate,
}: NotificationCentreComponentProps) {
  const [isLoading, setIsLoading] = useState(notificationCentre.isLoading);
  const [preferences, setPreference] = useState<any>(
    notificationCentre.preferences
  );

  useEffect(() => {
    setIsLoading(notificationCentre.isLoading);
    setPreference(notificationCentre.preferences);
  }, [notificationCentre, notificationCentre.preferences]);

  return (
    <div className="CommunityThemeSelector Card rounded shadow theme-bg-surface">
      {/* headers */}
      <div className="pl-4 pr-2 py-4  border-b theme-border-default flex items-center justify-between">
        <span className="font-bold theme-text-heading-1">
          Notification Centre
        </span>
      </div>
      {/* body */}
      {!isLoading && groups ? (
        <>
          <div className="flex flex-col">
            <PersonalNotifications
              user={user}
              addToast={addToast}
              community={community}
              preferences={preferences}
              setPreference={setPreference}
              onNotificationCentreUpdate={onNotificationCentreUpdate}
            />
            <NewPostNotifications
              user={user}
              groups={groups}
              addToast={addToast}
              community={community}
              preferences={preferences}
              setPreference={setPreference}
              onNotificationCentreUpdate={onNotificationCentreUpdate}
            />
          </div>
        </>
      ) : (
        <div className="h-96 p-12">
          <Loader />
        </div>
      )}
    </div>
  );
}

interface PersonalNotificationsProps {
  community?: Community;
  user: User;
  preferences: NotificationPreferences;
  setPreference: (preferences: NotificationPreferences) => void;
  addToast: (message: string) => void;
  onNotificationCentreUpdate: (preferences: NotificationPreferences) => void;
}

/**
 * @param {Object} preferences - notification preferences
 * @param {Function} addToast - Callback function to display the toast
 * Notification Centre for personal notifications
 */
function PersonalNotifications({
  community,
  user,
  preferences,
  setPreference,
  addToast,
  onNotificationCentreUpdate,
}: PersonalNotificationsProps) {
  const { analyticsService } = useAppService();

  const [isLoading, setIsLoading] = useState<boolean>();

  const [commentReaction, setCommentReaction] = useState(false);
  const [commentReply, setCommentReply] = useState(false);
  const [directMessage, setDirectMessage] = useState(false);
  const [postComment, setPostComment] = useState(false);
  const [postLike, setPostLike] = useState(false);
  const [replyReaction, setReplyReaction] = useState(false);

  const [mention, setMention] = useState(false);

  // Initialize the notification preferences
  useEffect(() => {
    setIsLoading(true);
    if (!preferences) {
      return;
    }
    setIsLoading(false);
    setCommentReaction(preferences.commentReaction);
    setCommentReply(preferences.commentReply);
    setDirectMessage(preferences.directMessage);
    setPostComment(preferences.postComment);
    setPostLike(preferences.postLike);
    setReplyReaction(preferences.replyReaction);

    setMention(preferences.mention);
  }, [preferences]);

  // Update the notification preferences
  function updateNotificationPreference() {
    setIsLoading(true);
    const notificationPreference = {
      commentReaction: commentReaction,
      commentReply: commentReply,
      directMessage: directMessage,
      postComment: postComment,
      postLike: postLike,
      replyReaction: replyReaction,
      mention: mention,
    };

    const communityId = community?.id ?? ""; // Use optional chaining and nullish coalescing

    NotificationCentreService.updateNotificationPreference(
      communityId,
      user,
      notificationPreference
    )
      .then((response) => {
        setPreference(response.preferences);
        onNotificationCentreUpdate(response.preferences);
        setIsLoading(false);
        addToast("Notification preference updated successfully");
      })
      .catch((error) => {
        setIsLoading(false);
        addToast(error.message);
      });

      analyticsService.track("notification-preference-updated")
  }

  return (
    <div className="flex flex-col">
      <div className="font-bold px-4 pt-4 pb-2 theme-text-heading-1">
        Manage your personal account notification preferences
      </div>
      {/* Table to display list of notifications subscriptions*/}
      <div className="mx-2 theme-bg-default rounded">
        <table className="w-full">
          <thead>
            <tr className="theme-bg-subtitle-2 theme-text-background h-8">
              <th className="flex h-8 items-center px-4">Preferences</th>
              <th>Email notification</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">Notify me about comment on my posts</td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={postComment}
                  onClick={() => {
                    setPostComment(!postComment);
                  }}
                />
              </td>
            </tr>
            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">
                Notify me about replies on my comment
              </td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={commentReply}
                  onClick={() => {
                    setCommentReply(!commentReply);
                  }}
                />
              </td>
            </tr>
            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">Notify me about upvote on my post</td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={postLike}
                  onClick={() => {
                    setPostLike(!postLike);
                  }}
                />
              </td>
            </tr>
            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">
                Notify me about reaction to my comment
              </td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={commentReaction}
                  onClick={() => {
                    setCommentReaction(!commentReaction);
                  }}
                />
              </td>
            </tr>

            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">
                Notify me about reaction on my reply
              </td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={replyReaction}
                  onClick={() => {
                    setReplyReaction(!replyReaction);
                  }}
                />
              </td>
            </tr>
            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">Notify me about direct message</td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={directMessage}
                  onClick={() => {
                    setDirectMessage(!directMessage);
                  }}
                />
              </td>
            </tr>
            <tr className="border-b theme-border-default">
              <td className="h-10 px-4">Notify me about mentions</td>
              <td className="flex justify-center h-5 items-center">
                <Checkbox
                  className="justify-self-center"
                  selected={mention}
                  onClick={() => {
                    setMention(!mention);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-end px-4 pt-4">
        <Button
          isLoading={isLoading}
          label={"Save"}
          className="px-6"
          onClick={() => {
            updateNotificationPreference();
          }}
        />
      </div>
    </div>
  );
}

interface NewPostNotificationsProps {
  community: Community;
  groups: Group[];
  user: User;
  preferences: any;
  setPreference: (preferences: NotificationPreferences) => void;
  addToast: any;
  onNotificationCentreUpdate: (preferences: NotificationPreferences) => void;
}
/**
 * Notification Centre for new Posts
 * @param {Array<Object>} groups - list of groups
 * @param {Object} preferences - notification preferences
 * @param {Function} addToast - callback function to display toast message
 * @description  Display only those groups in which user is joined and has sections.
 */
function NewPostNotifications({
  community,
  groups,
  user,
  preferences,
  setPreference,
  addToast,
  onNotificationCentreUpdate,
}: NewPostNotificationsProps) {
  const joinedGroups = groups.filter(
    (g) =>
      g.joinStatus === "joined" &&
      g.tabs &&
      g.tabs.some((tab) => tab.isJoined || !tab.isClosed)
  );
  const [tabsPreference, setTabsPreference] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Update tabs preference
  function updateNotificationPreference() {
    setIsLoading(true);
    const payLoad = {
      ...preferences,
      tabs: tabsPreference,
    };
    if (community && community.id) {
      NotificationCentreService.updateNotificationPreference(
        community.id,
        user,
        payLoad
      )
        .then((response) => {
          addToast("Notification preference updated successfully");
          setIsLoading(false);
          onNotificationCentreUpdate(response.preferences);
        })
        .catch((error) => {
          setIsLoading(false);
          addToast(error.message, "", ToastTypes.danger);
        });
    }
  }
  // Initialize tabs preference
  useEffect(() => {
    if (!preferences) return;
    setTabsPreference(preferences.tabs);
  }, [preferences]);

  return (
    <div className="flex flex-col">
      <div className="font-bold px-4 pt-6 pb-2 theme">
        <div className="theme-text-heading-1">Notification for new posts</div>
        <div className="font-normal theme-text-subtitle-1">
          Only groups and channel available which you are part of. You can also
          control notification
        </div>
      </div>
      <div className="mx-2">
        <div className="grid grid-cols-5 gap-y-4 text-xs font-bold border theme-border-default theme-bg-subtitle-2 theme-text-background">
          <div className=" p-2 col-span-2">Group & Channels</div>
          <div className=" p-2 col-span-1 justify-self-center">
            All Activity
          </div>
          <div className="p-2 col-span-1 justify-self-center">
            Post by admin
          </div>
          <div className=" p-2 col-span-1 justify-self-center">
            No Notification
          </div>
        </div>

        {joinedGroups && (
          <>
            {joinedGroups.length > 0 ? (
              joinedGroups.map((group, index) => {
                return (
                  <GroupNotifications
                    key={index}
                    group={group}
                    preferences={preferences}
                    addToast={addToast}
                    onGroupPreferencesChange={(groupPreferences) => {
                      let list: any = [];
                      if (tabsPreference && tabsPreference.length > 0) {
                        list = tabsPreference.filter(
                          (tab: any) => tab.groupId !== group.id
                        );
                      }
                      // Update tabs preference
                      if (
                        Array.isArray(groupPreferences) &&
                        groupPreferences.length > 0
                      ) {
                        groupPreferences.map((pref) => list.push(pref));
                      }
                      setTabsPreference(list);
                    }}
                  />
                );
              })
            ) : (
              // If user is not part of any group
              <div className="px-4 py-2 text-center theme-text-subtitle-2 theme-bg-default mb-4">
                You haven&apos;t joined any group yet. Please join a group to
                enable notification.
              </div>
            )}
          </>
        )}
      </div>
      {joinedGroups && joinedGroups.length > 0 && (
        <div className="flex justify-end px-4 py-4">
          <Button
            label={"Save"}
            className="px-6"
            onClick={() => updateNotificationPreference()}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
}

interface Tab {
  id: string;
  name: string;
  emoji?: string;
  isJoined: boolean;
  isClosed: boolean;
}
interface PreferenceTab {
  groupId: string;
  tabId: string;
  notificationLevel: string;
}
interface GroupNotificationsProps {
  group?: Group | null;
  preferences?: {
    tabs: PreferenceTab[];
  };
  addToast: any;
  onGroupPreferencesChange: (preferences: PreferenceTab[]) => void;
}

/**
 * Toggle notifications of a group and its sections
 * @param {Object} group - group
 * @param {Object} preferences - notification preferences
 * @param {Function} onGroupPreferencesChange - Callback to update the group preferences
 * @description  Display sections of a group. If group has no sections, display nothing.
 * Users will be able to see groups and sections which they are part of.
 * Sections in which user is joined or not closed will be displayed.
 */
function GroupNotifications({
  group,
  preferences,
  addToast,
  onGroupPreferencesChange,
}: GroupNotificationsProps) {
  // Responsible for displaying sections of a group in expand or collapse state
  const [isExpanded, setIsExpanded] = useState(false);

  // Stores the selected sections of a group
  const [selectedTabs, setSelectedTabs] = useState<
    { id: string; type: string }[]
  >([]);

  // Initialize pre-selected tab
  useEffect(() => {
    if (
      Validator.hasValue(preferences) &&
      Validator.hasValue(preferences?.tabs)
    ) {
      const preferenceTabs = preferences?.tabs.filter(
        (tab) => tab.groupId === group?.id
      );
      const map = group?.tabs?.map((tab) => {
        const preference = preferenceTabs?.find((t) => t.tabId === tab.id);
        if (preference) {
          return {
            id: tab.id,
            type: preference.notificationLevel,
          };
        } else {
          // If not, set the default preference to NotificationLevel.ALL
          return {
            id: tab.id,
            type: NotificationLevel.ALL,
          };
        }
      });

      setSelectedTabs(map || []);
    }
    //  set the default preference to NotificationLevel.ALLƒ
    else if (preferences && preferences.tabs && preferences.tabs.length === 0) {
      setSelectedTabs(
        group?.tabs?.map((tab) => ({
          id: tab.id,
          type: NotificationLevel.ALL,
        })) || []
      );
    }
  }, [preferences, group]);

  // If group is not not defined, return nothing
  if (group === undefined || group === null) {
    return null;
  }
  // if group has no sections, display nothing
  else if (!Array.isArray(group.tabs) || group.tabs.length === 0) {
    return null;
  }

  const joinedTabs = group.tabs.filter((t) => t.isJoined || !t.isClosed);

  // If user is not part of any section of this group, display nothing
  if (joinedTabs && joinedTabs.length === 0) {
    return (
      <div className="border theme-border-default my-1 theme-bg-default text-sm p-1 theme-text-subtitle-2">
        {/* You are not part of any section of {group.name} group. */}
        <span>
          You are not part of any channel of
          <span className="text-bold theme-text-heading-1">
            &nbsp; {group.name} &nbsp;
          </span>
          group
        </span>
      </div>
    );
  }

  // Check if user has pre-selected section for specific notification type
  function checkNotificationTypeSelected(tab: Tab, type: string) {
    const map = { id: tab.id, type: type };
    return selectedTabs.some((t) => t.id === map.id && t.type === map.type);
  }

  // Check if all sections of a group are selected
  function checkAllSectionsSelected(type: string) {
    if (joinedTabs && selectedTabs && selectedTabs.length === 0) {
      return false;
    }
    return (
      selectedTabs.length === joinedTabs.length &&
      selectedTabs.every((t) => t.type === type)
    );
  }

  // Check if any sections of a group is selected
  function checkAnySectionSelected(type: string) {
    if (!joinedTabs || (selectedTabs && selectedTabs.length === 0)) {
      return false;
    }
    return selectedTabs.some((t) => t.type === type);
  }

  return (
    <div className="border theme-border-default my-1">
      <div className="grid grid-cols-5 py-2 px-4 border-t border-b theme-border-default theme-bg-default">
        <div
          className="col-span-2 font-bold theme-text-heading-2 cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}>
          {group.name}
        </div>
        {/* Enable All activity notification for Group*/}
        <CustomGroupCheckBox type={NotificationLevel.ALL} />
        {/* Enable Post by Admin notification for group*/}
        <CustomGroupCheckBox type={NotificationLevel.ADMIN} />
        <div className="justify-self-center flex w-full justify-end">
          {/* Disable All notification for group*/}
          <CustomGroupCheckBox type={NotificationLevel.NONE} />
          <button className="w-12" onClick={() => setIsExpanded(!isExpanded)}>
            <UIcon
              icon={!isExpanded ? "angle-small-down" : "angle-small-up"}
              className="h-4 theme-text-subtitle-1"
            />
          </button>
        </div>
      </div>
      {/* Display sections list if 
        1. expanded state enabled
        2. Joined or not closed sections are available
      */}
      {isExpanded &&
        joinedTabs.map((tab, index) => (
          <div
            key={index}
            className="grid grid-cols-5 justify-items-center px-4 py-1 border-b theme-border-default">
            <div className="col-span-2 w-full theme-text-subtitle-1">
              {tab.emoji ?? "#"}&nbsp;{tab.name}
            </div>
            {/* Enable All activity notification */}
            <CustomTabCheckBox tab={tab} type={NotificationLevel.ALL} />
            {/* Enable Post by Admin notification for section */}
            <CustomTabCheckBox tab={tab} type={NotificationLevel.ADMIN} />
            {/* Disable all notification */}
            <CustomTabCheckBox tab={tab} type={NotificationLevel.NONE} />
          </div>
        ))}
    </div>
  );

  function CustomGroupCheckBox({ type }: { type: string }) {
    const isSelected = checkAllSectionsSelected(type);
    const semiSelected = checkAnySectionSelected(type);
    return (
      <Checkbox
        className="justify-self-center"
        selected={isSelected}
        semiSelected={semiSelected}
        onClick={() => {
          if (isSelected) {
            setSelectedTabs([]);
            onGroupPreferencesChange([]);
          } else {
            const sectionsList = joinedTabs.map((t) => ({
              id: t.id,
              type: type,
            }));
            setSelectedTabs(sectionsList);
            onGroupPreferencesChange(
              sectionsList?.map((t) => ({
                groupId: group?.id || "",
                tabId: t.id,
                notificationLevel: t.type,
              }))
            );
          }
          setIsExpanded(true);
        }}
      />
    );
  }

  /**
   * Custom checkbox component
   * @param {Object} tab - section
   * @param {NotificationType} type - notification type
   */
  function CustomTabCheckBox({ tab, type }: { tab: Tab; type: string }) {
    return (
      <Radio
        className="justify-self-center"
        selected={checkNotificationTypeSelected(tab, type)}
        onClick={() => {
          let tempList;
          const map = { id: tab.id, type: type };
          // Check if tab is already selected with different notification type
          if (selectedTabs.some((t) => t.id === tab.id && t.type !== type)) {
            tempList = selectedTabs.filter((t) => t.id !== tab.id);
            tempList.push(map);
            setSelectedTabs(tempList);
          }

          // Remove pre-selected tab from selected tabs
          else if (selectedTabs.some((t) => t.id === tab.id)) {
            // Remove tab from selected tabs
            tempList = selectedTabs; //selectedTabs.filter((t) => t.id !== tab.id);
            setSelectedTabs(tempList);
          } else {
            // Add tab to selected tabs
            tempList = [...selectedTabs, map];
            setSelectedTabs(tempList);
          }
          onGroupPreferencesChange(
            tempList.map((t) => ({
              groupId: group?.id || "",
              tabId: t.id,
              notificationLevel: t.type,
            }))
          );
        }}
        children={undefined}
      />
    );
  }
}

const mtp = (s: any) => ({
  user: s.auth,
  community: s.community,
  groups: s.groups,
  notificationCentre: s.notificationCentre,
});

const dtp = {
  onCommunityUpdate: setCommunity,
  onNotificationCentreUpdate: setNotificationCentre,
};

// `connect` returns a new function that accepts the component to wrap:
const connectToStore = connect(mtp, dtp);
// and that function returns the connected, wrapper component:
const NotificationCentrePage = withToast(
  connectToStore(NotificationCentreComponent)
);

export default NotificationCentrePage;
