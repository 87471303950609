import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "..";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { RoleService } from "../../_service/role.service";
import Validator from "../../_utils/validator";
import { Avatar } from "../avatar.component";
import { Checkbox } from "../form-controls/checkbox.component";
import { Radio } from "../form-controls/radio.component";
import IconButton2 from "../icon-button-2-component";
import { Modal } from "../modal.component";

function UpdateRoleModalComponent({
  community,
  user,
  groups,
  activeGroup,
  selectedUser,
  updateGroupMember,
  updateSubscriber,
  unselectUser,
  addToast,
}) {
  const [selectedRole, setSelectedRole] = useState(null);
  const [groupRoles, setGroupRoles] = useState(null);
  const [subItems, setSubItems] = useState(
    groups.map((g) => ({
      label: g.name,
      value: g.id,
    }))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const communityId = community.id;

  // Initialize subitems
  useEffect(() => {
    if (Validator.hasValue(groups) && !selectedRole) {
      setSubItems(
        groups.map((g) => ({
          label: g.name,
          value: g.id,
        }))
      );
    }
  }, [groups, selectedRole]);

  // set selected = false for all subitems when selectedUser changes
  useEffect(() => {
    if (!isLoadingRoles && Validator.hasValue(subItems)) {
      const newSubItems = subItems.map((item) => {
        return { ...item, selected: false };
      });
      setSubItems(newSubItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  // get the role of selected user
  useEffect(() => {
    if (!selectedUser || !Validator.hasValue(groups) || isLoadingRoles) {
      return;
    }
    setIsLoadingRoles(true);
    RoleService.getRole(communityId, selectedUser.id)
      .then(({ user, selectedRole, groupRoles }) => {
        setSelectedRole(selectedRole);
        setGroupRoles(groupRoles);
      })
      .catch((err) => {
        console.log("RoleService.getRole failed", { err });
        addToast("Failed to get role", "", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoadingRoles(false);
        console.log("RoleService.getRole finally");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, communityId, groups]);

  // Reset the the
  useEffect(() => {
    if (!selectedRole) {
      return;
    }
    const newSubItems = subItems.map((item) => {
      const groupRole = groupRoles[item.value];
      item.selected = groupRole === selectedRole;
      return { ...item };
    });
    setSubItems(newSubItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  const updateItem = useCallback(
    (value, selected) => {
      // find subitem with value and change its selected value
      const newSubItems = subItems.map((item) => {
        if (item.value === value) {
          return { ...item, selected };
        }
        return { ...item };
      });
      setSubItems(newSubItems);
    },
    [subItems]
  );

  if (!selectedUser) {
    return <></>;
  }

  const onRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    RoleService.updateRole(
      community.id,
      selectedUser.id,
      selectedRole,
      subItems.filter((item) => item.selected).map((item) => item.value)
    )
      .then(({ message, userRoleUpdateStatus }) => {
        // update activegroup memeber if needed
        if (
          activeGroup &&
          Object.keys(userRoleUpdateStatus).includes(activeGroup.id)
        ) {
          updateGroupMember({
            ...selectedUser,
            groupRole: userRoleUpdateStatus[activeGroup.id],
          });

          // update subscriber if needed
          updateSubscriber({
            ...selectedUser,
            groupRole: userRoleUpdateStatus[activeGroup.id]
              ? userRoleUpdateStatus[activeGroup.id]
              : selectedUser.groupRole,
            communityRole: selectedRole === "superadmin" ? "admin" : "user",
          });
        }
        addToast(message);
      })
      .catch((error) => {
        console.error("submitForm ~ error:", error);
        addToast(error.message, "", ToastTypes.danger);
      })
      .finally(() => {
        setIsLoading(false);
        unselectUser(false);
      });
  };

  return (
    <Modal
      className="theme-bg-surface"
      setActive={unselectUser}
      active={unselectUser}
      padding={false}>
      {/* headers */}
      <div className="pl-4 pr-2 py-2 theme-theme-bg-default  flex items-center justify-between">
        <span className="font-bold theme-text-heading-1">Change Role</span>
        <IconButton2
          hoverable={true}
          icon="cross"
          size="xs"
          iconClass="theme-text-subtitle-2 text-xs"
          onClick={() => {
            unselectUser(false);
          }}
        />
      </div>
      {/* body */}
      <div className="p-5 theme-text-heading-1 theme-bg-surface">
        {/* user detail */}
        <div className="theme-theme-bg-default  p-2 rounded">
          <Avatar
            user={selectedUser}
            tag={<span className="text-xs ml-1">• {selectedUser.userId}</span>}
            extraInfo={[
              selectedUser.countryCode + "-" + selectedUser.mobile,
              selectedUser.email,
            ].join(" • ")}
          />
        </div>

        {/* selections */}
        {isLoadingRoles ? (
          <div className="flex flex-col gap-4 justify-center items-center h-full animate-pulse">
            <div className="h-32 w-full border theme-default-border theme-bg-default " />
            <div className="h-64 w-full border theme-default-border theme-bg-default " />
            <div className="h-24 w-full border theme-default-border theme-bg-default " />
          </div>
        ) : (
          <>
            <div className="py-6">
              <SelectionOption
                title="Super Admin"
                subtitle="Super admin of community will have full access to the community settings"
                value="superadmin"
                selected={selectedRole === "superadmin"}
                onSelect={onRoleSelect}
              />
              <SelectionOption
                title="Group Admin"
                subtitle="Admin of selected group can edit the group information and create, edit or delete Channel under selected groups."
                subItems={subItems}
                updateItem={updateItem}
                value="admin"
                selected={selectedRole === "admin"}
                onSelect={onRoleSelect}
              />
              <SelectionOption
                title="Group Moderator"
                subtitle="Group Moderator can create or delete post, comment, reply &amp; message in the group."
                subItems={subItems}
                updateItem={updateItem}
                value="moderator"
                selected={selectedRole === "moderator"}
                onSelect={onRoleSelect}
              />
              <SelectionOption
                title="User"
                value="user"
                selected={selectedRole === "user"}
                onSelect={onRoleSelect}
              />
            </div>
            {/* submit button */}
            <div className="my-5">
              <div className="mx-auto flex justify-between items-center">
                <Button
                  onClick={(e) => {
                    unselectUser(false);
                  }}
                  flat
                  className="p-4"
                  label="Cancel"
                  large
                />
                <Button
                  disabled={!selectedRole}
                  onClick={submitForm}
                  className="p-4"
                  label={"Save"}
                  large
                  isLoading={isLoading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

function SelectionOption({
  value,
  title,
  subtitle,
  subItems = [], // { label, value }
  updateItem = (e) => {},
  selected = false,
  onSelect = (e) => {},
}) {
  return (
    <div
      className={cx(
        "border hover:theme-border-primary rounded px-4 py-3 cursor-pointer mb-3",
        {
          "theme-border-primary": selected,
        }
      )}
      onClick={(e) => {
        onSelect(value);
      }}>
      {/* details */}
      <div className="flex">
        <Radio selected={selected} />
        <div className="flex-grow ml-2">
          <div className="theme-text-heading-1 font-semibold">{title}</div>
          {subtitle ? (
            <div className="theme-text-subtitle-2 text-sm mt-2">{subtitle}</div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* subitems */}
      {selected && subItems.length > 0 ? (
        <div className="border-t mt-2 py-2">
          {subItems.map((item) => (
            <div
              key={item.value + item.selected}
              className="my-2"
              onClick={(e) => {
                e.preventDefault();
                updateItem(item.value, !item.selected);
              }}>
              <Checkbox selected={item.selected} />
              <div className="flex-grow ml-8">
                <div className="theme-text-subtitle-2 hover:theme-text-subtitle-1">
                  {item.label}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

const UpdateRoleModal = withToast(
  connect((s) => ({
    community: s.community,
    user: s.auth,
    groups: s.groups,
  }))(UpdateRoleModalComponent)
);

export default UpdateRoleModal;
