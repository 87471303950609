// import { DyteMeeting, Meeting } from "dyte-client";
import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createGroupPageUrlFromSlug, getGroupPageRouteFromSlug } from ".";
import { useAppService } from "../../hooks/use-app-service";
import { Button, Loader } from "../../_components";
import I18 from "../../_components/atoms/i18";
import PaymentPopup from "../../_components/group/payment-popup.component";
import { history } from "../../_config";
import { GroupService } from "../../_service";
import AnalyticsService, { EventType } from "../../_service/analytics-service";
import { SubscriptionUtils } from "../../_utils/subscription/subscription-util";
import { GroupCheckoutComponents } from "./group-checkout-components";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

function JoinButtonComponent({
  community,
  user,
  socket,
  group,
  setGroup,
  mySubscription,
  subscriptionsPlan,
  openGroupOnJoined = false,
  sectionAuth = "",
  sectionType = ""
}) {
  // group limits - temporary

  const [isProcessing, setIsProcessing] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [buyNowModal, setBuyNowModal] = useState(false);

  const plans = useMemo(
    () =>
      SubscriptionUtils.filterSubscriptionsForGroup(
        group.id,
        subscriptionsPlan
      ),
    [subscriptionsPlan, group.id]
  );

  const { analyticsService } = useAppService();

  const takeAnalyticsOnButtonClick = (joinStatus) => {
    analyticsService.track("button-on-group-card-in-explore-group", {
      joinStatus 
    })
  }

  // If any subscription plan contains the group id, then it is a paid group
  // Here if plan is not null and any of the subscription plan contains the group id, then it is a paid group
  const isPaidGroup = SubscriptionUtils.hasPaidSubscription(plans, group.id);

  if (!community) {
    return <></>;
  }
  if (!user) {
    // if not user show login option
    // Login button
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          takeAnalyticsOnButtonClick(group.joinStatus)
          window.location.href = "/login";
          // window.location.href = process.env.REACT_APP_CLIENT_URL + (window.location.port ? (":" + window.location.port) : "") + "?redirect=" + window.location.origin;
        }}
        className="border theme-bg-primary theme-text-on-primary font-semibold rounded-lg px-5 py-2 focus:outline-none">
        <I18>Join</I18>
      </button>
    );
  }

  if (group.joinStatus === "joined") {
    return (
      <button
        className="border theme-border-default theme-bg-surface  font-semibold rounded-lg px-5 py-2 focus:outline-none"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          takeAnalyticsOnButtonClick(group.joinStatus)
          if (openGroupOnJoined) {
            history.push(getSegmentedPath(getGroupPageRouteFromSlug(group)));
          }
        }}>
        <I18>Joined</I18>
      </button>
    );
  } else if (group.joinStatus === "requested") {
    return (
      <Button label="Requested" outlined disabled className="min-w-[90px]" onClick={(e) => {
        e.preventDefault();
        takeAnalyticsOnButtonClick(group.joinStatus);
      }}/>
    );
  } else if (group.joinStatus === "invited") {
    return (
      <Button
        label="Accept Invitation"
        outlined
        isLoading={isProcessing}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsProcessing(true);
          takeAnalyticsOnButtonClick(group.joinStatus)
          GroupService.acceptInvitation(user, group.id)
            .then((response) => {
              // update group
              setGroup(response.group);
              // set loading to false
              setIsProcessing(false);
            })
            .catch((error) => {
              setIsProcessing(false);
            });
        }}
      />
    );
  } else if (group.groupType === "closed-free" && isPaidGroup) {
    return (
      <div>
        <Button
          isLoading={isPaying}
          label={"Buy Now"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            takeAnalyticsOnButtonClick(group.joinStatus)
            setBuyNowModal(true);
          }}
          outlined
          btnColor="red"
        />

        <PaymentPopup
          group={group}
          setGroup={setGroup}
          active={isPaying}
          setActive={setIsPaying}
          user={user}
        />
        <GroupCheckoutComponents
          community={community}
          user={user}
          plans={plans}
          header={group?.name}
          setActive={setBuyNowModal}
          active={buyNowModal}
          mySubscription={mySubscription}
          socket={socket}
        />
      </div>
    );
  } else if (group.groupType === "closed-free" && !isPaidGroup) {
    return (
      <Button
        label="Request Access"
        className="min-w-[120px]"
        isLoading={isProcessing}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsProcessing(true);
          takeAnalyticsOnButtonClick(group.joinStatus)
          GroupService.joinGroup(user, group.id)
            .then((response) => {
              // update group
              setGroup(response.group);
              // set loading to false
              setIsProcessing(false);
              AnalyticsService.logEvent(
                EventType.Group.name,
                EventType.Group.join
              );
              analyticsService.track("join-group", {
                userType: community?.myRole,
                groupName: group.name,
                description: group.description,
                groupType: group.groupType,
                status: group.joinStatus,
                groupUrl: createGroupPageUrlFromSlug(group),
              });
            })
            .catch((error) => {
              setIsProcessing(false);
            });
        }}
      />
    );
  } else if (group.joinStatus === "declined") {
    return <Button label="  " outlined disabled btnColor="red" onClick={(e) => {
      e.preventDefault();
      takeAnalyticsOnButtonClick(group.joinStatus);
    }}/>;
  } else {
    return (
      <Button
        label="Join"
        isLoading={isProcessing}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsProcessing(true);
          takeAnalyticsOnButtonClick(group.joinStatus);
          if(sectionType !== "" || sectionAuth !== ""){
            analyticsService.track("join-section", {
              sectionAuth,
              sectionType
            })
          }
          GroupService.joinGroup(user, group.id)
            .then((response) => {
              // update group
              setGroup(response.group);
              // set loading to false
              setIsProcessing(false);
              AnalyticsService.logEvent(
                EventType.Group.name,
                EventType.Group.join
              );
              analyticsService.track("join-group", {
                userType: community?.myRole,
                groupName: group.name,
                description: group.description,
                groupType: group.groupType,
                status: group.joinStatus,
                groupUrl: createGroupPageUrlFromSlug(group),
              });
            })
            .catch((error) => {
              setIsProcessing(false);
            });
        }}
      />
    );
  }
}
export function JoinLink({ group, setGroup, user, openGroupOnJoined = false }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPaying, setIsPaying] = useState(false);

  // if not user show login option
  if (!user) {
    // Login button
    return (
      <button
        onClick={(e) => {
          window.location.href =
            process.env.REACT_APP_CLIENT_URL +
            (window.location.port ? ":" + window.location.port : "") +
            "?redirect=" +
            window.location.origin;
        }}
        className="theme-text-primary font-semibold focus:outline-none">
        <I18>Join</I18>
      </button>
    );
  }
  if (isProcessing) {
    return (
      <div className="px-4">
        <Loader />
      </div>
    );
  }

  if (group.joinStatus === "joined") {
    return (
      <button
        className="theme-text-primary font-semibold focus:outline-none"
        onClick={(e) => {
          if (openGroupOnJoined) {
            history.push(getSegmentedPath(getGroupPageRouteFromSlug(group)));
          }
        }}>
        <I18>Joined</I18>
      </button>
    );
  } else if (group.joinStatus === "invited") {
    return (
      <button
        onClick={() => {
          setIsProcessing(true);
          GroupService.acceptInvitation(user, group.id)
            .then((response) => {
              // update group
              setGroup(response.group);
              // set loading to false
              setIsProcessing(false);
            })
            .catch((error) => {
              setIsProcessing(false);
            });
        }}
        className="theme-text-primary font-semibold focus:outline-none">
        <I18>Accept Invitation</I18>
      </button>
    );
  } else if (group.joinStatus === "requested") {
    return (
      <button className="theme-text-primary font-semibold focus:outline-none">
        <I18>Requested</I18>
      </button>
    );
  } else if (group.joinStatus === "declined") {
    return (
      <button className="theme-text-danger font-semibold focus:outline-none">
        <I18>Declined</I18>
      </button>
    );
  } else if (group.groupType === "closed-free") {
  } else if (group.groupType === "open") {
    return (
      <button
        onClick={() => {
          setIsProcessing(true);
          GroupService.joinGroup(user, group.id)
            .then((response) => {
              // update group
              setGroup(response.group);
              // set loading to false
              setIsProcessing(false);
            })
            .catch((error) => {
              setIsProcessing(false);
            });
        }}
        className="theme-text-primary font-semibold focus:outline-none">
        <I18>Join</I18>
      </button>
    );
  }

  return (
    <button
      onClick={() => {
        setIsProcessing(true);
        GroupService.joinGroup(user, group.id)
          .then((response) => {
            // update group
            setGroup(response.group);
            // set loading to false
            setIsProcessing(false);
          })
          .catch((error) => {
            setIsProcessing(false);
          });
      }}
      className="theme-text-primary font-semibold rounded-lg focus:outline-none">
      <I18>Request Access</I18>
    </button>
  );
}

const JoinButton = connect(
  (s) => ({
    community: s.community,
    socket: s.socket,
    mySubscription: s.mySubscription,
    subscriptionsPlan: s.subscriptionsPlan,
  }),
  (d) => bindActionCreators({}, d)
)(JoinButtonComponent);
export default JoinButton;
