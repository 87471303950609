import axios from "axios";
import { endpoints } from "../_config";

class EmailService {
  /**
   * Send community join invites using email addresses
   * @param {*} user
   * @param {*} communityId
   * @param {*} data - data contains memberData an array of objects containing email and name. Example: [{email: "jondoe@email.com", name: "Jon Doe"}]
   * @returns
   */
  static async inviteByEmail(user, communityId, data) {
    return axios
      .post(endpoints.community.invite.inviteViaEmail(communityId), data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Sends community join invite using CSV file
   * @param {*} user
   * @param {*} communityId
   * @param {*} payload
   * @param {*} file - CSV file
   * @param {*} setPercentComplete - callback function to set percent complete
   */
  static async inviteByCSV(
    user,
    communityId,
    payload,
    file,
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    formData.append("groupId", payload.groupId);
    formData.append("additionalMessage", payload.additionalMessage);
    formData.append("memberDataCSV", file);

    return axios
      .post(endpoints.community.invite.inviteViaCsv(communityId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentComplete(percentCompleted);
        },
      })
      .then((response) => response.data);
  }

  /**
   * Ger community join invite list
   * @param {*} user - user object
   * @param {String} communityId - community id
   * @param {String} query - query string
   * @param {Number} page - page number
   */
  static async getInvitesList(user, communityId, query, pageNo) {
    return axios
      .get(
        endpoints.community.invite.getInvitesList(communityId, query, pageNo),
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Save mail template
   * @param {*} user - user object
   * @param {String} communityId - community
   * @param {String} template - template
   */
  static async saveMailTemplate(user, communityId, template) {
    return axios
      .post(
        endpoints.community.invite.configureMailTemplates(communityId),
        {
          configurableValue: template,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get mail template
   * @param {*} user - user object
   * @param {String} communityId - community
   */
  static async getMailTemplate(user, communityId) {
    return axios
      .get(endpoints.community.invite.configureMailTemplates(communityId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * @description - Send bulk mail to all the members of community or selected groups
   * @param {*} user - user object
   * @param {String} communityId - community
   * @param {Object} payload - payload
   */
  static async sendBulkMail(user, communityId, payload) {
    return axios
      .post(endpoints.community.bulkEmail.send(communityId), payload, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * @description - Get bulk mail list
   * @param {*} user - user object
   * @param {String} communityId - community
   * @param {String} query - query string
   * @param {Number} page - page number
   */
  static async getBulkMailList(user, communityId, query, pageNo) {
    return axios
      .get(
        endpoints.community.bulkEmail.getBulkEmailLogList(
          communityId,
          query,
          pageNo
        ),
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * @description - Resend bulk mail using invites Ids.
   * @param {Array<string>} inviteIds - user object
   */
  static async resendBulkMail(user, communityId, inviteIds) {
    return axios
      .post(
        endpoints.community.invite.batchReInvite(communityId),
        { inviteIds },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * @description - Resend bulk mail using invites Ids.
   * @param {Array<string>} inviteIds - user object
   */
  static async cancelBulkMail(user, communityId, inviteIds) {
    return axios
      .post(
        endpoints.community.invite.cancelBatchInvite(communityId),
        { inviteIds },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
}

export { EmailService };
