import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  getPost: (postId) => base + "open/post/" + postId,
  getPostList: (groupId, tabId) =>
    base + "open/group/" + groupId + "/feed/" + tabId + "/posts",
  getFontData: base + "/font-data",
};
