// actions
export const GET_COMMUNITY_LINKS = "GET_COMMUNITY_LINKS";
export const SET_COMMUNITY_LINKS = "SET_COMMUNITY_LINKS";
export const ADD_COMMUNITY_LINK = "ADD_COMMUNITY_LINK";
export const ADD_COMMUNITY_LINK_SUCCESS = "ADD_COMMUNITY_LINK_SUCCESS";

export const EDIT_COMMUNITY_LINK = "EDIT_COMMUNITY_LINK";
export const EDIT_COMMUNITY_LINK_SUCCESS = "EDIT_COMMUNITY_LINK_SUCCESS";

export const REMOVE_COMMUNITY_LINK = "REMOVE_COMMUNITY_LINK";
export const REMOVE_COMMUNITY_LINK_SUCCESS = "REMOVE_COMMUNITY_LINK_SUCCESS";

export function getCommunityLinksAction() {
  return {
    type: GET_COMMUNITY_LINKS,
  };
}

export function setCommunityLinksAction(links) {
  return {
    type: SET_COMMUNITY_LINKS,
    links,
  };
}

export function addCommunityLinkAction(link) {
  return {
    type: ADD_COMMUNITY_LINK,
    link,
  };
}

export function addCommunityLinkActionSuccess(link) {
  return {
    type: ADD_COMMUNITY_LINK_SUCCESS,
    link,
  };
}

export function editCommunityLinkAction(link) {
  return {
    type: EDIT_COMMUNITY_LINK,
    link,
  };
}

export function editCommunityLinkActionSuccess(link) {
  return {
    type: EDIT_COMMUNITY_LINK_SUCCESS,
    link,
  };
}

export function removeCommunityLinkAction(linkId) {
  return {
    type: REMOVE_COMMUNITY_LINK,
    linkId,
  };
}

export function removeCommunityLinkActionSuccess(linkId) {
  return {
    type: REMOVE_COMMUNITY_LINK_SUCCESS,
    linkId,
  };
}

// thunk

// reducer
const INITIAL_STATE = {
  isLoadingList: false,
  links: [],
  errorMessage: null,
  updatingLinkId: null,
};

// using as use effect right now
export function communityLinksReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMUNITY_LINKS:
      return {
        ...state,
        isLoadingList: true,
        links: [],
        errorMessage: null,
        updatingLinkId: null,
      };
    case SET_COMMUNITY_LINKS:
      return {
        ...state,
        isLoadingList: true,
        links: action.links,
        errorMessage: null,
        updatingLinkId: null,
      };
    default:
      return state;
  }
}
