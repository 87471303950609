import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  getGroupWidget: (communityId, groupId) =>
    base + `community/${communityId}/widgets?groupId=${groupId}`,
  createCustomWidget: (communityId) =>
    base + `community/${communityId}/widgets`,
  uploadCustomCustomWidgetPicture: (communityId, widgetId) =>
    base + `community/${communityId}/widgets/${widgetId}/upload-picture`,
  updateSortOrder: (communityId) =>
    base + `community/${communityId}/widgets/sort`,
  updateWidget: (communityId, widgetId) =>
    base + `community/${communityId}/widgets/${widgetId}`,
  deleteCustomWidget: (communityId, widgetId) =>
    base + `community/${communityId}/widgets/${widgetId}`,
};
