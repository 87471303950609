import { Suspense, lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import cx from "classnames";
import { Button } from "../../..";
import { withToast } from "../../../../contexts/toastr.context";
import { history } from "../../../../_config";
import {
  createGroupSectionPageUrl,
  getGroupPageRouteFromSlug,
} from "../../../../_pages";
import { NotificationLevel } from "../../../../_pages/community-settings/notification-centre.page";
import JoinButton from "../../../../_pages/group/group-join-button.component";
import { GroupService } from "../../../../_service";
import { store } from "../../../../_store";
import {
  setActiveGroup,
  setGroups,
} from "../../../../_store/_actions/group.actions";
import { updateNotificationPreferenceForSection } from "../../../../_store/_actions/notification-centre.actions";
import { setPersonalGroup } from "../../../../_store/_actions/personal-group.actions";
import { ActionModal } from "../../../action-modal.component";
import EmbedLinkModal from "../../../embed-link-modal.component";
import IconMenu2, { ActionType } from "../../../icon-menu-2.component";
import { Loader } from "../../../loader.component";
import CreatePost from "../../../post/create";
import PremiumFeature from "../../../premium/premium-feature.component";
import { ShareTextModal } from "../../../share-text-modal.component";
import Label from "../../../typography/label.typography";
import UIcon from "../../../uicon-component";
import { CreateGroupModal } from "../../create-group-modal.component";
import { CreateSectionModal } from "../../create-section-modal.component";
import ClosedSectionRequestButton from "./closed-section-request-button.component";
import { getSegmentedPath } from "../../../../_utils/segmented-community-util";
const GoLiveButton = lazy(() =>
  import("../../../post/create/go-live-button.component")
    .then(({ GoLiveButton }) => ({ default: GoLiveButton })),
);

/**
 * @description - function to check is user is allowed to create post in section
 * @param {Object} group - group˝ object
 * @param {Object} section - section object
 */
export const isUserAllowedToPostInSection = (group: any, tab: any) =>
  group.joinStatus === "joined" &&
  (tab && tab.postLevel === "admin"
    ? group.myRole === "admin" || group.myRole === "moderator"
    : true);

function SectionInfoComponent({
  group,
  community,
  communityPac,
  setGroup,
  user,
  activeTab,
  activeTabModel,
  setActiveTab,
  showShareModal,
  setShowShareModal,
  setPersonalGroup,
  personalGroup,
  notificationCentre,
  addPost = (e: any) => {},
  addToast = (e: any) => {},
  updateGroups = (e: any) => {},
  updateActiveGroup = (e: any) => {},
}: any) {
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [isEditingTab, setIsEditingTab] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // Stores the active tab notification preference
  const [notificationLevel, setNotificationLevel] = useState<any>(
    NotificationLevel.NONE
  );

  // create a check if user can post in group
  let isUserAllowedToPost = isUserAllowedToPostInSection(group, activeTabModel);
  // group.joinStatus === "joined" &&
  // (activeTabModel && activeTabModel.postLevel === "admin"
  //   ? group.myRole === "admin" || group.myRole === "moderator"
  //   : true);

  // Initialize the notification preference for selected tab
  useEffect(() => {
    if (notificationCentre.preferences && activeTabModel) {
      const preferences = notificationCentre.preferences;
      if (preferences.tabs && preferences.tabs.length > 0) {
        // Check if the user has a preference for this section
        const tab = preferences.tabs.find(
          (tab: { tabId: any }) => tab.tabId === activeTabModel.id
        );
        if (tab) {
          setNotificationLevel(tab.notificationLevel);
        }
        // If not, set the default preference to NotificationLevel.ALL
        else {
          setNotificationLevel(NotificationLevel.ALL);
        }
      } else {
        setNotificationLevel(NotificationLevel.ALL);
      }
    }
  }, [notificationCentre, activeTab]);

  const onSubmitDelete = (e: any) => {
    // delete section
    setIsLoading(true);
    GroupService.deleteGroupTab(
      user,
      personalGroup && personalGroup.id === group.id
        ? personalGroup.id
        : group.id,
      activeTabModel.id
    )
      .then(({ group }) => {
        // update the group
        setIsLoading(false);
        // resetFormAndClose();
        addToast("Channel deleted!", "", "success");
        setIsDeleteModalVisible(false);
        if (personalGroup && personalGroup.id === group.id) {
          setPersonalGroup(group);
          history.push(getSegmentedPath(`/personal-space?tab=${personalGroup.tabs[0].id}`));
        } else {
          updateActiveGroup(group);
        }
      })
      .catch((error) => {
        console.log({ error });
        addToast("Unable to delete channel!", "", "danger");
        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className="my-2 block font-semibold cursor-pointer"
        onClick={() => history.push(getSegmentedPath(getGroupPageRouteFromSlug(group)))}>
        ᐸ &nbsp; {group.name}
      </div>

      {/* section info */}
      <div className="flex flex-col">
        <div className="SectionInfoComponent rounded theme-bg-surface mb-4 py-2">
          {/* section title */}
          <div className="flex flex-col sm:flex-row items-stretch justify-between">
            <div
              className={cx(
                "font-bold theme-text-heading-1 p-4 pb-3 overflow-ellipsis max-w-[36vh]",
                {
                  "text-xl": activeTabModel,
                  "text-2xl": !activeTabModel,
                }
              )}>
              {activeTabModel.emoji + " " + activeTabModel.name}
            </div>

            <div className="flex flex-wrap items-center space-x-2 gap-1 md:gap-0">
              {/* join / invite button, on group level only */}
              <div className=" order-1 md:order-none">
                {group.joinStatus === "joined" ? (
                  <>
                    {activeTabModel.isClosed && !activeTabModel.isJoined ? (
                      activeTabModel.status === "requested" ? (
                        <Button
                          onClick={() => {
                            // make the request access to the closed section
                          }}
                          label="Requested"
                          disabled
                        />
                      ) : (
                        <ClosedSectionRequestButton
                          // @ts-ignore
                          activeTabModel={activeTabModel as any}
                          user={user}
                          group={group}
                          updateActiveGroup={updateActiveGroup}
                          setGroup={setGroup}
                          addToast={addToast}
                        />
                      )
                    ) : (
                      // Display the section notification preference button

                      <IconMenu2
                        icon="bell"
                        solid={notificationLevel !== NotificationLevel.NONE}
                        className={cx("", {
                          "theme-text-primary":
                            notificationLevel !== NotificationLevel.NONE,
                        })}
                        actions={[
                          // For All notifications
                          {
                            label: "All posts",
                            // icon: "view-grid",
                            onClick: () => {
                              let tab = {
                                tabId: activeTabModel.id,
                                groupId: group.id,
                                notificationLevel: NotificationLevel.ALL,
                              };
                              store.dispatch(
                                updateNotificationPreferenceForSection(
                                  tab,
                                  addToast
                                )
                              );
                            },
                            actionType:
                              notificationLevel === NotificationLevel.ALL
                                ? ActionType.primary
                                : ActionType.default,
                          },
                          // For admin posts only
                          {
                            label: "Admin posts only",
                            // icon: "view-grid",
                            onClick: () => {
                              let tab = {
                                tabId: activeTabModel.id,
                                groupId: group.id,
                                notificationLevel: NotificationLevel.ADMIN,
                              };
                              store.dispatch(
                                updateNotificationPreferenceForSection(
                                  tab,
                                  addToast
                                )
                              );
                            },
                            actionType:
                              notificationLevel === NotificationLevel.ADMIN
                                ? ActionType.primary
                                : ActionType.default,
                          },
                          // For no notifications
                          {
                            label: "None",
                            // icon: "view-grid",
                            onClick: () => {
                              let tab = {
                                tabId: activeTabModel.id,
                                groupId: group.id,
                                notificationLevel: NotificationLevel.NONE,
                              };
                              store.dispatch(
                                updateNotificationPreferenceForSection(
                                  tab,
                                  addToast
                                )
                              );
                            },
                            actionType:
                              notificationLevel === NotificationLevel.NONE
                                ? ActionType.primary
                                : ActionType.default,
                          },
                        ]}
                      />
                    )}
                  </>
                ) : (
                  // Display join Group button if group is open and active section is not private .
                  !activeTabModel.isPrivate && (
                    <>
                      {
                        // Hide Join group button if group is closed and section is also closed
                        group.groupType !== "closed-free" &&
                          !activeTabModel.isClosed && (
                            <JoinButton
                              group={group}
                              setGroup={setGroup}
                              user={user}
                              sectionAuth={activeTabModel.postLevel}
                              sectionType={activeTabModel.sectionType}
                            />
                          )
                      }
                    </>
                  )
                )}
              </div>
              {/* 
                go live button
                - Hide for AppSumo communities
              */}
              {!community.isDealRedeemed &&
              (!activeTabModel ||
                !activeTabModel.isClosed ||
                activeTabModel.isJoined) ? (
                <PremiumFeature isAvailable={communityPac.liveSession}>
                  <Suspense fallback={<></>}>
                    <GoLiveButton
                      groupId={group.id}
                      group={group}
                      tabId={activeTabModel.id}
                      setIsLoading={(e: any) => {}}
                      user={user}
                      community={community}
                      title={""}
                    />
                  </Suspense>
                </PremiumFeature>
              ) : (
                <></>
              )}
              {/* create post button */}
              {isUserAllowedToPost &&
              (!activeTabModel.isClosed || activeTabModel.isJoined) ? (
                <CreatePost
                  group={group}
                  sectionId={activeTabModel.id}
                  addPost={(post: { tab: { id: any } }) => {
                    if (
                      post &&
                      post.tab &&
                      post.tab?.id === activeTabModel?.id
                    ) {
                      addPost(post);
                    }
                  }}
                />
              ) : (
                <></>
              )}
              {
                // show menu only if joined
                group.joinStatus === "joined" ? (
                  <div className="flex-1 flex place-content-end order-2 md:order-none">
                    <IconMenu2
                      className="md:mr-0 flex-1 "
                      dropdownClassName="w-52"
                      actions={[
                        {
                          // show share option
                          icon: "share",
                          label: "Share",
                          onClick: () => {
                            setShowShareModal(true);
                          },
                        },

                        group.groupType === "open" &&
                        activeTabModel.isClosed === false
                          ? {
                              // show embed link if active modal
                              icon: "globe",
                              label: activeTabModel ? "Embed Channel" : null,
                              onClick: () => {
                                setEmbedModalVisible(true);
                              },
                            }
                          : null,

                        {
                          // for tab menu
                          icon: "edit",
                          label:
                            activeTabModel && group.myRole === "admin"
                              ? "Edit Channel"
                              : null,
                          onClick: () => {
                            setIsEditingTab(true);
                          },
                        },
                        {
                          // for section delete
                          icon: "trash",
                          label:
                            activeTabModel && group.myRole === "admin"
                              ? "Delete Channel"
                              : null,
                          onClick: () => {
                            setIsDeleteModalVisible(true);
                          },
                          actionType: "alert",
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <></>
                )
              }
              {/* edit tab model */}
              <CreateSectionModal
                activeTab={activeTabModel}
                active={isEditingTab}
                setActive={setIsEditingTab}
              />

              {/* Delete tab model */}
              <ActionModal
                active={isDeleteModalVisible}
                setActive={setIsDeleteModalVisible}
                onSubmit={onSubmitDelete}
                header="Delete Channel"
                title="Are you sure you want to delete the channel?"
                isLoading={isLoading}
                btnColor="red"
                children={undefined}
              />

              <ShareTextModal
                heading="Share"
                text={createGroupSectionPageUrl(group, activeTabModel)}
                moreInfo="Share this link with your members for them to join this group."
                active={showShareModal}
                setActive={setShowShareModal}
                onClose={(e: any) => {
                  setShowShareModal(false);
                }}
              />
              {isLoading ? <Loader /> : <></>}
            </div>
          </div>
        </div>
        {/* Display Create Post Permission */}
        {user &&
          !isUserAllowedToPost &&
          group.myRole === "user" &&
          activeTabModel.postLevel === "admin" && (
            <div className="flex gap-2 p-2 border theme-border-default rounded mb-2 sm:mb-0 mx-2 sm:mx-0">
              <UIcon icon="info" size="sm" />
              <Label size="body" variant="s1" translate>
                Only admin can post here.
              </Label>
            </div>
          )}
      </div>

      {/* group edit modal */}
      <CreateGroupModal
        group={group}
        updateGroup={(group) => {
          addToast("Group updated!", "", "success");
          setGroup(group);
        }}
        active={isCreateGroupModalVisible}
        setActive={setIsCreateGroupModalVisible}
      />
      {/* embed modal */}
      <EmbedLinkModal
        link={
          process.env.REACT_APP_EMBED_URL +
          (window.location.port ? ":" + window.location.port : "") +
          "/group/" +
          group.id +
          "/feed/" +
          activeTabModel.id
        }
        active={isEmbedModalVisible}
        setActive={setEmbedModalVisible}
      />
    </>
  );
}

export const SectionInfo = withToast(
  connect(
    (s: any) => ({
      user: s.auth,
      activeGroup: s.activeGroup,
      groups: s.groups,
      community: s.community,
      communityPac: s.communityPac,
      personalGroup: s.personalGroup,
      notificationCentre: s.notificationCentre,
      activeTabModel: s.sections.activeSectionId
        ? s.sections.sections[s.sections.activeSectionId]
        : null,
    }),
    (d) =>
      bindActionCreators(
        {
          updateGroups: setGroups,
          updateActiveGroup: setActiveGroup,
          setPersonalGroup: setPersonalGroup,
        },
        d
      )
  )(SectionInfoComponent)
);
