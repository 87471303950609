export const svSwedish = {
  "Pensil Keyword": "Swedish",
  "Home Page Left column": "Hemsida vänster kolumn",
  Home: "Hem",
  "Explore Groups": "Utforska grupper",
  Events: "evenemang",
  Groups: "Grupper",
  Links: "Länkar",
  "Direct Message": "Direkt meddelande",
  "Powered by": "Drivs av",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Inga grupper här, använd + för att skapa en ny, eller gå med i en se dem här.",
  "Please join a group to see them here":
    "Gå med i en grupp för att se dem här",
  "Section can only be reordered within the group!":
    "Avsnitt kan endast omordnas i gruppen!",
  "Group Page": "Gruppsida",
  "Invite Members": "Bjuda in medlemmar",
  Join: "Ansluta sig",
  Joined: "Gick med i",
  "Create new section": "Skapa en ny sektion",
  "Group Setting drop down": "Gruppinställning nedgång",
  Share: "Dela med sig",
  "Edit group": "Redigera grupp",
  "Delete group": "Radera grupp",
  Leave: "Lämna",
  "Are you sure you want to delete the group?":
    "Är du säker på att du vill ta bort gruppen?",
  Cancel: "Annullera",
  "Yes, Go Ahead": "Ja gör det",
  "Share via link": "Dela via länk",
  Copy: "Kopiera",
  Copied: "Kopierad",
  "Share on social media": "Dela på sociala medier",
  "Add Section": "Lägga till sektion",
  "Section Header": "Sektionsrubrik",
  Live: "leva",
  "Create Post": "Skapa inlägg",
  "Notificaiton Bell": "Notificaiton Bell",
  "All posts": "Alla inlägg",
  "Admin posts only": "Endast administratörsinlägg",
  None: "Ingen",
  "Section Setting drop down": "Avsnittinställning nedgång",
  "Edit Section": "Redigera avsnitt",
  "Embed Section": "Bädda sektion",
  "Delete Section": "Ta bort avsnitt",
  "Are you sure you want to delete the section?":
    "Är du säker på att du vill ta bort avsnittet?",
  "Section deleted!": "Avsnitt Raderat!",
  "Create Group Pop up / Update": "Skapa grupppop -up / uppdatering",
  "Create group": "Skapa grupp",
  "Basic Detail": "Grundläggande detalj",
  Settings: "inställningar",
  "Size 16:9 or 1600 by 900": "Storlek 16: 9 eller 1600 av 900",
  "Group name": "Grupp namn",
  "Enter group name": "Ange gruppnamn",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Förslag: utrymmen, kanaler, grupper, blogg, kunskapsbas, etc.",
  "Group Description": "Gruppbeskrivning",
  "Enter description here": "Ange beskrivning här",
  "Group Type": "Grupptyp",
  Open: "Öppen",
  "Visible to all & anyone can join":
    "Synlig för alla och vem som helst kan gå med",
  Closed: "Stängd",
  "User will have to request to join the group":
    "Användaren måste begära att gå med i gruppen",
  Paid: "Betalad",
  "Group Price": "Grupppris",
  "Currency (Default: INR)": "Valuta (standard: INR)",
  "User will have to pay to join the group":
    "Användaren måste betala för att gå med i gruppen",
  "Make this a private group": "Gör detta till en privat grupp",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Privat grupp kommer bara att vara synlig för medlemmar som ingår i det. Administratör kan lägga till medlemmar manuellt från medlemslistan",
  "Upload from device": "Ladda upp från enheten",
  "Search image": "Sökbild",
  "Valid group name required!": "Giltigt gruppnamn krävs!",
  "Update Group": "Uppdateringsgrupp",
  "SEO Settings": "SEO -inställningar",
  "Custom URL": "Anpassad URL",
  "Meta Title": "Metatitel",
  "Maximum length of 255 characters allowed":
    "Maximal längd på 255 tecken tillåtet",
  "Meta Description": "Metabeskrivning",
  "Open Graph Detail": "Öppna grafdetalj",
  "Open Graph Title": "Öppen graftitel",
  "Open Graph Description": "Öppna grafbeskrivning",
  "Open Graph Image": "Öppna grafbilden",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Rekommenderade dimensioner: 1200px av 630px med ett bildförhållande på 1,91: 1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Om ingen URL används kommer den automatiskt att plocka din grupp miniatyrbild.",
  "Create Section Pop up / Update": "Skapa avsnitt Pop Up / Update",
  "Create Section": "Skapa avsnitt",
  "Section Name": "Sektionsnamn",
  "Suggestions: General, Introductions, Community Guidelines":
    "Förslag: Allmänt, introduktioner, gemenskapsriktlinjer",
  "Section Type": "Sektionstyp",
  "Post Type": "Posttyp",
  "Group Chat": "Gruppchatt",
  "Who can post in this section?": "Vem kan posta i det här avsnittet?",
  "Only Admin": "Endast administratör",
  "Any user": "Någon användare",
  "Sorting order for section": "Sorteringsorder för avsnitt",
  "Latest first": "Senaste första",
  "Oldest post first": "Äldsta inlägg först",
  "Section Appearance": "Sektionsutseende",
  "List View": "Listvy",
  "Grid View": "Rutnät",
  "Make this closed section": "Gör detta stängda avsnitt",
  "Members will have to request access to see section content":
    "Medlemmarna måste begära åtkomst för att se sektionsinnehåll",
  "Make this a private section": "Gör detta till ett privat avsnitt",
  "Disables requests (Make sure closed section is selected as well).":
    "Inaktiverar förfrågningar (se till att det stängda avsnittet är valt också).",
  "Post Editor": "Postredaktör",
  "Create new post in": "Skapa nytt inlägg i",
  "Add Cover Picture": "Lägg till omslagsbild",
  "Heading (Optional)": "Rubrik (valfritt)",
  "Write here..": "Skriv här..",
  Picture: "Bild",
  Video: "Video",
  Attachment: "Anknytning",
  Poll: "Opinionsundersökning",
  "Embed Code": "Inbäddade koden",
  Tags: "Taggar",
  "Toggle to question": "Växla för att fråga",
  "Poll expire time": "Undersökning löper ut",
  "Poll question": "Undersökningsfråga",
  "Enter Question": "Gå in i frågan",
  Question: "Fråga",
  Option: "Alternativ",
  Options: "alternativ",
  "Add Option": "Lägg till alternativ",
  "Poll Expiry time": "Undersökningstid",
  "12 Hours": "12 timmar",
  "1 Day": "1 dag",
  "2 Days": "2 dagar",
  "1 Week": "1 vecka",
  "2 Weeks": "2 veckor",
  "Always Open": "Alltid öppen",
  "Write embeddable code": "Skriv inbäddningsbar kod",
  "Click here to add embed code.":
    "Klicka här för att lägga till inbäddningskod.",
  "Paste your embed code here!": "Klistra in din inbäddningskod här!",
  "Post Settings": "Inställningar efter inställningar",
  "Update Post": "Uppdatera inlägg",
  Update: "Uppdatering",
  "Start meeting / Video call / Live": "Börja möte / videosamtal / live",
  "Meeting Name": "Mötesnamn",
  "Suggestion: Group discussion etc": "Förslag: Gruppdiskussion etc.",
  Description: "Beskrivning",
  "Enter description here (optional)": "Ange beskrivning här (valfritt)",
  "When do you want to start the session": "När vill du starta sessionen",
  "Right now": "Just nu",
  "Schedule for later": "Schema för senare",
  "Group video call": "Gruppvideosamtal",
  Webinar: "Webbseminarium",
  "Go Live": "Gå och lev",
  "Info on the Post": "Info om inlägget",
  "Post comment": "Publicera kommentar",
  Comment: "Kommentar",
  Comments: "Kommentarer",
  Reply: "Svar",
  Replies: "Svar",
  "Share via URL": "Dela via URL",
  "Reshare in other sections": "Reshare i andra avsnitt",
  "Post Card Drop Down": "Postkortsavlopp",
  Bookmark: "Bokmärke",
  "Pin post": "Stiftstolpe",
  Edit: "Redigera",
  "Embed Post": "Inbäddas",
  Delete: "Radera",
  "Remove Pin": "Ta bort stiftet",
  "Remove Bookmark": "Ta bort bokmärket",
  "Just copy paste this code wherever you want it to be embedded":
    "Kopiera bara klistra in den här koden var du vill att den ska vara inbäddad",
  Height: "Höjd",
  Preview: "Förhandsvisning",
  "Copied to clipboard": "Kopierad till urklipp",
  "Select Widgets": "Välj widgetar",
  "Add widgets to": "Lägg till widgetar till",
  "You can change their order by dragging & dropping the widgets over each other":
    "Du kan ändra beställningen genom att dra och släppa widgets över varandra",
  "Please note: Widget wont be displayed if there is no data available,":
    "Observera: Widgeten visas inte om det inte finns några data tillgängliga,",
  "Group Members": "Gruppmedlemmar",
  "Group Leaderboard": "Grupptavla",
  "Community Leaderboard": "Community learterboard",
  "Trending Posts": "Trender",
  "Group Events": "Gruppevenemang",
  "Add Custom": "Lägg till anpassning",
  "Save Widgets": "Spara widgetar",
  "Visible to user": "Synlig för användaren",
  "Visible to admin": "Synlig för administratör",
  "Add widget to": "Tillsätt widget till",
  "Upload cover picture": "Ladda upp omslagsbilden",
  "1:1 aspect ration (recommended)": "1: 1 Aspektration (rekommenderas)",
  "Card Title": "Korttitel",
  "Enter widget name": "Ange widgetnamn",
  "Small Description (try to keep it under 50 words)":
    "Liten beskrivning (försök att hålla den under 50 ord)",
  "Enter description": "Ange beskrivning",
  "Button Title": "Knapptitel",
  "Enter button name": "Ange knappnamn",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestion: Vet mer, gå med, ansök, etc.",
  "URL link": "URL -länk",
  "Create custom widget": "Skapa anpassad widget",
  "Custom widget created successfully": "Anpassad widget skapade framgångsrikt",
  Widgets: "Widget",
  Members: "Medlemmar",
  "See All": "Se allt",
  "Communtiy Top Contributors": "CommunTiy Top -bidragsgivare",
  "Community Members": "Medlemmar i samhället",
  "Group Top Contributors": "Gruppens toppbidragsgivare",
  "Upcoming Events": "uppkommande händelser",
  "Home Page Banner": "Hemsida banner",
  "Edit Banner": "Redigera banderoll",
  "Home page Edit Banner Pop up": "Hemsida Redigera banner dyker upp",
  "Update Banner": "Uppdatera banner",
  Search: "Sök",
  "Upload Image": "Ladda upp bild",
  Remove: "Avlägsna",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Bannerbild (använd 16: 6 -förhållandet eller 1600px med 600px bild för bästa vy)",
  "Banner Title": "Banner",
  "Enter here": "Gå in här",
  "Suggestion: Welcome to my community etc":
    "Förslag: Välkommen till mitt samhälle etc.",
  "Banner Description": "Bannerbeskrivning",
  "Enter banner description": "Ange bannerbeskrivning",
  "Save Banner Info": "Spara bannerinfo",
  "Home Page Feed": "Hemsida flöde",
  Recent: "Nyligen",
  "Top Posts": "Toppposter",
  Trending: "Trendig",
  "Home Feed Groups": "Hemfodergrupper",
  "Header BarProfile Drop down": "READER BARPROFILE Drop Down",
  "My Profile": "Min profil",
  Logout: "Logga ut",
  Notification: "Underrättelse",
  Notifications: "Meddelanden",
  "Mark all viewed": "Markera alla visade",
  "has created a post": "har skapat ett inlägg",
  "wants to join": "vill gå med",
  "has commented on your post": "har kommenterat ditt inlägg",
  "has replied to your comment": "har svarat på din kommentar",
  "Search for tags": "Sök efter taggar",
  "All groups": "Alla grupper",
  "Search result": "Sökresultat",
  "No post found for selected hashtag":
    "Inget inlägg hittades för vald hashtag",
  "Start a conversation": "Starta en konversation",
  "Search by name": "Sök efter namn",
  Upcoming: "Kommande",
  UPCOMING: "Kommande",
  Past: "Dåtid",
  PAST: "DÅTID",
  "No upcoming events": "Inga kommande evenemang",
  "Create event popup": "Skapa popup -evenemang",
  "Event details": "Evenemangsdetaljer",
  "16:9 aspect ratio": "16: 9 bildförhållande",
  "Event name": "Event namn",
  "Enter event name": "Ange evenemangsnamn",
  "Host Name": "Värdnamn",
  "You will be made host if none selected":
    "Du kommer att bli värd om ingen vald",
  "Write here...": "Skriv här...",
  "No. of registration for the event": "Antal registrering för evenemanget",
  Unlimited: "Obegränsat",
  "When & where": "När & var",
  From: "Från",
  "Start date": "Start datum",
  time: "tid",
  To: "Till",
  "End date": "Slutdatum",
  "Current time zone:": "Aktuell tidszon:",
  "Can be updated from the communtiy setting":
    "Kan uppdateras från kommunens inställning",
  "Online event": "Onlineevenemang",
  "Add meeting link": "Lägg till möteslänk",
  "Meeting link will be generated once event is created":
    "Möteslänk kommer att genereras när evenemanget skapas",
  Venue: "Mötesplats",
  "Enter address": "Ange adressen",
  Create: "Skapa",
  Invite: "Bjuda",
  "Share event via link": "Dela händelse via länk",
  Copy__1: "Kopiera",
  "Select section to post this event":
    "Välj avsnitt för att publicera detta evenemang",
  "Once posted, members of that group or section will recieve an notification":
    "När de har lagts ut kommer medlemmar i den gruppen eller avsnittet att få ett meddelande",
  Post: "Posta",
  RSVP: "RSVP",
  "Book Event": "Bokevenemang",
  "Yes, I'll attend": "Ja, jag kommer att delta",
  "May be": "Kanske",
  "No, I won't attend": "Nej, jag kommer inte att delta",
  "Date & Time": "Datum Tid",
  Host: "Värd",
  "Online meeting": "Onlinemöte",
  "Meeting link will be visible once registered for the event":
    "Möteslänk kommer att synas när den är registrerad för evenemanget",
  "User Management": "Användarhantering",
  "Go bac to community": "Gå Bac till samhället",
  "Manage Members": "Hantera medlemmar",
  Member: "Medlem",
  Invited: "Inbjuden",
  "Search for member": "Sök efter medlem",
  "Add to group": "Lägg till i gruppen",
  "Send Direct Message": "Skicka direktmeddelande",
  "Change role": "Ändra roll",
  "Kick from community": "Kick från samhället",
  "Super Admin": "Superadministratör",
  "Super admin will have full access to the communtiy settings":
    "Super Admin kommer att ha full tillgång till kommunikationsinställningarna",
  "Group Admin": "Gruppadministratör",
  "Admin of the selected group can edit the group information and create, edt or delete section under selected group":
    "Administratör för den valda gruppen kan redigera gruppinformationen och skapa, EDT eller ta bort avsnitt under vald grupp",
  "Group Moderator": "Gruppmoderator",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Gruppmoderator kan skapa eller ta bort inlägg, kommentera, svara och meddelanden i den valda gruppen.",
  User: "Användare",
  Save: "Spara",
  "User role updated successfully": "Användarrollen uppdaterad framgångsrikt",
  Leaderboard: "Topplista",
  "Click on the column header for sorting":
    "Klicka på kolumnhuvudet för sortering",
  Rank: "Rang",
  "Karma Points": "Karma poäng",
  "Custom Points": "Anpassade poäng",
  Follow: "Följ",
  Upvotes: "Utrotning",
  Posts: "Inlägg",
  "Add custom points": "Lägg till anpassade punkter",
  "Subtract custom points": "Subtrahera anpassade punkter",
  "Enter no. of points": "Ange nej. punkter",
  "Add points": "Lägga till poäng",
  "Invite members to": "Bjuda in medlemmar till",
  "Invite via link": "Bjud in via länk",
  "Invite via email": "Bjud in via e -post",
  "Add more": "Lägga till mer",
  "Upload a CSV": "Ladda upp en CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Ladda upp en kommasepraterad CSV-fil, med rubrik som namn, e-post.",
  "Here is a template CSV file": "Här är en mall CSV -fil",
  "Upload File": "Ladda upp fil",
  "Send Invite": "Skicka inbjudan",
  "Mail Template": "Postmall",
  "Send Test Mail": "Skicka testpost",
  Hey: "Hallå",
  "A has invite you to join the B": "A har bjudit in dig att gå med i B",
  "Switch to rich text editor": "Byt till rik textredigerare",
  "Add Custom Message": "Lägg till anpassat meddelande",
  "Enter message here": "Ange meddelandet här",
  "Send Bulk Mail": "Skicka bulkpost",
  "Send Mail": "Skicka brev",
  "Send bulk mail to all community members or select group members":
    "Skicka bulkpost till alla medlemmar i samhället eller välj gruppmedlemmar",
  Date: "Datum",
  Subject: "Ämne",
  Recipients: "Mottagare",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 bulk -e -postmeddelanden kvar för denna månad. Återställs på <datum>",
  Everyone: "Alla",
  "Enter mail subject": "Ange e -postämne",
  "Write message": "Skrivmeddelande",
  Cancel__1: "Annullera",
  Send: "Skicka",
  "Profile Page": "Profilsida",
  Activity: "Aktivitet",
  Bookmarks: "Bokmärken",
  "Edit Profile": "Redigera profil",
  Unsubscribe: "Säga upp",
  "Settings (Account)": "Inställningar (konto)",
  Profile: "Profil",
  "Set profile Picture": "Ställ in profilbild",
  "Your name": "Ditt namn",
  "User ID": "användar ID",
  "Short bio": "Kort bio",
  "Link your social account, this will be displayed on your profile":
    "Länk ditt sociala konto, detta kommer att visas på din profil",
  "Add social handle": "Lägg till socialt handtag",
  Verification: "Verifiering",
  "Account Verification": "Konto verifikation",
  Email: "E-post",
  "Enter email": "Skriv in e-mail",
  Verified: "Verifierad",
  Unverified: "Overifierad",
  Change: "Förändra",
  Unlink: "Länka",
  Mobile: "Mobil",
  "enter mobile no.": "Ange mobil nr.",
  "Request OTP": "Begär OTP",
  "Notification centre": "Anmälningscenter",
  "Manage your personal account notification preferences":
    "Hantera dina personliga informationsmeddelanden",
  Prefrences: "Prefenser",
  "Email notification": "E -postmeddelande",
  "Notify me about comments on my posts":
    "Meddela mig om kommentarer på mina inlägg",
  "Notify me about replies on my comments":
    "Meddela mig om svar på mina kommentarer",
  "Notify me about upvotes on my posts":
    "Meddela mig om Upvotes på mina inlägg",
  "Notify me about reaction on my comments":
    "Meddela mig om reaktion på mina kommentarer",
  "Notify me about reaction on my reply":
    "Meddela mig om reaktion på mitt svar",
  "Notify me about direct message": "Meddela mig om direkt meddelande",
  "Notify me about my mentions": "Meddela mig om mina omnämnanden",
  "Notification for new posts": "Meddelande för nya inlägg",
  "Only group & section available which you are part of":
    "Endast grupp och avsnitt tillgängliga som du är en del av",
  "Group & Section": "Grupp och sektion",
  "All activity": "All aktivitet",
  "Post by admin": "Inlägg av admin",
  "No Notification": "Ingen anmälan",
  "Setting (Community)": "Inställning (community)",
  "General Settings": "Allmänna Inställningar",
  "Upload community logo": "Ladda upp community -logotypen",
  "Community name": "Gruppnamn",
  "Invalid field": "Ogiltig fält",
  "Your community address": "Din samhällsadress",
  "Fav icon": "FAV -ikon",
  "This will be visible on the browser tab (32px by 32px)":
    "Detta kommer att synas på webbläsarfliken (32px med 32px)",
  TimeZone: "Tidszon",
  "Privacy Policy on login page": "Sekretesspolicy på inloggningssidan",
  "Terms & Condition on login page": "Villkor på inloggningssidan",
  "Enter link here": "Ange länken här",
  "Open Group Image": "Öppen gruppbild",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Som standard tar IR upp samhällsbannern som laddas upp med kommunen",
  "Community Experience": "Samhällsupplevelse",
  Onboarding: "Ombord",
  "Make your community private": "Gör ditt samhälle privat",
  "Users will have to login or sign up to access the community":
    "Användare måste logga in eller registrera sig för att komma åt samhället",
  "Make account verification mandatory for both email & mobile":
    "Gör kontoverifiering obligatorisk för både e -post och mobil",
  "New user will have to verify both email & mobile no. to access the community":
    "Ny användare måste verifiera både e -post och mobil nr. för att få tillgång till samhället",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Admin måste också verifiera sitt mobilnummer. & e -post annars kommer de inte att kunna komma åt samhället",
  "Gated community access": "Gated community access",
  "Admin will have to approve or reject user's requests to join the community":
    "Admin måste godkänna eller avvisa användarens förfrågningar om att gå med i samhället",
  "Disable mobile login/register": "Inaktivera mobil inloggning/register",
  "Disable email login/register": "Inaktivera e -postinloggning/register",
  "Disable google login/register": "Inaktivera Google -inloggning/register",
  "Disable facebook login/register": "Inaktivera Facebook -inloggning/register",
  "Show & Hide features": "Visa och dölj funktioner",
  "Hide direct chat": "Dölj direktchatt",
  "User will not be able to send direct messages to each other":
    "Användaren kommer inte att kunna skicka direktmeddelanden till varandra",
  "Hide profile picture from top right corner for mobile responsive view":
    "Dölj profilbild från det övre högra hörnet för mobil responsiv vy",
  "Useful when using community in an in app browser":
    "Användbar när du använder community i en app -webbläsare",
  "Hide profile picture from top right corner in every view":
    "Dölj profilbild från det övre högra hörnet i varje vy",
  "Disable header dropdown on profile picture":
    "Inaktivera rullgardinsmenet för rubrik på profilbild",
  "Useful for integrated community, especially using SSO":
    "Användbart för integrerat samhälle, särskilt med SSO",
  "Hide the communtiy logo from the header":
    "Dölj kommunens logotyp från rubriken",
  "Hide the communtiy title from the header":
    "Dölj kommunens titel från rubriken",
  "Show collpased group in the side nav bar":
    "Visa kollegerad grupp i sido -navbaren",
  "Show full expanded post in the home feed":
    "Visa ett fullt utökat inlägg i hemmatflödet",
  "Hide the leaderboard score from the header":
    "Dölj spånen med topplistan från rubriken",
  "Show powered by Pensil": "Visa drivna av Pensil",
  "Hide closed groups if not joined":
    "Dölj stängda grupper om de inte är förenade",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Stängda grupper skulle inte visas för användaren om de inte är medlem i gruppen",
  "Hide Community subscriber count from the users":
    "Dölj community -abonnentantalet från användarna",
  "This will hide the community subscriber list in the community header":
    "Detta kommer att dölja gemenskapens abonnentlista i gemenskapens rubrik",
  "Disable Communtiy Switcher": "Inaktivera CommunTiy Switcher",
  "Use MD editor for create post description":
    "Använd MD -redigerare för Create Post Description",
  "Non logged in user will land on explore group page instead of post directory":
    "Icke inloggad användare kommer att landa på utforska gruppsidan istället för postkatalog",
  "Advanced configurable": "Avancerad konfigurerbar",
  "Communtiy default country code": "CommunTiy Standard Country Code",
  "This will show up by default on login/signup":
    "Detta kommer som standard att dyka upp på inloggning/registrering",
  "Onboarding/Welcome Message": "Onboarding/Welcome Message",
  "Welcome Message": "Välkomstmeddelande",
  "Greet your members with personalized message":
    "Hälsa dina medlemmar med personligt meddelande",
  "Welcome to": "Välkommen till",
  Message: "Meddelande",
  "Add message": "Lägg till meddelande",
  "Custom domain": "Anpassad domän",
  "Add custom domain": "Lägg till anpassad domän",
  "Create new request": "Skapa ny begäran",
  "Enter your custom domain": "Ange din anpassade domän",
  "Setup details": "Inställningsdetaljer",
  "Add a CNAME record in your domain pointing to this community's address":
    "Lägg till en CNAME -post i din domän som pekar på den här gemenskapens adress",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "För att konfigurera din domän måste du lägga till en CNAME -post som pekar din domän till din Pensil Community -URL",
  "Step by Step help apge": "Steg för steg hjälp apge",
  "All request list": "Alla förfrågningslista",
  "Allow admin to assign/un-assign custom points to users":
    "Låt administratören tilldela/un-tilldela anpassade poäng till användare",
  "Base points for user to begin with":
    "Baspoäng för användare till att börja med",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Baspunkter kommer att uppdateras för alla användare, alla ändringar återspeglar för alla användare.",
  "Allocate points for interaction": "Tilldela poäng för interaktion",
  "Upvoting post": "Utvotningspost",
  "creating a comment": "Skapa en kommentar",
  "creating a reply": "Skapa ett svar",
  "Getting upvote to their post": "Att komma till deras inlägg",
  "Getting comment to their post": "Få kommentar till sitt inlägg",
  "Getting reply to their comment": "Få svar på deras kommentar",
  "Creating a post": "Skapa ett inlägg",
  Visiblity: "Synlighet",
  "Move Up": "Flytta upp",
  "Move Down": "Flytta ner",
  "Create embedded section": "Skapa inbäddad sektion",
  "Preview area": "Förhandsgranskning",
  "Theme selector": "Temalös",
  "Community Theme": "Gemenskapstema",
  "Select theme": "Välj tema",
  "Select brand color": "Välj varumärkesfärg",
  "Customize your button color to match your brand":
    "Anpassa din knappfärg för att matcha ditt märke",
  "Select on button text color": "Välj på knapptextfärg",
  "Suggestion: Keep inverted contrast against brand color":
    "Förslag: Håll inverterad kontrast mot varumärkesfärg",
  Billing: "Fakturering",
  "Current plan": "Aktuell plan",
  "You are current on Free PLan": "Du är aktuell på gratis plan",
  "Renew on: NA": "Förnya på: na",
  Select: "Välj",
  "Email us at": "Maila oss på",
  "Compare plan": "Jämför plan",
  "API Keys": "API -nycklar",
  "Create new key": "Skapa ny nyckel",
  "Total keys": "Totala nycklar",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Håll dina API -nycklar säkra! Du kan använda dem för att få åtkomst till specifik funktion och låt cetain -appar perfekta vissa åtgärder för dina räkning på plattformen.",
  "Key name": "Nyckelnamn",
  "Key value": "Nyckelvärde",
  Action: "Handling",
  "Group updated!": "Grupp uppdaterad!",
  "Are you sure you want to": "Är du säker på att du vill",
  "Make Moderator": "Göra moderator",
  "Remove from group": "Ta bort från grupp",
  "Show All": "Visa allt",
  "Change Role": "Ändra roll",
  Close: "Stänga",
  copy: "kopiera",
  LINKS: "Länkar",
  "Email Id": "E-post ID",
  Name: "namn",
  "Search for an image": "Sök efter en bild",
  "Crop Image": "Beskära bild",
  "No photos found": "Inga foton hittades",
  Community: "gemenskap",
  "Banner ratio - 16:9": "Bannerförhållande - 16: 9",
  "Banner Ratio - 16:6": "Bannerförhållande - 16: 6",
  "Share on mail": "Dela på post",
  "Share on Telegram": "Dela på telegram",
  "Share on Whatsapp": "Dela på WhatsApp",
  "Share on Linkedin": "Dela på LinkedIn",
  "Share on Facebook": "Dela på Facebook",
  "Share on Twitter": "Dela på Twitter",
  "Pin Post": "Stiftstolpe",
  "Heading (optional)": "Rubrik (valfritt)",
  "Select section": "Välj avsnitt",
  Gif: "GIF",
  "Create Custom Widget": "Skapa anpassad widget",
  "Are you sure you want to delete this widget?":
    "Är du säker på att du vill ta bort den här widgeten?",
  "Go to event": "Gå till evenemanget",
  "Add Tags": "Lägg till taggar",
  "Create Tag": "Skapa tagg",
  "Title (Optional)": "Titel (valfritt)",
  "Search Gif": "Sök GIF",
  "What's on your mind": "Vad tänker du på",
  "Looks like nothing available here right now.":
    "Ser ut som ingenting tillgängligt här just nu.",
  "Try exploring other tabs": "Försök utforska andra flikar",
  "Delete Widget": "Ta bort widget",
  "Please select a section to post": "Välj ett avsnitt att posta",
  "Post added successfully!": "Post tillagd framgångsrikt!",
  "Failed to create post. Please check post content and retry again.":
    "Det gick inte att skapa inlägg. Kontrollera postinnehållet och försök igen igen.",
  "Are you sure you want to discard current post?":
    "Är du säker på att du vill kassera nuvarande inlägg?",
  "By default it will take up the image uploaded with the post":
    "Som standard kommer det att ta upp bilden som laddas upp med inlägget",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Quiz kan bara ses och försökt från mobil enhet just nu!",
  "Community Banner": "Samhällsbanner",
  "Change Cover": "Byta omslag",
  "Suggestions: Welcome to my community etc":
    "Förslag: Välkommen till mitt samhälle etc.",
  "(edited)": "(redigerad)",
  "Aspect ratio (recommended)": "Bildförhållande (rekommenderas)",
  "Banner details updated!": "Bannerinformation uppdateras!",
  "Refresh if changes are not visible!":
    "Uppdatera om ändringar inte är synliga!",
  "Banner details could not be updated!":
    "Bannerinformation kunde inte uppdateras!",
  "Post deleted": "Efter raderad",
  "Post not available. It may have been deleted.":
    "Post inte tillgängligt. Det kan ha tagits bort.",
  Admin: "Administration",
  Moderator: "Moderator",
  "Bookmark added!": "Bokmärke tillagd!",
  "Bookmark removed": "Bokmärke",
  "Failed performing action!": "Misslyckades med att utföra åtgärder!",
  "Copy this link to share on other platforms":
    "Kopiera den här länken för att dela på andra plattformar",
  "View this event": "Visa det här evenemanget",
  Event: "Händelse",
  "Join Meeting": "Gå med mötet",
  "The live meeting has ended": "LIVE -mötet har avslutats",
  "read more...": "Läs mer...",
  "Load more comments": "Ladda fler kommentarer",
  "Post Reply": "Skicka svar",
  "Post Comment": "Publicera kommentar",
  more: "Mer",
  upvoted: "avtagen",
  "1 Upvote": "1 Urvote",
  "1 Comment": "1 kommentar",
  "Please enter a title": "Ange en titel",
  "Please enter a url link": "Ange en URL -länk",
  "Please enter button title": "Ange knapptitel",
  "Button label must be at least 20 characters":
    "Knappetikett måste vara minst 20 tecken",
  "Please enter valid url": "Ange giltig URL",
  "Suggestions: Know more, Join, Apply...":
    "Förslag: Vet mer, gå med, ansöka ...",
  URL: "Url",
  "Community Events": "Community -evenemang",
  "Widget deleted successfully": "Widget raderas framgångsrikt",
  "Community Top Contributors": "Community Top -bidragsgivare",
  points: "punkter",
  "Points cannot be greater than current points":
    "Poäng kan inte vara större än nuvarande poäng",
  "Point cannot be a negative value":
    "Punkten kan inte vara ett negativt värde",
  "Point cannot be a decimal point": "Punkten kan inte vara en decimalpunkt",
  "Please enter a valid number": "var vänlig skriv in ett giltigt nummer",
  "Karma points updated successfully": "Karma -poäng uppdateras framgångsrikt",
  "Error in updating karma points": "Fel vid uppdatering av karma -poäng",
  "Add karma points": "Lägg till karmapoäng",
  "Minus karma points": "Minus karma poäng",
  "Total Karma points:": "Totala karmapoäng:",
  "Enter no. of karma points": "Ange nej. av Karma -poäng",
  "Minus Points": "Minuspoäng",
  "Trending Post": "Trending post",
  "No visible description": "Ingen synlig beskrivning",
  "Picture ratio: 1:1": "Bildförhållande: 1: 1",
  "home feed": "hemfoder",
  "Event name is invalid!": "Eventnamnet är ogiltigt!",
  custom: "beställnings-",
  "Custom widget updated successfully":
    "Anpassad widget uppdaterad framgångsrikt",
  "Name invalid!": "Namngiltigt!",
  "Current timezone:": "Aktuell tidszon:",
  "can be updated from community settings":
    "kan uppdateras från samhällsinställningar",
  "Event created successfully": "Händelse skapades framgångsrikt",
  "Error in creating event": "Fel i att skapa händelse",
  Attendees: "Deltagare",
  "Do you wish to notify these changes to attendees?":
    "Vill du meddela dessa ändringar till deltagarna?",
  "users wants to attend this event": "Användare vill delta i detta evenemang",
  "Yes, Notify them": "Ja, meddela dem",
  "No, its okay": "Nej det är okej",
  "Do you wish to notify that event is cancelled?":
    "Vill du meddela att händelsen avbryts?",
  "Are you sure you want to delete the event?":
    "Är du säker på att du vill ta bort evenemanget?",
  "Delete Event": "Radera händelse",
  "New Event": "Nytt event",
  "Accept Invitation": "Acceptera inbjudan",
  Requested: "Begärda",
  Declined: "Tackade nej",
  "Request Access": "Begära tillgång",
  "Enroll for": "Anmäla sig till",
  Housefull: "Husfull",
  "Just copy paste this code wherever you want it to be embedded:":
    "Kopiera bara klistra in den här koden var du vill att den ska vara inbäddad:",
  Ended: "Slut",
  "Error getting events": "Fel att få händelser",
  "Events could not be fetched!": "Händelser kunde inte hämtas!",
  "Show Attendees": "Visa deltagare",
  Subscribers: "Prenumeranter",
  "Make this a closed section": "Gör detta till ett stängt avsnitt",
  "Update Section": "Uppdateringsavsnitt",
  "Valid section name required!": "Giltigt avsnitt Namn krävs!",
  "Enter section name": "Ange sektionsnamn",
  "Community Admin": "Community admin",
  Embed: "Bädda in",
  "Invite to": "Bjuda in till",
  "Please enter a valid email address": "Ange en giltig e -postadress",
  "Invitation sent successfully": "Inbjudan skickas framgångsrikt",
  "Error sending invitation": "Fel skickar inbjudan",
  "Email is not valid!": "E-post är inte giltig!",
  "Data could not be loaded!": "Data kunde inte laddas!",
  "Posted in": "Inlagd i",
  "Invite Members Individually": "Bjuda in medlemmar individuellt",
  "Start a discussion": "Starta en diskussion",
  "Post something": "Lägga upp något",
  "Invite your audience": "Bjud in din publik",
  "Choose Plan": "Välj plan",
  or: "eller",
  "Create a link": "Skapa en länk",
  "Add link": "Lägg till länk",
  "Update link": "Uppdateringslänk",
  "Add shortcut to your most used tools":
    "Lägg till genväg till dina mest använda verktyg",
  "Your website, Instagram Channel, Facebook profile etc":
    "Din webbplats, Instagram Channel, Facebook -profil etc.",
  "Valid link name required!": "Giltigt länknamn krävs!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Giltig URL krävs och se till att du inte saknar http: // eller https: //",
  "Invite to__1": "Bjuda in till",
  "Link Name": "Länknamn",
  "Link URL": "Länkadress",
  "Could not subscribe to": "Kunde inte prenumerera på",
  "Subscribed to": "Prenumererad på",
  "By default it will take up the community banner uploaded with the community":
    "Som standard kommer det att ta upp samhällsbannern som laddas upp med samhället",
  "Created by": "Skapad av",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Online -evenemang (möteslänk kommer att vara synlig när den är registrerad för evenemanget)",
  "Join Call": "Gå med i samtal",
  "Please login to book this event": "Logga in på att boka detta evenemang",
  "Commented on": "Kommenterade",
  "Replied to": "Påskjutande av",
  "Looks like it's lonely here.": "Ser ut som om det är ensamt här.",
  "Try exploring other sections and groups.":
    "Försök utforska andra avsnitt och grupper.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Fortsätt med betalnings- eller kontaktadministratören för att få tillgång till denna grupp.",
  "This is a closed group You'll need access to this group.":
    "Detta är en stängd grupp du behöver tillgång till den här gruppen.",
  "You have no bookmarks right now.": "Du har inga bokmärken just nu.",
  "The user hasn't posted anything yet publicly.":
    "Användaren har inte publicerat något ännu offentligt.",
  "No activity to show.": "Ingen aktivitet att visa.",
  "Are you sure you want to unsubscribe the community?":
    "Är du säker på att du vill avsluta prenumerationen?",
  "deals left": "affärer kvar",
  "Something went wrong while loading posts":
    "Något gick fel när du laddade inlägg",
  "Could not unsubscribe from": "Kunde inte avsluta prenumerationen",
  "Unsubscribed from": "Avbruten från",
  "Login to view this page": "Logga in för att se den här sidan",
  Login: "Logga in",
  "There was some error while doing the action!":
    "Det var något fel när han gjorde handlingen!",
  "Unable to delete section!": "Det går inte att ta bort avsnittet!",
};
