import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

const nftEndpoints = {
  getMyNFTs: base + "profile/nfts",
  getUserNFTs: (userId) => base + `user/${userId}/nfts`,
  checkForGatedGroupAccess: (communityId) =>
    base + `community/${communityId}/check-for-gated-group-access`,
};

export default nftEndpoints;
