import { CommunityService } from "../../_service";
import { KarmaPointsState } from "./karma-points.reducer";

export const SET_KARMA_POINTS = "SET_KARMA_POINT";
export const ADD_KARMA_POINTS = "ADD_KARMA_POINT";

export const setKarmaPointAction = (obj: KarmaPointsState) => ({
  type: SET_KARMA_POINTS,
  obj,
});
export const addKarmaPointAction = (points: number, activityType: string) => ({
  type: ADD_KARMA_POINTS,
  points,
  activityType
});

/**
 * Get karma points for auth user
 */
export function getKarmaPointsAction() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      CommunityService.getMyLeaderboardScore(community.id)
        .then((response) => {
          // console.log("🚀 ~ file: karma-points.action.ts:28 ~ .then ~ response:", response)
          dispatch(setKarmaPointAction(response));
        })
        .catch((err) => {
          console.log({ err });
        });
    } catch (error) {
      console.log(error);
    }
  };
}
