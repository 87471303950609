import React from "react";

export const AppContext = React.createContext(null);

export const withAppContext = (Component) => (props) =>
  (
    <AppContext.Consumer>
      {(context) => <Component {...context} {...props} />}
    </AppContext.Consumer>
  );
