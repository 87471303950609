import { useEffect, useState } from "react";
import { Remarkable } from "remarkable";
import {
  convertYouTubeLinksToPlainText,
  getVideoEmbedFromDescription,
} from "../../../../_components";
import AlertStrip from "../../../../_components/molecule/alert-strips";
import Label from "../../../../_components/typography/label.typography";
/**
 * Render lesson view content
 * @param {Object} lesson
 * @param {Boolean} isLoading - true if lesson is loading
 * @returns
 */

export function LessonSummaryView({ lesson, isLoading }) {
  const [videoUrl, setVideoUrl] = useState();

  const [description, setDescription] = useState("");

  /** Initialize videoUrl and description */
  useEffect(() => {
    if (lesson.coverMedia) {
      setVideoUrl(lesson.coverMedia);
    } else {
      setVideoUrl(null);
    }

    if (lesson.description) {
      const md = new Remarkable();
      const rawDescription = convertYouTubeLinksToPlainText(lesson.description);
      const renderedDescription = md.render(rawDescription);
      const descriptionWithEmbeds = getVideoEmbedFromDescription(
        renderedDescription ?? ""
      );
      setDescription(descriptionWithEmbeds ?? "");
    }
  }, [lesson]);

  if (isLoading) {
    return (
      <div className="animate-pulse flex flex-col place-content-center gap-3 h-(calc(100%-900px)) p-4 pt-4 py-2 theme-bg-surface rounded">
        <div className="flex items-center place-content-between">
          <div className="w-5/12 h-8 theme-bg-default" />
        </div>
        <div className="w-7/12 h-32 mt-4 theme-bg-default"></div>
        <div className=" theme-bg-default h-96" />
        <div className=" theme-bg-default " />
      </div>
    );
  }
  if (!lesson || Object.keys(lesson).length === 0) {
    return (
      <AlertStrip
        error
        title="No lesson found"
        message="Please select a lesson from the left panel."
        className="theme-bg-surface"
      />
    );
  }
  return (
    <div className="flex flex-col space-y-2 h-full px-4 py-2 theme-bg-surface rounded">
      <div className="flex items-center place-content-between">
        <Label size="h3" variant="t2">
          {lesson.title}
        </Label>
      </div>
      <div className="flex flex-col">
        <div className="w-full">
          {videoUrl ? (
            <div className="w-full h-80 mt-4 group">
              <video
                className="aspect-video h-full w-full theme-bg-default rounded cursor-pointer"
                controls
                src={videoUrl}
                controlsList="nodownload"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className="NL2BR headings"
        dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
}
