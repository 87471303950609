function HeaderShimmer() {
  return (
    <div
      className="Header animate-pulse border-b theme-border-default theme-bg-surface py-1"
      style={{ height: "73px" }}>
      <div className="header-container px-6 sm:px-0 mg:px-6 xl:px-0 pb-2 pt-3">
        <div className="flex items-center space-x-2  w-full ">
          <p className="w-10 h-10 bg-gray-300 rounded "></p>
          <p className="w-12 h-4 bg-gray-300 rounded"></p>
        </div>

        <div className="OtherOptions flex items-center space-x-2 place-content-end">
          <p className="w-6 h-6 bg-gray-300 rounded"></p>
          <p className="w-6 h-6 bg-gray-300 rounded"></p>
          <p className="w-6 h-6 bg-gray-300 rounded"></p>
          <p className="w-10 h-10 bg-gray-300 rounded-full "></p>
        </div>
      </div>
    </div>
  );
}

export default HeaderShimmer;
