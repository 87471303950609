import cx from "classnames";
import React from "react";
import razorpayCurrencyCodes from "../../_config/razorpay-currency-codes.config.json";
import I18 from "../atoms/i18";

interface Props {
  currency?: string;
  label?: string;
  onCurrencyChange: React.Dispatch<React.SetStateAction<string>>;
  className?: string;
  selectClassName?: string;
}

/**
 * Component to display and update community currency.
 * @param {string} label - label for the currency selector
 * @param {string} currency - currency selected
 * @param {Function} onCurrencyChange - function to set the currency
 * @param {string} className - className for the currency selector
 * @param {string} selectClassName - className for the select element
 * @returns {JSX.Element} - CurrencySelector component
 */
export default function CurrencySelector({
  label,
  currency,
  className,
  selectClassName,
  onCurrencyChange,
}: Props) {
  return (
    <div className={className}>
      <div className="font-semibold mb-1">
        <I18>{label ?? "Currency (Default INR"}</I18>
      </div>
      <div
        className={cx(
          "border py-2 theme-border-default cursor-pointer px-2 rounded",
          selectClassName
        )}>
        <select
          className="focus:outline-none w-full bg-transparent"
          value={currency}
          onChange={(e) => {
            onCurrencyChange(e.target.value);
          }}>
          {razorpayCurrencyCodes.map((currency) => (
            <option key={currency.isoCode} value={currency.isoCode}>
              {currency.isoCode} - {currency.currencyName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
