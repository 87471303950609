export const noNorwegian = {
  "Pensil Keyword": "Norwegian",
  "Home Page Left column": "Hjemmeside venstre kolonne",
  Home: "Hjem",
  "Explore Groups": "Utforsk grupper",
  Events: "arrangementer",
  Groups: "Grupper",
  Links: "Lenker",
  "Direct Message": "Direkte melding",
  "Powered by": "Drevet av",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Ingen grupper her, bruk + for å lage en ny, eller bli med en se dem her.",
  "Please join a group to see them here":
    "Vennligst bli med en gruppe for å se dem her",
  "Section can only be reordered within the group!":
    "Seksjonen kan bare omorganiseres i gruppen!",
  "Group Page": "Gruppeside",
  "Invite Members": "Inviter medlemmer",
  Join: "Bli med",
  Joined: "Ble med",
  "Create new section": "Lag ny seksjon",
  "Group Setting drop down": "Gruppeinnstilling",
  Share: "Dele",
  "Edit group": "Rediger gruppe",
  "Delete group": "Slett gruppe",
  Leave: "Permisjon",
  "Are you sure you want to delete the group?":
    "Er du sikker på at du vil slette gruppen?",
  Cancel: "Avbryt",
  "Yes, Go Ahead": "Ja, bare gå",
  "Share via link": "Del via lenke",
  Copy: "Kopiere",
  Copied: "Kopiert",
  "Share on social media": "Del på sosiale medier",
  "Add Section": "Legg til seksjon",
  "Section Header": "Seksjonshode",
  Live: "Bo",
  "Create Post": "Lag innlegg",
  "Notificaiton Bell": "Varsler Bell",
  "All posts": "Alle innlegg",
  "Admin posts only": "Bare administratorinnlegg",
  None: "Ingen",
  "Section Setting drop down": "Seksjonsinnstilling",
  "Edit Section": "Rediger seksjon",
  "Embed Section": "Innebygd seksjon",
  "Delete Section": "Slett seksjon",
  "Are you sure you want to delete the section?":
    "Er du sikker på at du vil slette seksjonen?",
  "Section deleted!": "Seksjonen slettet!",
  "Create Group Pop up / Update": "Opprett gruppepop -up / oppdatering",
  "Create group": "Lag gruppe",
  "Basic Detail": "Grunnleggende detaljer",
  Settings: "Innstillinger",
  "Size 16:9 or 1600 by 900": "Størrelse 16: 9 eller 1600 med 900",
  "Group name": "Gruppenavn",
  "Enter group name": "Skriv inn gruppenavn",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Forslag: mellomrom, kanaler, grupper, blogg, kunnskapsbase, etc",
  "Group Description": "gruppebeskrivelse",
  "Enter description here": "Skriv inn beskrivelsen her",
  "Group Type": "Gruppetype",
  Open: "Åpen",
  "Visible to all & anyone can join":
    "Synlig for alle og hvem som helst kan bli med",
  Closed: "Lukket",
  "User will have to request to join the group":
    "Bruker må be om å bli med i gruppen",
  Paid: "Betalt",
  "Group Price": "Gruppepris",
  "Currency (Default: INR)": "Valuta (standard: INR)",
  "User will have to pay to join the group":
    "Bruker må betale for å bli med i gruppen",
  "Make this a private group": "Gjør dette til en privat gruppe",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Privat gruppe vil bare være synlig for medlemmer som er en del av den. Administrator kan legge til medlemmer manuelt fra medlemslisten",
  "Upload from device": "Last opp fra enheten",
  "Search image": "Søkebilde",
  "Valid group name required!": "Gyldig gruppenavn påkrevd!",
  "Update Group": "Oppdateringsgruppe",
  "SEO Settings": "SEO -innstillinger",
  "Custom URL": "Egendefinert nettadresse",
  "Meta Title": "Metatittel",
  "Maximum length of 255 characters allowed":
    "Maksimal lengde på 255 tegn tillatt",
  "Meta Description": "Metabeskrivelse",
  "Open Graph Detail": "Åpne grafdetaljer",
  "Open Graph Title": "Åpen graftittel",
  "Open Graph Description": "Åpen grafbeskrivelse",
  "Open Graph Image": "Åpent grafbilde",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Anbefalte dimensjoner: 1200px med 630px med et aspektforhold på 1,91: 1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Hvis ingen URL brukes, vil den automatisk plukket ut miniatyrbildet.",
  "Create Section Pop up / Update": "Opprett seksjonen Pop Up / Update",
  "Create Section": "Opprett seksjon",
  "Section Name": "Seksjonsnavn",
  "Suggestions: General, Introductions, Community Guidelines":
    "Forslag: Generelt, introduksjoner, retningslinjer for samfunn",
  "Section Type": "Seksjonstype",
  "Post Type": "Innleggstype",
  "Group Chat": "Gruppechat",
  "Who can post in this section?": "Hvem kan legge ut i denne delen?",
  "Only Admin": "Bare admin",
  "Any user": "Enhver bruker",
  "Sorting order for section": "Sorteringsordre for seksjonen",
  "Latest first": "Siste første",
  "Oldest post first": "Eldste innlegg først",
  "Section Appearance": "Seksjonsutseende",
  "List View": "Listevisning",
  "Grid View": "Rutenett visning",
  "Make this closed section": "Lag denne lukkede delen",
  "Members will have to request access to see section content":
    "Medlemmene må be om tilgang for å se seksjonsinnhold",
  "Make this a private section": "Gjør dette til en privat seksjon",
  "Disables requests (Make sure closed section is selected as well).":
    "Deaktiver forespørsler (Forsikre deg om at lukkede seksjoner er valgt også).",
  "Post Editor": "Postredaktør",
  "Create new post in": "Lag nytt innlegg i",
  "Add Cover Picture": "Legg til omslagsbilde",
  "Heading (Optional)": "Overskrift (valgfritt)",
  "Write here..": "Skriv her..",
  Picture: "Bilde",
  Video: "Video",
  Attachment: "Vedlegg",
  Poll: "avstemming",
  "Embed Code": "Legg inn kode",
  Tags: "Tagger",
  "Toggle to question": "Veksle for å stille spørsmål",
  "Poll expire time": "Avstemningen utløper tid",
  "Poll question": "Avstemningsspørsmål",
  "Enter Question": "Skriv inn spørsmål",
  Question: "Spørsmål",
  Option: "Alternativ",
  Options: "Alternativer",
  "Add Option": "Legg til alternativ",
  "Poll Expiry time": "Avstemningsutløpstid",
  "12 Hours": "12 timer",
  "1 Day": "1 dag",
  "2 Days": "2 dager",
  "1 Week": "1 uke",
  "2 Weeks": "2 uker",
  "Always Open": "Alltid åpen",
  "Write embeddable code": "Skriv innebygd kode",
  "Click here to add embed code.": "Klikk her for å legge til innebygd kode.",
  "Paste your embed code here!": "Lim inn innebygd kode her!",
  "Post Settings": "Innstillinger",
  "Update Post": "Oppdateringsinnlegg",
  Update: "Oppdater",
  "Start meeting / Video call / Live": "Begynn å møte / videosamtale / live",
  "Meeting Name": "Møtets navn",
  "Suggestion: Group discussion etc": "Forslag: Gruppediskusjon osv",
  Description: "Beskrivelse",
  "Enter description here (optional)": "Skriv inn beskrivelse her (valgfritt)",
  "When do you want to start the session": "Når vil du starte økten",
  "Right now": "Akkurat nå",
  "Schedule for later": "Plan for senere",
  "Group video call": "Gruppevideoanrop",
  Webinar: "Webinar",
  "Go Live": "Gå live",
  "Info on the Post": "Info om innlegget",
  "Post comment": "Post Kommentar",
  Comment: "Kommentar",
  Comments: "Kommentarer",
  Reply: "Svare",
  Replies: "Svar",
  "Share via URL": "Del via url",
  "Reshare in other sections": "Reshare i andre seksjoner",
  "Post Card Drop Down": "Postkort rullegardin",
  Bookmark: "Bokmerke",
  "Pin post": "Fest Innlegg",
  Edit: "Redigere",
  "Embed Post": "Legg inn innlegg",
  Delete: "Slett",
  "Remove Pin": "Fjern pinnen",
  "Remove Bookmark": "Fjern bokmerket",
  "Just copy paste this code wherever you want it to be embedded":
    "Bare kopier lim inn denne koden uansett hvor du vil at den skal være innebygd",
  Height: "Høyde",
  Preview: "Forhåndsvisning",
  "Copied to clipboard": "Kopiert til utklippstavle",
  "Select Widgets": "Velg widgeter",
  "Add widgets to": "Legg til widgeter til",
  "You can change their order by dragging & dropping the widgets over each other":
    "Du kan endre bestillingen deres ved å dra og slippe widgets over hverandre",
  "Please note: Widget wont be displayed if there is no data available,":
    "Merk: Widget vil ikke vises hvis det ikke er noen data tilgjengelig,",
  "Group Members": "Gruppemedlemmer",
  "Group Leaderboard": "Gruppen toppliste",
  "Community Leaderboard": "Community Leaderboard",
  "Trending Posts": "Trending innlegg",
  "Group Events": "Gruppearrangementer",
  "Add Custom": "Legg til tilpasset",
  "Save Widgets": "Lagre widgeter",
  "Visible to user": "Synlig for brukeren",
  "Visible to admin": "Synlig for admin",
  "Add widget to": "Legg til widget til",
  "Upload cover picture": "Last opp omslagsbilde",
  "1:1 aspect ration (recommended)": "1: 1 Aspektrasjon (anbefalt)",
  "Card Title": "Korttittel",
  "Enter widget name": "Skriv inn widget -navn",
  "Small Description (try to keep it under 50 words)":
    "Liten beskrivelse (prøv å holde den under 50 ord)",
  "Enter description": "Skriv inn beskrivelse",
  "Button Title": "Knappetittel",
  "Enter button name": "Skriv inn knappenavn",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestion: vet mer, bli med, søk osv",
  "URL link": "URL -lenke",
  "Create custom widget": "Lag tilpasset widget",
  "Custom widget created successfully": "Tilpasset widget opprettet med hell",
  Widgets: "Widgets",
  Members: "Medlemmer",
  "See All": "Se alt",
  "Communtiy Top Contributors": "Communtiy Top Bidragsytere",
  "Community Members": "Samfunnsmedlemmer",
  "Group Top Contributors": "Gruppens toppbidragsytere",
  "Upcoming Events": "Kommende arrangementer",
  "Home Page Banner": "Hjemmeside banner",
  "Edit Banner": "Rediger banner",
  "Home page Edit Banner Pop up": "Hjemmeside rediger banner pop -up",
  "Update Banner": "Oppdater banner",
  Search: "Søk",
  "Upload Image": "Last opp bilde",
  Remove: "Fjerne",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Bannerbilde (bruk 16: 6 -forholdet eller 1600px med 600px -bilde for den beste visningen)",
  "Banner Title": "Bannertittel",
  "Enter here": "Gå inn her",
  "Suggestion: Welcome to my community etc":
    "Forslag: Velkommen til samfunnet mitt osv",
  "Banner Description": "Bannerbeskrivelse",
  "Enter banner description": "Skriv inn bannerbeskrivelse",
  "Save Banner Info": "Lagre bannerinfo",
  "Home Page Feed": "Hjemmeside feed",
  Recent: "Nylig",
  "Top Posts": "Toppinnlegg",
  Trending: "Trender",
  "Home Feed Groups": "Hjemmefôrgrupper",
  "Header BarProfile Drop down": "Header Barprofile rullegardin",
  "My Profile": "Min profil",
  Logout: "Logg ut",
  Notification: "Melding",
  Notifications: "Varsler",
  "Mark all viewed": "Merk alle sett",
  "has created a post": "har laget et innlegg",
  "wants to join": "vil være med",
  "has commented on your post": "har kommentert innlegget ditt",
  "has replied to your comment": "har svart på kommentaren din",
  "Search for tags": "Søk etter tagger",
  "All groups": "Alle grupper",
  "Search result": "Søkeresultat",
  "No post found for selected hashtag":
    "Ingen innlegg funnet for valgt hashtag",
  "Start a conversation": "Start en samtale",
  "Search by name": "Søk etter navn",
  Upcoming: "Kommende",
  UPCOMING: "Kommende",
  Past: "Forbi",
  PAST: "FORBI",
  "No upcoming events": "Ingen kommende arrangementer",
  "Create event popup": "Lag begivenhetspop",
  "Event details": "Hendelsesdetaljer",
  "16:9 aspect ratio": "16: 9 sideforhold",
  "Event name": "Arrangementsnavn",
  "Enter event name": "Skriv inn hendelsesnavn",
  "Host Name": "Vertsnavn",
  "You will be made host if none selected":
    "Du blir gjort til vert hvis ingen valgte",
  "Write here...": "Skriv her...",
  "No. of registration for the event": "Antall registrering for arrangementet",
  Unlimited: "Ubegrenset",
  "When & where": "Når hvor",
  From: "Fra",
  "Start date": "Startdato",
  time: "tid",
  To: "Til",
  "End date": "Sluttdato",
  "Current time zone:": "Nåværende tidssone:",
  "Can be updated from the communtiy setting":
    "Kan oppdateres fra kommunalinnstillingen",
  "Online event": "Online -arrangement",
  "Add meeting link": "Legg til møtelink",
  "Meeting link will be generated once event is created":
    "Møtelink vil bli generert når hendelsen er opprettet",
  Venue: "Sted",
  "Enter address": "Skriv inn adresse",
  Create: "Skape",
  Invite: "Invitere",
  "Share event via link": "Del arrangementet via lenke",
  Copy__1: "Kopiere",
  "Select section to post this event":
    "Velg seksjon for å legge ut denne hendelsen",
  "Once posted, members of that group or section will recieve an notification":
    "Når den er lagt ut, vil medlemmer av den gruppen eller seksjonen motta et varsel",
  Post: "Post",
  RSVP: "RSVP",
  "Book Event": "Reserver arrangement",
  "Yes, I'll attend": "Ja, jeg skal delta",
  "May be": "Kan være",
  "No, I won't attend": "Nei, jeg vil ikke delta",
  "Date & Time": "Dato tid",
  Host: "Vert",
  "Online meeting": "Online møte",
  "Meeting link will be visible once registered for the event":
    "Møtelink vil være synlig når den er registrert for arrangementet",
  "User Management": "brukeradministrasjon",
  "Go bac to community": "Gå Bac til samfunnet",
  "Manage Members": "Administrer medlemmer",
  Member: "Medlem",
  Invited: "Invitert",
  "Search for member": "Søk etter medlem",
  "Add to group": "Legg til i gruppen",
  "Send Direct Message": "Send direkte melding",
  "Change role": "Endre rolle",
  "Kick from community": "Spark fra samfunnet",
  "Super Admin": "Super admin",
  "Super admin will have full access to the communtiy settings":
    "Superadministrator vil ha full tilgang til Communtiy -innstillingene",
  "Group Admin": "Gruppeadministrator",
  "Admin of the selected group can edit the group information and create, edt or delete section under selected group":
    "Administartor for den valgte gruppen kan redigere gruppeinformasjonen og opprette, redigere eller slette seksjon under valgt gruppe.",
  "Group Moderator": "Gruppemoderator",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Gruppemoderator kan opprette eller slette innlegg, kommentere, svar og meldinger i den valgte gruppen.",
  User: "Bruker",
  Save: "Lagre",
  "User role updated successfully": "Brukerrollen oppdatert vellykket",
  Leaderboard: "Toppliste",
  "Click on the column header for sorting":
    "Klikk på kolonneoverskriften for sortering",
  Rank: "Rang",
  "Karma Points": "Karma poeng",
  "Custom Points": "Tilpassede poeng",
  Follow: "Følg",
  Upvotes: "Upvotes",
  Posts: "Innlegg",
  "Add custom points": "Legg til tilpassede poeng",
  "Subtract custom points": "Trekk tilpassede poeng",
  "Enter no. of points": "Skriv inn hvor mange poeng",
  "Add points": "Legg til poeng",
  "Invite members to": "Inviter medlemmene til",
  "Invite via link": "Inviter via lenke",
  "Invite via email": "Inviter via e -post",
  "Add more": "Legg til mer",
  "Upload a CSV": "Last opp en CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Last opp en kommadelt CSV-fil, med overskrift som navn, e-post.",
  "Here is a template CSV file": "Her er en mal CSV -fil",
  "Upload File": "Last opp fil",
  "Send Invite": "Send invitasjon",
  "Mail Template": "Postmal",
  "Send Test Mail": "Send testpost",
  Hey: "Hei",
  "A has invite you to join the B": "A har invitert deg til å bli med i B",
  "Switch to rich text editor": "Bytt til Rich Text Editor",
  "Add Custom Message": "Legg til tilpasset melding",
  "Enter message here": "Skriv inn melding her",
  "Send Bulk Mail": "Send bulk -post",
  "Send Mail": "Send post",
  "Send bulk mail to all community members or select group members":
    "Send bulk -post til alle samfunnsmedlemmer eller velg gruppemedlemmer",
  Date: "Dato",
  Subject: "Emne",
  Recipients: "Mottakere",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 bulk -e -postmeldinger som gjenstår for denne måneden. Tilbakestillinger på <Dato>",
  Everyone: "Alle sammen",
  "Enter mail subject": "Skriv inn postemne",
  "Write message": "Skriv melding",
  Cancel__1: "Avbryt",
  Send: "Sende",
  "Profile Page": "Profilside",
  Activity: "Aktivitet",
  Bookmarks: "Bokmerker",
  "Edit Profile": "Rediger profil",
  Unsubscribe: "Avslutte abonnementet",
  "Settings (Account)": "Innstillinger (konto)",
  Profile: "Profil",
  "Set profile Picture": "Sett profilbilde",
  "Your name": "Navnet ditt",
  "User ID": "bruker-ID",
  "Short bio": "Kort biografi",
  "Link your social account, this will be displayed on your profile":
    "Koble din sosiale konto, dette vises på profilen din",
  "Add social handle": "Legg til sosialt håndtak",
  Verification: "Bekreftelse",
  "Account Verification": "Konto bekreftelse",
  Email: "E -post",
  "Enter email": "Skriv inn e -post",
  Verified: "Bekreftet",
  Unverified: "Ubekreftet",
  Change: "Endring",
  Unlink: "Unlink",
  Mobile: "Mobil",
  "enter mobile no.": "Skriv inn mobilnr.",
  "Request OTP": "Be om OTP",
  "Notification centre": "Varslingssenter",
  "Manage your personal account notification preferences":
    "Administrer dine personlige kontoer",
  Prefrences: "Preferanser",
  "Email notification": "E -postvarsel",
  "Notify me about comments on my posts":
    "Gi meg beskjed om kommentarer til innleggene mine",
  "Notify me about replies on my comments":
    "Gi meg beskjed om svar på kommentarene mine",
  "Notify me about upvotes on my posts":
    "Gi meg beskjed om upvotes om innleggene mine",
  "Notify me about reaction on my comments":
    "Gi meg beskjed om reaksjon på kommentarene mine",
  "Notify me about reaction on my reply":
    "Gi meg beskjed om reaksjon på svaret mitt",
  "Notify me about direct message": "Gi meg beskjed om direkte melding",
  "Notify me about my mentions": "Gi meg beskjed om omtaler",
  "Notification for new posts": "Varsel for nye innlegg",
  "Only group & section available which you are part of":
    "Bare gruppe og seksjon tilgjengelig som du er en del av",
  "Group & Section": "Gruppe og seksjon",
  "All activity": "All aktivitet",
  "Post by admin": "Innlegg av admin",
  "No Notification": "Ingen varsel",
  "Setting (Community)": "Innstilling (fellesskap)",
  "General Settings": "Generelle innstillinger",
  "Upload community logo": "Last opp samfunnslogo",
  "Community name": "Fellesskapsnavn",
  "Invalid field": "Ugyldig felt",
  "Your community address": "Din samfunnsadresse",
  "Fav icon": "Favikon",
  "This will be visible on the browser tab (32px by 32px)":
    "Dette vil være synlig i nettleserfanen (32px med 32px)",
  TimeZone: "Tidssone",
  "Privacy Policy on login page": "Personvernregler på påloggingssiden",
  "Terms & Condition on login page": "Vilkår og betingelser på påloggingssiden",
  "Enter link here": "Skriv inn lenken her",
  "Open Group Image": "Åpen gruppebilde",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Som standard tar IR opp fellesskapsbanneret lastet opp med kommunen",
  "Community Experience": "Fellesskapserfaring",
  Onboarding: "Onboarding",
  "Make your community private": "Gjør samfunnet ditt privat",
  "Users will have to login or sign up to access the community":
    "Brukere må logge inn eller registrere seg for å få tilgang til samfunnet",
  "Make account verification mandatory for both email & mobile":
    "Gjør kontobekreftelse obligatorisk for både e -post og mobil",
  "New user will have to verify both email & mobile no. to access the community":
    "Ny bruker må bekrefte både e -post og mobilnr. For å få tilgang til samfunnet",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Administrator må også bekrefte mobilnr. & e -post, ellers vil de ikke kunne få tilgang til samfunnet",
  "Gated community access": "Gated Community Access",
  "Admin will have to approve or reject user's requests to join the community":
    "Administrator må godkjenne eller avvise brukerens forespørsler om å bli medlem av samfunnet",
  "Disable mobile login/register": "Deaktiver mobil pålogging/register",
  "Disable email login/register": "Deaktiver e -postpålogging/register",
  "Disable google login/register": "Deaktiver Google Logg inn/register",
  "Disable facebook login/register": "Deaktiver Facebook -pålogging/register",
  "Show & Hide features": "Vis og skjule funksjoner",
  "Hide direct chat": "Skjul direkte chat",
  "User will not be able to send direct messages to each other":
    "Bruker vil ikke kunne sende direkte meldinger til hverandre",
  "Hide profile picture from top right corner for mobile responsive view":
    "Skjul profilbilde fra øverste høyre hjørne for mobil responsiv visning",
  "Useful when using community in an in app browser":
    "Nyttig når du bruker fellesskap i en i app -nettleser",
  "Hide profile picture from top right corner in every view":
    "Skjul profilbilde fra øverste høyre hjørne i alle visninger",
  "Disable header dropdown on profile picture":
    "Deaktiver rullegardinmenying på profilbildet",
  "Useful for integrated community, especially using SSO":
    "Nyttig for integrert fellesskap, spesielt ved bruk av SSO",
  "Hide the communtiy logo from the header":
    "Skjul den felles logoen fra overskriften",
  "Hide the communtiy title from the header":
    "Skjul den felles tittelen for overskriften",
  "Show collpased group in the side nav bar":
    "Vis kollpasert gruppe i Side Nav Bar",
  "Show full expanded post in the home feed":
    "Vis full utvidet innlegg i hjemmefôret",
  "Hide the leaderboard score from the header":
    "Skjul topplistenscore for overskriften",
  "Show powered by Pensil": "Vis drevet av pensil",
  "Hide closed groups if not joined": "Skjul lukkede grupper hvis ikke ble med",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Lukkede grupper vil ikke bli vist for brukeren hvis de ikke er medlem av gruppen",
  "Hide Community subscriber count from the users":
    "Skjul community abonnentantall fra brukerne",
  "This will hide the community subscriber list in the community header":
    "Dette vil skjule fellesskapets abonnentliste i fellesskapets overskrift",
  "Disable Communtiy Switcher": "Deaktiver Communtiy Switcher",
  "Use MD editor for create post description":
    "Bruk MD Editor for å lage innleggsbeskrivelse",
  "Non logged in user will land on explore group page instead of post directory":
    "Ikke logget bruker vil lande på Explore Group -side i stedet for postkatalog",
  "Advanced configurable": "Avansert konfigurerbar",
  "Communtiy default country code": "Communtiy standard landskode",
  "This will show up by default on login/signup":
    "Dette vises som standard ved innlogging/påmelding",
  "Onboarding/Welcome Message": "Onboarding/velkomstmelding",
  "Welcome Message": "Velkomstmelding",
  "Greet your members with personalized message":
    "Hilsen medlemmene dine med personlig melding",
  "Welcome to": "Velkommen til",
  Message: "Beskjed",
  "Add message": "Legg til melding",
  "Custom domain": "Tilpasset domene",
  "Add custom domain": "Legg til tilpasset domene",
  "Create new request": "Opprett ny forespørsel",
  "Enter your custom domain": "Skriv inn ditt tilpassede domene",
  "Setup details": "Oppsett detaljer",
  "Add a CNAME record in your domain pointing to this community's address":
    "Legg til en CNAME -post i domenet ditt som peker på dette samfunnets adresse",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "For å konfigurere domenet ditt, må du legge til en CNAME -post som peker domenet ditt på url -url -en",
  "Step by Step help apge": "Trinn for trinn hjelper apge",
  "All request list": "Alle forespørselsliste",
  "Allow admin to assign/un-assign custom points to users":
    "La admin tilordne/ikke-tildeler tilpassede punkter til brukere",
  "Base points for user to begin with":
    "Basispoeng for brukeren til å begynne med",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Basispunkter vil bli oppdatert for alle brukerne, eventuelle endringer vil gjenspeile for alle brukerne.",
  "Allocate points for interaction": "Tildel poeng for samhandling",
  "Upvoting post": "Oppvekslingsinnlegg",
  "creating a comment": "Opprette en kommentar",
  "creating a reply": "Opprette et svar",
  "Getting upvote to their post": "Få oppkjørt til innlegget sitt",
  "Getting comment to their post": "Få kommentar til innlegget sitt",
  "Getting reply to their comment": "Få svar på kommentaren deres",
  "Creating a post": "Opprette et innlegg",
  Visiblity: "Synlighet",
  "Move Up": "Flytte opp",
  "Move Down": "Flytt ned",
  "Create embedded section": "Lag innebygd seksjon",
  "Preview area": "Forhåndsvisningsområde",
  "Theme selector": "Temavelger",
  "Community Theme": "Fellesskapstema",
  "Select theme": "Velg tema",
  "Select brand color": "Velg merkevarefarge",
  "Customize your button color to match your brand":
    "Tilpass knappfargen din for å matche merkevaren din",
  "Select on button text color": "Velg på knappetekstfarge",
  "Suggestion: Keep inverted contrast against brand color":
    "Forslag: Hold omvendt kontrast mot merkevarefarge",
  Billing: "Fakturering",
  "Current plan": "Gjeldende plan",
  "You are current on Free PLan": "Du er aktuell på gratis plan",
  "Renew on: NA": "Forny på: NA",
  Select: "Velg",
  "Email us at": "Send oss ​​en e -post på",
  "Compare plan": "Sammenlign plan",
  "API Keys": "API -nøkler",
  "Create new key": "Lag ny nøkkel",
  "Total keys": "Total nøkler",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Hold API -nøklene dine trygge! Du kan bruke dem til å få tilgang til spesifikke funksjoner og la cetain -apper performe visse handlinger på dine vegne på plattformen.",
  "Key name": "Nøkkelnavn",
  "Key value": "Nøkkelverdi",
  Action: "Handling",
  "Group updated!": "Gruppe oppdatert!",
  "Are you sure you want to": "Er du sikker på at du vil",
  "Make Moderator": "Lage moderator",
  "Remove from group": "Fjern fra gruppen",
  "Show All": "Vis alt",
  "Change Role": "Endre rolle",
  Close: "Lukk",
  copy: "kopiere",
  LINKS: "Lenker",
  "Email Id": "Epost id",
  Name: "Navn",
  "Search for an image": "Søk etter et bilde",
  "Crop Image": "Avlingsbilde",
  "No photos found": "Ingen bilder funnet",
  Community: "Samfunnet",
  "Banner ratio - 16:9": "Bannerforhold - 16: 9",
  "Banner Ratio - 16:6": "Bannerforhold - 16: 6",
  "Share on mail": "Del på post",
  "Share on Telegram": "Del på telegram",
  "Share on Whatsapp": "Del på WhatsApp",
  "Share on Linkedin": "Del på LinkedIn",
  "Share on Facebook": "Del på Facebook",
  "Share on Twitter": "Del på Twitter",
  "Pin Post": "Fest Innlegg",
  "Heading (optional)": "Overskrift (valgfritt)",
  "Select section": "Velg del",
  Gif: "Gif",
  "Create Custom Widget": "Lag tilpasset widget",
  "Are you sure you want to delete this widget?":
    "Er du sikker på at du vil slette denne widgeten?",
  "Go to event": "Gå til arrangementet",
  "Add Tags": "Legg til merkelapper",
  "Create Tag": "Lag tag",
  "Title (Optional)": "Tittel (valgfritt)",
  "Search Gif": "Søk Gif",
  "What's on your mind": "Hva tenker du på",
  "Looks like nothing available here right now.":
    "Ser ut som ingenting tilgjengelig her akkurat nå.",
  "Try exploring other tabs": "Prøv å utforske andre faner",
  "Delete Widget": "Slett widget",
  "Please select a section to post": "Velg en seksjon du vil legge ut",
  "Post added successfully!": "Innlegget lagt til vellykket!",
  "Failed to create post. Please check post content and retry again.":
    "Kunne ikke lage innlegg. Sjekk innlegg innhold og prøv på nytt igjen.",
  "Are you sure you want to discard current post?":
    "Er du sikker på at du vil forkaste gjeldende innlegg?",
  "By default it will take up the image uploaded with the post":
    "Som standard vil det ta opp bildet lastet opp med innlegget",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Quiz kan bare sees og forsøkes fra mobilenhet akkurat nå!",
  "Community Banner": "Community Banner",
  "Change Cover": "Bytte deksel",
  "Suggestions: Welcome to my community etc":
    "Forslag: Velkommen til samfunnet mitt osv",
  "(edited)": "(redigert)",
  "Aspect ratio (recommended)": "Aspektforhold (anbefalt)",
  "Banner details updated!": "Bannerdetaljer oppdatert!",
  "Refresh if changes are not visible!":
    "Oppdater hvis endringer ikke er synlige!",
  "Banner details could not be updated!":
    "Bannerdetaljer kunne ikke oppdateres!",
  "Post deleted": "POST slettet",
  "Post not available. It may have been deleted.":
    "Innlegg ikke tilgjengelig. Det kan ha blitt slettet.",
  Admin: "Admin",
  Moderator: "Moderator",
  "Bookmark added!": "Bokmerke lagt til!",
  "Bookmark removed": "Bokmerke fjernet",
  "Failed performing action!": "Mislykket utførende handling!",
  "Copy this link to share on other platforms":
    "Kopier denne lenken for å dele på andre plattformer",
  "View this event": "Se denne hendelsen",
  Event: "Begivenhet",
  "Join Meeting": "Bli med på møte",
  "The live meeting has ended": "Live møtet er avsluttet",
  "read more...": "Les mer...",
  "Load more comments": "Last inn flere kommentarer",
  "Post Reply": "Publisér svar",
  "Post Comment": "post Kommentar",
  more: "mer",
  upvoted: "upvoted",
  "1 Upvote": "1 Upvote",
  "1 Comment": "1 kommentar",
  "Please enter a title": "Vennligst skriv inn en tittel",
  "Please enter a url link": "Vennligst skriv inn en URL -lenke",
  "Please enter button title": "Vennligst skriv inn knappetittel",
  "Button label must be at least 20 characters":
    "Knappetiketten må være minst 20 tegn",
  "Please enter valid url": "Vennligst skriv inn gyldig url",
  "Suggestions: Know more, Join, Apply...":
    "Forslag: Vet mer, bli med, søk ...",
  URL: "URL",
  "Community Events": "Fellesskapsarrangementer",
  "Widget deleted successfully": "Widget slettet vellykket",
  "Community Top Contributors": "Community Top Bidragsytere",
  points: "poeng",
  "Points cannot be greater than current points":
    "Poeng kan ikke være større enn nåværende punkter",
  "Point cannot be a negative value": "Poeng kan ikke være en negativ verdi",
  "Point cannot be a decimal point": "Poeng kan ikke være et desimalpunkt",
  "Please enter a valid number": "Vennligst oppgi et gyldig nummer",
  "Karma points updated successfully": "Karma -poeng oppdatert vellykket",
  "Error in updating karma points": "Feil ved oppdatering av karma -poeng",
  "Add karma points": "Legg til Karma -poeng",
  "Minus karma points": "Minus karma -poeng",
  "Total Karma points:": "Totalt Karma -poeng:",
  "Enter no. of karma points": "Skriv inn nei. av Karma -poeng",
  "Minus Points": "Minus poeng",
  "Trending Post": "Trending Post",
  "No visible description": "Ingen synlig beskrivelse",
  "Picture ratio: 1:1": "Bildeforhold: 1: 1",
  "home feed": "hjemmefôr",
  "Event name is invalid!": "Arrangementsnavn er ugyldig!",
  custom: "tilpasset",
  "Custom widget updated successfully": "Tilpasset widget oppdatert vellykket",
  "Name invalid!": "Navn ugyldig!",
  "Current timezone:": "Nåværende tidssone:",
  "can be updated from community settings":
    "kan oppdateres fra samfunnsinnstillinger",
  "Event created successfully": "Arrangement opprettet med hell",
  "Error in creating event": "Feil i å opprette arrangement",
  Attendees: "Deltakere",
  "Do you wish to notify these changes to attendees?":
    "Ønsker du å varsle disse endringene til deltakerne?",
  "users wants to attend this event":
    "Brukere ønsker å delta på dette arrangementet",
  "Yes, Notify them": "Ja, varsle dem",
  "No, its okay": "Nei, det går bra",
  "Do you wish to notify that event is cancelled?":
    "Ønsker du å varsle om at hendelsen blir kansellert?",
  "Are you sure you want to delete the event?":
    "Er du sikker på at du vil slette hendelsen?",
  "Delete Event": "Slett hendelse",
  "New Event": "Ny arrangement",
  "Accept Invitation": "Godta invitasjon",
  Requested: "Forespurt",
  Declined: "Avvist",
  "Request Access": "Be om tilgang",
  "Enroll for": "Melde deg på",
  Housefull: "Housefull",
  "Just copy paste this code wherever you want it to be embedded:":
    "Bare kopier lim inn denne koden uansett hvor du vil at den skal være innebygd:",
  Ended: "Endte",
  "Error getting events": "Feil å få hendelser",
  "Events could not be fetched!": "Hendelser kunne ikke hentes!",
  "Show Attendees": "Vis deltakere",
  Subscribers: "Abonnenter",
  "Make this a closed section": "Gjør dette til en lukket seksjon",
  "Update Section": "Oppdateringsseksjon",
  "Valid section name required!": "Gyldig seksjonsnavn påkrevd!",
  "Enter section name": "Skriv inn seksjonsnavn",
  "Community Admin": "Community Admin",
  Embed: "Innebygd",
  "Invite to": "Invitere til",
  "Please enter a valid email address":
    "Vennligst skriv inn en gyldig e-post adresse",
  "Invitation sent successfully": "Invitasjon sendt vellykket",
  "Error sending invitation": "Feil på å sende invitasjon",
  "Email is not valid!": "E -post er ikke gyldig!",
  "Data could not be loaded!": "Data kunne ikke lastes!",
  "Posted in": "Postet i",
  "Invite Members Individually": "Inviter medlemmene individuelt",
  "Start a discussion": "Start en diskusjon",
  "Post something": "Legg ut noe",
  "Invite your audience": "Inviter publikum",
  "Choose Plan": "Velg plan",
  or: "eller",
  "Create a link": "Opprett en lenke",
  "Add link": "Legg til lenke",
  "Update link": "Oppdateringslenke",
  "Add shortcut to your most used tools":
    "Legg til snarvei til de mest brukte verktøyene dine",
  "Your website, Instagram Channel, Facebook profile etc":
    "Nettstedet ditt, Instagram Channel, Facebook -profil osv",
  "Valid link name required!": "Gyldig lenkenavn påkrevd!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Gyldig URL som kreves, og sørg for at du ikke mangler http: // eller https: //",
  "Invite to__1": "Invitere til",
  "Link Name": "Lenkenavn",
  "Link URL": "Link URL",
  "Could not subscribe to": "Kunne ikke abonnere på",
  "Subscribed to": "Abonnerer på",
  "By default it will take up the community banner uploaded with the community":
    "Som standard vil det ta opp samfunnsbanneret lastet opp med samfunnet",
  "Created by": "Laget av",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Online -arrangement (Møtelink vil være synlig når den er registrert for arrangementet)",
  "Join Call": "Bli med på samtale",
  "Please login to book this event": "Logg inn for å bestille denne hendelsen",
  "Commented on": "Kommenterte på",
  "Replied to": "Svarte på",
  "Looks like it's lonely here.": "Ser ut som det er ensomt her.",
  "Try exploring other sections and groups.":
    "Prøv å utforske andre seksjoner og grupper.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Fortsett med betaling eller kontaktadministrator for å få tilgang til denne gruppen.",
  "This is a closed group You'll need access to this group.":
    "Dette er en lukket gruppe du trenger tilgang til denne gruppen.",
  "You have no bookmarks right now.": "Du har ingen bokmerker akkurat nå.",
  "The user hasn't posted anything yet publicly.":
    "Brukeren har ikke lagt ut noe ennå offentlig.",
  "No activity to show.": "Ingen aktivitet å vise.",
  "Are you sure you want to unsubscribe the community?":
    "Er du sikker på at du vil melde deg av samfunnet?",
  "deals left": "avtaler igjen",
  "Something went wrong while loading posts":
    "Noe gikk galt mens du lastet inn innlegg",
  "Could not unsubscribe from": "Kunne ikke melde seg av abonnement fra",
  "Unsubscribed from": "Avsluttet fra",
  "Login to view this page": "Logg inn for å se denne siden",
  Login: "Logg Inn",
  "There was some error while doing the action!":
    "Det var en viss feil mens du gjorde handlingen!",
  "Unable to delete section!": "Kan ikke slette seksjonen!",
};
