import cx from "classnames";
import { isAfter } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import RImage from "../../_components/responsive-image-holder.component";
import { createMeetingLinkWithToken } from "../../_pages";
import { formatEventTime } from "../../_pages/event-detail.page";
import { EventService } from "../../_service/event.service";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import { Loader } from "../loader.component";
import Label from "../typography/label.typography";
import UIcon from "../uicon-component";
import { useAppService } from "../../hooks/use-app-service";

/**
 * @param {*} param0
 * @returns
 */

const options = [
  {
    value: "going",
    key: "Yes, I'll attend",
    toast: "Yes",
  },
  {
    value: "maybe",
    key: "Maybe",
    toast: "Maybe",
  },
  {
    value: "not-going",
    key: "No, I won't attend",
    toast: "No",
  },
];
function SidebarEventDetailsCardComponent({
  community,
  joinScheduledPersonalMeeting,
  joinScheduledMeeting,
  event,
  addToast,
  onAttendeeClick = () => {},
  getEventDetails = () => {},
  user,
}) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [dropdownValue, setDropdownValue] = useState({
    key: "Book Event",
    value: "",
  });

  const rsvpNotAllowed =
    (event.myRSVPStatus !== "going" &&
      event.myRSVPStatus !== "maybe" &&
      event.maximumRegistrations - event.participantCount === 0) ||
    isHost;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { analyticsService } = useAppService();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /** Using REF as window.document.body is considering the dropdown also
       * if clicked on outside of Dropdown Div
       */
      function closeMenuListener(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownVisible(false);
        }
      }
      // Bind the event listener
      document.addEventListener("click", closeMenuListener);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", closeMenuListener);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (event.myRSVPStatus) {
      let selectValue = options.filter(
        (dropdownValue) => dropdownValue.value === event.myRSVPStatus
      );
      if (selectValue.length > 0) {
        setDropdownValue(selectValue[0]);
      }
    }
    if (user && event.host && event.host.id === user.id) {
      setIsHost(true);
    }
  }, [event, user]);

  const onSubmit = (item) => {
    setIsLoading(true);
    analyticsService.track("rsvp-selected", {
      rsvpType: item.value
    })
    EventService.updateRSVP(user, event.community, event.id, item.value)
      .then((response) => {
        getEventDetails();
        setDropdownValue(item);
        setIsLoading(false);
        setIsDropdownVisible(false);
        addToast(
          `Successfully registered to event as ${item.toast}!`,
          "",
          ToastTypes.success
        );
      })
      .catch((error) => {
        setIsLoading(false);
        setIsDropdownVisible(false);
        addToast("Error in accepting invitation", "", ToastTypes.danger);
      });
  };

  // add link to google calendar
  const generateGoogleCalendarUrl = () => {
    const start = new Date(event.startTime).toISOString().replace(/[:-]/g, "");
    const end = new Date(event.endTime).toISOString().replace(/[:-]/g, "");
    const title = encodeURIComponent(event.title);
    let privateLink = "";
    if (event.liveMeeting) {
      privateLink = createMeetingLinkWithToken(
        event.liveMeeting.meetingId,
        user.token
      );
    }
    const meetingLink = event.webURL || privateLink;

    const desc = encodeURIComponent(
      `${event.description}\n\n${
        meetingLink
          ? `Join here: <a href="${meetingLink}">${meetingLink}</a>`
          : ""
      }`
    );
    const timezone = community.timezone;
    const location = event.location;

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&dates=${start}/${end}&text=${title}&details=${desc}&ctz=${timezone}&location=${location}`;
    window.open(url);
  };

  return (
    <div className="p-3 md:mt-0 md:p-0">
      <div className="theme-bg-surface py-2 px-4 rounded">
        <div className="theme-text-subtitle-1 uppercase font-bold">
          <I18>Date & Time</I18>
        </div>
        <div className="py-2">
          {formatEventTime(event.startTime, event.endTime)}
        </div>
        <span className="theme-text-heading-2 text-xs pr-2">
          <span className="font-semibold">
            <I18>Timezone:</I18>&nbsp;
          </span>
          <span>
            {community.timezone ? community.timezone : "Asia/Kolkata"}
          </span>
        </span>
        {!user && isAfter(new Date(event.endTime), Date.now()) ? (
          <div className="mt-6">
            <Button disabled className="p-4 w-full" label="Book Event" large />
            <div className="theme-text-subtitle-2 text-xs py-1">
              <I18>Please login to book this event</I18>
            </div>
          </div>
        ) : null}
        {!isAfter(new Date(event.endTime), Date.now()) ? (
          <div className="mt-6">
            <Button disabled className="p-4 w-full" label="Event Over" large />
            <EventAttendeesPreview
              event={event}
              onAttendeeClick={onAttendeeClick}
            />
          </div>
        ) : (
          <>
            {user ? (
              <>
                <div ref={wrapperRef} className="relative mt-6 ">
                  {event.maximumRegistrations !== 0 &&
                  event.maximumRegistrations - event.participantCount !== 0 ? (
                    <div className="mb-1 theme-text-default text-xs font-semibold">
                      {event.maximumRegistrations - event.participantCount}{" "}
                      <I18>slots available</I18>
                    </div>
                  ) : null}
                  {event.maximumRegistrations !== 0 &&
                  event.maximumRegistrations - event.participantCount === 0 ? (
                    <div className="mb-1 theme-text-danger  text-xs font-semibold">
                      <I18>Booking full</I18>
                    </div>
                  ) : null}
                  {isLoading ? (
                    <div className="flex justify-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {/* RSVP BUTTON */}
                      <Button
                        outlined={!rsvpNotAllowed}
                        label={
                          <span className="flex items-center">
                            {dropdownValue.key}
                            <UIcon
                              icon="angle-down"
                              solid={true}
                              className={cx("ml-2  h-4 absolute right-0 mr-4", {
                                "theme-text-disabled": rsvpNotAllowed,
                                "theme-text-primary": !rsvpNotAllowed,
                              })}
                            />
                          </span>
                        }
                        className={cx("w-full", {})}
                        disabled={rsvpNotAllowed}
                        onClick={() => {
                          if (!isHost) {
                            analyticsService.track("rsvp-clicked")
                            setIsDropdownVisible(!isDropdownVisible);
                          }
                        }}
                      />
                      {/* RSVP OPTIONS */}
                      <div
                        className={cx(
                          "absolute w-full dropdown shadow-lg theme-bg-surface border theme-border-default  my-2 py-2 rounded z-10",
                          {
                            visible: isDropdownVisible,
                            hidden: !isDropdownVisible,
                          }
                        )}>
                        {options &&
                          options.map((item, index) => {
                            return (
                              <div
                                className="dropdown-button theme-text-default px-2 py-2 block cursor-pointer font-semibold hover:theme-bg-default"
                                key={index}
                                onClick={() => {
                                  onSubmit(item);
                                }}>
                                <I18>{item.key}</I18>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
                <EventAttendeesPreview
                  event={event}
                  onAttendeeClick={onAttendeeClick}
                />
                <div className="p-1">
                  <Label small>Add to Calendar : </Label>
                  <button
                    className="theme-text-primary font-semibold focus:outline-none"
                    onClick={generateGoogleCalendarUrl}>
                    Google
                  </button>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
      <div></div>
      {user &&
      (event.myRSVPStatus === "going" ||
        user.id === event.host.id ||
        event.myCommunityRole === "admin") ? (
        <>
          {event.liveMeeting !== null ? (
            <Button
              onClick={joinScheduledMeeting}
              className="mt-3 w-full"
              label="Join Event"
              large
            />
          ) : null}
          {event.webURL && event.liveMeeting === null ? (
            <Button
              onClick={joinScheduledPersonalMeeting}
              className="mt-3 w-full"
              label="Join Event"
              large
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

function EventAttendeesPreview({ event, onAttendeeClick = () => {} }) {
  let { participants } = event;

  if (Array.isArray(participants) && participants.length > 5) {
    participants = event.participants
      .filter(
        (liker) => !(liker.status !== "going" && liker.status !== "maybe")
      )
      .slice(0, 5);
  }

  return (
    <div className="PostLikesPreview pt-2">
      {Array.isArray(participants) && participants.length > 0 ? (
        <div
          onClick={() => {
            if (event.myCommunityRole === "admin") {
              onAttendeeClick(true);
            }
          }}
          className="flex items-center relative h-8 mb-1 -space-x-2">
          {
            // attendee pictures
            participants.map((liker, index) => {
              if (liker.status !== "going" && liker.status !== "maybe") {
                return null;
              }
              return (
                <RImage
                  src={liker.user.picture}
                  key={index}
                  className={`relative z-${
                    10 * -(index + 1)
                  } inline object-cover w-7 h-7 border-2 theme-border-default rounded-full ${
                    event.myCommunityRole === "admin" ? "cursor-pointer" : ""
                  } `}
                />
              );
            })
          }
          <div
            className={`text-xs font-semibold ${
              event.myCommunityRole === "admin" ? "cursor-pointer" : ""
            }`}
            style={{
              marginLeft:
                participants.length < 3
                  ? 24 + participants.length * 10 + "px"
                  : 24 + 3 * 10 + "px",
            }}>
            {event.participantCount} <I18>Attendee</I18>
            {event.participantCount > 1 ? "s" : ""}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

const SidebarEventDetailsCard = connect((s) => ({
  user: s.auth,
  group: s.activeGroup,
  community: s.community,
}))(withToast(SidebarEventDetailsCardComponent));

export default SidebarEventDetailsCard;
