import { MySubscription } from "../../../types/subscription/my-subscription-plan";
import { SubscriptionService } from "../../../_service/subscription.service";

export const SET_MY_SUBSCRIPTION_PLAN = "SET_MY_SUBSCRIPTION_PLAN";

/**
 * Set my (auth user) subscription plans
 * @param { MySubscription} mySubscription
 * @returns
 */
export function setSubscriptionPlan(mySubscription: MySubscription) {
  return {
    type: SET_MY_SUBSCRIPTION_PLAN,
    mySubscription: mySubscription,
  };
}

/**
 * Get subscription plans for auth user
 */
export function getMySubscription() {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      SubscriptionService.getMySubscription(community.id)
        .then(({ subscription }) => {
          dispatch(setSubscriptionPlan(subscription));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
}

/**
 * Cancel my subscription plan
 * @param {Function} onSuccess
 * @param {Function} onError
 * @returns
 */
export function cancelMySubscription({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess: Function;
  onError: Function;
}) {
  return async (dispatch: any, getState: any) => {
    try {
      const { auth: user, community } = getState();
      if (!user || !community) {
        return;
      }
      SubscriptionService.cancelSubscription(community.id)
        .then(({ subscription }) => {
          onSuccess(subscription);
          dispatch(setSubscriptionPlan({} as MySubscription));
        })
        .catch(({ err }) => {
          console.log(err);
          onError(err);
        });
    } catch (error) {
      console.log(error);
      onError(error);
    }
  };
}
