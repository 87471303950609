import "./message-card.style.scss";

import { Avatar } from "../avatar.component";
import { EditMessageBox } from "./edit-message-box.component";
import EmbedLinkModal from "../embed-link-modal.component";
import EmojiPickerV2 from "../form-controls/emoji-picker-v2.component";
import I18 from "../atoms/i18";
import IconMenu from "../icon-menu.component";
import { Loader } from "../loader.component";
import { MessageService } from "../../_service/message.service";
import PopupImageViewer from "../popup-image-viewer.component";
import PopupVideoPlayer from "../popup-video-player.component";
import { PostLinkMetaCard } from "../post/post-card.component";
import ReactionPills from "../reactions/reaction-pills.component";
import { RealtimeSectionPinnedMessageService } from "../../_service/realtime-section-pinned-message.service";
import { Remarkable } from "remarkable";
import { ThreadBox } from "./thread-box-component";
import UIcon from "../uicon-component";
import { connect } from "react-redux";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
import cx from "classnames";
import ellipsisIcon from "../../_assets/vectors/ellipsis-icon.svg";
import moment from "moment";
import { useState } from "react";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

function MessageCardComponent({
  highlightsPinned,
  message,
  activeTabModel,
  user,
  group,
  updateMessage = (message) => {},
  deleteMessage = (message) => {},
  noMargin = false,
  hideUserDetails = false,
  nextMessageIsBySameUser = false,
  isOnSameDay = false,
  isNotContinued = false,
  isUserAllowedToPost = false,
}) {
  const md = new Remarkable();

  const [isProcessing, setIsProcessing] = useState(false);
  const [areCommentsLoading, setAreCommentsLoading] = useState(false);

  const [isEmbedModalVisible, setEmbedModalVisible] = useState(false);

  const [isBeingEdit, setIsBeingEdit] = useState(false); // TODO: add update message card

  const [showReplies, setShowReplies] = useState(false);

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const openUserPage = (e) => {
    e.preventDefault();
    window.open(getSegmentedPath(createUserWallPageRoute(message.createdBy.id)));
  };

  return (
    <>
      {/* date divider */}
      {isOnSameDay ? null : (
        <div className="date-divider">
          <div className="date">
            {moment(message.createdAt).format("MMM DD, YYYY")}
          </div>
        </div>
      )}
      {/* message video */}
      <div
        className={cx(
          "RealtimePostCard hover:theme-bg-default  pt-1 px-3 flex gap-2 items-start",
          {
            "theme-bg-default": highlightsPinned === message.id,
            "my-3": !noMargin && !hideUserDetails && !isOnSameDay,
            "mb-0": nextMessageIsBySameUser,
          }
        )}>
        {/* user avatar */}
        {!isOnSameDay || showReplies || isNotContinued || !hideUserDetails ? (
          <Avatar
            onClick={openUserPage}
            className="cursor-pointer min-w-[40px] w-10"
            noName
            user={{ ...message.createdBy, name: undefined }}
          />
        ) : (
          <span className="w-12 flex-shrink-0">
            <span className="text-xxs msg-time theme-text-subtitle-2">
              {moment(message.createdAt).format("HH:mm")}
            </span>
          </span>
        )}
        {/* post body */}
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            {/* name and time */}
            {!hideUserDetails || !isOnSameDay || isNotContinued ? (
              <div className="flex items-center">
                <div
                  onClick={openUserPage}
                  className="font-semibold cursor-pointer">
                  {message.createdBy.name}
                </div>
                <span className="text-xxs theme-text-subtitle-1 ml-2">
                  {moment(message.createdAt).format("HH:mm")}
                </span>
              </div>
            ) : (
              <></>
            )}
            {/* options */}
          </div>
          <div className="relative">
            {isBeingEdit ? (
              <EditMessageBox
                message={message}
                onUpdate={(message) => {
                  setIsBeingEdit(false);
                  updateMessage(message);
                }}
                cancelUpdate={() => {
                  setIsBeingEdit(false);
                }}
              />
            ) : (
              <>
                <div
                  className="NL2BR headings break-words"
                  dangerouslySetInnerHTML={{
                    __html: md.render(message.description),
                  }}
                />
                {message.isEdited ? (
                  <span className="theme-text-subtitle-2 pl-2">
                    <I18>(edited)</I18>
                  </span>
                ) : (
                  <></>
                )}
              </>
            )}
            {!isBeingEdit ? (
              <div
                className={cx(
                  "realtimepost-options cursor-pointer border theme-border-default rounded",
                  { hideUserDetails: hideUserDetails }
                )}>
                <PostActions
                  post={message}
                  activeTabModel={activeTabModel}
                  group={group}
                  updatePost={updateMessage}
                  deletePost={deleteMessage}
                  areCommentsLoading={areCommentsLoading}
                  setCommentsLoading={setAreCommentsLoading}
                  setEmbedModalVisible={setEmbedModalVisible}
                  isBeingEdit={isBeingEdit}
                  setIsBeingEdit={setIsBeingEdit}
                  setIsProcessing={setIsProcessing}
                  showReplies={showReplies}
                  setShowReplies={setShowReplies}
                  isEmojiPickerVisible={isEmojiPickerVisible}
                  setIsEmojiPickerVisible={setIsEmojiPickerVisible}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* post attachments */}
          <MessageAttachments message={message} />
          <PostLinkMetaCard post={message} />
          {/* post processing */}
          <PostProcessing isProcessing={isProcessing} />
          {/* embed modal */}
          <EmbedLinkModal
            link={
              process.env.REACT_APP_EMBED_URL +
              (window.location.port ? ":" + window.location.port : "") +
              "/post/" +
              message.id
            }
            active={isEmbedModalVisible}
            setActive={setEmbedModalVisible}
          />
          {/* left options */}
          {message.reactions?.total ? (
            <div className="flex items-center mt-2">
              {/* emoji picker */}
              <div className="pr-2">
                <div className="py-0.5">
                  {message.reactions?.total ? (
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setIsEmojiPickerVisible(!isEmojiPickerVisible);
                      }}>
                      <UIcon
                        icon="grin-alt"
                        className="theme-text-subtitle-2"
                      />
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {message.reactions ? (
                <ReactionPills
                  reactions={message.reactions}
                  react={(emoji) => {
                    // do something
                    MessageService.reactToMessage(message, emoji);
                  }}
                  unreact={() => {
                    MessageService.unreactToMessage(message);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          <EmojiPickerV2
            emoji={null}
            hidePlaceholder={true}
            setEmoji={(emoji) => {
              // set emoji
              MessageService.reactToMessage(message, emoji);
              // hide picker
              setIsEmojiPickerVisible(false);
            }}
            visible={isEmojiPickerVisible}
            setVisible={setIsEmojiPickerVisible}
          />
          {/* replies */}
          <ReplyPill
            message={message}
            showReplies={showReplies}
            setShowReplies={setShowReplies}
          />
        </div>
      </div>
      {/* Thread */}
      {!message.parent ? (
        <ThreadBox
          user={user}
          replies={message.replies}
          setReplies={(replies) => {
            updateMessage({ ...message, replies });
          }}
          group={group}
          activeTabModel={activeTabModel}
          isUserAllowedToPost={isUserAllowedToPost}
          showReplies={showReplies}
          setShowReplies={setShowReplies}
          message={message}
        />
      ) : (
        <></>
      )}
    </>
  );
}

function ReplyPill({ message, showReplies, setShowReplies }) {
  const replyCount = message.replies?.length;

  return replyCount ? (
    <span
      onClick={() => {
        setShowReplies(!showReplies);
      }}
      className="inline-block p-0.5 pl-2.5 pr-2.5 rounded text-xs theme-bg-disable font-light cursor-pointer">
      <div className="flex">
        {replyCount ? replyCount : ""}{" "}
        {replyCount < 2 ? <I18>Reply</I18> : <I18>Replies</I18>}
        <div className="pl-2">
          {showReplies ? (
            <UIcon icon="angle-up" />
          ) : (
            <UIcon icon="angle-down" />
          )}
        </div>
      </div>
    </span>
  ) : (
    <></>
  );
}

/**
 * Post Actions
 * @param {*} param0
 * @returns
 */
const PostActions = connect((s) => ({
  user: s.auth,
  community: s.community,
}))(
  ({
    post,
    activeTabModel,
    user,
    group,
    community,
    updatePost = (post) => {},
    deletePost = (post) => {},
    areCommentsLoading = false,
    isBeingEdit = false,
    setCommentsLoading = (e) => {},
    setEmbedModalVisible = (e) => {},
    setIsBeingEdit = (e) => {},
    setIsProcessing = (e) => {},
    showReplies = false,
    setShowReplies = (e) => {},
    isEmojiPickerVisible = false,
    setIsEmojiPickerVisible = (e) => {},
  }) => {
    //function to pin the message
    const handlePinRealtimeSectionMessage = async (postId) => {
      try {
        await RealtimeSectionPinnedMessageService.pinRealtimeSectionMessage(
          postId
        );
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <div className="flex justify-start items-center theme-bg-surface  rounded">
        {/* visible options */}
        {activeTabModel && (
          <div className="flex space-x-2 pl-2 ">
            {!post.parent && (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowReplies(!showReplies);
                }}>
                <UIcon
                  size="lg"
                  icon="comment"
                  className="theme-text-subtitle-1"
                />
              </span>
            )}

            <span
              className="cursor-pointer"
              onClick={() => {
                setIsEmojiPickerVisible(!isEmojiPickerVisible);
              }}>
              <UIcon
                size="lg"
                icon="grin-alt"
                className="theme-text-subtitle-1 "
              />
            </span>
          </div>
        )}
        {/* more options */}
        <IconMenu
          hideOnEmpty
          iconButtonSmall
          icon={ellipsisIcon}
          rotate={90}
          items={[
            activeTabModel !== undefined && {
              // show edit button
              label: post.createdBy.id === user.id ? "Edit" : "",
              onClick: (e) => {
                setIsBeingEdit(true);
              },
            },
            activeTabModel !== undefined && {
              // show reply button
              label: "Reply",
              onClick: () => {
                setShowReplies(!showReplies);
              },
            },
            activeTabModel !== undefined &&
              community.myRole === "admin" && {
                label: "Pin message",
                onClick: () => {
                  handlePinRealtimeSectionMessage(post.id);
                },
              },
            {
              // show delete button
              label:
                post.createdBy.id === user.id ||
                (group && ["admin", "moderator"].includes(group.myRole))
                  ? "Delete"
                  : "",
              onClick: () => {
                setIsProcessing(true);
                deletePost(post);
              },
            },
          ]}
        />
      </div>
    );
  }
);

export function IconButtonSmall({
  icon = "",
  img = "",
  active = false,
  onClick = (e) => {},
  rotate = 0,
}) {
  return (
    <div
      className={cx(
        "realtimepost-option p-2 hover:hover:theme-bg-default rounded",
        {
          "bg-blue-500": active,
        }
      )}
      onClick={onClick}>
      <img
        src={icon === "img" ? img : icon}
        alt={""}
        style={{
          transform: "rotate(" + rotate + "deg)",
        }}
        className="default-icon w-3 h-3"
      />
    </div>
  );
}

/**
 * Show post is processing
 * @param {*} param0
 * @returns
 */
function PostProcessing({ isProcessing }) {
  if (!isProcessing) return <></>;

  return (
    <div className="PostProcessing">
      <Loader />
    </div>
  );
}

export function MessageAttachments({ message, removeAttachment = null }) {
  const { attachments } = message;

  if (!attachments || attachments.length === 0) return <></>;

  return (
    <div className="MessageAttachments p-1 rounded">
      {attachments.map((attachment, index) => (
        <MessageAttachment
          key={index}
          removeAttachment={
            removeAttachment
              ? (e) => {
                  // remove the attachment at index
                  removeAttachment(index);
                }
              : null
          }
          attachment={attachment}
        />
      ))}
    </div>
  );
}

// create MessageAttachment component
function MessageAttachment({ attachment, removeAttachment = null }) {
  let { mimeType, url, name, size, thumbnailUrl } = removeAttachment
    ? {}
    : attachment; // get all the details

  if (removeAttachment && attachment) {
    // make url from file
    url = URL.createObjectURL(attachment);
    mimeType = attachment.type;
    name = attachment.name;
    size = attachment.size;
  }

  const category = mimeType ? mimeType.split("/")[0] : ""; // get the category of the attachment

  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false); // show video player by default
  const [isImageViewerVisible, setIsImageViewerVisible] = useState(false); // show image viewer by default

  // if there is not url, show nothing
  if (!url) return <></>;

  // special display for image
  if (category === "image" || "gif") {
    return (
      <div className="flex relative MessageAttachmentHolder">
        <div
          // style={{
          //   backgroundImage: `url(${url})`,
          // }}
          onClick={(e) => {
            setIsImageViewerVisible(true);
          }}
          className={"MessageAttachment cursor-pointer " + category}>
          <img src={url} alt="" className="MessageAttachment" loading="lazy" />
        </div>
        <PopupImageViewer
          imageUrl={url}
          isPopupVisible={isImageViewerVisible}
          setIsPopupVisible={() => setIsImageViewerVisible(false)}
        />
        {removeAttachment ? (
          <span className="remove-attachment-button" onClick={removeAttachment}>
            &times;
          </span>
        ) : null}
      </div>
    );
  }

  // special display for video
  if (category === "video") {
    return (
      <div className="flex relative MessageAttachmentHolder">
        <div
          style={{
            backgroundImage: `url(${thumbnailUrl})`,
          }}
          className={"MessageAttachment video-thumbnail"}
          onClick={(e) => {
            setIsVideoPlayerVisible(true);
          }}>
          <div className="bg-gray-600 hover:bg-gray-400 pt-1 rounded-full h-8 w-8 flex items-center place-content-center cursor-pointer hover:scale-110">
            <UIcon
              icon="play"
              size="md"
              solid={true}
              className="text-white hover:text-gray-50"
            />
          </div>
        </div>
        <PopupVideoPlayer
          videoUrl={url}
          isVideoPlayerVisible={isVideoPlayerVisible}
          setIsVideoPlayerVisible={setIsVideoPlayerVisible}
        />
        {removeAttachment ? (
          <span className="remove-attachment-button" onClick={removeAttachment}>
            &times;
          </span>
        ) : null}
      </div>
    );
  }

  // for other attachments, show the name and size
  return (
    <div className="flex relative MessageAttachmentHolder w-full">
      <PostDocument url={url} name={name} size={size} />
      {removeAttachment ? (
        <span className="remove-attachment-button" onClick={removeAttachment}>
          &times;
        </span>
      ) : null}
    </div>
  );
}

/**
 * Show post document if necessary
 * @param {*} param0
 * @returns
 */
export function PostDocument({ url, name, size }) {
  const videoFormat = ["mp4", "mov", "webm", "ogg"];
  const audioFormat = ["wav", "mp3", "aac", "amr"];

  const document = url;
  console.log({ document });
  return document && videoFormat.includes(document.split(".").pop()) ? (
    <div className={cx("w-full my-2")}>
      <div className="py-1 mt-2 mr-3">
        <video className="m-0 rounded-md" controls controlsList="nodownload">
          <source src={document} />
        </video>
      </div>
    </div>
  ) : document && audioFormat.includes(document.split(".").pop()) ? (
    <div className={cx("w-full ")}>
      <div className="">
        <audio
          className="m-0 rounded-md w-full"
          controls
          controlsList="nodownload">
          <source src={document} />
        </audio>
      </div>
    </div>
  ) : document ? (
    <div className={cx("w-full my-2")}>
      <a
        download
        target="_blank"
        rel="noreferrer"
        href={document}
        title="Open file"
        className="flex items-center space-x-2 theme-bg-default border theme-border-default rounded px-4 font-semibold py-2">
        <UIcon icon="document" className="text-3xl h-9" />
        <div className="flex items-center justify-between flex-grow space-x-4 theme-text-default">
          <div className="items-center ">
            <div>{name ? name : ""}</div>
            <div className="uppercase text-xs">
              {/* {(documentName ? documentName : post.document).split(".").pop()} */}
              <div className="text-xs">{humanFileSize(size)}</div>
            </div>
          </div>
          <span className="flex-none">
            <UIcon icon="download" className="text-xl" />
          </span>
        </div>
      </a>
    </div>
  ) : (
    <></>
  );
}

/**
 * Create function to get human readable size from bytes
 */
function humanFileSize(bytes, si) {
  let thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  let units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}

const MessageCard = connect((s) => ({ user: s.auth, group: s.activeGroup }))(
  MessageCardComponent
);

export { MessageCard };
