import { Course } from "../../types/course/course.type";
import { CourseService } from "../../_service";
import { Status } from "./courses-reducer";

export const SET_COURSES = "SET_COURSES";
export const SET_COURSE_LOADING_STATUS = "SET_COURSE_LOADING_STATUS";

/**
 * Set Course plans for users
 * @param { Array<Course>} courses
 * @returns
 */
export function setAllCourses(courses: Array<Course>, status?: Status) {
  console.log("coursesReducer", courses);
  return {
    type: SET_COURSES,
    courses: courses,
    status: status,
  };
}

/**
 * Set course loading status
 * @param { 'idle' | 'loading' | 'succeeded' | 'failed' } status
 */
export function setCourseLoadingStatus(status: Status) {
  return {
    type: SET_COURSE_LOADING_STATUS,
    status: status,
  };
}

/**
 * Get all Course for community
 */
export function getAllCourses() {
  return async (dispatch: any, getState: any) => {
    try {
      const { community } = getState();
      if (!community) {
        return;
      }
      console.log("getAllCourses", community.id);
      dispatch(setCourseLoadingStatus("loading"));
      CourseService.getAllCourses(community.id)
        .then(({ courses }) => {
          console.log("getAllCourses", courses);
          dispatch(setAllCourses(courses, "success"));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setCourseLoadingStatus("error"));
        });
    } catch (error) {
      console.log(error);
    }
  };
}
