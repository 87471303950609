import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { Community } from "../../types/community/community.type";
import { User } from "../../types/user/minimal-user.type";
import { Button, TextInput } from "../../_components";
import I18 from "../../_components/atoms/i18";
import IconButton2 from "../../_components/icon-button-2-component";
import CropImageComponent from "../../_components/image-crop/crop-image-component";
import { NL2BR } from "../../_components/nl2br.component";
import { CommunityService } from "../../_service";
import { setCommunity } from "../../_store/_actions/community.actions";
import { useAppService } from "../../hooks/use-app-service";

export const COMMUNITY_BANNER_SETTINGS_PAGE_ROUTE = "/settings/bannersettings";

interface Props {
  addToast: any;
  community: Community;
  user: User;
  setCommunity: (community: Community) => void;
}
function CommunityBannerSettings({
  addToast,
  community,
  user,
  setCommunity,
}: Props) {
  return (
    <div className="CommunityBannerSettings Card theme-bg-surface rounded shadow">
      <CommunityBannerSettingsComponent
        addToast={addToast}
        community={community}
        setCommunity={setCommunity}
        user={user}
      />
    </div>
  );
}

/**
 * Component to edit community banner
 * @param {object} community
 * @param {function} setCommunity
 * @param {object} user
 * @param {function} addToast
 * @param {function} setIsCommunityBannerEditModalVisible
 */
interface ComponentProps {
  addToast: any;
  community: any;
  setCommunity: (community: any) => void;
  user: any;
  setIsCommunityBannerEditModalVisible?: any;
}
export function CommunityBannerSettingsComponent({
  addToast,
  community,
  setCommunity,
  user,
  setIsCommunityBannerEditModalVisible,
}: ComponentProps) {
  const [banner, setBanner] = useState(null);
  const [image, setImage] = useState(community?.banner);
  const [bannerTitle, setBannerTitle] = useState(
    community && community.bannerTitle
  );
  const [isBannerRemoved, setIsBannerRemoved] = useState(false);
  const [bannerDescription, setBannerDescription] = useState(
    (community && community.bannerDescription) ?? ""
  );

  const { analyticsService } = useAppService();

  const [isLoading, setIsLoading] = useState(false);

  const displayInModal = setIsCommunityBannerEditModalVisible ? true : false;

  const textAreaElement = useRef<HTMLTextAreaElement>(null);

  let rows = bannerDescription.split("\n").length;
  rows = rows > 3 ? rows : 3;

  const [isFormSubmittable, setIsFormSubmittable] = useState(true); // to disable submit button

  const resetFormAndClose = () => {

    if (displayInModal) {
      setIsCommunityBannerEditModalVisible(false);
    }
    setBanner(null);
    setBannerTitle(community.bannerTitle ?? "");
    setBannerDescription(community.bannerDescription ?? "");
  };

  useEffect(() => {
    if (!community) return;
    setBannerTitle(community.bannerTitle ?? "");
    setBannerDescription(community.bannerDescription ?? "");
  }, [community]);

  const submitForm = (e: any) => {
    // update the details of the community
    setIsLoading(true);
    CommunityService.updateCommunityBannerDetails(
      community.id,
      bannerTitle,
      bannerDescription,
      user
    )
      .then(({ community }) => {
        // check if need to upload banner image
        if (image && typeof image !== "string") {
          CommunityService.updateCommunityBanner(community.id, user, image)
            .then(({ community }) => {
              addToast(
                "Banner details updated!",
                "Refresh if changes are not visible!"
              );
              resetFormAndClose();
              setCommunity(community);
              setIsLoading(false);
            })
            .catch((error) => {
              addToast(
                "Banner details could not be updated!",

                "",
                ToastTypes.danger
              );
              console.log("error occurred!", { error });
              setIsLoading(false);
            });
        } else if (isBannerRemoved) {
          // Remove community banner
          CommunityService.removeCommunityBanner(user, community.id)
            .then(({ community }) => {
              addToast(
                "Banner details updated!",
                "Refresh if changes are not visible!"
              );
              resetFormAndClose();
              setCommunity(community);
              setIsLoading(false);
            })
            .catch((error) => {
              addToast(
                "Banner details could not be updated!",
                "",
                ToastTypes.danger
              );
              console.log("error occurred!", { error });
              setIsLoading(false);
            });
        } else {
          addToast(
            "Banner details updated!",
            "Refresh if changes are not visible!"
          );
          resetFormAndClose();
          setIsLoading(false);
          setCommunity(community);
        }
        analyticsService.track("community-banner-update", {
          ...(community.bannerTitle !== '' && { bannerTitle: community.bannerTitle }),
          ...(community.bannerDescription !== '' && { bannerDescription: community.bannerDescription }),
          ...(community.banner !== '' && { banner: community.banner })
      });

      })
      .catch((error) => {
        addToast("Banner details could not be updated!", "", ToastTypes.danger);
        console.log("error occurred!", { error });
        setIsLoading(false);
      });
  };

  if (!community) {
    return null;
  }
  return (
    <div className="CommunityBannerSettingsComponent theme-bg-default">
      {/* headers */}
      <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
        <span className="font-bold theme-text-heading-2">
          <I18>Update</I18> {community.name} <I18>Community Banner</I18>
        </span>
        {displayInModal ? (
          <IconButton2
            icon="cross"
            size="md"
            onClick={() => {
              setIsCommunityBannerEditModalVisible(false);
            }}
          />
        ) : (
          <div className="h-10"></div>
        )}
      </div>
      {/* form controls */}
      <div className="flex flex-col px-5 pt-5 pb-8 theme-bg-default">
        {/* banner */}
        <div className="banner-selector-holder overflow-scroll rounded theme-bg-surface">
          <div
            className={cx("banner-selector cursor-pointer", {
              selected: image || community.banner,
              "justify-center items-center": !banner && !community.banner,
            })}>
            {/* {banner || community.banner ? ( */}
            <div className="flex flex-col w-full">
              <CropImageComponent
                picture={image}
                setPicture={setImage}
                setPictureSelected={(selected: any) => setBanner(selected)}
                setIsPictureRemoved={setIsBannerRemoved}
                aspectRatio={16 / 6}
                recommendedRatio={"16:6"}
                cropRatioMessage={"Banner Ratio - 16:6"}
                displayUnsplashSearch={true}
              />
              <div className="px-4 font-semibold text-lg mt-2">
                {bannerTitle ? bannerTitle : ""}
              </div>
              <div className="px-4 ">
                <NL2BR text={bannerDescription} />
              </div>
            </div>
          </div>
        </div>
        {/* name */}
        <div className="font-semibold theme-text-heading-2 mt-4">
          <I18>Banner Title</I18>
        </div>
        <TextInput
          placeholder="Enter here"
          validator={(value) => {}}
          defaultValue={bannerTitle}
          className="pt-2 focus:outline-none theme-bg-surface"
          onChange={(value) => {
            setBannerTitle(value);
          }}
        />
        <div className="text-xs theme-text-subtitle-2 font-light">
          <I18>Suggestions: Welcome to my community etc</I18>
        </div>

        {/* banner description */}
        <>
          <div className="font-semibold theme-text-heading-2 mt-4">
            <I18>Banner Description</I18>
          </div>
          <textarea
            ref={textAreaElement}
            value={bannerDescription}
            onChange={(e) => {
              setBannerDescription(e.target.value);
              window.setTimeout(() => {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }, 0);
            }}
            className="px-4 py-2 w-full my-3 flex-grow block border theme-border-default theme-bg-surface rounded focus:outline-none"
            rows={rows}
            placeholder={"Enter Banner Description"}
          />
        </>
      </div>
      {/* submit button */}
      <div
        className={cx("px-4 py-3 theme-bg-surface", {
          "border-t theme-border-default": displayInModal,
        })}>
        <div
          className={cx("mx-auto flex items-center", {
            "justify-between": displayInModal,
            "justify-end": !displayInModal,
          })}>
          {displayInModal && (
            <Button
              onClick={(e) => {
                resetFormAndClose();
              }}
              flat
              className="p-4 theme-border-primary"
              label="Cancel"
              large
            />
          )}

          <Button
            disabled={!isFormSubmittable}
            onClick={submitForm}
            className="p-4"
            label={"Save"}
            large
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

const stp = (state: any) => ({
  user: state.auth,
  community: state.community,
  communityPac: state.communityPac,
  setCommunity: state.updateCommunity,
});

const dtp = (dispatch: any) =>
  bindActionCreators({ setCommunity: setCommunity }, dispatch);

const CommunityBannerSettingsPage = withToast(
  connect(stp, dtp)(CommunityBannerSettings)
);

export default CommunityBannerSettingsPage;
