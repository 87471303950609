import React, { useState } from "react";
import UpgradePlanModal, { UpgradePlanComponent } from "./upgrade-plan-modal";

interface Props {
  isAvailable: boolean;
  hideFeature?: boolean;
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
}
function PremiumFeatureComponent({
  isAvailable = true,
  children,
  className,
  hideFeature = false,
}: Props) {
  const [isActive, setActive] = useState(false);
  return (
    <section
      className={`PremiumFeature   ${className}`}
      onClick={(e) => {
        if (!isAvailable) {
          e.preventDefault();
          e.stopPropagation();
          setActive(true);
        }
      }}>
      {/* Display Pricing component if premium is not available and feature is not visible*/}
      {!isAvailable && hideFeature ? (
        <UpgradePlanComponent />
      ) : (
        <div className="relative">
          {children}

          {/* Restrict user interaction with feature if premium not available */}
          {!isAvailable && (
            <div className="Layer absolute top-0 bottom-0 left-0 right-0 z-10"></div>
          )}
        </div>
      )}

      {/* Display Pricing  modal if premium is not available and feature is visible*/}
      {!isAvailable && !hideFeature && (
        <UpgradePlanModal active={isActive} setActive={setActive} />
      )}
    </section>
  );
}
/**
 * @param {boolean} isAvailable - Whether the premium is available or not.
 * @param {boolean} hideFeature - Whether the feature should be hidden or not. If feature is not available, it will be hidden.
 * @param {any} children - Children to be rendered.
 * @description Restricts the user to access the premium features if the premium is not available.
 */
const PremiumFeature = PremiumFeatureComponent;
export default PremiumFeature;
