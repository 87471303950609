export const deGerman = {
  "Pensil Keyword": "German",
  Home: "Home",
  "Explore Groups": "Gruppen erkunden",
  Events: "Veranstaltungen",
  Groups: "Gruppen",
  Links: "Links",
  "Direct Message": "Direktnachricht",
  "Powered by": "Bereitgestellt von",
  "No groups here, please use + to create a new one, or join one see them here.":
    "Keine Gruppen hier, bitte verwende +, um eine Neue zu erstellen oder tritt einer angezeigten Gruppe bei.",
  "Please join a group to see them here":
    "Bitte tritt einer Gruppe bei um diese hier anzuzeigen",
  "Section can only be reordered within the group!":
    "Der Abschnitt kann nur innerhalb der Gruppe neu angeordnet werden!",
  "Group Page": "Gruppenseite",
  "Invite Members": "Mitglieder einladen",
  Join: "Beitreten",
  Joined: "Beigetreten",
  "Create new section": "Neuen Abschnitt erstellen",
  "Group Setting drop down": "Gruppeneinstellung Dropdown",
  Share: "Teilen",
  "Edit group": "Gruppe bearbeiten",
  "Delete group": "Gruppe löschen",
  Leave: "Verlassen",
  "Are you sure you want to delete the group?":
    "Bist du sicher, dass du die Gruppe löschen willst?",
  Cancel: "Abbrechen",
  "Yes, Go Ahead": "Ja, weitermachen",
  "Share via link": "Per Link teilen",
  Copy: "Kopieren",
  Copied: "Kopiert",
  "Share on social media": "In den sozialen Medien teilen",
  "Add Section": "Abschnitt hinzufügen",
  "Section Header": "Abschnittskopfzeile",
  Live: "Live",
  "Create Post": "Beitrag erstellen",
  "Notificaiton Bell": "Benachrichtigungs-Glocke",
  "All posts": "Alle Beiträge",
  "Admin posts only": "Nur Beiträge von Admins",
  None: "Keiner",
  "Section Setting drop down": "Abschnittseinstellung Dropdown",
  "Edit Section": "Abschnitt bearbeiten",
  "Embed Section": "Eingebundener Abschnitt",
  "Delete Section": "Abschnitt löschen",
  "Are you sure you want to delete the section?":
    "Bist du sicher, dass du den Abschnitt löschen willst?",
  "Section deleted!": "Abschnitt gelöscht!",
  "Create Group Pop up / Update": "Gruppen Popup / Update erstellen",
  "Create group": "Gruppe erstellen",
  "Basic Detail": "Grundlegendes Detail",
  Settings: "Einstellungen",
  "Size 16:9 or 1600 by 900": "Größe 16:9 oder 1600px x 900px",
  "Group name": "Gruppenname",
  "Enter group name": "Gib den Gruppennamen ein",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Vorschläge: Leerzeichen, Kanäle, Gruppen, Blog, KnowledgeBase usw.",
  "Group Description": "Gruppenbeschreibung",
  "Enter description here": "Gib hier eine Beschreibung ein",
  "Group Type": "Gruppentyp",
  Open: "Offen",
  "Visible to all & anyone can join":
    "Für jeden sichtbar und jeder kann beitreten",
  Closed: "Geschlossen",
  "User will have to request to join the group":
    "Der Benutzer muss anfragen um der Gruppe beizutreten",
  Paid: "Bezahlt",
  "Group Price": "Preis für die Gruppe",
  "Currency (Default: INR)": "Währung (Standard: INR)",
  "User will have to pay to join the group":
    "Der Benutzer muss bezahlen, um der Gruppe beizutreten",
  "Make this a private group": "Zu einer privaten Gruppe ändern",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "Eine private Gruppe ist nur für Mitglieder sichtbar, die Teil davon sein. Ein Admin kann Mitglieder manuell aus der Mitgliederliste hinzufügen",
  "Upload from device": "Vom Gerät hochladen",
  "Search image": "Suchbild",
  "Valid group name required!": "Gültiger Gruppenname erforderlich!",
  "Update Group": "Gruppe aktualisieren",
  "SEO Settings": "SEO Einstellungen",
  "Custom URL": "Eigene URL",
  "Meta Title": "Meta-Titel",
  "Maximum length of 255 characters allowed":
    "Maximale Länge von 255 Zeichen zulässig",
  "Meta Description": "Meta Beschreibung",
  "Open Graph Detail": "Open Graph Detail",
  "Open Graph Title": "Open Graph Titel",
  "Open Graph Description": "Open Graph Beschreibung",
  "Open Graph Image": "Open Grpah Bild",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Empfohlene Abmessungen: 1200px x 630px mit einem Seitenverhältnis von 1,91:1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Wird keine URL verwendet, wird automatisch das Thumbnail der Gruppe verwendet.",
  "Create Section Pop up / Update": "Abschnitt Popup / Update",
  "Create Section": "Abschnitt erstellen",
  "Section Name": "Abschnitt Name",
  "Suggestions: General, Introductions, Community Guidelines":
    "Vorschläge: Allgemein, Einführung, Richtlinien",
  "Section Type": "Abschnittstyp",
  "Post Type": "Beitragstyp",
  "Group Chat": "Gruppenchat",
  "Who can post in this section?": "Wer kann in diesem Abschnitt posten?",
  "Only Admin": "Nur Admin",
  "Any user": "Jeder Benutzer",
  "Sorting order for section": "Sortierreihenfolge für den Abschnitt",
  "Latest first": "Neueste Beiträge zuerst",
  "Oldest post first": "Älterer Beitrag zuerst",
  "Section Appearance": "Aussehen des Abschnitts",
  "List View": "Listenansicht",
  "Grid View": "Rasteransicht",
  "Make this closed section": "In einen geschlossenen Abschnitt ändern",
  "Members will have to request access to see section content":
    "Mitglieder müssen den Zugriff beantragen, um den Inhalt des Abschnitts anzuzeigen",
  "Make this a private section": "In einen privaten Abschnitt ändern",
  "Disables requests (Make sure closed section is selected as well).":
    "Anfragen deaktivieren (stellen Sie sicher, dass der geschlossene Abschnitt ebenfalls ausgewählt ist).",
  "Post Editor": "Beitrags-Editor",
  "Create new post in": "Erstelle einen neuen Beitrag in",
  "Add Cover Picture": "Titelbild hinzufügen",
  "Heading (Optional)": "Überschrift (optional)",
  "Write here..": "Hier schreiben...",
  Picture: "Bild",
  Video: "Video",
  Attachment: "Anhang",
  Poll: "Umfrage",
  "Embed Code": "Code einbetten",
  Tags: "Tags",
  "Toggle to question": 'Zu "Frage" ändern',
  "Poll expire time": "Laufzeit der Umfrage",
  "Poll question": "Umfrage",
  "Enter Question": "Fragen eingeben",
  Question: "Frage",
  Option: "Option",
  Options: "Optionen",
  "Add Option": "Option hinzufügen",
  "Poll Expiry time": "Ablaufzeit der Umfrage",
  "12 Hours": "12 Stunden",
  "1 Day": "1 Tag",
  "2 Days": "2 Tage",
  "1 Week": "1 Woche",
  "2 Weeks": "2 Wochen",
  "Always Open": "ohne zeitliche Beschränkung",
  "Write embeddable code": "Schreib einen einbettbaren Code",
  "Click here to add embed code.":
    "Klicke hier, um ein Einbettungscode hinzuzufügen.",
  "Paste your embed code here!": "Füge deinen Einbettungscode hier ein!",
  "Post Settings": "Beitragseinstellungen",
  "Update Post": "Beitrak aktualisieren",
  Update: "Aktualisieren",
  "Start meeting / Video call / Live": "Meeting / Videoanruf / Live starten",
  "Meeting Name": "Name des Meetings",
  "Suggestion: Group discussion etc": "Vorschlag: Gruppendiskussion usw.",
  Description: "Beschreibung",
  "Enter description here (optional)":
    "Gib hier eine Beschreibung ein (optional)",
  "When do you want to start the session":
    "Wann möchtest du das Meeting starten?",
  "Right now": "Jetzt",
  "Schedule for later": "Für später planen",
  "Group video call": "Gruppen Videanruf",
  Webinar: "Webinar",
  "Go Live": "Live starten",
  "Info on the Post": "Infos über den Beitrag",
  "Post comment": "Kommentar hinzufügen",
  Comment: "Kommentar",
  Comments: "Kommentare",
  Reply: "Antwort",
  Replies: "Antworten",
  "Share via URL": "Über URL teilen",
  "Reshare in other sections": "In anderen Abschnitten teilen",
  "Post Card Drop Down": "Beitrags-Karte Dropdown",
  Bookmark: "Lesezeichen",
  "Pin post": "Beitrag anheften",
  Edit: "Bearbeiten",
  "Embed Post": "Eingebetteter Beitrag",
  Delete: "Löschen",
  "Remove Pin": "Nicht mehr anheften",
  "Remove Bookmark": "Lesezeichen entfernen",
  "Just copy paste this code wherever you want it to be embedded":
    "Kopiere einfach diesen Code, und füge ihn dort ein, wo er angezeigt werden soll.",
  Height: "Höhe",
  Preview: "Vorschau",
  "Copied to clipboard": "In die Zwischenablage kopiert",
  "Select Widgets": "Wähle Widgets aus",
  "Add widgets to": "Widgets hinzufügen zu",
  "You can change their order by dragging & dropping the widgets over each other":
    "Du kannst die Anordnung der Widgets ändern, indem du ein Widget über ein anderes ziehst",
  "Please note: Widget wont be displayed if there is no data available,":
    "Bitte beachte: Das Widget wird nicht angezeigt, wenn keine Daten verfügbar sind.",
  "Group Members": "Gruppenmitglieder",
  "Group Leaderboard": "Gruppen Leaderboard",
  "Community Leaderboard": "Community Leaderboard",
  "Trending Posts": "Top-Beiträge",
  "Group Events": "Gruppen Events",
  "Add Custom": "Eigenes hinzufügen",
  "Save Widgets": "Widgets speichern",
  "Visible to user": "Für den Benutzer sichtbar",
  "Visible to admin": "Sichtbar für Admins",
  "Add widget to": "Widget hinzufügen zu",
  "Upload cover picture": "Titelbild hochladen",
  "1:1 aspect ration (recommended)": "Verhätlnis 1:1 (empfohlen)",
  "Card Title": "Titel der Karte",
  "Enter widget name": "Gib den Namen des Widgets ein",
  "Small Description (try to keep it under 50 words)":
    "Kurze Beschreibung (unter 50 Wörter)",
  "Enter description": "Beschreibung eingeben",
  "Button Title": "Titel der Schaltfläche",
  "Enter button name": "Name der Schaltfläche eingeben",
  "Siggestion: Know more, Join, Apply, etc":
    "Vorschläge: mehr erfahren, beitreten, anfragen, usw.",
  "URL link": "URL-Link",
  "Create custom widget": "Ein benutzerdefiniertes Widget erstellen",
  "Custom widget created successfully":
    "Benutzerdefiniertes Widget erfolgreich erstellt",
  Widgets: "Widgets",
  Members: "Mitglieder",
  "See All": "Alles anzeigen",
  "Communtiy Top Contributors": "Communtiy Leaderboard",
  "Community Members": "Community Mitglieder",
  "Group Top Contributors": "Gruppen Leaderboard",
  "Upcoming Events": "Zukünftige Veranstaltungen",
  "Home Page Banner": "Titelbild der Startseite",
  "Edit Banner": "Titelbild bearbeiten",
  "Home page Edit Banner Pop up": "Homepage bearbeiten Titelbild Popup",
  "Update Banner": "Titelbild ändern",
  Search: "Suchen",
  "Upload Image": "Bild hochladen",
  Remove: "Entfernen",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Titelbild (Bitte verwende ein 16:6 oder 1600px x 600px großes Bild für die optimale Anzeige)",
  "Banner Title": "Titelbild Titel",
  "Enter here": "Hier eingeben",
  "Suggestion: Welcome to my community etc":
    "Vorschlag: Willkommen in meiner Community, usw.",
  "Banner Description": "Beschreibung des Titelbildes",
  "Enter banner description": "Gib eine Beschreibung des Titelbildes ein",
  "Save Banner Info": "Informationen zum Titelbild speichern",
  "Home Page Feed": "Home Feed",
  Recent: "Neueste Beiträge",
  "Top Posts": "Top Beiträge",
  Trending: "Beiträge im Trend",
  "Home Feed Groups": "Home Feed Gruppen",
  "Header BarProfile Drop down": "Menüleiste Drop down",
  "My Profile": "Mein Profil",
  Logout: "Abmelden",
  Notification: "Benachrichtigung",
  Notifications: "Benachrichtigungen",
  "Mark all viewed": "Alle als gelesen markieren",
  "has created a post": "hat einen Beitrag erstellt",
  "wants to join": "will beitreten",
  "has commented on your post": "hat Ihren Beitrag kommentiert",
  "has replied to your comment": "hat auf Ihren Kommentar geantwortet",
  "Search for tags": "Suche nach Tags",
  "All groups": "Alle Gruppen",
  "Search result": "Suchergebnis",
  "No post found for selected hashtag":
    "Kein Beitrag für gesuchten Hashtag gefunden",
  "Start a conversation": "Einen privaten Chat starten",
  "Search by name": "Suche nach Namen",
  Upcoming: "Zukünftige",
  UPCOMING: "ZUKÜNFTIGE",
  Past: "Vergangene",
  PAST: "VERGANGENE",
  "No upcoming events": "Keine zukünftigen Events",
  "Create event popup": "Erstelle ein Event Popup",
  "Event details": "Details des Events",
  "16:9 aspect ratio": "16:9 Seitenverhältnis",
  "Event name": "Name des Events",
  "Enter event name": "Name des Events eingeben",
  "Host Name": "Name des Hosts",
  "You will be made host if none selected":
    "Du bist der Host, wenn niemand ausgewählt wurde",
  "Write here...": "Hier schreiben...",
  "No. of registration for the event":
    "Anzahl der Anmeldungen für die Veranstaltung",
  Unlimited: "Unbegrenzt",
  "When & where": "Wann & Wo",
  From: "Von",
  "Start date": "Startdatum",
  time: "Zeit",
  To: "bis",
  "End date": "Enddatum",
  "Current time zone:": "Aktuelle Zeitzone:",
  "Can be updated from the communtiy setting":
    "Kann von den Einstellungen übernommen werden",
  "Online event": "Online Event",
  "Add meeting link": "Meeting Link hinzufügen",
  "Meeting link will be generated once event is created":
    "Der Meeting Link wird generiert, sobald das Ereignis erstellt wurde",
  Venue: "Veranstaltungsort",
  "Enter address": "Adresse eingeben",
  Create: "Erstellen",
  Invite: "Einladen",
  "Share event via link": "Event über Link teilen",
  Copy__1: "Kopieren",
  "Select section to post this event":
    "Wähle einen Abschnitt aus, um dieses Ereignis zu veröffentlichen",
  "Once posted, members of that group or section will recieve an notification":
    "Nach der Veröffentlichung erhalten Mitglieder dieser Gruppe oder des Abschnitts eine Benachrichtigung",
  Post: "Beitrag",
  RSVP: "RSVP",
  "Book Event": "Am Event teilnehmen",
  "Yes, I'll attend": "Ja, ich werde teilnehmen",
  "May be": "Vielleicht",
  "No, I won't attend": "Nein, ich werde nicht teilnehmen",
  "Date & Time": "Datum & Uhrzeit",
  Host: "Host",
  "Online meeting": "Online Meeting",
  "Meeting link will be visible once registered for the event":
    "Der Besprechungslink ist sichtbar, sobald du dichfür das Event angemeldet hast",
  "User Management": "Benutzerverwaltung",
  "Go back to community": "Zurück zur Community",
  "Manage Members": "Mitglieder verwalten",
  Member: "Mitglied",
  Invited: "Eingeladen",
  "Search for member": "Suche nach Mitgliedern",
  "Add to group": "Zur Gruppe hinzufügen",
  "Send Direct Message": "Direktnachricht senden",
  "Change role": "Rolle ändern",
  "Kick from community": "Aus der Community entfernen",
  "Super Admin": "Super Admin",
  "Super admin will have full access to the communtiy settings":
    "Super Admin hat vollen Zugriff auf die Community Einstellungen",
  "Group Admin": "Gruppenadministrator",
  "Admin of the selected group can edit the group information and create, edit or delete section under selected group":
    "Der Administrator der ausgewählten Gruppe kann die Gruppeninformationen bearbeiten, einen Abschnitt erstellen, bearbeiten oder löschen.",
  "Group Moderator": "Gruppenmoderator",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "Gruppenmoderator kann in der ausgewählten Gruppe Posts erstellen, löschen, kommentieren und antworten.",
  User: "Benutzer",
  Save: "Speichern",
  "User role updated successfully": "Benutzerrolle erfolgreich aktualisiert",
  Leaderboard: "Leaderboard",
  "Click on the column header for sorting":
    "Klicke auf die Spalten-Überschrift zum Sortieren",
  Rank: "Rang",
  "Karma Points": "Karmapunkte",
  "Custom Points": "Benutzerdefinierte Punkte",
  Follow: "Folgen",
  Upvotes: "Like",
  Posts: "Beiträge",
  "Add custom points": "Füge benutzerdefinierte Punkte hinzu",
  "Subtract custom points": "Entferne benutzerdefinierte Punkte",
  "Enter no. of points": "Gib die Anzahl der Punkte ein",
  "Add points": "Punkte hinzufügen",
  "Invite members to": "Mitglieder einladen zu",
  "Invite via link": "Über Link einladen",
  "Invite via email": "Per E -Mail einladen",
  "Add more": "Mehr hinzufügen",
  "Upload a CSV": "Eine CSV Datei hochladen",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Bitte lade eine durch Komma getrennte CSV-Datei mit Überschrift als Name und E-Mail hoch.",
  "Here is a template CSV file": "Hier ist eine Vorlage der CSV-Datei",
  "Upload File": "Datei hochladen",
  "Send Invite": "Einladung senden",
  "Mail Template": "Mail-Vorlage",
  "Send Test Mail": "Test Mail senden",
  Hey: "Hey",
  "A has invite you to join the B":
    "du wurdest von A eingeladen der B beizutreten",
  "Switch to rich text editor": "Wechsle zum Rich-Texteditor",
  "Add Custom Message": "Füge eine benutzerdefinierte Nachricht hinzu",
  "Enter message here": "Gib hier eine Nachricht ein",
  "Send Bulk Mail": "Massen-Mail senden",
  "Send Mail": "Mail senden",
  "Send bulk mail to all community members or select group members":
    "Als Newsletter an die ganze Community senden oder wähle Gruppenmitglieder aus",
  Date: "Datum",
  Subject: "Betreff",
  Recipients: "Empfänger",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 Newsletter-Mails für diesen Monat verbleibed. Zurückgesetzt am <Date>",
  Everyone: "Alle",
  "Enter mail subject": "Gib einen Betreff ein",
  "Write message": "Nachricht schreiben",
  Cancel__1: "Abbrechen",
  Send: "Senden",
  "Profile Page": "Profilseite",
  Activity: "Aktivität",
  Bookmarks: "Lesezeichen",
  "Edit Profile": "Profil bearbeiten",
  Unsubscribe: "Abbestellen",
  "Settings (Account)": "Einstellungen (Konto)",
  Profile: "Profil",
  "Set profile Picture": "Füge ein Profilbild hinzu",
  "Your name": "Ihr Name",
  "User ID": "Benutzer-ID",
  "Short bio": "Kurzbiografie",
  "Link your social account, this will be displayed on your profile":
    "Verknüpfe deine Social Media Konten. Diese werden in Ihrem Profil angezeigt",
  "Add social handle": "Social Media hinzufügen",
  Verification: "Verifizierung",
  "Account Verification": "Bestätigung des Kontos",
  Email: "E-Mail",
  "Enter email": "E-Mail Adresse eingeben",
  Verified: "Verifiziert",
  Unverified: "Nicht verifiziert",
  Change: "Ändern",
  Unlink: "Trennen",
  Mobile: "Handy",
  "enter mobile no.": "Handynummer eingeben",
  "Request OTP": "Bestätigungscode anfordern",
  "Notification centre": "Benachrichtigungszentrum",
  "Manage your personal account notification preferences":
    "Verwalte deine persönlichen Konto-Benachrichtigen",
  Prefrences: "Einstellungen",
  "Email notification": "E-Mail Benachrichtigung",
  "Notify me about comments on my posts":
    "Benachrichtige mich über Kommentare zu meinen Beiträgen",
  "Notify me about replies on my comments":
    "Benachrichtige mich über Antworten auf meine Kommentare",
  "Notify me about upvotes on my posts":
    "Benachrichtige mich über Likes zu meinen Beiträgen",
  "Notify me about reaction on my comments":
    "Benachrichtige mich über Reaktionen auf meine Kommentare",
  "Notify me about reaction on my reply":
    "Benachrichtige mich über Reaktionen auf meine Antworten",
  "Notify me about direct message":
    "Benachrichtige mich über persönliche Nachrichten",
  "Notify me about my mentions": "Benachrichtige mich über meine Erwähnungen",
  "Notification for new posts": "Benachrichtigung über neue Beiträge",
  "Only group & section available which you are part of":
    "Es sind nur Gruppen oder Abschnitte verfügbar, in denen du Mitglied bist",
  "Group & Section": "Gruppe & Abschnitt",
  "All activity": "Alle Aktivitäten",
  "Post by admin": "Beitrag eines Admins",
  "No Notification": "Keine Benachrichtigung",
  "Setting (Community)": "Einstellung (Community)",
  "General Settings": "Allgemeine Einstellungen",
  "Upload community logo": "Lade das Community Logo hoch",
  "Community name": "Name der Community",
  "Invalid field": "Ungültiges Feld",
  "Your community address": "Deine Community Adresse",
  "Fav icon": "Favicon",
  "This will be visible on the browser tab (32px by 32px)":
    "Wird im Browser Tag angezeigt (32px x 32px)",
  TimeZone: "Zeitzone",
  "Privacy Policy on login page": "Datenschutzrichtlinien auf der Login-Seite",
  "Terms & Condition on login page": "AGB auf Login-Seite",
  "Enter link here": "Link hier eingeben",
  "Open Group Image": "Gruppenbild öffnen",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Standardmäßig wird das Titelbild der Community verwendet",
  "Community Experience": "Community Erfahrung",
  Onboarding: "Onboarding",
  "Make your community private": "Diese Community auf privat ändern",
  "Users will have to login or sign up to access the community":
    "Benutzer müssen sich registrieren oder anmelden, um auf die Community Zugriff zu haben",
  "Make account verification mandatory for both email & mobile":
    "E-Mail & Handynummer müssen angegeben werden",
  "New user will have to verify both email & mobile no. to access the community":
    "Ein neues Mitglied muss sowohl seine E-Mail als auch Handynummer angeben, um Zugang zur Community zu erhalten",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "Auch Admins müssen ihre E-Mail Adresse und Handynummer angeben um Zugriff auf die Community zu haben",
  "Gated community access": "Zugriff auf die Community mit einem Portal",
  "Admin will have to approve or reject user's requests to join the community":
    "Ein Admin kann die Beitritts-Anfrage des Benutzers genehmigen oder ablehnen",
  "Disable mobile login/register":
    "Deaktiviere Login/Registrierung mit der Handynummer",
  "Disable email login/register":
    "Deaktiviere Login/Registrierung mit der E-Mail Adresse",
  "Disable google login/register": "Deaktiviere Login/Registrierung mit Google",
  "Disable facebook login/register":
    "Deaktiviere Login/Registrierung mit Facebook",
  "Show & Hide features": "Funktionen anzeigen & ausblenden",
  "Hide direct chat": "Privaten Chat verbergen",
  "User will not be able to send direct messages to each other":
    "Benutzer können keine privaten Nachrichten senden",
  "Hide profile picture from top right corner for mobile responsive view":
    "Das Profilbild in der responsiven mobilen Ansicht in der oberen rechten Ecke nicht anzeigen",
  "Useful when using community in an in app browser":
    "Nützlich bei der Verwendung dieser Community in einem In-App-Browser",
  "Hide profile picture from top right corner in every view":
    "Das Profilbild in der oberen rechten Ecke immer ausblenden",
  "Disable header dropdown on profile picture":
    "Deaktiviere die Dropdown-Funktion des Profilbildes",
  "Useful for integrated community, especially using SSO":
    "Nützlich für integrierte Communities, insbesondere mit SSO",
  "Hide the communtiy logo from the header":
    "Das Community Logo in der Kopfzeile ausblenden",
  "Hide the communtiy title from the header":
    "Den Community Namen in der Kopfzeile ausblenden",
  "Show collapsed group in the side nav bar":
    "Eingeklappte Gruppe in der seitlichen Navigationsleiste anzeigen",
  "Show full expanded post in the home feed":
    "Zeige den ganzen Beitrag im Home Feed an",
  "Hide the leaderboard score from the header":
    "Das Leaderboard in der Kopfzeile ausblenden",
  "Show powered by Pensil": 'powered by Pensil" anzeigen',
  "Hide closed groups if not joined":
    "Private Gruppen für Nicht-Mitglieder ausblenden",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Private Gruppen werden Nicht-Mitgliedern nicht angezeigt",
  "Hide Community subscriber count from the users":
    "Mitglieder Anzahl ausblenden",
  "This will hide the community subscriber list in the community header":
    "Die Community Mitglieder Liste in der Kopfzeile wird nicht angezeigt",
  "Disable Communtiy Switcher": "Die Community Auswahl nicht anzeigen",
  "Use MD editor for create post description":
    "Verwende den MD-Editor für die Beitrags-Erstellung",
  "Non logged in user will land on explore group page instead of post directory":
    'Nicht eingeloggte Benutzer landen auf der Seite "Gruppe erkunden" statt im Verzeichnis der Beiträge',
  "Advanced configurable": "Erweiterte Konfigurierbarkeit",
  "Community default country code": "Standard-Ländercode der Community",
  "This will show up by default on login/signup":
    "Dies wird standardmäßig bei der Anmeldung/Registrierung angezeigt",
  "Onboarding/Welcome Message": "Onboarding/Begrüßungsnachricht",
  "Welcome Message": "Begrüßungsnachricht",
  "Greet your members with personalized message":
    "Begrüße Mitglieder mit einer persönlichen Nachricht",
  "Welcome to": "Willkommen in der",
  Message: "Nachricht",
  "Add message": "Nachricht hinzufügen",
  "Custom domain": "Benutzerdefinierten Domain",
  "Add custom domain": "Füge eine benutzerdefinierte Domain hinzu",
  "Create new request": "Neue Anfrage erstellen",
  "Enter your custom domain": "Gib die benutzerdefinierte Domain ein",
  "Setup details": "Details zur Einrichtung",
  "Add a CNAME record in your domain pointing to this community's address":
    "Füge der Domain einen CNAME-Eintrag hinzu, der auf die Adresse dieser Community verweist",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "Um die Domain zu konfigurieren, müss ein CNAME-Eintrag hinzugefügt werden, der die Domain zur Pensil-Community-URL verweist",
  "Step by Step help page": "Schritt für Schritt Anleitung",
  "All request list": "Liste aller Anfragen",
  "Allow admin to assign/un-assign custom points to users":
    "Administratoren können Benutzern benutzerdefinierte Punkte zuweisen bzw. die Zuweisung aufheben",
  "Base points for user to begin with":
    "Basispunkte für den Benutzer zu Beginn",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Die Basispunkte werden für alle Benutzer aktualisiert, alle Änderungen werden für alle Benutzer übernommen.",
  "Allocate points for interaction": "Zuteilung von Punkten für Interaktion",
  "Upvoting post": "Einen Beitrag liken",
  "creating a comment": "Einen Kommentar verfassen",
  "creating a reply": "Eine Antwort verfassen",
  "Getting upvote to their post": "Upvotes auf eigene Beiträge",
  "Getting comment to their post": "Kommentare zu eigenen Beiträgen",
  "Getting reply to their comment": "Antworten auf eigene Kommentare",
  "Creating a post": "Einen Beitrag erstellen",
  Visiblity: "Sichtbarkeit",
  "Move Up": "Nach oben",
  "Move Down": "Nach unten",
  "Create embedded section": "Erstellen von eingebetteten Abschnitten",
  "Preview area": "Vorschau",
  "Theme selector": "Themenauswahl",
  "Community Theme": "Community Theme",
  "Select theme": "Theme wählen",
  "Select brand color": "Wähle deine Marken-Farbe",
  "Customize your button color to match your brand":
    "Passe die Farben von Schaltflächen an die Marke an",
  "Select on button text color": "Wähle die Farbe des Schaltflächen-Textes",
  "Suggestion: Keep inverted contrast against brand color":
    "Empfehlung: Umgekehrten Kontrast zur Markenfarbe beibehalten",
  Billing: "Abrechnung",
  "Current plan": "Derzeitiger Plan",
  "You are current on Free PLan": "Dein aktueller Plan: kostenlos",
  "Renew on: NA": "Erneuern am: NA",
  Select: "Auswählen",
  "Email us at": "Schreibe uns an",
  "Compare plan": "Mitgliedschaften vergleichen",
  "API Keys": "API-Schlüssel",
  "Create new key": "Neuen API-Schlüssel erstellen",
  "Total keys": "Gesamtanzahl der API-Schlüssel",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "Bewahre die API-Schlüssel sicher auf! Sie können verwendet werden, um auf bestimmte Funktionen zuzugreifen und bestimmte Aktionen in deinem Namen auf der Plattform durchführen zu lassen.",
  "Key name": "API-Schlüssel Name",
  "Key value": "API-Schlüssel Wert",
  Action: "Aktionen",
  "Group updated!": "Gruppe aktualisiert!",
  "Are you sure you want to": "Bist du sicher, dass du das willst",
  "Make Moderator": "Zum Moderator machen",
  "Remove from group": "Aus der Gruppe entfernen",
  "Show All": "Alles anzeigen",
  "Change Role": "Rolle ändern",
  Close: "Schließen",
  copy: "Kopieren",
  LINKS: "LINKS",
  "Email Id": "E-Mail Adresse",
  Name: "Name",
  "Search for an image": "Nach einem Bild suchen",
  "Crop Image": "Bild zuschneiden",
  "No photos found": "Keine Bilder gefunden",
  Community: "Community",
  "Banner ratio - 16:9": "Bannerverhältnis - 16: 9",
  "Banner Ratio - 16:6": "Bannerverhältnis - 16: 6",
  "Share on mail": "Über E-Mail teilen",
  "Share on Telegram": "Auf Telegram teilen",
  "Share on Whatsapp": "Auf WhatsApp teilen",
  "Share on Linkedin": "Auf Linkedin teilen",
  "Share on Facebook": "Auf Facebook teilen",
  "Share on Twitter": "Auf Twitter teilen",
  "Pin Post": "Post anheften",
  "Heading (optional)": "Überschrift (optional)",
  "Select section": "Abschnitt auswählen",
  Gif: "GIF",
  "Create Custom Widget": "Benutzerdefiniertes Widget erstellen",
  "Are you sure you want to delete this widget?":
    "Bist du sicher, dass du dieses Widget löschen möchtest?",
  "Go to event": "Zur Veranstaltung",
  "Add Tags": "Tags hinzufügen",
  "Create Tag": "Tag erstellen",
  "Title (Optional)": "Titel (optional)",
  "Search Gif": "GIF suchen",
  "What's on your mind": "Was möchtest du posten",
  "Looks like nothing available here right now.":
    "Sieht aus, als wäre hier im Moment nichts verfügbar.",
  "Try exploring other tabs": "Erkunde andere Registerkarten",
  "Delete Widget": "Widget löschen",
  "Please select a section to post": "Bitte wähle einen Abschnitt zum Posten",
  "Post added successfully!": "Beitrag erfolgreich hinzugefügt!",
  "Failed to create post. Please check post content and retry again.":
    "Beitrag konnte nicht erstellt werden. Bitte überprüfe den Inhalt des Beitrags und versuche es erneut.",
  "Are you sure you want to discard current post?":
    "Bist du sicher, dass du den aktuellen Beitrag verwerfen möchten?",
  "By default it will take up the image uploaded with the post":
    "Standardmäßig wird das mit dem Beitrag hochgeladene Bild übernommen",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "Man kann das Quiz jetzt nur auf mobilen Geräten sehen und teilnehmen.",
  "Community Banner": "Community Titelbild",
  "Change Cover": "Titelbild ändern",
  "Suggestions: Welcome to my community etc":
    "Vorschläge: Willkommen in meiner Community usw.",
  "(edited)": "(bearbeitet)",
  "Aspect ratio (recommended)": "Seitenverhältnis (empfohlen)",
  "Banner details updated!": "Titelbild-Details aktualisiert!",
  "Refresh if changes are not visible!":
    "Aktualisieren, wenn Änderungen nicht sichtbar sind!",
  "Banner details could not be updated!":
    "Titelbild-Details konnten nicht aktualisiert werden!",
  "Post deleted": "Beitrag gelöscht",
  "Post not available. It may have been deleted.":
    "Beitrag ist nicht verfügbar. Vielleicht wurde er gelöscht.",
  Admin: "Admin",
  Moderator: "Moderator",
  "Bookmark added!": "Lesezeichen hinzugefügt!",
  "Bookmark removed": "Lesezeichen entfernt",
  "Failed performing action!": "Ausführung der Aktion fehlgeschlagen!",
  "Copy this link to share on other platforms":
    "Kopiere diesen Link, um ihn auf anderen Plattformen zu teilen",
  "View this event": "Sieh dir dieses Event an",
  Event: "Event",
  "Join Meeting": "Beitreten",
  "The live meeting has ended": "Das Live-Meeting ist beendet",
  "read more...": "Weiterlesen...",
  "Load more comments": "Weitere Kommentare laden",
  "Post Reply": "Antworten",
  "Post Comment": "Kommentar schreiben",
  more: "mehr",
  upvoted: "Like",
  "1 Upvote": "1 Like",
  "1 Comment": "1 Kommentar",
  "Please enter a title": "Bitte gib einen Titel ein",
  "Please enter a url link": "Bitte gib einen URL-Link ein",
  "Please enter button title": "Bitte gib den Schaltflächen Bezeichnung",
  "Button label must be at least 20 characters":
    "Die Bezeichnung der Schaltfläche muss mindestens 20 Zeichen lang sein",
  "Please enter valid url": "Bitte gib eine gültige URL ein",
  "Suggestions: Know more, Join, Apply...":
    "Vorschläge: mehr erfahren, beitreten, anfragen ...",
  URL: "URL",
  "Community Events": "Community Ereignisse",
  "Widget deleted successfully": "Widget erfolgreich gelöscht",
  "Community Top Contributors": "Community Top-Mitwirkende",
  points: "Punkte",
  "Points cannot be greater than current points":
    "Punkte können nicht höher sein als die aktuellen Punkte",
  "Point cannot be a negative value": "Punkte können nicht negativ sein",
  "Point cannot be a decimal point": "Punkte können keine Dezimalstellen sein",
  "Please enter a valid number": "Bitte gib eine gültige Nummer ein",
  "Karma points updated successfully": "Karmapunkte erfolgreich aktualisiert",
  "Error in updating karma points":
    "Fehler bei der Aktualisierung von Karmapunkten",
  "Add karma points": "Füge Karmapunkte hinzu",
  "Minus karma points": "Karma Punkte verringern",
  "Total Karma points:": "Gesamtkarma-Punkte:",
  "Enter no. of karma points": "Gib die Anzahl an Karmapunkten ein",
  "Minus Points": "Minus Punkte",
  "Trending Post": "Populärer Post",
  "No visible description": "Keine sichtbare Beschreibung",
  "Picture ratio: 1:1": "Bildverhältnis: 1:1",
  "home feed": "Home Feed",
  "Event name is invalid!": "Der Event Name ist ungültig!",
  custom: "benutzerdefiniert",
  "Custom widget updated successfully":
    "Benutzerdefiniertes Widget erfolgreich aktualisiert",
  "Name invalid!": "Name ungültig!",
  "Current timezone:": "Aktuelle Zeitzone:",
  "can be updated from community settings":
    "Kann aus den Community Einstellungen übernommen werden",
  "Event created successfully": "Event erfolgreich erstellt",
  "Error in creating event": "Fehler beim Erstellen des Events",
  Attendees: "Teilnehmer",
  "Do you wish to notify these changes to attendees?":
    "Möchtest du die Teilnehmer über diese Änderungen informieren?",
  "users wants to attend this event":
    "Benutzer möchten an dieser Veranstaltung teilnehmen",
  "Yes, Notify them": "Ja, benachrichtigen",
  "No, its okay": "Nein, danke",
  "Do you wish to notify that event is cancelled?":
    "Möchtest du mitteilen, dass das Event abgesagt wurde?",
  "Are you sure you want to delete the event?":
    "Sicher, dass das Event gelöscht werden soll?",
  "Delete Event": "Event löschen",
  "New Event": "Neues Event",
  "Accept Invitation": "Einladung annehmen",
  Requested: "Angefordert",
  Declined: "Abgelehnt",
  "Request Access": "Zugang anfordern",
  "Enroll for": "Anmelden für",
  Housefull: "Ausgebucht",
  "Just copy paste this code wherever you want it to be embedded:":
    "Kopiere einfach diesen Code und füge ihn dort ein, wo er eingebettet werden soll:",
  Ended: "Beendet",
  "Error getting events": "Fehler beim Laden der Events",
  "Events could not be fetched!": "Events konnten nicht abgerufen werden!",
  "Show Attendees": "Teilnehmer anzeigen",
  Subscribers: "Abonnenten",
  "Make this a closed section": "Zu einem geschlossenen Abschnitt ändern",
  "Update Section": "Abschnitt Aktualisieren",
  "Valid section name required!": "Gültiger Abschnittsname erforderlich!",
  "Enter section name": "Abschnittsname eingeben",
  "Community Admin": "Community Admin",
  Embed: "Einbetten",
  "Invite to": "Einladen zu",
  "Please enter a valid email address":
    "Bitte gib eine gültige E-Mail-Adresse ein",
  "Invitation sent successfully": "Einladung erfolgreich gesendet",
  "Error sending invitation": "Fehler beim Senden der Einladung",
  "Email is not valid!": "E-Mail ist ungültig!",
  "Data could not be loaded!": "Daten konnten nicht geladen werden!",
  "Posted in": "Veröffentlicht in",
  "Invite Members Individually": "Einzelne Mitglieder einladen",
  "Start a discussion": "Eine Diskussion beginnen",
  "Post something": "Etwas posten",
  "Invite your audience": "Publikum einladen",
  "Choose Plan": "Plan wählen",
  or: "oder",
  "Create a link": "Link erstellen",
  "Add link": "Link hinzufügen",
  "Update link": "Link aktualisieren",
  "Add shortcut to your most used tools":
    "Verknüpfung zu den am häufigsten verwendeten Tools hinzufügen",
  "Your website, Instagram Channel, Facebook profile etc":
    "Deine Website, Instagram, Facebook-Profil usw.",
  "Valid link name required!": "Gültiger Linkname erforderlich!",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Gültige Url erforderlich, stelle sicher, dass http:// oder https:// nicht fehlen",
  "Invite to__1": "Einladen zu",
  "Link Name": "Name des Links",
  "Link URL": "URL verknüpfen",
  "Could not subscribe to": "Konnte sich nicht anmelden für",
  "Subscribed to": "Angemeldet für",
  "By default it will take up the community banner uploaded with the community":
    "Standardmäßig wird das Community-Titelbild verwendet",
  "Created by": "Erstellt von",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Online-Event (Meeting-Link wird sichtbar, sobald du für das Event angemeldet bist)",
  "Join Call": "Meeting beitreten",
  "Please login to book this event":
    "Bitte melde dich an um dich für dieses Event anzumelden",
  "Commented on": "Kommentiert am",
  "Replied to": "Geantwortet an",
  "Looks like it's lonely here.":
    "Sieht so aus, als ob hier noch nichts gepostet wurde.",
  "Try exploring other sections and groups.":
    "Versuche, andere Bereiche und Gruppen zu erkunden.",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "Bitte führe die Zahlung durch oder kontaktiere den Admin, um Zugang zu dieser Gruppe zu erhalten.",
  "This is a closed group You'll need access to this group.":
    "Dies ist eine geschlossene Gruppe du benötigst Zugang zu dieser Gruppe.",
  "You have no bookmarks right now.": "Du hast aktuell keine Lesezeichen.",
  "The user hasn't posted anything yet publicly.":
    "Der Benutzer hat noch nichts öffentlich gepostet.",
  "No activity to show.": "Keine Aktivität zu verzeichnen.",
  "Are you sure you want to unsubscribe the community?":
    "Bist du sicher, dass du dich von dieser Community abmelden möchtest?",
  "deals left": "Angebote übrig",
  "Something went wrong while loading posts":
    "Beim Laden von Beiträgen ist ein Fehler aufgetreten",
  "Could not unsubscribe from":
    "Die Abmeldung konnte nicht durchgeführt werden",
  "Unsubscribed from": "Abgemeldet von",
  "Login to view this page": "Melde dich an, um diese Seite anzuzeigen",
  Login: "Anmelden",
  "There was some error while doing the action!":
    "Es ist ein Fehler bei der Durchführung der Aktion aufgetreten!",
  "Unable to delete section!": "Abschnitt kann nicht gelöscht werden!",
};
