import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

const paymentPreferenceEndpoint = {
  getPaymentPreference: (communityId: string) =>
    `${base}community/${communityId}/payment-preference`,
  updatePaymentPreference: (communityId: string) =>
    `${base}community/${communityId}/payment-preference`,
  getInvoicePreference: (communityId: string) =>
    `${base}community/${communityId}/invoice-preference`,
  updateInvoicePreference: (communityId: string) =>
    `${base}community/${communityId}/invoice-preference`,
};

export default paymentPreferenceEndpoint;
