import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
interface Props {
  id?: string;
  src?: string;
  alt?: string;
  className?: string;
  height?: number;
  width?: number;
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
}

/**
 * Component to display a lazy loaded image
 * @param {string} src - The url of the image
 * @param {string} alt - The alt text of the image
 * @param {string} className - The class name of the image
 * @param {function} onClick - The function to call when the image is clicked
 */
export const LazyImage: React.FC<Props> = ({
  id,
  src,
  alt,
  height,
  width,
  className,
  onClick = () => {},
  style,
}) => {
  // const [imageSrc, setImageSrc] = useState<string | null | undefined>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const blur = 10;

  const imageStyle: React.CSSProperties = {
    filter: `blur(${isLoaded ? 0 : blur}px)`,
    transition: "opacity 300ms ease-out 0.3s",
    opacity: isLoaded ? 1 : 0,
    ...style,
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (imgRef.current) {
            imgRef.current.src = src ?? "";
          }
          observer.unobserve(entry.target);
        }
      });
    });

    if (imgRef.current) {
      // imgRef.current.src = src ?? "";
      observer.observe(imgRef.current);
    }

    const currentImgRef = imgRef.current;
    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, [src]);

  return (
    <img
      id={id}
      ref={imgRef}
      height={height}
      width={width}
      style={imageStyle}
      // src={imageSrc ? imageSrc : ""}
      alt={alt ?? "picture"}
      onLoadStart={(e) => {
        e.currentTarget.style.filter = "blur(10px)";
      }}
      onLoad={(e) => {
        e.currentTarget.style.filter = "none";
        setIsLoaded(true);
      }}
      onError={(e) => {
        e.currentTarget.style.filter = "none";
      }}
      onAbort={(e) => {
        e.currentTarget.style.filter = "none";
      }}
      className={cx("LazyImage", className)}
      decoding="async"
      loading="lazy"
      onClick={onClick}
    />
  );
};

export default LazyImage;
