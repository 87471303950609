import { GroupService } from "../../_service";

export const SET_GROUPS = "SET_GROUPS";
export const SET_ACTIVE_GROUP = "SET_ACTIVE_GROUP";
export const REMOVE_ACTIVE_GROUP = "REMOVE_ACTIVE_GROUP";
export const SET_IS_LOADING_GROUPS = "SET_IS_LOADING_GROUPS";

/**
 * Set the groups list
 * @param {*} user
 * @returns
 */
export function setGroups(groups = []) {
  return {
    type: SET_GROUPS,
    groups,
  };
}

/**
 * Set which group is active
 * @param {*} group
 * @returns
 */
export function setActiveGroup(group = null) {
  return (dispatch, getState) => {
    // update active group in groups lists too
    if (group) {
      const { groups } = getState();
      const newGroups = [...groups];
      const groupIndex = newGroups.findIndex((g) => g.id === group.id);
      newGroups.splice(groupIndex, 1, group);
      dispatch(setGroups(newGroups));
    }
    // set active group
    dispatch(_setActiveGroup(group));
  };
}

/**
 * Update portion of community groups by ids, used for nft gating
 * @param {*} groupId
 * @param {*} newState
 * @param {*} matchFinder add some logic here, which return true or false
 */
export function updateGroupsByIds(
  groupIds,
  newState = {},
  matchFinder = (prevValue) => {
    return true;
  }
) {
  return (dispatch, getState) => {
    // update active group in groups lists too
    if (groupIds?.length) {
      // update all previous groups
      const { groups, activeGroup } = getState();
      const newGroups = [...groups].map((group) => {
        return matchFinder(group) ? { ...group, ...newState } : group;
      });
      dispatch(setGroups(newGroups));
      // update active group, if needed
      if (groupIds.includes(activeGroup?.id) && matchFinder(activeGroup)) {
        dispatch(_setActiveGroup({ ...activeGroup, ...newState }));
      }
    }
  };
}

/**
 * Set which group is active
 * @param {*} group
 * @returns
 */
export function removeActiveGroup(group = null) {
  return (dispatch, getState) => {
    // update active group in groups lists too
    let newGroups;
    if (group) {
      const { groups } = getState();
      newGroups = [...groups];
      const groupIndex = newGroups.findIndex((g) => g.id === group.id);
      newGroups.splice(groupIndex, 1);
      dispatch(setGroups(newGroups));
    }
  };
}

function _setActiveGroup(group = null) {
  return {
    type: SET_ACTIVE_GROUP,
    group,
  };
}

// thunk related

/**
 * Get all groups
 */
export function getGroups() {
  return (dispatch, getState) => {
    // get the user
    const { auth: user } = getState();
    // get all the groups
    GroupService.getAllGroups(user)
      .then(({ groups }) => {
        dispatch(setGroups(groups));
      })
      .catch((err) => {
        // TODO: show error
        console.log("getGroups Redux failed", { err });
      });
  };
}
/**
 * Set the groups loading
 * @param {*} user
 * @returns
 */
export function setIsGroupsLoading(loading = true) {
  return {
    type: SET_IS_LOADING_GROUPS,
    loading,
  };
}

export function updateGroupStateFromSocketPayload(socketPayload) {
  return async (dispatch, getState) => {
    // get the user
    const { auth, groups, activeGroup } = getState();
    const { state, groupId, group: groupSlim } = socketPayload;
    switch (state) {
      case "CREATED":
        // check if we already have the group
        if (groups.find((g) => g.id === groupId)) {
          // already have group
          return;
        }
        // get details
        const { groups: group } = await GroupService.groupDetail(auth, groupId);

        dispatch(setGroups([...groups, group]));
        return;
      case "UPDATED":
        dispatch(
          setGroups(
            groups.map((g) => {
              if (g.id === groupId) {
                g.name = groupSlim.name;
                g.description = groupSlim.description;
                g.color = groupSlim.color;
                g.webUrl = groupSlim.webUrl;
                g.defaultTabName = groupSlim.defaultTabName;
                g.defaultTabEmoji = groupSlim.defaultTabEmoji;
                g.tags = groupSlim.tags;
                g.communityId = groupSlim.communityId;
                g.groupType = groupSlim.groupType;
                g.price = groupSlim.price;
                g.isOnlyVisibleToMembers = groupSlim.isOnlyVisibleToMembers;
                g.currency = groupSlim.currency;
                g.postLevel = groupSlim.postLevel;
                g.meta = groupSlim.meta;
              }
              if (activeGroup?.id === groupId) {
                dispatch(setActiveGroup(g));
              }
              return g;
            })
          )
        );
        return;
      case "DELETED":
        // remove from list
        dispatch(setGroups(groups.filter((g) => g.id !== groupId)));
        if (activeGroup?.id === groupId) {
          dispatch(setActiveGroup(null));
        }
        return;
      default:
        return;
    }
  };
}
