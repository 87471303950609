import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class MessageService {
  /**
   * Send group section messages
   * @param {*} user
   * @param {*} message
   * @returns
   */
  static async sendMessage(user, message) {
    return axios
      .post(endpoints.message.create, message, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
  /**
   *
   * @param {User} user
   * @param {Message} message
   * @returns
   */
  static async editMessage(user, message) {
    return axios
      .put(endpoints.message.edit(message.id), message, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
  static async deleteMessage(user, message) {
    return axios
      .delete(endpoints.message.delete(message.id), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async reactToMessage(message, emoji) {
    return axios
      .post(
        endpoints.message.react(message.id),
        {
          emoji,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  static async unreactToMessage(message, emoji) {
    return axios
      .post(
        endpoints.message.unreact(message.id),
        {
          emoji,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  static async uploadAttachments(user, message, files) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("attachments", file);
    });
    // set groupId and tabId from message in formData
    formData.append("groupId", message.groupId);
    formData.append("tabId", message.tabId);
    if (message.parentId) {
      formData.append("parentId", message.parentId);
    }
    return axios
      .post(endpoints.message.uploadAttachment, formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async sendDirectMessage(user, message, otherUserId) {
    return axios
      .post(
        endpoints.directMessages.sendDirectMessage(otherUserId),
        { message },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async uploadDirectAttachments(user, files, otherUserId) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("attachments", file);
    });
    return axios
      .post(endpoints.directMessages.uploadAttachments(otherUserId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {User} user
   * @param {DirectMessage} message
   * @returns
   */
  static async editDirectMessage(user, message) {
    return axios
      .post(
        endpoints.directMessages.editDirectMessage(message.id),
        { message: message.description },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async deleteDirectMessage(user, message) {
    return axios
      .delete(endpoints.directMessages.deleteDirectMessage(message.id), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Mark all message in a direct chat as read
   * @param {*} user
   * @param {*} otherUserId
   * @returns
   */
  static async markAllMessagesInChatAsRead(user, otherUserId) {
    return axios
      .post(
        endpoints.directMessages.markAllMessageAsRead(otherUserId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async getDirectChats(user) {
    return axios
      .get(endpoints.directMessages.chats, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async getUserChatTimestampPaginated(user, otherUserId, lastId = null) {
    return axios
      .get(endpoints.directMessages.userChat(otherUserId, lastId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
}

export { MessageService };
