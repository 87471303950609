import cx from "classnames";
import { MediaService } from "../../../../_service/media.service";
import { ToastTypes } from "../../../../contexts/toastr.context";
import Tooltip from "../../../atoms/tooltip";
import { Button } from "../../../button.component";
import { FileInput } from "../../../form-controls/file-input";
import TenorGifPicker from "../../../tenor-gif-picker";
import UIcon from "../../../uicon-component";
import MEDIA_TYPES from "../../../../_constants/mediaType";

/**
 * Component to display toolbar at create post card bottom
 * @param {Array} images - array of images
 * @param {Function} setImages - function to set images
 * @param {Array} videos - array of videos
 * @param {Function} setVideos - function to set videos
 * @param {File} document - file to upload
 * @param {Function} setDocument - function to set document
 * @param {boolean} isEmbedEditorVisible - boolean to show embed code editor
 * @param {Function} setIsEmbedEditorVisible - function to set is embed editor visible
 * @param {boolean} isEmojiPickerVisible - boolean to show emoji picker
 * @param {Function} setIsEmojiPickerVisible - function to set is emoji picker visible
 * @param {boolean} isPollVisible - boolean to show poll
 * @param {Function} setIsPollVisibleVisible - function to set is poll visible
 * @param {boolean} isRecorderVisible - boolean to show recorder
 * @param {Function} setIsRecorderVisible - function to set is recorder visible
 * @param {boolean} isGifPickerVisible - boolean to show gif picker
 * @param {Function} setIsGifPickerVisible - function to set is gif picker visible
 * @param {string} description - description of post
 * @param {Function} setDescription - function to set description
 * @param {boolean} disableSubmit - boolean to disable submit button
 * @param {boolean} isSavingPost - boolean to show saving post
 * @param {Function} onSubmitPost - function to submit post
 * @param {String} submitButtonLabel - label of submit button. Default is "Post"
 */
function CreatePostToolbar(props) {
  const {
    community,
    user,
    images = [],
    setImages,
    videos = [],
    setVideos,
    documents = [],
    setDocument,
    description,
    setDescription,
    isEmbedEditorVisible,
    setIsEmbedEditorVisible,
    isPollVisible,
    setIsPollVisibleVisible,
    setAreFilesUploading,
    isRecorderVisible,
    setIsRecorderVisible,
    isGifPickerVisible,
    setIsGifPickerVisible,
    isButtonsSectionVisible,
    setIsButtonsSectionVisible,
    uploadedMedia,
    setUploadedMedia,
    setRemovedMediaUploadsOnEdit,
    addToast,
    disableSubmit = true,
    isSavingPost = false,
    onSubmitPost = () => {},
    submitButtonLabel = "Post",
  } = props;


  const uploadImages = async (imagesToUpload) => {
    setAreFilesUploading(true);

    try {
      const res = await MediaService.uploadMedia(
        user,
        community.id,
        imagesToUpload,
        MEDIA_TYPES.IMAGE,
      );

      setUploadedMedia((prevUploadedMedia) => ({
          ...prevUploadedMedia,
          images: [...prevUploadedMedia.images, ...res.publicUrls],
      }));
    } catch (error) {
      console.log("Error: ", error);
      setImages([]);
      addToast("Something went wrong!", "", ToastTypes.danger);
    }
    setAreFilesUploading(false);
  };

  const uploadVideo = async (videosToUpload) => {
    setAreFilesUploading(true);
    try {
      const res = await MediaService.uploadMedia(
        user,
        community.id,
        [videosToUpload],
        MEDIA_TYPES.VIDEO,
      );

      setUploadedMedia((prevUploadedMedia) => ({
        ...prevUploadedMedia,
        videos: [...prevUploadedMedia.videos, ...res.publicUrls],
      })); 
    } catch (error) {
      console.log("Error: ", error);
      setVideos([]);
      addToast("Something went wrong!", "", ToastTypes.danger);
    }

    setAreFilesUploading(false);
  }

  const uploadDocument = async (documentToUpload) =>  {
    setAreFilesUploading(true);

    const res = await MediaService.uploadMedia(
      user,
      community.id,
      [documentToUpload],
      MEDIA_TYPES.DOCUMENT,
    );
    
    uploadedMedia.document[0] &&
      setRemovedMediaUploadsOnEdit((prevMediaItems) => [
        ...prevMediaItems,
        uploadedMedia.document[0],
      ]);

    setUploadedMedia(prevUploadedMedia => ({
      ...prevUploadedMedia,
      document: [...prevUploadedMedia.document, ...res.publicUrls]
    }));

    setAreFilesUploading(false);
  }

  return (
    <div className="Toolbar flex justify-between border-t theme-border-default p-1 sticky bottom-0 theme-bg-surface">
      <div className="ToolbarIcon grid grid-cols-9 gap-3 justify-start place-items-center">
        {/* PICTURE */}
        <Tooltip label="Picture">
          <FileInput
            accept="image/*"
            maxFileSize={10}
            maxFiles={5}
            multiple
            disabled={images.length === 5}
            onChange={(imageList) => {
              if (imageList) {
                  var tempImages = [...uploadedMedia.images, ...imageList].slice(0, 5);
                  setImages(tempImages);
                  images.length > 0 ? uploadImages([...imageList]): uploadImages(tempImages);
              }
            }}>
            <div
              className={cx("h-6 hover:scale-110 cursor-pointer", {
                "theme-text-subtitle-2 cursor-not-allowed": images.length > 4,
              })}>
              <UIcon size="xl" icon="picture" />
            </div>
          </FileInput>
        </Tooltip>

        {/* VIDEO */}
        <Tooltip label="Video">
          <FileInput
            accept="video/*"
            disabled={false}
            onChange={
              (video) => {
                setVideos([video])
                uploadVideo(video)
              }
              }>
            <div
              className={cx("h-6 hover:scale-110 cursor-pointer", {
                "theme-text-subtitle-2 cursor-not-allowed": videos.length > 0,
              })}>
              <UIcon size="xl" icon="play-alt" />
            </div>
          </FileInput>
        </Tooltip>

        {/* ATTACHMENT */}
        <Tooltip label="Attach file">
          <FileInput
            accept="file/*"
            disabled={documents.length > 0}
            onChange={(doc) => {
            setDocument([doc])
            uploadDocument(doc)
            }}>
            <div
              className={cx("h-6 hover:scale-110 cursor-pointer", {
                "theme-text-subtitle-2 cursor-not-allowed":
                  documents.length > 0,
              })}>
              <UIcon size="xl" icon="clip" />
            </div>
          </FileInput>
        </Tooltip>

        {/* POLL */}
        <Tooltip label="Poll" className={!setIsPollVisibleVisible && "hidden"}>
          <button
            className="h-6 hover:scale-110 12"
            onClick={() => {
              setIsPollVisibleVisible(!isPollVisible);
            }}>
            <UIcon size="xl" icon="stats" />
          </button>
        </Tooltip>

        {/* CODE */}
        <Tooltip
          label="Embed Code"
          className={!setIsEmbedEditorVisible && "hidden"}>
          <button
            className="h-6 hover:scale-110"
            onClick={() => {
              setIsEmbedEditorVisible(!isEmbedEditorVisible);
            }}>
            <div className="flex place-content-center">
              <UIcon size="sm" icon="angle-left" />
              <UIcon size="sm" icon="angle-right" />
            </div>
          </button>
        </Tooltip>

        {/* EMOJI */}
        {/* <CustomDropDown
          button={
            <Tooltip label="Emoji" className="">
              <button
                type="button"
                aria-haspopup="true"
                aria-expanded="true"
                className="h-6 hover:scale-110 12 ">
                <UIcon size="xl" icon="grin" />
              </button>
            </Tooltip>
          }>
          <EmojiPicker />
        </CustomDropDown> */}

        <Tooltip label="Record Audio">
          <button
            className="h-6 hover:scale-110 12"
            onClick={() => {
              documents?.length === 0 && setIsRecorderVisible(!isRecorderVisible);
            }}>
            <div
              className={cx("h-6 hover:scale-110 cursor-pointer", {
                "theme-text-subtitle-2 cursor-not-allowed": documents?.length > 0,
              })}>
              <UIcon size="xl" icon="microphone" />
            </div>
          </button>
        </Tooltip>
        {/* GIF */}
        <Tooltip label="Gif" className={!setIsGifPickerVisible && "hidden"}>
          <button
            className="p-.05 hover:scale-110 w-10"
            onClick={() => {
              images.length < 5 
              ? setIsGifPickerVisible(!isGifPickerVisible) 
              : addToast("You can add 5 images or GIFs in total!", "", ToastTypes.danger);
            }}>
            <span className="border theme-border-heading-1 rounded px-1">
              Gif
            </span>
          </button>
        </Tooltip>
        <TenorGifPicker
          active={isGifPickerVisible}
          setActive={setIsGifPickerVisible}
          initialSearchQuery="HAHA"
          onGifSelect={async (gif) => {
           if (gif?.media?.[0].gif?.url) {
              setUploadedMedia((prevUploadedMedia) => ({
                ...prevUploadedMedia,
                images: [...prevUploadedMedia.images, gif?.media?.[0].gif?.url],
            }));
              setImages([...images, gif?.media?.[0].gif?.url]);
            }
            setIsGifPickerVisible(false);
          }}
        />

        {/* buttons */}
        <Tooltip
          label="Custom buttons"
          className={!setIsButtonsSectionVisible && "hidden"}>
          <button
            className="p-.05 hover:scale-110 w-10"
            onClick={() => {
              setIsButtonsSectionVisible(!isButtonsSectionVisible);
            }}>
            <span className="border theme-border-heading-1 rounded px-1">
              Btn
            </span>
          </button>
        </Tooltip>
      </div>
      <Button
        label={submitButtonLabel}
        disabled={disableSubmit}
        onClick={onSubmitPost}
        isLoading={isSavingPost}
        large
      />
    </div>
  );
}
export default CreatePostToolbar;
