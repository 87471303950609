import cx from "classnames";

export default function RImage({ className = "", src = "", style = {} }) {
  return (
    <div
      className={cx("RImage", className)}
      style={{
        ...style,
        backgroundImage: `url('${src}')`,
        backgroundSize: "cover",
        content: "",
        backgroundPosition: "center center",
      }}></div>
  );
}
