import { FormError } from "../../../form-error.component";
import { TextInput } from "../../../text-input.component";

/**
 * Post title input component
 * @param {string} title - post title string
 * @param {Function} setTitle - function to set title
 * @param {object} errors - error object containing errors
 * @param {Function} setErrors - function to set error messages
 */
function PostTitle({ title, setTitle, errors, setErrors }) {
  return (
    <div className="pt-4 pb-1 mb-4">
      <TextInput
        noMargin
        defaultValue={title}
        onChange={(e) => {
          setTitle(e);
          if (e.length > 180) {
            setErrors({
              title: "Title must be less than 180 characters.",
            });
          } else {
            setErrors({
              title: undefined,
            });
          }
        }}
        placeholder="Heading (optional)"
        className="placeholder:theme-text-subtitle-1 placeholder:text-xl placeholder:font-bold px-0"
        inputHolderClassName="border-none text-xl"
      />
      <FormError error={errors?.title} padding0 />
    </div>
  );
}

export { PostTitle };
