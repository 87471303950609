import { useEffect, useState } from "react";
import { connect } from "react-redux";
import I18 from "../../_components/atoms/i18";
import { NL2BR } from "../../_components/nl2br.component";
import { RectangleCardShimmer } from "../../_components/shimmers/rectangle-card-shimmer";
import Label from "../../_components/typography/label.typography";
import UIcon from "../../_components/uicon-component";
import { NFTService } from "../../_service";

export const NTFS_PAGE_ROUTE = "/nfts";

function NTFsPageComponent({ community, user }) {
  const userId = user?.id;
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      NFTService.getMyNFTs()
        .then(({ nfts }) => {
          setNfts(nfts);
          setIsLoading(false);
          setMessage("");
        })
        .catch((err) => {
          setIsLoading(false);
          setMessage(err?.response?.data?.message ?? "Cannot fetch NFT list");
        });
    }
  }, [userId]);

  return (
    <div className="NFTPage flex flex-col space-y-2 px-2 sm:px-0">
      {/* HEADER */}
      <div className="flex flex-col theme-bg-surface pl-4 pr-2 py-4 shadow rounded">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4 font-bold theme-text-heading-2">
            <I18>NFTs</I18>
          </div>
        </div>
      </div>
      {/* BODY */}
      {message ? (
        <MessageBox message={message} />
      ) : (
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4 my-4">
          {isLoading ? (
            <Shimmer />
          ) : nfts.length ? (
            nfts.map((nft, index) => <NftCard nft={nft} key={index} />)
          ) : (
            <MessageBox message="You dont have any NFTs" />
          )}
        </div>
      )}
    </div>
  );
}

function MessageBox({ message }) {
  return (
    <div className="flex gap-2 p-2 border theme-border-default rounded mb-2 sm:mb-0">
      <UIcon icon="info" size="sm" />
      <Label size="body" variant="s1" translate>
        {message}
      </Label>
    </div>
  );
}

function Shimmer() {
  return (
    <>
      <RectangleCardShimmer height="300px" />
      <RectangleCardShimmer height="300px" />
      <RectangleCardShimmer height="300px" />
    </>
  );
}

export function NftCard({ nft }) {
  const [previewActive, setPreviewActive] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const handlePreviewClick = (imageUrl) => {
    setPreviewUrl(imageUrl);
    setPreviewActive(true);
  };

  return (
    <div className="NftCard flex justify-center rounded items-center cursor-pointer flex-col">
      <div className="theme-bg-surface rounded w-full flex-grow flex flex-col relative">
        {/* background image */}
        {nft.image ? (
          <div
            onClick={() => handlePreviewClick(nft?.image)}
            className="NftCardImage w-full h-64 rounded-t">
            <img
              src={nft.image}
              alt="NFT"
              className="w-full h-full top-0 left-0 object-cover rounded-t"
            />
          </div>
        ) : (
          <div className="NftCardImage color theme-bg-primary  object-cover rounded-t"></div>
        )}
        {/* group name and description */}
        <div className=" mb-4">
          <div className="font-semibold w-full px-3 pb-1 pt-3">{nft?.name}</div>
          <div className="font-semibold w-full text-xs theme-text-subtitle-1 mt-1 px-3 flex-grow overflow-hidden pb-10 h-7">
            {nft.description?.length > 60 ? (
              <NL2BR text={nft.description.substring(0, 57) + "..."} />
            ) : (
              nft.description
            )}
          </div>
        </div>
      </div>

      {previewActive && (
        <div
          className="PopupImageViewer"
          onClick={() => setPreviewActive(false)}>
          <img
            src={previewUrl}
            alt="Preview"
            className="max-h-full max-w-full"
          />
        </div>
      )}
    </div>
  );
}

const NTFsPage = connect((s) => ({ user: s.auth, community: s.community }))(
  NTFsPageComponent
);

// const CoursePage = CoursePageComponent;
export default NTFsPage;
