import React, { useRef, useState } from "react";
import "react-image-crop/src/ReactCrop.scss";
import I18 from "../atoms/i18";
import { Button } from "../button.component";
import IconButton2 from "../icon-button-2-component";
import { Modal } from "../modal.component";
import ImageCrop from "./v2";

/**
 * @param {function} src The image source (can be base64 or a blob just like a normal image).
 * @param {function} setPicture function to set the picture. Returns a blob.
 * @param {double} aspectRatio the aspect ratio of the image. Default is 16:9.
 * @param {function} setPictureSelected function to signal that a picture has been selected. Returns a boolean value.
 * @param {string} cropRatioMessage the recommended ratio to be displayed on modal footer.
 * @param {boolean} activeCrop if true, the crop modal will be displayed.
 * @param {function} setActiveCrop function to set the crop modal active. Returns a boolean value.
 * @param {boolean} displayUnsplashSearch if true, the unsplash search will be hidden.
 */

interface ImageCropModalProps {
  src?: any;
  setPicture?: (blob: Blob) => void;
  aspectRatio?: number;
  setPictureSelected?: (selected: boolean) => void;
  cropRatioMessage?: string;
  activeCrop?: boolean;
  setActiveCrop?: (active: boolean) => void;
  displayUnsplashSearch?: boolean;
}
function ImageCropModal({
  src,
  setPicture = () => {},
  aspectRatio = 16 / 9,
  setPictureSelected = () => {},
  cropRatioMessage,
  activeCrop = false,
  setActiveCrop = () => {},
}: ImageCropModalProps) {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = React.useState<any>({
    aspect: aspectRatio,
    unit: "%",
    width: 100,
    height: 100, // Add the height property to the initial crop state
  });
  const [zoom, setZoom] = React.useState(1);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [completedCrop, setCompletedCrop] = useState();

  const getCroppedImg = () => {
    if (!image) {
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width! * pixelRatio;
    canvas.height = crop.height! * pixelRatio;
    ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx!.imageSmoothingQuality = "high";

    ctx!.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    );

    // As Base64 string
    const base64Image = canvas.toDataURL("image/jpeg");
    const Blob = dataURItoBlob(base64Image);
    setPicture(Blob);
    setPictureSelected(true);
    setActiveCrop(false);
    setImage(null);
  };

  function dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    let byteString = atob(dataURI.split(",")[1]);
    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    let bb = new Blob([ab]);
    return bb;
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      console.error("Crop canvas does not exist");
      return;
    }

    previewCanvasRef.current?.toBlob((blob: any) => {
      if (!blob) {
        console.error("Failed to create blob");
        return;
      }

      setPicture(blob);
      setPictureSelected(true);
      setActiveCrop(false);
      setImage(null);
    });
  }

  return (
    <>
      <Modal
        className="relative"
        active={activeCrop}
        width="640px"
        padding={false}>
        <div className="ImageCropModal theme-bg-surface">
          <div className="pl-4 pr-2 py-2 flex items-center justify-between">
            <span className="font-bold theme-text-heading-2">
              <I18>Crop Image</I18>
            </span>
            <IconButton2
              hoverable
              icon="cross"
              size="md"
              iconClass="theme-text-subtitle-2"
              onClick={() => {
                setActiveCrop(false);
                setImage(null);
              }}
            />
          </div>
          <div>
            <div className="flex justify-center py-5 px-5 theme-bg-default border-y theme-border-default max-h-[calc(80vh)] sm:max-h-max overflow-y-auto">
              {/* <ReactCrop
                src={src}
                onImageLoaded={(image) => {
                  setImage(image);
                }}
                keepSelection={true}
                zoom={zoom}
                crop={crop}
                onComplete={setCompletedCrop}
                onChange={setCrop}
              />
              */}
              <ImageCrop
                imgSrc={src}
                previewCanvasRef={previewCanvasRef}
                aspectRatio={aspectRatio}
              />
            </div>
            <div className="flex items-center justify-between px-5">
              {cropRatioMessage ? (
                <p className="font-semibold theme-heading-2">
                  <I18>{cropRatioMessage}</I18>
                </p>
              ) : (
                <div></div>
              )}

              <Button
                onClick={(e) => {
                  // getCroppedImg();
                  onDownloadCropClick();
                }}
                className="p-4 my-3 "
                label="Crop Image"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ImageCropModal;
