import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  loginWithOTP: base + "login/otp",
  loginWithEmailOTP: base + "auth/email/otp",
  verifyOTP: base + "verify-otp",
  googleLoginV2: base + "auth/google/v2",
  facebookLogin: base + "auth/facebook",
  web3Login: base + "auth/web3",
};
