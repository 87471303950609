import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CustomThemeData } from "../../App";
import { ErrorDiv } from "../../_components";
import MainLoader from "../../_components/main-loader.component";
import { redirectToAuthUrl } from "../../_config/helper.config";
import { CommunityService } from "../../_service";
import { createSession } from "../../_store/_actions/auth.actions";
import "../../_styles/color/light-color.css";
import AuthChooser from "./auth-chooser.login-page";
import EmailAuthComponent from "./email-auth.login-page";
const MobileAuthComponent = React.lazy(() => import("./mobile-auth.login-page"));

export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "/register";
export const LOGIN_EMAIL_ROUTE = "/login-email";
export const REGISTER_EMAIL_ROUTE = "/register-email";

function LoginPageComponent({ community, setUser, isRegister = false }) {
  const [communityDetail, setCommunityDetail] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [authType, setAuthType] = useState(
    [LOGIN_EMAIL_ROUTE, REGISTER_EMAIL_ROUTE].includes(window.location.pathname)
      ? "email"
      : null
  ); // email or mobile or null

  // check if we have a redirect url
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURL = urlParams.get("redirect");
  const errorFromParams = urlParams.get("error");
  let redirectSubdomain = null;
  const currentURL = window.location.origin;
  try {
    // redirectSubdomain = redirectURL.replace(/http(s)?(:\/\/)/g, "").split(".")[0];
    redirectSubdomain = getCommunityDomainToLoad(
      redirectURL ? redirectURL : currentURL
    );
  } catch (error) {}

  // load error from url
  useEffect(() => {
    if (errorFromParams) {
      setErrorMessage(errorFromParams);
    }
  }, [errorFromParams]);

  // get communityDetail, for community logo
  useEffect(() => {
    if (redirectSubdomain) {
      CommunityService.getCommunity(redirectSubdomain)
        .then(({ community }) => {
          setCommunityDetail(community);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [redirectSubdomain]);

  // if community has third party auth details
  if (community && community.authRedirectUrl) {
    // redirect the user there for login
    redirectToAuthUrl(community);
    return (
      <div className="h-screen bg-default flex justify-center items-center flex-col">
        <CustomThemeData community={community} />
        <MainLoader />
        <span className="text-gray-500">
          Please wait while we are redirecting you...
        </span>
      </div>
    );
  }

  const setUserState = (user) => {
    // set user in state
    setUser(user);
  };

  return (
    <div
      style={
        {
          // backgroundImage: "url('/assets/signup-bg.png')"
        }
      }
      className="LoginPage">
      <div className="h-screen flex justify-center items-center">
        <div className="w-96 relative">
          <ErrorDiv error={errorMessage} />
          {!authType ? (
            <AuthChooser
              community={community}
              communityDetail={communityDetail}
              redirectSubdomain={redirectSubdomain}
              redirectURL={redirectURL}
              isRegister={isRegister}
              setUser={setUserState}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
              setAuthType={setAuthType}
            />
          ) : authType === "email" ? (
            <EmailAuthComponent
              community={community}
              communityDetail={communityDetail}
              redirectSubdomain={redirectSubdomain}
              redirectURL={redirectURL}
              isRegister={isRegister}
              setUser={setUserState}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setErrorMessage={setErrorMessage}
              setAuthType={setAuthType}
            />
          ) : (
            <Suspense fallback={<></>}>
                <MobileAuthComponent
                community={community}
                communityDetail={communityDetail}
                redirectSubdomain={redirectSubdomain}
                redirectURL={redirectURL}
                isRegister={isRegister}
                setUser={setUserState}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setErrorMessage={setErrorMessage}
                setAuthType={setAuthType}
              />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  community: state.community,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser: createSession,
    },
    dispatch
  );

const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPageComponent);

export default LoginPage;

/**
 * get community to load using
 * community subdomain or hardcoded check for alternate domains
 * @returns
 */
function getCommunityDomainToLoad(redirectURL = "") {
  // check if we have community subdomain in env file
  if (process.env.REACT_APP_COMMUNITY_SUBDOMAIN) {
    return process.env.REACT_APP_COMMUNITY_SUBDOMAIN;
  }

  // generate url
  const url = new URL(redirectURL);
  // get subdomain
  const subDomain = redirectURL.replace(/http(s)?(:\/\/)/g, "").split(".")[0];

  // check if subdomain matches, return it if so
  if (
    (url.hostname.endsWith("pensil.in") ||
      url.hostname.endsWith("pensil.com") ||
      url.hostname.endsWith("pensil.so")) &&
    subDomain !== "app" &&
    subDomain !== "embed"
  )
    return subDomain;

  // check if subdomain is app
  if (url.hostname.startsWith("app.pensil.")) return "";

  // return the host itself, server will handle
  return url.hostname;
}
