import cx from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GroupService } from "../../_service";
import { setActiveGroup, setGroups } from "../../_store/_actions/group.actions";
import I18 from "../atoms/i18";
import { TabBody } from "../atoms/tab-body.component";
import { TabPills } from "../atoms/tab-pills.component";

import { useAppService } from "../../hooks/use-app-service";
import { activateSectionAction } from "../../_store/sections.store";
import { Button } from "../button.component";
import {
  ConvertToSlugString,
  CreateSlugComponent,
  limitMaxCharacterTo255,
} from "../create-slug-modal";
import { Checkbox } from "../form-controls/checkbox.component";
import EmojiPicker from "../form-controls/emoji-picker.component";
import { Radio } from "../form-controls/radio.component";
import IconButton2 from "../icon-button-2-component";
import { Modal } from "../modal.component";
import { TextInput } from "../text-input.component";

const TabButton = Object.freeze({
  details: "Basic Detail",
  slug: "Settings",
});

function CreateSectionModalComponent({
  user,
  activeGroup = null,

  activeTab = null,
  active = false,
  setActiveTabModel = (e) => {},
  setActive = (e) => {},
  groups = [],
  community = null,
  updateGroups = (e) => {},
  updateActiveGroup = (e) => {},
}) {
  const { analyticsService } = useAppService();
  // form states
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(activeTab ? activeTab.sortBy : "latest");
  const [name, setName] = useState(activeTab ? activeTab.name : "");
  const [emoji, setEmoji] = useState(activeTab ? activeTab.emoji : null);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [sectionType, setSectionType] = useState(
    activeTab ? activeTab.sectionType : "generic"
  );
  const [sectionView, setSectionView] = useState(
    activeTab ? activeTab.sectionView : "list"
  );
  const [postLevel, setPostLevel] = useState(
    activeTab ? activeTab.postLevel : "anyone"
  );
  const [defaultNotificationLevel, setDefaultNotificationLevel] = useState(
    activeTab ? activeTab.defaultNotificationLevel : "all"
  );
  const [pushNotificationOnNewPost, setPushNotificationOnNewPost] = useState(
    activeTab ? activeTab.pushNotificationOnNewPost : true
  );
  const [emailNotificationOnNewPost, setEmailNotificationOnNewPost] = useState(
    activeTab ? activeTab.emailNotificationOnNewPost : true
  );
  const [isClosed, setIsClosed] = useState(
    activeTab ? (activeTab.isPrivate ? true : activeTab.isClosed) : false
  );
  const [isPrivate, setIsPrivate] = useState(
    activeTab ? activeTab.isPrivate : false
  );

  const [selectedTab, setSelectedTab] = useState(TabButton.details);

  // Slugs related
  const [slug, setSlug] = useState(
    activeTab && activeTab.meta && activeTab.meta.slug
      ? activeTab.meta.slug
      : null
  );
  const [slugTitle, setSlugTitle] = useState(
    activeTab && activeTab.meta && activeTab.meta.title
      ? activeTab.meta.title
      : null
  );
  const [slugDescription, setSlugDescription] = useState(
    activeTab && activeTab.meta && activeTab.meta.description
      ? activeTab.meta.description
      : null
  );
  const [OGTitle, setOGTitle] = useState(
    activeTab && activeTab.meta && activeTab.meta.openGraphTitle
      ? activeTab.meta.openGraphTitle
      : null
  );
  const [OGDescription, setOGDescription] = useState(
    activeTab && activeTab.meta && activeTab.meta.openGraphDescription
      ? activeTab.meta.openGraphDescription
      : null
  );
  const [OGImage, setOGImage] = useState(
    activeTab && activeTab.meta && activeTab.OGImage ? activeTab.OGImage : null
  );

  // const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!active) {
      return;
    }
    setName(activeTab ? activeTab.name : "");
    setPostLevel(activeTab ? activeTab.postLevel : "anyone");
    setDefaultNotificationLevel(
      activeTab ? activeTab.defaultNotificationLevel : "all"
    );
    setPushNotificationOnNewPost(
      activeTab ? activeTab.pushNotificationOnNewPost : true
    );
    setEmailNotificationOnNewPost(
      activeTab ? activeTab.emailNotificationOnNewPost : true
    );
    setSectionType(activeTab ? activeTab.sectionType : "generic");
    setSectionView(activeTab ? activeTab.sectionView : "list");
    setEmoji(activeTab ? activeTab.emoji : "");
    setIsClosed(
      activeTab ? (activeTab.isPrivate ? true : activeTab.isClosed) : false
    );
    setSortBy(activeTab ? activeTab.sortBy : "latest");
  }, [activeTab, active]);

  // check if form should be submittable
  const isFormSubmittable =
    name && name.length > 2 && sectionType && sectionView && postLevel
      ? true
      : false;

  const submitForm = (e) => {
    // create section object
    const tab = {
      name,
      emoji,
      sectionType,
      sectionView,
      postLevel,
      defaultNotificationLevel,
      pushNotificationOnNewPost,
      emailNotificationOnNewPost,
      isClosed: isPrivate ? true : isClosed,
      isPrivate,
      sortBy,
      meta: {
        slug: ConvertToSlugString(slug === null ? name : slug),
        title: slugTitle === null ? name : slugTitle,
        description: slugDescription === null ? name : slugDescription,
        openGraphTitle: OGTitle === null ? name : OGTitle,
        openGraphDescription: OGDescription === null ? name : OGDescription,
        openGraphImage: OGImage,
      },
    };

    setIsLoading(true);
    // if editing a tab, update it, or create a new
    if (activeTab) {
      // call the edit section api
      GroupService.editGroupTab(user, activeGroup.id, activeTab.id, tab)
        .then(({ group }) => {
          // close the form
          setIsLoading(false);
          resetFormAndClose();
          // update the active group and groups table
          updateActiveGroup(group);

          const section = group?.tabs.find((t) => t.id === activeTab.id);
          if (section) {
            setActiveTabModel(section);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      // call the create section api
      GroupService.createGroupTab(user, activeGroup.id, tab)
        .then(({ group }) => {
          // close the form
          setIsLoading(false);
          resetFormAndClose();
          // update the active group and groups table
          updateActiveGroup(group);

          analyticsService.track("create-section", {
            userType: community.myRole,
            groupName: group.name,
            sectionName: name,
            sectionType: sectionType,
            sectionAuth: postLevel,
          });

          // 31 Mar 2023 - if the section is a realtime section,
          // reload the window to make sure the new group chat is connected to the socket
          // TODO: Fix this from the backend
          if (sectionType === "realtime") {
            window.location.reload();
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const resetFormAndClose = () => {
    setName("");
    // setPostLevel("");
    setActive(false);
    setSortBy("latest");
    setIsClosed(false);
    setIsPrivate(false);
    setEmoji(null);
    setSectionType("generic");
    setSectionView("list");
    clearSlugMeta();
  };
  function clearSlugMeta() {
    setSlug(null);
    setOGTitle(null);
    setOGDescription(null);
    setOGImage(null);
    setSlugTitle(null);
    setSlugDescription(null);
  }

  if (!activeGroup || !active) {
    return <></>;
  }

  return (
    <Modal
      className="CreateGroupModal"
      active={active}
      width="520px"
      padding={false}
      setActive={(e) => {
        setActive(false);
        resetFormAndClose();
      }}>
      <div className="theme-bg-surface">
        {/* headers */}
        <div className="pl-4 pr-2 py-2 theme-bg-default flex items-center justify-between">
          <span className="font-bold theme-text-heading-2">
            {activeTab ? <I18>Update Channel</I18> : <I18>Create Channel</I18>}
            {activeTab && (
              <span className="theme-text-heading-2 font-semibold ml-3">
                Id: {activeTab.id}
              </span>
            )}
          </span>
          <IconButton2
            icon="cross"
            size="md"
            iconClass="theme-text-subtitle-2"
            onClick={() => {
              resetFormAndClose();
            }}
          />
        </div>
        <div className="flex justify-start theme-bg-default tab-holder px-5">
          <TabPills
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={[
              { label: TabButton.details, key: TabButton.details },
              {
                label: TabButton.slug,
                key: TabButton.slug,
              },
            ]}
          />
        </div>
        <TabBody
          selectedTab={selectedTab}
          components={{
            [TabButton.details]: (
              <CreateSectionBody
                emoji={emoji}
                setEmoji={setEmoji}
                name={name}
                setName={setName}
                isClosed={isClosed}
                setIsClosed={setIsClosed}
                isPrivate={isPrivate}
                setIsPrivate={setIsPrivate}
                sectionType={sectionType}
                setSectionType={setSectionType}
                postLevel={postLevel}
                setPostLevel={setPostLevel}
                defaultNotificationLevel={defaultNotificationLevel}
                setDefaultNotificationLevel={setDefaultNotificationLevel}
                pushNotificationOnNewPost={pushNotificationOnNewPost}
                setPushNotificationOnNewPost={setPushNotificationOnNewPost}
                emailNotificationOnNewPost={emailNotificationOnNewPost}
                setEmailNotificationOnNewPost={setEmailNotificationOnNewPost}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sectionView={sectionView}
                setSectionView={setSectionView}
                isEmojiPickerVisible={isEmojiPickerVisible}
                setIsEmojiPickerVisible={setIsEmojiPickerVisible}
                activeTab={activeTab}
                isLoading={isLoading}
                isFormSubmittable={isFormSubmittable}
                submitForm={submitForm}
              />
            ),
            [TabButton.slug]: (
              <>
                <div className="p-4 mb-2">
                  <CreateSlugComponent
                    community={community}
                    setActive={setActive}
                    slug={ConvertToSlugString(slug === null ? name : slug)}
                    slugPrefix="s"
                    setSlug={setSlug}
                    title={limitMaxCharacterTo255(
                      slugTitle == null ? name : slugTitle
                    )}
                    setTitle={setSlugTitle}
                    description={limitMaxCharacterTo255(
                      slugDescription == null ? name : slugDescription
                    )}
                    setDescription={setSlugDescription}
                    OGTitle={limitMaxCharacterTo255(
                      OGTitle == null ? name : OGTitle
                    )}
                    setOGTitle={(title) => {
                      setOGTitle(title);
                    }}
                    OGDescription={limitMaxCharacterTo255(
                      OGDescription == null ? name : OGDescription
                    )}
                    setOGDescription={setOGDescription}
                    OGImage={OGImage}
                    setOGImage={setOGImage}
                    clearSlugMeta={clearSlugMeta}
                  />
                </div>
              </>
            ),
          }}
        />
      </div>
    </Modal>
  );
}

function CreateSectionBody({
  emoji,
  setEmoji,
  name,
  setName,
  isClosed,
  setIsClosed,
  isPrivate,
  setIsPrivate,
  sectionType,
  setSectionType,
  postLevel,
  setPostLevel,
  defaultNotificationLevel,
  setDefaultNotificationLevel,
  pushNotificationOnNewPost,
  setPushNotificationOnNewPost,
  emailNotificationOnNewPost,
  setEmailNotificationOnNewPost,
  sortBy,
  setSortBy,
  sectionView,
  setSectionView,
  isEmojiPickerVisible,
  setIsEmojiPickerVisible,
  activeTab,

  submitForm,
  isLoading,
  isFormSubmittable,
}) {
  return (
    <>
      <div className="p-5 theme-text-heading-2">
        {/* section name */}
        <div className="mb-5">
          <div className="font-bold mb-1 after:content-['*'] after:text-red-500 after:ml-0.5 ">
            <I18>Channel Name</I18>
          </div>
          <TextInput
            placeholder="Enter channel name"
            className="theme-bg-surface"
            containerClassName="theme-bg-surface"
            prefix={
              <div className="theme-bg-surface">
                <EmojiPicker
                  emoji={emoji}
                  setEmoji={(emoji) => {
                    // set emoji
                    setEmoji(emoji);
                    // hide picker
                    setIsEmojiPickerVisible(false);
                  }}
                  visible={isEmojiPickerVisible}
                  setVisible={setIsEmojiPickerVisible}
                />
              </div>
            }
            defaultValue={name}
            validator={(value) => {
              if (value && value.length > 2) {
                return null;
              } else {
                return "Valid channel name required!";
              }
            }}
            onChange={(value) => {
              setName(value);
            }}
            large
            noMargin
          />
          <div className="text-xs theme-text-subtitle-1 mt-1">
            <I18>Suggestions: General, Introductions, Community Guidelines</I18>
          </div>
        </div>
        {/* section type */}
        {
          // not available if editing
          !activeTab ? (
            <div className="mb-5">
              <div className="font-bold">Channel Type</div>
              <div className="flex mt-3 space-x-8">
                <div
                  className="mr-3 flex flex-col items-center cursor-pointer hover:theme-bg-default"
                  onClick={() => {
                    setSectionType("generic");
                  }}>
                  {sectionType === "generic_making_this_false_for_now" ? (
                    <img
                      src="/assets/vectors/section-type-generic-selected-icon.svg"
                      width="124px"
                      alt=""
                    />
                  ) : (
                    <img
                      src="/assets/vectors/section-type-generic-icon.svg"
                      width="124px"
                      alt=""
                    />
                  )}
                  <Radio className="my-2" selected={sectionType === "generic"}>
                    <I18>Post Type</I18>
                  </Radio>
                </div>
                <div
                  className="mr-3 flex flex-col items-center cursor-pointer hover:theme-bg-default"
                  onClick={() => {
                    setSectionType("realtime");
                  }}>
                  {sectionType === "realtime_making_this_false_for_now" ? (
                    <img
                      src="/assets/vectors/section-type-realtime-selected-icon.svg"
                      width="124px"
                      alt=""
                    />
                  ) : (
                    <img
                      src="/assets/vectors/section-type-realtime-icon.svg"
                      width="124px"
                      alt=""
                    />
                  )}
                  <Radio className="my-2" selected={sectionType === "realtime"}>
                    <I18>Group Chat</I18>
                  </Radio>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        }
        {/* section post level */}

        <div className="mb-5">
          <div className="font-bold">
            <I18>Who can post in this channel?</I18>
          </div>
          <div className="flex space-x-8">
            <Radio
              className="my-2"
              selected={postLevel === "anyone"}
              onClick={() => {
                setPostLevel("anyone");
              }}>
              <I18>Any User</I18>
            </Radio>
            <Radio
              className="my-2"
              selected={postLevel === "admin"}
              onClick={() => {
                setPostLevel("admin");
              }}>
              <I18>Only Admin</I18>
            </Radio>
          </div>
        </div>

        {/* notification preferences */}
        <div className="mb-5">
          <div className="font-bold">
            <I18>Default notification level for tab</I18>
          </div>
          <div className="flex space-x-8">
            <Radio
              className="my-2"
              selected={defaultNotificationLevel === "all"}
              onClick={() => {
                setDefaultNotificationLevel("all");
              }}>
              <I18>All</I18>
            </Radio>
            <Radio
              className="my-2"
              selected={defaultNotificationLevel === "admin"}
              onClick={() => {
                setDefaultNotificationLevel("admin");
              }}>
              <I18>Admin</I18>
            </Radio>
            <Radio
              className="my-2"
              selected={defaultNotificationLevel === "none"}
              onClick={() => {
                setDefaultNotificationLevel("none");
              }}>
              <I18>None</I18>
            </Radio>
          </div>
        </div>
        {/* new post notification */}
        <div className="mb-5">
          <div className="font-bold">
            <I18>New post notification</I18>
          </div>
          <div className="flex space-x-8">
            <Checkbox
              className="my-2"
              selected={emailNotificationOnNewPost}
              onClick={() => {
                setEmailNotificationOnNewPost(!emailNotificationOnNewPost);
              }}>
              <I18>Email</I18>
            </Checkbox>
            <Checkbox
              className="my-2"
              selected={pushNotificationOnNewPost}
              onClick={() => {
                setPushNotificationOnNewPost(!pushNotificationOnNewPost);
              }}>
              <I18>Mobile app notification</I18>
            </Checkbox>
          </div>
        </div>
        {/* Sorting order */}
        {sectionType === "generic" ? (
          <div className="mb-5">
            <div className="font-bold">
              <span>
                <I18>Sorting order for channel</I18>
              </span>
            </div>
            <div className="flex space-x-8">
              <Radio
                className="my-2"
                selected={sortBy === "latest"}
                onClick={() => {
                  setSortBy("latest");
                }}>
                <I18>Latest first</I18>
              </Radio>
              <Radio
                className="my-2"
                selected={sortBy === "oldest"}
                onClick={() => {
                  setSortBy("oldest");
                }}>
                <I18>Oldest post first</I18>
              </Radio>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* Section View */}
        <div
          className={cx("mb-5", {
            hidden: sectionType === "realtime",
          })}>
          <div className="font-bold">
            <I18>Channel appearance</I18>
          </div>
          <div className="flex space-x-8">
            <Radio
              className="my-2"
              selected={sectionView === "list"}
              onClick={() => {
                setSectionView("list");
              }}>
              <I18>List View</I18>
            </Radio>
            <Radio
              className="my-2"
              selected={sectionView === "grid"}
              onClick={() => {
                setSectionView("grid");
              }}>
              <I18>Grid View</I18>
            </Radio>
          </div>
        </div>

        {/* closed section data */}
        <div
          className="mb-5 flex cursor-pointer space-x-2"
          onClick={(e) => {
            if (!isPrivate) {
              setIsClosed(!isClosed);
            }
          }}>
          <Checkbox
            selected={isPrivate ? true : isClosed}
            disabled={isPrivate}
          />
          <div className="flex-grow">
            <div className="font-bold">Make this a closed channel</div>
            <div className="theme-text-subtitle-1 text-xs">
              <I18>
                Members will have to request access to see channel content
              </I18>
            </div>
          </div>
        </div>
        {/* closed section data */}
        <div
          className="mb-5 flex cursor-pointer space-x-2"
          onClick={(e) => {
            setIsPrivate(!isPrivate);
          }}>
          <Checkbox selected={isPrivate} />
          <div className="flex-grow">
            <div className="font-bold">
              <I18>Make this a private channel</I18>
            </div>
            <div className="theme-text-subtitle-1 text-xs">
              <I18>
                Disables requests (Make sure closed channel is selected as
                well).
              </I18>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="mb-5 mt-20">
          <div className="mx-auto">
            <Button
              disabled={!isFormSubmittable}
              onClick={submitForm}
              className="p-4 w-full"
              label={activeTab ? "Update Channel" : "Create new Channel"}
              large
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const CreateSectionModal = connect(
  (s) => ({
    user: s.auth,
    activeGroup: s.activeGroup,
    groups: s.groups,
    community: s.community,
  }),
  (d) =>
    bindActionCreators(
      {
        updateGroups: setGroups,
        updateActiveGroup: setActiveGroup,
        setActiveTabModel: activateSectionAction,
      },
      d
    )
)(CreateSectionModalComponent);

export { CreateSectionModal };
