import cx from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { history } from "../_config";
import { getSectionPageRouteFromSlug } from "../_pages";
import { UserService } from "../_service";
import Validator from "../_utils/validator";
import I18 from "./atoms/i18";
import LazyImage from "./atoms/lazy-image";
import { Loader } from "./loader.component";
import { getSegmentedPath } from "../_utils/segmented-community-util";

function Avatar({
  user,
  extraInfo,
  hideNameOnMobile = false,
  activityAvatar = false, // activity avatar
  subCommentAvatar = false, // sub comment avatar
  noPicture = false,
  noName = false,
  tag = <></>,
  picture = "picture",
  size = 36,
  showDetailsOnHover = false,
  onClick = (e) => {},
  isProcessing = false,
  className = "",
  buttonLabel = "",
  buttonOnClick = (e) => {},
  notificationCount = 0,
  bold = false,
}) {
  const [detailVisibility, setDetailVisibility] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseEnter={(e) => {
        // check if have to show details on hover
        if (showDetailsOnHover && !detailVisibility) {
          setDetailVisibility(true);
        }
      }}
      onMouseLeave={(e) => {
        // check if have to show details on hover
        if (showDetailsOnHover && detailVisibility) {
          setDetailVisibility(false);
        }
      }}
      className={cx(
        `Avatar flex ${!noPicture ? "items-center" : ""} justify-between`,
        className,
        {
          "hide-on-mobile": hideNameOnMobile,
        }
      )}>
      <div className={`flex ${!noPicture ? "items-center" : ""}`}>
        {!noPicture ? (
          <div
            style={{
              backgroundImage: "url('" + user[picture] + "')",
              width: size + "px",
              height: size + "px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "50%",
              marginRight: "12px",
              flexShrink: 0,
            }}
          />
        ) : (
          // <LazyImage
          //   src={user.picture}
          //   onClick={onClick}
          //   style={{
          //     width: size + "px",
          //     height: size + "px",
          //     minWidth: size + "px",
          //     minHeight: size + "px",
          //   }}
          //   className={`rounded-full cursor-pointer object-cover mr-3`}
          // />
          <></>
        )}

        <div>
          <div className="flex items-center">
            {!noName ? (
              <div
                className={
                  "name text-sm " + (bold ? "font-bold" : "font-semibold")
                }>
                {user.name}
              </div>
            ) : (
              <></>
            )}
            {tag}
            {activityAvatar ? (
              <span className="font-normal ml-2 theme-text-subtitle-2">
                {extraInfo}
              </span>
            ) : (
              <></>
            )}
            {/* show has updates marker */}
            {notificationCount ? (
              <span className="update-marker">{notificationCount}</span>
            ) : (
              <></>
            )}
          </div>
          {!activityAvatar ? (
            <div
              className={
                subCommentAvatar
                  ? "font-normal extra-info theme-text-subtitle-2"
                  : "text-xs font-semibold extra-info theme-text-subtitle-2"
              }>
              {extraInfo}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!isProcessing ? (
        <div
          className="font-semibold theme-text-primary text-sm"
          onClick={(e) => {
            buttonOnClick(e);
            e.preventDefault();
          }}>
          {buttonLabel}
        </div>
      ) : (
        <Loader />
      )}
      {showDetailsOnHover ? (
        <AvatarDetails user={user} visibility={detailVisibility} />
      ) : (
        <></>
      )}
    </div>
  );
}

const AvatarDetails = connect((s) => ({ loggedInUser: s.auth }))(
  ({ loggedInUser, user, visibility = false }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // get user details
    useEffect(() => {
      UserService.getUserProfileById(loggedInUser, user.id)
        .then(({ user }) => {
          setUserDetails(user);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error({
            error,
            message: "Could not load user detail in AvatarDetails",
          });
          setIsLoading(false);
        });
    }, [loggedInUser, user]);

    return (
      <div
        className={cx(
          "Avatar details flex items-center justify-between rounded card py-3 px-5 bg-white",
          {
            flex: visibility,
            hidden: !visibility,
          }
        )}>
        {isLoading ? (
          <Loader />
        ) : userDetails ? (
          <div className="flex items-center overflow-hidden">
            <div
              style={{
                backgroundImage: "url('" + userDetails.picture + "')",
                width: "48px",
                height: "48px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "50%",
                marginRight: "12px",
                flexShrink: 0,
              }}></div>
            <div className="extra-info-holder">
              <div className={"name theme-text-heading-1 font-bold"}>
                {userDetails.name}
              </div>
              {userDetails.mobile ? (
                <div className="text-xs font-semibold extra-info">
                  <img src="/assets/vectors/call-icon.svg" alt="Call Icon" />
                  {userDetails.mobile}
                </div>
              ) : (
                <></>
              )}
              {userDetails.email ? (
                <div className="text-xs font-semibold extra-info">
                  <img src="/assets/vectors/mail-icon.svg" alt="Mail Icon" />
                  {userDetails.email}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="theme-text-danger">Data could not be loaded!</div>
        )}
      </div>
    );
  }
);

export const DetailedAvatar = connect((s) => ({ loggedInUser: s.auth }))(
  ({ loggedInUser, user, noBG = false }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // get user details
    useEffect(() => {
      UserService.getUserProfileById(loggedInUser, user.id)
        .then(({ user }) => {
          setUserDetails(user);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error({
            error,
            message: "Could not load user detail in AvatarDetails",
          });
          setIsLoading(false);
        });
    }, [loggedInUser, user]);

    return (
      <div
        className={cx(
          "Avatar detailed flex items-center justify-between rounded card py-3 px-5",
          {
            flex: true,
            "bg-white": !noBG,
          }
        )}>
        {isLoading ? (
          <Loader />
        ) : userDetails ? (
          <div className="flex items-center overflow-hidden">
            <div
              style={{
                backgroundImage: "url('" + userDetails.picture + "')",
                width: "48px",
                height: "48px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "50%",
                marginRight: "12px",
                flexShrink: 0,
              }}></div>
            <div className="extra-info-holder">
              <div className={"name theme-text-heading-1 font-bold"}>
                {userDetails.name}
              </div>
              <div className="flex items-center">
                {userDetails.mobile ? (
                  <div className="text-xs font-semibold extra-info flex theme-text-heading-2 items-center">
                    {userDetails.countryCode} {userDetails.mobile}
                  </div>
                ) : (
                  <></>
                )}
                {userDetails.mobile && userDetails.email ? (
                  <div className="px-1">•</div>
                ) : (
                  <></>
                )}
                {userDetails.email ? (
                  <div className="text-xs font-semibold extra-info flex theme-text-heading-2 items-center">
                    {userDetails.email}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="theme-text-danger">
            <I18>Data could not be loaded!</I18>
          </div>
        )}
      </div>
    );
  }
);

export const AvatarCommunityPost = connect((s) => ({ loggedInUser: s.auth }))(
  ({ loggedInUser, user, post, tag, onClick = (e) => {} }) => {
    return (
      <div className={cx("Avatar flex items-center justify-between rounded")}>
        {user ? (
          <div className="overflow-hidden flex items-center">
            <LazyImage
              src={user.picture}
              onClick={onClick}
              className="h-12 w-12 rounded-full cursor-pointer object-cover flex-shrink-0 m-auto"
            />
            <div className="extra-info-holder">
              <div
                onClick={onClick}
                className={
                  "name theme-text-heading-2 font-bold items-center flex whitespace-nowrap ml-2 cursor-pointer"
                }>
                {user.name && user.name.length > 12
                  ? user.name.substring(0, 10) + "..."
                  : user.name}
                <div className={"font-normal extra-info theme-text-subtitle-2"}>
                  {tag}
                </div>

                <div className="h-1 w-1 ml-2 bg-gray-400 rounded-full inline-block" />
                <div className="ml-1 text-xs font-normal">
                  {post.createdAt ? moment(post.createdAt).fromNow() : ""}
                </div>
              </div>
              <div className="ml-2 line-clamp-1 text-sm">
                {Validator.hasValue(post.createdBy?.shortBio)
                  ? post.createdBy?.shortBio
                  : "  "}
              </div>
              <div
                onClick={() =>
                  history.push(
                    getSegmentedPath(getSectionPageRouteFromSlug(post.group, post.tab))
                  )
                }
                className="ml-2 font-md cursor-pointer ">
                {/* {post.group && post.group.name ? post.group.name : ""} */}
                <I18>Posted in</I18>{" "}
                <span className="theme-text-primary">
                  {post.tab && post.tab.name ? post.tab.name : ""}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="theme-text-danger">
            <I18>Data could not be loaded!</I18>
          </div>
        )}
      </div>
    );
  }
);

export { Avatar };
