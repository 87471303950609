import moment from "moment";
import { useState } from "react";
import { ToastTypes } from "../../contexts/toastr.context";
import { Button, Loader } from "../../_components";
import { ComponentDisplay } from "../../_components/component-switcher/component-switcher.component";
import IconMenu2, { ActionType } from "../../_components/icon-menu-2.component";
import Pagination from "../../_components/pagination.component";
import { EmailService } from "../../_service";

/**
 * Display the list of invited users
 * @param {Object} user - Auth user object
 * @param {Object} community - Current community object
 * @param {Array<Object>} groups - List of groups
 * @param {Array<Object>} invites - List of invited users
 * @param {Function} setInvites - Callback function to set invites
 * @param {number} totalInvites - Total number of invited users
 * @param {Function} setTotalInvites - Callback function to set total invites
 * @param {boolean} isLoading - Flag to check if data is loading
 * @param {number} page - Current page number
 * @param {Function} setPage - Set invite count callback function
 * @param {Function} addToast - Toast notification function
 */
export default function InvitedUsersList({
  user,
  community,
  groups,
  invites,
  setInvites = (e) => {},
  setTotalInvites = (e) => {},
  totalInvites,
  isLoading,
  page,
  setPage = (e) => {},
  addToast,
}) {
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [reSendingBulkMail, setReSendingBulkMail] = useState(false);
  const [cancelingBulkMail, setCancelingBulkMail] = useState(false);

  const onUserSelected = (user) => {
    if (selectedUserList.findIndex((u) => u.id === user.id) === -1) {
      setSelectedUserList([...selectedUserList, user]);
    } else {
      setSelectedUserList(selectedUserList.filter((u) => u.id !== user.id));
    }
  };
  //  Resend invite mail
  function reinvite() {
    if (selectedUserList && selectedUserList.length < 1) {
      return;
    }
    setReSendingBulkMail(true);
    const payload = selectedUserList.map((user) => {
      return user.id;
    });
    EmailService.resendBulkMail(user, community.id, payload)
      .then((res) => {
        addToast("Invite sent successfully");
        setSelectedUserList([]);
      })
      .catch((err) => {
        console.log(err);
        addToast("Error sending invite", "", ToastTypes.danger);
      })
      .then(() => {
        setReSendingBulkMail(false);
      });
  }

  // Cancel all pending invites
  function cancelBulkMail() {
    if (selectedUserList && selectedUserList.length < 1) {
      return;
    }
    const canProceed = window.confirm(
      `Are you sure you want to cancel ${selectedUserList.length}${
        selectedUserList.length > 1 ? " invites" : " invite"
      }?`
    );
    if (!canProceed) {
      return;
    }
    setCancelingBulkMail(true);
    const payload = selectedUserList.map((user) => {
      return user.id;
    });
    EmailService.cancelBulkMail(user, community.id, payload)
      .then((res) => {
        addToast("Invite cancelled successfully");
        /// Remove the selected users from the invites
        const temp = invites.filter((invite) => !payload.includes(invite.id));
        setInvites(temp);
        setTotalInvites(totalInvites - selectedUserList.length);
        setSelectedUserList([]);
      })
      .catch((err) => {
        console.log(err);
        addToast("Error cancelling invite", "", ToastTypes.danger);
      })
      .then(() => {
        setCancelingBulkMail(false);
      });
  }

  return (
    <>
      {/* Table */}
      <div className="w-full">
        <ComponentDisplay
          IsDisplay={selectedUserList.length > 0}
          transition="scale"
          className="flex justify-end">
          <div className="flex">
            <Button
              label="Resend Invites"
              className=" m-2 mr-2"
              onClick={reinvite}
              isLoading={reSendingBulkMail}
              disabled={cancelingBulkMail}
            />
            <Button
              label="Cancel Invites"
              className=" m-2 "
              onClick={cancelBulkMail}
              isLoading={cancelingBulkMail}
              disabled={reSendingBulkMail}
            />
          </div>
        </ComponentDisplay>
        {/* </div> */}

        <table className="table-auto min-w-full text-sm theme-text-subtitle-1 border theme-border-default w-full rounded">
          <thead className="text-xs text-left theme-text-headline-1 uppercase theme-bg-default">
            <tr>
              <th scope="col" className="px-1 py-3"></th>
              <th scope="col" className="px-3 py-3">
                Name
              </th>
              <th scope="col" className="px-1 py-3">
                Email
              </th>
              <th scope="col" className="px-1 pr-16 py-3">
                Invited
              </th>
              <th scope="col" className="px-1 py-3">
                Status
              </th>
              <th scope="col" className="px-1 py-3">
                Invited To
              </th>
              <th scope="col" className="px-1 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((_, index) => (
                <MemberTableRowShimmer key={index} />
              ))
            ) : (
              <>
                {invites.length > 0 ? (
                  invites.map((invitedUser, index) => {
                    return (
                      <InviteMemberRow
                        key={index}
                        invitedUser={invitedUser}
                        user={user}
                        community={community}
                        groups={groups}
                        onUserSelected={onUserSelected}
                        isSelected={
                          selectedUserList.findIndex(
                            (u) => u.id === invitedUser.id
                          ) !== -1
                        }
                        onInviteCancel={(invite) => {
                          const temp = invites.filter(
                            (inv) => inv.id !== invite.id
                          );
                          setInvites(temp);
                          setTotalInvites(totalInvites - 1);
                        }}
                        addToast={addToast}
                      />
                    );
                  })
                ) : (
                  <tr
                    className=" items-center justify-center place-content-center text-center h-32 w-full"
                    colSpan="5">
                    {/* <td></td> */}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="theme-text-subtitle-2 ">No invite sent</td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          className="pagination-bar justify-center"
          currentPage={page}
          totalCount={totalInvites}
          pageSize={10}
          onPageChange={setPage}
        />
      </div>
    </>
  );
}

/**
 * @description  Display the Invited User table row data
 * @param {Object} invitedUser - Invited user object
 * @param {Object} user - Auth user object
 * @param {Object} community - Current community object
 * @param {Function} onInviteCancel - Callback function to return the cancelled invite
 * @param {Array<Object>} groups - List of groups
 * @param {Function} addToast - Toast notification function
 * @param {Function} onUserSelected - Callback function to select user
 * @param {boolean} isSelected - Flag to check if user is selected
 */
function InviteMemberRow({
  index,
  invitedUser,
  user,
  groups,
  community,
  onInviteCancel = (e) => {},
  addToast,
  onUserSelected,
  isSelected,
}) {
  const [isSendingInvite, setIsSendingInvite] = useState();

  function getGroupNameFromId(id) {
    let groupName = "-";
    if (!id) {
      return groupName;
    }
    groups.forEach((group) => {
      if (group.id === id) {
        groupName = group.name;
      }
    });
    return groupName;
  }
  return (
    <tr
      key={index}
      className="theme-bg-surface border-b theme-border-default hover:theme-bg-default">
      <td className="flex place-content-center pl-1 py-3">
        <input
          type="checkbox"
          disabled={invitedUser.isAccepted}
          checked={isSelected}
          onChange={() => onUserSelected(invitedUser)}
          className={`ml-1 ${
            invitedUser.isAccepted
              ? "cursor-not-allowed disabled:opacity-75"
              : "cursor-pointer"
          }`}
        />
      </td>

      <td className="px-3 py-2 font-medium theme-text-headline-1 ">
        {invitedUser.name && invitedUser.name.length > 0
          ? invitedUser.name
          : "-"}
      </td>
      <td className="px-1 py-2 theme-text-subtitle-1">
        {invitedUser.email && invitedUser.email.length > 0
          ? invitedUser.email
          : "-"}
      </td>
      <td className="px-1 py-2 theme-text-subtitle-1">
        {moment(invitedUser.createdAt).fromNow()}
      </td>
      <td className="px-1 py-2 flex items-center">
        {invitedUser.isAccepted ? (
          <div className="h-2 w-2 rounded-full bg-green-500 mr-1"></div>
        ) : (
          <div className="h-2 w-2 rounded-full theme-bg-warning mr-1"></div>
        )}
        {invitedUser.isAccepted ? "Joined" : "Pending"}
      </td>
      <td className="px-1 py-2 theme-text-subtitle-1">
        {invitedUser.group == null
          ? community.name
          : getGroupNameFromId(invitedUser.group)}
      </td>
      <td className="w-8">
        {isSendingInvite ? (
          <Loader />
        ) : (
          <IconMenu2
            actions={[
              !invitedUser.isAccepted && {
                icon: "paper-plane",
                label: "Resend Invite",
                onClick: () => {
                  setIsSendingInvite(true);
                  const payload = {
                    groupId: !invitedUser.group ? null : invitedUser.group,
                    additionalMessage: "",
                    memberData: [
                      {
                        email: invitedUser.email,
                        name: invitedUser.name,
                      },
                    ],
                  };
                  EmailService.inviteByEmail(user, community.id, payload)
                    .then((res) => {
                      addToast("Invite sent successfully");
                    })
                    .catch((err) => {
                      console.log(err);
                      addToast("Error sending invite", "", ToastTypes.danger);
                    })
                    .finally(() => {
                      setIsSendingInvite(false);
                    });
                },
              },
              !invitedUser.isAccepted && {
                icon: "cross",
                label: "Cancel Invite",
                actionType: ActionType.alert,
                onClick: () => {
                  setIsSendingInvite(true);

                  EmailService.cancelBulkMail(user, community.id, [
                    invitedUser.id,
                  ])
                    .then((res) => {
                      addToast("Invite cancelled successfully");
                      onInviteCancel(invitedUser);
                    })
                    .catch((err) => {
                      console.log(err);
                      addToast(
                        "Error cancelling invite",
                        "",
                        ToastTypes.danger
                      );
                    })
                    .then(() => {
                      setIsSendingInvite(false);
                    });
                },
              },
            ]}
          />
        )}
      </td>
    </tr>
  );
}

export function MemberTableRowShimmer() {
  return (
    <tr className="w-full  theme-bg-surface border-b theme-border-default hover:theme-bg-default animate-pulse my-2 py-2">
      <td className=" pl-1">
        <div className="theme-bg-default mx-1  py-3 my-2" />
      </td>
      <td className=" ">
        <div className="theme-bg-default mr-6 py-3 my-2" />
      </td>
      <td className=" ">
        <div className="theme-bg-default mr-6 py-3 my-2" />
      </td>
      <td className=" ">
        <div className="theme-bg-default mr-6 py-3 my-2" />
      </td>
      <td className=" ">
        <div className="theme-bg-default mr-6 py-3 my-2" />
      </td>
      <td className=" ">
        <div className="theme-bg-default mr-6 py-3 my-2" />
      </td>
    </tr>
  );
}
