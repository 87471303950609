import { useEffect, useState } from "react";
import { history } from "../../_config";
import {
  createEventDetailSlugPageRoute,
  formatEventTime,
} from "../../_pages/event-detail.page";
import { COMMUNITY_EVENTS_PAGE_ROUTE } from "../../_pages/events-page";
import { EventService } from "../../_service/event.service";
import I18 from "../atoms/i18";
import TextButton from "../atoms/text-button";
import { Avatar } from "../avatar.component";
import { Loader } from "../loader.component";
import { useAppService } from "../../hooks/use-app-service";
import { getSegmentedPath } from "../../_utils/segmented-community-util";
interface UpcomingEventWidgetProps {
  user: any;
  community: any;
  group: any;
  isCommunityEvent: boolean;
}

/**
 * Widget to display trending posts of community and group
 * @param {any} user current active user profile data.
 * @param {any} community community object.
 * @param {any} group group object.
 * @param {boolean} isCommunityEvent Determine whether to widget is to display on home page or in group
 */
function UpcomingEventWidget({
  user,
  community,
  group,
  isCommunityEvent = false,
}: UpcomingEventWidgetProps) {
  const date = "";
  const [events, setEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  useEffect(() => {
    if (isCommunityEvent && events.length === 0) {
      getCommunityUpcomingEvents();
    } else if (!isCommunityEvent && events.length === 0) {
      getGroupUpcomingEvents();
    }
  }, [community]);

  const getCommunityUpcomingEvents = () => {
    let getCommunityUpcomingEvents: any = [];
    setIsLoading(true);
    EventService.upcomingEvents(user, community.id, date)
      .then((response) => {
        if (response.events) {
          response.events.map((event: any) => {
            const newEvent = {
              title:
                event.title && event.title.length > 15
                  ? event.title.substring(0, 14) + "..."
                  : event.title,
              start: new Date(event.startTime),
              end: new Date(event.endTime),
              ...event,
            };
            // Take only 5 recent events to display in widget
            if (getCommunityUpcomingEvents.length < 5) {
              getCommunityUpcomingEvents.push(newEvent);
            }
          });
        }
        setEvents(getCommunityUpcomingEvents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const getGroupUpcomingEvents = () => {
    let getCommunityUpcomingEvents: any = [];
    setIsLoading(true);
    EventService.latestGroupEvent(user, community.id, group.id)
      .then((response) => {
        if (response.events) {
          response.events.map((event: any) => {
            const newEvent = {
              title:
                event.title && event.title.length > 15
                  ? event.title.substring(0, 14) + "..."
                  : event.title,
              start: new Date(event.startTime),
              end: new Date(event.endTime),
              ...event,
            };
            // Take only 5 recent events to display in widget
            if (getCommunityUpcomingEvents.length < 5) {
              getCommunityUpcomingEvents.push(newEvent);
            }
          });
        }
        setEvents(getCommunityUpcomingEvents);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  // Hide event widget if there is no upcoming event available
  if ((!isLoading && events === undefined) || events.length == 0) {
    return <></>;
  }
  return (
    <div
      className="theme-bg-surface rounded w-full my-4"
      style={{ maxWidth: "100%" }}>
      <h4 className="font-bold px-4 py-2 border-b theme-border-default">
        {isCommunityEvent ? (
          <I18>Community Events</I18>
        ) : (
          <I18>Group Events</I18>
        )}
      </h4>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {events &&
            events.map((event: any, i: number) => {
              return <Event event={event} key={i} />;
            })}
          <TextButton
            label="Show All"
            className="m-2"
            onClick={(e: any) => {
              history.push(getSegmentedPath(`${COMMUNITY_EVENTS_PAGE_ROUTE}`));
            }}
          />
        </>
      )}
    </div>
  );
}

function Event({ event }: any) {
  const { analyticsService } = useAppService();
  
  return (
    <div
      onClick={(e) => {
        analyticsService.track("event-selected-from-widget")
        history.push(getSegmentedPath(createEventDetailSlugPageRoute(event)));
      }}
      className="border-b theme-border-default p-4 space-y-2 cursor-pointer">
      <div className="flex items-center space-x-2  ">
        {event.banner && (
          <img
            className="w-1/3"
            src={event.banner}
            alt="event"
            loading="lazy"
          />
        )}
        {/* Event Info */}
        <div className="flex flex-col">
          <div className="font-semibold">{event.title}</div>
          <div className="text-xs">
            {formatEventTime(event.startTime, event.endTime)}
          </div>
        </div>
      </div>
      {/* Host */}
      <div className="flex space-x-2 items-center">
        <Avatar
          user={event.host ?? event.createdBy}
          className=""
          extraInfo={undefined}
          size={25}
        />
      </div>
    </div>
  );
}
export default UpcomingEventWidget;
