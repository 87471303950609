import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ToastTypes } from "../../../contexts/toastr.context";
import { Button, Loader } from "../../../_components";
import { ActionModal } from "../../../_components/action-modal.component";
import { CommunityService } from "../../../_service";
import { setCommunity } from "../../../_store/_actions/community.actions";
import { RedeemButton } from "./redeem-button";
import { useAppService } from "../../../hooks/use-app-service";

function PlansListComponent({
  community,
  user,
  addToast,
  deal = false,

  isLoading = false,
  setIsLoading = (e) => {},
  setCommunity = (e) => {},
  setIsCancelPlanModalVisible = (e) => {},
}) {
  const [isChangePlanModalVisible, setIsChangePlanModalVisible] =
    useState(false);
  const [selectedPlanToChangeTo, setSelectedPlanToChangeTo] = useState(null);
  const [plansList, setPlansList] = useState({});
  const [isLoadingPlansList, setIsLoadingPlansList] = useState(true);

  const { analyticsService } = useAppService();

  const communityId = community.id;

  useEffect(() => {
    setIsLoadingPlansList(true);
    CommunityService.getPlansList(community.id)
      .then(({ plans }) => {
        setPlansList(plans);
      })
      .catch((err) => {
        addToast(
          "Could not get plans list!",
          "Please try again after refreshing or contact pensil support!",
          ToastTypes.danger
        );
      })
      .finally(() => {
        setIsLoadingPlansList(false);
      });
  }, [communityId]);

  const getButtonLabelFromPlanType = (
    currentPlanType,
    comparingPlanLevel,
    isPlanExpired
  ) => {
    if (!currentPlanType || !plansList[currentPlanType] || isPlanExpired) {
      return "Select";
    }

    const planLevel = plansList[currentPlanType].level;
    const planLowerOrHigher =
      planLevel === comparingPlanLevel
        ? "Cancel"
        : planLevel > comparingPlanLevel
        ? "Downgrade"
        : "Upgrade";
    return planLowerOrHigher;
  };

  return (
    <div className="flex flex-wrap">
      <table className="w-full my-5 border-collapse">
        {/* table header */}
        <thead className="mb-10">
          <tr>
            {isLoadingPlansList ? (
              <Loader />
            ) : (
              Object.keys(plansList).map((key) => (
                <td key={key} className="p-2" colSpan={1}>
                  <p className="font-semibold">{plansList[key].name}</p>
                  <p className="theme-text-subtitle-1 text-xxs">
                    {plansList[key].price ? (
                      <span>${plansList[key].price}/month</span>
                    ) : (
                      "Custom"
                    )}
                  </p>
                  {isLoading ? (
                    <div className="flex justify-start items-center mt-3">
                      <Loader />
                    </div>
                  ) : community.isDealRedeemed ? (
                    getButtonLabelFromPlanType(
                      community.planType,
                      plansList[key].level,
                      community.isPlanExpired
                    ) === "Cancel" ? (
                      <Button label="Redeemed" />
                    ) : (
                      <Button label="Select" disabled />
                    )
                  ) : deal ? (
                    <RedeemButton
                      user={user}
                      code={key}
                      community={community}
                      setIsLoading={setIsLoading}
                      setCommunity={setCommunity}
                      addToast={addToast}
                    />
                  ) : plansList[key].price ? (
                    <Button
                      className="mt-2"
                      label={getButtonLabelFromPlanType(
                        community.planType,
                        plansList[key].level,
                        community.isPlanExpired
                      )}
                      onClick={() => {
                        const buttonLabel = getButtonLabelFromPlanType(
                          community.planType,
                          plansList[key].level,
                          community.isPlanExpired
                        );

                        // tracking events 
                        if (plansList[key].level === 1)
                          analyticsService.track("start-plan-pressed");
                        else if (plansList[key].level === 2)
                          analyticsService.track("grow-plan-pressed");
                        
                        // if we need to choose a plan, do so
                        if (buttonLabel === "Select") {
                          setIsLoading(true);
                          // get the redirect url
                          CommunityService.getCheckoutURLForCommunityPlan(
                            user,
                            community.id,
                            key,
                            window.location.origin
                          )
                            .then(({ redirectUrl }) => {
                              window.location.href = redirectUrl;
                            })
                            .catch((err) => {
                              console.log({ err });
                              let additionalMessage = "";
                              if (
                                err &&
                                err.isAxiosError &&
                                err.response &&
                                err.response.data &&
                                err.response.data.message
                              ) {
                                additionalMessage = err.response.data.message;
                              }
                              addToast(
                                additionalMessage
                                  ? additionalMessage
                                  : "Could not create checkout session!",
                                "Please try again after refreshing or contact pensil support!",
                                ToastTypes.danger
                              );
                            })
                            .finally(() => {
                              setIsLoading(false);
                            });
                        }
                        if (
                          buttonLabel === "Upgrade" ||
                          buttonLabel === "Downgrade"
                        ) {
                          setIsChangePlanModalVisible(true);
                          setSelectedPlanToChangeTo(key);
                        }
                        // if button has Cancel label, we need to cancel the plan and subscription
                        else if (buttonLabel === "Cancel") {
                          setIsCancelPlanModalVisible(true);
                        }
                      }}
                      outlined
                    />
                  ) : (
                    <span
                      className="w-fit"
                      style={{
                        height: "33px",
                        marginTop: "0.5rem",
                        display: "block",
                      }}>
                      Email us at{" "}
                      <a href="mailto:support@pensil.in"> support@pensil.in</a>
                    </span>
                  )}
                </td>
              ))
            )}
          </tr>
        </thead>
        {/* <tbody className="py-4">
          
          <tr>
            <td colSpan="2" className="text-lg py-5 px-2 theme-text-heading-1">
              Usage
            </td>
            {Object.keys(plansList).map((key) => (
              <td key={key}></td>
            ))}
          </tr>
          
          {plansDetails.map((detail, index) => (
            <tr className={detail.label} key={index}>
              <td colSpan="2" className="theme-text-subtitle-1 p-2">
                {detail.label}
              </td>
              {detail.values.map((key) => (
                <td className="p-2 text-sm" key={key}>
                  {key}
                </td>
              ))}
            </tr>
          ))}
        </tbody> */}
      </table>
      <ActionModal
        active={isChangePlanModalVisible}
        setActive={setIsChangePlanModalVisible}
        onSubmit={(e) => {
          setIsChangePlanModalVisible(false);
          setIsLoading(true);
          // get the redirect url
          CommunityService.getCheckoutURLForCommunityPlan(
            user,
            community.id,
            selectedPlanToChangeTo,
            window.location.origin
          )
            .then(({ redirectUrl }) => {
              window.location.href = redirectUrl;
            })
            .catch((err) => {
              console.log({ err });
              let additionalMessage = "";
              if (
                err &&
                err.isAxiosError &&
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                additionalMessage = err.response.data.message;
              }
              addToast(
                additionalMessage
                  ? additionalMessage
                  : "Could not create checkout session!",
                "Please try again after refreshing or contact pensil support!",
                ToastTypes.danger
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        header={"Change Plan to " + selectedPlanToChangeTo}
        btnColor="green"
        title="Are you sure you want to change your plan?"
        // isLoading={isLoading}
      />
    </div>
  );
}

const plansDetails = [
  { label: "Members", values: ["500 members", "1000 members", "Unlimited"] },
  { label: "Admins", values: ["2", "5", "100"] },
  { label: "Moderators", values: ["2", "10", "100"] },
];

const stp = (state) => ({
  community: state.community,
  user: state.auth,
});

const dtp = {
  setCommunity,
};

const PlansList = connect(stp, dtp)(PlansListComponent);

export { PlansList };
