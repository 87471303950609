import { csCzech } from "./languages/cs.czech";
import { v1Pensil } from "./languages/custom-lang/v1-pensil";
import { deGerman } from "./languages/de.german";
import { enLanguage } from "./languages/en.language";
import { esSpanish } from "./languages/es.spanish";
import { etEstonian } from "./languages/et.estonian";
import { frFrench } from "./languages/fr.french";
import { jaLanguage } from "./languages/ja.language";
import { nlDutch } from "./languages/nl.dutch";
import { noNorwegian } from "./languages/no.norwegian";
import { svSwedish } from "./languages/sv.swedish";

const languages = {
  EN: enLanguage,
  JA: jaLanguage,
  ES: esSpanish,
  DE: deGerman,
  NL: nlDutch,
  ET: etEstonian,
  FR: frFrench,
  CS: csCzech,
  SV: svSwedish,
  V1: v1Pensil,
  NO: noNorwegian,
};

export default class I18N {
  constructor(languageCode = "EN") {
    this.languageCode = languageCode;
    this.language = languages[languageCode]
      ? languages[languageCode]
      : languages.EN;
  }

  trans(text) {
    if (text === null) {
      return text;
    }
    if (!this.language[text]) {
      // console.log("I18 lang", text);
      return text;
    }
    return this.language[text];
  }
}
