import cx from "classnames";
import { Card } from "./card.component";
/**
 * Modal component
 * @param isDismissible - if true, modal can be closed by clicking outside of it
 */
export function Modal({
  children,
  className = "",
  width = "540px",
  padding = true,
  active = false,
  isDismissible = true,
  overflow = "overflow-hidden",
  setActive = (e) => {},
  ...props
}) {
  return (
    <div
      onClick={(e) => {
        if (active && isDismissible) {
          setActive(false);
        } else {
          setActive(true);
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Escape") {
          setActive(false);
        }
      }}
      className={cx("Modal backdrop-blur-sm" + className, {
        active: active,
      })}
      {...props}>
      <Card
        onClick={(e) => {
          // stop the card being closed when we click on inner divs
          if (active) {
            e.stopPropagation();
          }
        }}
        padding={padding}
        className={overflow}
        style={{ maxWidth: width }}>
        {children}
      </Card>
    </div>
  );
}
