import { SearchService } from "../../_service/search.service";

export const SET_SEARCH_REQUEST_LOADING = "SET_SEARCH_REQUEST_LOADING";
export const SEARCH_REQUEST_SUCCESS = "SEARCH_REQUEST_SUCCESS";

export function setSearchLoadingAction() {
  return {
    type: SET_SEARCH_REQUEST_LOADING,
    isLoading: true,
  };
}

export function setSearchRequestSuccess(results) {
  return {
    type: SEARCH_REQUEST_SUCCESS,
    results,
  };
}

// thunk
export function getSearchResults(search = "") {
  return (dispatch, getState) => {
    if (!search) return dispatch(setSearchRequestSuccess({}));

    const { community } = getState();
    // get the data
    dispatch(setSearchLoadingAction(true));
    SearchService.search(community?.id, search).then((response) => {
      dispatch(setSearchRequestSuccess(response));
    });
  };
}

const INITIAL_STATE = {
  results: null,
  isLoading: false,
};

export function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SEARCH_REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
        results: null,
      };
    case SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: action.results,
      };
    default:
      return state;
  }
}
