import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withToast } from "../contexts/toastr.context";
import { Avatar } from "../_components/avatar.component";
import { CommunityService } from "../_service";
import { setCommunity } from "../_store/_actions/community.actions";
import { createUserWallPageRoute } from "./user-wall.page";

import { Loader } from "../_components";
import IconMenu2 from "../_components/icon-menu-2.component";
import { AlterCustomPointsModal } from "../_components/leaderboard-component";
import PremiumFeature from "../_components/premium/premium-feature.component";
import { getSegmentedPath } from "../_utils/segmented-community-util";
const Table = React.lazy(() => import("../_components/table.component"));

export const LEADERBOARD_PAGE_ROUTE = "/settings/leaderboard";

export function createLeaderboardPageRoute() {
  return `/leaderboard`;
}

/**
 * @param {object} user - current user object.
 * @param {object} community - community object.
 * @param {boolean} fullHeight - if true, the leaderboard will be full height. Otherwise, it will be a fixed height.
 * @returns
 */
function LeaderboardPageComponent({
  addToast,
  user,
  community,
  communityPac,
  fullHeight = true,
}) {
  const [communityLeaderboard, setCommunityLeaderboard] = useState([]);
  const [customPointUser, setCustomPointUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [list, setList] = useState();

  const [isLoadingMoreContributors, setIsLoadingMoreContributors] =
    useState(false);
  const [noMoreContributors, setNoMoreContributors] = useState(false);
  const [page, setPage] = useState(1);

  const [active, setActive] = useState(false);
  const [isAddPoints, setIsAddPoints] = useState(true);

  const innerRef = useRef();
  const abortController = useMemo(() => new AbortController(), []);
  const recordLimit = 20;

  const communityId = community?.id;
  const userId = user?.id;

  useEffect(() => {
    if (!communityPac.leaderboard || !communityId || !userId) {
      return;
    }
    setIsLoading(true);
    const getLeaderboard = async () => {
      try {
        // first set of contributors
        const leaderboardData = await CommunityService.getLeaderboardV2Paginated(
          communityId,
          page,
          recordLimit
        );
        const basePoints = community?.configurables?.COMMUNITY_BASE_POINT_KEY || 0;
        const detailsRows = leaderboardData.leaderboard.map((row, index) => {
          const grandTotal = (row.karmaPoints.grandTotal || 0 ) + basePoints;
          return {
            rank: index < 9 ? `0${index + 1}` : index + 1,
            id: row.user.id,
            // communityRole: row.communityRole,
            grandTotal,
            commentReply: row.karmaPoints?.["comment-reply"] || 0,
            postComment: row.karmaPoints?.["post-comment"] || 0,
            postCreate: row.karmaPoints?.["post-create"] || 0,
            postLike: row.karmaPoints?.["post-like"] || 0,
            user: row.user,
            userCopy: row.user,
            karmaPoints: row.karmaPoints,
            customPoints: row.karmaPoints["admin-assigned"] || 0,
          };
        });
        setCommunityLeaderboard(detailsRows);
        setIsLoading(false);
      } catch (err) {
        console.log({ err });
        setIsLoading(true);
      }
    }
    getLeaderboard()
  }, [communityId]);

  const onScroll = async () => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        // Contributors list lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreContributors && !noMoreContributors) {
          const pageCount = page + 1;
          // if we are not already loading more Contributors, load more Contributors
          setIsLoadingMoreContributors(true);
          try {
            const leaderboardData =
              await CommunityService.getLeaderboardV2Paginated(
                communityId,
                pageCount,
                recordLimit
              );
            if (leaderboardData.leaderboard.length === 0) {
              setNoMoreContributors(true);
            } else {
              const detailsRows = leaderboardData.leaderboard.map(
                (row, index) => {
                  return {
                    rank: index < 9 ? `0${index + 1}` : index + 1,
                    id: row.user.id,
                    // communityRole: row.communityRole,
                    grandTotal: row.karmaPoints.grandTotal || 0,
                    commentReply: row.karmaPoints["comment-reply"] || 0,
                    postComment: row.karmaPoints["post-comment"] || 0,
                    postCreate: row.karmaPoints["post-create"] || 0,
                    postLike: row.karmaPoints["post-like"] || 0,
                    user: row.user,
                    userCopy: row.user,
                    karmaPoints: row.karmaPoints,
                    customPoints: row.karmaPoints["admin-assigned"] ?? 0,
                  };
                }
              );
              detailsRows = assignRankToMember(communityLeaderboard, detailsRows);
              setCommunityLeaderboard(detailsRows);
              setPage(pageCount);
            }
            setIsLoadingMoreContributors(false);
          } catch (error) {
            console.error({ error });
            console.log(error);
            setIsLoading(false);
            setIsLoadingMoreContributors(false);
          }
        }
      }
    }
  };

  function assignRankToMember(list1, list2) {
    let list = [...list1, ...list2];
    const detailsRows = list.map((row, index) => {
      return {
        ...row,
        rank: index < 9 ? `0${index + 1}` : index + 1,
      };
    });

    return detailsRows;
  }

  function assignRankToMember(list1) {
    let list = [...list1];
    const detailsRows = list.map((row, index) => {
      return {
        ...row,
        rank: index < 9 ? `0${index + 1}` : index + 1,
      };
    });

    return detailsRows;
  }

  const columns = [
    {
      Header: "RANK",
      accessor: "rank",
    },
    {
      Header: "MEMBERS",
      accessor: "user",
      Cell: (props) => {
        return (
          <div>
            <Avatar
              className="cursor-pointer"
              user={props.value}
              onClick={() => {
                // open the user page
                window.open(
                  getSegmentedPath(createUserWallPageRoute(props.value.id, "activity"))
                );
              }}></Avatar>
          </div>
        );
      },
    },
    {
      Header: "KARMA POINTS",
      accessor: "grandTotal",
    },
    {
      Header: "CUSTOM POINTS",
      accessor: "customPoints",
    },
    // {
    //   Header: "FOLLOW",
    //   accessor: "follow",
    // },
    {
      Header: "COMMENTS",
      accessor: "postComment",
    },
    {
      Header: "UPVOTES",
      accessor: "postLike",
    },
    {
      Header: "REPLIES",
      accessor: "commentReply",
    },
    {
      Header: "POSTS",
      accessor: "postCreate",
    },
    {
      Header: "",
      accessor: "userCopy",
      Cell: (props) => {
        return (
          <div>
            {["admin"].includes(community.myRole) && (
              <IconMenu2
                actions={[
                  {
                    icon: "plus",
                    label: "Add custom points",

                    onClick: (e) => {
                      setCustomPointUser(props.value);
                      setIsAddPoints(true);
                      setActive(true);
                    },
                  },
                  {
                    // change user role
                    icon: "minus",
                    label: "Subtract custom points",

                    onClick: (e) => {
                      setCustomPointUser(props.value);
                      setIsAddPoints(false);
                      setActive(true);
                    },
                  },
                ]}
                hideOnEmpty={false}
              />
            )}
          </div>
        );
      },
    },
  ];
  function onUpdateActivity(contributor) {
    // update contributors array
    const tempContributors = [...communityLeaderboard];
    const index = tempContributors.findIndex(
      (p) => p.user.id === contributor.user.id
    );
    if (index >= 0) {
      tempContributors[index] = contributor;
      tempContributors.sort((a, b) => b.grandTotal - a.grandTotal);
      const detailsRows = assignRankToMember(tempContributors);
      setCommunityLeaderboard(detailsRows);
    }
  }

  return (
    <PremiumFeature isAvailable={communityPac.leaderboard} hideFeature>
      <div
        className="theme-bg-surface rounded LeaderboardPage-Container"
        style={{ height: "100%" }}
        onScroll={() => {
          console.log("Something went wrong!");
        }}>
        <div className="pt-4 pb-2" style={{ height: "50px" }}>
          <span className="text-2xl font-medium pl-4 theme-text-heading-1">
            Leaderboard
          </span>
        </div>
        <div
          className="LeaderboardPage  theme-bg-surface px-2 pt-2 theme-text-default overflow-y-scroll"
          style={{ height: "Calc(100% - 50px)" }}>
          {/* <Table columns={columns} data={communityLeaderboard} /> */}
          <span className="theme-text-subtitle-2 text-xs">
            Click on column header for sorting{" "}
          </span>
          {isLoading ? (
            <div
              style={{
                height: fullHeight
                  ? "Calc(100% - 50px)"
                  : "calc(100vh - 200px)",
              }}>
              <Loader />
            </div>
          ) : (
            <>
              <div
                className="TABLE overflow-y-auto"
                ref={innerRef}
                onScroll={onScroll}
                // style={{ height: "500px" }}
                style={{
                  height: fullHeight
                    ? "Calc(100% - 50px)"
                    : "calc(100vh - 200px)",
                }}>
                <Suspense fallback={<></>}>
                  <Table
                    theadClassName="theme-bg-default"
                    columns={columns}
                    data={communityLeaderboard}
                  />
                </Suspense>
              </div>
            </>
          )}

          {isLoadingMoreContributors && <Loader />}
        </div>
      </div>
      {customPointUser && (
        <AlterCustomPointsModal
          addToast={addToast}
          active={active}
          setActive={setActive}
          contributor={
            communityLeaderboard.filter((c) => c.id === customPointUser.id)[0]
          }
          isAddPoints={isAddPoints}
          community={community}
          group={undefined}
          onUpdateActivity={onUpdateActivity}
        />
      )}
    </PremiumFeature>
  );
}

const LeaderboardPage = withToast(
  connect(
    (s) => ({
      community: s.community,
      communityPac: s.communityPac,
      user: s.auth,
    }),
    (d) =>
      bindActionCreators(
        {
          updateCommunity: setCommunity,
        },
        d
      )
  )(LeaderboardPageComponent)
);

export { LeaderboardPage };
