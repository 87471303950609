import React from "react";

/**
 * @description Catch JavaScript errors anywhere in their child component tree, log those errors, and display a fallback UI
 * @param {Element} children - Child component to catch errors in
 * @param {Element} fallback - Fallback UI to display if an error occurs
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      //  Custom fallback UI
      if (this.props.fallback) {
        return this.props.fallback;
      } else {
        return <h1>Something went wrong.</h1>;
      }
    }

    return this.props.children;
  }
}
