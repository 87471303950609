export const esSpanish = {
  "Home Page Left column": "Página de inicio Columna izquierda",
  Home: "Hogar",
  "Explore Groups": "Explorar grupos",
  Events: "Eventos",
  Groups: "Grupos",
  Links: "Enlaces",
  "Direct Message": "Mensaje directo",
  "Powered by": "Energizado por",
  "No groups here, please use + to create a new one, or join one see them here.":
    "No hay grupos aquí, use + para crear uno nuevo o únase a uno, verlos aquí.",
  "Please join a group to see them here": "Únase a un grupo para verlos aquí",
  "Channel can only be reordered within the group!":
    "¡La sección solo se puede reordenar dentro del grupo!",
  "Group Page": "Página de grupo",
  "Invite Members": "Invitar a los miembros",
  Join: "Unirse",
  Joined: "Unido",
  "Create new Channel": "Crear nueva sección",
  "Group Setting drop down": "Desenfato de ajuste grupal",
  Share: "Cuota",
  "Edit group": "Grupo de edición",
  "Delete group": "Grupo de eliminación",
  Leave: "Abandonar",
  "Are you sure you want to delete the group?":
    "¿Estás seguro de que quieres eliminar el grupo?",
  Cancel: "Cancelar",
  "Yes, Go Ahead": "Sí adelante",
  "Share via link": "Compartir a través del enlace",
  Copy: "Copiar",
  Copied: "Copiado",
  "Share on social media": "Compartir en las redes sociales",
  "Add Channel": "Agregar sección",
  "Channel Header": "Encabezado de sección",
  Live: "Vivir",
  "Create Post": "Crear publicación",
  "Notificaiton Bell": "Notificaiton Bell",
  "All posts": "Todos los mensajes",
  "Admin posts only": "Solo publicaciones de administración",
  None: "Ninguna",
  "Channel Setting drop down": "SECCIÓN DESPLAYO DESPLAY",
  "Edit Channel": "Sección de edición",
  "Embed Channel": "Sección de incrustaciones",
  "Delete Channel": "Eliminar sección",
  "Are you sure you want to delete the Channel?":
    "¿Estás seguro de que quieres eliminar la sección?",
  "Channel deleted!": "Sección eliminada!",
  "Create Group Pop up / Update":
    "Crear grupo emergente / actualización del grupo",
  "Create group": "Crea un grupo",
  "Basic Detail": "Detalle básico",
  Settings: "Ajustes",
  "Size 16:9 or 1600 by 900": "Tamaño 16: 9 o 1600 por 900",
  "Group name": "Nombre del grupo",
  "Enter group name": "Ingrese el nombre del grupo",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "Sugerencias: espacios, canales, grupos, blog, base de conocimiento, etc.",
  "Group Description": "Descripción del Grupo",
  "Enter description here": "Ingrese la descripción aquí",
  "Group Type": "Tipo de grupo",
  Open: "Abierto",
  "Visible to all & anyone can join":
    "Visible para todos y cualquiera puede unirse",
  Closed: "Cerrado",
  "User will have to request to join the group":
    "El usuario tendrá que solicitar unirse al grupo",
  Paid: "Pagado",
  "Group Price": "Precio grupal",
  "Currency (Default: INR)": "Moneda (predeterminado: INR)",
  "User will have to pay to join the group":
    "El usuario tendrá que pagar para unirse al grupo",
  "Make this a private group": "Haz de este un grupo privado",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "El grupo privado solo será visible para los miembros que forman parte de él. El administrador puede agregar miembros manualmente de la lista de miembros",
  "Upload from device": "Cargar desde el dispositivo",
  "Search image": "Imagen de búsqueda",
  "Valid group name required!": "¡Se requiere nombre de grupo válido!",
  "Update Group": "Grupo de actualización",
  "SEO Settings": "Configuración de SEO",
  "Custom URL": "URL personalizada",
  "Meta Title": "Meta título",
  "Maximum length of 255 characters allowed":
    "Longitud máxima de 255 caracteres permitidos",
  "Meta Description": "Metadescripción",
  "Open Graph Detail": "Abrir detalle del gráfico",
  "Open Graph Title": "Título del gráfico abierto",
  "Open Graph Description": "Descripción del gráfico abierto",
  "Open Graph Image": "Imagen de gráfico abierto",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "Dimensiones recomendadas: 1200px por 630px con una relación de aspecto 1.91: 1.",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "Si no se usa URL, elegirá automáticamente su miniatura de grupo.",
  "Create Channel Pop up / Update": "Crear sección emergente / actualización",
  "Create Channel": "Crear sección",
  "Channel Name": "Nombre de la sección",
  "Suggestions: General, Introductions, Community Guidelines":
    "Sugerencias: General, Introducciones, Directrices de la comunidad",
  "Channel Type": "Tipo de sección",
  "Post Type": "Tipo de mensaje",
  "Group Chat": "Grupo de chat",
  "Who can post in this Channel?": "¿Quién puede publicar en esta sección?",
  "Only Admin": "Solo administrador",
  "Any user": "Cualquier usuario",
  "Sorting order for Channel": "Orden de clasificación para la sección",
  "Latest first": "Último primero",
  "Oldest post first": "Publicación más antigua primero",
  "Channel Appearance": "Apariencia de sección",
  "List View": "Vista de la lista",
  "Grid View": "Vista en cuadrícula",
  "Make this closed Channel": "Haga esta sección cerrada",
  "Members will have to request access to see Channel content":
    "Los miembros deberán solicitar acceso para ver el contenido de la sección",
  "Make this a private Channel": "Haga de esta una sección privada",
  "Disables requests (Make sure closed Channel is selected as well).":
    "Desactiva las solicitudes (asegúrese de que se seleccione la sección cerrada).",
  "Post Editor": "Editor de la publicación",
  "Create new post in": "Crear una nueva publicación en",
  "Add Cover Picture": "Agregar imagen de portada",
  "Heading (Optional)": "Encabezado (opcional)",
  "Write here..": "Escriba aqui..",
  Picture: "Imagen",
  Video: "Video",
  Attachment: "Archivo adjunto",
  Poll: "Encuesta",
  "Embed Code": "Código de inserción",
  Tags: "Etiquetas",
  "Toggle to question": "Alternar para cuestionar",
  "Poll expire time": "Tiempo de expirar la encuesta",
  "Poll question": "Pregunta de la encuesta",
  "Enter Question": "Ingrese la pregunta",
  Question: "Pregunta",
  Option: "Opción",
  Options: "Opción",
  "Add Option": "Añadir opción",
  "Poll Expiry time": "Tiempo de vencimiento de la encuesta",
  "12 Hours": "12 horas",
  "1 Day": "1 día",
  "2 Days": "2 días",
  "1 Week": "1 semana",
  "2 Weeks": "2 semanas",
  "Always Open": "Siempre abierto",
  "Write embeddable code": "Escribir código incrustable",
  "Click here to add embed code.":
    "Haga clic aquí para agregar código de incrustación.",
  "Paste your embed code here!": "¡Pega tu código de incrustación aquí!",
  "Post Settings": "Configuración de publicación",
  "Update Post": "Publicación de actualización",
  Update: "Actualizar",
  "Start meeting / Video call / Live":
    "Comience a reuniones / videollamadas / en vivo",
  "Meeting Name": "Nombre de reunión",
  "Suggestion: Group discussion etc": "Sugerencia: discusión grupal, etc.",
  Description: "Descripción",
  "Enter description here (optional)": "Ingrese la descripción aquí (opcional)",
  "When do you want to start the session":
    "¿Cuándo quieres comenzar la sesión?",
  "Right now": "En este momento",
  "Schedule for later": "Programar para más tarde",
  "Group video call": "Videollamada grupal",
  Webinar: "Seminario web",
  "Go Live": "Ir a vivir",
  "Info on the Post": "Información sobre la publicación",
  "Post comment": "Publicar comentario",
  Comment: "Comentario",
  Comments: "Comentario",
  Reply: "Respuesta",
  Replies: "Respuestas",
  "Share via URL": "Compartir a través de URL",
  "Reshare in other Channels": "Remodelar en otras secciones",
  "Post Card Drop Down": "Post Card Downward",
  Bookmark: "Marcador",
  "Pin post": "Postal",
  Edit: "Editar",
  "Embed Post": "Incrustación",
  Delete: "Borrar",
  "Remove Pin": "Quitar el alfiler",
  "Remove Bookmark": "Eliminar marcador",
  "Just copy paste this code wherever you want it to be embedded":
    "Simplemente copie Pegue este código donde quiera que esté incrustado",
  Height: "Altura",
  Preview: "Avance",
  "Copied to clipboard": "Copiado al portapapeles",
  "Select Widgets": "Seleccionar widgets",
  "Add widgets to": "Agregar widgets a",
  "You can change their order by dragging & dropping the widgets over each other":
    "Puede cambiar su pedido arrastrando y dejando caer los widgets uno sobre el otro",
  "Please note: Widget wont be displayed if there is no data available, ":
    "Tenga en cuenta: el widget no se mostrará si no hay datos disponibles,",
  "Group Members": "Miembros del grupo",
  "Group Leaderboard": "Tabla de clasificación grupal",
  "Community Leaderboard": "Tabla de clasificación comunitaria",
  "Trending Posts": "Puestos de tendencia",
  "Group Events": "Eventos grupales",
  "Add Custom": "Agregar personalizado",
  "Save Widgets": "Guardar widgets",
  "Visible to user": "Visible para el usuario",
  "Visible to admin": "Visible para administrador",
  "Add widget to": "Agregar widget a",
  "Upload cover picture": "Imagen de portada de carga",
  "1:1 aspect ration (recommended)": "Ración de aspecto 1: 1 (recomendado)",
  "Card Title": "Título de tarjeta",
  "Enter widget name": "Ingrese el nombre del widget",
  "Small Description (try to keep it under 50 words)":
    "Pequeña descripción (intente mantenerlo menos de 50 palabras)",
  "Enter description": "Ingrese la descripción",
  "Button Title": "Título de botón",
  "Enter button name": "Ingrese el nombre del botón",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestion: Conozca más, unirse, aplicar, etc.",
  "URL link": "Enlace URL",
  "Create custom widget": "Crear widget personalizado",
  "Custom widget created successfully":
    "Widget personalizado creado correctamente",
  Widgets: "Widgets",
  Members: "Miembros",
  "See All": "Ver todo",
  "Communtiy Top Contributors": "Los principales contribuyentes comunes",
  "Community Members": "Miembros de la comunidad",
  "Group Top Contributors": "Colaboradores de los mejores grupos",
  "Upcoming Events": "Próximos Eventos",
  "Home Page Banner": "Banner de la página de inicio",
  "Edit Banner": "Banner de edición",
  "Home page Edit Banner Pop up": "Página de inicio Editar banner",
  "Update Banner": "Banner de actualización",
  Search: "Búsqueda",
  "Upload Image": "Cargar imagen",
  Remove: "Remover",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "Imagen de banner (use la imagen de 16: 6 o 1600px por 600px para la mejor vista)",
  "Banner Title": "Título de banner",
  "Enter here": "Entre aquí",
  "Suggestion: Welcome to my community etc":
    "Sugerencia: Bienvenido a mi comunidad, etc.",
  "Banner Description": "Descripción del banner",
  "Enter banner description": "Ingrese la descripción del banner",
  "Save Banner Info": "Guardar información banner",
  "Home Page Feed": "Feed de la página de inicio",
  Recent: "Reciente",
  "Top Posts": "Publicaciones principales",
  Trending: "Tendencias",
  "Home Feed Groups": "Grupos de alimentación casera",
  "Header BarProfile Drop down": "Encabezado barprofile desplegable",
  "My Profile": "Mi perfil",
  Logout: "Cerrar sesión",
  Notification: "Notificación",
  Notifications: "Notificaciones",
  "Mark all viewed": "Marque todo visto",
  "has created a post": "ha creado una publicación",
  "wants to join": "Quiere unirse",
  "has commented on your post": "ha comentado en tu publicación",
  "has replied to your comment": "ha respondido a tu comentario",
  "Search for tags": "Buscar etiquetas",
  "All groups": "Todos los grupos",
  "Search result": "Resultado de búsqueda",
  "No post found for selected hashtag":
    "No se encuentra una publicación para el hashtag seleccionado",
  "Start a conversation": "Iniciar una conversación",
  "Search by name": "Buscar por nombre",
  Upcoming: "Próximo",
  UPCOMING: "Próximo",
  Past: "Pasado",
  PAST: "PASADO",
  "No upcoming events": "Sin eventos próximos",
  "Create event popup": "Crear ventana emergente de eventos",
  "Event details": "Detalles del evento",
  "16:9 aspect ratio": "Relación de aspecto 16: 9",
  "Event name": "Nombre del evento",
  "Enter event name": "Ingrese el nombre del evento",
  "Host Name": "Nombre de host",
  "You will be made host if none selected":
    "Se lo hará host si ninguno seleccionado",
  "Write here...": "Escriba aqui...",
  "No. of registration for the event": "No. de registro para el evento",
  Unlimited: "Ilimitado",
  "When & where": "Cuando donde",
  From: "De",
  "Start date": "Fecha de inicio",
  time: "tiempo",
  To: "A",
  "End date": "Fecha final",
  "Current time zone: ": "Zona horaria actual:",
  "Can be updated from the communtiy setting":
    "Se puede actualizar desde la configuración de comunicación",
  "Online event": "Evento en línea",
  "Add meeting link": "Agregar enlace de reunión",
  "Meeting link will be generated once event is created":
    "El enlace de reunión se generará una vez que se cree el evento",
  Venue: "Evento",
  "Enter address": "Ingresa la direccion",
  Create: "Crear",
  Invite: "Invitar",
  "Share event via link": "Evento de compartir a través del enlace",
  "Copy ": "Copiar",
  "Select Channel to post this event":
    "Seleccionar sección para publicar este evento",
  "Once posted, members of that group or Channel will recieve an notification":
    "Una vez publicado, los miembros de ese grupo o sección recibirán una notificación",
  Post: "Correo",
  RSVP: "RSVP",
  "Book Event": "Evento de libros",
  "Yes, I'll attend": "Si, asistiré",
  "May be": "Quizás",
  "No, I won't attend": "No, no asistiré",
  "Date & Time": "Fecha y hora",
  Host: "Anfitrión",
  "Online meeting": "Reunión en línea",
  "Meeting link will be visible once registered for the event":
    "El enlace de reunión será visible una vez registrado para el evento",
  "User Management": "Gestión de usuarios",
  "Go bac to community": "Ir a la comunidad",
  "Manage Members": "Administrar miembros",
  Member: "Miembro",
  Invited: "Invitado",
  "Search for member": "Buscar miembro",
  "Add to group": "Añadir al grupo",
  "Send Direct Message": "Enviar mensaje directo",
  "Change role": "Cambiar de rol",
  "Kick from community": "Patada de la comunidad",
  "Super Admin": "Super administrador",
  "Super admin will have full access to the communtiy settings":
    "Super Admin tendrá acceso completo a la configuración de Communtiy",
  "Group Admin": "Administrador del grupo",
  "Admin of the selected group can edit the group information and create, edt or delete Channel under selected group":
    "El administrador del grupo seleccionado puede editar la información del grupo y crear, EDT o eliminar sección en el grupo seleccionado",
  "Group Moderator": "Moderador de grupo",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "El moderador del grupo puede crear o eliminar publicaciones, comentarios, responder y mensajes en el grupo seleccionado.",
  User: "Usuario",
  Save: "Ahorrar",
  "User role updated successfully": "Rol de usuario actualizado con éxito",
  Leaderboard: "Tabla de clasificación",
  "Click on the column header for sorting":
    "Haga clic en el encabezado de la columna para clasificar",
  Rank: "Rango",
  "Karma Points": "Puntos de karma",
  "Custom Points": "Puntos personalizados",
  Follow: "Seguir",
  Upvotes: "Votos",
  Posts: "Postes",
  "Add custom points": "Agregar puntos personalizados",
  "Subtract custom points": "Restar puntos personalizados",
  "Enter no. of points": "Ingrese no. de puntos",
  "Add points": "Añadir puntos",
  "Invite members to": "Invitar a los miembros a",
  "Invite via link": "Invitar a través de un enlace",
  "Invite via email": "Invitar por correo electrónico",
  "Add more": "Añadir más",
  "Upload a CSV": "Subir un CSV",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "Cargue un archivo CSV de compra de comas, con encabezado como nombre, correo electrónico.",
  "Here is a template CSV file": "Aquí hay un archivo CSV de plantilla",
  "Upload File": "Subir archivo",
  "Send Invite": "Enviar invitación",
  "Mail Template": "Plantilla de correo",
  "Send Test Mail": "Enviar correo de prueba",
  Hey: "Oye",
  "A has invite you to join the B": "A te ha invitado a unirte a la B",
  "Switch to rich text editor": "Cambiar al editor de texto rico",
  "Add Custom Message": "Agregar mensaje personalizado",
  "Enter message here": "Ingrese el mensaje aquí",
  "Send Bulk Mail": "Enviar correo a granel",
  "Send Mail": "Enviar correo",
  "Send bulk mail to all community members or select group members":
    "Enviar correo a granel a todos los miembros de la comunidad o seleccionar miembros del grupo",
  Date: "Fecha",
  Subject: "Tema",
  Recipients: "Receptores",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "1000 correos electrónicos a granel restantes para este mes. Restablecer en <Date>",
  Everyone: "Todo el mundo",
  "Enter mail subject": "Ingrese el tema del correo",
  "Write message": "Escribe un mensaje",
  "Cancel ": "Cancelar",
  Send: "Enviar",
  "Profile Page": "Página de perfil",
  Activity: "Actividad",
  Bookmarks: "Marcadores",
  "Edit Profile": "Editar perfil",
  Unsubscribe: "Cancelar la suscripción",
  "Settings (Account)": "Configuración (cuenta)",
  Profile: "Perfil",
  "Set profile Picture": "Establecer una foto de perfil",
  "Your name": "Su nombre",
  "User ID": "ID de usuario",
  "Short bio": "Bio",
  "Link your social account, this will be displayed on your profile":
    "Enlace su cuenta social, esto se mostrará en su perfil",
  "Add social handle": "Agregar mango social",
  Verification: "Verificación",
  "Account Verification": "Verificación de la cuenta",
  Email: "Correo electrónico",
  "Enter email": "Ingrese correo electrónico",
  Verified: "Verificado",
  Unverified: "Inconfirmado",
  Change: "Cambio",
  Unlink: "Desconectar",
  Mobile: "Móvil",
  "enter mobile no.": "Ingrese móvil no.",
  "Request OTP": "Solicitar OTP",
  "Notification centre": "Centro de notificación",
  "Manage your personal account notification preferences":
    "Administre las preferencias de notificación de su cuenta personal",
  Prefrences: "Prefrencias",
  "Email notification": "Notificación de correo electrónico",
  "Notify me about comments on my posts":
    "Notificarme sobre los comentarios en mis publicaciones",
  "Notify me about replies on my comments":
    "Notificarme sobre las respuestas sobre mis comentarios",
  "Notify me about upvotes on my posts":
    "Notifíqueme sobre los votos en mis publicaciones",
  "Notify me about reaction on my comments":
    "Notificarme sobre la reacción en mis comentarios",
  "Notify me about reaction on my reply":
    "Notificarme sobre la reacción en mi respuesta",
  "Notify me about direct message": "Notificarme sobre el mensaje directo",
  "Notify me about my mentions": "Notificarme sobre mis menciones",
  "Notification for new posts": "Notificación para nuevas publicaciones",
  "Only group & Channel available which you are part of":
    "Solo grupo y sección disponible en la que forma parte",
  "Group & Channel": "Grupo y sección",
  "All activity": "Toda actividad",
  "Post by admin": "Publicado por admin",
  "No Notification": "Sin notificación",
  "Setting (Community)": "Escenario (comunidad)",
  "General Settings": "Configuración general",
  "Upload community logo": "Subir el logotipo de la comunidad",
  "Community name": "Nombre de la comunidad",
  "Invalid field": "Campo no válido",
  "Your community address": "Su dirección comunitaria",
  "Fav icon": "Icono favorito",
  "This will be visible on the browser tab (32px by 32px)":
    "Esto será visible en la pestaña del navegador (32px por 32px)",
  TimeZone: "Zona horaria",
  "Privacy Policy on login page":
    "Política de privacidad en la página de inicio de sesión",
  "Terms & Condition on login page":
    "Términos y condición en la página de inicio de sesión",
  "Enter link here": "Ingrese el enlace aquí",
  "Open Group Image": "Imagen de grupo abierto",
  "By default ir takes up the community banner uploaded with the communtiy":
    "Por defecto, el IR toma el banner de la comunidad cargado con el communtiy",
  "Community Experience": "Experiencia comunitaria",
  Onboarding: "Inducción",
  "Make your community private": "Haz que tu comunidad sea privada",
  "Users will have to login or sign up to access the community":
    "Los usuarios deberán iniciar sesión o registrarse para acceder a la comunidad",
  "Make account verification mandatory for both email & mobile":
    "Haga que la verificación de la cuenta sea obligatoria para correo electrónico y móvil",
  "New user will have to verify both email & mobile no. to access the community":
    "El nuevo usuario tendrá que verificar el correo electrónico y el móvil no. Para acceder a la comunidad",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "El administrador también tendrá que verificar su móvil no. y correo electrónico o no podrán acceder a la comunidad",
  "Gated community access": "Acceso comunitario cerrado",
  "Admin will have to approve or reject user's requests to join the community":
    "El administrador tendrá que aprobar o rechazar las solicitudes del usuario para unirse a la comunidad",
  "Disable mobile login/register":
    "Deshabilitar el inicio de sesión/registro móvil",
  "Disable email login/register":
    "Deshabilitar el inicio de sesión/registro de correo electrónico",
  "Disable google login/register":
    "Deshabilitar el inicio de sesión/registro de Google",
  "Disable facebook login/register":
    "Deshabilitar el inicio de sesión/registro de Facebook",
  "Show & Hide features": "Show & Hide Características",
  "Hide direct chat": "Ocultar chat directo",
  "User will not be able to send direct messages to each other":
    "El usuario no podrá enviarse mensajes directos entre sí",
  "Hide profile picture from top right corner for mobile responsive view":
    "Ocultar una imagen de perfil desde la esquina superior derecha para una vista de respuesta móvil",
  "Useful when using community in an in app browser":
    "Útil cuando se usa la comunidad en un navegador en aplicaciones",
  "Hide profile picture from top right corner in every view":
    "Ocultar una imagen de perfil desde la esquina superior derecha en cada vista",
  "Disable header dropdown on profile picture":
    "Desactivar el desplegable de encabezado en la foto de perfil",
  "Useful for integrated community, especially using SSO":
    "Útil para la comunidad integrada, especialmente el uso de SSO",
  "Hide the communtiy logo from the header":
    "Esconde el logotipo de la comunicación del encabezado",
  "Hide the communtiy title from the header":
    "Esconde el título de la comunicación del encabezado",
  "Show collpased group in the side nav bar":
    "Mostrar grupo colpicado en la barra de navegación lateral",
  "Show full expanded post in the home feed":
    "Mostrar publicación amplia completa en la alimentación casera",
  "Hide the leaderboard score from the header":
    "Ocultar el puntaje de la tabla de clasificación del encabezado",
  "Show powered by Pensil": "Espectáculo impulsado por Pensil",
  "Hide closed groups if not joined": "Ocultar grupos cerrados si no se une",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "Los grupos cerrados no se mostrarían al usuario si no son miembros del grupo",
  "Hide Community subscriber count from the users":
    "Ocultar el recuento de suscriptores de la comunidad de los usuarios",
  "This will hide the community subscriber list in the community header":
    "Esto ocultará la lista de suscriptores de la comunidad en el encabezado de la comunidad",
  "Disable Communtiy Switcher": "Deshabilitar el conmutador de communty",
  "Use MD editor for create post description":
    "Use el editor MD para crear una descripción de la publicación",
  "Non logged in user will land on explore group page instead of post directory":
    "El usuario no registrado aterrizará en la página del grupo Explore en lugar del directorio post",
  "Advanced configurable": "Configurable avanzado",
  "Communtiy default country code":
    "Código de país predeterminado de Communtiy",
  "This will show up by default on login/signup":
    "Esto aparecerá de manera predeterminada en el inicio de sesión/registro",
  "Onboarding/Welcome Message": "Mensaje de incorporación/bienvenida",
  "Welcome Message": "Mensaje de bienvenida",
  "Greet your members with personalized message":
    "Salude a sus miembros con un mensaje personalizado",
  "Welcome to": "Bienvenido a",
  Message: "Mensaje",
  "Add message": "Agregar mensaje",
  "Custom domain": "Dominio personalizado",
  "Add custom domain": "Agregar dominio personalizado",
  "Create new request": "Crear nueva solicitud",
  "Enter your custom domain": "Ingrese su dominio personalizado",
  "Setup details": "Detalles de configuración",
  "Add a CNAME record in your domain pointing to this community's address":
    "Agregue un registro CNAME en su dominio que señale la dirección de esta comunidad",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "Para configurar su dominio, deberá agregar un registro CNAME que apunta a su dominio a la URL de su comunidad Pensil",
  "Step by Step help apge": "Paso a paso Ayuda APGE",
  "All request list": "Toda la lista de solicitudes",
  "Allow admin to assign/un-assign custom points to users":
    "Permitir que el administrador asigne/no asignar puntos personalizados a los usuarios",
  "Base points for user to begin with":
    "Puntos base para que el usuario comience con",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "Los puntos base se actualizarán para todos los usuarios, cualquier cambio se reflejará para todos los usuarios.",
  "Allocate points for interaction": "Asignar puntos para la interacción",
  "Upvoting post": "Publicación de votación",
  "creating a comment": "Creando un comentario",
  "creating a reply": "Creando una respuesta",
  "Getting upvote to their post": "Obtener votos a su publicación",
  "Getting comment to their post": "Hacer comentarios en su publicación",
  "Getting reply to their comment": "Recibiendo respuesta a su comentario",
  "Creating a post": "Creando una publicación",
  Visiblity: "Visible",
  "Move Up": "Ascender",
  "Move Down": "Mover hacia abajo",
  "Create embedded Channel": "Crear sección incrustada",
  "Preview area": "Área previa",
  "Theme selector": "Selector de temas",
  "Community Theme": "Tema de la comunidad",
  "Select theme": "Seleccione el tema",
  "Select brand color": "Seleccionar el color de la marca",
  "Customize your button color to match your brand":
    "Personalice el color de su botón para que coincida con su marca",
  "Select on button text color": "Seleccionar el color del texto del botón",
  "Suggestion: Keep inverted contrast against brand color":
    "Sugerencia: Mantenga el contraste invertido con el color de la marca",
  Billing: "Facturación",
  "Current plan": "Plan actual",
  "You are current on Free PLan": "Estás actualizado en el plan gratuito",
  "Renew on: NA": "Renovar: NA",
  Select: "Seleccione",
  "Email us at": "Envíanos un email a",
  "Compare plan": "Plan de comparación",
  "API Keys": "Llaves de API",
  "Create new key": "Crear nueva clave",
  "Total keys": "Total llaves",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "¡Mantenga sus llaves API seguras! Puede usarlos para acceder a la característica específica y dejar que Cetain Apps busque ciertas acciones en su nombre en la plataforma.",
  "Key name": "Nombre clave",
  "Key value": "Valor clave",
  Action: "Acción",
  "Group updated!": "Grupo actualizado!",
  "Are you sure you want to": "Estás seguro que quieres",
  "Make Moderator": "Hacer moderador",
  "Remove from group": "Sacar del grupo",
  "Show All": "Mostrar todo",
  "Change Role": "Cambiar de rol",
  Close: "Cerca",
  copy: "Copiar",
  LINKS: "ENLACES",
  "Email Id": "Identificación de correo",
  Name: "Nombre",
  "Search for an image": "Buscar una imagen",
  "Crop Image": "Delimitar imagen",
  "No photos found": "No se encontraron fotos",
  Community: "Comunidad",
  "Banner ratio - 16:9": "Relación de banner - 16: 9",
  "Banner Ratio - 16:6": "Relación de banner - 16: 6",
  "Share on mail": "Compartir por correo",
  "Share on Telegram": "Compartir en telegrama",
  "Share on Whatsapp": "Compartir en WhatsApp",
  "Share on Linkedin": "Compartir en LinkedIn",
  "Share on Facebook": "Compartir en Facebook",
  "Share on Twitter": "Compartir en Twitter",
  "Pin Post": "Postal",
  "Heading (optional)": "Encabezado (opcional)",
  "Select Channel": "SELECCIÓN SECCIÓN",
  Gif: "Gif",
  "Create Custom Widget": "Crear widget personalizado",
  "Are you sure you want to delete this widget?":
    "¿Estás seguro de que quieres eliminar este widget?",
  "Go to event": "Ir al evento",
  "Add Tags": "Agregar etiquetas",
  "Create Tag": "Crear etiqueta",
  "Title (Optional)": "Título (opcional)",
  "Search Gif": "Buscar gif",
  "What's on your mind": "Qué tienes en mente",
  "Looks like nothing available here right now.":
    "Parece nada disponible aquí en este momento.",
  "Try exploring other tabs": "Intenta explorar otras pestañas",
  "Delete Widget": "Eliminar widget",
  "Please select a Channel to post": "Seleccione una sección para publicar",
  "Post added successfully!": "¡Publicación agregada con éxito!",
  "Failed to create post. Please check post content and retry again.":
    "No se pudo crear publicaciones. Consulte el contenido de la publicación y vuelva a intentarlo.",
  "Are you sure you want to discard current post?":
    "¿Estás seguro de que quieres descartar la publicación actual?",
  "By default it will take up the image uploaded with the post":
    "Por defecto, tomará la imagen cargada con la publicación",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "¡El cuestionario solo se puede ver e intentar desde el dispositivo móvil en este momento!",
  "Community Banner": "Bandera de la comunidad",
  "Change Cover": "Cubierta de cambio",
  "Suggestions: Welcome to my community etc":
    "Sugerencias: Bienvenido a mi comunidad, etc.",
  "(edited)": "(Editado)",
  "Aspect ratio (recommended)": "Relación de aspecto (recomendado)",
  "Banner details updated!": "¡Detalles de banner actualizados!",
  "Refresh if changes are not visible!":
    "¡Actualice si los cambios no son visibles!",
  "Banner details could not be updated!":
    "¡Los detalles del banner no se pueden actualizar!",
  "Post deleted": "Publicación eliminada",
  "Post not available. It may have been deleted.":
    "Publicación no disponible. Puede haber sido borrada.",
  Admin: "Administración",
  Moderator: "Moderador",
  "Bookmark added!": "¡Se agregó marcador!",
  "Bookmark removed": "Marcador eliminado",
  "Failed performing action!": "¡Falló en la acción!",
  "Copy this link to share on other platforms":
    "Copie este enlace para compartir en otras plataformas",
  "View this event": "Ver este evento",
  Event: "Evento",
  "Join Meeting": "Unirse a la reunión",
  "The live meeting has ended": "La reunión en vivo ha terminado",
  "read more...": "Lee mas...",
  "Load more comments": "Cargar más comentarios",
  "Post Reply": "Enviar respuesta",
  "Post Comment": "publicar comentario",
  more: "más",
  upvoted: "votado",
  "1 Upvote": "1 voto superior",
  "1 Comment": "1 comentario",
  "Please enter a title": "Por favor ingrese un título",
  "Please enter a url link": "Ingrese un enlace de URL",
  "Please enter button title": "Ingrese el título del botón",
  "Button label must be at least 20 characters":
    "La etiqueta del botón debe tener al menos 20 caracteres",
  "Please enter valid url": "Ingrese URL válida",
  "Suggestions: Know more, Join, Apply...":
    "Sugerencias: Conozca más, únase, aplique ...",
  URL: "Url",
  "Community Events": "Eventos comunitarios",
  "Widget deleted successfully": "Widget eliminado con éxito",
  "Community Top Contributors":
    "Los principales contribuyentes de la comunidad",
  points: "puntos",
  "Points cannot be greater than current points":
    "Los puntos no pueden ser mayores que los puntos actuales",
  "Point cannot be a negative value": "El punto no puede ser un valor negativo",
  "Point cannot be a decimal point": "El punto no puede ser un punto decimal",
  "Please enter a valid number": "por favor ingrese un número valido",
  "Karma points updated successfully": "Puntos de karma actualizados con éxito",
  "Error in updating karma points": "Error al actualizar los puntos de karma",
  "Add karma points": "Agregar puntos de karma",
  "Minus karma points": "Puntos de menos karma",
  "Total Karma points:": "Puntos totales de karma:",
  "Enter no. of karma points": "Ingrese no. de puntos de karma",
  "Minus Points": "Menos puntos",
  "Trending Post": "Puesto de tendencia",
  "No visible description": "Sin descripción visible",
  "Picture ratio: 1:1": "Relación de imagen: 1: 1",
  "home feed": "tus noticias",
  "Event name is invalid!": "¡El nombre del evento no es válido!",
  custom: "disfraz",
  "Custom widget updated successfully":
    "Widget personalizado actualizado correctamente",
  "Name invalid!": "Nombre inválido!",
  "Current timezone:": "Zona horaria actual:",
  "can be updated from community settings":
    "se puede actualizar desde la configuración de la comunidad",
  "Event created successfully": "Evento creado con éxito",
  "Error in creating event": "Error al crear evento",
  Attendees: "Asistentes",
  "Do you wish to notify these changes to attendees?":
    "¿Desea notificar estos cambios a los asistentes?",
  "users wants to attend this event":
    "Los usuarios quieren asistir a este evento",
  "Yes, Notify them": "Si, notifíqueles",
  " No, its okay": " No, está bien",
  "Do you wish to notify that event is cancelled?":
    "¿Desea notificar que el evento está cancelado?",
  "Are you sure you want to delete the event?":
    "¿Estás seguro de que quieres eliminar el evento?",
  "Delete Event": "Evento de eliminación",
  "New Event": "Nuevo evento",
  "Accept Invitation": "Aceptar la invitacion",
  Requested: "Solicitado",
  Declined: "Rechazado",
  "Request Access": "Solicitar acceso",
  "Enroll for": "Inscribir para",
  Housefull: "Casa",
  "Just copy paste this code wherever you want it to be embedded:":
    "Simplemente copie Pegue este código donde quiera que esté incrustado:",
  Ended: "Terminado",
  "Error getting events": "Error a obtener eventos",
  "Events could not be fetched!": "¡Los eventos no se pudieron buscar!",
  "Show Attendees": "Mostrar asistentes",
  Subscribers: "Suscriptores",
  "Make this a closed Channel": "Hacer de esto una sección cerrada",
  "Update Channel": "Sección de actualización",
  "Valid Channel name required!": "¡Se requiere nombre de sección válido!",
  "Enter Channel name": "Ingrese el nombre de la sección",
  "Community Admin": "Administrador de la comunidad",
  Embed: "Empotrar",
  "Posted in": "Publicado en",
  "Start a discussion": "Comience una discusión",
  "Invite Members Individually": "Invitar a los miembros individualmente",
  "Post something": "Publicar algo",
  "Invite your audience": "Invita a tu audiencia",
  "Choose Plan": "Elija el plan",
  or: "o",
  "Create a link": "Crea un enlace",
  "Update link": "Enlace de actualización",
  "Add shortcut to your most used tools":
    "Agregue el atajo a sus herramientas más utilizadas",
  "Your website, Instagram Channel, Facebook profile etc":
    "Su sitio web, canal de Instagram, perfil de Facebook, etc.",
  "Valid url required, and make sure you are not missing http:// or https://":
    "Se requiere URL válida y asegúrese de que no le falte http:// o https://",
  "Valid link name required!": "¡Se requiere nombre de enlace válido!",
  "Add link": "Añadir enlace",
  "Invite to": "Invitar a",
  "Link Name": "Nombre de enlace",
  "Link URL": "URL del enlace",
  "Could not subscribe to": "No pudo suscribirse a",
  "Subscribed to": "Suscrito a",
  "By default it will take up the community banner uploaded with the community":
    "Por defecto, tomará el banner de la comunidad cargado con la comunidad",
  "Online Event (Meeting link will be visible once registered for the event)":
    "Evento en línea (el enlace de reunión será visible una vez registrado para el evento)",
  "Created by": "Creado por",
  "Join Call": "Unir la llamada",
};
