import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";
import { BillingDetails } from "../_store/subscription/my-subscription/my-subscription.reducer";

class SubscriptionService {
  /**
   * Create a subscription
   * @param communityId
   * @param payload {name, accessibleGroups, accessibleCourses}
   */
  static async createSubscription(communityId: string, payload: any) {
    return axios
      .post(endpoints.subscription.createSubscription(communityId), payload, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update the subscription
   * @param communityId
   * @param subscriptionId
   * @param payload {name, accessibleGroups, accessibleCourses}
   */
  static async updateSubscription(
    communityId: string,
    subscriptionId: string,
    payload: any
  ) {
    return axios
      .post(
        endpoints.subscription.updateSubscription(communityId, subscriptionId),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Cancel the subscription
   * @param communityId
   */
  static async cancelSubscription(communityId: string) {
    return axios
      .delete(endpoints.subscription.cancelSubscription(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete the subscription
   * @param communityId
   * @param subscriptionId
   */
  static async deleteSubscription(communityId: string, subscriptionId: string) {
    return axios
      .delete(
        endpoints.subscription.deleteSubscription(communityId, subscriptionId),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * Get all subscriptions
   * @param communityId
   */
  static async getAllSubscriptions(communityId: string) {
    return axios
      .get(endpoints.subscription.getAllSubscriptions(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
  /**
   * Get my subscription
   * @param communityId
   */
  static async getMySubscription(communityId: string) {
    return axios
      .get(endpoints.subscription.getMySubscription(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
  /**
   * Get all subscriptions for admin
   * @param communityId
   */
  static async getAllSubscriptionsAdmin(communityId: string) {
    return axios
      .get(endpoints.subscription.getAllSubscriptionsAdmin(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get active subscriptions for a subscription
   * @param communityId
   * @param subscriptionId
   */
  static async getActiveSubscriptionsForSubscription(
    communityId: string,
    subscriptionId: string
  ) {
    return axios
      .get(
        endpoints.subscription.getActiveSubscriptionsForSubscription(
          communityId,
          subscriptionId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get previous subscriptions for a subscription
   * @param communityId
   * @param subscriptionId
   */
  static async getPreviousSubscriptionsForSubscription(
    communityId: string,
    subscriptionId: string
  ) {
    return axios
      .get(
        endpoints.subscription.getPreviousSubscriptionsForSubscription(
          communityId,
          subscriptionId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get subscription details
   * @param communityId
   * @param subscriptionId
   */
  static async getSubscriptionDetails(
    communityId: string,
    subscriptionId: string
  ) {
    return axios
      .get(
        endpoints.subscription.getSubscriptionDetails(
          communityId,
          subscriptionId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get all members of a subscription
   * @param communityId
   * @param subscriptionId
   */
  static async getAllSubscriptionMembers(
    communityId: string,
    subscriptionId: string
  ) {
    return axios
      .get(
        endpoints.subscription.getAllSubscriptionMembers(
          communityId,
          subscriptionId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Get all members of a subscription
   * @param communityId
   */
  static async getAllMembershipSubscription(
    communityId: string,
    subscriptionId: string
  ) {
    return axios
      .get(
        endpoints.subscription.getAllMembershipSubscription(
          communityId,
          subscriptionId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Free subscription
   * @param communityId
   * @param subscriptionId
   */
  static async subscribeToFreePlan(
    communityId: string,
    subscriptionId: string
  ) {
    return axios
      .post(
        endpoints.subscription.freeSubscribe(communityId, subscriptionId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * Initiate subscription
   * @param communityId
   * @param subscriptionId
   */
  static async initiateSubscription(
    communityId: string,
    subscriptionId: string,
    billingDetails: BillingDetails
  ) {
    return axios
      .post(
        endpoints.subscription.initiateSubscription(
          communityId,
          subscriptionId
        ),
        { billingDetails },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Resend Invoice
   * @param communityId
   * @param invoiceId
   */
  static async resendInvoice(communityId: string, invoiceId: string) {
    return axios
      .post(
        endpoints.subscription.resendInvoice(communityId, invoiceId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * Edit invoice
   * @param communityId
   * @param userSubscriptionId
   */
  static async editInvoice(
    communityId: string,
    userSubscriptionId: string,
    billingDetails: {
      invoicePrefix: string;
      invoiceNumber: string;
    }
  ) {
    return axios
      .post(
        endpoints.subscription.editInvoice(communityId, userSubscriptionId),
        billingDetails,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}

export { SubscriptionService };
