import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class NFTService {
  static async getMyNFTs() {
    return axios
      .get(endpoints.nft.getMyNFTs, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  static async getUserNFTs(userId) {
    return axios
      .get(endpoints.nft.getUserNFTs(userId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  static async checkForGatedGroupAccess(communityId) {
    return axios
      .post(
        endpoints.nft.checkForGatedGroupAccess(communityId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}

export { NFTService };
