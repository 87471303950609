import cx from "classnames";
import { twMerge } from "tailwind-merge";
import I18 from "./atoms/i18";
import { Loader } from "./loader.component";

export function cn(...inputs) {
  return twMerge(cx(inputs));
}

function Button({
  label = "Button",
  disabled = false,
  isLoading = false,
  outlined = false,
  large = false,
  className = "",
  flat = false,
  btnColor = "",
  type = "button",
  onClick = (value) => {},
}) {
  // if (isLoading) {
  //   return <Loader />;
  // }

  let loaderColor = "var(--theme-on-primary-color)";

  let defaultState =
    "theme-bg-primary theme-border-primary theme-text-on-primary";
  if (flat) {
    defaultState =
      "theme-border-default theme-text-primary hover:theme-border-primary";
  }
  if (btnColor === "red") {
    defaultState = "theme-bg-danger theme-border-danger theme-text-on-primary";
  }
  if (btnColor === "green") {
    defaultState = "bg-green-500 border-green-500 theme-text-on-primary";
  }

  return (
    <button
      disabled={disabled}
      className={cx(
        "focus:outline-none border font-semibold  hover:shadow select-none word-breaker " +
          className,
        {
          "theme-text-primary theme-border-primary cursor-not-allowed hover:shadow-none":
            disabled && outlined,
          "theme-text-primary theme-border-primary": !disabled && outlined,
          "theme-bg-disable theme-border-default theme-text-subtitle-2 cursor-not-allowed hover:shadow-none":
            disabled && !outlined,
          [defaultState]: !disabled && !outlined,
          "py-1 px-3 rounded": !large,
          "py-2 px-6 rounded": large,
          "cursor-progress ": isLoading && !disabled,
          "cursor-progress theme-border-default": isLoading && outlined,
        }
      )}
      onClick={(e) => {
        if (isLoading || disabled) {
          return null;
        } else {
          onClick(e);
        }
      }}>
      <span className="flex items-center whitespace-normal place-content-center space-x-1">
        <span>
          {isLoading ? <Loader spinnerColor={loaderColor} size={20} /> : null}
        </span>
        <span>
          <I18>{label}</I18>
        </span>
      </span>
    </button>
  );
}

export { Button };
