import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class RoleService {
  static async getRole(communityId: string, selectedUserId: string) {
    return axios
      .get(endpoints.community.roles.getRole(communityId, selectedUserId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get the groups by user
   * @param {User} user
   */
  static async updateRole(
    communityId: string,
    selectedUserId: string,
    role: string,
    groupIds: string[]
  ) {
    return axios
      .post(
        endpoints.community.roles.updateRole(communityId),
        {
          userId: selectedUserId,
          role,
          groups: groupIds,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}

export { RoleService };
