import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

export default class LeaderboardService {
  /**
   * Add custom karma points to the leader board of a user
   * @param {String} communityId - The community id
   * @param {String} userId - The user id
   * @param  {Object} karmaPoints - karma points object. `points` is required. `groupId` is optional
   */
  static async addCustomPoints(communityId, karmaPoints, userId) {
    return axios
      .post(
        endpoints.leaderboard.addCustomPoints(communityId, userId),
        karmaPoints,
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}
