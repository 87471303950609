export function Tick({ success = false, minimal = false }) {
  return (
    <div
      className={
        "Tick " +
        (success ? "success " : "") +
        (minimal ? "minimal " : "shadow")
      }></div>
  );
}
