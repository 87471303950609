import { Course } from "../../types/course/course.type";
import { SET_COURSES, SET_COURSE_LOADING_STATUS } from "./courses.action";

export type Status = "idle" | "loading" | "success" | "error";
const defaultState = {
  list: [] as Array<Course>,
  status: "idle" as Status,
};

/**
 * Courses plan reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function coursesReducer(state = defaultState, action: any) {
  switch (action.type) {
    case SET_COURSES:
      console.log("coursesReducer", action);
      return {
        list: action.courses,
        status: action.status ?? state.status,
      };
    case SET_COURSE_LOADING_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
}
