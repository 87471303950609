import I18 from "./atoms/i18";
import { Button } from "./button.component";
import IconButton2 from "./icon-button-2-component";
import { Modal } from "./modal.component";
import UIcon from "./uicon-component";

export function ConvertToSlugString(str: string) {
  if (str === undefined || str === null || str === "") {
    return "";
  }
  // Take only 255 maximum characters
  str = str.substring(0, 255);
  // Replace spaces with hyphens
  str = str.replace(/\s+/g, "-");
  // Remove all non-word characters (everything except numbers, letters and hyphens)
  str = str.replace(/[^\w\-]+/g, "");

  // Replace all groups of spaces with a single hyphen
  str = str.replace(/[\s-]+/g, "-");

  // Remove leading hyphens
  str = str.replace(/^-+/, "");
  // str = str.replace(/^-+|-+$/g, "");

  // Convert to lowercase
  str = str.toLowerCase();
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  return str;
}

/// Limit the length of the to 255 characters
export function limitMaxCharacterTo255(text: string) {
  if (text !== undefined && text !== null && text.length > 255) {
    return text.substring(0, 255);
  } else {
    return text;
  }
}

interface PostSlugComponentProps {
  community?: any;
  active?: boolean;
  setActive: (active: boolean) => void;
  slug?: string;
  slugPrefix?: string;
  setSlug: (slug: string) => void;
  title?: string;
  setTitle: (title: string) => void;
  description?: string;
  setDescription: (description: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  OGTitle?: string;
  setOGTitle: (OGTitle: string) => void;
  OGDescription?: string;
  setOGDescription: (OGDescription: string) => void;
  OGImage?: string;
  OgImageSubtitle?: string;
  setOGImage: (OGImage: string) => void;
  clearSlugMeta?: () => void;
  hideCreateSlug?: boolean;
}
export default function CreateSlugModal({
  community,
  active,
  setActive,
  slug,
  slugPrefix,
  setSlug,
  title,
  setTitle,
  description,
  setDescription,
  OGTitle,
  setOGTitle,
  OGDescription,
  setOGDescription,
  OGImage,
  OgImageSubtitle,
  setOGImage,
  hideCreateSlug = false,
  clearSlugMeta,
}: PostSlugComponentProps) {
  if (!active) {
    return null;
  }
  return (
    <Modal
      className=""
      active={active}
      width="540px"
      padding={false}
      setActive={setActive}>
      <div className="flex flex-col px-4 py-4 pb-8 theme-bg-surface">
        <div className="flex items-center place-content-between mb-4">
          <span className="flex items-center font-semibold">
            {/* <UIcon icon="cross" /> */}
            <IconButton2
              hoverable
              size="sm"
              icon="cross"
              solid
              onClick={() => {
                if (clearSlugMeta) {
                  clearSlugMeta();
                }
                setActive(false);
              }}
            />
            <I18>Post Settings</I18>
          </span>
          <Button
            label="Done"
            onClick={() => setActive(false)}
            btnColor={undefined}
          />
        </div>
        <CreateSlugComponent
          hideCreateSlug={hideCreateSlug}
          community={community}
          setActive={setActive}
          slug={slug}
          slugPrefix={slugPrefix}
          setSlug={setSlug}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          OGTitle={OGTitle}
          setOGTitle={setOGTitle}
          OGDescription={OGDescription}
          setOGDescription={setOGDescription}
          OGImage={OGImage}
          setOGImage={setOGImage}
          OgImageSubtitle={OgImageSubtitle}
          clearSlugMeta={clearSlugMeta}
        />
      </div>
    </Modal>
  );
}

export function CreateSlugComponent({
  community,
  slug,
  setSlug,
  title,
  slugPrefix,
  setTitle,
  description,
  setDescription,
  OGTitle,
  setOGTitle,
  OGDescription,
  setOGDescription,
  OGImage,
  OgImageSubtitle,
  setOGImage,
  hideCreateSlug = false,
}: PostSlugComponentProps) {
  /// Limit the length of the to 255 characters
  function checkMaxCharacter(text: any) {
    if (text !== undefined && text !== null && text.length > 255) {
      return text.substring(0, 255);
    } else {
      return text;
    }
  }
  let mtRows = description !== undefined ? description.split("\n").length : 1;
  mtRows = mtRows > 3 ? mtRows : 3;

  let ogRows =
    OGDescription !== undefined ? OGDescription.split("\n").length : 1;
  mtRows = mtRows > 3 ? mtRows : 3;
  return (
    <div>
      <span className="flex items-center space-x-2 text-xs font-semibold">
        <I18>SEO Settings</I18>
        <span>
          <UIcon icon="caret-down" size="xs" className="ml-1" />
        </span>
      </span>

      {/* Custom URL */}
      {!hideCreateSlug && (
        <>
          <div className="flex flex-col mt-2">
            <h4 className="font-bold">
              <I18>Custom URL</I18>
            </h4>
            <span className="text-xs theme-text-subtitle-2 my-1">
              {`https://${window.location.hostname}/${slugPrefix}/${
                slug === "" ? "{title}" : slug
              }`}
            </span>

            <div className="border theme-border-default rounded flex">
              <input
                className="py-1 md:px-2 flex-grow  theme-bg-default focus:outline-none rounded"
                onChange={(event) => {
                  setSlug(ConvertToSlugString(event.target.value));
                }}
                value={slug ?? ""}
              />
            </div>
          </div>
        </>
      )}

      {/* Meta Title*/}
      <div className="flex flex-col mt-2">
        <h4 className="font-bold mt-2">
          <I18>Meta Title</I18>
        </h4>
        <div className="text-xxs mb-2 theme-text-subtitle-2 italic ">
          <I18>Maximum length of 255 characters allowed</I18>
        </div>
        <div className="border theme-border-default rounded flex">
          <input
            className="py-1 md:px-2 flex-grow  theme-bg-default focus:outline-none rounded"
            onChange={(event) => {
              setTitle(checkMaxCharacter(event.target.value));
            }}
            value={title ?? ""}
          />
        </div>
      </div>

      {/* Meta Description */}
      <div className="flex flex-col mt-2">
        <h4 className="font-bold mt-2">
          <I18>Meta Description</I18>
        </h4>
        <div className="text-xxs mb-2 theme-text-subtitle-2 italic ">
          <I18>Maximum length of 255 characters allowed</I18>
        </div>
        <div className="border theme-border-default rounded flex">
          <textarea
            // type="text"
            // ref={textAreaElement}
            value={description ?? ""}
            onChange={(e) => {
              setDescription(checkMaxCharacter(e.target.value));
              window.setTimeout(() => {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }, 0);
            }}
            className="px-2 py-1 w-full flex-grow block theme-bg-default focus:outline-none rounded"
            rows={mtRows}
          />
        </div>
      </div>

      <span className="flex items-center space-x-2 text-xs font-semibold mt-8">
        <I18>Open Graph Detail</I18>
        <span>
          <UIcon icon="caret-down" size="xs" className="ml-1" />
        </span>
      </span>

      {/* Open Graph Title */}
      <div className="flex flex-col mt-2">
        <h4 className="font-bold mt-2">
          <I18>Open Graph Title</I18>
        </h4>
        <div className="text-xxs mb-2 theme-text-subtitle-2 italic ">
          <I18>Maximum length of 255 characters allowed</I18>
        </div>
        <div className="border theme-border-default rounded flex">
          <input
            className="py-1 md:px-2 flex-grow  theme-bg-default focus:outline-none rounded"
            onChange={(event) => {
              setOGTitle(checkMaxCharacter(event.target.value));
            }}
            value={OGTitle ?? ""}
          />
        </div>
      </div>
      {/* OG Description */}
      <div className="flex flex-col mt-2">
        <h4 className="font-bold mt-2">
          <I18>Open Graph Description</I18>
        </h4>
        <div className="text-xxs mb-2 theme-text-subtitle-2 italic ">
          <I18>Maximum length of 255 characters allowed</I18>
        </div>
        <div className="border theme-border-default rounded flex">
          {/* <input
            className="py-1 md:px-2 flex-grow  theme-bg-default"
            onChange={(event) => {
              setOGDescription(checkMaxCharacter(event.target.value));
            }}
            value={OGDescription}
          /> */}
          <textarea
            // type="text"
            // ref={textAreaElement}
            value={OGDescription ?? ""}
            onChange={(e) => {
              setOGDescription(checkMaxCharacter(e.target.value));
              window.setTimeout(() => {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
              }, 0);
            }}
            className="py-1 md:px-2 flex-grow block theme-bg-default focus:outline-none rounded"
            rows={mtRows}
          />
        </div>
      </div>

      {/* OG Image */}
      {community.configuration.isWhiteLabel ? (
        <></>
      ) : (
        <div className="flex flex-col mt-2">
          <h4 className="font-bold">
            <I18>Open Graph Image</I18>
          </h4>
          <div className="text-xs theme-text-subtitle-2 my-1">
            <div>
              <I18>
                Recommended dimensions: 1200px by 630px with a 1.91:1 aspect
                ratio.
              </I18>
            </div>
            {OgImageSubtitle && <I18>{OgImageSubtitle}</I18>}
          </div>

          <div className="border theme-border-default rounded flex">
            <input
              className="py-1 md:px-2 flex-grow  theme-bg-default focus:outline-none rounded"
              onChange={(event) => {
                setOGImage(event.target.value);
              }}
              placeholder="https://example.com/image.jpg"
              value={OGImage ?? ""}
            />
          </div>
        </div>
      )}
    </div>
  );
}
