export default class FileUtil {
  /**
   * Return the file size in MB if the file is valid.
   * @param file The file to check.
   * @returns The file size in MB if the file is valid, null otherwise.
   * @memberof FileUtil
   * @example FileUtil.getFileSizeInMB(null); // null
   * FileUtil.getFileSizeInMB(undefined); // null
   * FileUtil.getFileSizeInMB({}); // null
   * FileUtil.getFileSizeInMB({ name: 'test.txt', size: 1024 }); // 0.001
   * FileUtil.getFileSizeInMB({ name: 'test.txt', size: 1024 * 1024 }); // 1
   */
  static getFileSizeInMB(file: File): number | null {
    if (file && file.size) {
      return file.size / (1024 * 1024);
    }
    return null;
  }

  /**
   * Check file size in Mb. If file size is greater than the given size, return true.
   * @param file File to check.
   * @param sizeMb Size in Mb.
   * @returns True if file size is greater than the given size, false otherwise.
   * @memberof FileUtil
   * @example
   * FileUtil.sizeGreaterThan(file, 1); // true if file size is greater than 1 Mb
   */
  static sizeGreaterThan(file: File, sizeMb: number): boolean {
    const fileSize = this.getFileSizeInMB(file);
    return fileSize !== null && fileSize > sizeMb;
  }

  /**
   * Check file size in Mb. If file size is less than the given size, return true.
   * @param file File to check.
   * @param sizeMb Size in Mb.
   * @returns True if file size is less than the given size, false otherwise.
   * @memberof FileUtil
   * @example
   * FileUtil.sizeLessThan(file, 1); // true if file size is less than 1 Mb
   * FileUtil.sizeLessThan(file, 0); // false
   * FileUtil.sizeLessThan(file, -1); // false
   * FileUtil.sizeLessThan(file, 1.5); // true if file size is less than 1.5 Mb
   */
  static sizeLessThan(file: File, sizeMb: number): boolean {
    const fileSize = this.getFileSizeInMB(file);
    return fileSize !== null && fileSize < sizeMb;
  }
}
