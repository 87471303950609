import { ComponentDisplay } from "../../../component-switcher/component-switcher.component";
import { CreatePostPoll } from "../create-post-poll.component";

/**
 * Component to poll component
 * @param {Boolean} isPollVisible - Boolean to show poll component
 * @param {any} errors - Errors from backend
 * @param {boolean} isQuestion - Boolean to show question component
 * @param {Function} setIsQuestion - Function to set isQuestion
 * @param {boolean} isQuiz - Boolean to show quiz component
 * @param {Function} setIsQuiz - Function to set isQuiz
 * @param {boolean} isPoll - Boolean to show poll component
 * @param {Function} setIsPoll - Function to set poll
 * @param {string} quizTitle - Title of quiz
 * @param {Function} setQuizTitle - Function to set quizTitle
 * @param {string} quizDuration - Duration of quiz
 * @param {Function} setQuizDuration - Function to set quizDuration
 * @param {string} quizInstruction - Instruction of quiz
 * @param {Function} setQuizInstruction - Function to set quizInstruction
 * @param {Array<string>} quizQuestions - Array of questions of quiz
 * @param {Function} setQuizQuestions - Function to set quizQuestions
 * @param {string} endTime - End time of quiz
 * @param {Function} setEndTime - Function to set endTime
 */

function CreatePoll(props) {
  const {
    isPollVisible,
    errors,
    isQuestion,
    setIsQuestion,
    isQuiz,
    setIsQuiz,
    isPoll,
    setIsPoll,
    quizTitle,
    setQuizTitle,
    quizDuration,
    setQuizDuration,
    quizInstruction,
    setQuizInstruction,
    quizQuestions,
    setQuizQuestions,
    endTime,
    setEndTime,
  } = props;
  return (
    <ComponentDisplay IsDisplay={isPollVisible} transition="scale">
      <div className="border theme-border-default p-4 rounded theme-bg-default mb-2">
        <CreatePostPoll
          errors={errors && errors.poll ? errors.poll : null}
          isQuestion={isQuestion}
          setIsQuestion={setIsQuestion}
          isQuiz={isQuiz}
          isPoll={isPoll}
          setIsQuiz={(e) => {
            // toggle quiz
            setIsQuiz(true);
            setIsPoll(false);
          }}
          quizTitle={quizTitle}
          setQuizTitle={setQuizTitle}
          quizDuration={quizDuration}
          setQuizDuration={setQuizDuration}
          quizInstruction={quizInstruction}
          setQuizInstruction={setQuizInstruction}
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
          endTime={endTime}
          setEndTime={setEndTime}
        />
      </div>
    </ComponentDisplay>
  );
}
export { CreatePoll };
