import cx from "classnames";
import React, { Suspense, useRef, useState } from "react";
import { connect } from "react-redux";
import TurndownService from "turndown";
import { useAppService } from "../../../../hooks/use-app-service";
import { redirectToAuthUrl } from "../../../../_config/helper.config";
import { createPostDetailPageRouteFromSlug } from "../../../../_pages/post-detail.page";
import { PostService } from "../../../../_service";
import AnalyticsService, {
  EventType,
} from "../../../../_service/analytics-service";
import { MentionService } from "../../../../_service/mention.service";
import Validator from "../../../../_utils/validator";
import I18 from "../../../atoms/i18";
import Tooltip from "../../../atoms/tooltip";
import ErrorBoundary from "../../../error-boundry.component";
import { FileInput } from "../../../form-controls/file-input";
import IconButton2 from "../../../icon-button-2-component";
import { Loader } from "../../../loader.component";
import TenorGifPicker from "../../../tenor-gif-picker";
import UIcon from "../../../uicon-component";
import PostAudioRecorder from "../../create/component/post-audio-recorder.component";
const RichTextEditor = React.lazy(() => import("../../../form-controls/rich-text-editor.component"));

const tds = new TurndownService();

/**
 * Component to create comment
 * @param {*} param0
 * @returns
 */
function CreateCommentComponent({
  post,
  user,
  updatePost = (e) => {},
  isSubComment = false,
  commentId,
  community,
}) {
  const { analyticsService } = useAppService();
  const [description, setDescription] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  // media related
  const [images, setImages] = useState([]);
  const [document, setDocument] = useState(null);

  // Recorder related
  const [isRecorderVisible, setIsRecorderVisible] = useState(false);

  // doc upload for sub comment
  const [subDocument, setSubDocument] = useState([]);
  const [videos, setVideos] = useState([]);
  const textAreaElement = useRef();
  const [isGifPickerVisible, setIsGifPickerVisible] = useState(false);

  // if no user, dont show the comment box
  if (!user) {
    return (
      <section className="theme-bg-default p-4 border-b border-l border-r theme-border-default">
        <span
          className="theme-text-primary cursor-pointer"
          onClick={(e) => {
            redirectToAuthUrl(community);
          }}>
          Sign in
        </span>{" "}
        <span>to add a comment,</span>
      </section>
    );
  }

  async function onSubmit() {
    // create comment

    if (!description) {
      return window.alert("Comment text is required!");
    }

    setIsPosting(true);
    if (!isSubComment) {
      try {
        const embedlessComment = await description.replace(
          /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
          " $1 "
        );
        let finalComment = await tds.turndown(embedlessComment);

        let { post: newPost, commentId } = await PostService.addComment(
          user,
          post.id,
          {
            description: finalComment,
          }
        );
        AnalyticsService.logEvent(
          EventType.Post.comment.name,
          EventType.Post.comment.create
        );
        //mix panel event
        analyticsService.track("post-comment", {
          comment: description,
          groupName: post?.group.name,
          sectionName: post?.tab.name,
          attachment: document || images.length > 0 ? true : false,
          postUrl:
            window.location.origin + createPostDetailPageRouteFromSlug(post),
        });
        // try uploading attachments
        if (images.length > 0) {
          newPost = (
            await PostService.uploadImagesToComment(
              user,
              post.id,
              commentId,
              images
            )
          ).post;
        }

        if (document) {
          newPost = (
            await PostService.uploadDocumentToComment(
              user,
              post.id,
              commentId,
              document
            )
          ).post;
        }

        setImages([]);
        setDocument(null);
        setDescription("");

        if (textAreaElement.current) {
          window.setTimeout(() => {
            textAreaElement.current.style.height = "auto";
            // textAreaElement.current.style.height = e.target.scrollHeight + "px";
          }, 0);
        }
        setIsPosting(false);

        updatePost(newPost);
        return;
      } catch (error) {
        setIsPosting(false);
      }
    } else {
      // create Sub Comment
      try {
        const embedlessSubComment = await description.replace(
          /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
          " $1 "
        );
        let finalSubComment = await tds.turndown(embedlessSubComment);

        let { commentReply: commentReply } = await PostService.addCommentReply(
          user,
          post.id,
          commentId,
          { description: finalSubComment }
        );
        analyticsService.track("post-reply", {
          comment: description,
          groupName: post?.group.name,
          sectionName: post?.tab.name,
          attachment: document || images.length > 0 ? true : false,
          postUrl:
            window.location.origin + createPostDetailPageRouteFromSlug(post),
        });
        AnalyticsService.logEvent(
          EventType.Post.comment.reply.name,
          EventType.Post.comment.reply.create
        );

        // if document
        if (subDocument.length > 0) {
          commentReply = (
            await PostService.uploadDocumentToCommentReply(
              user,
              post.id,
              commentId,
              commentReply.id,
              subDocument
            )
          ).commentReply;
        }
        // if image
        if (images.length > 0) {
          commentReply = (
            await PostService.uploadDocumentToCommentReply(
              user,
              post.id,
              commentId,
              commentReply.id,
              images
            )
          ).commentReply;
        }
        // if video
        if (videos.length > 0) {
          commentReply = (
            await PostService.uploadDocumentToCommentReply(
              user,
              post.id,
              commentId,
              commentReply.id,
              videos
            )
          ).commentReply;
        }

        setDescription("");
        setSubDocument([]);
        setImages([]);
        setVideos([]);
        if (textAreaElement.current) {
          window.setTimeout(() => {
            textAreaElement.current.style.height = "auto";
            // textAreaElement.current.style.height = e.target.scrollHeight + "px";
          }, 0);
        }
        setIsPosting(false);

        // mixpanel event

        updatePost(commentReply, commentId);
        return;
      } catch (error) {
        setIsPosting(false);
      }
    }
  }
  return (
    <ErrorBoundary>
      <div className="CreateComment py-2 border-t theme-border-default">
        <div
          className={cx(
            "flex flex-col items-start border theme-border-default rounded",
            {
              "theme-bg-default": !isSubComment,
              "theme-bg-surface shadow-inner": isSubComment,
            }
          )}>
            <Suspense fallback={<></>}>
              <RichTextEditor
                className="noBorder minHeightDefault w-full px-2 overflow-y-auto"
                text={description}
                setText={setDescription}
                placeholder={isSubComment ? "Post Reply" : "Post Comment"}
                getMentionsFeed={async (query) => {
                  const users = (
                    await MentionService.searchInCommunity(
                      user,
                      community.id,
                      query
                    )
                  ).users.map((user, index) => {
                    const useruid = user.id;
                    user.id = "@" + user.userId;
                    user.userId = useruid;
                    return user;
                  });
                  return users;
                }}
            />
            </Suspense>

          {/* controls */}
          <div className="flex justify-between items-center w-full px-2">
            {/* Toolbar buttons */}
            <div className="Toolbar flex items-center space-x-4">
              <Tooltip label="Picture">
                <FileInput
                  accept="image/*"
                  maxFileSize={10}
                  disabled={images.length > 0}
                  onChange={
                    images.length > 0
                      ? () => {}
                      : (img) => {
                          setImages([img]);
                          setDocument(null);
                          setSubDocument([]);
                          setVideos([]);
                        }
                  }>
                  <div
                    className={cx(
                      "p-1 hover:scale-110 cursor-pointe theme-text-heading-2",
                      {
                        "theme-text-subtitle-2 cursor-not-allowed":
                          images.length > 0,
                      }
                    )}>
                    <UIcon size="md" icon="picture" />
                  </div>
                </FileInput>
              </Tooltip>

              {/* VIDEO */}
              <Tooltip label="Video" className={!setVideos && "hidden"}>
                <FileInput
                  accept="video/*"
                  maxFileSize={100}
                  disabled={videos.length > 0}
                  onChange={(video) => {
                    if (video) {
                      setVideos([video]);
                      setImages([]);
                      setSubDocument([]);
                      setDocument(null);
                    }
                  }}>
                  <div
                    className={cx(
                      "p-1 hover:scale-110 cursor-pointer theme-text-heading-2",
                      {
                        "theme-text-subtitle-2 cursor-not-allowed":
                          videos.length > 0,
                      }
                    )}>
                    <UIcon size="md" icon="play-alt" />
                  </div>
                </FileInput>
              </Tooltip>

              {/* ATTACHMENT */}
              <Tooltip label="Attachment">
                <FileInput
                  accept="file/*"
                  maxFileSize={100}
                  disabled={document || subDocument.length > 0}
                  onChange={(doc) => {
                    if (isSubComment) {
                      setVideos([]);
                      setImages([]);
                      setSubDocument([]);
                      setSubDocument([doc]);
                    } else {
                      setDocument(null);
                      setImages([]);
                      setDocument(doc);
                    }
                  }}>
                  <div
                    className={cx(
                      "p-1 hover:scale-110 cursor-pointer theme-text-heading-2",
                      {
                        "theme-text-subtitle-2 cursor-not-allowed":
                          document || subDocument.length > 0,
                      }
                    )}>
                    <UIcon size="md" icon="clip" />
                  </div>
                </FileInput>
              </Tooltip>
              {/* Audio */}
              <Tooltip label="Record Audio">
                <button
                  disabled={document || subDocument.length > 0}
                  className={cx(
                    "p-1 hover:scale-110 cursor-pointer theme-text-heading-2",
                    {
                      "theme-text-subtitle-2 cursor-not-allowed":
                        document || subDocument.length > 0,
                    }
                  )}
                  onClick={() => {
                    setIsRecorderVisible(!isRecorderVisible);
                  }}>
                  <UIcon size="md" icon="microphone" />
                </button>
              </Tooltip>
              {/* GIF */}
              <Tooltip
                label="Gif"
                className={!setIsGifPickerVisible && "hidden "}>
                <button
                  className="p-.05 hover:scale-110 "
                  onClick={() => {
                    setIsGifPickerVisible(!isGifPickerVisible);
                  }}>
                  <span className="border theme-border-heading-1 rounded px-2 text-xxs">
                    <I18>Gif</I18>
                  </span>
                </button>
              </Tooltip>
              <TenorGifPicker
                active={isGifPickerVisible}
                setActive={setIsGifPickerVisible}
                initialSearchQuery=""
                onGifSelect={async (gif) => {
                  setImages([gif.blobData]);
                  setDocument(null);
                  setSubDocument([]);
                  setVideos([]);
                  setIsGifPickerVisible(false);
                }}
              />
            </div>
            {isPosting ? (
              <div className="send-message m-2">
                <Loader />
              </div>
            ) : (
              <IconButton2
                icon="paper-plane"
                hoverable={false}
                iconSmall
                onClick={onSubmit}
                size="lg"
                iconClass="theme-text-heading-2"
                className={cx(
                  "comment-button rotate-45 rounded-full mb-1, theme-bg-default "
                )}
              />
            )}
          </div>
        </div>
        {/* media preview */}
        {images.length > 0 ? (
          <div className="preview-image mb-2">
            <img
              className="h-40 w-full"
              src={URL.createObjectURL(images[0])}
              alt="CommentImage"
            />
            <span
              className="remove"
              onClick={(e) => {
                setImages([]);
              }}>
              &times;
            </span>
          </div>
        ) : (
          <></>
        )}
        {videos.length > 0 ? (
          <div className="preview-video">
            <div className="py-1 mt-2">
              <video className="m-0 w-full" controls controlsList="nodownload">
                <source src={URL.createObjectURL(videos[0])} />
              </video>
            </div>
            <span
              className="remove"
              onClick={(e) => {
                setVideos([]);
              }}>
              &times;
            </span>
          </div>
        ) : (
          <></>
        )}
        {document &&
        ["mp4", "mov", "webm", "ogg"].includes(
          document.name.split(".").pop()
        ) ? (
          <div className="preview-video">
            <div className="py-1 mt-2">
              <video className="m-0" controls controlsList="nodownload">
                <source src={URL.createObjectURL(document)} />
              </video>
            </div>
            <span
              className="remove"
              onClick={(e) => {
                setDocument(null);
              }}>
              &times;
            </span>
          </div>
        ) : (
          <SelectedDocumentPreview
            document={document}
            isSubComment={isSubComment}
            subDocument={subDocument}
            setDocument={setDocument}
            setSubDocument={setSubDocument}
          />
        )}
        <PostAudioRecorder
          isRecorderVisible={isRecorderVisible}
          setIsRecorderVisible={setIsRecorderVisible}
          setRecording={(recording) => {
            if (isSubComment) {
              setVideos([]);
              setImages([]);
              setSubDocument([]);
              setSubDocument([recording]);
            } else {
              setImages([]);
              setDocument(recording);
            }
          }}
        />
      </div>
    </ErrorBoundary>
  );
}

/**
 * Preview document component
 * @param {object} props
 *
 */
function SelectedDocumentPreview({
  document = null,
  subDocument = [],
  setDocument = (e) => {},
  setSubDocument = (e) => {},
  isSubComment,
}) {
  let file = document;
  if (!Validator.hasValue(file) && Validator.hasValue(subDocument)) {
    file = subDocument[0];
  }
  if (!file) {
    return <></>;
  }

  if (["mp3"].includes(file.name.split(".").pop())) {
    return (
      <>
        <div className="SelectedDocumentPreview w-full">
          <div className="relative py-3 mt-2 w-full">
            <audio
              src={URL.createObjectURL(file)}
              controls
              className={cx("w-full py-2")}
            />
            <button
              className="absolute top-1 -right-1 p-1  w-7 h-7 theme-bg-danger theme-text-on-primary border theme-border-on-primary rounded-full"
              size="sm"
              type="general"
              onClick={(e) => {
                if (isSubComment) {
                  setSubDocument([]);
                } else {
                  // remove the videos
                  setDocument(null);
                }
              }}>
              <UIcon icon="cross-small" className="h-4 w-4" />
            </button>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="SelectedDocumentPreview my-3 relative">
        <a
          download
          target="_blank"
          rel="noreferrer"
          href={file}
          title="Open file"
          className="flex items-center space-x-2 border-2 theme-border-default rounded px-4 font-semibold py-2 ">
          <UIcon icon="document" className="text-3xl h-9" />
          <div className="flex items-center justify-between flex-grow space-x-4">
            <div className="items-center ">
              <div>{file.name}</div>
              <div className="uppercase text-xs">
                {file.name.split(".").pop()}
              </div>
            </div>
            <span className="flex-none">
              <UIcon icon="download" className="text-xl" />
            </span>
          </div>
        </a>
        <div
          onClick={(e) => {
            if (isSubComment) {
              setSubDocument([]);
            } else {
              // remove the videos
              setDocument(null);
            }
          }}
          className="remove">
          &times;
        </div>
      </div>
    </>
  );
}

const CreateComment = connect((s) => ({ community: s.community }))(
  CreateCommentComponent
);
export default CreateComment;
