import React from "react";
import { GroupService } from "../../../../_service";
import { Button } from "../../../button.component";

/**
 * @description Button to request to join a closed section
 * @param {Object} user - user object
 * @param {Object} group - group object
 * @param {any} activeTabModel - active tab object
 * @param {Function} setGroup - set group function
 * @param {Function} addToast - add toast function
 * @param {Function} updateActiveGroup - update active group function
 */
export default function ClosedSectionRequestButton({
  activeTabModel,
  user,
  group,
  updateActiveGroup,
  setGroup,
  addToast,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  return activeTabModel.isPrivate ? (
    <></>
  ) : (
    <Button
      label="Request Access"
      onClick={() => {
        setIsLoading(true);
        // make the request access to the closed section
        GroupService.joinClosedSection(user, group.id, activeTabModel.id)
          .then(({ group }) => {
            // update the group
            updateActiveGroup(group);
            setGroup(group);
            addToast("Request sent!", "", "success");
          })
          .catch((error) => {
            console.log({ error });
            addToast("Unable to send request!", "", "danger");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
      isLoading={isLoading}
      className="CloseSectionRequestButton"
    />
  );
}
