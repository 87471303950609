import React from "react";
import I18 from "../atoms/i18";
import { Loader } from "../loader.component";
import RightSideModal from "../right-side-modal.component";
import unsplash from "./unsplash-api";
import PhotoList from "./unsplash-photo-list";
import SearchBar from "./unsplash-photo-search-bar";

interface Props {
  active?: boolean;
  initialPhotoSearchQuery: string;
  setActive?: (active: boolean) => void;
  onPhotoSelect?: (photo: any) => void;
}

/**
 * @description UnsplashImagePicker search image from Unsplash.
 * @param {string} initialPhotoSearchQuery - The initial search query.
 * @param {boolean} active - Whether the image picker is active.
 * @param {function} setActive - Function to set the image picker active.
 * @param {function} onPhotoSelect - Function to call when a photo is selected.
 */
export default function UnsplashImagePicker({
  active = false,
  initialPhotoSearchQuery = "",
  setActive = (_: boolean) => {},
  onPhotoSelect = (_: any) => {},
}: Props) {
  const [pics, setPics] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState<number | undefined>();
  const [query, setQuery] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [convertingToBlob, setConvertingToBlob] = React.useState(false);

  React.useEffect(() => {
    if (initialPhotoSearchQuery !== "" && active) {
      setQuery(initialPhotoSearchQuery);
      fetchPhotos(1, initialPhotoSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPhotoSearchQuery, active]);

  const fetchPhotos = (page: number, text: string, reset = false) => {
    if (isLoading || isLoadingMore) {
      return;
    }
    if (page === 1) {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }
    setPage(page);
    unsplash.search
      .getPhotos({
        page: page,
        perPage: 30,
        query: text,
        orientation: "landscape",
      })
      .then((response: any) => {
        const newPics = response?.response?.results;
        if (newPics) {
          let mergedPics = newPics;
          if (!reset) {
            mergedPics = [...pics, ...newPics];
          }
          setPics(mergedPics);
          setTotal(response.response.total);
        }
        setIsLoading(false);
        setIsLoadingMore(false);
      });
  };

  if (!active) {
    return null;
  }

  return (
    <div className="UnsplashImagePicker theme-bg-surface rounded">
      <RightSideModal active={active} setActive={setActive} width="540px">
        <div className="relative h-full">
          <div className="px-4 pt-4 font-bold text-lg theme-bg-surface">
            <I18>Search image</I18>
          </div>
          <div className="shadow p-4 theme-bg-surface">
            <div className="">
              <SearchBar
                onSearch={(query: string) => {
                  setPics([]);
                  fetchPhotos(1, query, true);
                }}
                placeholder="Search for an image"
                query={query}
                setQuery={setQuery}
              />
            </div>
          </div>

          <PhotoList
            total={total}
            photoList={pics}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={() => {
              fetchPhotos(page + 1, query);
            }}
            onPhotoSelect={async (photo: any) => {
              try {
                setConvertingToBlob(true);
                let blob = await fetch(photo.urls.full).then((r) => r.blob());
                let image = await URL.createObjectURL(blob);
                setConvertingToBlob(false);
                onPhotoSelect({
                  ...photo,
                  blobData: image,
                });
              } catch (error) {
                console.log(error);
                setConvertingToBlob(false);
              }
            }}
          />
        </div>
        {isLoading ||
          (convertingToBlob && (
            <div className="absolute top-0 bottom-0 left-0 right-0">
              <div className="flex items-center place-content-center h-full bg-slate-600/70 ">
                <div className="opacity-100">
                  <Loader />
                </div>
              </div>
            </div>
          ))}
      </RightSideModal>
    </div>
  );
}
