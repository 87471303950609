import cx from "classnames";
import React from "react";
import Label from "../../../_components/typography/label.typography";
import UIcon from "../../../_components/uicon-component";

interface Props {
  firstPageTitle?: string;
  secondPageTitle?: string;
  trailingIcons1?: React.ReactNode;
  trailingIcons2?: React.ReactNode;
  isSecondPageVisible: boolean;
  setSecondPageVisible: (value: boolean) => void;
}

function DualPageHeaderComponent({
  firstPageTitle = "New Page",
  secondPageTitle = "New Form",
  isSecondPageVisible,
  setSecondPageVisible,
  trailingIcons1,
  trailingIcons2,
}: Props) {
  return (
    <>
      <div
        className={cx(
          "flex flex-col pl-4 pr-2 place-content-center  w-full theme-bg-surface shadow h-14 border-b rounded"
        )}>
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center justify-between w-full">
            <button
              className={cx("", {
                "cursor-pointer": isSecondPageVisible,
                "cursor-default": !isSecondPageVisible,
              })}
              onClick={(e) => {
                if (isSecondPageVisible) setSecondPageVisible(false);
              }}>
              {isSecondPageVisible ? (
                <span className="flex items-center space-x-2">
                  <UIcon icon="angle-left" />
                  <span>{secondPageTitle}</span>
                </span>
              ) : (
                <Label variant="t1">{firstPageTitle}</Label>
              )}
            </button>
            {isSecondPageVisible ? trailingIcons2 : trailingIcons1}
          </div>
        </div>
      </div>
    </>
  );
}

export default DualPageHeaderComponent;
