import cx from "classnames";
import { Suspense, lazy } from "react";
import useRecorder from "../../../../hooks/use-recorder.hook";
import audioBufferToWav from "../../../../_utils/recorder/audio-buffer-to-wav-converter";
import Validator from "../../../../_utils/validator";
import TextButton from "../../../atoms/text-button";
import { Button } from "../../../button.component";
import { Modal } from "../../../modal.component";
import Label from "../../../typography/label.typography";
import UIcon from "../../../uicon-component";
const LottiePlayer = lazy(() => import("../../../molecule/lottie-player"));

interface Props {
  className?: string;
  isRecorderVisible: boolean;
  setRecording: (rec: any) => void;
  setIsRecorderVisible: (isRecorderVisible: boolean) => void;
}
/**
 * Component for recording audio
 * @param {boolean} isRecorderVisible - if true, the recorder is visible
 * @param {function} setIsRecorderVisible - function to set the visibility of the recorder
 * @param {function} setRecording - function to set the recording
 */

const PostAudioRecorder = ({
  isRecorderVisible,
  setIsRecorderVisible,
  setRecording = () => {},
}: Props): JSX.Element => {
  let { audioURL, isRecording, startRecording, stopRecording, resetRecording } =
    useRecorder();

  if (!isRecorderVisible) {
    return <></>;
  }

  const audioContext = new window.AudioContext();
  // const fileReader = new FileReader();

  async function convertToBlob(audioURL: any) {
    // Generate audio file name
    const fileName = `recording-${Date.now()}.mp3`;
    await fetch(audioURL)
      .then((r) => {
        if (r.ok) {
          // Convert response to array buffer
          r.arrayBuffer().then((arrayBuffer) => {
            // Decode array buffer to audio buffer
            audioContext.decodeAudioData(
              arrayBuffer as ArrayBuffer,
              (audioBuffer) => {
                // Convert audio buffer to wav
                const wav = audioBufferToWav(audioBuffer);
                // Set the recording
                setRecording(
                  new File([new DataView(wav)], fileName, {
                    type: "audio/wav",
                    lastModified: Date.now(),
                  })
                );
              },
              (err) => {
                console.log(err);
              }
            );
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function confirm() {
    if (Validator.hasValue(audioURL)) {
      convertToBlob(audioURL);
      setIsRecorderVisible(false);
    }
    resetRecording();
  }

  return (
    <Modal
      active={isRecorderVisible}
      setActive={setIsRecorderVisible}
      padding={false}
      className="w-full h-full">
      <div className="AudioRecorder">
        <div className="border-b theme-border-default w-full p-2 px-3 theme-bg-surface">
          <Label variant="t1" size="h2">
            Record
          </Label>
        </div>

        <section
          className={cx(
            " relative flex flex-col gap-2 p-4  theme-border-default rounded my-3"
          )}>
          <audio
            src={audioURL}
            controls
            className={cx("w-full py-1 rounded-full my-6", {
              hidden: isRecording || !Validator.hasValue(audioURL),
            })}
          />

          <Suspense fallback={<></>}>
            <LottiePlayer
              src={"https://assets9.lottiefiles.com/packages/lf20_pzrstZ.json"}
              autoplay={true}
              loop={true}
              controlVisible={false}
              className={cx("mx-auto w-full h-32 scale-x-150", {
                hidden: !isRecording,
              })}
            />
          </Suspense>

          <div
            className={cx(
              "flex flex-col gap-2 place-content-between sm:flex-row"
            )}>
            <TextButton
              label="start recording"
              className="flex items-center gap-2 outline outline-1  rounded-full max-w-fit"
              onClick={startRecording}
              disabled={isRecording}
              prefix={<UIcon icon="rec" solid={true} className="h-4" />}
            />
            <TextButton
              label="Stop recording"
              onClick={stopRecording}
              disabled={!isRecording}
              className="flex items-center gap-2 outline outline-1 rounded-full max-w-fit"
              prefix={<UIcon icon="stop" solid={true} className="h-4" />}
            />

            <TextButton
              label="Reset recording"
              onClick={() => {
                resetRecording();
              }}
              disabled={isRecording || !Validator.hasValue(audioURL)}
              className="flex items-center gap-2 outline outline-1  rounded-full max-w-fit"
              prefix={
                <UIcon icon="cross-circle" solid={true} className="h-4" />
              }
            />
          </div>
        </section>

        {/* Bottom Buttons */}
        <div className="flex flex-row place-content-end gap-2 p-3 theme-bg-surface">
          <TextButton
            label={"Cancel"}
            onClick={() => {
              startRecording();
              resetRecording();
              setIsRecorderVisible(false);
            }}
          />
          <Button
            label={"Confirm"}
            onClick={() => {
              confirm();
            }}
            disabled={isRecording || !Validator.hasValue(audioURL)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PostAudioRecorder;
