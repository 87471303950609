import PostTileShimmer, { PostTileType } from "./post-tile-shimmer.component";

export { PostTileType } from "./post-tile-shimmer.component";

/**
 * PostListShimmer
 * @param {*} length
 * @param {*} type
 */
function PostListShimmer({ length, type = PostTileType.default }) {
  const list = [];

  for (let i = 0; i < length ?? 1; i++) {
    list.push(<PostTileShimmer key={i} type={type} />);
  }
  return (
    <div className="flex-grow flex flex-col pt-0  animate-pulse  py-2 z-0">
      {list}
    </div>
  );
}

export default PostListShimmer;
