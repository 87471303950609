import { useEffect, useState } from "react";
import I18 from "../../_components/atoms/i18";
import { RectangleCardShimmer } from "../../_components/shimmers/rectangle-card-shimmer";
import Label from "../../_components/typography/label.typography";
import UIcon from "../../_components/uicon-component";
import { NFTService } from "../../_service";
import { NftCard } from "../nfts-page";

export default function UserWallNFTTab({
  user,
  isVisible = false,
  setWalletAddress = () => {},
}) {
  const userId = user?.id;
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      NFTService.getUserNFTs(userId)
        .then(({ nfts, walletAddress }) => {
          setNfts(nfts);
          setIsLoading(false);
          setMessage("");
          console.log("NFT", nfts, walletAddress);
          setWalletAddress(walletAddress);
        })
        .catch((err) => {
          setIsLoading(false);
          setMessage(err?.response?.data?.message ?? "Cannot fetch NFT list");
        });
    }
  }, [userId]);

  return isVisible ? (
    message ? (
      <MessageBox message={message} />
    ) : nfts.length || isLoading ? (
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-4 my-4">
        {isLoading ? (
          <Shimmer />
        ) : (
          nfts.map((nft, index) => <NftCard nft={nft} key={index} />)
        )}
      </div>
    ) : (
      <div className="no-post p-2 my-10 text-center">
        <div className="font-bold my-3">
          <I18>Looks like it's lonely here.</I18>
        </div>
        <div>
          <I18>No nfts to show.</I18>
        </div>
      </div>
    )
  ) : (
    <></>
  );
}

function MessageBox({ message }) {
  return (
    <div className="flex gap-2 p-2 border theme-border-default rounded mb-2 sm:mb-0">
      <UIcon icon="info" size="sm" />
      <Label size="body" variant="s1" translate>
        {message}
      </Label>
    </div>
  );
}

function Shimmer() {
  return (
    <>
      <RectangleCardShimmer height="300px" />
      <RectangleCardShimmer height="300px" />
      <RectangleCardShimmer height="300px" />
    </>
  );
}
//user page nft card & preview.
<NftCard />;
