import cx from "classnames";
import React from "react";
import { useAppService } from "../hooks/use-app-service";
import I18 from "./atoms/i18";

/**
 * Filter component.
 *
 * @param {string} selectedOption - The currently selected option.
 * @param {Function} setSelectedOption - A function to set the selected option.
 * @param {Array} options - An array of option objects with keys and labels.
 */
interface Props {
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  options: { key: string; label: string }[];
}

const Filter: React.FC<Props> = ({
  selectedOption,
  setSelectedOption,
  options,
}) => {
  const { analyticsService } = useAppService();

  return (
    <div className="flex gap-7 ml-1">
      {options.map((option) => (
        <div
          key={option.key}
          className={cx("flex justify-center items-center cursor-pointer", {
            "px-4 py-2 rounded cursor-pointer theme-bg-primary theme-text-on-primary":
              selectedOption === option.key,
          })}
          onClick={() => {
            if (selectedOption !== option.key) {
              setSelectedOption(option.key);
              analyticsService.track("post-filter", {
                filterName: option.key,
              });
            }
          }}>
          <I18>{option.label}</I18>
        </div>
      ))}
    </div>
  );
};

export default Filter;
