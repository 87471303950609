import cx from "classnames";
import moment from "moment";
import { useState } from "react";
import removeMd from "remove-markdown";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { history } from "../../_config";
import { createPostDetailPageRouteFromSlug } from "../../_pages/post-detail.page";
import { PostService } from "../../_service";
import LazyImage from "../atoms/lazy-image";
import { Avatar } from "../avatar.component";
import IconButton2 from "../icon-button-2-component";
import IconMenu2, { ActionType } from "../icon-menu-2.component";
import UpdatePost from "./edit";
import { PostProcessing } from "./post-card.component";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

/**
 * @param {Object} post post object
 * @param {Object} user current user object. If user is null, then user is not logged in
 * @param {Function} updatePost Callback function to update post
 * @param {Function} deletePost Callback function to delete post
 * @description Display minimal post with cover photo
 */
function SquarePostCardComponent({
  post,
  user,
  addToast,
  updatePost = (post) => {},
  deletePost = (post) => {},
}) {
  // Determine if the post is being edited.
  const [isBeingEdit, setIsBeingEdit] = useState(false);

  // Determine if the post is being deleted.
  const [isProcessing, setIsProcessing] = useState(false);

  // post preferences
  const { hideLikes, hideComment, hideCreator, hideDate } =
    post.preferences ?? {};

  // Display post heading. If post has no title, display post content.
  const heading = post.title
    ? takeOnly55(post.title)
    : post.description
    ? takeOnly55(removeMd(post.description))
    : undefined;

  function takeOnly55(text) {
    if (text.length > 55) {
      return text.substring(0, 70) + "...";
    }
    return text;
  }
  return (
    <div className="SquarePostCard h-full theme-bg-surface rounded border theme-border-default cursor-pointer">
      <div
        className="group flex flex-col h-full"
        onClick={() => {
          history.push(getSegmentedPath(createPostDetailPageRouteFromSlug(post)));
        }}>
        {post.banner ? (
          <div className="relative">
            <LazyImage src={post.banner} className="flex-none" alt="banner" />
            <div className="w-6 h-6 absolute top-1 right-1">
              <PostMenuAction />
            </div>
          </div>
        ) : post.images && post.images.length > 0 ? (
          <>
            {" "}
            <LazyImage
              src={post.images[0]}
              style={{ aspectRatio: "16/9" }}
              className="flex-none object-cover"
              alt="banner"
            />
          </>
        ) : (
          <></>
        )}
        <div className="flex-grow flex flex-col h-full px-3 pt-2">
          {/* {post.title && (
            <div className="text-lg font-bold flex-none">{post.title}</div>
          )} */}
          {heading ? (
            <div className="text-lg font-bold flex-none break-words">
              {heading}
            </div>
          ) : (
            <div className="theme-text-subtitle-2 text-sm my-4">
              {" "}
              No Caption Available
            </div>
          )}
          {!post.title && !heading && post.banner && (
            <div className="pt-2 h-6 flex-none"></div>
          )}
          <div className="flex justify-between">
            {!hideCreator ? (
              <Avatar
                user={post.createdBy}
                size={30}
                className="my-1 flex-none"
                extraInfo={
                  moment
                    .duration(moment(new Date()).diff(moment(post.createdAt)))
                    .asDays() < 1
                    ? moment(post.createdAt).fromNow()
                    : "" + moment(post.createdAt).format("DD MMM YYYY")
                }
              />
            ) : (
              <div />
            )}
            {!post.banner && <PostMenuAction />}
          </div>
          <div className="flex-grow theme-bg-surface"></div>

          {/* Like Comment Icons */}
          <div
            className={cx("flex-none flex items-center", {
              "mb-2": hideComment && hideLikes,
            })}>
            <div
              className={cx("flex items-center mr-4", {
                hidden: hideLikes,
              })}>
              <IconButton2
                icon="thumbs-up"
                label={`${post.likeCount}`}
                iconClass="text-lg"
                size="sm"
              />
            </div>
            <div
              className={cx("flex items-center", {
                hidden: hideComment,
              })}>
              <IconButton2
                icon="comment-alt"
                label={`${post.commentCount}`}
                iconClass="text-lg"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>
      <PostProcessing isProcessing={isProcessing} />
    </div>
  );

  function PostMenuAction() {
    if (!user) {
      return null;
    }
    function deletePostHandler() {
      setIsProcessing(true);
      PostService.deletePost(user, post.id)
        .then(() => {
          addToast("Post deleted");
          deletePost(post);
        })
        .catch((error) => {
          console.log(error);
          addToast(error.message, "", ToastTypes.error);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }

    function bookmarkHandler() {
      (post.isBookmarkedByMe
        ? PostService.removeBookmark(user, post.id)
        : PostService.bookmarkPost(user, post.id)
      )
        .then(() => {
          // bookmark updated, update the post
          updatePost({
            ...post,
            isBookmarkedByMe: !post.isBookmarkedByMe,
          });
          addToast(
            !post.isBookmarkedByMe ? "Bookmark added!" : "Bookmark removed",
            "",
            !post.isBookmarkedByMe ? ToastTypes.success : ToastTypes.info
          );
        })
        .catch((error) => {
          addToast(error.message, "", ToastTypes.error);
        });
    }
    return (
      <>
        <IconMenu2
          hideOnEmpty={true}
          dropdownClassName={post.isBookmarkedByMe ? "w-56" : "w-40"}
          actions={[
            {
              label: user && post.createdBy.id === user.id ? "Edit" : "",
              icon: "edit",
              onClick: () => setIsBeingEdit(true),
            },
            {
              label: post.canDelete ? "Delete" : "",
              icon: "trash",
              onClick: () => deletePostHandler(),
              actionType: ActionType.alert,
            },
            {
              // Toggle bookmark
              icon: "bookmark",
              solidIcon: post.isBookmarkedByMe,
              label: post.isBookmarkedByMe ? "Remove Bookmark" : "Bookmark",
              onClick: () => bookmarkHandler(),
            },
          ]}
        />
        {isBeingEdit ? (
          // <UpdatePostCard
          //   postToUpdate={post}
          //   user={user}
          //   updatePost={(post) => {
          //     setIsBeingEdit(false);
          //     updatePost(post);
          //   }}
          //   cancelUpdate={(e) => {
          //     // set is being edit to false
          //     setIsBeingEdit(false);
          //   }}
          // />
          <UpdatePost
            postToUpdate={post}
            active={isBeingEdit}
            setActive={setIsBeingEdit}
            updatePost={updatePost}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}
const SquarePostCard = SquarePostCardComponent;
/**
 * @param {Object} post post object
 * @param {Object} user current user object. If user is null, then user is not logged in
 * @param {Function} updatePost Callback function to update post
 * @param {Function} deletePost Callback function to delete post
 * @description Display minimal post with cover photo
 */
export default withToast(SquarePostCard);
