import cx from "classnames";
import liveIcon from "../_assets/vectors/live-icon-2.svg";
import liveIconWhite from "../_assets/vectors/live-white-icon-2.svg";
import I18 from "./atoms/i18";

interface Props {
  icon: string;
  onClick?: () => void;
  className?: string;
  iconClass?: string;
  ended?: Boolean;
}
function LiveButton({ onClick, className, ended }: Props) {
  return (
    <div
      onClick={(e) => {
        if (onClick != null && !ended) {
          onClick();
        }
      }}
      className={cx(
        `flex items-center place-content-center h-6 px-2 text-xs space-x-1 rounded select-none ${className}`,
        {
          "theme-bg-disable  theme-text-subtitle-2 cursor-not-allowed": ended,
          "theme-bg-danger cursor-pointer hover:shadow text-white": !ended,
        }
      )}>
      <span>
        <img
          src={!ended ? liveIconWhite : liveIcon}
          className={"w-full h-full"}
          alt=""
        />
      </span>
      <span>
        <I18>Live</I18>
      </span>
    </div>
  );
}
export default LiveButton;
