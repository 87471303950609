import axios from "axios";
import { endpoints } from "../_config";

class NotificationCentreService {
  /**
   * get user's notifications preference
   * @param {String} communityId community id
   * @param {Object} user user object
   * @returns
   */
  static async getNotificationPreference(communityId, user) {
    return axios
      .get(endpoints.community.notificationPreference(communityId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
  /**
   * Update user's notification preference
   * @param {String} communityId community id
   * @param {Object} user user object
   * @param {Object} preference preference object
   * @returns
   */
  static async updateNotificationPreference(communityId, user, preference) {
    return axios
      .post(
        endpoints.community.updateNotificationPreference(communityId),
        preference,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
}

export { NotificationCentreService };
