import MEDIA_TYPES from "../../../../_constants/mediaType";
import UIcon from "../../../uicon-component";

/**
 * Component to display documents preview
 * @param {Array<File>} documents - file to upload
 * @param {Function} setDocument - function to set documents
 */
export default function SelectedDocumentPreview({
  documents = null,
  setDocument = (e) => {},
  setUploadedMedia = () => {},
  setRemovedMediaUploadsOnEdit = () => {},
  areFilesUploading = false
}) {
  if (!documents || documents.length === 0) {
    return null;
  }
  if (typeof(documents) !== 'object') {
    documents = [documents];
  }

  const doc = documents[0];
  return doc ? (
    <div className="SelectedDocumentPreview my-0.5 relative">
      <a
        download
        target="_blank"
        rel="noreferrer"
        href="/#"
        title="Open file"
        className="flex items-center space-x-2 border-2 theme-border-default rounded px-4 font-semibold py-2">
        <UIcon icon="document" className="text-3xl h-9" />
        <div className="flex items-center justify-between flex-grow space-x-4">
          <div className="items-center ">
            <div>{doc.name}</div>
            <div className="uppercase text-xs">
              {doc?.name?.split(".")?.pop() ?? "file"}
            </div>
          </div>
        </div>
      </a>
      <div
        onClick={(e) => {
          // remove the document
          setDocument([]);
          console.log(doc)
          setRemovedMediaUploadsOnEdit((prevMediaItems) => [
            ...prevMediaItems,
            { mediaItem: doc, type: MEDIA_TYPES.DOCUMENT },
            ,
          ]);
          setUploadedMedia((prevUploadedMedia) => ({
            ...prevUploadedMedia,
            document: [],
          }));
        }}
        className={`${areFilesUploading ? "hidden" : "remove"}`}>
        &times;
      </div>
    </div>
  ) : (
    <></>
  );
}
