import { GroupService } from "../../_service";
import {
  sectionPostCommentAddReducer,
  sectionPostCommentReactionUpdateReducer,
  sectionPostCommentRemoveReducer,
  sectionPostLikeRealtimeIncrementReducer,
  SECTION_POST_COMMENT_REALTIME_ADD,
  SECTION_POST_COMMENT_REALTIME_REACTION_UPDATE,
  SECTION_POST_COMMENT_REALTIME_REMOVE,
  SECTION_POST_LIKE_REALTIME_INCREMENT,
} from "./sections-realtime-reducer";

// action
export const ACTIVATE_SECTION = "ACTIVATE_SECTION";

export const INCREASE_NEW_POST_COUNT = "INCREASE_NEW_POST_COUNT";
export const RESET_NEW_POST_COUNT = "RESET_NEW_POST_COUNT";

export const GET_SECTION_PINNED_POSTS = "GET_SECTION_PINNED_POSTS";
export const SET_SECTION_PINNED_POSTS = "SET_SECTION_PINNED_POSTS";
export const ADD_SECTION_PINNED_POST = "ADD_SECTION_PINNED_POST";
export const REMOVE_SECTION_PINNED_POST = "REMOVE_SECTION_PINNED_POST";
export const UPDATE_SECTION_PINNED_POST = "UPDATE_SECTION_PINNED_POST";

export const GET_SECTION_POSTS = "GET_SECTION_POSTS";
export const SET_SECTION_POSTS = "SET_SECTION_POSTS";
export const SET_CURRENT_SECTION_POSTS = "SET_CURRENT_SECTION_POSTS";

export function activateSectionAction(section) {
  return {
    type: ACTIVATE_SECTION,
    section,
  };
}

export function increaseNewPostCount(sectionId, postId) {
  return {
    type: INCREASE_NEW_POST_COUNT,
    sectionId,
    postId,
  };
}

export function resetNewPostCount(sectionId) {
  return {
    type: RESET_NEW_POST_COUNT,
    sectionId,
  };
}

export function getSectionPinnedPostsAction(sectionId) {
  return {
    type: GET_SECTION_PINNED_POSTS,
    sectionId,
  };
}

export function setSectionPinnedPosts(sectionId, posts) {
  return {
    type: SET_SECTION_PINNED_POSTS,
    posts,
    sectionId,
  };
}

export function addSectionPinnedPost(post) {
  return {
    type: ADD_SECTION_PINNED_POST,
    post,
  };
}

export function removeSectionPinnedPosts(post) {
  return {
    type: REMOVE_SECTION_PINNED_POST,
    post,
  };
}
export function updateSectionPinnedPost(post) {
  return {
    type: UPDATE_SECTION_PINNED_POST,
    post,
  };
}

export function getSectionPostsAction(sectionId, pageNo) {
  return {
    type: GET_SECTION_POSTS,
    sectionId,
    pageNo,
  };
}

export function setSectionPosts(sectionId, posts, pageNo) {
  if (posts) {
    posts = posts.map((post) => {
      return {
        ...post,
        comments: post.comments ?? [],
      };
    });
  }
  return {
    type: SET_SECTION_POSTS,
    posts,
    sectionId,
    pageNo,
  };
}

export function setCurrentSectionPosts(posts) {
  return {
    type: SET_CURRENT_SECTION_POSTS,
    posts,
  };
}

// thunk
export function getSectionPinnedPosts(groupId, sectionId) {
  return async (dispatch) => {
    dispatch(getSectionPinnedPostsAction(sectionId));
    const { posts } = await GroupService.sectionPostsPinned(groupId, sectionId);
    dispatch(setSectionPinnedPosts(sectionId, posts));
  };
}
/**
 * Get paginated posts for a section
 */
export function getSectionPosts(groupId, sectionId, pageNo = 1) {
  return async (dispatch, getState) => {
    const { sections } = getState();
    if (sections.sections[sectionId].isLoadingMorePosts) {
      return;
    }
    dispatch(getSectionPostsAction(sectionId, pageNo));
    const { posts, page } = await GroupService.sectionPostsPaginated(
      groupId,
      sectionId,
      pageNo
    );
    dispatch(setSectionPosts(sectionId, posts, page));
  };
}

const INITIAL_STATE = {
  activeSectionId: null,
  sections: {},
};

const INITIAL_SECTION_STATE = {
  isLoadingPinnedList: false,
  pinnedPosts: [],
  pinnedErrorMessage: null,
  isLoadingPosts: false,
  posts: [],
  currentPage: 1,
  newPostCount: 0,
  errorMessage: null,
  isLoadingMorePosts: false,
  noMorePosts: false,
};

const POSTS_PER_PAGE = 10;

export function sectionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIVATE_SECTION: {
      const { section } = action;
      if (!section) {
        return { ...state, activeSectionId: null };
      } else {
        const newState = { ...state };

        // If section is not in list, add it
        if (!(section.id in state.sections)) {
          newState.sections[section.id] = {
            ...INITIAL_SECTION_STATE,
            ...section,
          };
        }
        // If section is in list, update it
        else {
          newState.sections[section.id] = {
            ...newState.sections[section.id],
            ...section,
          };
        }
        newState.activeSectionId = section.id;
        return newState;
      }
    }
    case INCREASE_NEW_POST_COUNT: {
      const { sectionId, postId } = action;
      const newState = { ...state };
      // check if not already in list
      if (
        !newState.sections[sectionId].posts.find((p) => p.id === postId) &&
        !newState.sections[sectionId].pinnedPosts.find((p) => p.id === postId)
      ) {
        newState.sections[sectionId] = {
          ...INITIAL_SECTION_STATE,
          ...newState.sections[sectionId],
          newPostCount: newState.sections[sectionId].newPostCount + 1,
        };
      }
      return newState;
    }
    case RESET_NEW_POST_COUNT: {
      const { sectionId } = action;
      const newState = { ...state };
      newState.sections[sectionId] = {
        ...INITIAL_SECTION_STATE,
        ...newState.sections[sectionId],
        newPostCount: 0,
      };
      return newState;
    }
    case GET_SECTION_PINNED_POSTS: {
      const { sectionId } = action;
      // get section
      const newState = { ...state };
      newState.sections[sectionId] = {
        ...INITIAL_SECTION_STATE,
        ...newState.sections[sectionId],
        isLoadingPinnedList: true,
        pinnedPosts: [],
        pinnedErrorMessage: null,
      };
      return newState;
    }
    case SET_SECTION_PINNED_POSTS: {
      const { posts, sectionId } = action;
      // get section
      const newState = { ...state };
      newState.sections[sectionId] = {
        ...INITIAL_SECTION_STATE,
        ...newState.sections[sectionId],
        isLoadingPinnedList: false,
        pinnedPosts: posts,
        pinnedErrorMessage: null,
      };
      return newState;
    }
    case ADD_SECTION_PINNED_POST: {
      const { post } = action;
      // add post to highlight if needed
      const newState = { ...state };
      const { id, tabId } = post;
      const existingPinnedPosts = [...newState.sections[tabId].pinnedPosts];
      const hasPinnedPostAlready = existingPinnedPosts.find((p) => p.id === id);
      if (hasPinnedPostAlready) {
        // do nothing
        return state;
      } else {
        newState.sections[tabId] = {
          ...INITIAL_SECTION_STATE,
          ...newState.sections[tabId],
          pinnedPosts: [...existingPinnedPosts, post],
        };
        return newState;
      }
    }
    case REMOVE_SECTION_PINNED_POST: {
      const { post } = action;
      // add post to highlight if needed
      const newState = { ...state };
      const { id, tabId } = post;
      const existingPinnedPosts = [...newState.sections[tabId].pinnedPosts];
      const pinnedPostIndex = existingPinnedPosts.findIndex((p) => p.id === id);
      if (pinnedPostIndex === -1) {
        // do nothing
        return state;
      } else {
        existingPinnedPosts.splice(pinnedPostIndex, 1);
        newState.sections[tabId] = {
          ...INITIAL_SECTION_STATE,
          ...newState.sections[tabId],
          pinnedPosts: existingPinnedPosts,
        };
        return newState;
      }
    }
    case UPDATE_SECTION_PINNED_POST: {
      const { post } = action;
      // add post to highlight if needed
      const newState = { ...state };
      const { id, tabId } = post;
      const existingPinnedPosts = [...newState.sections[tabId].pinnedPosts];
      const pinnedPostIndex = existingPinnedPosts.findIndex((p) => p.id === id);
      if (pinnedPostIndex === -1) {
        // do nothing
        return state;
      } else {
        existingPinnedPosts.splice(pinnedPostIndex, 1, post);
        newState.sections[tabId] = {
          ...INITIAL_SECTION_STATE,
          ...newState.sections[tabId],
          pinnedPosts: existingPinnedPosts,
        };
        return newState;
      }
    }
    case GET_SECTION_POSTS: {
      const { sectionId, pageNo } = action;
      // get section
      const newState = { ...state };
      newState.sections[sectionId] = {
        ...INITIAL_SECTION_STATE,
        ...newState.sections[sectionId],
        isLoadingPosts: pageNo === 1 ? true : false,
        postsLoadingState: pageNo === 1 ? "" : null,
        posts:
          !pageNo || pageNo === 1 ? [] : newState.sections[sectionId].posts,
        noMorePosts: false,
        errorMessage: null,
        isLoadingMorePosts: !pageNo || pageNo === 1 ? false : true,
      };
      return newState;
    }
    case SET_SECTION_POSTS: {
      const { posts, sectionId, pageNo } = action;
      // get section
      const newState = { ...state };
      newState.sections[sectionId] = {
        ...INITIAL_SECTION_STATE,
        ...newState.sections[sectionId],
        isLoadingPosts: false,
        currentPage: pageNo,
        posts:
          !pageNo || pageNo === 1
            ? posts
            : [...newState.sections[sectionId].posts, ...posts],
        errorMessage: null,
        noMorePosts: !posts?.length || posts?.length < POSTS_PER_PAGE,
        isLoadingMorePosts: false,
      };
      return newState;
    }
    case SET_CURRENT_SECTION_POSTS: {
      const { posts } = action;
      const { activeSectionId: sectionId } = state;
      const newState = { ...state };
      newState.sections[sectionId] = {
        ...INITIAL_SECTION_STATE,
        ...newState.sections[sectionId],
        posts,
      };
      return newState;
    }
    case SECTION_POST_LIKE_REALTIME_INCREMENT: {
      return sectionPostLikeRealtimeIncrementReducer(state, action);
    }
    case SECTION_POST_COMMENT_REALTIME_ADD: {
      return sectionPostCommentAddReducer(state, action);
    }
    case SECTION_POST_COMMENT_REALTIME_REMOVE: {
      return sectionPostCommentRemoveReducer(state, action);
    }
    case SECTION_POST_COMMENT_REALTIME_REACTION_UPDATE: {
      return sectionPostCommentReactionUpdateReducer(state, action);
    }
    default:
      return state;
  }
}
