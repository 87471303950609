import cx from "classnames";
interface Props {
  className?: string;
}
export default function CircleCheck({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx("h-5 w-5", className)}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
      aria-labelledby="title-06 desc-06">
      <title id="title-06">Circle Checkmark</title>
      <desc id="desc-06">Checkmark in circle</desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}
