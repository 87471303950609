import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

const lesson = {
  /**
   * create/update lesson in section
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {Object} lessonId lesson id is optional. If lessonId is provided, the lesson will be updated instead of created.
   */
  createLesson: (communityId, courseId, sectionId, lessonId) =>
    base +
    (!lessonId
      ? `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons`
      : `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons/${lessonId}`),

  /**
   * Get lesson details
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   */
  getLessonDetails: (communityId, courseId, sectionId, lessonId) =>
    base +
    `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons/${lessonId}`,

  /**
   * Delete lesson
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   */
  deleteLesson: (communityId, courseId, sectionId, lessonId) =>
    base +
    `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons/${lessonId}`,

  /**
   * Update lesson cover media
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   */
  updateLessonCover: (communityId, courseId, sectionId, lessonId) =>
    base +
    `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons/${lessonId}/cover`,

  /**
   * Mark lesson as complete
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId the section id
   * @param {string} lessonId the lesson id
   * @param {string} userId the user id
   */
  markLessonComplete: (communityId, courseId, sectionId, lessonId) =>
    base +
    `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons/${lessonId}/mark-complete`,

  /**
   * Mark lesson as in complete
   */
  markIncomplete: (communityId, courseId, sectionId, lessonId) =>
    base +
    `community/${communityId}/courses/${courseId}/section/${sectionId}/lessons/${lessonId}/mark-incomplete`,
};

const section = {
  /**
   * Create/update section in course
   * @param {string} communityId the community id
   * @param {string} courseId the course id
   * @param {string} sectionId section id is optional. If sectionId is provided, the section will be updated instead of created.
   */
  createSection: (communityId, courseId, sectionId) =>
    base +
    (!sectionId
      ? `community/${communityId}/courses/${courseId}/section`
      : `community/${communityId}/courses/${courseId}/section/${sectionId}`),
  /** Get all sections  */
  getAll: (communityId, courseId) =>
    base + `community/${communityId}/courses/${courseId}/sections`,

  deleteSection: (communityId, courseId, sectionId) =>
    base + `community/${communityId}/courses/${courseId}/section/${sectionId}`,

  /** Get a section details */
  getSectionDetails: (communityId, courseId, sectionId) =>
    base + `community/${communityId}/courses/${courseId}/sections/${sectionId}`,

  lesson: lesson,
};

export default {
  /**
   * Create/update course
   * @param {string} communityId the community id
   * @param {string} courseId course id is optional. If courseId is provided, the course will be updated instead of created.
   */
  createCourse: (communityId, courseId) =>
    base +
    (!courseId
      ? `community/${communityId}/courses`
      : `community/${communityId}/courses/${courseId}`),
  uploadCourseBanner: (communityId, courseId) =>
    base + `community/${communityId}/courses/${courseId}/banner`,
  /** Get all courses  */
  getAll: (communityId) => base + `community/${communityId}/courses`,
  /** Get a course details */
  getCourseDetails: (communityId, courseId) =>
    base + `community/${communityId}/courses/${courseId}`,
  deleteCourse: (communityId, courseId) =>
    base + `community/${communityId}/courses/${courseId}`,
  paginatedMembers: (communityId, courseId, page = 1, limit = 10) =>
    base +
    `community/${communityId}/courses/${courseId}/members/paginated?` +
    `page=${page}&limit=${limit}`,
  addMember: (communityId, courseId, userId) =>
    base + `community/${communityId}/courses/${courseId}/accept/${userId}`,
  removeMember: (communityId, courseId, userId) =>
    base + `community/${communityId}/courses/${courseId}/reject/${userId}`,
  joinCourse: (communityId, courseId) =>
    base + `community/${communityId}/courses/${courseId}/join`,
  sections: section,
};
