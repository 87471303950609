import useLang from "../../hooks/use-lang.hook";
import { Button } from "../button.component";

interface Props {
  query: string;
  placeholder?: string;
  setQuery: (query: string) => void;
  onSearch: (query: string) => void;
}
function SearchBar({
  setQuery,
  query,
  placeholder = "Search",
  onSearch,
}: Props) {
  const searchPhotos = async (e: any) => {
    e.preventDefault();
    onSearch(query);
  };

  const lang = useLang();

  return (
    <>
      <div>
        <form onSubmit={searchPhotos} className="flex items-center space-x-2">
          <input
            className="placeholder:italic placeholder:theme-text-subtitle-1 theme-bg-default w-full border theme-border-default rounded-md py-2 pl-3 pr-3  focus:outline-none focus:theme-border-primary  focus:ring-1 sm:text-sm"
            placeholder={lang.trans(placeholder)}
            type="text"
            name="search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />

          <Button
            label={"Search"}
            onClick={searchPhotos}
            className="word-breaker"
          />
        </form>
      </div>
    </>
  );
}

export default SearchBar;
