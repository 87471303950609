import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastTypes } from "../../../../contexts/toastr.context";
import { useToast } from "../../../../hooks/use-toast.hook";
import { Course } from "../../../../types/course/course.type";
import { PaymentPreference } from "../../../../types/payment-preference/payment-preference.type";
import { SubscriptionPlan } from "../../../../types/subscription/subscription-plan";
import { Button } from "../../../../_components";
import Tooltip from "../../../../_components/atoms/tooltip";
import ComponentSwitcher from "../../../../_components/component-switcher/component-switcher.component";
import IconButton2 from "../../../../_components/icon-button-2-component";
import { Modal } from "../../../../_components/modal.component";
import { TableBody } from "../../../../_components/molecule/listview.component";
import Label from "../../../../_components/typography/label.typography";
import { SubscriptionService } from "../../../../_service";
import { store } from "../../../../_store";
import { getAllCourses } from "../../../../_store/courses/courses.action";
import { getPaymentPreference } from "../../../../_store/preference/preference.action";
import DualPageHeaderComponent from "../../components/dual-page-header";
import { MemberTableRowShimmer } from "../../invited-users-list";
import SubscriptionTile from "../component/subscription_tile.component";
import CreateSubscriptions from "../create/create-subscription-plan.component";
import { SubscriptionDetail } from "../subscription-detail/subscription-detail-component";
import LinkPaywallView from "./link-paywall-view";

export const SUBSCRIPTION_PAGE_ROUTE = "/settings/subscriptions";
interface SubscriptionsPageProps {
  community: any;
  groups: Array<any>;
  courses: {
    list: Array<Course> | undefined;
    status: Status;
  };
  paymentPreference: {
    preference: PaymentPreference;
    status: Status;
  };
}
/**
 * Displays the community subscriptions. This page is only visible to the community admin.
 * Community admin can create new subscription plans, edit existing subscription plans and delete subscription plans.
 * @param {Object} community - community object
 * @param {Array<any>} groups - groups Array
 * @param {Array<Course>} courses - courses Array
 */

export type Status = "idle" | "loading" | "success" | "error";
const Subscriptions = ({
  community,
  groups,
  courses,
  paymentPreference,
}: SubscriptionsPageProps) => {
  const [status, setStatus] = useState<Status>("idle");
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlan[]>([]);
  const [isSubscriptionVisible, setIsSubscriptionVisible] = useState(false);

  const toast = useToast();
  const communityId = community.id;

  const [selectedSubscription, setSelectedSubscription] = useState<
    SubscriptionPlan | undefined
  >();

  // Get all subscriptions when app loads
  useEffect(() => {
    if (!communityId || (subscriptions && subscriptions.length > 0)) {
      return;
    }
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  // Get payment preference for the community
  useEffect(() => {
    if (paymentPreference.status === "idle") {
      store.dispatch(getPaymentPreference());
    }
  }, [paymentPreference]);

  // Get community courses
  useEffect(() => {
    if (courses.status === "idle") {
      store.dispatch(getAllCourses());
    }
  }, [courses, community?.id]);

  // Call API to get all subscriptions
  const getSubscriptions = async () => {
    setStatus("loading");
    SubscriptionService.getAllSubscriptionsAdmin(communityId)
      .then(({ subscriptions }) => {
        setSubscriptions(subscriptions);
        setStatus("success");
      })
      .catch((error) => {
        console.error("error", error);
        toast.addToast("Error fetching subscriptions", "", ToastTypes.danger);
      });
  };

  return (
    <>
      {/* header */}
      <div className="SubscriptionsPage flex flex-col theme-bg-surface">
        <DualPageHeaderComponent
          isSecondPageVisible={Boolean(selectedSubscription)}
          setSecondPageVisible={() => {
            setSelectedSubscription(undefined);
          }}
          secondPageTitle="Go back to subscription"
          firstPageTitle="Subscriptions"
          trailingIcons1={
            <div className="flex space-x-2">
              <Tooltip label={"Refresh"}>
                <IconButton2
                  icon="rotate-right"
                  hoverable
                  onClick={() => {
                    getSubscriptions();
                  }}
                />
              </Tooltip>
              <Button
                label="Create Subscription"
                onClick={() => {
                  setIsSubscriptionVisible(true);
                }}
              />
            </div>
          }
        />

        {/* SUBSCRIPTIONS DETAILS  */}
        <ComponentSwitcher
          transition="slideFromRight"
          isActiveFirst={Boolean(selectedSubscription)}
          className="theme-bg-surface"
          child2={
            <SubscriptionsList
              communityId={communityId}
              subscriptions={subscriptions}
              status={status}
              courses={courses}
              groups={groups}
              paymentPreference={paymentPreference?.preference}
              setSubscriptions={setSubscriptions}
              onSubscriptionSelect={(subscription: any) => {
                setSelectedSubscription(subscription);
              }}
            />
          }
          child1={
            <SubscriptionDetail
              subscription={selectedSubscription!}
              communityId={communityId}
              groups={groups}
              courses={courses}
            />
          }
          duration={100}
        />
        {/* CREATE SUBSCRIPTION PLAN MODEL*/}
        <Modal
          active={isSubscriptionVisible}
          padding={false}
          isDismissible={false}
          setActive={setIsSubscriptionVisible}>
          <CreateSubscriptions
            community={community}
            groups={groups}
            courses={courses}
            isSubscriptionVisible={isSubscriptionVisible}
            setIsSubscriptionVisible={setIsSubscriptionVisible}
            onSubscriptionCreated={(sub: any) => {
              setSubscriptions([...subscriptions, sub]);
            }}
          />
        </Modal>
      </div>
    </>
  );
};

/**
 * Displays the list of subscriptions
 * @param {Array<any>} subscriptions - subscriptions Array
 * @param {String} status - status of the API call
 */
function SubscriptionsList({
  communityId,
  subscriptions,
  status,
  courses,
  groups,
  paymentPreference,
  onSubscriptionSelect = (_) => {},
  setSubscriptions = () => {},
}: {
  communityId: string;
  subscriptions: Array<SubscriptionPlan>;
  status: Status;
  paymentPreference: PaymentPreference;
  groups: Array<any> | undefined;
  courses: {
    list: Array<Course> | undefined;
    status: Status;
  };
  onSubscriptionSelect: (subscription: any) => void;
  setSubscriptions: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  const SortedSubscriptions = subscriptions
    .filter((sub) => !sub.isDeleted)
    .concat(subscriptions.filter((sub) => sub.isDeleted));

  // Check if any payment preference is set
  // If not, show the link paywall view
  const isPaywallAVailable = !["not-set", undefined, null].includes(
    paymentPreference?.paymentMethod
  );
  return (
    <>
      <div className="mx-4 sm:mx-0 pb-4 theme-bg-surface p-4">
        {!isPaywallAVailable && <LinkPaywallView />}
        <table className="table-auto min-w-full text-sm theme-text-subtitle-1 border theme-border-default w-full rounded">
          <thead className="text-xs text-left theme-text-headline-1 uppercase theme-bg-default py-3">
            <tr>
              <th className="py-3 p-1 md:px-4 text-left">
                <Label variant="t1">Subscription Title</Label>
              </th>
              <th className="py-3 p-1 md:px-4 text-left ">
                <Label variant="t1">Amount</Label>
              </th>
              <th className="py-3 p-1 md:px-4 text-left ">
                <Label variant="t1">Created on</Label>
              </th>
              <th className="py-3 p-1 md:px-4 text-left max-w-[1000px]">
                <Label variant="t1">Current Users</Label>
              </th>
              <th className="py-3 p-1 md:px-4 text-left">
                <Label variant="t1"> Access to</Label>
              </th>
              <th className="py-3 p-1 md:px-4 text-left">
                <Label variant="t1">Status</Label>
              </th>
              <th className="py-3  text-left w-2"></th>
            </tr>
          </thead>
          <tbody className="theme-bg-surface">
            <TableBody
              items={SortedSubscriptions}
              loading={status === "loading"}
              renderItem={(sub: any, index: number) => (
                <SubscriptionTile
                  key={index}
                  communityId={communityId}
                  subscription={sub}
                  onSubscriptionUpdate={(sub: SubscriptionPlan) => {
                    const newSubscriptions = subscriptions.map((s) => {
                      if (s.id === sub.id) {
                        return sub;
                      }
                      return s;
                    });
                    setSubscriptions(newSubscriptions);
                  }}
                  courses={courses}
                  groups={groups}
                  onSelect={onSubscriptionSelect}
                />
              )}
              noItemsElement={
                <tr>
                  <td colSpan={7} className="py-3 px-4 text-center">
                    <Label variant="t1">No subscriptions found</Label>
                  </td>
                </tr>
              }
              placeholder={[...Array(10)].map((_, index) => (
                <MemberTableRowShimmer key={index} />
              ))}
            />
          </tbody>
        </table>
      </div>
    </>
  );
}

const SubscriptionsPage = connect(
  (s: any) => ({
    user: s.auth,
    community: s.community,
    groups: s.groups,
    courses: s.courses,
    paymentPreference: s.preferenceReducer.paymentPreference,
  }),
  (d) => bindActionCreators({}, d)
)(Subscriptions);
export default SubscriptionsPage;

// export default Subscriptions;
