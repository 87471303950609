import { MySubscription } from "../../../types/subscription/my-subscription-plan";
import { SET_MY_SUBSCRIPTION_PLAN } from "./my-subscription.action";

export interface BillingDetails {
  email: string;
  fullName: string;
  mobile: string;
  addressLine1: string;
  addressLine2: string;
  state: string;
  country: string;
}

const defaultState = {} as MySubscription;

/**
 * Subscription plan reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function mySubscriptionReducer(state: any = defaultState, action: any) {
  switch (action.type) {
    case SET_MY_SUBSCRIPTION_PLAN:
      return action.mySubscription;
    default:
      return state;
  }
}
