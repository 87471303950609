import cx from "classnames";

/**
 * Checkbox component
 * @param {boolean} selected  Whether the checkbox is selected or not
 * @param {function} onClick  Function to call when the checkbox is clicked
 * @param {boolean} disabled  Whether the checkbox is disabled or not
 * @param {boolean} semiSelected  Whether the checkbox is semi-selected or not
 * @param {any} children  Children of the checkbox
 */
export function Checkbox({
  children = <></>,
  className = "",
  selected = false,
  onClick = () => {},
  disabled = false,
  semiSelected = false,
}) {
  return (
    <div
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      className={cx(
        "Checkbox cursor-pointer transition-all duration-300 ease-linear",
        className,
        {
          selected: selected && !disabled,
          "cursor-not-allowed disabled-selected": selected && disabled,
          "cursor-not-allowed disabled ": !selected && disabled,
          semiSelected: !selected && semiSelected,
        }
      )}>
      {children}
    </div>
    // <div className="flex items-center">
    //   <div className="relative">
    //     <input
    //       className={cx(
    //         "peer h-4 w-4 cursor-pointer appearance-none rounded border-2  transition-colors  focus-visible:outline-none disabled:cursor-not-allowed  ",
    //         " checked:theme-border-primary-light focus:outline-none checked:bg-slate-800 ",
    //         " checked:theme-bg-primary  hover:theme-bg-primary checked:focus:theme-bg-primary",
    //         { " theme-border-primary-light": selected },
    //         { " theme-border-subtitle-1": !selected },
    //         {
    //           "disabled:theme-border-default disabled:theme-bg-disable bg-opacity-70":
    //             disabled,
    //         }
    //       )}
    //       type="checkbox"
    //       value=""
    //       disabled={disabled}
    //       defaultChecked={selected}
    //       id="id-c01"
    //       checked={selected}
    //       onClick={onClick}
    //     />

    //     <svg
    //       className="pointer-events-none absolute left-0 inset-0 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
    //       viewBox="0 0 16 16"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //       aria-hidden="true"
    //       aria-labelledby="title-1 description-1">
    //       <title id="title-1">Check mark icon</title>
    //       <desc id="description-1">
    //         Check mark icon to indicate whether the radio input is checked or
    //         not.
    //       </desc>
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
    //       />
    //     </svg>
    //   </div>
    //   <label
    //     className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
    //     htmlFor="id-c01">
    //     {children}
    //   </label>
    // </div>
  );
}
