interface CommunitySpecificProps {
  communityId: string;
  communityName: string;
  communityDomain: string;
  googleAdsenseId?: string;
  googleAdSlotId?: string;
  googleAnalyticsId?: string;
  mixpanelId?: string;
}

const communityConfigList: CommunitySpecificProps[] = [
  {
    communityId: "649e8f19cfd83dc4c97479c0",
    communityName: "Diskusjoner",
    communityDomain: "adnan.pensil.in",
    googleAdsenseId: "ca-pub-3456762661974715",
    googleAdSlotId: "7126302351",
  },
  {
    communityId: "649e8f19cfd83dc4c97479c0",
    communityName: "Diskusjoner",
    communityDomain: "www.diskusjoner.no",
    googleAdsenseId: "ca-pub-3456762661974715",
    googleAdSlotId: "7126302351",
  },
  {
    communityId: "60868502af8027342aa13315",
    communityName: "Tech - 1",
    communityDomain: "tech-1.pensil.in",
    googleAdsenseId: "ca-pub-3456762661974715",
    googleAdSlotId: "7126302351",
  },
  // {
  //   communityId: "60868502af8027342aa13315",
  //   communityName: "Tech - 1",
  //   communityDomain: "tech-1.pensil.com",
  //   googleAdsenseId: "ca-pub-3456762661974715",
  //   googleAdSlotId: "7126302351",
  // },
  {
    communityId: "627e504b164ff3b288962852",
    communityName: "Tech - 1",
    communityDomain: "tech-1.pensil.so",
    googleAdsenseId: "ca-pub-3456762661974715",
    googleAdSlotId: "7126302351",
  },
];

// Get community specific config based on domain
const communitySpecificConfig = communityConfigList.find(
  (config) => config.communityDomain === window.location.hostname
);

export default communitySpecificConfig;
