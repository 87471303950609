import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class PostService {
  /**
   * Get posts paginated
   * @param {*} user
   * @param {*} page
   * @returns
   */
  static async getPostsPaginated(user, page = 1) {
    return axios
      .get(endpoints.post.paginated(page), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get posts to explore paginated
   * @param {*} user
   * @param {*} page
   * @returns
   */
  static async getPostsToExplorePaginated(user, page = 1) {
    return axios
      .get(endpoints.post.popularPaginated(page), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async getPostDetail(user, postId) {
    return axios
      .get(endpoints.post.detail(postId), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  static async getPostDetailFromSlug(user, communityId, slug) {
    return axios
      .get(endpoints.post.detailFromSlug(communityId, slug), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  static async getPostLikes(user, postId) {
    return axios
      .get(endpoints.post.likes(postId), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Create a post from post object
   * @param {*} user
   * @param {*} post
   * @returns
   */
  static async createPost(user, post) {
    console.log(post)
    return axios
      .post(endpoints.post.create, post, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async getTagsBySearch(user, query, communityId) {
    return axios
      .get(endpoints.post.searchTag(query, communityId), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }
  // Get Posts by tag search
  static async getPostByTagSearch(
    user,
    communityId,
    page,
    tags,
    tabId,
    groupId
  ) {
    return axios
      .get(
        endpoints.post.getPostByTag(communityId, page, tags, tabId, groupId),
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
  /**
   * Update a post from post object
   * @param {*} user
   * @param {*} post
   * @returns
   */
  static async updatePost(user, post, removedMediaUploadsOnEdit, postId) {
    console.log("post data and media links to be deleted, will directly go to backend now:: ", post, removedMediaUploadsOnEdit)

    post.removedMediaUploadsOnEdit = removedMediaUploadsOnEdit;
    return axios
      .post(endpoints.post.update(postId), post, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Upload images
   * @param {*} user
   * @param {*} postId
   * @param {*} images
   * @returns
   */
  static async uploadImages(
    user,
    postId = "",
    images = [],
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images[]", image, image.fileName);
    });
    return axios
      .post(endpoints.post.uploadImage(postId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentComplete(percentCompleted);
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete images
   * @param {*} user
   * @param {*} postId
   * @returns
   */
  static async deleteImages(user, postId = "") {
    return axios
      .delete(endpoints.post.uploadImage(postId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Upload videos in post
   * @param {*} user
   * @param {*} postId
   * @param {*} videos
   * @returns
   */
  static async uploadVideos(
    user,
    postId = "",
    videos = [],
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    videos.forEach((video) => {
      formData.append("videos[]", video, video.fileName);
    });
    return axios
      .post(endpoints.post.uploadVideo(postId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentComplete(percentCompleted);
        },
      })
      .then((response) => response.data);
  }

  /**
   * TODO: TO be implemented in backend first
   * Delete images
   * @param {*} user
   * @param {*} postId
   * @returns
   */
  static async deleteVideos(user, postId = "") {
    const formData = new FormData();
    formData.append("videos[]", []);
    return axios
      .post(endpoints.post.uploadVideo(postId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Upload document
   * @param {*} user
   * @param {*} postId
   * @param {*} document
   * @returns
   */
  static async uploadDocument(
    user,
    postId = "",
    document,
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    formData.append("document", document, document.fileName);
    return axios
      .post(endpoints.post.uploadDocument(postId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentComplete(percentCompleted);
        },
      })
      .then((response) => response.data);
  }
  /**
   * Upload document
   * @param {*} user
   * @param {*} postId
   * @param {*} banner
   * @returns
   */
  static async uploadCoverPicture(
    user,
    postId = "",
    banner,
    setPercentComplete = (e) => {}
  ) {
    const formData = new FormData();
    formData.append("banner", banner, banner.fileName);
    return axios
      .post(endpoints.post.uploadBanner(postId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercentComplete(percentCompleted);
        },
      })
      .then((response) => response.data);
  }

  /**
   * Pin a post
   * @param {*} user
   * @param {*} postId
   * @param {*} document
   * @returns
   */
  static async pinPost(user, postId = "") {
    return axios
      .post(
        endpoints.post.pin(postId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Unpin a post
   * @param {*} user
   * @param {*} postId
   * @param {*} document
   * @returns
   */
  static async unpinPost(user, postId = "") {
    return axios
      .post(
        endpoints.post.unpin(postId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Bookmark a post
   * @param {*} user
   * @param {*} postId
   * @param {*} document
   * @returns
   */
  static async bookmarkPost(user, postId = "") {
    return axios
      .post(
        endpoints.post.bookmark(postId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Un??Bookmark a post
   * @param {*} user
   * @param {*} postId
   * @param {*} document
   * @returns
   */
  static async removeBookmark(user, postId = "") {
    return axios
      .delete(endpoints.post.removeBookmark(postId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get user bookmarks
   * @param {*} user
   * @param {*} postId
   * @param {*} document
   * @returns
   */
  static async getBookmarks() {
    return axios
      .get(endpoints.post.getBookmarks, {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Answer with the user selected option
   * @param {*} user
   * @param {*} postId
   * @param {*} option
   */
  static async voteOnPoll(user, postId = "", option) {
    return axios
      .post(
        endpoints.post.vote(postId),
        { option },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Like a post
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async likePost(user, postId) {
    return axios
      .post(
        endpoints.post.like(postId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Dislike a post
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async dislikePost(user, postId) {
    return axios
      .post(
        endpoints.post.dislike(postId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Add comment on pole
   * @param {*} user
   * @param {*} postId
   * @param {*} comment
   * @returns
   */
  static async addComment(user, postId, comment = { description: "" }) {
    return axios
      .post(endpoints.post.addComment(postId), comment, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async uploadImagesToComment(user, postId, commentId, images) {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images[]", image, image.fileName);
    });

    return axios
      .post(endpoints.post.uploadImagesToComment(postId, commentId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async uploadDocumentToComment(user, postId, commentId, document) {
    const formData = new FormData();
    formData.append("document", document, document.fileName);

    return axios
      .post(
        endpoints.post.uploadDocumentToComment(postId, commentId),
        formData,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async uploadDocumentToCommentReply(
    user,
    postId,
    commentId,
    replyId,
    document
  ) {
    const formData = new FormData();
    document.forEach((document) => {
      formData.append("documents[]", document, document.fileName);
    });
    return axios
      .post(
        endpoints.post.uploadDocumentToCommentReply(postId, commentId, replyId),
        formData,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async deleteComment(user, postId, commentId) {
    return axios
      .delete(endpoints.post.deleteComment(postId, commentId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async deleteSubComment(user, postId, commentId, replyId) {
    return axios
      .delete(endpoints.post.deleteSubComment(postId, commentId, replyId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete a post
   * @param {*} user
   * @param {*} groupId
   * @returns
   */
  static async deletePost(user, postId) {
    return axios
      .delete(endpoints.post.delete(postId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Add comment on pole
   * @param {*} user
   * @param {*} postId
   * @param {*} commentId
   * @returns
   */
  static async addCommentReply(user, postId, commentId, description) {
    return axios
      .post(endpoints.post.addCommentReply(postId, commentId), description, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Add comment reaction
   * @param {*} user
   * @param {*} postId
   * @param {*} commentId
   * @param {*} emoji
   * @returns
   */
  static async addCommentReaction(user, postId, commentId, emoji) {
    return axios
      .post(
        endpoints.post.addCommentReaction(postId, commentId),
        { emoji },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * remove comment reaction
   * @param {*} user
   * @param {*} postId
   * @param {*} commentId
   * @param {*}
   * @returns
   */
  static async removeCommentReaction(user, postId, commentId) {
    return axios
      .post(
        endpoints.post.removeCommentReaction(postId, commentId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Add comment reply reaction
   * @param {*} user
   * @param {*} postId
   * @param {*} commentId
   * @param {*} emoji
   * @returns
   */
  static async addCommentReplyReaction(
    user,
    postId,
    commentId,
    replyId,
    emoji
  ) {
    return axios
      .post(
        endpoints.post.addCommentReplyReaction(postId, commentId, replyId),
        { emoji },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * remove comment reaction
   * @param {*} user
   * @param {*} postId
   * @param {*} commentId
   * @param {*}
   * @returns
   */
  static async removeCommentReplyReaction(user, postId, commentId, replyId) {
    return axios
      .post(
        endpoints.post.removeCommentReplyReaction(postId, commentId, replyId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
}

export { PostService };
