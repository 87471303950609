import { useEffect, useState } from "react";
import IconMenu2, { ActionType } from "../../icon-menu-2.component";
import { PostDocument, PostImage } from "../post-card.component";

import cx from "classnames";
import moment from "moment";
import { Remarkable } from "remarkable";
import useLang from "../../../hooks/use-lang.hook";
import { createUserWallPageRoute } from "../../../_pages/user-wall.page";
import { PostService } from "../../../_service";
import { ActionModal } from "../../action-modal.component";
import CustomDropDown from "../../atoms/custom-dropdown";
import I18 from "../../atoms/i18";
import { Avatar } from "../../avatar.component";
import EmojiPicker from "../../form-controls/emoji-picker.component";
import IconButton2 from "../../icon-button-2-component";
import IconButton from "../../icon-button.component";
import { Loader } from "../../loader.component";
import UIcon from "../../uicon-component";
import CreateComment from "./create-comment";
import { getSegmentedPath } from "../../../_utils/segmented-community-util";
const md = new Remarkable();

/**
 * Component to show a post comment
 * @param {*} param0
 * @returns
 */
export function PostComment({
  post,
  user,
  group,
  comment,
  postId,
  updatePost,
  updateComment,
  addToast,
}) {
  const imageFormat = ["png", "jpg", "jpeg", "gif"];
  const lang = useLang();

  const [isProcessing, setIsProcessing] = useState(false);
  const [subCommentView, setSubCommentView] = useState(false);
  const [commentRepliesArr, setCommentRepliesArr] = useState([]);
  //opening delete confirmation tab
  const [visibleDeleteComment, setVisibleDeleteComment] = useState(false);
  const [commentImageToBePreviewed, setCommentImageToBePreviewed] =
    useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  useEffect(() => {
    setCommentRepliesArr(comment.replies ? comment.replies : []);
  }, [comment.replies]);

  const showSubComment = () => {
    setSubCommentView(!subCommentView);
  };

  const onCommentUpdate = (commentReply) => {
    let newSubCommentArr = [...commentRepliesArr, commentReply];
    setCommentRepliesArr(newSubCommentArr);
    setSubCommentView(true);
  };

  // handle react to commnet
  const handleReactToComment = (emoji) => {
    console.log({ emoji });
    PostService.addCommentReaction(user, postId, comment.id, emoji)
      .then(({ comment }) => {
        // update comment
        updateComment(comment);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  // handle react to commnet
  const handleRemoveReactToComment = () => {
    PostService.removeCommentReaction(user, postId, comment.id)
      .then(({ comment }) => {
        // update comment
        updateComment(comment);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const onSubCommentDelete = (commentReply) => {
    let updatedSubCommentArr = commentRepliesArr.filter(
      (re) => re.id !== commentReply.id
    );
    setCommentRepliesArr(updatedSubCommentArr);
  };

  useEffect(() => {
    const cb = (e) => {
      setCommentImageToBePreviewed(e.target.src);
    };

    document
      .querySelectorAll("#PostComment PostImages PostImage img")
      .forEach((img) => {
        img.addEventListener("click", cb);
      });

    return () => {
      document
        .querySelectorAll("#PostComment PostImages PostImage img")
        .forEach((img) => {
          img.removeEventListener("click", cb);
        });
    };
  }, [post]);

  const sample = moment
    .duration(moment(new Date()).diff(moment(comment.createdAt)))
    .asDays();

  const postCommentDomId = comment.id ? "PostComment_" + comment.id : null;

  /**
   * Enable copy button on pre code parent
   */
  useEffect(() => {
    if (postCommentDomId) {
      const codeElemeents = document.querySelectorAll(
        `#${postCommentDomId} pre>code`
      );
      codeElemeents.forEach((codeElement) => {
        const preElement = codeElement.parentElement;
        preElement.style.position = "relative";
        const copyButtonElement = document.createElement("span");
        copyButtonElement.style.position = "absolute";
        copyButtonElement.style.top = "0px";
        copyButtonElement.style.right = "5px";
        copyButtonElement.style.cursor = "pointer";
        copyButtonElement.innerText = "Copy";
        copyButtonElement.onclick = async () => {
          try {
            await navigator.clipboard.writeText(codeElement.innerText);
            return;
          } catch (error) {
            console.error("Cannot copy to clipboard, trying legacy");
          }
          const copyElem = document.createElement("textarea");
          copyElem.value = codeElement.innerText;
          document.body.appendChild(copyElem);
          copyElem.select();
          document.execCommand("copy");
          document.body.removeChild(copyElem);
          addToast("Copied to clipboard");
        };
        preElement.appendChild(copyButtonElement);
      });
    }
  }, [comment.description]);

  return (
    <>
      {commentImageToBePreviewed &&
      imageFormat.includes(commentImageToBePreviewed.split(".").pop()) ? (
        <div
          className="PostImageFullPreview"
          onClick={(e) => {
            setCommentImageToBePreviewed(null);
          }}>
          <div className="holder">
            <img src={commentImageToBePreviewed} alt="Preview" loading="lazy" />
            <IconButton
              icon="plus"
              rotate={45}
              className="close-button"
              onClick={(e) => {
                setCommentImageToBePreviewed(null);
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="flex">
        <div className="mt-3">
          <div className="cursor-pointer">
            <Avatar
              user={comment.createdBy}
              noName
              onClick={(e) => {
                window.open(
                  getSegmentedPath(createUserWallPageRoute(comment.createdBy.id, "activity"))
                );
              }}
            />
          </div>
          {subCommentView ? (
            <div className="border-l-2 theme-border-default ml-4 mt-3 avatar-thread-border"></div>
          ) : (
            <></>
          )}
        </div>
        <div
          key={comment.id}
          id={postCommentDomId}
          className="PostComment w-full my-2 pl-3 pt-1 rounded theme-bg-default">
          <div className="flex justify-between items-center">
            <div className="text-sm">
              <Avatar
                user={comment.createdBy}
                extraInfo={
                  sample < 1
                    ? moment(comment.createdAt).fromNow()
                    : moment(comment.createdAt).format("DD MMM YYYY")
                }
                noPicture
                size={36}
                className="cursor-pointer"
                onClick={(e) => {
                  window.open(
                    getSegmentedPath(createUserWallPageRoute(comment.createdBy.id, "activity"))
                  );
                }}
              />
            </div>
            <IconMenu2
              icon="menu-dots"
              actions={[
                {
                  icon: "trash",
                  label:
                    (user &&
                      comment.createdBy &&
                      comment.createdBy.id === user.id) ||
                    post.canDeleteComment
                      ? "Delete"
                      : "",
                  onClick: (e) => {
                    setIsProcessing(true);
                    setVisibleDeleteComment(true);
                    setIsProcessing(false);
                  },
                  actionType: ActionType.alert,
                },
              ]}
              hideOnEmpty
            />
            <ActionModal
              active={visibleDeleteComment}
              setActive={setVisibleDeleteComment}
              header="Delete Comment"
              title="Are you sure you want to delete this comment?"
              onSubmit={(e) => {
                PostService.deleteComment(user, postId, comment.id)
                  .then((response) => {
                    updatePost(response.post);
                    addToast("Comment deleted!");
                  })
                  .catch((err) => {
                    setIsProcessing(false);
                  });
              }}
            />
          </div>
          <div className="py-1 text-sm font-light">
            <div
              className="NL2BR headings"
              dangerouslySetInnerHTML={{
                __html: md.render(comment.description),
              }}
            />
          </div>
          <div className="PostImages">
            {comment.images && comment.images.length > 0 ? (
              <PostImage
                image={comment.images[0]}
                className={cx("object-cover object-center", {
                  "h-40 max-w-max  rounded":
                    comment.images[0].split(".").pop() === "gif",
                })}
              />
            ) : (
              <></>
            )}
            <PostDocument post={comment} smallMargin />
          </div>
          <div className="flex justify-between items-center my-2 pb-1.5 pr-2">
            {/* left side icons */}
            <div className="flex items-center space-x-1.5">
              <div onClick={showSubComment}>
                {user ? (
                  <div className="flex p-0.5 pl-2 pr-2 rounded theme-bg-disable items-center cursor-pointer">
                    <UIcon icon="undo" />
                    <span className="text-xs font-light pl-1">
                      <I18>Reply</I18>
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* emoji icon */}
              <CustomDropDown
                alignment="left-1"
                dropdownClassName="-left-32 sm:left-1"
                button={
                  <IconButton2
                    icon="grin-alt"
                    size="md"
                    iconClass="theme-text-subtitle-2"
                    className="h-4 "
                    onClick={() => {
                      setIsEmojiPickerVisible(true)
                    }}
                  />
                }>
                {/* emoji picker */}
                {isEmojiPickerVisible && (
                  <EmojiPicker
                    emoji={null}
                    hidePlaceholder={true}
                    setEmoji={(emoji) => {
                      // set emoji
                      handleReactToComment(emoji);
                    }}
                    visible={true}
                  />
                )}
               
              </CustomDropDown>
              {/* reactions list */}
              {comment.reactions.details.map((reaction, index) => (
                <div
                  key={reaction.emoji + reaction.count + index}
                  className={cx(
                    "flex items-center py-0.5 px-1 text-xs font-light rounded cursor-pointer mr-2",
                    {
                      "theme-bg-primary theme-text-on-primary": reaction.isByMe,
                      "theme-bg-disable": !reaction.isByMe,
                    }
                  )}
                  onClick={(e) => {
                    // set reaction as this emoji
                    if (reaction.isByMe) {
                      handleRemoveReactToComment();
                    } else {
                      handleReactToComment(reaction.emoji);
                    }
                    // hit like api
                  }}
                  title={
                    "Reacted by " +
                    reaction.users.map((user) => user.name).join(", ")
                  }>
                  <span className="pr-1">{reaction.emoji}</span>
                  <span className="pl-1">{reaction.count}</span>
                </div>
              ))}
            </div>
            {commentRepliesArr && commentRepliesArr.length > 0 ? (
              <div onClick={showSubComment} className="mr-2">
                <span className="p-0.5 pl-2.5 pr-2.5 rounded text-xs theme-bg-disable font-light cursor-pointer">
                  {`${commentRepliesArr.length} ${
                    commentRepliesArr.length > 1
                      ? lang.trans("Replies")
                      : lang.trans("Reply")
                  }`}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {subCommentView ? (
            <>
              <div
                className={
                  commentRepliesArr.length > 0
                    ? "mr-2 rounded "
                    : "mr-2 pb-2 rounded "
                }>
                {commentRepliesArr.length > 0 ? (
                  commentRepliesArr.map((reply, index) => (
                    <PostSubComment
                      post={post}
                      group={group}
                      user={user}
                      postId={post.id}
                      commentRepliesArr={commentRepliesArr}
                      comment={comment}
                      reply={reply}
                      index={index}
                      key={reply.id}
                      deleteSubComment={onSubCommentDelete}
                      updateComment={updateComment}
                      addToast={addToast}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          {subCommentView ? (
            <div className="mr-4 pb-3">
              {!subCommentView ? (
                <div className="border-t theme-border-default mr-2 mt-4"></div>
              ) : (
                <></>
              )}
              <div className="my-1">
                <CreateComment
                  post={post}
                  user={user}
                  updatePost={onCommentUpdate}
                  commentId={comment.id}
                  isSubComment
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {isProcessing ? (
            <div className="processing">
              <Loader />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

/**
 * Component to show a post comment
 * @param {*} param0
 * @returns
 */
function PostSubComment({
  post,
  user,
  group,
  comment,
  reply,
  index,
  commentRepliesArr,
  postId,
  deleteSubComment,
  updateComment = (e) => {},
  addToast,
}) {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  //delete reply confirmation
  const [visibleDeleteComment, setVisibleDeleteComment] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  // handle react to commnet
  const handleReactToComment = (emoji) => {
    PostService.addCommentReplyReaction(
      user,
      postId,
      comment.id,
      reply.id,
      emoji
    )
      .then(({ comment }) => {
        // update comment
        updateComment(comment);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  // handle react to commnet
  const handleRemoveReactToComment = () => {
    PostService.removeCommentReplyReaction(user, postId, comment.id, reply.id)
      .then(({ comment }) => {
        // update comment
        updateComment(comment);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const sample = moment
    .duration(moment(new Date()).diff(moment(reply.createdAt)))
    .asDays();

  const postSubCommentDomId = comment.id
    ? "PostSubComment_" + comment.id
    : null;

  /**
   * Enable copy button on pre code parent
   */
  useEffect(() => {
    if (postSubCommentDomId) {
      const codeElemeents = document.querySelectorAll(
        `#${postSubCommentDomId} pre>code`
      );
      codeElemeents.forEach((codeElement) => {
        const preElement = codeElement.parentElement;
        preElement.style.position = "relative";
        const copyButtonElement = document.createElement("span");
        copyButtonElement.style.position = "absolute";
        copyButtonElement.style.top = "0px";
        copyButtonElement.style.right = "5px";
        copyButtonElement.style.cursor = "pointer";
        copyButtonElement.innerText = "Copy";
        copyButtonElement.onclick = async () => {
          try {
            await navigator.clipboard.writeText(codeElement.innerText);
            return;
          } catch (error) {
            console.error("Cannot copy to clipboard, trying legacy");
          }
          const copyElem = document.createElement("textarea");
          copyElem.value = codeElement.innerText;
          document.body.appendChild(copyElem);
          copyElem.select();
          document.execCommand("copy");
          document.body.removeChild(copyElem);
          addToast("Copied to clipboard");
        };
        preElement.appendChild(copyButtonElement);
      });
    }
  }, [comment.description]);

  return (
    <>
      <div
        key={reply.id}
        id={postSubCommentDomId}
        className="PostComment border-t theme-border-default rounded zm-auto mt-2 pl-1 pt-1">
        <div className="flex justify-between items-center">
          <div className="text-sm">
            <Avatar
              user={reply.createdBy}
              extraInfo={
                sample < 1
                  ? moment(reply.createdAt).fromNow()
                  : moment(reply.createdAt).format("DD MMM YYYY")
              }
              size={36}
              className="cursor-pointer"
              subCommentAvatar
              onClick={(e) => {
                // open the user page
                window.open(
                  getSegmentedPath(createUserWallPageRoute(reply.createdBy.id, "activity"))
                );
              }}
            />
          </div>
          <IconMenu2
            icon="menu-dots"
            hideOnEmpty
            actions={[
              {
                label:
                  (user && reply.createdBy && reply.createdBy.id === user.id) ||
                  post.canDeleteReply
                    ? "Delete"
                    : "",
                onClick: (e) => {
                  setIsProcessing(true);
                  setVisibleDeleteComment(true);
                },
                actionType: ActionType.alert,
                icon: "trash",
              },
            ]}
          />
          <ActionModal
            active={visibleDeleteComment}
            setActive={setVisibleDeleteComment}
            header="Delete Reply"
            title="Are you sure you want to delete this reply?"
            onSubmit={(e) => {
              PostService.deleteSubComment(user, postId, comment.id, reply.id)
                .then((response) => {
                  deleteSubComment(response.commentReply);
                  setIsProcessing(false);
                  addToast("Reply Deleted");
                })
                .catch((err) => {
                  setIsProcessing(false);
                });
            }}
          />
        </div>
        <div className="pl-12">
          <div className="py-1 text-sm font-light">
            <div
              className="NL2BR headings"
              dangerouslySetInnerHTML={{ __html: md.render(reply.description) }}
            />
          </div>
          <div className="PostSubCommentDocumentPreview">
            {reply.documents.length > 0 ? (
              <PostSubCommentDocumentPreview reply={reply} smallMargin />
            ) : (
              <></>
            )}
          </div>
          {/* post reply reactions */}
          <div className="flex justify-between items-center">
            {/* left side icons */}
            <div className="flex items-center">
              {/* emoji icon */}
              <CustomDropDown
                alignment="left-1"
                dropdownClassName="-left-24 sm:left-1"
                button={
                  <IconButton2
                    icon="grin-alt"
                    size="md"
                    iconClass="theme-text-subtitle-2"
                    className="h-4"
                    onClick={() => {
                      setIsEmojiPickerVisible(true)
                    }}
                  />
                }>
                {/* emoji picker */}
                {isEmojiPickerVisible && (
                  <EmojiPicker
                    emoji={null}
                    hidePlaceholder={true}
                    setEmoji={(emoji) => {
                      // set emoji
                      handleReactToComment(emoji);
                    }}
                    visible={true}
                  />
                )}
              </CustomDropDown>
              {/* reactions list */}
              {reply.reactions.details.map((reaction, index) => (
                <div
                  key={reaction.emoji + reaction.count + index}
                  className={cx(
                    "flex items-center py-0.5 px-1 text-xs font-light rounded cursor-pointer mr-2",
                    {
                      "theme-bg-primary theme-text-on-primary": reaction.isByMe,
                      "theme-bg-disable": !reaction.isByMe,
                    }
                  )}
                  onClick={(e) => {
                    // set reaction as this emoji
                    if (reaction.isByMe) {
                      handleRemoveReactToComment();
                    } else {
                      handleReactToComment(reaction.emoji);
                    }
                    // hit like api
                  }}
                  title={
                    "Reacted by " +
                    reaction.users.map((user) => user.name).join(", ")
                  }>
                  <span className="pr-1">{reaction.emoji}</span>
                  <span className="pl-1">{reaction.count}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={
            commentRepliesArr && commentRepliesArr.length - 1 === index
              ? ""
              : "ml-12 mt-2"
          }
        />
        {isProcessing ? (
          <div className="processing">
            <Loader />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

/**
 * Component to show a post comment
 * @param {*} param0
 * @returns
 */
export function PostSubCommentDocumentPreview({
  reply,
  activityType = "",
  isActivity = false,
}) {
  const [subCommentImageToBePreviewed, setSubCommentImageToBePreviewed] =
    useState(null);

  const videoFormat = ["mp4", "mov", "webm", "ogg"];
  // Note: `blob` is added here because of the way we are uploading the gif in replay of a comment
  const imageFormat = ["png", "jpg", "jpeg", "gif", "blob"];
  const audioFormat = ["wav", "mp3", "aac", "amr"];

  const [fileType, setFileType] = useState("");

  useEffect(() => {
    if (activityType == "" || activityType == "comment-reply") {
      setFileType(
        reply.documents.length > 0
          ? reply.documents[0].location.split(".").pop()
          : ""
      );
    } else if (reply.images && reply.images.length > 0) {
      setFileType(reply.images[0].split(".").pop());
    } else if (reply.document && reply.document !== "") {
      setFileType(reply.document.split(".").pop());
    }
  }, []);

  return (
    <>
      {subCommentImageToBePreviewed &&
      imageFormat.includes(subCommentImageToBePreviewed.split(".").pop()) ? (
        <div
          className="PostImageFullPreview"
          onClick={(e) => {
            setSubCommentImageToBePreviewed(null);
          }}>
          <div className="holder">
            <img src={subCommentImageToBePreviewed} alt="Preview" />
            <IconButton
              icon="plus"
              rotate={45}
              className="close-button"
              onClick={(e) => {
                setSubCommentImageToBePreviewed(null);
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {
        // Post preview and activity comment-reply preview
        activityType === "" || activityType === "comment-reply" ? (
          <div key={reply.id} className="PostComment m-auto mt-2">
            {videoFormat.includes(fileType) && reply.documents.length > 0 ? (
              <div className="py-1 pl-12 mt-2 pr-4">
                <video
                  className="m-0 rounded-md h-64 w-full object-cover"
                  controls
                  controlsList="nodownload">
                  <source src={reply.documents[0].location} />
                </video>
              </div>
            ) : audioFormat.includes(fileType) && reply.documents.length > 0 ? (
              <>
                <div className="py-1 pl-12 mt-2 pr-4">
                  <audio
                    className="m-0 rounded-md w-full py-1"
                    controls
                    controlsList="nodownload">
                    <source src={reply.documents[0].location} />
                  </audio>
                </div>
              </>
            ) : imageFormat.includes(fileType) && reply.documents.length > 0 ? (
              <div className="PostImage w-full pl-12 pr-2 relative">
                <img
                  onClick={(e) => setSubCommentImageToBePreviewed(e.target.src)}
                  alt=""
                  className={cx(
                    "rounded-md  w-full object-cover object-center ",
                    {
                      "h-40 max-w-max rounded":
                        reply.documents[0].location.split(".").pop() === "gif",
                      "h-60":
                        !reply.documents[0].location.split(".").pop() === "gif",
                    }
                  )}
                  src={reply.documents[0].location}
                />
              </div>
            ) : reply.documents.length > 0 ? (
              <div className="relative m-2 ml-12">
                <a
                  download
                  target="_blank"
                  rel="noreferrer"
                  href={
                    reply.documents.length > 0 && !isActivity
                      ? reply.documents[0].location
                      : "/#"
                  }
                  title="Open file"
                  className="flex items-center space-x-2 border-2 theme-border-default rounded px-4 font-semibold py-2 ">
                  <UIcon icon="document" className="text-3xl h-9" />
                  <div className="flex items-center justify-between flex-grow space-x-4">
                    <div className="items-center ">
                      <div>
                        {reply.documents.length > 0
                          ? reply.documents[0].name
                          : ""}
                      </div>
                      <div className="uppercase text-xs">
                        {reply.documents.length > 0
                          ? reply.documents[0].name.split(".").pop()
                          : ""}
                      </div>
                    </div>
                    <span className="flex-none">
                      <UIcon icon="download" className="text-xl" />
                    </span>
                  </div>
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          // for post-comment
          <div key={reply.id} className="PostComment m-auto mt-2">
            {reply.images.length > 0 && imageFormat.includes(fileType) ? (
              <div className="PostImage w-full pl-12 pr-2 relative">
                <img
                  className={cx(
                    "rounded-md w-full  object-cover object-center",
                    {
                      "h-40 max-w-max rounded":
                        reply.images[0].split(".").pop() === "gif",
                      "h-60": !reply.images[0].split(".").pop() === "gif",
                    }
                  )}
                  src={reply.images[0]}
                  alt=""
                />
              </div>
            ) : reply.document && videoFormat.includes(fileType) ? (
              <div className="py-1 pl-12 mt-2 pr-4">
                <video
                  className="m-0 rounded-md h-64 w-full object-cover "
                  controls>
                  <source src={reply.document} />
                </video>
              </div>
            ) : reply.document && reply.document !== "" ? (
              <div className="relative m-2 ml-12">
                <a
                  download
                  target="_blank"
                  rel="noreferrer"
                  href="/#"
                  title="Open file"
                  className="flex items-center space-x-2 border-2 theme-border-default rounded px-4 font-semibold py-2 ">
                  <UIcon icon="document" className="text-3xl h-9" />
                  <div className="flex items-center justify-between flex-grow space-x-4">
                    <div className="items-center ">
                      <div>
                        {reply.documentName !== "" ? reply.documentName : ""}
                      </div>
                      <div className="uppercase text-xs">
                        {reply.document && reply.document !== ""
                          ? reply.document.split(".").pop()
                          : ""}
                      </div>
                    </div>
                    <span className="flex-none">
                      <UIcon icon="download" className="text-xl" />
                    </span>
                  </div>
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      }
    </>
  );
}
