import {
  SAVING_NOTIFICATION_CENTRE,
  SET_NOTIFICATION_CENTRE,
  SET_NOTIFICATION_CENTRE_ERROR,
} from "../_actions/notification-centre.actions";

const defaultState = {
  isLoading: true,
  isUpdating: false,
  preferences: undefined,
  error: undefined,
};

/**
 * Notification Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function notificationCentreReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_CENTRE:
      return {
        isLoading: false,
        isUpdating: false,
        error: undefined,
        preferences: action.preferences,
      };

    case SAVING_NOTIFICATION_CENTRE:
      return {
        ...state,
        error: undefined,
        isUpdating: true,
      };
    case SET_NOTIFICATION_CENTRE_ERROR:
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      };
    default:
      return state;
  }
}
