import { PointBreakDown } from "../../types/karma-points/karma-points.type";
import { ADD_KARMA_POINTS, SET_KARMA_POINTS } from "./karma-points.action";

export interface KarmaPointsState {
  breakdown: PointBreakDown | null;
  communityBasePoints?: number;
  addedPoints?: number;
}

const INITIAL_STATE = {
  breakdown: null,
  communityBasePoints: 0,
  addedPoints: 0,
} as KarmaPointsState;

export function communityKarmaPointsReducer(
  state = INITIAL_STATE,
  action: any
) {
  switch (action.type) {
    case SET_KARMA_POINTS: // sets whole object
      // console.log('action.obj', action.obj);
      return action.obj;
    case ADD_KARMA_POINTS:
      return {
        ...state,
        addedPoints: action.points,
        activityType: action.activityType
      };
    default:
      return state;
  }
}
