import ReactDOM from "react-dom";

export default function PopupImageViewer({
  imageUrl,
  imageAlt = "",
  isPopupVisible = false,
  setIsPopupVisible = () => {},
}) {
  if (!isPopupVisible) return <></>;

  const portalContainer = document.createElement("div");
  portalContainer.setAttribute("id", "PopupImageViewer");
  document.body.insertBefore(portalContainer, document.body.firstChild);

  return ReactDOM.createPortal(
    <div
      className="PopupImageViewer overflow-y-auto"
      onClick={() => {
        setIsPopupVisible(false);
      }}>
      <div
        className="holder"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div onClick={() => setIsPopupVisible(false)}>
          {isPopupVisible && <img src={imageUrl} alt={imageAlt} />}
        </div>
        <div
          className="close-button"
          onClick={(e) => {
            setIsPopupVisible(false);
          }}>
          &times;
        </div>
      </div>
    </div>,
    portalContainer
  );
}
