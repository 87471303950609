import _ from "lodash";
import { getUserIdFromStorage } from "../../_config/helper.config";

const SECTION_POST_LIKE_REALTIME_INCREMENT =
  "SECTION_POST_LIKE_REALTIME_INCREMENT";
function triggerSectionPostLikeIncrementAction(socketPayload) {
  return {
    type: SECTION_POST_LIKE_REALTIME_INCREMENT,
    socketPayload,
  };
}
const sectionPostLikeRealtimeIncrementReducer = (state, action) => {
  return likeSectionPostFromSocket(
    Object.assign({}, state),
    action.socketPayload
  );
};

// post comment add
const SECTION_POST_COMMENT_REALTIME_ADD = "SECTION_POST_COMMENT_REALTIME_ADD";
function triggerSectionPostCommentAddAction(socketPayload) {
  return {
    type: SECTION_POST_COMMENT_REALTIME_ADD,
    socketPayload,
  };
}
const sectionPostCommentAddReducer = (state, action) => {
  return addSectionPostCommentFromSocket(
    Object.assign({}, state),
    action.socketPayload
  );
};

// post comment remove
const SECTION_POST_COMMENT_REALTIME_REMOVE =
  "SECTION_POST_COMMENT_REALTIME_REMOVE";
function triggerSectionPostCommentRemoveAction(socketPayload) {
  return {
    type: SECTION_POST_COMMENT_REALTIME_REMOVE,
    socketPayload,
  };
}
const sectionPostCommentRemoveReducer = (state, action) => {
  return removeSectionPostCommentFromSocket(
    Object.assign({}, state),
    action.socketPayload
  );
};

// post comment reaction update
const SECTION_POST_COMMENT_REALTIME_REACTION_UPDATE =
  "SECTION_POST_COMMENT_REALTIME_REACTION_UPDATE";
function triggerSectionPostCommentReactionUpdateAction(socketPayload) {
  return {
    type: SECTION_POST_COMMENT_REALTIME_REACTION_UPDATE,
    socketPayload,
  };
}
const sectionPostCommentReactionUpdateReducer = (state, action) => {
  return updateSectionPostCommentReactionFromSocket(
    Object.assign({}, state),
    action.socketPayload
  );
};

export {
  SECTION_POST_LIKE_REALTIME_INCREMENT,
  triggerSectionPostLikeIncrementAction,
  sectionPostLikeRealtimeIncrementReducer,
  SECTION_POST_COMMENT_REALTIME_ADD,
  triggerSectionPostCommentAddAction,
  sectionPostCommentAddReducer,
  SECTION_POST_COMMENT_REALTIME_REMOVE,
  triggerSectionPostCommentRemoveAction,
  sectionPostCommentRemoveReducer,
  SECTION_POST_COMMENT_REALTIME_REACTION_UPDATE,
  triggerSectionPostCommentReactionUpdateAction,
  sectionPostCommentReactionUpdateReducer,
};

/**
 * Reducer
 * @param {*} newState
 * @param {*} socketPayload
 * @returns
 */
function likeSectionPostFromSocket(newState, socketPayload) {
  const { user, increment, postId, sectionId, groupId } = socketPayload;
  const currentUserId = getUserIdFromStorage();
  if (currentUserId === user?.id) {
    // ignore
    return newState;
  }
  //  update state
  return updatePostInSectionState(
    newState,
    {
      id: postId,
      tab: sectionId,
    },
    (post) => {
      const newPost = { ...post };
      if (increment === -1) {
        // disliked
      } else {
        // liked
      }

      newPost.likes += increment;
      newPost.likeCount += increment;

      // TODO: update latest likes

      return newPost;
    }
  );
}

/**
 * Reducer
 * @param {*} newState
 * @param {*} socketPayload
 * @returns
 */
function addSectionPostCommentFromSocket(newState, socketPayload) {
  const { postId, sectionId, groupId, postCommentId, comment } = socketPayload;
  const { createdBy: user } = comment;

  const currentUserId = getUserIdFromStorage();
  if (currentUserId === user?.id) {
    // ignore
    return newState;
  }

  const postObject = {
    id: postId,
    tab: sectionId,
  };

  //  update state
  return updatePostInSectionState(newState, postObject, (post) => {
    const newPost = { ...post };

    newPost.commentCount = post.commentCount + 1;

    // update latest comment
    newPost.latestComment = comment;

    // update all comments if required
    if (Array.isArray(newPost.comments)) {
      newPost.comments.unshift(comment);
    }

    return newPost;
  });
}

/**
 * Reducer
 * @param {*} newState
 * @param {*} socketPayload
 * @returns
 */
function removeSectionPostCommentFromSocket(newState, socketPayload) {
  const { postId, sectionId, groupId, postCommentId } = socketPayload;

  const postObject = {
    id: postId,
    tab: sectionId,
  };

  //  update state
  return updatePostInSectionState(newState, postObject, (post) => {
    const newPost = { ...post };

    // update latest comment, if needed
    if (newPost.latestComment?.id === postCommentId) {
      newPost.latestComment = null;
    }

    // find if we have comment
    const comment = newPost.comments?.find((c) => c.id === postCommentId);
    if (comment) {
      // we have comment, remove it
      newPost.commentCount = post.commentCount - 1;
      newPost.comments = newPost.comments.filter((c) => c.id !== postCommentId);
    }

    return newPost;
  });
}

/**
 * Reducer
 * @param {*} newState
 * @param {*} socketPayload
 * @returns
 */
function updateSectionPostCommentReactionFromSocket(newState, socketPayload) {
  const { postId, sectionId, groupId, postCommentId, reaction, user } =
    socketPayload;

  const currentUserId = getUserIdFromStorage();
  if (currentUserId === user?.id) {
    // ignore
    return newState;
  }

  const postObject = {
    id: postId,
    tab: sectionId,
  };

  //  update state
  return updatePostInSectionState(newState, postObject, (post) => {
    const newPost = { ...post };

    // update latest comment, if needed
    if (newPost.latestComment?.id === postCommentId) {
      // yes same comment
      newPost.latestComment = updateCommentReaction(
        newPost.latestComment,
        reaction,
        user
      );
    }

    // find if we have comment
    const commentIndex = newPost.comments?.findIndex(
      (c) => c.id === postCommentId
    );
    if (commentIndex > -1) {
      newPost.comments[commentIndex] = updateCommentReaction(
        newPost.comments[commentIndex],
        reaction,
        user
      );
    }

    return newPost;
  });
}

export function updateCommentReaction(comment, emoji, user) {
  // returns comment
  // remove previous emoji, if any by user
  for (let i = 0; i < comment.reactions.details.length; i++) {
    const oldIndex = comment.reactions.details[i].users.findIndex(
      (u) => u.id === user.id
    );
    if (oldIndex > -1) {
      comment.reactions.details[i].users = comment.reactions.details[
        i
      ].users.filter((u) => u.id !== user.id);
      comment.reactions.details[i].count -= 1;
      if (comment.reactions.details[i].count < 1) {
        // remove the reaction
        comment.reactions.details.splice(i, 1);
      }
      comment.reactions.total -= 1;
    }
  }
  if (emoji) {
    // we have to add/update emoji
    // check if we have emoji details object for this emoji
    const emojiDetailIndex = comment.reactions.details.findIndex(
      (d) => d.emoji === emoji
    );
    if (emojiDetailIndex === -1) {
      // we dont have the detail, push it to the reactions
      comment.reactions.details.push({
        count: 1,
        isByMe: false,
        emoji,
        users: [user],
      });
    } else {
      comment.reactions.details[emojiDetailIndex].count += 1;
      comment.reactions.details[emojiDetailIndex].users.push(user);
    }
    comment.reactions.total += 1;
  }

  return comment;
}

// update post in section
function updatePostInSectionState(state, post, mutator = (post) => {}) {
  const sectionId = post.tab;
  const postId = post.id;
  const newState = _.cloneDeep(state);
  // update posts
  if (!newState.sections[sectionId]) return state;
  newState.sections[sectionId].posts = newState.sections[sectionId].posts.map(
    (post) => {
      if (post.id === postId) {
        return mutator(post);
      }
      return post;
    }
  );
  // update pinned posts
  newState.sections[sectionId].pinnedPosts = newState.sections[
    sectionId
  ].pinnedPosts.map((post) => {
    if (post.id === postId) {
      return mutator(post);
    }
    return post;
  });
  return newState;
}
