import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  sendCustomPush: (communityId) =>
    base + `community/${communityId}/custom-push`,
  getCustomPushLog: (communityId, page = 1) =>
    base + `community/${communityId}/custom-push/log?page=` + page,
};
