import cx from "classnames";
import React from "react";

export function CourseViewSidebar({
  children,
  rightSidebar,
  leftSidebar,
  visible = true,
}: any) {
  return (
    <div
      style={{ zIndex: 1 }}
      className={cx("w-72 relative hidden ", {
        "lg:block": leftSidebar && visible,
        " xl:block": rightSidebar && visible,
      })}>
      <div className="fixed overflow-y-auto top-[75px] bottom-[10px] w-72  rounded theme-bg-surface">
        {children}
      </div>
    </div>
  );
}

export function CourseViewContent({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex-1 flex max-w-[740px] mt-5">
      <div className="lg:px-2 w-full ">{children}</div>
    </div>
  );
}

export const CourseViewNavbar = ({ children }: any) => {
  return (
    <div className="fixed left-0 right-0 top-0 theme-bg-surface h-[54px] z-0">
      <div className="flex items-center h-full">
        <div className="mx-auto max-w-[1340px] px-2 w-full">{children}</div>
      </div>
    </div>
  );
};

export function CourseViewFooter({ children, className }: any) {
  return (
    <footer
      className={cx(
        "fixed left-0 right-0 bottom-0  h-[54px] z-0 shadow-2xl",
        className
      )}>
      <div className="flex items-center mx-auto max-w-[1040px] px-2  h-full lg:px-2 w-full">
        <div className="flex place-content-between items-center w-full theme-bg-surface h-full shadow-2xl border-t theme-border-default px-4">
          {children}
        </div>
      </div>
    </footer>
  );
}
