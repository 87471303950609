// url Async requesting function
function httpGetAsync(theUrl: any, callback: any) {
  // create the request object
  let xmlHttp = new XMLHttpRequest();

  // set the state change callback to capture when the response comes in
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      callback(xmlHttp.responseText);
    }
  };

  // open as a GET call, pass in the url and set async = True
  xmlHttp.open("GET", theUrl, true);

  // call send with no params as they were passed in on the url string
  xmlHttp.send(null);
}

// function to call the trending and category endpoints
async function SearchGif(
  query = "",
  page = "",
  perPage = 30,
  whenResult = (e: any) => {}
) {
  // set the apiKey and limit
  let apiKey = "LIVDSRZULELA";
  let lmt = perPage;

  // test search term
  let search_term = query;

  // using default locale of en_US
  let search_url;

  if (!query || query.length === 0) {
    search_url =
      "https://g.tenor.com/v1/trending?key=" +
      apiKey +
      "&limit=" +
      lmt +
      "&pos=" +
      page;
  } else {
    search_url =
      "https://g.tenor.com/v1/search?q=" +
      search_term +
      "&key=" +
      apiKey +
      "&limit=" +
      lmt +
      "&pos=" +
      page;
  }

  httpGetAsync(search_url, (response: any) => {
    let json = JSON.parse(response);
    whenResult(json);
  });

  // data will be loaded by each call's callback
}

export default SearchGif;
