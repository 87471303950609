function MessageTileShimmer() {
  return (
    <div className="flex my-3 ">
      <p className="w-8 h-8 bg-gray-300 rounded-full "></p>
      <div className="w-max ml-2 space-y-1 ">
        <p className="w-64 h-2 bg-gray-300 rounded"></p>
        <div className="w-6/12 h-2 mt-2  bg-gray-300 rounded" />
        <div className=" w-8/12 h-2 mt-2  bg-gray-300 rounded" />
      </div>
    </div>
  );
}

export default MessageTileShimmer;
