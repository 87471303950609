import cx from "classnames";

export function InputSwitch({
  children,
  className = "",
  selected = false,
  onClick = (e) => {},
  alt = false,
}) {
  return (
    <div
      onClick={onClick}
      className={cx("Switch cursor-pointer " + className, {
        selected: selected,
        "no-children": !children,
        alt: alt,
      })}>
      {children}
    </div>
  );
}
