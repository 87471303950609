import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

export class SectionMessageBadgeService {
  static async getSectionMessageNotificationBadge(communityId: string) {
    return axios
      .get(endpoints.community.sectionMessageNotificationBadges(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
  static async getSectionMessageNotificationMarkAsRead(
    communityId: string,
    tabId: string
  ) {
    return axios
      .get(
        endpoints.community.sectionMessageNotificationMarkAsRead(
          communityId,
          tabId
        ),
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}
