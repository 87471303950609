import { useEffect, useState } from "react";
import I18 from "../../../atoms/i18";
import CropImageComponent from "../../../image-crop/crop-image-component";
import UIcon from "../../../uicon-component";
import { MediaService } from "../../../../_service/media.service";
import MEDIA_TYPES from "../../../../_constants/mediaType";
import { User } from "../../../../types/user/minimal-user.type";
import { Community } from "../../../../types/community/community.type";

interface Props {
  user: User;
  community: Community;
  coverPicture: Blob;
  setCoverPicture: (picture: Blob | null) => void;
  setAreFilesUploading: (value: boolean) => void;
  setUploadedMedia: (media: any) => void;
  uploadedMedia: any;
  setRemovedMediaUploadsOnEdit: (removedMediaUploads: any) => void;
  isCoverPictureEdit?: boolean;
}

const CoverPicture = ({
  user,
  community,
  coverPicture,
  setCoverPicture,
  setAreFilesUploading,
  setUploadedMedia,
  uploadedMedia,
  setRemovedMediaUploadsOnEdit,
  isCoverPictureEdit = false,
}: Props): JSX.Element => {
  const [isPictureRemoved, setIsPictureRemoved] = useState<boolean | null>(null);
  const [isPictureChanged, setIsPictureChanged] = useState<boolean | null>(null);

  useEffect(() => {
    if (isCoverPictureEdit && (isPictureRemoved || isPictureChanged)) {
      setRemovedMediaUploadsOnEdit((prevMediaItems: any) => [
        ...prevMediaItems,
        { mediaItem: uploadedMedia?.banner?.[0], type: MEDIA_TYPES.BANNER },
      ]);
      setUploadedMedia((prevUploadedMedia: any) => ({
        ...prevUploadedMedia,
        banner: [],
      }));
    }
    const uploadCoverPicture = async () => {
      if (community && (!isCoverPictureEdit &&
          coverPicture &&
          uploadedMedia.banner.length === 0) ||
        (isCoverPictureEdit && isPictureRemoved === false)
      ) {
        setAreFilesUploading(true);
        const res = await MediaService.uploadMedia(
          user,
          community.id!,
          [coverPicture],
          MEDIA_TYPES.BANNER,
        );

        setAreFilesUploading(false);

        setUploadedMedia((prevUploadedMedia: any) => ({
          ...prevUploadedMedia,
          banner: [...prevUploadedMedia.banner, ...res.publicUrls],
        }));
      }
    };

    uploadCoverPicture();
  }, [coverPicture]);

  return (
      <CropImageComponent
        picture={coverPicture}
        setPicture={setCoverPicture}
        setIsPictureRemoved={setIsPictureRemoved}
        setIsPictureChanged={setIsPictureChanged}
        aspectRatio={16 / 9}
        recommendedRatio={"16:9"}
        cropRatioMessage="Picture ratio: 16:9"
        selectPictureComponent={
          <div className="flex items-center space-x-3  cursor-pointer select-none py-2">
            <UIcon icon="picture" />
            <span className="theme-text-subtitle-1 font-semibold">
              <I18>Add Cover Picture</I18>
            </span>
          </div>
        }
      />
  );
};

export { CoverPicture };
