import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  create: base + "post",
  searchTag: (query, communityId) =>
    base + "tag/search?query=" + query + "&community=" + communityId,
  getPostByTag: (communityId, page, tags, tabId, groupId) =>
    base +
    `/post/explore-by-tag-paginated/community/${communityId}?page=${page}&tags[]=${tags}&groupId=${groupId}&tabId=${tabId}`,
  update: (postId) => base + "post/" + postId,
  uploadImage: (postId) => base + "post/" + postId + "/images",
  uploadVideo: (postId) => base + "post/" + postId + "/videos",
  uploadDocument: (postId) => base + "post/" + postId + "/document",
  uploadBanner: (postId) => base + "post/" + postId + "/banner",
  paginated: (page) => base + "post-paginated?page=" + page,
  popularPaginated: (page) => base + "post/explore-paginated?page=" + page,
  detail: (postId) => base + "post/" + postId,
  detailFromSlug: (communityId, slug) =>
    base + "community/" + communityId + "/post/" + slug,
  likes: (postId) => base + "post/" + postId + "/likes",
  pin: (postId) => base + "post/" + postId + "/pin",
  unpin: (postId) => base + "post/" + postId + "/unpin",
  getBookmarks: base + "bookmark",
  bookmark: (postId) => base + "post/" + postId + "/bookmark",
  removeBookmark: (postId) => base + "post/" + postId + "/bookmark",
  addComment: (postId) => base + "post/" + postId + "/comment",
  addCommentReply: (postId, commentId) =>
    base + "post/" + postId + "/comment/" + commentId + "/reply",
  addCommentReaction: (postId, commentId) =>
    base + "post/" + postId + "/comment/" + commentId + "/like",
  removeCommentReaction: (postId, commentId) =>
    base + "post/" + postId + "/comment/" + commentId + "/dislike",
  addCommentReplyReaction: (postId, commentId, replyId) =>
    base +
    "post/" +
    postId +
    "/comment/" +
    commentId +
    "/reply/" +
    replyId +
    "/like",
  removeCommentReplyReaction: (postId, commentId, replyId) =>
    base +
    "post/" +
    postId +
    "/comment/" +
    commentId +
    "/reply/" +
    replyId +
    "/dislike",
  uploadImagesToComment: (postId, commentId) =>
    base + "post/" + postId + "/comment/" + commentId + "/images",
  uploadDocumentToComment: (postId, commentId) =>
    base + "post/" + postId + "/comment/" + commentId + "/document",
  uploadDocumentToCommentReply: (postId, commentId, replyId) =>
    base +
    "post/" +
    postId +
    "/comment/" +
    commentId +
    "/reply/" +
    replyId +
    "/documents?documents[]",
  deleteComment: (postId, commentId) =>
    base + "post/" + postId + "/comment/" + commentId,
  deleteSubComment: (postId, commentId, replyId) =>
    base + "post/" + postId + "/comment/" + commentId + "/reply/" + replyId,
  like: (postId) => base + "post/" + postId + "/like",
  vote: (postId) => base + "post/" + postId + "/vote",
  dislike: (postId) => base + "post/" + postId + "/dislike",
  delete: (postId) => base + "post/" + postId,
  getMeetingData: (meetingId) =>
    base + "dyte/join-meeting/" + meetingId + "/json",
  toggleMeetingRecording: (roomName, endingKey) =>
    base + "dyte/meeting/" + roomName + "/toggle-recording/" + endingKey,
  endMeeting: (roomName, endingKey) =>
    base + "dyte/meeting/" + roomName + "/end/" + endingKey,
};
