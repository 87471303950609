export function TabBody({
  selectedTab = null, // will have the current selected tab key
  components = {}, // will have key and component as key value pair
}) {
  if (!selectedTab) return <></>;

  return (
    components[selectedTab] ?? (
      <div className="p-4 text-center">There is nothing to view here.</div>
    )
  );
}
