import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class UserService {
  /**
   * Update user profile data
   * @param {*} user
   * @param {*} data
   * @returns
   */
  static async updateProfile(user, data) {
    return axios
      .post(endpoints.user.updateProfile, data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
  /**
   * Update wallet address
   * @param {*} user
   * @param {*} data
   * @returns
   */
  static async updateWalletAddress(walletAddress) {
    return axios
      .post(
        endpoints.user.updateWalletAddress,
        {
          walletAddress,
        },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Update user picture
   * @param {*} user
   * @param {*} picture
   */
  static async updatePicture(user, picture) {
    const data = new FormData();
    // add picture
    data.append("picture", picture, picture.fileName);
    // call api
    return axios
      .post(endpoints.user.updatePicture, data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * sync one signal userid
   * @param {*} user
   * @returns
   */
  static async syncOneSignalWebUserId(user, userId) {
    return axios
      .post(
        endpoints.user.syncOneSignalWebUserId,
        { userId },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async getUserProfile(user) {
    return axios
      .get(endpoints.user.profile, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  static async checkForUserIdAvailability(userId, user) {
    return axios
      .get(endpoints.user.checkProfileAvailability(userId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get profile of a user by id
   * @param {*} userId
   * @returns
   */
  static async getUserProfileById(userId) {
    return axios
      .get(endpoints.user.profileById(userId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get all users
   * @param {*} user
   * @returns
   */
  static async getAllUsers(user) {
    return axios
      .get(endpoints.user.getAllUsers, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Start following a user
   * @param {*} user
   * @param {*} userId
   * @returns
   */
  static async followUser(user, userId) {
    return axios
      .get(endpoints.user.followUser(userId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Start unfollowing a user
   * @param {*} user
   * @param {*} userId
   * @returns
   */
  static async unfollowUser(user, userId) {
    return axios
      .get(endpoints.user.unfollowUser(userId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  // verification apis
  static async verifyMobileSendOTP(user, countryCode, mobile) {
    return axios
      .post(
        endpoints.user.verify.mobileSendOtp,
        { countryCode, mobile },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async verifyMobileVerifyOTP(user, mobile, otp) {
    return axios
      .post(
        endpoints.user.verify.mobileVerifyOtp,
        { mobile, otp },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async verifyEmailSendOTP(user, email) {
    return axios
      .post(
        endpoints.user.verify.emailSendOtp,
        { email },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async verifyEmailVerifyOTP(user, email, otp) {
    return axios
      .post(
        endpoints.user.verify.emailVerifyOtp,
        { email, otp },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  static async unlinkEmailOrMobile(unlinkType) {
    return axios
      .post(
        endpoints.user.account.unlinkMobileOrEmail,
        { unlinkType },
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
}

export { UserService };
