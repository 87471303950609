import { SET_PERSONAL_GROUP } from "../_actions/personal-group.actions";

/**
 * Main Auth Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function personalGroupReducer(state = {}, action) {
  switch (action.type) {
    case SET_PERSONAL_GROUP:
      return action.group;
    default:
      return state;
  }
}
