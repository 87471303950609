import { ToastTypes } from "../../contexts/toastr.context";
import { NotificationCentreService } from "../../_service/notification-centre-service";

export const SET_NOTIFICATION_CENTRE = "SET_NOTIFICATION_CENTRE";
export const SAVING_NOTIFICATION_CENTRE = "SAVING_NOTIFICATION_CENTRE";
export const SET_NOTIFICATION_CENTRE_ERROR = "SET_NOTIFICATION_CENTRE_ERROR";

/**
 * Set the notification centre preferences
 * @param {*} preferences
 * @returns
 */
export function setNotificationCentre(preferences) {
  return {
    type: SET_NOTIFICATION_CENTRE,
    preferences,
  };
}

/**
 * Saving the notification centre preferences
 */
function updatingNotificationCentre() {
  return {
    type: SAVING_NOTIFICATION_CENTRE,
  };
}

/**
 * Set error in update notifications preferences
 */
function setError(error) {
  return {
    type: SET_NOTIFICATION_CENTRE_ERROR,
    error,
  };
}

/**
 * get notification centre preferences from server
 */
export function getNotificationPreference() {
  return (dispatch, getState) => {
    // get the user
    const { auth: user, community } = getState();
    if (!user || !community) {
      return;
    }
    // get notification preferences
    NotificationCentreService.getNotificationPreference(community.id, user)
      .then(({ preferences }) => {
        dispatch(setNotificationCentre(preferences));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

/**
 * Update notification preference of a tab
 * @param {Object} tab - tab preference. ex { tabId: "tabId", groupId: "groupId", notificationLevel: "NotificationLevel" }
 * @param {Function} addToast - callback to add toast message
 */
export function updateNotificationPreferenceForSection(tab, addToast) {
  return (dispatch, getState) => {
    // get the user
    const { notificationCentre } = getState();
    let preferences = notificationCentre.preferences;
    if (!preferences) {
      preferences = {
        tabs: [],
      };
    }
    let tabs = preferences.tabs ?? [];
    let tabIndex = tabs.findIndex((t) => t.id === tab.id);
    if (tabIndex === -1) {
      tabs.push(tab);
    } else {
      tabs[tabIndex] = tab;
    }
    preferences.tabs = tabs;

    // update notification preferences
    dispatch(updateNotificationPreferenceAsync(preferences, addToast));
  };
}

/**
 * Update notification preferences
 * @param {*} preferences - notification preferences
 * @param {Function} addToast - callback to add toast message
 * @returns
 */
function updateNotificationPreferenceAsync(preferences, addToast) {
  return (dispatch, getState) => {
    // get the user
    const { auth: user, community } = getState();

    dispatch(updatingNotificationCentre());
    // update notification preferences
    NotificationCentreService.updateNotificationPreference(
      community.id,
      user,
      preferences
    )
      .then(({ preferences }) => {
        dispatch(setNotificationCentre(preferences));
        addToast("Notification preferences updated successfully");
      })
      .catch((err) => {
        console.log(err);
        addToast(
          "Error updating notification preferences",
          "",
          ToastTypes.danger
        );
        dispatch(setError(err));
      });
  };
}
