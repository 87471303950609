import React from "react";
import { Link } from "react-router-dom";
import UIcon from "../uicon-component";
import PrefixedLink from "../prefixed-link";

interface Props {
  items: Array<{ label: string; link: string }>;
}

/**
 * Breadcrumb component
 * @param {Array<Object>} items - Array of breadcrumb items. Each item should have label and link. Example: ```[{label: 'Home', link: '/'}]```
 * @returns {React.Component} - Breadcrumb component
 */
const Breadcrumb: React.FC<Props> = ({ items }) => {
  return (
    <div className="Breadcrumb">
      <div className="flex items-center font-medium">
        <ul style={{ padding: "0" }} className="flex items-center font-medium">
          {items.map((item, index) => (
            <li key={index} className="flex items-center  font-medium">
              <PrefixedLink
                to={item.link}
                className="hover:underline theme-text-primary">
                {item.label}
              </PrefixedLink>
              {index === items.length - 1 ? (
                <></>
              ) : (
                <UIcon icon="angle-right" size="xs" className="pt-1 px-2 " />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
