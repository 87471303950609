import { useState } from "react";

/**
 * Hook to use cache on the basis of give key value
 * @param {Object} initialState - Initial state of the cache
 */

export default function useCache<T>(initialState: T) {
  const [cache, setCache] = useState(initialState ?? ({} as T));

  //   Save data to cache
  function saveCache(key: string, value: T[keyof T]) {
    setCache({ ...cache, [key]: value });
  }

  return { cache, saveCache };
}
