import { GroupService } from "../../_service";

// actions
export const ADD_SECTION_MESSAGE = "ADD_SECTION_MESSAGE";
export const UPDATE_SECTION_MESSAGE = "UPDATE_SECTION_MESSAGE";
export const REMOVE_SECTION_MESSAGE = "REMOVE_SECTION_MESSAGE";
export const UPDATE_SECTION_MESSAGE_REACTION =
  "UPDATE_SECTION_MESSAGE_REACTION";

export const SET_SECTION_MESSAGES_SUCCESS = "SET_SECTION_MESSAGES_SUCCESS";
export const SET_SECTION_MESSAGES_ERROR = "SET_SECTION_MESSAGES_ERROR";
export const APPEND_SECTION_MESSAGES = "APPEND_SECTION_MESSAGES";
export const SET_SECTION_MESSAGES_LOADING = "SET_SECTION_MESSAGES_LOADING";

export function setSectionMessagesLoadingAction(
  isLoading = true,
  clear = false
) {
  return {
    type: SET_SECTION_MESSAGES_LOADING,
    isLoading,
    clear,
  };
}

export function setSectionMessagesSuccess(messages, clear) {
  return {
    type: SET_SECTION_MESSAGES_SUCCESS,
    messages,
    clear,
  };
}

export function setSectionMessagesError(error) {
  return {
    type: SET_SECTION_MESSAGES_ERROR,
    error,
  };
}

export function addSectionMessage(message) {
  return {
    type: ADD_SECTION_MESSAGE,
    message,
  };
}

export function updateSectionMessage(message) {
  return {
    type: UPDATE_SECTION_MESSAGE,
    message,
  };
}

export function removeSectionMessage(messageId) {
  return {
    type: REMOVE_SECTION_MESSAGE,
    messageId,
  };
}

export function updateSectionMessageReaction(
  { messageId, emoji, status, user },
  authUser
) {
  return {
    type: UPDATE_SECTION_MESSAGE_REACTION,
    messageId,
    emoji,
    status,
    user,
    authUser,
  };
}

// thunk
export function getSectionMessages(groupId, tabId, clear = false) {
  return (dispatch, getState) => {
    const { sectionMessages, auth: user } = getState();
    const { messages } = sectionMessages;
    const timestamp = !clear && messages.length > 0 ? messages[0].id : null;
    dispatch(setSectionMessagesLoadingAction(true, clear));
    GroupService.sectionMessagesTimestampPaginated(
      user,
      groupId,
      tabId,
      timestamp
    ).then((response) => {
      dispatch(setSectionMessagesSuccess(response.messages, clear));
    });
  };
}

const INITIAL_STATE = {
  messages: [],
  noMoreMessages: false,
  isLoading: false,
  isFirstLoading: false,
};

/**
 * Message Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function messagesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SECTION_MESSAGES_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        messages: action.clear ? [] : state.messages,
        isFirstLoading: action.clear ? true : state.isFirstLoading,
        ...(action.clear ? { noMoreMessages: false } : {}),
        error: null,
      };
    case SET_SECTION_MESSAGES_SUCCESS: {
      // if there are no more posts, set so
      let noMoreMessages = false;
      if (action.messages.length === 0) {
        noMoreMessages = true;
      } else {
        // messages are less then 10, we have no more messages
        if (action.messages.length < 10) {
          noMoreMessages = true;
        }
      }
      return {
        ...state,
        messages: [
          ...action.messages.reverse(),
          ...(action.clear ? [] : state.messages),
        ],
        noMoreMessages,
        isFirstLoading: false,
        error: null,
        isLoading: false,
      };
    }
    case ADD_SECTION_MESSAGE: {
      const { message } = action;
      const { messages } = state;
      let newMessages = [...messages];
      if (message.parent) {
        addSectionMessage(message);
        // find parent in messages
        const index = messages.findIndex((m) => m.id === message.parent);
        if (index > -1) {
          newMessages = [...messages];
          if (!newMessages[index].replies) {
            newMessages[index].replies = [message];
          } else {
            newMessages[index].replies.push(message);
          }
        }
      } else {
        newMessages = [...messages, message];
      }
      return {
        ...state,
        messages: newMessages,
      };
    }
    case UPDATE_SECTION_MESSAGE: {
      const { message } = action;
      const { messages } = state;

      let newMessages = [...messages];
      const index = messages.findIndex((m) => m.id === message.id);
      if (index > -1) {
        newMessages[index] = {
          ...message,
          reactions: newMessages[index].reactions,
        };
      } else {
        newMessages = newMessages.map((message) => {
          if (message.replies) {
            message.replies = message.replies.map((reply) => {
              return reply.id === message.id
                ? {
                    ...message,
                    reactions: reply.reactions,
                  }
                : reply;
            });
          }
          return message;
        });
      }
      return {
        ...state,
        messages: newMessages,
      };
    }
    case REMOVE_SECTION_MESSAGE: {
      const { messageId } = action;
      const { messages } = state;

      let newMessages = [...messages];
      const index = messages.findIndex((m) => m.id === messageId);
      if (index === -1) {
        // check for child
        newMessages = newMessages.map((message) => {
          if (message.replies) {
            message.replies = message.replies.filter(
              (reply) => reply.id !== messageId
            );
          }
          return message;
        });
      } else {
        newMessages = messages.filter((m) => m.id !== messageId);
      }
      return {
        ...state,
        messages: newMessages,
      };
    }
    case UPDATE_SECTION_MESSAGE_REACTION: {
      const { messageId, emoji, status, user, authUser } = action;

      const { messages } = state;
      const newMessages = updateMessageInState(
        messages,
        messageId,
        (message) => {
          const { total, details } = message.reactions;

          let increaseTotal = false;
          let decreaseTotal = false;

          const newDetails = [...details];
          // details is an emoji list
          const emojiIndex = details.findIndex((e) => e.emoji === emoji);
          // if not in list add to list

          if (emojiIndex < 0) {
            if (status === "updated") {
              newDetails.push({
                emoji,
                count: 1,
                users: [user],
                isByMe: authUser.id === user.id,
              });
              increaseTotal = true;
            }
          } else {
            if (status === "updated") {
              // get the user index
              const userIndex = newDetails[emojiIndex].users.findIndex(
                (u) => u.id === user.id
              );
              if (userIndex < 0) {
                // if user not their push details
                newDetails[emojiIndex].users.push(user);
                newDetails[emojiIndex].count = newDetails[emojiIndex].count + 1;
                newDetails[emojiIndex].isByMe = user.id === authUser.id;
                increaseTotal = true;
              }
            }
          }

          // remove user from the list of other emojis
          const newEmojiIndex = newDetails.findIndex((e) => e.emoji === emoji);
          let removedIndex = false;
          for (let index = 0; index < newDetails.length; index++) {
            if (index !== newEmojiIndex) {
              // get the user index
              const userIndex = newDetails[index].users.findIndex(
                (u) => u.id === user.id
              );
              if (userIndex > -1) {
                // if user not their push details
                newDetails[index].users.splice(userIndex, 1);
                newDetails[index].count = newDetails[index].count - 1;
                newDetails[index].false = user.id === authUser.id;
                newDetails[index].isByMe = false;
                if (!increaseTotal) {
                  decreaseTotal = true;
                }
                increaseTotal = false;

                removedIndex = newDetails[index].users.length ? false : index;
              }
            }
          }

          if (removedIndex !== false) {
            newDetails.splice(removedIndex, 1);
          }

          message.reactions = {
            total: increaseTotal
              ? total + 1
              : decreaseTotal
              ? total - 1
              : total,
            details: newDetails,
          };
          return message;
        }
      );
      return {
        ...state,
        messages: newMessages,
      };
    }
    default:
      return state;
  }
}

function updateMessageInState(
  messages,
  messageId,
  updateFunction = (message) => message
) {
  let newMessages = [...messages];
  const index = messages.findIndex((m) => m.id === messageId);
  if (index === -1) {
    // check for child
    newMessages = newMessages.map((message) => {
      if (message.replies) {
        message.replies = message.replies.map((reply) =>
          reply.id === messageId ? updateFunction(reply) : reply
        );
      }
      return message;
    });
  } else {
    newMessages = messages.map((m) =>
      m.id === messageId ? updateFunction(m) : m
    );
  }
  return newMessages;
}
