import cx from "classnames";
import { cn } from "../button.component";
import I18 from "./i18";

interface Props {
  prefix?: any;
  label: string;
  suffix?: any;
  onClick?: any;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  hoverable?: boolean;
}

/**
 * @description TextButton component
 * @param {any} prefix - prefix component
 * @param {string} label - Text to display
 * @param {any} suffix - suffix component
 * @param {MouseEvent} onClick - Event to trigger when clicked
 * @param {string} className - Class name
 * @param {boolean} isLoading - If the button is loading
 * @param {boolean} disabled - If the button is disabled
 * @param {boolean} hoverable - If the button is hoverable
 * @returns {JSX.Element}
 */
export default function TextButton({
  label,
  onClick,
  className,
  prefix,
  suffix,
  isLoading = false,
  disabled = false,
  hoverable = true,
}: Props) {
  return (
    <button
      className={cn(
        cx("select-none px-2 py-1 rounded", className, {
          "theme-text-subtitle-2 cursor-not-allowed ": !onClick || disabled,
          "theme-text-primary cursor-pointer": onClick,
          "cursor-wait": isLoading,
          "hover:theme-bg-primary-light": hoverable,
          "hover:theme-bg-disable": hoverable && (!onClick || disabled),
        })
      )}
      onClick={(e) => {
        if (onClick && !isLoading && !disabled) {
          onClick(e);
        }
      }}>
      {prefix}
      <I18>{label}</I18>
      {suffix}
    </button>
  );
}
