import React, { Suspense, useState } from "react";
import { connect } from "react-redux";
import { Remarkable } from "remarkable";
import TurndownService from "turndown";
import { withToast } from "../../contexts/toastr.context";
import sendMessageIcon from "../../_assets/vectors/send-message-icon.svg";
import { MessageService } from "../../_service/message.service";
import IconButton2 from "../icon-button-2-component";
import IconButton from "../icon-button.component";
import { Loader } from "../loader.component";
const RichTextEditor = React.lazy(() => import("../form-controls/rich-text-editor.component"));

const md = new Remarkable();
const tds = new TurndownService();

function EditMessageBoxComponent({
  message,
  user,
  onUpdate = (message) => {},
  cancelUpdate = () => {},
  addToast,
  smoothScrollToBottom = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState(
    message.description ? md.render(message.description) : ""
  );

  const [errors, setErrors] = useState(null);

  const sendMessage = async (event) => {
    // try updating the message
    try {
      // check for validation on post
      const e = validatePost({ description });
      const embedlessMessage = await description.replace(
        /(?:<figure class="media"><oembed url=")([^"^<^>^\[^\]]+)(?:"><\/oembed><\/figure>)/g,
        " $1 "
      );
      let finalMessage = await tds.turndown(embedlessMessage);

      setErrors(e);
      // create message only if no errors and description is not empty
      if (!e && description && description.trim().length > 0) {
        // start loading
        setIsLoading(true);
        // update message based on userId
        let createdMessageResponse = !message.sender
          ? await MessageService.editMessage(user, {
              ...message,
              description: finalMessage,
            })
          : await MessageService.editDirectMessage(user, {
              ...message,
              description: finalMessage,
            });
        // add post
        onUpdate(createdMessageResponse.message);
        // reset the form
        setDescription("");
        event.target.style.height = "auto";
        event.target.style.height = event.target.scrollHeight + "px";

        // stop loading
        setIsLoading(false);
      }
    } catch (error) {
      //
      setIsLoading(false);
      console.log({ error });
    }
  };

  let rows = description.split("\n").length;
  rows = rows > 0 ? rows : 1;

  const CreatePostButton1 = () => {
    return isLoading ? (
      <div className="my-1 mx-3">
        {" "}
        <Loader />
      </div>
    ) : (
      <>
        <IconButton2
          icon="cross"
          size="md"
          iconClass="theme-text-subtitle-2"
          onClick={cancelUpdate}
        />
        <IconButton
          icon={sendMessageIcon}
          onClick={sendMessage}
          iconSmall
          title="Save"
        />
      </>
    );
  };

  return (
    <>
      <div className="RealtimeCreatePostCard px-4 py-2">
        {/* user avatar and text area */}
        <div className="theme-bg-default flex items-start justify-between pr-1 rounded border theme-border-default">
          <Suspense fallback={<></>}>
            <RichTextEditor
              className="RichEditorComment minHeightDefault noBorder px-2 w-full"
              text={description}
              setText={setDescription}
            />
          </Suspense>
          <CreatePostButton1 />
        </div>
      </div>
    </>
  );
}

function validatePost(message) {
  const errors = {};
  // return errors
  // check if description empty
  if (!message.description && !message.attachments.length) {
    errors.description = "Description is required!";
  }

  // if no errors at all, delete post
  if (Object.keys(errors).length === 0) {
    return null;
  }
  // return the errors
  return errors;
}

const EditMessageBox = withToast(
  connect((s) => ({
    user: s.auth,
  }))(EditMessageBoxComponent)
);

export { EditMessageBox };
