import { ReactNode, useEffect, useState } from "react";
import Label from "../../../../_components/typography/label.typography";

import { Link } from "react-router-dom";
import { Course, PrettiFy } from "../../../../types/course/course.type";
import { MySubscription } from "../../../../types/subscription/my-subscription-plan";
import { SubscriptionPlan } from "../../../../types/subscription/subscription-plan";
import { User } from "../../../../types/user/minimal-user.type";
import { TabBody } from "../../../../_components/atoms/tab-body.component";
import { TabPills } from "../../../../_components/atoms/tab-pills.component";
import { ListView } from "../../../../_components/molecule/listview.component";
import UIcon from "../../../../_components/uicon-component";
import { SubscriptionService } from "../../../../_service";
import { store } from "../../../../_store";
import { getAllCourses } from "../../../../_store/courses/courses.action";
import { Fold } from "../../../../_utils/extensions/typescript-utils";
import { SubscriptionUtils } from "../../../../_utils/subscription/subscription-util";
import { getCoursePageRoute } from "../../../course/course-detail/course-detail.page";
import { getGroupPageRouteFromSlug } from "../../../group/group.page";
import { CurrencySymbol } from "../component/subscription_tile.component";
import { Status } from "../subscriptions-list/subscriptions-page";
import {
  ActiveSubscriptionMembers,
  LogsDetailList,
} from "./components/active-subscription-members";

export type ActiveSubscriptionMember = {
  user: PrettiFy<User> & {
    email?: string;
    mobile?: string;
  };
  subscriptionPlan: string;
  invoiceDetails: InvoiceDetails;
} & Omit<MySubscription, "subscriptionPlan">;

interface InvoiceDetails {
  invoicePrefix: string;
  invoiceNumber: number;
  subscriptionAmount: number;
  currency: string;
}
/**
 * Displays the subscription details like active members, access to, details and logs
 * @param {Object} subscription - subscription object
 */
export function SubscriptionDetail({
  subscription,
  communityId,
  groups,
  courses,
}: {
  subscription: SubscriptionPlan;
  communityId: any;
  groups: Array<any> | undefined;
  courses: {
    list: Array<Course> | undefined;
    status: Status;
  };
}) {
  const TabButton = Object.freeze({
    activeMembers: "Active Members",
    accessTo: "Access to",
    details: "Details",
    logs: "Logs",
  });
  const [activeTab, setActiveTab] = useState<any>(TabButton.activeMembers);
  const [activeMembers, setActiveMembers] =
    useState<ActiveSubscriptionMember[]>();
  const [logs, setLogs] = useState<ActiveSubscriptionMember[]>();
  const [activeMemberStatus, setActiveMemberStatus] =
    useState<Status>("loading");
  const [logsStatus, setLogsStatus] = useState<Status>("loading");

  // Fetch active members | logs
  useEffect(() => {
    if (!communityId || !subscription) {
      return;
    }
    setActiveMemberStatus("loading");
    SubscriptionService.getActiveSubscriptionsForSubscription(
      communityId,
      subscription.id!
    )
      .then(
        ({
          userSubscriptions,
        }: {
          userSubscriptions: Array<ActiveSubscriptionMember>;
        }) => {
          setActiveMembers(userSubscriptions);
          setActiveMemberStatus("success");
        }
      )
      .catch((error) => {
        console.error("error", error);
        setActiveMemberStatus("error");
      });

    // Get logs
    setLogsStatus("loading");
    SubscriptionService.getAllMembershipSubscription(
      communityId,
      subscription.id!
    )
      .then(
        ({
          subscriptions,
        }: {
          subscriptions: Array<ActiveSubscriptionMember>;
        }) => {
          setLogs(subscriptions);
          setLogsStatus("success");
        }
      )
      .catch((error) => {
        console.error("error", error);
        setLogsStatus("error");
      });
    if (courses.status === "idle") {
      store.dispatch(getAllCourses());
    }
  }, [communityId, subscription, courses]);

  if (!Boolean(subscription)) {
    return null;
  }

  const TotalAmount =
    SubscriptionUtils.calculateSubscriptionTotal(subscription);

  const currency =
    CurrencySymbol[subscription.currency as keyof typeof CurrencySymbol] ||
    subscription.currency;

  return (
    <div className="SubscriptionDetail border theme-border-default pb-4 flex flex-col">
      <div className="flex flex-col justify-center h-full pt-10 pb-4 px-9 theme-bg-default">
        {/* header & searchbox  */}
        <div className=" flex gap-12 items-center">
          <Label size="body1" variant="t1">
            {subscription.name}
          </Label>
          <div className="flex items-center gap-1">
            <Label size="h3" variant="t1">
              {TotalAmount > 0 ? `${currency} ${TotalAmount}` : "Free"}
            </Label>
            <Label variant="t1" size="caption">
              {(subscription?.recurringInterval ?? 0) > 0 &&
                `per ${subscription?.recurringInterval} month`}
            </Label>
          </div>
        </div>
      </div>
      {/* Tabs  */}

      <TabPills
        className="w-full flex px-4"
        selectedTab={activeTab}
        setSelectedTab={(tabPath: typeof TabButton) => {
          setActiveTab(tabPath);
        }}
        tabs={[
          {
            label: `Active Members`,
            key: TabButton.activeMembers,
          },
          {
            label: `Access to`,
            key: TabButton.accessTo,
          },
          {
            label: `Details`,
            key: TabButton.details,
          },
          {
            label: `Logs`,
            key: TabButton.logs,
          },
        ]}
      />
      {/* tab details page */}
      <TabBody
        selectedTab={activeTab}
        components={{
          [TabButton.activeMembers]: (
            <ActiveSubscriptionMembers
              activeMembers={activeMembers}
              status={activeMemberStatus}
              subscription={subscription}
              communityId={communityId}
              onPlanUpdate={(activeMember) => {
                setActiveMembers((prev) => {
                  if (!prev) {
                    return prev;
                  }
                  return prev.map((member) => {
                    if (member.id === activeMember.id) {
                      return activeMember;
                    }
                    return member;
                  });
                });
              }}
            />
          ),
          [TabButton.accessTo]: (
            <AccessToListView
              subscription={subscription}
              groups={groups}
              courses={courses}
            />
          ),
          [TabButton.details]: <DetailsView subscription={subscription} />,
          [TabButton.logs]: (
            <LogsDetailList
              subscription={subscription}
              status={logsStatus}
              logs={logs}
              communityId={communityId}
            />
          ),
        }}
      />
    </div>
  );
}

/**
 * Displays the subscription description
 */
function DetailsView({ subscription }: { subscription: SubscriptionPlan }) {
  return (
    <div className="DetailsView theme-bg-surface px-4 mx-4 rounded border theme-default-border">
      <div key={subscription.id}>
        {subscription.description ? (
          <div
            dangerouslySetInnerHTML={{ __html: subscription.description }}
            className="NL2BR headings my-2 min-h-[24rem]"></div>
        ) : (
          <div className="flex items-center justify-center h-96">
            <Label variant="s1">No description available</Label>
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Displays the subscription resources like groups and courses
 */
export function AccessToListView({
  subscription,
  groups,
  courses,
}: {
  subscription: SubscriptionPlan;
  groups: Array<any> | undefined;
  courses: {
    list: Array<Course> | undefined;
    status: Status;
  };
}) {
  if (!groups || !subscription) return null;

  return (
    <div className="theme-bg-surface py-4 px-4 mx-4 border theme-default-border">
      <div key={subscription.id} className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 border theme-border-default py-2 px-4 rounded theme-bg-default">
          <Label size="body1" variant="t1">
            Groups
          </Label>
          <ListView
            items={subscription.accessibleGroups}
            className="pl-4"
            renderItem={function (groupId: any, index: number): ReactNode {
              const group =
                groups && groups.find((group) => group.id === groupId);
              return (
                <div className="pb-2">
                  <Fold
                    value={group}
                    ifPresent={(group: any) => {
                      return (
                        <Link
                          to={getGroupPageRouteFromSlug(group)}
                          className="text-start hover:underline theme-text-subtitle-1">
                          <span className="flex items-center gap-1">
                            <UIcon
                              icon="rec"
                              solid
                              size="xxs"
                              className={"scale-50"}
                            />
                            {group.name}
                          </span>
                        </Link>
                      );
                    }}
                    ifAbsent={() => {
                      return (
                        <Label variant="t2" size="small" className="text-start">
                          Group not found (May be removed)
                        </Label>
                      );
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        <div className="flex flex-col gap-2 border theme-border-default py-2 px-4 rounded theme-bg-default">
          <Label size="body1" variant="t1">
            Courses
          </Label>
          <ListView
            items={subscription.accessibleCourses}
            className="pl-4"
            renderItem={function (courseId: any, index: number): ReactNode {
              const course =
                courses.list &&
                courses.list.find((group) => group.id === courseId);
              return (
                <div className="pb-2">
                  <Fold
                    value={course}
                    ifPresent={(course: any) => {
                      return (
                        <Link
                          to={getCoursePageRoute(course.id)}
                          className="text-start hover:underline theme-text-subtitle-1">
                          <span className="flex items-center gap-1">
                            <UIcon
                              icon="rec"
                              solid
                              size="xxs"
                              className={"scale-50"}
                            />
                            {course.title}
                          </span>
                        </Link>
                      );
                    }}
                    ifAbsent={() => {
                      return (
                        <Label variant="t2" size="small" className="text-start">
                          Course not found (May be removed)
                        </Label>
                      );
                    }}
                  />
                </div>
              );
            }}
            noItemsElement={
              <Label variant="s1" size="caption">
                No course linked to this subscription
              </Label>
            }
          />
        </div>
      </div>
    </div>
  );
}
