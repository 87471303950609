import { useContext } from "react";
import { ToastContext, ToastTypes } from "../contexts/toastr.context";

/**
 * @description Toast hook
 */
export const useToast = () => {
  const { addToast } = useContext(ToastContext);

  return { addToast, ToastTypes };
};
