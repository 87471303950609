import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  getAllRequests: (communityId) =>
    `${base}community/${communityId}/custom-domain`,
  createRequest: (communityId) =>
    `${base}community/${communityId}/custom-domain`,
  deleteRequest: (communityId, requestId) =>
    `${base}community/${communityId}/custom-domain/${requestId}`,
};
