import cx from "classnames";
import React, { useEffect, useState } from "react";
import { MySubscription } from "../../types/subscription/my-subscription-plan";
import {
  GroupSubscriptionPlans,
  SubscriptionPlan,
} from "../../types/subscription/subscription-plan";
import { Button } from "../../_components";
import { CloseButton } from "../../_components/activity/close-button.component";
import I18 from "../../_components/atoms/i18";
import TextButton from "../../_components/atoms/text-button";
import { Radio } from "../../_components/form-controls/radio.component";
import { Modal } from "../../_components/modal.component";
import ModalForm from "../../_components/model2";
import { ListView } from "../../_components/molecule/listview.component";
import Tab from "../../_components/tab.component";
import Label from "../../_components/typography/label.typography";
import UIcon from "../../_components/uicon-component";
import { loadScript } from "../../_config/helper.config";
import { SubscriptionService } from "../../_service";
import { RazorPayCheckoutService } from "../../_service/payment-service/razor-pay/razor-pay-checkout-service";
import {
  OrderDetails,
  RazorpaySuccessResponse,
  SubscriptionDetails,
} from "../../_service/payment-service/types/razor-pay-type";
import { BillingDetails } from "../../_store/subscription/my-subscription/my-subscription.reducer";
import { Fold } from "../../_utils/extensions/typescript-utils";
import { SubscriptionUtils } from "../../_utils/subscription/subscription-util";
import { AddressCheckout } from "../user-management-settings/subscriptions/billing-address/billing-adress";
import { CurrencySymbol } from "../user-management-settings/subscriptions/component/subscription_tile.component";

interface Props {
  user: {
    name: string;
    email: string;
    mobile: string;
  } & { [key: string]: any };
  community: any;
  plans: GroupSubscriptionPlans;
  header?: string;
  active: boolean;
  labelCancel?: string;
  labelSubmit?: string;
  btnColor?: string;
  disabled?: boolean;
  mySubscription?: MySubscription;
  onPaymentSuccess?: (data: any) => void;
  setActive: (active: boolean) => void;
}

/**
 * Displays all the subscription plans for a group
 */
const GroupSubscriptionCheckoutModel: React.FC<Props> = ({
  community,
  user,
  plans,
  header,
  active = false,
  labelCancel = "Cancel",
  labelSubmit = "Yes, Go Ahead",
  btnColor,
  mySubscription,
  disabled = false,
  onPaymentSuccess = (e) => {},
  setActive = (e) => {},
}) => {
  type Tab = "Monthly" | "3 Months" | "6 Months" | "12 Months" | "one-time";
  type Status = "idle" | "loading" | "success" | "error";

  const [selectedSubscription, setSelectedSubscription] =
    useState<SubscriptionPlan | null>(null);
  const [activeTab, setActiveTab] = useState<Tab>("Monthly");
  const [status, setStatus] = useState<Status>("idle");
  const [checkoutUrl, setCheckoutUrl] = useState<string | undefined>();
  const [isDisplayAddressCheckout, setIsDisplayAddressCheckout] =
    useState(false);

  var tabs = Array<Tab>();
  if (plans.oneMonthlySubscriptions?.length) tabs.push("Monthly");
  if (plans.threeMonthlySubscriptions?.length) tabs.push("3 Months");
  if (plans.sixMonthlySubscriptions?.length) tabs.push("6 Months");
  if (plans.twelveMonthlySubscriptions?.length) tabs.push("12 Months");
  if (plans.oneTimeSubscriptions?.length) tabs.push("one-time");

  useEffect(() => {
    if (plans.oneMonthlySubscriptions?.length) setActiveTab("Monthly");
    else if (plans.threeMonthlySubscriptions?.length) setActiveTab("3 Months");
    else if (plans.sixMonthlySubscriptions?.length) setActiveTab("6 Months");
    else if (plans.twelveMonthlySubscriptions?.length)
      setActiveTab("12 Months");
    else if (plans.oneTimeSubscriptions?.length) setActiveTab("one-time");
  }, [plans, mySubscription]);

  // load script
  useEffect(() => {
    // if razorpay is not already initialized
    // @ts-ignore
    if (!window.Razorpay) {
      // load razorpay
      loadScript("https://checkout.razorpay.com/v1/checkout.js").then(
        async (result) => {
          if (!result) {
            return alert("Could not load razorpay!");
          } else {
            console.log("razorpay loaded");
          }
          // add script
        }
      );
    }
  }, []);

  function filterSubscriptions() {
    switch (activeTab) {
      case "Monthly":
        return plans.oneMonthlySubscriptions ?? [];
      case "3 Months":
        return plans.threeMonthlySubscriptions ?? [];
      case "6 Months":
        return plans.sixMonthlySubscriptions ?? [];
      case "12 Months":
        return plans.twelveMonthlySubscriptions ?? [];
      case "one-time":
        return plans.oneTimeSubscriptions ?? [];
      default:
        return [];
    }
  }

  // Subscribe to a free plan
  const handleFreeSubscriptions = (subscriptionId: string) => {
    if (!subscriptionId) return;
    setStatus("loading");
    SubscriptionService.subscribeToFreePlan(community.id, subscriptionId)
      .then(({ subscription }) => {
        setStatus("success");
        onPaymentSuccess(subscription);
      })
      .catch((err) => {
        setStatus("error");
        console.log(err);
      });
  };

  // Initiate subscription on click
  const initiateSubscription = (
    plan: SubscriptionPlan,
    billingDetails: BillingDetails
  ) => {
    if (!plan || !plan.id) return;
    setStatus("loading");
    SubscriptionService.initiateSubscription(
      community.id,
      plan.id!,
      billingDetails
    )
      .then(({ orderDetails, subscriptionDetails, paymentGateway }) => {
        setStatus("success");

        // If payment gateway is razorpay
        if (paymentGateway === "razorpay") {
          handleCheckoutRazorPay(
            orderDetails,
            subscriptionDetails,
            plan.isRecurring ?? false
          );
        }
      })
      .catch((err) => {
        setStatus("error");
        console.log(err);
      });
  };

  // Handle checkout with razorpay
  const handleCheckoutRazorPay = (
    oneTimeOrderDetails: OrderDetails,
    recurringOrderDetails: SubscriptionDetails,
    isRecurring: boolean
  ) => {
    // console.log("handleCheckoutRazorPay", {
    //   isRecurring,
    //   oneTimeOrderDetails,
    //   recurringOrderDetails,
    // });
    if (isRecurring && !recurringOrderDetails) {
      console.error("Order details not found");
      return;
    } else if (!isRecurring && !oneTimeOrderDetails) {
      console.error("Order details not found");
      return;
    }

    if (isRecurring) {
      const url = RazorPayCheckoutService.initiateRecurringPayment(
        community,
        recurringOrderDetails,
        user,
        onSuccess
      );
      setCheckoutUrl(url);
    } else {
      const url = RazorPayCheckoutService.initiateOneTimePayment(
        community,
        oneTimeOrderDetails!,
        user,
        onSuccess
      );
      setCheckoutUrl(url);
    }
  };

  function onSuccess(data: RazorpaySuccessResponse) {
    onPaymentSuccess(data);
    setActive(false);
    setCheckoutUrl(undefined);
  }
  if (checkoutUrl) {
    // Load Payment Gateway
    return (
      <ModalForm
        visible={checkoutUrl !== undefined}
        setVisible={() => {
          const isConfirmed = window.confirm(
            "Are you sure you want to cancel the payment?"
          );
          if (isConfirmed) {
            setCheckoutUrl(undefined);
          }
        }}
        hideHeader={true}
        childrenClassName="p-0 h-full border theme-border-default bg-transparent w-full rounded"
        className="w-full sm:m-4 overflow-y-auto max-h-screen sm:max-h-[90vh] max-w-full sm:max-w-[412px] flex items-center place-content-center h-full bg-transparent">
        <iframe
          src={checkoutUrl}
          className="w-full rounded h-full"
          title="Checkout"></iframe>
      </ModalForm>
    );
  }
  return (
    <Modal
      className="SubscriptionCheckoutModel"
      active={active}
      setActive={setActive}
      padding={false}>
      <div className="p-2 pl-4 theme-bg-surface flex justify-between items-center space-x-2">
        <Label variant="t1">{header}</Label>

        <CloseButton
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
      <div className="theme-bg-surface">
        <>
          <div className=" flex whitespace-nowrap justify-around theme-bg-default mt-3">
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                tab={tab}
                selected={activeTab === tab}
                onClick={() => {
                  setActiveTab(tab as Tab);
                  setSelectedSubscription(undefined as any);
                }}
              />
            ))}
          </div>

          <ListView
            className="rounded min-h-[60px] mx-4 mt-6 flex gap-4 flex-col overflow-y-auto"
            items={filterSubscriptions()}
            renderItem={function (item: SubscriptionPlan): React.ReactNode {
              return (
                <div
                  key={item.id}
                  onClick={() => {
                    if (mySubscription?.subscriptionPlan?._id !== item.id) {
                      setSelectedSubscription(item);
                      // console.log("selectedSubscription", {
                      //   mySubscription,
                      //   item,
                      // });
                    }
                  }}>
                  <Plan
                    plan={item}
                    mySubscription={mySubscription}
                    selected={selectedSubscription?.id === item.id}
                    onSelect={
                      mySubscription?.subscriptionPlan?._id === item.id
                        ? undefined
                        : () => {}
                    }
                  />
                </div>
              );
            }}
            noItemsElement={
              <div className="text-center py-20">
                <Label variant="s1">No plans available</Label>
              </div>
            }
            footerElement={
              <div
                className={cx("", {
                  "h-20": filterSubscriptions().length < 3,
                })}
              />
            }
          />
        </>
      </div>
      <div className="flex justify-between theme-bg-surface p-4">
        <TextButton
          label={labelCancel}
          disabled={disabled}
          onClick={() => {
            setActive(false);
          }}
        />

        <Button
          onClick={() => {
            // initiateSubscription(selectedSubscription!);
            if (!selectedSubscription) return;
            if (selectedSubscription.subscriptionAmount === 0) {
              handleFreeSubscriptions(selectedSubscription.id!);
              return;
            }
            setIsDisplayAddressCheckout(true);
          }}
          isLoading={status === "loading"}
          label={labelSubmit}
          disabled={!selectedSubscription || status === "loading"}
          btnColor={btnColor}
          large
        />
      </div>

      <CurrentSubscription mySubscription={mySubscription} />

      <AddressCheckout
        plan={selectedSubscription}
        user={user}
        community={community}
        existingBillingAddress={mySubscription?.billingDetails}
        isVisible={isDisplayAddressCheckout}
        setIsVisible={setIsDisplayAddressCheckout}
        onProceed={(billingInfo: BillingDetails) => {
          if (Boolean(billingInfo)) {
            // console.log("billingInfo", billingInfo);
            setIsDisplayAddressCheckout(false);
            initiateSubscription(selectedSubscription!, billingInfo);
          }
        }}
      />
    </Modal>
  );
};

/**
 * Displays plan with a radio button
 */
export function Plan({
  plan,
  mySubscription,
  selected = false,
  onSelect,
}: {
  plan: SubscriptionPlan;
  selected?: boolean;
  mySubscription?: MySubscription;
  onSelect?: (value: SubscriptionPlan) => void;
}) {
  const baseAmount = SubscriptionUtils.convertAmount(plan.subscriptionAmount);
  const taxAmount = baseAmount * ((plan.taxPercentage ?? 0) / 100);
  const currency =
    CurrencySymbol[plan.currency as keyof typeof CurrencySymbol] ||
    plan.currency;

  const totalAmount = SubscriptionUtils.calculateSubscriptionTotal(plan);

  /* Base Amount = Total / (1 + Percent/100) */
  // const baseAmount = amount / (1 + (plan?.taxPercentage ?? 0) / 100) ?? 0;

  return (
    <div
      className={cx(
        "flex flex-col gap-4 border theme-border-default  rounded px-4 py-3  hover:theme-bg-default",
        {
          "theme-border-primary": selected,
          "cursor-pointer": Boolean(onSelect),
        }
      )}
      onClick={() => {
        if (onSelect) {
          onSelect(plan);
        }
      }}>
      <div className="flex">
        <Radio
          selected={selected}
          className={cx("", {
            hidden: !onSelect,
          })}
          children={undefined}
        />
        <div className="flex-grow ml-2">
          <div className="flex place-content-between">
            <span className="flex items-center gap-2">
              <Label variant="t1">{plan.name}</Label>
              <Fold
                value={
                  mySubscription?.subscriptionPlan?._id === plan.id ?? false
                }
                ifPresent={() => {
                  return (
                    <span className="px-2 py-0.5 rounded theme-bg-primary theme-text-on-primary text-xs">
                      <I18>Current Plan</I18>
                    </span>
                  );
                }}
              />
            </span>
            <span>
              {baseAmount ? (
                <span className=" whitespace-nowrap">
                  <Label>
                    {currency}
                    {baseAmount}{" "}
                  </Label>
                  <Label variant="s1">
                    {(plan?.recurringInterval ?? 0) > 0 &&
                      `per ${plan?.recurringInterval} month`}
                  </Label>
                </span>
              ) : (
                <Label translate variant="t1">
                  Free
                </Label>
              )}
            </span>
          </div>
          <div className="flex flex-row justify-between">
            {plan.description && (
              <div
                dangerouslySetInnerHTML={{ __html: plan.description }}
                // className="theme-text-subtitle-2 text-sm mt-2"
                className="NL2BR headings mt-2 max-h-96 overflow-y-auto"></div>
            )}
          </div>
        </div>
      </div>
      {/* Amount breakdown */}
      {!onSelect && plan.taxPercentage && plan.taxPercentage > 0 ? (
        <div className="Amount flex flex-col gap-4 p-4 theme-bg-disable rounded">
          <div className="flex justify-between">
            <Label>{plan.name}</Label>
            <Label>
              {currency}&nbsp;
              {baseAmount}
            </Label>
          </div>
          <div className="flex justify-between">
            <Label>Tax at {plan.taxPercentage}%</Label>
            <Label>
              {currency}&nbsp;
              {taxAmount}
            </Label>
          </div>
          <hr />
          <div className="flex justify-between">
            <Label translate>Total</Label>
            <Label>
              {currency}&nbsp;
              {totalAmount}
            </Label>
          </div>
        </div>
      ) : null}
    </div>
  );
}

/**
 * Displays current subscription details
 */
export function CurrentSubscription({
  mySubscription,
}: {
  mySubscription?: MySubscription;
}) {
  if (!mySubscription || !mySubscription?.isActive) return null;

  const amount = SubscriptionUtils.convertAmount(
    mySubscription?.subscriptionPlan?.subscriptionAmount
  );
  const currency = mySubscription?.subscriptionPlan?.currency ?? "USD";
  const isRecurring = mySubscription?.subscriptionPlan?.isRecurring;

  return (
    <div className="p-4">
      <details className="group">
        <summary className="mt-2 flex cursor-pointer list-none items-center justify-between font-medium">
          <Label size="body1" variant="t1">
            Current Plan
          </Label>
          <span className="transition group-open:rotate-180">
            <UIcon icon="angle-down" />
          </span>
        </summary>
        <div className="select-none">
          <div className="flex flex-col  gap-1 border theme-border-primary rounded py-2 px-4 my-2">
            <Label variant="t2" className="text-primary-500">
              {mySubscription?.subscriptionPlan?.name}
            </Label>
            {amount ? (
              <span className="flex items-start gap-2 whitespace-nowrap">
                <Label>
                  {currency}
                  {amount}{" "}
                </Label>
                <Label variant="s1">
                  {(mySubscription.subscriptionPlan?.recurringInterval ?? 0) >
                    0 &&
                    `per ${mySubscription.subscriptionPlan?.recurringInterval} month`}
                </Label>
              </span>
            ) : (
              <Label translate variant="t1">
                Free
              </Label>
            )}
          </div>
          <div
            className={cx("flex justify-between items-center", {
              hidden: !isRecurring,
            })}>
            <Label variant="s1">
              If you change your plan, your current subscription will be
              canceled and a new subscription will be created.
            </Label>
          </div>
        </div>
      </details>
    </div>
  );
}

export { GroupSubscriptionCheckoutModel };
