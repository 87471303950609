import axios from "axios";
import { endpoints } from "../_config";
import { getUserTokenFromStorage } from "../_config/helper.config";

class PostHighlightService {
  static async getHighlightedPostsInCommunity(communityId) {
    return axios
      .get(endpoints.postHighlight.getHighlightedPosts(communityId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
  static async highlightCommunityPost(communityId, postId) {
    return axios
      .post(
        endpoints.postHighlight.highlightPost(communityId, postId),
        {},
        {
          headers: {
            Authorization: "Bearer " + getUserTokenFromStorage(),
          },
        }
      )
      .then((response) => response.data);
  }
  static async unHighlightCommunityPost(communityId, postId) {
    return axios
      .delete(endpoints.postHighlight.highlightPost(communityId, postId), {
        headers: {
          Authorization: "Bearer " + getUserTokenFromStorage(),
        },
      })
      .then((response) => response.data);
  }
}

export { PostHighlightService };
