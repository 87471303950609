import "polyfill-object.fromentries";
import React from "react";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import App from "./App";
import AppServiceProvider from "./contexts/app-service-context";
import { AppContext } from "./contexts/app.context";
import { ToastProvider } from "./contexts/toastr.context";
import reportWebVitals from "./reportWebVitals";
import "./_assets/font-icon/regular-uicons.css";
import "./_assets/font-icon/solid-uicons.css";
import { configureAxios } from "./_config/axios.config";
import { store } from "./_store";
import "./_styles/color/light-color.css";
import "./_styles/common.style.scss";
import "./_styles/main.style.scss";
import "./_styles/main_loader_style.scss";
import "./_styles/theme/theme.css";
import I18N from "./_utils/i18n";

configureAxios();

const ContextApp = connect((s) => ({ community: s.community }))(
  ({ community }) => {
    // get language
    const languageCode = community?.configurables?.COMMUNITY_DEFAULT_LANGUAGE;

    // make i18
    const lang = new I18N(languageCode);

    return (
      <AppContext.Provider
        value={{
          lang,
        }}>
        <AppServiceProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
        </AppServiceProvider>
      </AppContext.Provider>
    );
  }
);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ContextApp />
    </React.StrictMode>
  </Provider>
);

// remove the initial loader, hardcoded at index.html
// this loader show up before initial js load, and is removed as soon as js load is complete
const loader = document.getElementById("loader");
loader.parentElement.removeChild(loader);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
