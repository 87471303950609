import { useEffect, useState } from "react";
import { Remarkable } from "remarkable";
import {
  Button,
  convertYouTubeLinksToPlainText,
  getVideoEmbedFromDescription,
} from "..";
import { withToast } from "../../contexts/toastr.context";
import { history } from "../../_config";
import Validator from "../../_utils/validator";
import { Modal } from "../modal.component";
import CommunityLogo from "./community-logo.component";

function CommunityWelcomeMessagesComponent({ user = null, community }: any) {
  const [visible, setVisible] = useState<boolean>(false);
  const [welcomeMessages, setWelcomeMessages] = useState<Array<string>>([]);

  // set index
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!community || welcomeMessages.length > 0) return;
    const isTourCompleted = localStorage.getItem("pensil.tour-completed");

    // Don't display welcome message if
    // 1. There is no welcome message
    // 2. There is only one welcome message and it is empty
    // 3. User has already completed the tour
    // 4. User is not logged in
    if (
      (community &&
        (community.welcomeMessages?.length === 0 ||
          (community.welcomeMessages?.length === 1 &&
            community.welcomeMessages[0] === ""))) ||
      isTourCompleted ||
      !user
    ) {
      setVisible(false);
      return;
    }

    const messages = community.welcomeMessages;
    if (Validator.hasValue(community.welcomeMessages)) {
      let list: Array<string> = [];
      const md = new Remarkable();
      for (let i = 0; i < messages?.length; i++) {
        let message = community?.welcomeMessages[i];
        if (message) {
          message = convertYouTubeLinksToPlainText(message);
          const renderedDescription = md.render(message);
          const descriptionWithEmbeds = getVideoEmbedFromDescription(
            renderedDescription ?? ""
          );

          list.push(descriptionWithEmbeds);
        }
      }
      setWelcomeMessages(list);
    }

    setVisible(isTourCompleted == null ? true : false);
  }, [community, user, welcomeMessages.length]);

  const endJoyRide = () => {
    // end of the line
    history.replace({
      search: "",
    });
    localStorage.setItem("pensil.tour-completed", "true");
    setVisible(false);
    setIndex(0);
  };

  if (!visible) return <></>;

  return (
    <div className="CommunityWelcomeMessages">
      <Modal active={visible} setActive={endJoyRide} padding={false}>
        <div className="flex flex-col items-center py-6 theme-bg-surface border-b theme-border-default">
          <CommunityLogo community={community} height={148} maxSize />
          <div className="mt-3 mb-1 font-semibold text-xs">Welcome to</div>
          <div className="text-lg font-bold">{community.name!}</div>
        </div>
        {/* message board */}
        <div className="px-3 py-4">
          <div
            className="NL2BR headings break-all"
            dangerouslySetInnerHTML={{
              __html: welcomeMessages[index],
            }}
          />
        </div>
        {/* pagination */}
        <div className="pt-2 pb-4 flex justify-center">
          {welcomeMessages.map((message, i) => (
            <span
              key={i}
              className={"pageicon " + (i === index ? "active" : "")}></span>
          ))}
        </div>
        {/* footer */}
        <div className="flex items-center justify-between px-4 py-2 theme-bg-surface">
          {index === 0 ? (
            <div
              className="p-2 cursor-pointer theme-text-subtitle-1"
              onClick={endJoyRide}>
              Close
            </div>
          ) : (
            <Button
              label="Back"
              onClick={(e) => {
                setIndex(index - 1);
              }}
            />
          )}
          <Button
            label={index + 1 === welcomeMessages.length ? "Finish" : "Next"}
            onClick={(e) => {
              if (index + 1 === welcomeMessages.length) {
                endJoyRide();
              } else {
                setIndex(index + 1);
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

const CommunityWelcomeMessages = withToast(CommunityWelcomeMessagesComponent);

export default CommunityWelcomeMessages;
