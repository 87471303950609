import { User } from "../../types/user/minimal-user.type";
import { mixPanel } from "../../_config/mixpanel.config";
import { uuidv4 } from "../../_utils/extensions/typescript-utils";
import { AnalyticsEvents, GEvents } from "./events-type";

export interface GEventData {
  /**
   * user full name
   */
  user: Partial<User>;
  /**
   * date	2023-04-26
   */
  date: string;
  /**
   * unique key per session
   */
  sessionid: string;
  groupId: string;
  postId: string;
  groupName?: string;
  sectionId?: string;
  sectionName?: string;
  communityName?: string;
  communityId: string;
  communityUrl?: string;
  userType?: string;
  groupType?: string;
  /**
   * Source of the event. e.g. "home", "profile",
   */
  source: "home" | "profile" | "section" | "comment" | "post" | "notification";
  /**
   * unique key per impression/click/view
   */
  impressionId: string;
}

/**
 * Analytics service for application.
 */
export class AnalyticsService {
  private static instance: AnalyticsService;

  private communityId: string;
  private sessionid: string;
  private user?: Partial<User>;
  private communityName: string;
  private communityUrl: string;

  private constructor({
    communityId,
    communityName,
    user,
    communityUrl,
  }: Partial<GEventData>) {
    this.communityId = communityId ?? "";
    this.communityName = communityName ?? "";
    this.user = user;
    // Unique key per session. Consider using alphanumeric characters only.
    this.sessionid = uuidv4();
    this.communityUrl = communityUrl ?? window.location.href;
  }

  private updateValues({
    communityId,
    communityName,
    user,
    communityUrl,
  }: Partial<GEventData>) {
    if (communityId) {
      this.communityId = communityId;
    }
    if (communityName) {
      this.communityName = communityName;
    }
    if (user) {
      this.user = user;
    }
    if (communityUrl) {
      this.communityUrl = communityUrl;
    }
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static Instance({
    communityId,
    communityName,
    user,
    communityUrl,
  }: Partial<GEventData>): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService({
        communityId: communityId,
        communityName: communityName,
        user: user,
        communityUrl: communityUrl ?? window.location.href,
      });
    }

    AnalyticsService.instance.updateValues({
      communityId: communityId,
      communityName: communityName,
      user: user,
      communityUrl: communityUrl ?? window.location.href,
    });
    return AnalyticsService.instance;
  }

  /**
   * Send analytics data to server.
   * @param data Analytics data.
   */
  track(
    eventName: GEvents,
    data: Partial<GEventData> & { [key: string]: any } = {},
    extraData?: any
  ): void {
    // @ts-ignore
    // Send data to server.
    const gtag = window.gtag || ((() => {}) as any);

    if (!data.impressionId) {
      data.impressionId = uuidv4();
    }

    data.date = new Date().toISOString().split("T")[0];
    data.communityId = this.communityId;
    data.communityName = this.communityName;
    if (this.user) {
      data.user = this.user;
    }
    data.sessionid = this.sessionid;
    data.communityUrl = this.communityUrl;
    if (extraData) {
      data = { ...data, ...extraData };
    }
    data = {
      ...data,
      ...{
        event: AnalyticsEvents[eventName],
      },
    };

    gtag("event", eventName, data);
    mixPanel.track(eventName, data);
    // @ts-ignore
    if (window.umami) {
      // @ts-ignore
      window.umami.trackEvent(data, eventName);
    }

    // console.log("AnalyticsService.track", eventName, data);
  }
}
