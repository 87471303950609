import { connect } from "react-redux";
import { withToast } from "../../../contexts/toastr.context";
import { Modal } from "../../modal.component";
import EditPostComponent from "./component/edit-post-component";
/**
 * Edit post modal
 * @param {Object} community - community object
 * @param {Object} user - Auth user object
 * @param {Object} postToUpdate - post to update
 * @param {Function} updatePost - update post callback
 * @param {Function} addToast - Display toast callback
 * @param {Function} setActive - Toggle modal active/inactive state
 * @param {Boolean} active - Modal active/inactive state
 * @param {Object} activeGroup - Group in which post is being created. If post is being created from community home page, group is undefined.
 */
function UpdatePostModal({
  community,
  user,
  postToUpdate,
  updatePost = (post) => {},
  addToast,
  active,
  setActive,
  activeGroup,
}) {
  return (
    <Modal
      active={active}
      setActive={setActive}
      width="720px"
      padding={false}
      height="">
      <div className="CreatePost flex flex-col space-y-2 theme-bg-surface">
        <EditPostComponent
          setActive={setActive}
          user={user}
          community={community}
          postToUpdate={postToUpdate}
          updatePost={updatePost}
          addToast={addToast}
          activeGroup={activeGroup}
        />
      </div>
    </Modal>
  );
}

const UpdatePost = withToast(
  connect((s) => ({
    community: s.community,
    user: s.auth,
    activeGroup: s.activeGroup,
  }))(UpdatePostModal)
);

export default UpdatePost;
