import { NotificationService } from "../../_service/notification.service";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

/**
 * Set the notification list
 * @param {*} notifications
 * @returns
 */
export function setNotifications(notifications = []) {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
}

/**
 * Add a new Notification to list
 * @param {*} notification
 * @returns
 */
export function addNotification(notification = []) {
  return {
    type: ADD_NOTIFICATION,
    notification,
  };
}

// thunk related

export function getNotifications() {
  return (dispatch, getState) => {
    // get the user
    const { auth: user } = getState();

    // get all notification
    NotificationService.getNotifications(user)
      .then(({ notifications }) => {
        dispatch(setNotifications(notifications));
      })
      .catch((err) => {
        // TODO: show error
      });
  };
}
