import ReactDOM from "react-dom";

const ModalPortal = ({ openPortal, children }) => {
  const portalContainer = document.body;

  return (
    <>
      {openPortal &&
        ReactDOM.createPortal(
          <>
            <div>{children}</div>
          </>,
          portalContainer
        )}
    </>
  );
};

export default ModalPortal;
