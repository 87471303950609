import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withToast } from "../contexts/toastr.context";
import plusGrayIcon from "../_assets/vectors/plus-gray-icon.svg";
import I18 from "./atoms/i18";
import IconButton from "./icon-button.component";
import { Modal } from "./modal.component";
import CopyTextField from "./molecule/copy-text-field.component";
import ShareOnSocialMedia from "./molecule/share-on-social-media.component";

function ShareTextModalComponent({
  heading = "",
  text = "",
  active = false,
  setActive = (e) => {},
  onClose = (e) => {},
}) {
  if (!active) {
    return null;
  }
  return (
    <Modal
      className="ShareTextModal"
      active={active}
      setActive={setActive}
      padding={false}>
      <div className="pb-4">
        {/* Heading */}
        <div className="p-1 pl-4 theme-bg-surface flex justify-between items-center space-x-2">
          <span className="font-semibold my-2">{heading}</span>
          <IconButton icon={plusGrayIcon} rotate={45} onClick={onClose} />
        </div>
        {/* Body */}
        <div className="px-4 font-semibold theme-text-heading-2 my-2 ">
          <I18>Share via link</I18>
        </div>
        <CopyTextField text={text} className="theme-bg-surface mx-3" />
        <ShareOnSocialMedia text={text} className="mt-4" />
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth,
  community: state.community,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const ShareTextModal = withToast(
  connect(mapStateToProps, mapDispatchToProps)(ShareTextModalComponent)
);

export { ShareTextModal };
