import cx from "classnames";
import { useState } from "react";
import PostListShimmer, {
  PostTileType,
} from "../..//_components/shimmers/post/post-list-shimmer.component";
import { PostCard } from "../../_components";
import GoogleAds from "../../_components/atoms/google-ads.component";
import I18 from "../../_components/atoms/i18";
import { Avatar } from "../../_components/avatar.component";
import HighlightedPosts from "../../_components/community/highlighted-posts.component";
import Filter from "../../_components/filter.component";
import { SelectWidgetModal } from "../../_components/group/select-group-widget-modal.component";
import { MinimizedPostCard } from "../../_components/minimised-post-card";
import CreatePost from "../../_components/post/create";
import CustomWidget from "../../_components/widgets/custom-widget.components";
import LeaderBoardWidget from "../../_components/widgets/leaderboard-widget-component";
import MemberWidget from "../../_components/widgets/member-widget";
import TrendingPostWidget from "../../_components/widgets/trending-post-widget.component";
import UpcomingEventWidget from "../../_components/widgets/upcoming-events.widget.component";
import communitySpecificConfig from "../../_config/community-specific-config";
import { WidgetType } from "../group";
import { useAppService } from "../../hooks/use-app-service";

export default function HomePagePostList({
  addToast,
  community,
  posts = [],
  setPosts,
  user,
  widgets,
  hasMorePost,
  onWidgetListUpdate = (e) => {},
  isLoadingPosts = false,
  isLoadingMorePosts = false,
  sortOrder = "recent",
  setSortOrder = (e) => {},
}) {
  const [isSelectWidgetVisible, setIsSelectWidgetVisible] = useState(false);

  const config = communitySpecificConfig;

  const currentPath = window.location.href;

  const { analyticsService } = useAppService();

  const clickPostInputBox = () => {
    analyticsService.track("create-post-input-box-on-homefeed");
  }

  return (
    <div className={cx("CommunityPostTab my-6 flex flex-col px-2 sm:p-0")}>
      <div className="flex space-x-4">
        {/* Column -1 */}
        <div
          className={cx(" w-full", {
            "": community && !community.expandPostDirectory,
          })}>
          {/* highlights component */}
          <HighlightedPosts />
          {/* create post component */}
          {user && (
            <CreatePost
              user={user}
              group={undefined}
              groups={undefined}
              sectionId={undefined}
              addPost={(post) => {
                setPosts([post, ...posts]);
              }}
              addToast={addToast}
              community={community}
              inlineDivComponent={
                <>
                  <div className="PostList rounded-md shadow-sm p-4 theme-bg-surface w-full mb-3">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center w-full">
                        <Avatar
                          user={user}
                          noName
                          className="flex-none"
                          size={45}
                        />
                        <div className="font-semibold p-3  border theme-border-default theme-bg-default rounded-full flex-grow w-full theme-text-subtitle-1"
                        onClick={clickPostInputBox}>
                          <I18>What's on your mind</I18>,{" "}
                          {user.name.split(" ")[0]}?
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          )}
          <div className="flex items-center place-content-between mb-3">
            {/* sort order */}
            {posts.length > 0 ? (
              <Filter
                selectedOption={sortOrder}
                setSelectedOption={setSortOrder}
                options={[
                  { label: "Recent", key: "recent" },
                  {
                    label: "Top Posts",
                    key: "top",
                  },
                  { label: "Trending", key: "trending" },
                ]}
              />
            ) : (
              <div></div>
            )}
          </div>
          {isLoadingPosts ? (
            <div className="PostList theme-bg-surface p-2 rounded my-2">
              <PostListShimmer length={6} type={PostTileType.compact} />
            </div>
          ) : posts.length > 0 ? (
            <div
              className={cx("PostList", {
                "pensil-card my-2": community && !community.expandPostDirectory,
              })}>
              {posts.map((post) =>
                community && community.expandPostDirectory ? (
                  <PostCard
                    key={post.id}
                    isCommunityPost={true}
                    post={post}
                    user={user}
                    group={post.group}
                    updatePost={(post) => {
                      // get post index
                      const postIndex = posts.findIndex(
                        (p) => p.id === post.id
                      );
                      const oldPosts = [...posts];
                      oldPosts.splice(postIndex, 1, post);
                      setPosts(oldPosts);
                    }}
                    showFullPost={posts.length === 1}
                    deletePost={(post) => {
                      // get post index
                      const postIndex = posts.findIndex(
                        (p) => p.id === post.id
                      );
                      const oldPosts = [...posts];
                      oldPosts.splice(postIndex, 1);
                      setPosts(oldPosts);
                    }}
                  />
                ) : (
                  <MinimizedPostCard
                    post={post}
                    user={user}
                    updatePost={(post) => {
                      // get post index
                      const postIndex = posts.findIndex(
                        (p) => p.id === post.id
                      );
                      const oldPosts = [...posts];
                      oldPosts.splice(postIndex, 1, post);
                      setPosts(oldPosts);
                    }}
                    showFullPost={posts.length === 1}
                    deletePost={(post) => {
                      // get post index
                      const postIndex = posts.findIndex(
                        (p) => p.id === post.id
                      );
                      const oldPosts = [...posts];
                      oldPosts.splice(postIndex, 1);
                      setPosts(oldPosts);
                    }}
                    key={post.id}
                    displayLikeCommentIcons={
                      !(
                        post.preferences?.hideLikes &&
                        post.preferences?.hideComment
                      )
                    }
                  />
                )
              )}
            </div>
          ) : (
            <div className="no-post p-2 my-10 text-center">
              <div className="font-bold my-3">
                <I18>Looks like nothing available here right now.</I18>
              </div>
              <div>
                <I18>Try exploring other tabs</I18>
              </div>
            </div>
          )}
          {/* more post loader */}
          {isLoadingMorePosts ? (
            <div className="PostList theme-bg-surface p-2 rounded my-2">
              <PostListShimmer length={3} type={PostTileType.compact} />
            </div>
          ) : (
            <></>
          )}
          {hasMorePost ? (
            <div className="h-32 flex items-center place-content-center theme-text-subtitle-1 text-xs">
              <I18>
                Looks like you have reached the end of the list, No more post to
                display
              </I18>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Column -2 */}
        <div className="HomeWidget flex flex-col space-y-3 flex-grow ">
          {["admin", "moderator"].includes(community.myRole) && (
            <>
              <div
                onClick={() => {
                  analyticsService.track("click-select-widget", {
                    location: "home"
                  });
                  setIsSelectWidgetVisible(true);
                }}
                className="w-full flex place-content-center p-3 border border-dashed rounded theme-border-subtitle-2 theme-text-subtitle-1 cursor-pointer">
                <I18>Select Widgets</I18>
              </div>
              {/* Add/Update customs group widgets pop-up */}

              <SelectWidgetModal
                community={community}
                user={user}
                widgets={widgets}
                forCommunity={true}
                active={isSelectWidgetVisible}
                setActive={setIsSelectWidgetVisible}
                addToast={addToast}
                onWidgetListUpdate={onWidgetListUpdate}
              />
            </>
          )}

          {/*ADS FROM GOOGLE ADSENSE */}
          {config && config.googleAdsenseId && (
            <GoogleAds
              currentPath={currentPath}
              googleAdsenseId={config.googleAdsenseId}
              googleAdSlotId={config.googleAdSlotId}
            />
          )}

          {widgets && widgets.length > 0 && (
            <>
              {widgets.map((w, index) => {
                if (!user && !w.isVisible) {
                  return null;
                } else if (
                  ["user"].includes(community.myRole) &&
                  !w.isVisible
                ) {
                  return null;
                } else if (
                  ["admin"].includes(community.myRole) &&
                  !w.isVisibleToAdmin
                ) {
                  return null;
                }
                switch (w.type) {
                  case WidgetType.custom:
                    return <CustomWidget key={index} widget={w} />;
                  case WidgetType.members:
                    return (
                      <div className="" key={index}>
                        <MemberWidget
                          user={user}
                          community={community}
                          memberPreviewLimit={5}
                        />
                      </div>
                    );
                  case WidgetType.topPosts:
                    return (
                      <div className="" key={index}>
                        <TrendingPostWidget
                          user={user}
                          isCommunityPosts={true}
                        />
                      </div>
                    );
                  case WidgetType.upcomingEvents:
                    return (
                      <div key={index}>
                        <UpcomingEventWidget
                          user={user}
                          community={community}
                          isCommunityEvent={true}
                        />
                      </div>
                    );
                    case WidgetType.communityLeaderboard:
                      return (
                        <LeaderBoardWidget
                          key={index}
                          memberPreviewLimit={4}
                          isCommunityLeaderBoard={true}
                          group={undefined}
                        />
                      );
                  default:
                    return null;
                }
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
