import { getUserTokenFromStorage } from "../../_config/helper.config";
import { PostService } from "../../_service";

export const GET_POST_DETAIL_ACTION = "GET_POST_DETAIL_ACTION";
export const GET_POST_DETAIL_SUCCESS = "GET_POST_DETAIL_SUCCESS";
export const GET_POST_DETAIL_ERROR = "GET_POST_DETAIL_ERROR";
export const UPDATE_POST_DETAIL = "UPDATE_POST_DETAIL";

export function getPostDetailAction() {
  return {
    type: GET_POST_DETAIL_ACTION,
  };
}

export const getPostDetailSuccess = (post) => ({
  type: GET_POST_DETAIL_SUCCESS,
  post,
});

export const getPostDetailError = (errorMessage, statusCode) => {
  return {
    type: GET_POST_DETAIL_ERROR,
    error: errorMessage,
    statusCode: statusCode,
  };
};

export const updatePostDetail = (post) => ({
  type: UPDATE_POST_DETAIL,
  post,
});

export function getPostDetailById(postId) {
  return async (dispatch) => {
    dispatch(getPostDetailAction());
    const token = getUserTokenFromStorage();
    try {
      const { post } = await PostService.getPostDetail({ token }, postId);
      dispatch(getPostDetailSuccess(post));
    } catch (error) {
      dispatch(
        getPostDetailError("Post could not be fetched!", error.response.status)
      );
    }
  };
}

export function getPostDetailBySlug(slug) {
  return async (dispatch, getState) => {
    const { community } = getState();
    dispatch(getPostDetailAction());
    const token = getUserTokenFromStorage();
    try {
      const { post } = await PostService.getPostDetailFromSlug(
        { token },
        community.id,
        slug
      );
      dispatch(getPostDetailSuccess(post));
    } catch (error) {
      dispatch(
        getPostDetailError("Post could not be fetched!", error.response.status)
      );
    }
  };
}

const INITIAL_STATE = {
  post: null,
  isLoading: false,
  error: null,
};

export function postDetailReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_POST_DETAIL_ACTION:
      return {
        ...state,
        isLoading: true,
        post: null,
        error: null,
      };
    case GET_POST_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        post: action.post,
        error: null,
      };
    case GET_POST_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        post: null,
        error: action.error,
        statusCode: action.statusCode,
      };
    case UPDATE_POST_DETAIL:
      return {
        ...state,
        isLoading: false,
        post: action.post,
        error: null,
      };
    default:
      return state;
  }
}
