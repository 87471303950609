import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { createUserWallPageRoute } from "../../_pages/user-wall.page";
import { CommunityService, GroupService } from "../../_service";
import I18 from "../atoms/i18";
import { Avatar } from "../avatar.component";
import IconButton2 from "../icon-button-2-component";
import IconMenu2 from "../icon-menu-2.component";
import { Loader } from "../loader.component";
import { Modal } from "../modal.component";
import MakeAdminModal from "../roles/make-admin-modal.component";
import UIcon from "../uicon-component";
import UpdateRoleModal from "./update-role-modal.component";
import { getSegmentedPath } from "../../_utils/segmented-community-util";

/**
 * Get community subscribers with group role
 * @param {*} param0
 * @returns
 */
function SidebarCommunityCardComponent({
  user,
  group,
  community,
  addGroupMember,
  updateGroupMember,
  removeGroupMember,
  updateSubscriberRef = null,
  addToast,
}) {
  // get list of subscribers
  const [subscribers, setSubscribers] = useState([]);
  const [subscribersCount, setSubscribersCount] = useState(0);

  // helps with pagination
  const [isLoadingMoreSubscribers, setIsLoadingMoreSubscribers] =
    useState(false);
  const [noMoreSubscribers, setNoMoreSubscribers] = useState(false);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubscriberListVisible, setIsSubscriberListVisible] = useState(false);

  // for admin role related changes
  const [isMakeAdminModalVisible, setIsMakeAdminModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUpdateRoleModalVisible, setIsUpdateRoleModalVisible] =
    useState(false);
  const innerRef = useRef();

  // search filter
  const [query, setQuery] = useState("");

  // load the subscriber list once, or per community or group change
  useEffect(() => {
    if (["admin"].includes(community.myRole)) {
      setIsLoading(true);
      CommunityService.getCommunitySubscribersPaginated(
        user,
        community.id,
        10,
        1,
        query,
        group.id
      )
        .then(({ users, total }) => {
          setSubscribers(users);
          setSubscribersCount(total);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error({ error });
          setIsLoading(false);
        });
    }
  }, [user, community.id, community.myRole]);

  const handleSearchDebounced = React.useCallback(
    debounce((query) => {
      if (["admin"].includes(community.myRole)) {
        setIsLoading(true);
        CommunityService.getCommunitySubscribersPaginated(
          user,
          community.id,
          10,
          1,
          query,
          group.id
        )
          .then(({ users, total }) => {
            setSubscribers(users);
            setSubscribersCount(total);
            setIsLoading(false);
            setNoMoreSubscribers(false);
          })
          .catch((error) => {
            console.error({ error });
            setIsLoading(false);
          });
      }
    }, 1000),
    [community.myRole]
  );

  const subscribersList = subscribers;

  const updateSubscriber = (subscriber) => {
    const newSubscribers = [...subscribers];
    const memberIndex = newSubscribers.findIndex((m) => m.id === subscriber.id);
    newSubscribers.splice(memberIndex, 1, subscriber);
    setSubscribers(newSubscribers);
  };

  if (updateSubscriberRef) {
    updateSubscriberRef.current = updateSubscriber;
  }

  const removeSubscriber = (subscriber) => {
    const newSubscribers = [...subscribers];
    const memberIndex = newSubscribers.findIndex((m) => m.id === subscriber.id);
    newSubscribers.splice(memberIndex, 1);
    setSubscribers(newSubscribers);
  };

  const onScroll = React.useCallback(() => {
    if (innerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = innerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // subscribers list lazy loading you're at the bottom of the page
        // do this when we reach end
        if (!isLoadingMoreSubscribers && !noMoreSubscribers) {
          const pageCount = page + 1;
          // if we are not already loading more subscribers, load more subscribers
          setIsLoadingMoreSubscribers(true);
          CommunityService.getCommunitySubscribersPaginated(
            user,
            community.id,
            10,
            pageCount,
            query,
            group.id
          )
            .then((response) => {
              if (response.users.length === 0) {
                setNoMoreSubscribers(true);
              } else {
                setSubscribers([...subscribers, ...response.users]);
                setPage(pageCount);
              }
              setIsLoadingMoreSubscribers(false);
            })
            .catch((error) => {
              console.error({ error });
              setIsLoading(false);
            });
        }
      }
    }
  }, [isLoadingMoreSubscribers, noMoreSubscribers, page, query, subscribers]);

  // if not admin or moderator, show nothing
  if (community.myRole !== "admin") {
    return <></>;
  }

  return (
    <div className="theme-bg-surface rounded">
      {/* <div
        className="py-2 px-4 flex rounded justify-between border-b  theme-border-default items-center cursor-pointer"
        onClick={(e) => {
          if (community.myRole === "admin") {
            setIsSubscriberListVisible(true);
          }
        }}>
        <span className="sidebar-heading">
          {subscribersCount} <I18>Members</I18>
        </span>
        {isLoading ? (
          <div className="p-1">
            <Loader />
          </div>
        ) : (
          <IconButton icon="left-arrow" alt="More" rotate={180} />
        )}
      </div> */}
      {/* subscriber list */}
      <Modal
        className="theme-bg-surface"
        setActive={setIsSubscriberListVisible}
        active={isSubscriberListVisible}
        padding={false}>
        {/* headers */}
        <div className="pl-4 pr-2 py-2 theme-bg-surface flex items-center justify-between">
          <span className="font-bold theme-text-heading-1">
            {community.name} - {subscribersCount} <I18>Subscribers</I18>
          </span>
          <IconButton2
            hoverable={true}
            icon="cross"
            size="xs"
            iconClass="theme-text-subtitle-2 text-xs"
            onClick={() => {
              setIsSubscriberListVisible(false);
            }}
          />
        </div>
        {/* body */}
        <div className="p-5 theme-text-heading-1 pb-10 theme-bg-default">
          {/* search bar */}
          <div className="flex theme-bg-surface items-center border theme-border-default theme-bg-default px-4 py-1 rounded mb-4">
            <UIcon icon="search" className="theme-text-subtitle-2 text-xs" />
            <input
              type="text"
              placeholder="Search"
              value={query}
              onChange={(e) => {
                setIsLoading(true);
                setQuery(e.target.value);
                handleSearchDebounced(e.target.value);
              }}
              className="flex-grow  bg-transparent py-1 px-4 focus:outline-none"
            />
            {query.length > 0 ? (
              <i
                onClick={() => {
                  setIsLoading(true);
                  setQuery("");
                  handleSearchDebounced("");
                }}
                className="fi fi-rr-cross theme-text-heading-2 text-sm pr-1 cursor-pointer"
              />
            ) : null}
          </div>
          {/* subscribers list */}
          {isLoading ? (
            <div className="p-1">
              <Loader />
            </div>
          ) : (
            <></>
          )}
          <div
            onScroll={onScroll}
            ref={innerRef}
            className="overflow-y-scroll h-96">
            {subscribersList.map((member) => (
              <div
                className="m-3 flex justify-between items-center cursor-pointer"
                key={member.id}>
                <div className="flex items-center justify-center">
                  <Avatar
                    user={member}
                    onClick={() => {
                      // open the user page
                      window.open(
                        getSegmentedPath(createUserWallPageRoute(member.id, "activity"))
                      );
                    }}
                    tag={
                      <>
                        {member.communityRole === "admin" ? (
                          <div className="bg-blue-50 mx-2 font-semibold theme-text-primary text-xxs px-1 rounded">
                            <I18>Community Admin</I18>
                          </div>
                        ) : (
                          <></>
                        )}
                        {member.groupRole === "admin" ? (
                          <div className="bg-blue-50 mx-2 font-semibold theme-text-primary text-xxs px-1 rounded">
                            <I18>Admin</I18>
                          </div>
                        ) : member.groupRole === "moderator" ? (
                          <div className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-1 rounded">
                            <I18>Moderator</I18>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                    extraInfo={[member.mobile, member.email]
                      .filter((e) => e)
                      .join(" · ")}
                  />
                </div>
                {/* show approval for user, when community needs verification */}
                {community.myRole === "admin" &&
                community.needsAdminVerification &&
                member.communityRole !== "admin" &&
                !member.communityAdminVerified ? (
                  <div
                    className="bg-green-50 mx-2 font-semibold text-green-500 text-xxs px-2 py-1 border border-green-500 rounded"
                    onClick={(e) => {
                      CommunityService.verifyUserByAdmin(
                        user,
                        community.id,
                        member.id
                      )
                        .then(() => {
                          updateSubscriber({
                            ...member,
                            communityAdminVerified: true,
                          });
                        })
                        .catch((error) => {
                          console.error({ error });
                        });
                    }}>
                    <I18>Accept</I18>
                  </div>
                ) : (
                  <></>
                )}
                {/* show request to admin */}
                {community.myRole === "admin" ? (
                  <div>
                    {/* group admin operations */}
                    <IconMenu2
                      dropdownClassName="w-56"
                      hideOnEmpty
                      actions={[
                        {
                          icon: "edit",
                          label: "Change Role",
                          onClick: () => {
                            setSelectedUser(member);
                            setIsUpdateRoleModalVisible(true);
                          },
                        },
                        {
                          // button to add to group
                          icon: "user-add",
                          label: member.groupRole !== "" ? "" : "Add to group", // add only memeber where not already part of group
                          onClick: (e) => {
                            // update the user role to user, it adds the user too
                            GroupService.updateUserRole(
                              user,
                              group.id,
                              member.id,
                              "user"
                            )
                              .then(({ group }) => {
                                // update the current member
                                addGroupMember(member);
                                // update the subscriber in list
                                updateSubscriber({
                                  ...member,
                                  groupRole: "user",
                                });
                                // update email
                                addToast(
                                  `${member.name} is now part of` + group.name,
                                  "",
                                  ToastTypes.success
                                );
                              })
                              .catch((err) => {
                                console.log({
                                  message:
                                    "Error while updating the group member role",
                                  err,
                                });
                                addToast(
                                  "Error while updating the group member role",
                                  "",
                                  ToastTypes.error
                                );
                              });
                          },
                        },
                        {
                          // button to remove group
                          icon: "user-delete",
                          label: ["admin", ""].includes(member.groupRole)
                            ? ""
                            : "Remove from group", // cant remove admin
                          onClick: (e) => {
                            GroupService.removeUser(user, group.id, member.id)
                              .then(() => {
                                // TODO: add the current member
                                removeGroupMember(member);
                                updateSubscriber({
                                  ...member,
                                  groupRole: "",
                                });
                                addToast(
                                  `${member.name} is removed from` + group.name,
                                  "",
                                  ToastTypes.success
                                );
                              })
                              .catch((err) => {
                                console.log({ err });
                                addToast(
                                  "Error while removing the group member",
                                  "",
                                  ToastTypes.error
                                );
                              });
                          },
                          actionType: "alert",
                        },
                        /*      { // show make user admin modal
                                                             icon: 'user-remove',
                                                             label: member.id !== user.id && member.communityRole === "admin" ? "Remove Community Admin" : "",
                                                             onClick: (e) => {
                                                                 // call the api to remove admin
                                                                 CommunityService.removeUserAsAdminOfWholeCommunity(user, community.id, member.id)
                                                                     .then(res => {
                                                                         // addToast('Role successfully updated', '', ToastTypes.success);
                                                                         updateSubscriber({ ...member, communityRole: "user" });
                                                                         addToast(`${member.name} is removed as admin of` + community.name, "", ToastTypes.success);
                                                                     })
                                                                     .catch(err => {
                                                                         // addToast(err.message, '', ToastTypes.danger);
                                                                         console.log("some error occurred", { err });
                                                                         addToast("Error while removing the community admin", "", ToastTypes.error);
                                                                     });
                                                             },
                                                             actionType: 'alert'
                                                         }, */
                        {
                          // show make user admin modal
                          icon: "cross",
                          label:
                            member.id !== user.id ? "Kick from community" : "",
                          onClick: (e) => {
                            // call the api to remove admin
                            CommunityService.removeUserFromCommunity(
                              user,
                              community.id,
                              member.id
                            )
                              .then((res) => {
                                // addToast('Role successfully updated', '', ToastTypes.success);
                                removeSubscriber(member);
                                addToast(
                                  `${member.name} is removed from` +
                                    community.name,
                                  "",
                                  ToastTypes.success
                                );
                              })
                              .catch((err) => {
                                // addToast(err.message, '', ToastTypes.danger);
                                console.log("some error occurred", { err });
                                addToast(
                                  "Error while removing the community member",
                                  "",
                                  ToastTypes.error
                                );
                              });
                          },
                          actionType: "alert",
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
          {isLoadingMoreSubscribers ? (
            <div className="p-1">
              <Loader />
            </div>
          ) : null}
        </div>
        {/* make admin modal */}
        <MakeAdminModal
          activeGroup={group}
          active={isMakeAdminModalVisible}
          setActive={setIsMakeAdminModalVisible}
          updateGroupMember={updateGroupMember}
          updateSubscriber={updateSubscriber}
          selectedUser={selectedUser}
        />
      </Modal>
      {/* update role modal */}
      <UpdateRoleModal
        selectedUser={isUpdateRoleModalVisible && selectedUser}
        activeGroup={group}
        updateGroupMember={updateGroupMember}
        updateSubscriber={updateSubscriber}
        unselectUser={(e) => {
          setIsUpdateRoleModalVisible(false);
          setSelectedUser(null);
        }}
      />
    </div>
  );
}

const SidebarCommunityCard = connect((s) => ({
  user: s.auth,
  group: s.activeGroup,
  community: s.community,
}))(withToast(SidebarCommunityCardComponent));

export default SidebarCommunityCard;
