import axios from "axios";
import { endpoints } from "../_config";

class EventService {
  /**
   *
   * @param {string} communityId
   * @param {string} date
   * @description Get all events of a community
   * @returns
   */
  static async getAllEvents(user, communityId, date) {
    return axios
      .get(endpoints.event.getAllEvents(communityId, date), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @param {string} eventId
   * @param {File} file
   * @description Get all events of a community
   * @returns
   */
  static async updateEventBanner(user, communityId, eventId, file) {
    const formData = new FormData();
    formData.append("banner", file);
    return axios
      .post(endpoints.event.updateEventBanner(communityId, eventId), formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} startTime
   * @param {string} endTime
   * @param {string} title
   * @param {string} communityId
   * @description
   * Create an event
   * @returns
   */
  static async createEvent(
    user,
    communityId,
    startTime,
    endTime,
    title,
    description,
    location,
    webURL,
    maximumRegistrations,
    eventHost,
    createMeeting,
    meta
  ) {
    return axios
      .post(
        endpoints.event.createEvent(communityId),
        {
          startTime,
          endTime,
          title,
          description,
          location,
          webURL,
          maximumRegistrations,
          eventHost,
          createMeeting,
          meta: meta,
        },

        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} startTime
   * @param {string} endTime
   * @param {string} title
   * @param {string} postGroup
   * @param {string} postSection
   * @param {string} communityId
   * @description Create an event
   * @returns
   */
  static async updateEvent(
    user,
    communityId,
    eventId,
    notifyUser,
    startTime,
    endTime,
    title,
    description,
    location,
    webURL,
    maximumRegistrations,
    eventHost,
    createMeeting,
    meta
  ) {
    return axios
      .post(
        endpoints.event.updateEvent(communityId, eventId, notifyUser),
        {
          startTime,
          endTime,
          title,
          description,
          location,
          webURL,
          maximumRegistrations,
          eventHost,
          createMeeting,
          meta: meta,
        },

        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   *
   * @description Get all events of a community using event id
   * @returns
   */
  static async getEventDetail(user, communityId, eventId) {
    return axios
      .get(endpoints.event.getEventDetail(communityId, eventId), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @description Get all events of a community using event slug
   * @returns
   */
  static async getEventDetailBySlug(user, communityId, slug) {
    return axios
      .get(endpoints.event.getEventDetailBySlug(communityId, slug), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @param {string} eventId
   * @description Get all events of a community
   * @returns
   */
  static async acceptInvitation(user, communityId, eventId) {
    return axios
      .post(
        endpoints.event.acceptInvitation(communityId, eventId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @description Delete an event
   * @returns
   */
  static async deleteEvent(user, communityId, eventId, notifyUser) {
    return axios
      .delete(endpoints.event.updateEvent(communityId, eventId, notifyUser), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @param {string} eventId
   * @description accept event invite
   * @returns
   */
  static async updateRSVP(user, communityId, eventId, status) {
    return axios
      .post(
        endpoints.event.updateRSVP(communityId, eventId),
        { status },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @description Get Upcoming Events
   * @returns
   */
  static async upcomingEvents(user, communityId, timestamp) {
    return axios
      .get(endpoints.event.upcomingEvents(communityId, timestamp), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @param {string} eventId
   * @description Get latest group events
   * @returns
   */
  static async latestGroupEvent(user, communityId, groupId) {
    return axios
      .get(endpoints.event.latestGroupEvents(communityId, groupId), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @description Get Previous Events
   * @returns
   */
  static async previousEvents(user, communityId, timestamp) {
    return axios
      .get(endpoints.event.previousEvents(communityId, timestamp), {
        headers: {
          Authorization: "Bearer " + (user ? user.token : null),
        },
      })
      .then((response) => response.data);
  }

  /**
   *
   * @param {string} communityId
   * @param {string} eventId
   * @description accept event invite
   * @returns
   */
  static async inviteSections(user, communityId, eventId, groups) {
    return axios
      .post(
        endpoints.event.inviteSections(communityId, eventId),
        { groups },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
}

export { EventService };
