import cx from "classnames";
import React, { useEffect, useMemo } from "react";

interface Props {
  className?: string;
  currentPath?: string;
  googleAdsenseId: string;
  googleAdSlotId: string;
}
/**
 * Component to display Google Ads
 */
export default function GoogleAds({
  className,
  currentPath,
  googleAdsenseId,
  googleAdSlotId,
}: Props) {
  const [isScriptLoaded, setIsScriptLoaded] = React.useState(false);
  const isLoaded = document.getElementById("google-adsense-script");

  useEffect(() => {
    if (!isLoaded) {
      return;
    } else {
      setIsScriptLoaded(true);
    }
  }, [isLoaded]);

  const Ad = useMemo(
    () => (
      <Ads
        googleAdsenseId={googleAdsenseId}
        googleAdSlotId={googleAdSlotId}
        pathname={currentPath ?? window.location.href}
      />
    ),
    [googleAdsenseId, currentPath, googleAdSlotId]
  );

  if (!isScriptLoaded) {
    return null;
  }

  return (
    <div key={currentPath} className={className}>
      {Ad}
    </div>
  );
}

function Ads({
  googleAdsenseId,
  pathname,
  googleAdSlotId,
}: {
  googleAdsenseId: string;
  pathname: string;
  googleAdSlotId: string;
}) {
  useEffect(() => {
    // @ts-ignore
    window.adsbygoogle = window.adsbygoogle || [];
    // @ts-ignore
    const list = window.adsbygoogle;
    if (list.length === 0) {
      // @ts-ignore
      window.adsbygoogle.push({});
      // @ts-ignore
      console.log("adsbygoogle.push : 1", { list });
    }
    // @ts-ignore
    // push ads only if last element is not empty object
    else if (list.length > 0 && Object.keys(list[list.length - 1]).length > 0) {
      // @ts-ignore
      window.adsbygoogle.push({});
      // @ts-ignore
      console.log("adsbygoogle.push : 2", { list });
    } else if (list.length > 0) {
      console.log({
        Length: list.length,
        Count: Object.keys(list[list.length - 1]).length,
      });
    }
  });

  return (
    <div key={pathname}>
      <ins
        className={cx("adsbygoogle")}
        style={{ display: "block" }}
        data-ad-client={googleAdsenseId}
        data-ad-slot={googleAdSlotId}
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  );
}
