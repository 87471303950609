import BlankLinkify from "./atoms/blank-linkify.component";

export function NL2BR({ text = "", passTextThrough = (text) => text }) {
  const parsedText = text.split("\n");

  return (
    <p className="NL2BR">
      {parsedText.map((text, index) => (
        <span key={index}>
          {index !== 0 ? <br /> : <></>}
          <BlankLinkify>{passTextThrough(text)}</BlankLinkify>
        </span>
      ))}
    </p>
  );
}
