let apiEndpointBase = process.env.REACT_APP_BASE_URL;
let socketEndpointBase = process.env.REACT_APP_SOCKET_URL;

// check if need to upgrade server for specific community
const upgradedCommunities = [
  "tech.pensil.in",
  // "tech.pensil.com",
  "newstudy.pensil.in",
  "aol.pensil.in",
];
if (upgradedCommunities.includes(window.location.hostname)) {
  apiEndpointBase = "https://api-server.pensil.in/api/";
  socketEndpointBase = "https://socket-server.pensil.in";
}

export { apiEndpointBase, socketEndpointBase };
