import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import useCache from "../../../hooks/use-cache.hook";
import { useToast } from "../../../hooks/use-toast.hook";
import { Course } from "../../../types/course/course.type";
import { Loader } from "../../../_components";
import { ActionModal } from "../../../_components/action-modal.component";
import { TabBody } from "../../../_components/atoms/tab-body.component";
import { TabPills } from "../../../_components/atoms/tab-pills.component";
import TextButton from "../../../_components/atoms/text-button";
import IconMenu2, {
  ActionType,
} from "../../../_components/icon-menu-2.component";
import ModalForm from "../../../_components/model2";
import AlertStrip from "../../../_components/molecule/alert-strips";
import Breadcrumb from "../../../_components/molecule/breadcumb";
import { CourseService } from "../../../_service";
import { Fold } from "../../../_utils/extensions/typescript-utils";
import { SubscriptionUtils } from "../../../_utils/subscription/subscription-util";
import { GroupCheckoutComponents } from "../../group/group-checkout-components";
import CreateCourse from "../create-course";
import { CourseLessonsView } from "./component/course-lessons-view";
import { CourseMembersView } from "./component/course-members-view";
import { CourseSettingsView } from "./component/course-settings-view";

export const COURSE_DETAIL_PAGE_ROUTE = "/course/:id";
export function getCoursePageRoute(courseId: string) {
  return `/course/${courseId}`;
}
export const CourseStateType = Object.freeze({
  draft: "draft",
  published: "published",
  "closed-free": "closed-free",
});
export const CourseJoinStatus = Object.freeze({
  "not-joined": "not-joined",
  requested: "requested",
  joined: "joined",
});

interface CourseDetailPageComponentProps {
  user: any;
  community: any;
  subscriptionsPlan: any;
  mySubscription: any;
  socket: any;
}
function CourseDetailPageComponent({
  user,
  community,
  subscriptionsPlan,
  mySubscription,
  socket,
}: CourseDetailPageComponentProps) {
  const params = useParams() as any;
  const courseId = params?.id || "";
  const communityId = community?.id;

  // Course state
  const [course, setCourse] = useState<Course>();
  const [loading, setLoading] = useState(false);
  const [hasWritePermission, setHasWritePermission] = useState(false);
  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = React.useState(false);
  const [isDeletingCourse, setIsDeletingCourse] = React.useState(false);
  const [error, setError] = useState(null);
  const [joinStatus, setJoinStatus] = useState<keyof typeof CourseJoinStatus>();
  const [joiningCourse, setJoiningCourse] = useState(false);

  // Members state
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [members, setMembers] = useState<Array<any>>([]);
  const [currentMemberPage, setCurrentMemberPage] = useState(1);
  const [totalMembers, setTotalMembers] = useState(0);

  // Buy course state
  const [isBuyCourseVisible, setIsBuyCourseVisible] = useState(false);

  const { cache, saveCache } = useCache({} as any);
  const { addToast, ToastTypes } = useToast();

  const TabButton = Object.freeze({
    Lesson: "Lesson",
    Settings: "Settings",
    Members: "Members",
  });

  const [selectedTab, setSelectedTab] = useState<keyof typeof TabButton>(
    TabButton.Lesson
  );

  // List of plans that are applicable for this course
  const plans = useMemo(
    () =>
      SubscriptionUtils.filterSubscriptionsForCourse(
        courseId,
        subscriptionsPlan
      ),
    [subscriptionsPlan, courseId]
  );

  // Check if course is paid. If course is paid, then check if user has purchased any subscription plan
  const isPaidCourse = SubscriptionUtils.hasPaidSubscription(plans, courseId);

  // Check if user has purchased any subscription plan
  const isSubscriptionPurchased =
    SubscriptionUtils.isSubscriptionPurchasedByCourse(mySubscription, courseId);

  // Get course details
  useEffect(() => {
    if (!communityId || !courseId) {
      return;
    }
    setLoading(true);
    CourseService.getCourseDetails(communityId, courseId)
      .then(({ course, isAdmin }) => {
        setCourse(course);
        setJoinStatus(course.joinStatus);
        setHasWritePermission(isAdmin);
      })
      .catch(({ response }) => {
        console.error(response.data.errors);
        setError(response.data.errors);
        addToast("Error", "Unable to get course", ToastTypes.danger);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, courseId]);

  // Get course members
  useEffect(() => {
    // don't call API
    const restrictAPIcall =
      !communityId ||
      !courseId ||
      // If course is not published,
      course?.state === CourseStateType.draft ||
      // If members tab is not selected,
      selectedTab !== TabButton.Members;

    if (restrictAPIcall) {
      return;
    }

    // Check if data is already in cache
    if (cache[`course-members-${courseId}${currentMemberPage}`]) {
      setMembers(cache[`course-members-${courseId}${currentMemberPage}`]);
      return;
    }
    setLoadingMembers(true);
    // Fetch data from API
    CourseService.getCourseMembers(
      community.id,
      courseId,
      currentMemberPage,
      10
    )
      .then(({ members, total }) => {
        saveCache(`course-members-${courseId}${currentMemberPage}`, members);
        setMembers(members);
        setTotalMembers(total);
      })
      .catch(({ response }) => {
        console.error(response.data.errors);
        // setError(response.data.errors);
        addToast("Error", "Unable to get course members", ToastTypes.danger);
      })
      .finally(() => {
        setLoadingMembers(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, communityId, courseId, currentMemberPage, selectedTab]);

  // DELETE COURSE
  const handleDeleteCourse = () => {
    if (!course) {
      console.error("Course not found");
      return;
    }
    setIsDeletingCourse(true);
    CourseService.deleteCourse(community.id, course.id)
      .then(() => {
        addToast("Success", "Course deleted successfully", ToastTypes.success);
        setCourse(undefined);
        setIsDeletingCourse(false);
      })
      .catch((errors) => {
        console.error(errors);
        addToast("Error", "Unable to delete course", ToastTypes.danger);
      })
      .finally(() => {
        setIsDeletingCourse(false);
      });
  };

  // JOIN COURSE
  const handleJoinCourse = () => {
    if (!course) {
      console.error("Course not found");
      return;
    }
    setJoiningCourse(true);
    CourseService.joinCourse(community.id, course.id)
      .then(() => {
        if (course.state === CourseStateType["closed-free"]) {
          addToast(
            "Success",
            "Course join requested sent successfully",
            ToastTypes.success
          );
          setJoinStatus(CourseJoinStatus.requested);
        } else {
          addToast("Success", "Course joined successfully", ToastTypes.success);
          setJoinStatus(CourseJoinStatus.joined);
        }
      })
      .catch((errors) => {
        console.error(errors);
        addToast("Error", "Unable to join course", ToastTypes.danger);
      })
      .finally(() => {
        setJoiningCourse(false);
      });
  };

  // DISPLAY LOADER WHILE FETCHING COURSE DETAILS
  if (loading) {
    return (
      <div className="flex justify-center m-3">
        <Loader />
      </div>
    );
  } else if (!course || Object.keys(course).length === 0) {
    // DISPLAY MESSAGE IF COURSE NOT FOUND
    return (
      <div className="flex flex-col gap-2">
        <Breadcrumb
          items={[
            { label: "Course", link: "/course" },
            {
              label: course?.meta?.slug || courseId,
              link: course?.meta?.slug || courseId,
            },
          ]}
        />
        <AlertStrip
          className="theme-bg-surface mt-4"
          error
          title="Course not found"
          message="The course you are looking for does not exist."
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center m-3">
        {/* <ErrorDiv error={error} /> */}
        Unable to fetch course details
      </div>
    );
  }
  return (
    <div className="CourseDetailPage flex flex-col gap-2 px-2 sm:px-0 mb-20 h-full">
      {/* BREADCRUMBS  */}
      <div className="flex-none">
        <Breadcrumb
          items={[
            { label: "Course", link: "/course" },
            {
              label: course?.meta?.slug || courseId,
              link: course?.meta?.slug || courseId,
            },
          ]}
        />
      </div>
      {/* HEADER */}
      <div className="flex flex-col flex-none theme-bg-surface  shadow rounded">
        <Fold
          value={course.banner}
          ifPresent={(banner) => (
            <div>
              <div
                className="theme-bg-primary  object-cover"
                style={{
                  ...{
                    backgroundColor: "var(--theme-primary-light-color)",
                    aspectRatio: "16/6",
                  },
                }}>
                <img src={banner} className="rounded-t" alt="banner" />
              </div>
            </div>
          )}
        />

        <div className="flex items-center justify-between pl-4 pr-2 ">
          <div className="flex items-center space-x-4 font-bold theme-text-heading-2 py-2">
            {course.title}
          </div>

          {/* ACTION BUTTONS */}
          {hasWritePermission ? (
            <IconMenu2
              actions={[
                {
                  icon: "edit",
                  onClick: () => setIsEditVisible(true),
                  label: "Edit",
                  actionType: ActionType.default,
                },
                {
                  icon: "trash",
                  onClick: () => setIsDeleteVisible(true),
                  label: "Delete",
                  actionType: ActionType.alert,
                },
              ]}
            />
          ) : (
            // Actions for users
            <>
              {joinStatus === CourseJoinStatus["not-joined"] ? (
                <>
                  <TextButton
                    className="outline outline-1 my-3"
                    label={
                      course.state === CourseStateType["closed-free"]
                        ? isSubscriptionPurchased
                          ? "Send Request to enroll"
                          : isPaidCourse
                          ? "Buy Now"
                          : "Send Request to enroll"
                        : "Enroll Now"
                    }
                    onClick={() => {
                      if (isPaidCourse && !isSubscriptionPurchased) {
                        setIsBuyCourseVisible(true);
                      } else {
                        handleJoinCourse();
                      }
                    }}
                    isLoading={joiningCourse}
                  />
                </>
              ) : joinStatus === CourseJoinStatus.requested ? (
                <>
                  <TextButton
                    className="outline-1 my-3"
                    label="Request Sent"
                    disabled={true}
                  />
                </>
              ) : (
                joinStatus === CourseJoinStatus.joined && (
                  <>
                    <TextButton
                      className="my-3 cursor-not-allowed"
                      label="Enrolled"
                      onClick={() => {}}
                    />
                  </>
                )
              )}
            </>
          )}
        </div>

        {/* TABS */}
        <Fold
          value={hasWritePermission}
          ifPresent={(val) => (
            <div className="flex justify-start">
              <TabPills
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabs={[
                  { label: TabButton.Lesson, key: TabButton.Lesson },
                  {
                    label: TabButton.Settings,
                    key: TabButton.Settings,
                  },
                  { label: TabButton.Members, key: TabButton.Members },
                ]}
              />
            </div>
          )}
        />
      </div>

      {/* BODY */}

      <TabBody
        selectedTab={selectedTab as any}
        components={{
          [TabButton.Lesson]: (
            <CourseLessonsView
              community={community}
              course={course}
              setCourse={setCourse}
              hasWritePermission={hasWritePermission}
            />
          ),
          [TabButton.Settings]: (
            <CourseSettingsView
              community={community}
              course={course}
              setCourse={setCourse}
            />
          ),
          [TabButton.Members]: (
            <CourseMembersView
              community={community}
              course={course}
              members={members}
              setMembers={(members) => {
                saveCache(
                  `course-members-${courseId}${currentMemberPage}`,
                  members
                );
                setMembers(members);
              }}
              isLoading={loadingMembers}
              currentMemberPage={currentMemberPage}
              totalMembers={totalMembers}
              setCurrentMemberPage={setCurrentMemberPage}
            />
          ),
        }}
      />

      {/* EDIT COURSE */}
      {hasWritePermission && (
        <ModalForm
          title={"Edit Course"}
          className="w-full sm:w-2/3 md:w-2/4 lg:w-1/3"
          visible={isEditVisible}
          setVisible={setIsEditVisible}>
          <CreateCourse
            // @ts-ignore
            course={course}
            community={community}
            setVisible={setIsEditVisible}
            onCourseUpdated={(e: Course) => {
              setCourse(e);
              setIsEditVisible(false);
            }}
          />
        </ModalForm>
      )}

      {/* DELETE COURSE */}
      <ActionModal
        header="Are you sure you want to delete?"
        title="Note: All the course content and student progress will be lost."
        onSubmit={() => {
          handleDeleteCourse();
        }}
        isLoading={isDeletingCourse}
        active={isDeleteVisible}
        setActive={setIsDeleteVisible}
        btnColor={undefined}
        children={undefined}
      />

      {/*  PURCHASE COURSE POP-UP */}
      <GroupCheckoutComponents
        community={community}
        user={user}
        plans={plans}
        header={"Buy Course"}
        setActive={setIsBuyCourseVisible}
        active={isBuyCourseVisible}
        mySubscription={mySubscription}
        socket={socket}
      />
    </div>
  );
}

const CourseDetailPage = connect((s: any) => ({
  user: s.auth,
  community: s.community,
  mySubscription: s.mySubscription,
  subscriptionsPlan: s.subscriptionsPlan,
  socket: s.socket,
}))(CourseDetailPageComponent);

// const CourseDetailPage = CourseDetailPageComponent;
export default CourseDetailPage;
