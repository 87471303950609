import cx from "classnames";
import React from "react";
import I18 from "./atoms/i18";
import { Button } from "./button.component";
import { Modal } from "./modal.component";
import Label from "./typography/label.typography";

interface Props {
  onSubmit?: () => void;
  onCancelSubmit?: () => void;
  title: string;
  subTitle?: string;
  header?: string;
  isLoading?: boolean;
  active: boolean;
  labelCancel?: string;
  labelSubmit?: string;
  btnColor?: string;
  disabled?: boolean;
  setActive: (active: boolean) => void;
  large?: boolean;
  className?: string;
  children?: React.ReactNode;
  width?: number;
}
const NewActionModal: React.FC<Props> = ({
  onSubmit = () => {},
  onCancelSubmit = () => {},
  title,
  subTitle = "",
  header,
  width = 390,
  isLoading,
  active = false,
  labelCancel = "Cancel",
  labelSubmit = "Yes, Go Ahead",
  btnColor,
  disabled = false,
  setActive = () => {},
  large = false,
  className = "",
  children,
}) => {
  return (
    <Modal
      className="CreateGroupModal"
      active={active}
      width={`${width}px`}
      padding={false}
      setActive={setActive}>
      <div className="px-5 py-8 font-bold theme-bg-surface">
        <div className="text-center font-bold">
          <Label variant="t1">{title}</Label>
        </div>
        {subTitle && (
          <div className="text-center text-xs font-semibold mt-2 theme-text-primary">
            {subTitle}
          </div>
        )}
        {children}
        <div className="mt-5">
          <div className="mx-auto">
            {
              <div className="flex justify-between gap-2">
                <button
                  disabled={disabled}
                  className={cx(
                    "focus:outline-none border-2 theme-border-default font-semibold theme-bg-disable w-full",
                    className,
                    {
                      "py-2 px-6 rounded": large,
                      "py-1 px-3 rounded": !large,
                    }
                  )}
                  onClick={onCancelSubmit}>
                  <I18>{labelCancel}</I18>
                </button>
                <Button
                  onClick={onSubmit}
                  isLoading={isLoading}
                  className="w-full"
                  label={labelSubmit}
                  disabled={disabled}
                  btnColor={btnColor}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { NewActionModal };
