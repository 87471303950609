import { useState } from "react";
import { Button, TextInput } from "../../../../_components";
import { FileInput } from "../../../../_components/form-controls/file-input";
import ModalForm from "../../../../_components/model2";
import Tab from "../../../../_components/tab.component";
import Label from "../../../../_components/typography/label.typography";
import UIcon from "../../../../_components/uicon-component";

export const UploadLessonContent: React.FC<{
  title: string;
  label: string;
  contentType: string;
  uploadContentIcon: string;
  setEmbedded?: any;
  setContent: (video: string) => void;
}> = ({
  title,
  label,
  setContent,
  uploadContentIcon,
  contentType,
  setEmbedded,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  return (
    <>
      <button
        onClick={handleOpenModal}
        className="flex flex-row w-full border-2 theme-border-default rounded items-center gap-2">
        <UIcon
          icon={uploadContentIcon as any}
          solid
          className="p-2 theme-text-subtitle-1"
          size="lg"
        />
        <Label variant="t1">{title}</Label>
        <Label className="text-xs">{label}</Label>
      </button>
      <ModalForm
        title={`Upload ${title}`}
        headerBgDefault
        footer={null}
        className="w-full sm:w-1/2 lg:w-1/3"
        visible={isOpenModal}
        setVisible={handleCloseModal}
        button={undefined}>
        <UploadContent
          setEmbedded={setEmbedded}
          setContent={setContent}
          contentType={contentType}
          title={title}
        />
      </ModalForm>
    </>
  );
};

export const FileInputSection = ({ accept, onChange }: any) => (
  <FileInput accept={accept} onChange={onChange}>
    <div className="w-[150px] h-[40px] border rounded outline outline-1 justify-center flex items-center theme-text-primary">
      Choose File
    </div>
  </FileInput>
);
const UploadContent = ({
  setContent,
  contentType,
  title,
  setEmbedded,
}: any) => {
  const TabButton = Object.freeze({ upload: 1, embedLink: 2 });

  const [openTab, setOpenTab] = useState<number>(TabButton.upload);
  const [embeddedLink, setEmbeddedLink] = useState("");
  const handleEmbedClick = () => {
    setEmbedded(embeddedLink);
  };

  return (
    <>
      <div className="flex flex-row gap-2 w-full">
        {contentType !== "attachments" && (
          <>
            <Tab
              onClick={() => {
                if (openTab !== TabButton.upload) {
                  setOpenTab(TabButton.upload);
                }
              }}
              selected={openTab === TabButton.upload}
              tab="Upload"
            />
            <Tab
              onClick={() => {
                if (openTab !== TabButton.embedLink) {
                  setOpenTab(TabButton.embedLink);
                }
              }}
              selected={openTab === TabButton.embedLink}
              tab="Embed Link"
            />
          </>
        )}
      </div>
      {openTab === TabButton.upload && (
        <div className="mt-4 w-full border-dashed border theme-bg-default rounded h-[204px] items-center justify-center flex flex-col gap-3">
          <p>{`Drop Your ${contentType} here to upload`}</p>

          {contentType !== "attachments" && (
            <FileInputSection
              accept={
                contentType === "video"
                  ? "video/*"
                  : contentType === "audio"
                  ? "audio/*"
                  : "application/*"
              }
              onChange={(value: any) => {
                setContent(value);
              }}
            />
          )}
          {contentType === "attachments" && (
            <FileInputSection
              accept="image/*"
              onChange={(value: any) => {
                setContent(value);
              }}
            />
          )}
        </div>
      )}

      {/* Embedded link */}

      {openTab === TabButton.embedLink && contentType !== "attachments" && (
        <>
          <div className=" my-3  ">
            <Label variant="t1">URL</Label>
            <TextInput
              placeholder="https://"
              large
              type="link"
              onChange={(value) => {
                setEmbeddedLink(value);
              }}
            />

            <div className="flex justify-center mt-8">
              <Button
                label={`Embed ${title}`}
                outlined
                onClick={handleEmbedClick}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
