import { ReactElement } from "react";

import behanceIcon from "../_assets/vectors/behance.svg";
import dribbbleIcon from "../_assets/vectors/dribbble.svg";
import facebookIcon from "../_assets/vectors/facebook.svg";
import GlobeIcon from "../_assets/vectors/globe-icon.svg";
import instagramIcon from "../_assets/vectors/instagram.svg";
import linkedinIcon from "../_assets/vectors/linkedin.svg";
import pinterestIcon from "../_assets/vectors/pinterest.svg";
import twitterIcon from "../_assets/vectors/twitter.svg";
import youtubeIcon from "../_assets/vectors/youtube.svg";

interface Props {
  userDetails: {
    [key: string]: string | undefined;
  };
}

/**
 * Renders social icons based on user details.
 *
 * @param {Props} props - Component properties.
 * @returns {ReactElement} Social icons component.
 */
const UserWallSocial = ({ userDetails }: Props): ReactElement => {
  const socialIcons = [
    { link: "instagramLink", icon: instagramIcon },
    { link: "facebookLink", icon: facebookIcon },
    { link: "linkedinLink", icon: linkedinIcon },
    { link: "youtubeLink", icon: youtubeIcon },
    { link: "twitterLink", icon: twitterIcon },
    { link: "pintrestLink", icon: pinterestIcon },
    { link: "dribbbleLink", icon: dribbbleIcon },
    { link: "behanceLink", icon: behanceIcon },
    { link: "webLink", icon: GlobeIcon },
  ];
  const renderSocialIcons = (): ReactElement[] => {
    return socialIcons
      .filter((social) => userDetails[social.link])
      .map((social, index) => (
        <a
          key={index}
          href={userDetails[social.link]}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:scale-105 transition-transform ease-in-out duration-150">
          <img
            src={social.icon}
            className="ml-4 cursor-pointer"
            width="25"
            alt=""
          />
        </a>
      ));
  };

  const socialIconsToRender = renderSocialIcons();

  return (
    <>
      {socialIconsToRender.length > 0 && (
        <div className="border-t theme-border-default" />
      )}

      <div className="px-4 sm:px-0 mt-3 mb-3 flex flex-wrap">
        {socialIconsToRender}
      </div>
    </>
  );
};

export { UserWallSocial };
