export const jaLanguage = {
  "Pensil Keyword": "Pensil -Schlüsselwort",
  "Home Page Left column": "ホームページ左列",
  Home: "家",
  "Explore Groups": "グループを探索します",
  Events: "イベント",
  Groups: "グループ",
  Links: "リンク",
  "Direct Message": "ダイレクトメッセージ",
  "Powered by": "搭載",
  "No groups here, please use + to create a new one, or join one see them here.":
    "ここにグループはありません。 +を使用して新しいものを作成するか、ここで参照してください。",
  "Please join a group to see them here": "ここでグループに参加してください",
  "Channel can only be reordered within the group!":
    "セクションはグループ内でのみ並べ替えることができます！",
  "Group Page": "グループページ",
  "Invite Members": "メンバーを招待します",
  Join: "加入",
  Joined: "参加",
  "Create new Channel": "新しいセクションを作成します",
  "Group Setting drop down": "グループ設定ドロップダウン",
  Share: "共有",
  "Edit group": "グループの編集",
  "Delete group": "グループを削除します",
  Leave: "離れる",
  "Are you sure you want to delete the group?": "グループを削除したいですか？",
  Cancel: "キャンセル",
  "Yes, Go Ahead": "はい、先に進みます",
  "Share via link": "リンク経由で共有します",
  Copy: "コピー",
  Copied: "コピー",
  "Share on social media": "ソーシャルメディアで共有します",
  "Add Channel": "セクションを追加します",
  "Channel Header": "セクションヘッダー",
  Live: "ライブ",
  "Create Post": "投稿を作成します",
  "Notificaiton Bell": "NotificAitonベル",
  "All posts": "すべての投稿",
  "Admin posts only": "管理投稿のみ",
  None: "なし",
  "Channel Setting drop down": "セクション設定ドロップダウン",
  "Edit Channel": "編集セクション",
  "Embed Channel": "埋め込みセクション",
  "Delete Channel": "セクションを削除します",
  "Are you sure you want to delete the Channel?":
    "セクションを削除したいですか？",
  "Channel deleted!": "セクションが削除されました！",
  "Create Group Pop up / Update": "グループポップアップ /更新を作成します",
  "Create group": "グループを作る",
  "Basic Detail": "基本的な詳細",
  Settings: "設定",
  "Size 16:9 or 1600 by 900": "サイズ16：9または1600 x 900",
  "Group name": "グループ名",
  "Enter group name": "グループ名を入力します",
  "Suggestions: Spaces, Channels, Groups, Blog, Knowledgebase, etc":
    "提案：スペース、チャンネル、グループ、ブログ、知識ベースなど",
  "Group Description": "グループの説明",
  "Enter description here": "ここに説明を入力してください",
  "Group Type": "グループタイプ",
  Open: "開ける",
  "Visible to all & anyone can join": "すべてに表示され、誰でも参加できます",
  Closed: "閉まっている",
  "User will have to request to join the group":
    "ユーザーはグループへの参加を要求する必要があります",
  Paid: "有料",
  "Group Price": "グループ価格",
  "Currency (Default: INR)": "通貨（デフォルト：INR）",
  "User will have to pay to join the group":
    "ユーザーはグループに参加するために支払う必要があります",
  "Make this a private group": "これをプライベートグループにします",
  "Private group will only be visible to members who are part of it. Admin can add members manually from member list":
    "プライベートグループは、その一部であるメンバーにのみ表示されます。管理者はメンバーリストから手動でメンバーを追加できます",
  "Upload from device": "デバイスからアップロードします",
  "Search image": "画像を検索します",
  "Valid group name required!": "有効なグループ名が必要です！",
  "Update Group": "更新グループ",
  "SEO Settings": "SEO設定",
  "Custom URL": "カスタムURL",
  "Meta Title": "メタタイトル",
  "Maximum length of 255 characters allowed":
    "最大長さ255文字が許可されています",
  "Meta Description": "メタ記述",
  "Open Graph Detail": "グラフの詳細を開く",
  "Open Graph Title": "グラフタイトルを開く",
  "Open Graph Description": "グラフの説明を開く",
  "Open Graph Image": "グラフ画像を開く",
  "Recommended dimensions: 1200px by 630px with a 1.91:1 aspect ratio.":
    "推奨寸法：1.91：1のアスペクト比で、1200px x 630px x 630px。",
  "If no URL is used, it will automatically picked your group thumbnail.":
    "URLが使用されない場合、グループサムネイルを自動的に選択します。",
  "Create Channel Pop up / Update":
    "[ポップアップ /更新]セクションを作成します",
  "Create Channel": "セクションを作成します",
  "Channel Name": "セクション名",
  "Suggestions: General, Introductions, Community Guidelines":
    "提案：一般、紹介、コミュニティガイドライン",
  "Channel Type": "セクションタイプ",
  "Post Type": "ポストタイプ",
  "Group Chat": "グループチャット",
  "Who can post in this Channel?": "このセクションに誰が投稿できますか？",
  "Only Admin": "管理者のみ",
  "Any user": "任意のユーザー",
  "Sorting order for Channel": "セクションの並べ替え順序",
  "Latest first": "最新の最初",
  "Oldest post first": "最初に最も古い投稿",
  "Channel Appearance": "セクションの外観",
  "List View": "リストビュー",
  "Grid View": "グリッドビュー",
  "Make this closed Channel": "この閉じたセクションを作成します",
  "Members will have to request access to see Channel content":
    "メンバーはセクションコンテンツを表示するためにアクセスを要求する必要があります",
  "Make this a private Channel": "これをプライベートセクションにします",
  "Disables requests (Make sure closed Channel is selected as well).":
    "リクエストを無効にします（閉じたセクションも選択されていることを確認してください）。",
  "Post Editor": "ポストエディター",
  "Create new post in": "新しい投稿を作成します",
  "Add Cover Picture": "カバー画像を追加します",
  "Heading (Optional)": "見出し（オプション）",
  "Write here..": "ここに書く..",
  Picture: "写真",
  Video: "ビデオ",
  Attachment: "添付ファイル",
  Poll: "世論調査",
  "Embed Code": "埋め込みコード",
  Tags: "タグ",
  "Toggle to question": "質問に切り替えます",
  "Poll expire time": "投票期限が切れます",
  "Poll question": "世論調査の質問",
  "Enter Question": "質問を入力してください",
  Question: "質問",
  Option: "オプション",
  Options: "オプション",
  "Add Option": "オプションを追加します",
  "Poll Expiry time": "世論調査時間",
  "12 Hours": "12時間",
  "1 Day": "1日",
  "2 Days": "2日",
  "1 Week": "1週間",
  "2 Weeks": "2週間",
  "Always Open": "常に開いています",
  "Write embeddable code": "埋め込み可能なコードを書きます",
  "Click here to add embed code.":
    "埋め込みコードを追加するには、ここをクリックしてください。",
  "Paste your embed code here!": "ここに埋め込みコードを貼り付けます！",
  "Post Settings": "設定を投稿します",
  "Update Post": "投稿を更新します",
  Update: "アップデート",
  "Start meeting / Video call / Live": "会議 /ビデオ通話 /ライブを開始します",
  "Meeting Name": "会議名",
  "Suggestion: Group discussion etc": "提案：グループディスカッションなど",
  Description: "説明",
  "Enter description here (optional)":
    "ここに説明を入力してください（オプション）",
  "When do you want to start the session": "いつセッションを開始しますか",
  "Right now": "たった今",
  "Schedule for later": "後でスケジュール",
  "Group video call": "グループビデオ通話",
  Webinar: "ウェビナー",
  "Go Live": "ライブに行く",
  "Info on the Post": "投稿に関する情報",
  "Post comment": "コメントを投稿",
  Comment: "コメント",
  Comments: "コメント",
  Reply: "返事",
  Replies: "返信",
  "Share via URL": "URL経由で共有します",
  "Reshare in other Channels": "他のセクションの再配ッシ",
  "Post Card Drop Down": "ポストカードドロップダウン",
  Bookmark: "ブックマーク",
  "Pin post": "ピンポスト",
  Edit: "編集",
  "Embed Post": "埋め込まれた投稿",
  Delete: "消去",
  "Remove Pin": "ピンを取り外します",
  "Remove Bookmark": "ブックマークを削除します",
  "Just copy paste this code wherever you want it to be embedded":
    "このコードを埋め込みたい場所にコピーするだけです",
  Height: "身長",
  Preview: "プレビュー",
  "Copied to clipboard": "クリップボードにコピーされました",
  "Select Widgets": "ウィジェットを選択します",
  "Add widgets to": "ウィジェットを追加します",
  "You can change their order by dragging & dropping the widgets over each other":
    "ウィジェットを互いにドラッグしてドロップすることで、注文を変更できます",
  "Please note: Widget wont be displayed if there is no data available,":
    "注意：利用可能なデータがない場合、ウィジェットは表示されません。",
  "Group Members": "グループの人（仲間）たち",
  "Group Leaderboard": "グループリーダーボード",
  "Community Leaderboard": "コミュニティリーダーボード",
  "Trending Posts": "トレンド投稿",
  "Group Events": "グループイベント",
  "Add Custom": "カスタムを追加します",
  "Save Widgets": "ウィジェットを保存します",
  "Visible to user": "ユーザーに表示されます",
  "Visible to admin": "管理者に見える",
  "Add widget to": "ウィジェットを追加します",
  "Upload cover picture": "カバー画像をアップロードします",
  "1:1 aspect ration (recommended)": "1：1アスペクト配分（推奨）",
  "Card Title": "カードタイトル",
  "Enter widget name": "ウィジェット名を入力します",
  "Small Description (try to keep it under 50 words)":
    "小さな説明（50ワード以下に保つようにしてください）",
  "Enter description": "説明を入力してください",
  "Button Title": "ボタンタイトル",
  "Enter button name": "ボタン名を入力します",
  "Siggestion: Know more, Join, Apply, etc":
    "Siggestion：もっと知り、参加し、応募するなど",
  "URL link": "URLリンク",
  "Create custom widget": "カスタムウィジェットを作成します",
  "Custom widget created successfully":
    "カスタムウィジェットは正常に作成されました",
  Widgets: "ウィジェット",
  Members: "メンバー",
  "See All": "すべてを見る",
  "Communtiy Top Contributors": "Communtiyトップの貢献者",
  "Community Members": "コミュニティメンバー",
  "Group Top Contributors": "グループトップの貢献者",
  "Upcoming Events": "今後のイベント",
  "Home Page Banner": "ホームページバナー",
  "Edit Banner": "バナーを編集します",
  "Home page Edit Banner Pop up": "ホームページの編集バナーポップアップ",
  "Update Banner": "バナーを更新します",
  Search: "検索",
  "Upload Image": "画像をアップロードします",
  Remove: "削除",
  "Banner image (please use 16:6 ratio or 1600px by 600px image for the best view)":
    "バナー画像（16：6比を使用するか、1600px x 600px画像を使用して最良のビューを使用してください）",
  "Banner Title": "バナータイトル",
  "Enter here": "ここに入力",
  "Suggestion: Welcome to my community etc":
    "提案：私のコミュニティなどへようこそ",
  "Banner Description": "バナーの説明",
  "Enter banner description": "バナーの説明を入力してください",
  "Save Banner Info": "バナー情報を保存します",
  "Home Page Feed": "ホームページフィード",
  Recent: "最近",
  "Top Posts": "トップポスト",
  Trending: "トレンド",
  "Home Feed Groups": "ホームフィードグループ",
  "Header BarProfile Drop down": "ヘッダーバープロファイルドロップダウン",
  "My Profile": "私のプロフィール",
  Logout: "ログアウト",
  Notification: "通知",
  Notifications: "通知",
  "Mark all viewed": "すべて表示されているすべてのマーク",
  "has created a post": "投稿を作成しました",
  "wants to join": "参加したい",
  "has commented on your post": "あなたの投稿にコメントしました",
  "has replied to your comment": "あなたのコメントに返信しました",
  "Search for tags": "タグを検索します",
  "All groups": "すべてのグループ",
  "Search result": "検索結果",
  "No post found for selected hashtag":
    "選択したハッシュタグの投稿は見つかりませんでした",
  "Start a conversation": "会話を始めます",
  "Search by name": "名前で検索します",
  Upcoming: "今後",
  UPCOMING: "今後",
  Past: "過去",
  PAST: "過去",
  "No upcoming events": "今後のイベントはありません",
  "Create event popup": "イベントポップアップを作成します",
  "Event details": "イベントの詳細",
  "16:9 aspect ratio": "16：9アスペクト比",
  "Event name": "イベント名",
  "Enter event name": "イベント名を入力します",
  "Host Name": "ホスト名",
  "You will be made host if none selected":
    "選択されていない場合、ホストになります",
  "Write here...": "ここに書く...",
  "No. of registration for the event": "イベントの登録数",
  Unlimited: "無制限",
  "When & where": "いつどこで",
  From: "から",
  "Start date": "開始日",
  time: "時間",
  To: "に",
  "End date": "終了日",
  "Current time zone:": "現在のタイムゾーン：",
  "Can be updated from the communtiy setting": "Communtiy設定から更新できます",
  "Online event": "オンラインイベント",
  "Add meeting link": "会議リンクを追加します",
  "Meeting link will be generated once event is created":
    "イベントが作成されると、会議リンクが生成されます",
  Venue: "会場",
  "Enter address": "アドレスを入力します",
  Create: "作成",
  Invite: "招待",
  "Share event via link": "リンクを介してイベントを共有します",
  Copy__1: "コピー",
  "Select Channel to post this event":
    "このイベントを投稿するにはセクションを選択します",
  "Once posted, members of that group or Channel will recieve an notification":
    "掲示されると、そのグループまたはセクションのメンバーは通知を受け取ります",
  Post: "役職",
  RSVP: "お返事お願いします",
  "Book Event": "ブックイベント",
  "Yes, I'll attend": "はい、私は出席します",
  "May be": "多分",
  "No, I won't attend": "いいえ、私は出席しません",
  "Date & Time": "日付時刻",
  Host: "ホスト",
  "Online meeting": "オンライン会議",
  "Meeting link will be visible once registered for the event":
    "イベントに登録されると、会議リンクが表示されます",
  "User Management": "ユーザー管理",
  "Go bac to community": "コミュニティにBACに移動します",
  "Manage Members": "メンバーを管理します",
  Member: "メンバー",
  Invited: "招待",
  "Search for member": "メンバーを検索します",
  "Add to group": "グループに追加します",
  "Send Direct Message": "直接メッセージを送信します",
  "Change role": "役割を変更します",
  "Kick from community": "コミュニティからのキック",
  "Super Admin": "スーパー管理者",
  "Super admin will have full access to the communtiy settings":
    "スーパー管理者はCommuntiy設定に完全にアクセスできます",
  "Group Admin": "グループ管理者",
  "Admin of the selected group can edit the group information and create, edt or delete Channel under selected group":
    "選択したグループの管理者は、グループ情報を編集し、選択したグループの下でセクションを作成、EDT、または削除できます",
  "Group Moderator": "グループモデレーター",
  "Group moderator can create or delete post, comment, reply & messages in the selected group.":
    "グループモデレーターは、選択したグループで投稿、コメント、返信、メッセージを作成または削除できます。",
  User: "ユーザー",
  Save: "保存",
  "User role updated successfully": "ユーザーの役割は正常に更新されました",
  Leaderboard: "リーダーボード",
  "Click on the column header for sorting":
    "ソートについては、列ヘッダーをクリックします",
  Rank: "ランク",
  "Karma Points": "カルマポイント",
  "Custom Points": "カスタムポイント",
  Follow: "従う",
  Upvotes: "upvotes",
  Posts: "投稿",
  "Add custom points": "カスタムポイントを追加します",
  "Subtract custom points": "カスタムポイントを差し引きます",
  "Enter no. of points": "NOを入力してください。ポイントの",
  "Add points": "ポイントを追加します",
  "Invite members to": "メンバーを招待します",
  "Invite via link": "リンク経由で招待します",
  "Invite via email": "メールで招待してください",
  "Add more": "さらに追加",
  "Upload a CSV": "CSVをアップロードします",
  "Please upload a comma-seprated CSV file, with header as name, email.":
    "ヘッダーは名前、電子メールとして、コンマセレートCSVファイルをアップロードしてください。",
  "Here is a template CSV file": "これがテンプレートCSVファイルです",
  "Upload File": "ファイルをアップロードする",
  "Send Invite": "招待を送る",
  "Mail Template": "メールテンプレート",
  "Send Test Mail": "テストメールを送信します",
  Hey: "おい",
  "A has invite you to join the B":
    "Aはあなたにbに参加するように招待しています",
  "Switch to rich text editor": "リッチテキストエディターに切り替えます",
  "Add Custom Message": "カスタムメッセージを追加します",
  "Enter message here": "ここにメッセージを入力してください",
  "Send Bulk Mail": "バルクメールを送信します",
  "Send Mail": "メールを送信します",
  "Send bulk mail to all community members or select group members":
    "すべてのコミュニティメンバーにバルクメールを送信するか、グループメンバーを選択します",
  Date: "日にち",
  Subject: "主題",
  Recipients: "受信者",
  "1000 bulk emails remaining for this month. Resets on <date>":
    "今月は1000のバルクメールが残っています。 <date>でリセット",
  Everyone: "みんな",
  "Enter mail subject": "メールの件名を入力します",
  "Write message": "メッセージを書きます",
  Cancel__1: "キャンセル",
  Send: "送信",
  "Profile Page": "自己紹介ページ",
  Activity: "アクティビティ",
  Bookmarks: "ブックマーク",
  "Edit Profile": "プロファイル編集",
  Unsubscribe: "登録解除",
  "Settings (Account)": "設定（アカウント）",
  Profile: "プロフィール",
  "Set profile Picture": "プロフィール画像を設定します",
  "Your name": "あなたの名前",
  "User ID": "ユーザーID",
  "Short bio": "ショートバイオ",
  "Link your social account, this will be displayed on your profile":
    "ソーシャルアカウントをリンクすると、これがあなたのプロフィールに表示されます",
  "Add social handle": "ソーシャルハンドルを追加します",
  Verification: "検証",
  "Account Verification": "アカウント検証",
  Email: "Eメール",
  "Enter email": "メールアドレスを入力して",
  Verified: "検証",
  Unverified: "未検証",
  Change: "変化",
  Unlink: "リンクしていません",
  Mobile: "モバイル",
  "enter mobile no.": "モバイル番号を入力します。",
  "Request OTP": "OTPをリクエストします",
  "Notification centre": "通知センター",
  "Manage your personal account notification preferences":
    "個人アカウント通知の好みを管理します",
  Prefrences: "プレフリーンス",
  "Email notification": "電子メール通知",
  "Notify me about comments on my posts":
    "私の投稿へのコメントについて私に通知してください",
  "Notify me about replies on my comments":
    "私のコメントの返信について私に通知してください",
  "Notify me about upvotes on my posts":
    "私の投稿のupvotesについて私に通知してください",
  "Notify me about reaction on my comments":
    "私のコメントの反応について私に通知してください",
  "Notify me about reaction on my reply":
    "私の返信の反応について私に通知してください",
  "Notify me about direct message":
    "直接メッセージについて私に通知してください",
  "Notify me about my mentions": "私の言及について私に通知してください",
  "Notification for new posts": "新しい投稿の通知",
  "Only group & Channel available which you are part of":
    "あなたが利用できるグループとセクションのみが利用可能",
  "Group & Channel": "グループとセクション",
  "All activity": "すべてのアクティビティ",
  "Post by admin": "管理者による投稿",
  "No Notification": "通知なし",
  "Setting (Community)": "設定（コミュニティ）",
  "General Settings": "一般設定",
  "Upload community logo": "コミュニティロゴをアップロードします",
  "Community name": "コミュニティ名",
  "Invalid field": "無効なフィールド",
  "Your community address": "あなたのコミュニティアドレス",
  "Fav icon": "お気に入りのアイコン",
  "This will be visible on the browser tab (32px by 32px)":
    "これは、ブラウザタブ（32px x 32px）に表示されます",
  TimeZone: "タイムゾーン",
  "Privacy Policy on login page": "ログインページのプライバシーポリシー",
  "Terms & Condition on login page": "ログインページの用語と条件",
  "Enter link here": "ここにリンクを入力してください",
  "Open Group Image": "オープングループ画像",
  "By default ir takes up the community banner uploaded with the communtiy":
    "デフォルトでは、communtiyでアップロードされたコミュニティバナーを取り上げます",
  "Community Experience": "コミュニティの経験",
  Onboarding: "オンボーディング",
  "Make your community private": "コミュニティをプライベートにします",
  "Users will have to login or sign up to access the community":
    "ユーザーは、コミュニティにアクセスするためにログインまたはサインアップする必要があります",
  "Make account verification mandatory for both email & mobile":
    "メールとモバイルの両方にアカウント検証を必須にします",
  "New user will have to verify both email & mobile no. to access the community":
    "新しいユーザーは、電子メールとモバイル番号の両方を確認する必要があります。コミュニティにアクセスする",
  "Admin will also have to verify their mobile no. & email or else they won't be able to access the community":
    "また、管理者はモバイル番号を確認する必要があります。 ＆電子メールまたはそうでなければ、彼らはコミュニティにアクセスできません",
  "Gated community access": "ゲートコミュニティアクセス",
  "Admin will have to approve or reject user's requests to join the community":
    "管理者は、コミュニティに参加するためにユーザーの要求を承認または拒否する必要があります",
  "Disable mobile login/register": "モバイルログイン/レジスタを無効にします",
  "Disable email login/register": "電子メールのログイン/レジスタを無効にします",
  "Disable google login/register": "Googleログイン/レジスタを無効にします",
  "Disable facebook login/register": "Facebookログイン/レジスタを無効にします",
  "Show & Hide features": "機能を表示および非表示にします",
  "Hide direct chat": "直接チャットを非表示にします",
  "User will not be able to send direct messages to each other":
    "ユーザーはお互いに直接メッセージを送信できません",
  "Hide profile picture from top right corner for mobile responsive view":
    "モバイルレスポンシブビューのために、右上隅からプロフィール写真を隠す",
  "Useful when using community in an in app browser":
    "アプリブラウザーでコミュニティを使用する場合に便利です",
  "Hide profile picture from top right corner in every view":
    "すべてのビューの右上隅からプロフィール写真を隠す",
  "Disable header dropdown on profile picture":
    "プロフィール写真でヘッダードロップダウンを無効にします",
  "Useful for integrated community, especially using SSO":
    "特にSSOを使用して、統合コミュニティに役立ちます",
  "Hide the communtiy logo from the header":
    "ヘッダーからCommuntiyロゴを非表示にします",
  "Hide the communtiy title from the header":
    "Communtiyタイトルをヘッダーから非表示にします",
  "Show collpased group in the side nav bar":
    "サイドナビゲーションバーにざるつめたグループを表示します",
  "Show full expanded post in the home feed":
    "ホームフィードに完全な拡張ポストを表示します",
  "Hide the leaderboard score from the header":
    "ヘッダーからリーダーボードスコアを非表示にします",
  "Show powered by Pensil": "ペンシルを搭載した表示を表示します",
  "Hide closed groups if not joined":
    "参加していない場合は、閉じたグループを非表示にします",
  "Closed groups would not be shown to the user if they are not a member of the group":
    "彼らがグループのメンバーではない場合、閉じたグループはユーザーに表示されません",
  "Hide Community subscriber count from the users":
    "ユーザーからコミュニティの加入者数を非表示にします",
  "This will hide the community subscriber list in the community header":
    "これにより、コミュニティヘッダーのコミュニティサブスクライバーリストが非表示になります",
  "Disable Communtiy Switcher": "Communtiyスイッチャーを無効にします",
  "Use MD editor for create post description":
    "MDエディターを使用して、投稿の作成を作成します",
  "Non logged in user will land on explore group page instead of post directory":
    "ログインしていないユーザーは、投稿ディレクトリの代わりにExploreグループページに着陸します",
  "Advanced configurable": "高度な構成可能",
  "Communtiy default country code": "Communtiyデフォルトの国コード",
  "This will show up by default on login/signup":
    "これは、ログイン/サインアップでデフォルトで表示されます",
  "Onboarding/Welcome Message": "オンボーディング/ウェルカムメッセージ",
  "Welcome Message": "ようこそメッセージ",
  "Greet your members with personalized message":
    "パーソナライズされたメッセージでメンバーに挨拶します",
  "Welcome to": "ようこそ",
  Message: "メッセージ",
  "Add message": "メッセージを追加します",
  "Custom domain": "カスタムドメイン",
  "Add custom domain": "カスタムドメインを追加します",
  "Create new request": "新しいリクエストを作成します",
  "Enter your custom domain": "カスタムドメインを入力します",
  "Setup details": "セットアップの詳細",
  "Add a CNAME record in your domain pointing to this community's address":
    "このコミュニティの住所を指し示すドメインにcnameレコードを追加する",
  "To configure your domain, you'll need to add a CNAME record pointing your domain to your pensil community URL":
    "ドメインを構成するには、ペンシルコミュニティURLにドメインを指すCNAMEレコードを追加する必要があります",
  "Step by Step help apge": "ステップバイステップAPGEを助けます",
  "All request list": "すべての要求リスト",
  "Allow admin to assign/un-assign custom points to users":
    "管理者がユーザーにカスタムポイントを割り当てる/割り当てることを許可します",
  "Base points for user to begin with":
    "ユーザーが最初から入れるためのベースポイント",
  "Base points will be updated for all the users, any changes will reflect for all the users.":
    "ベースポイントはすべてのユーザーに対して更新され、変更はすべてのユーザーに反映されます。",
  "Allocate points for interaction": "相互作用にポイントを割り当てます",
  "Upvoting post": "投稿投稿",
  "creating a comment": "コメントを作成します",
  "creating a reply": "返信を作成します",
  "Getting upvote to their post": "彼らの投稿に跳ね返る",
  "Getting comment to their post": "彼らの投稿にコメントを得る",
  "Getting reply to their comment": "彼らのコメントへの返信を取得します",
  "Creating a post": "投稿を作成します",
  Visiblity: "視野",
  "Move Up": "上に移動します",
  "Move Down": "下に移動",
  "Create embedded Channel": "埋め込みセクションを作成します",
  "Preview area": "プレビューエリア",
  "Theme selector": "テーマセレクター",
  "Community Theme": "コミュニティのテーマ",
  "Select theme": "テーマを選択します",
  "Select brand color": "ブランドの色を選択します",
  "Customize your button color to match your brand":
    "ブランドに合わせてボタンの色をカスタマイズします",
  "Select on button text color": "ボタンのテキスト色を選択します",
  "Suggestion: Keep inverted contrast against brand color":
    "提案：ブランドカラーとの反転コントラストを維持します",
  Billing: "請求する",
  "Current plan": "現在の計画",
  "You are current on Free PLan": "あなたは無料の計画で最新のものです",
  "Renew on: NA": "更新：na",
  Select: "選択する",
  "Email us at": "にメールしてください",
  "Compare plan": "計画を比較します",
  "API Keys": "APIキー",
  "Create new key": "新しいキーを作成します",
  "Total keys": "合計キー",
  "Keep your API keys safe! You can use them to access specific feature & let cetain apps perfrom certain actions on your behalf on the platform.":
    "APIキーを安全に保ちましょう！それらを使用して特定の機能にアクセスし、プラットフォーム上のあなたに代わって特定のアクションを実行することができます。",
  "Key name": "キー名",
  "Key value": "キー値",
  Action: "アクション",
  "Group updated!": "グループが更新されました！",
  "Are you sure you want to": "よろしいですか？",
  "Make Moderator": "モデレーターを作成します",
  "Remove from group": "グループから取り外します",
  "Show All": "すべて表示する",
  "Change Role": "役割を変更します",
  Close: "近い",
  copy: "コピー",
  LINKS: "リンク",
  "Email Id": "電子メールID",
  Name: "名前",
  "Search for an image": "画像を検索します",
  "Crop Image": "クロップ画像",
  "No photos found": "写真は見つかりません",
  Community: "コミュニティ",
  "Banner ratio - 16:9": "バナー比-16：9",
  "Banner Ratio - 16:6": "バナー比-16：6",
  "Share on mail": "メールで共有します",
  "Share on Telegram": "電報で共有します",
  "Share on Whatsapp": "WhatsAppで共有します",
  "Share on Linkedin": "LinkedInで共有します",
  "Share on Facebook": "Facebookでシェア",
  "Share on Twitter": "Twitterで共有します",
  "Pin Post": "ピンポスト",
  "Heading (optional)": "見出し（オプション）",
  "Select Channel": "セクションを選択します",
  Gif: "gif",
  "Create Custom Widget": "カスタムウィジェットを作成します",
  "Are you sure you want to delete this widget?":
    "このウィジェットを削除したいですか？",
  "Go to event": "イベントに移動します",
  "Add Tags": "タグを追加する",
  "Create Tag": "タグを作成します",
  "Title (Optional)": "タイトル（オプション）",
  "Search Gif": "GIFを検索します",
  "What's on your mind": "何を考えているの",
  "Looks like nothing available here right now.":
    "今ここでは何も利用できないように見えます。",
  "Try exploring other tabs": "他のタブを探索してみてください",
  "Delete Widget": "ウィジェットを削除します",
  "Please select a Channel to post": "投稿するセクションを選択してください",
  "Post added successfully!": "投稿が正常に追加されました！",
  "Failed to create post. Please check post content and retry again.":
    "投稿の作成に失敗しました。ポストコンテンツをチェックして、もう一度再試行してください。",
  "Are you sure you want to discard current post?":
    "現在の投稿を破棄したいですか？",
  "By default it will take up the image uploaded with the post":
    "デフォルトでは、投稿でアップロードされた画像を取り上げます",
  "Quiz can be only viewed and attempted from mobile device right now!":
    "クイズは、今すぐモバイルデバイスからのみ表示および試みることができます！",
  "Community Banner": "コミュニティバナー",
  "Change Cover": "表紙を変える",
  "Suggestions: Welcome to my community etc":
    "提案：私のコミュニティなどへようこそ",
  "(edited)": "（編集）",
  "Aspect ratio (recommended)": "アスペクト比（推奨）",
  "Banner details updated!": "バナーの詳細が更新されました！",
  "Refresh if changes are not visible!":
    "変更が見えない場合は更新してください！",
  "Banner details could not be updated!":
    "バナーの詳細は更新できませんでした！",
  "Post deleted": "削除された投稿",
  "Post not available. It may have been deleted.":
    "投稿できません。削除された可能性があります。",
  Admin: "管理者",
  Moderator: "モデレータ",
  "Bookmark added!": "ブックマークが追加されました！",
  "Bookmark removed": "削除されたブックマーク",
  "Failed performing action!": "動作に失敗しました！",
  "Copy this link to share on other platforms":
    "このリンクをコピーして、他のプラットフォームで共有します",
  "View this event": "このイベントをご覧ください",
  Event: "イベント",
  "Join Meeting": "会議に参加してください",
  "The live meeting has ended": "ライブミーティングは終了しました",
  "read more...": "続きを読む...",
  "Load more comments": "より多くのコメントを読み込みます",
  "Post Reply": "返信",
  "Post Comment": "コメントを投稿",
  more: "もっと",
  upvoted: "賛成派",
  "1 Upvote": "1 Upvote",
  "1 Comment": "1つのコメント",
  "Please enter a title": "タイトルを入力してください",
  "Please enter a url link": "URLリンクを入力してください",
  "Please enter button title": "ボタンタイトルを入力してください",
  "Button label must be at least 20 characters":
    "ボタンラベルは少なくとも20文字でなければなりません",
  "Please enter valid url": "有効なURLを入力してください",
  "Suggestions: Know more, Join, Apply...":
    "提案：もっと知って、参加し、応募してください...",
  URL: "URL",
  "Community Events": "コミュニティイベント",
  "Widget deleted successfully": "ウィジェットは正常に削除されました",
  "Community Top Contributors": "コミュニティトップの貢献者",
  points: "ポイント",
  "Points cannot be greater than current points":
    "ポイントは現在のポイントより大きくなることはできません",
  "Point cannot be a negative value": "ポイントは負の値になることはできません",
  "Point cannot be a decimal point": "ポイントは小数点にすることはできません",
  "Please enter a valid number": "有効な数値を入力してください",
  "Karma points updated successfully": "カルマポイントは正常に更新されました",
  "Error in updating karma points": "Karmaポイントの更新のエラー",
  "Add karma points": "カルマポイントを追加します",
  "Minus karma points": "マイナスカルマポイント",
  "Total Karma points:": "総カルマポイント：",
  "Enter no. of karma points": "NOを入力してください。カルマポイントの",
  "Minus Points": "マイナスポイント",
  "Trending Post": "トレンドポスト",
  "No visible description": "目に見える説明はありません",
  "Picture ratio: 1:1": "画像比：1：1",
  "home feed": "ホームフィード",
  "Event name is invalid!": "イベント名は無効です！",
  custom: "カスタム",
  "Custom widget updated successfully":
    "カスタムウィジェットは正常に更新されました",
  "Name invalid!": "名前は無効です！",
  "Current timezone:": "現在のタイムゾーン：",
  "can be updated from community settings": "コミュニティ設定から更新できます",
  "Event created successfully": "正常に作成されたイベント",
  "Error in creating event": "イベントの作成のエラー",
  Attendees: "参加者",
  "Do you wish to notify these changes to attendees?":
    "これらの変更を参加者に通知したいですか？",
  "users wants to attend this event":
    "ユーザーはこのイベントに参加したいと考えています",
  "Yes, Notify them": "はい、それらに通知します",
  "No, its okay": "いいえ、大丈夫です",
  "Do you wish to notify that event is cancelled?":
    "イベントがキャンセルされていることを通知しますか？",
  "Are you sure you want to delete the event?": "イベントを削除したいですか？",
  "Delete Event": "イベントを削除します",
  "New Event": "新しいイベント",
  "Accept Invitation": "招待を受ける",
  Requested: "要求されました",
  Declined: "辞退した",
  "Request Access": "アクセスをリクエストします",
  "Enroll for": "に登録します",
  Housefull: "housefull",
  "Just copy paste this code wherever you want it to be embedded:":
    "このコードを埋め込みたい場所にコピーするだけです。",
  Ended: "終了しました",
  "Error getting events": "イベントの取得エラー",
  "Events could not be fetched!": "イベントを取得できませんでした！",
  "Show Attendees": "参加者を表示します",
  Subscribers: "加入者",
  "Make this a closed Channel": "これを閉じたセクションにします",
  "Update Channel": "セクションを更新します",
  "Valid Channel name required!": "有効なセクション名が必要です！",
  "Enter Channel name": "セクション名を入力します",
  "Community Admin": "コミュニティ管理者",
  Embed: "埋め込み",
  "Invite to": "へ招待する",
  "Please enter a valid email address":
    "有効なメールアドレスを入力してください",
  "Invitation sent successfully": "招待状が正常に送信されました",
  "Error sending invitation": "招待状の送信エラー",
  "Email is not valid!": "電子メールは無効です！",
  "Data could not be loaded!": "データはロードできませんでした！",
  "Posted in": "で掲示されます",
  "Invite Members Individually": "メンバーを個別に招待します",
  "Start a discussion": "議論を始めましょう",
  "Post something": "何かを投稿してください",
  "Invite your audience": "聴衆を招待してください",
  "Choose Plan": "計画を選択してください",
  or: "また",
  "Create a link": "リンクを作成します",
  "Add link": "リンクを追加します",
  "Update link": "リンクを更新します",
  "Add shortcut to your most used tools":
    "最も使用されているツールにショートカットを追加します",
  "Your website, Instagram Channel, Facebook profile etc":
    "あなたのウェブサイト、Instagramチャンネル、Facebookプロフィールなど",
  "Valid link name required!": "有効なリンク名が必要です！",
  "Valid url required, and make sure you are not missing http:// or https://":
    "有効なURLが必要であり、http：//またはhttps：//が欠落していないことを確認してください",
  "Invite to__1": "へ招待する",
  "Link Name": "リンク名",
  "Link URL": "リンクURL",
  "Could not subscribe to": "購読できませんでした",
  "Subscribed to": "登録されています",
  "By default it will take up the community banner uploaded with the community":
    "デフォルトでは、コミュニティにアップロードされたコミュニティバナーを取り上げます",
  "Created by": "によって作成された",
  "Online Event (Meeting link will be visible once registered for the event)":
    "オンラインイベント（イベントに登録されると、会議リンクが表示されます）",
  "Join Call": "コールに参加してください",
  "Please login to book this event":
    "このイベントを予約するためにログインしてください",
  "Commented on": "コメントした",
  "Replied to": "に答えた",
  "Looks like it's lonely here.": "ここでは孤独なようです。",
  "Try exploring other Channels and groups.":
    "他のセクションやグループを探索してみてください。",
  "Please proceed with the payment or contact admin to gain access to this group.":
    "このグループへのアクセスを得るには、支払いまたは連絡先管理者を進めてください。",
  "This is a closed group You'll need access to this group.":
    "これは、このグループにアクセスする必要があるクローズドグループです。",
  "You have no bookmarks right now.": "現在、ブックマークはありません。",
  "The user hasn't posted anything yet publicly.":
    "ユーザーはまだ公開されていません。",
  "No activity to show.": "表示するアクティビティはありません。",
  "Are you sure you want to unsubscribe the community?":
    "コミュニティの登録を解除したいですか？",
  "deals left": "取引が残っています",
  "Something went wrong while loading posts":
    "投稿を読み込んでいる間、何かがうまくいかなかった",
  "Could not unsubscribe from": "登録解除できませんでした",
  "Unsubscribed from": "登録解除",
  "Login to view this page": "ログインこのページを表示します",
  Login: "ログイン",
  "There was some error while doing the action!":
    "アクションを行っている間にいくつかのエラーがありました！",
  "Unable to delete Channel!": "セクションを削除できません！",
};
