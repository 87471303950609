import { SET_REALTIME_SECTION_NOTIFICATION_COUNT } from "./realtime-section-notification.action";

interface realTimeNotificationCountState {
  /**
   * An object containing the key-value pair of section id and new message count for that section.
   */
  count: { [key: string]: number };
}

const initialState: realTimeNotificationCountState = {
  count: {},
};

/**
 * Real-time section notification count reducer
 */
export const realTimeSectionCountReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SET_REALTIME_SECTION_NOTIFICATION_COUNT:
      return {
        ...state,
        realtimeSectionNotificationCount: action.payload,
      };

    default:
      return state;
  }
};
