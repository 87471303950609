import facebookIcon from "../../_assets/vectors/facebook.svg";
import linkedinIcon from "../../_assets/vectors/linkedin.svg";
import telegramIcon from "../../_assets/vectors/telegram.svg";
import twitterIcon from "../../_assets/vectors/twitter.svg";
import whatsapp from "../../_assets/vectors/whatsapp.svg";
import I18 from "../atoms/i18";
import Tooltip from "../atoms/tooltip";
import IconButton2 from "../icon-button-2-component";
import IconButton from "../icon-button.component";

interface Props {
  text: string;
  className?: string;
}

/**
 * @description Display a share on social media buttons ex. Facebook, Twitter, LinkedIn, WhatsApp, etc.
 * @property {string} text - Text to share
 */
export default function ShareOnSocialMedia({ text, className }: Props) {
  return (
    <div className={`flex flex-col space-y-2 px-4 py-2 ${className}`}>
      <div className="font-semibold theme-text-heading-2">
        <I18>Share on social media</I18>
      </div>
      <div className="flex items-center theme-bg-surface px-4 py-2">
        <Tooltip label="Share on Twitter">
          <IconButton
            icon={twitterIcon}
            onClick={(e) => {
              window.open(`https://twitter.com/intent/tweet?text=${text}`);
            }}
            hoverable
            alt={undefined}
          />
        </Tooltip>
        <Tooltip label="Share on Facebook">
          <IconButton
            icon={facebookIcon}
            onClick={(e) => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${text}`
              );
            }}
            hoverable
            alt={undefined}
          />
        </Tooltip>
        <Tooltip label="Share on Linkedin">
          <IconButton
            icon={linkedinIcon}
            onClick={(e) => {
              window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${text}`
              );
            }}
            hoverable
            alt={undefined}
          />
        </Tooltip>
        <Tooltip label="Share on Whatsapp">
          <IconButton
            icon={whatsapp}
            className="w-12 h-12"
            onClick={(e) => {
              window.open(`https://wa.me/?text=${text}`);
            }}
            hoverable
            alt={undefined}
          />
        </Tooltip>
        {/* Share on Telegraam */}
        <Tooltip label="Share on Telegram">
          <IconButton
            icon={telegramIcon}
            alt={undefined}
            className="w-12 h-12"
            onClick={(e) => {
              // Share on Telegraam
              window.open(`https://telegram.me/share/url?url=${text}`);
            }}
          />
        </Tooltip>
        {/* Share on email */}
        <Tooltip label={"Share on mail"}>
          <IconButton2
            icon={"envelope"}
            size={"2xl"}
            hoverable
            onClick={(e) => {
              window.open(`mailto:?body=${text}`);
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
}
