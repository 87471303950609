import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

const subscriptionEndpoint = {
  createSubscription: (communityId: string) =>
    `${base}community/${communityId}/subscription`,
  updateSubscription: (communityId: string, subscription: string) =>
    `${base}community/${communityId}/subscription/${subscription}`,
  deleteSubscription: (communityId: string, subscription: string) =>
    `${base}community/${communityId}/subscription/${subscription}`,
  cancelSubscription: (communityId: string) =>
    `${base}community/${communityId}/subscription/my`,
  getAllSubscriptions: (communityId: string) =>
    `${base}community/${communityId}/subscriptions`,
  getSubscriptionDetails: (communityId: string, subscriptionId: string) =>
    `${base}community/${communityId}/subscription/${subscriptionId}`,
  getAllSubscriptionMembers: (communityId: string, subscriptionId: string) =>
    `${base}community/${communityId}/subscription/${subscriptionId}/members`,
  getMySubscription: (communityId: string) =>
    `${base}community/${communityId}/subscription/my`,
  getAllSubscriptionsAdmin: (communityId: string) =>
    `${base}community/${communityId}/subscriptions/admin`,
  getAllMembershipSubscription: (communityId: string, subscription: string) =>
    `${base}community/${communityId}/subscription/${subscription}/members`,
  getActiveSubscriptionsForSubscription: (
    communityId: string,
    subscription: string
  ) =>
    `${base}community/${communityId}/subscription/${subscription}/active-subscriptions`,
  getPreviousSubscriptionsForSubscription: (
    communityId: string,
    subscription: string
  ) =>
    `${base}community/${communityId}/subscription/${subscription}/previous-subscriptions`,
  initiateSubscription: (communityId: string, subscriptionId: string) =>
    `${base}community/${communityId}/subscription/${subscriptionId}/initiate-subscription`,
  freeSubscribe: (communityId: string, subscriptionId: string) =>
    `${base}community/${communityId}/subscription/${subscriptionId}/free-subscribe`,
  resendInvoice: (communityId: string, invoiceId: string) =>
    `${base}community/${communityId}/invoice-resend/${invoiceId}`,
  editInvoice: (communityId: string, userSubscriptionId: string) =>
    `${base}community/${communityId}/invoice-edit/${userSubscriptionId}`,
};

export default subscriptionEndpoint;
