import axios from "axios";
import { endpoints } from "../_config";

class NotificationService {
  /**
   * get notifications
   * @param {*} postId
   * @returns
   */
  static async getNotifications(user) {
    return axios
      .get(endpoints.user.notifications, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Mark a particular notification as viewed
   * @param {*} user
   * @param {*} notificationId
   * @returns
   */
  static async markNotificationAsRead(user, notificationId) {
    return axios
      .post(
        endpoints.user.markNotificationAsViewed(notificationId),
        {},
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Mark a particular notification as viewed
   * @param {*} user
   * @param {*} notificationId
   * @returns
   */
  static async markNotificationsAsReceived(user, notificationIds) {
    return axios
      .post(
        endpoints.user.markNotificationsAsReceived,
        {
          ids: notificationIds,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Mark a particular notification as viewed
   * @param {*} user
   * @param {*} notificationId
   * @returns
   */
  static async markSelectedNotificationsAsReceived(user, notificationIds) {
    return axios
      .post(
        endpoints.user.markSelectedNotificationsAsReceived,
        {
          ids: notificationIds,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Mark a particular notification as viewed
   * @param {*} user
   * @param {*} notificationId
   * @returns
   */
  static async markNotificationsAsViewed(user, notificationIds) {
    return axios
      .post(
        endpoints.user.markNotificationsAsViewed,
        {
          ids: notificationIds,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => response.data);
  }
}

export { NotificationService };
