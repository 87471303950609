import { useContext } from "react";
import { AppContext } from "../contexts/app.context";

/**
 * Hook to get the language translator
 */

export default function useLang() {
  const { lang } = useContext(AppContext);

  return lang;
}
