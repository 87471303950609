import cx from "classnames";
import UIcon, { UIcons } from "./uicon-component";

interface Props {
  icon: UIcons;
  onClick?: (e: any) => void;
  className?: string;
  iconClass?: string;
  solid?: boolean;
  label?: String;
  hoverable?: boolean;
  size?: "xxs" | "xs" | "sm" | "base" | "lg" | "md" | "xl" | "2xl" | "3xl";
  unreadCount?: number;
}
function IconButton2({
  icon,
  onClick,
  className,
  iconClass = "",
  label,
  solid = false,
  hoverable,
  size = "xl",
  unreadCount = 0,
}: Props) {
  return (
    <div
      onClick={(e) => {
        if (onClick != null) {
          onClick(e);
        }
      }}
      className={cx(
        `IconButton2 h-10 w-10 flex items-center place-content-center rounded-full cursor-pointer ${className}`,
        { "hover:shadow": hoverable }
      )}>
      <UIcon
        icon={icon as any}
        className={` ${iconClass}  `}
        solid={solid}
        size={size}
      />
      {label && <span className="text-xs ml-1">{label}</span>}
      {unreadCount > 0 && <span className="unread-count">{unreadCount}</span>}
    </div>
  );
}
export default IconButton2;
