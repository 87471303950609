import { apiEndpointBase } from "./endpoint.config";

const base = apiEndpointBase;

export default {
  getAllEmbeddedSections: (communityId, showAll = false) =>
    base + `community/${communityId}/embedded-sections?showAll=${showAll}`,
  getEmbeddedSectionDetailBySlug: (communityId, slug) =>
    base + `community/${communityId}/embedded-sections/${slug}`,
  changeVisibilityEmbeddedSectionInCommunity: (communityId, sectionId) =>
    base +
    `community/${communityId}/embedded-sections/${sectionId}/change-visibility`,
  createEmbeddedSection: (communityId) =>
    base + `community/${communityId}/embedded-sections`,
  updateEmbeddedSection: (communityId, sectionId) =>
    base + `community/${communityId}/embedded-sections/${sectionId}`,
  updateEmbeddedSectionSortOrder: (communityId) =>
    base + `community/${communityId}/embedded-sections/update-sort`,
  getEMbeddedSectionDetailByIdForAdmin: (communityId, sectionId) =>
    base + `community/${communityId}/embedded-sections/${sectionId}/admin`,
};
